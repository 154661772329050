import React, { forwardRef, useState } from 'react';
import { Platform, StyleSheet, TextInput, TextInputProps, TextStyle, View, ViewStyle } from 'react-native';

import colors from '@packages/core/styles/colors';
import { Label, LabelProps } from './label';
import { Typography } from './typography';
import { Icon } from './icon';
import { useHoverState } from './hooks';

export interface TextareaHelperProps extends TextInputProps {
    buttonElement?: JSX.Element;
    style?: ViewStyle;
    label?: string;
    labelRightElement?: LabelProps['customRightElement'];
    textInputStyle?: TextStyle;
    errorMessage?: string;
    required?: boolean;
    optional?: boolean;
    controlId?: string;
    containerStyle?: ViewStyle;
}

export const TextareaHelper = forwardRef<TextInput, TextareaHelperProps>(
    (
        {
            buttonElement,
            style,
            label,
            labelRightElement,
            required,
            optional,
            textInputStyle,
            errorMessage,
            controlId,
            containerStyle,
            ...textInputProps
        },
        ref
    ) => {
        const [isFocused, setIsFocused] = useState(false);
        const { isHovered, hoverEventHandlers } = useHoverState();

        const isDisabled = typeof textInputProps.editable === 'boolean' && !textInputProps.editable;

        return (
            <View style={style}>
                {!!label && (
                    <Label
                        controlId={controlId}
                        required={required}
                        optional={optional}
                        customRightElement={labelRightElement}
                    >
                        {label}
                    </Label>
                )}

                <View
                    {...hoverEventHandlers}
                    style={[
                        styles.inputContainer,
                        !isDisabled && isHovered && styles.hoveredInput,
                        !isDisabled && isFocused && styles.focusedInput,
                        !isDisabled && !!errorMessage && styles.invalidInput,
                        isDisabled && styles.disabledInput,
                        containerStyle,
                    ]}
                >
                    <TextInput
                        nativeID={controlId}
                        ref={ref}
                        {...textInputProps}
                        multiline
                        onFocus={(e) => {
                            setIsFocused(true);
                            textInputProps.onFocus && textInputProps.onFocus(e);
                        }}
                        onBlur={(e) => {
                            setIsFocused(false);
                            textInputProps.onBlur && textInputProps.onBlur(e);
                        }}
                        underlineColorAndroid="transparent"
                        style={[styles.textInput, textInputStyle]}
                        placeholderTextColor={colors.textDarkTertiary}
                    />

                    {buttonElement}
                </View>

                {!!errorMessage && (
                    <>
                        <Icon style={styles.errorIcon} name="error" />

                        <Typography variant="caption" color="redOne" style={styles.errorMessage}>
                            {errorMessage}
                        </Typography>
                    </>
                )}
            </View>
        );
    }
);

const styles = StyleSheet.create({
    inputContainer: {
        height: Platform.select({ web: 142, default: 234 }),
        borderRadius: 3,
        borderWidth: 1,
        borderColor: colors.grayFive,
    },
    textInput: {
        flex: 1,
        paddingVertical: 8,
        paddingHorizontal: 12,
        fontSize: 17,
        lineHeight: 24,
        color: colors.textDarkPrimary,
        textAlignVertical: 'top',
    },
    hoveredInput: {
        shadowColor: colors.black,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.16,
        shadowRadius: 4,
    },
    disabledInput: {
        borderColor: colors.grayTwo,
        color: colors.textDarkSecondary,
        backgroundColor: colors.grayTwo,
    },
    focusedInput: {
        borderWidth: 2,
        borderColor: colors.blueOne,
    },
    invalidInput: {
        borderWidth: 2,
        borderColor: colors.redOne,
    },
    errorIcon: {
        position: 'absolute',
        right: 12,
        top: 8,
    },
    errorMessage: {
        marginTop: 8,
    },
});
