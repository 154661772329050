import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { Icon, StsIconName } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

const useTableCheckboxStyles = createUseStyles({
    tableCheckboxOuter: {
        width: 16,
        height: 16,
        '& label': {
            width: 16,
            height: 16,
            margin: 0,
            padding: 0,
            display: 'block',
            cursor: 'pointer',
            position: 'relative',
        },
        '& input': {
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            opacity: 0,
            cursor: 'pointer',
            position: 'absolute',
        },
        // Default state
        '& span': {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: 2,
            position: 'absolute',
            border: `2px solid ${colors.grayFive}`,
            '& > i': {
                display: 'none',
            },
        },
        // Hover state
        '&:hover input ~ span': {
            backgroundColor: colors.grayOne,
        },
        // Focus state
        '& input:focus ~ span': {
            boxShadow: '0 0 0 0.2rem rgba(38, 143, 255, 0.5)',
        },
        // Checked state
        '& input:checked ~ span': {
            backgroundColor: colors.blueOne,
            border: `2px solid ${colors.blueOne}`,
            '& > i': {
                display: 'block',
            },
        },
    },
    // Check icon
    checkmarkOuter: {
        width: 16,
        height: 16,
        display: 'block',
        fontSize: '16px',
        lineHeight: '16px',
        '& > div': {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
        },
    },
});

interface TableCheckboxProps {
    value?: string;
    checked?: boolean;
    iconName?: StsIconName;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    disabled?: boolean;
}

export const TableCheckbox: FC<TableCheckboxProps> = React.memo(({ value, checked, iconName, onChange, disabled }) => {
    const classes = useTableCheckboxStyles();

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!onChange) {
            return;
        }

        onChange(event);
    }

    return (
        <div className={classes.tableCheckboxOuter}>
            <label>
                <input
                    type="checkbox"
                    disabled={disabled ? disabled : false}
                    value={value}
                    checked={checked}
                    onChange={handleOnChange}
                />
                <span>
                    <i className={classes.checkmarkOuter}>
                        <Icon name={iconName || 'checkmark'} color="white" size={14} />
                    </i>
                </span>
            </label>
        </div>
    );
});

TableCheckbox.displayName = 'TableCheckbox';
