import uuidv4 from 'uuid/v4';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';

import { AccountLocation as AccountLocationTable, Button, Icon, LoadingCircle, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import Select from '@web/components/select';
import {
    Account,
    AccountLocation,
    AccountRoleOption,
    BaseRole,
    District,
    Region,
    ROLE_TARGET_ID,
    Zone,
} from '@packages/models/api';
import { accountsService, feedbackAdminService, locationsService } from '@web/services/singletons';
import { Typeahead } from 'react-bootstrap-typeahead';
import RenderJson from '@web/components/render-json';

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.grayOne,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    panelCenterContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    panelContainer: {
        padding: 24,
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

interface AcountLocationExtended extends AccountLocation {
    regionOptions: Region[];
    regionSelection: string;
    zoneOptions: Zone[];
    zoneSelection: string;
    districtOptions: District[];
    districtSelection: string;
    locationId: string;
    isLoading: boolean;
}

interface ManageUsersPanelProps {
    account?: Account;
    onAccountUpdate(account: Account): void;
}

export const ManageUsersPanel = ({ account, onAccountUpdate }: ManageUsersPanelProps) => {
    const classes = useStyles();
    const { dismissAllOverlays } = useContentOverlayState();

    const [isLoading, setIsLoading] = useState(false);
    const [baseRoleOptions, setBaseRoleOptions] = useState<AccountRoleOption[]>([]);
    const [addOnRoleOptions, setAddOnRoleOptions] = useState<AccountRoleOption[]>([]);

    const [baseRoles, setBaseRoles] = useState<BaseRole[]>(account?.baseRoles || []);
    const [addOnRoles, setAddOnRoles] = useState<AccountRoleOption[]>(account?.addOnRoles || []);
    const [locations, setLocations] = useState<AcountLocationExtended[]>(
        account?.locations.map((location) => {
            return {
                ...location,
                locationId: uuidv4(),
                isLoading: false,
                regionSelection: location.elements.find((e) => e.accountLocationElementType === 'REGION')?.value || '',
                zoneSelection: location.elements.find((e) => e.accountLocationElementType === 'ZONE')?.value || '',
                districtSelection:
                    location.elements.find((e) => e.accountLocationElementType === 'DISTRICT')?.value || '',
                zoneOptions: [],
                regionOptions: [],
                districtOptions: [],
            };
        }) || []
    );

    useEffect(() => {
        if (!account) {
            return;
        }

        setBaseRoles(account.baseRoles);
        setAddOnRoles(account.addOnRoles);
    }, [account]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            try {
                const rolesResponse = await feedbackAdminService.fetchAccountRoles();

                if (rolesResponse.success) {
                    setBaseRoleOptions(rolesResponse.data.baseRoles);
                    setAddOnRoleOptions(rolesResponse.data.addOnRoles);
                } else {
                    throw rolesResponse.data;
                }

                const locationsClone = cloneDeep(locations);

                for (let i = 0; i < locationsClone.length; i++) {
                    const regionsResponse = await locationsService.getRegions();
                    if (regionsResponse.success) {
                        locationsClone[i].regionOptions = regionsResponse.data.regions;
                    } else {
                        throw regionsResponse.data;
                    }

                    if (locationsClone[i].zoneSelection) {
                        const districtResponse = await locationsService.getDistricts(locationsClone[i].zoneSelection);
                        if (districtResponse.success) {
                            locationsClone[i].districtOptions = districtResponse.data.districts;
                        } else {
                            throw districtResponse.data;
                        }
                    }

                    if (locationsClone[i].regionSelection) {
                        const zoneResponse = await locationsService.getZones(locationsClone[i].regionSelection);
                        if (zoneResponse.success) {
                            locationsClone[i].zoneOptions = zoneResponse.data.zones;
                        } else {
                            throw zoneResponse.data;
                        }
                    }
                }

                setLocations(locationsClone);
            } catch (error) {
                window.alert(error.message);
            }

            setIsLoading(false);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handeSaveButtonClick = useCallback(async () => {
        setIsLoading(true);

        try {
            if (!account) {
                throw new Error('Account not found.');
            }

            const response = await accountsService.updateAccount(account.accountId, {
                addOnRoleIds: addOnRoles.map((s) => s.roleId),
                accountRoles: baseRoles.map((br) => {
                    return {
                        accountRoleId: br.accountRoleId,
                        roleId: br.roleId,
                    };
                }),
                // @ts-ignore
                roleTargets: locations
                    .map((l) => {
                        if (l.roleTargetTypeId === ROLE_TARGET_ID.REGION) {
                            return {
                                roleTargetId: l.regionSelection,
                                roleId: l.roleId,
                            };
                        } else if (l.roleTargetTypeId === ROLE_TARGET_ID.ZONE) {
                            return {
                                roleTargetId: l.zoneSelection,
                                roleId: l.roleId,
                            };
                        } else if (l.roleTargetTypeId === ROLE_TARGET_ID.DISTRICT) {
                            return {
                                roleTargetId: l.districtSelection,
                                roleId: l.roleId,
                            };
                        }

                        return undefined;
                    })
                    .filter((rtti) => {
                        return rtti !== undefined;
                    }),
            });

            if (response.success) {
                onAccountUpdate(response.data.account);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }

        setIsLoading(false);
    }, [account, addOnRoles, baseRoles, locations, onAccountUpdate]);

    const handleRegionSelectChange = useCallback(
        async (locationId: any, regionId: any) => {
            const locationsClone = cloneDeep(locations);
            const locationIndex = locations.findIndex((l) => l.locationId === locationId);

            try {
                const response = await locationsService.getZones(regionId);

                if (response.success) {
                    locationsClone[locationIndex].regionSelection = regionId;
                    locationsClone[locationIndex].zoneOptions = response.data.zones;

                    setLocations(locationsClone);
                } else {
                    throw response.data;
                }
            } catch (error) {
                window.alert(error.message);
            }
        },
        [locations]
    );

    const handleZoneSelectChange = useCallback(
        async (locationId: any, zoneId: any) => {
            const locationsClone = cloneDeep(locations);
            const locationIndex = locations.findIndex((l) => l.locationId === locationId);

            try {
                const response = await locationsService.getDistricts(zoneId);

                if (response.success) {
                    locationsClone[locationIndex].zoneSelection = zoneId;
                    locationsClone[locationIndex].districtOptions = response.data.districts;

                    setLocations(locationsClone);
                } else {
                    throw response.data;
                }
            } catch (error) {
                window.alert(error);
            }
        },
        [locations]
    );

    const handleDistrictSelectChange = useCallback(
        (locationId: any, districtId: any) => {
            const locationsClone = cloneDeep(locations);
            const locationIndex = locations.findIndex((l) => l.locationId === locationId);

            locationsClone[locationIndex].districtSelection = districtId;

            setLocations(locationsClone);
        },
        [locations]
    );

    const rightPanelDiv = document.getElementById('right-side-panel');
    if (!rightPanelDiv) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <div className={classes.panelHeader}>
                <Typography>View Details</Typography>
                <div className="d-flex align-items-center">
                    <Button onPress={handeSaveButtonClick}>Save Changes</Button>
                    <div className={classes.closeButton} onClick={dismissAllOverlays}>
                        <Icon name="x-close" style={{ display: 'flex' }} />
                    </div>
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                {isLoading && (
                    <div className={classes.panelCenterContainer}>
                        <LoadingCircle size={64} borderWidth={8} borderColor={colors.grayOne} />
                    </div>
                )}

                {!isLoading && (
                    <div className={classes.panelContainer}>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Subaru Username
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Typography>{account?.soaUsername ? account.soaUsername : 'N/A'}</Typography>
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Full Name
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Typography>{account?.name}</Typography>
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Phone
                                </Typography>
                            </div>
                            <div className="mb-2">
                                {account && account.phoneNumbers.length > 0 ? (
                                    account.phoneNumbers.map((phoneNumber) => {
                                        return (
                                            <Typography key={phoneNumber.phoneNumberId}>
                                                {phoneNumber.phoneNumber}
                                            </Typography>
                                        );
                                    })
                                ) : (
                                    <Typography>N/A</Typography>
                                )}
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Email
                                </Typography>
                            </div>
                            <div className="mb-2">
                                {account && account.emailAddresses.length > 0 ? (
                                    account.emailAddresses.map((emailAddress) => {
                                        return (
                                            <Typography key={emailAddress.emailAddressId}>
                                                {emailAddress.emailAddress}
                                            </Typography>
                                        );
                                    })
                                ) : (
                                    <Typography>N/A</Typography>
                                )}
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Role
                                </Typography>
                            </div>

                            {baseRoles.map((baseRole, index) => {
                                return (
                                    <div className="mb-2" key={`${baseRole.roleId}-${index}`}>
                                        <Select
                                            value={baseRole.roleId}
                                            options={baseRoleOptions.map((option) => {
                                                return {
                                                    value: option.roleId,
                                                    title: option.description,
                                                };
                                            })}
                                            onChange={(event) => {
                                                const baseRolesClone = cloneDeep(baseRoles);
                                                baseRolesClone[index].roleId = event.currentTarget.value;

                                                setBaseRoles(baseRolesClone);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        {account?.baseRoleOverridden && (
                            <div className="mb-2">
                                <div className="mb-1">
                                    <Typography variant="caption2" color="graySix">
                                        Default Role
                                    </Typography>
                                </div>
                                <div className="mb-2">
                                    <Typography>
                                        {account?.baseRoles[0].defaultRoleDescription
                                            ? account?.baseRoles[0].defaultRoleDescription
                                            : 'N/A'}
                                    </Typography>
                                </div>
                                <hr />
                            </div>
                        )}
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Add-on Role
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Typeahead
                                    id="manage-users-async-typeahead"
                                    placeholder="Search for roles..."
                                    labelKey="description"
                                    multiple
                                    selected={addOnRoles}
                                    onChange={(selections) => {
                                        setAddOnRoles(selections);
                                    }}
                                    options={addOnRoleOptions}
                                />
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Job Title
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Typography>
                                    {account?.jobTitleDescription ? account.jobTitleDescription : 'N/A'}
                                </Typography>
                            </div>
                            <hr />
                        </div>

                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Default Location
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Typography>
                                    {account?.locationsDescription ? account.locationsDescription : 'N/A'}
                                </Typography>
                            </div>
                            <hr />
                        </div>

                        <div className="mb-0">
                            {locations.map((location, index) => {
                                return (
                                    <>
                                        {location.roleTargetTypeId !== ROLE_TARGET_ID.REGION &&
                                            location.roleTargetTypeId !== ROLE_TARGET_ID.DISTRICT && (
                                                <>
                                                    <div className="mb-1">
                                                        <Typography variant="caption2" color="graySix">
                                                            Location
                                                        </Typography>
                                                    </div>
                                                    <AccountLocationTable elements={location.elements} index={index} />
                                                </>
                                            )}

                                        {(location.roleTargetTypeId === ROLE_TARGET_ID.REGION ||
                                            location.roleTargetTypeId === ROLE_TARGET_ID.DISTRICT) && (
                                            <>
                                                <div className="mb-1">
                                                    <Typography variant="caption2" color="graySix">
                                                        Region, Zone, District
                                                    </Typography>
                                                </div>
                                                <div className="mb-1">
                                                    <Typography>
                                                        {
                                                            location.elements.find(
                                                                (e) => e.accountLocationElementType === 'JOB_TITLE'
                                                            )?.value
                                                        }
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <div className="mb-2">
                                                        <div className="mb-2">
                                                            <Typography variant="default">Region</Typography>
                                                        </div>
                                                        <Select
                                                            value={location.regionSelection}
                                                            options={location.regionOptions.map((region) => {
                                                                return {
                                                                    value: region.regionId,
                                                                    title: region.description,
                                                                };
                                                            })}
                                                            onChange={(event) => {
                                                                handleRegionSelectChange(
                                                                    location.locationId,
                                                                    event.currentTarget.value
                                                                );
                                                            }}
                                                            disabled={location.isLoading}
                                                        />
                                                    </div>
                                                    {location.roleTargetTypeId === ROLE_TARGET_ID.DISTRICT && (
                                                        <>
                                                            <div className="mb-2">
                                                                <div className="mb-2">
                                                                    <Typography variant="default">Zone</Typography>
                                                                </div>
                                                                <Select
                                                                    value={location.zoneSelection}
                                                                    options={location.zoneOptions.map((zone) => {
                                                                        return {
                                                                            value: zone.zoneId,
                                                                            title: zone.description,
                                                                        };
                                                                    })}
                                                                    onChange={(event) => {
                                                                        handleZoneSelectChange(
                                                                            location.locationId,
                                                                            event.currentTarget.value
                                                                        );
                                                                    }}
                                                                    disabled={location.isLoading}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <div className="mb-2">
                                                                    <Typography variant="default">District</Typography>
                                                                </div>
                                                                <Select
                                                                    value={location.districtSelection}
                                                                    options={location.districtOptions.map(
                                                                        (district) => {
                                                                            return {
                                                                                value: district.districtId,
                                                                                title: district.description,
                                                                            };
                                                                        }
                                                                    )}
                                                                    onChange={(event) => {
                                                                        handleDistrictSelectChange(
                                                                            location.locationId,
                                                                            event.currentTarget.value
                                                                        );
                                                                    }}
                                                                    disabled={location.isLoading}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>,
        rightPanelDiv
    );
};
