import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import colors from '@packages/core/styles/colors';
import { configService } from '@web/services/singletons';

const useRenderJsonStyles = createUseStyles({
    renderJson: {
        padding: 16,
        marginTop: 24,
        borderRadius: 4,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
    },
});

interface RenderJsonProps {
    json: any;
}

const RenderJson: FC<RenderJsonProps> = (props) => {
    const classes = useRenderJsonStyles();

    if (configService.buildDebugEnabled) {
        return <pre className={classes.renderJson}>{JSON.stringify(props.json, null, 4)}</pre>;
    }

    return null;
};

export default RenderJson;
