import React, { FC } from 'react';
import colors from '@packages/core/styles/colors';
import { Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { AccountsService } from '@packages/contexts/accounts';

const useBmisAsideContentStyles = createUseStyles({
    asideHeader: {
        borderBottom: `1px solid ${colors.grayThree}`,
        marginBottom: '16px',
        padding: '0 24px 16px 24px',
    },
});

interface BmisAsideContentProps {
    accountService: AccountsService;
}

export const BmisAsideContent: FC<BmisAsideContentProps> = ({ accountService }) => {
    const { t } = useTranslation();
    const classes = useBmisAsideContentStyles();

    return (
        <div>
            <div className={classes.asideHeader}>
                <Typography variant="h5" color="blueOne">
                    {t('Details')}
                </Typography>
            </div>
            {/*<BmisAsideDetails accountService={accountService}/>*/}
        </div>
    );
};
