import React, { FC, useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { Animated, View, StyleSheet, PanResponder, LayoutChangeEvent } from 'react-native';

import colors from '@packages/core/styles/colors';
import { Typography } from './typography';
import LinearGradient from 'react-native-linear-gradient';

interface ValueProp {
    id: string;
    value: number;
    message?: string;
}

interface RangeProps {
    values: ValueProp[];
    value: number | undefined;
    onChange(value: number): void;
    disabled?: boolean;
}

export const Range: FC<RangeProps> = ({ values, value, onChange, disabled }) => {
    const rangeRef = useRef<View>(null);

    const [rangeWidth, setRangeWidth] = useState(0);
    const [startX, setStartX] = useState(0);

    const fillWidth = useRef(new Animated.Value(0)).current;
    const fillOpacity = useRef(new Animated.Value(value === undefined ? 0 : 1)).current;
    const knobTextOpacity = useRef(new Animated.Value(1)).current;
    const messageX = useRef(new Animated.Value(0)).current;
    const messagePlacholderOpacity = useRef(new Animated.Value(1)).current;
    const messageWidth = useRef(0);

    const animateFillWidthToPixelValue = useCallback(
        (targetValue: number) => {
            Animated.spring(fillWidth, {
                toValue: targetValue,
            }).start();
        },
        [fillWidth]
    );

    const jumpFillWidthToPixelValue = useCallback(
        (targetValue: number) => {
            Animated.timing(fillWidth, {
                toValue: targetValue,
                duration: 0,
            }).start();
        },
        [fillWidth]
    );

    const animateFillWidthToRangeValue = useCallback(
        (targetValue: number) => {
            const valueAsPercent = (targetValue - 1) / (values.length - 1);
            const valueAsWidth = valueAsPercent * rangeWidth;

            animateFillWidthToPixelValue(valueAsWidth);
        },
        [animateFillWidthToPixelValue, rangeWidth, values.length]
    );

    const animateFillOpacityToValue = useCallback(
        (targetValue: number) => {
            Animated.timing(fillOpacity, {
                toValue: targetValue,
                duration: 300,
            }).start();
        },
        [fillOpacity]
    );

    const animateKnobTextOpacityToValue = useCallback(
        (targetValue: number) => {
            Animated.timing(knobTextOpacity, {
                toValue: targetValue,
                duration: 100,
            }).start();
        },
        [knobTextOpacity]
    );

    const animateMessagePlaceholderOpacityToValue = useCallback(
        (targetValue: number) => {
            Animated.timing(messagePlacholderOpacity, {
                toValue: targetValue,
                duration: 300,
            }).start();
        },
        [messagePlacholderOpacity]
    );

    const animateMessageX = useCallback(() => {
        if (!value) {
            return;
        }

        const valueAsPercent = (value - 1) / (values.length - 1);
        const valueAsWidth = valueAsPercent * rangeWidth;

        if (valueAsWidth + messageWidth.current / 2 > rangeWidth) {
            Animated.spring(messageX, {
                toValue: rangeWidth - messageWidth.current,
            }).start();
        } else if (valueAsWidth - messageWidth.current / 2 < 0) {
            Animated.spring(messageX, {
                toValue: 0,
            }).start();
        } else {
            Animated.spring(messageX, {
                toValue: valueAsWidth - messageWidth.current / 2,
            }).start();
        }
    }, [messageX, rangeWidth, value, values.length]);

    const emitValue = useCallback(() => {
        // @ts-ignore
        let calculatedPosition = fillWidth._value;

        if (calculatedPosition < 0) {
            calculatedPosition = 0;
        }
        if (calculatedPosition > rangeWidth) {
            calculatedPosition = rangeWidth;
        }

        const percentage = calculatedPosition / rangeWidth;
        const totalOutcomes = values.length - 1;
        const closestOutcome = Math.round(percentage * totalOutcomes);
        const valueToEmit = closestOutcome + 1;

        if (valueToEmit !== value) {
            onChange(valueToEmit);
        } else {
            animateFillWidthToRangeValue(valueToEmit);
        }

        animateKnobTextOpacityToValue(1);
    }, [
        animateFillWidthToRangeValue,
        animateKnobTextOpacityToValue,
        // @ts-ignore
        fillWidth._value,
        onChange,
        rangeWidth,
        value,
        values.length,
    ]);

    // Detect when value prop changes, and animate to that position
    useEffect(() => {
        if (value === undefined || isNaN(value)) {
            animateMessagePlaceholderOpacityToValue(1);
            animateFillOpacityToValue(0);
            return;
        }

        animateFillWidthToRangeValue(value);
        animateMessagePlaceholderOpacityToValue(0);
        animateMessageX();
    }, [
        animateFillOpacityToValue,
        animateFillWidthToRangeValue,
        animateMessagePlaceholderOpacityToValue,
        animateMessageX,
        value,
    ]);

    // Bind touch events
    const panResponder = useMemo(() => {
        return PanResponder.create({
            onStartShouldSetPanResponder: () => !disabled,
            onPanResponderGrant: (_event, gestureState) => {
                if (rangeRef.current) {
                    rangeRef.current.measure((_fx, _fy, _width, _height, px, _py) => {
                        setStartX(gestureState.x0 - px);
                        jumpFillWidthToPixelValue(gestureState.x0 - px);
                    });
                } else {
                    setStartX(gestureState.x0);
                    jumpFillWidthToPixelValue(gestureState.x0);
                }

                animateFillOpacityToValue(1);
                animateKnobTextOpacityToValue(0);
            },
            onPanResponderMove: (_event, gestureState) => {
                fillWidth.setValue(startX + gestureState.dx);
            },
            onPanResponderRelease: () => {
                emitValue();
            },
            onPanResponderTerminate: () => {
                emitValue();
            },
        });
    }, [
        animateFillOpacityToValue,
        animateKnobTextOpacityToValue,
        disabled,
        emitValue,
        fillWidth,
        jumpFillWidthToPixelValue,
        startX,
    ]);

    const handleRangeLayout = useCallback(() => {
        if (!rangeRef.current) {
            return;
        }

        rangeRef.current.measure((_fx, _fy, width, _height, _px, _py) => {
            if (width >= 0) {
                setRangeWidth(width);
            }
        });
    }, []);

    const handleMessageLayout = useCallback(
        (event: LayoutChangeEvent) => {
            messageWidth.current = event.nativeEvent.layout.width;
            animateMessageX();
        },
        [animateMessageX]
    );

    return (
        <>
            <View ref={rangeRef} onLayout={handleRangeLayout} {...panResponder.panHandlers} style={styles.range}>
                <View style={styles.track}>
                    <Animated.View
                        style={[
                            styles.fill,
                            {
                                opacity: fillOpacity,
                                width: fillWidth.interpolate({
                                    inputRange: [0, rangeWidth],
                                    outputRange: [16, rangeWidth],
                                    extrapolate: 'clamp',
                                }),
                            },
                        ]}
                    >
                        <LinearGradient
                            start={{ x: 0, y: 0 }}
                            end={{ x: 1, y: 0 }}
                            colors={['#0AA3DC', '#1F518F']}
                            style={styles.fillGradient}
                        />
                        <View style={styles.knob}>
                            <Animated.View
                                style={{
                                    opacity: knobTextOpacity,
                                }}
                            >
                                <Typography variant="h6" style={styles.knobText}>
                                    {String(value)}
                                </Typography>
                            </Animated.View>
                        </View>
                    </Animated.View>
                    {values.map((v) => {
                        return <View key={v.id} style={styles.marker} />;
                    })}
                </View>
            </View>
            <View style={styles.messageOuter}>
                <Animated.View style={[styles.messagePlaceholderOuter, { opacity: messagePlacholderOpacity }]}>
                    <Typography variant="caption" style={styles.messagePlaceholderText}>
                        {values[0] && values[0].message}
                    </Typography>
                    <Typography variant="caption" style={styles.messagePlaceholderText}>
                        {values[values.length - 1] && values[values.length - 1].message}
                    </Typography>
                </Animated.View>

                <Animated.Text
                    style={[
                        styles.message,
                        {
                            opacity: knobTextOpacity,
                            transform: [{ translateX: messageX }],
                        },
                    ]}
                    onLayout={handleMessageLayout}
                >
                    <Typography variant="caption" style={styles.messageText}>
                        {value ? values[value - 1].message : ''}
                    </Typography>
                </Animated.Text>
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    range: {
        paddingVertical: 12,
    },
    track: {
        height: 16,
        borderRadius: 8,
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: 2,
        justifyContent: 'space-between',
        backgroundColor: colors.grayFour,
    },
    marker: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: colors.grayFive,
    },
    fill: {
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 1,
        position: 'absolute',
    },
    fillGradient: {
        width: '100%',
        height: '100%',
        borderRadius: 500,
    },
    knob: {
        top: -4,
        right: -4,
        width: 24,
        height: 24,
        borderRadius: 12,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.blueOne,
    },
    knobText: {
        color: colors.white,
    },
    messageOuter: {
        position: 'relative',
    },
    message: {
        top: 0,
        left: 0,
        position: 'absolute',
    },
    messageText: {
        color: colors.blueOne,
    },
    messagePlaceholderOuter: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    messagePlaceholderText: {
        color: colors.grayFive,
    },
});
