import React, { useState } from 'react';
import { Button, Icon, Label, TextBadge, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

const useStyles = createUseStyles({
    worksheetContainer: {
        border: `2px solid ${colors.grayTwo}`,
        borderRadius: 5,
    },
});

export const WorksheetsInputs = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [showSelectWorksheetModal, setShowSelectWorksheetModal] = useState(false);
    const [selectedWorksheet, setSelectedWorksheet] = useState([]);

    const renderWorksheet = () => {
        return (
            <div
                className={`${classes.worksheetContainer} d-flex flex-row align-items-center justify-content-between p-5`}
            >
                <div className="d-flex flex-column">
                    <div className="mb-1">
                        <Typography variant="h6">Transmission Worksheet</Typography>
                    </div>
                    <Typography style={{ color: colors.brandSecondary }} variant="caption">
                        12 oct, 2022 | Emanuel Smargiassi
                    </Typography>
                </div>
                <TextBadge variant="green">SUBMITTED</TextBadge>
            </div>
        );
    };

    const renderWorksheets = () => {
        return (
            <div>
                <Typography variant="h3">Worksheets</Typography>
                <div className="d-flex flex-row align-items-center justify-content-between mt-5 mb-5">
                    <Label required={true}>Transmission</Label>
                    <div onClick={() => setShowSelectWorksheetModal(true)}>
                        <Typography style={{ color: colors.blueOne }} variant="h6">
                            Add worksheet
                        </Typography>
                    </div>
                </div>
                {renderWorksheet()}
                <Typography style={{ color: colors.redOne }} variant="caption">
                    There is required information missing
                </Typography>
            </div>
        );
    };

    const renderSelectWorksheet = () => {
        return (
            <Modal
                show={showSelectWorksheetModal}
                centered={true}
                onHide={() => setShowSelectWorksheetModal(false)}
                size="lg"
            >
                <Modal.Header placeholder={''}>
                    <Modal.Title>Add worksheet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Label required>Worksheet</Label>
                    <Typeahead
                        className="mt-3"
                        multiple
                        onChange={setSelectedWorksheet}
                        options={
                            [
                                {
                                    label: 'Transmission worksheet',
                                },
                            ] as any
                        }
                        placeholder="Choose worksheet"
                        selected={selectedWorksheet}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={() => setShowSelectWorksheetModal(false)}>
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={false}
                        disabled={false}
                        onPress={() => navigate('/techline/worksheet-answer')}
                    >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div>
            <hr className="mt-5 mb-5" color={colors.grayThree} />
            {renderWorksheets()}
            {renderSelectWorksheet()}
        </div>
    );
};
