export const getPopoverContentLocation = (
    childRect: any,
    popoverRect: any,
    position: any,
    customBottomHeight: number
) => {
    // Calculate position of popover based on window height and client position and based on calculation show the popover at top or bottom position
    if (childRect.y + childRect.height + popoverRect.height > window.innerHeight) {
        return {
            top: childRect.top - customBottomHeight - popoverRect.height,
            left: childRect.left - (popoverRect.width - childRect.width),
        };
    } else {
        return {
            top: childRect.bottom + 4,
            left: childRect.left - (popoverRect.width - childRect.width),
        };
    }
};
