import colors from '@packages/core/styles/colors';

export const scCard = {
    '.subcomm-card': {
        height: '100%',
        borderRadius: 3,
        backgroundColor: colors.grayOne,
        border: `1px solid ${colors.grayThree}`,
        '& .card-body': {
            padding: 16,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        '@media(max-width: 600px)': {
            '& .card-body': {
                'word-break': 'break-all',
            },
        },
    },
};
