import { cloneDeep } from 'lodash';
import React, { FC } from 'react';

import { TextInputHelper, Typography } from '@packages/ui/shared';

import { DraggableFormComponent, FeedbackFormElementOption } from '@packages/models/api';

interface FormEditTextInputProps extends DraggableFormComponent {
    onChange({ formElementLabel, options }: { formElementLabel?: string; options?: FeedbackFormElementOption[] }): void;
}

export const FormEditTextInput: FC<FormEditTextInputProps> = ({ formElementLabel, options, onChange }) => {
    function handleLabelChangeText(text: string) {
        onChange({ formElementLabel: text });
    }

    function handlePlaceholderChangeText(text: string, index: number) {
        if (!options) {
            return;
        }

        const optionsClone = cloneDeep(options);
        optionsClone[index].optionPlaceholder = text;

        onChange({ options: optionsClone });
    }

    return (
        <>
            <div className="mb-2">
                <Typography variant="label">Question Text</Typography>
            </div>
            <div className="mb-8">
                <TextInputHelper
                    value={formElementLabel}
                    onChangeText={handleLabelChangeText}
                    placeholder="Enter a label"
                />
            </div>
            {options?.map((option, index) => {
                return (
                    <TextInputHelper
                        key={option.optionId}
                        value={option.optionPlaceholder}
                        onChangeText={(text) => {
                            handlePlaceholderChangeText(text, index);
                        }}
                        placeholder="Enter placeholder text (optional)"
                    />
                );
            })}
        </>
    );
};
