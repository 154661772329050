import uuid from 'uuid';
import React, { FC, useCallback } from 'react';

import { ConditionModel, FlatGroupConditionModel, GroupConditionModel } from './models';
import { QueryConditionGroup } from './components';
import { flattenQuery, isFlatGroupCondition, removeIdFromFlattenedQuery, unflattenQuery } from './utils';
import { AdvancedSearchCombinator, AdvancedSearchField } from '@packages/models/api';

interface QueryBuilderProps {
    combinators: AdvancedSearchCombinator[];
    fields: AdvancedSearchField[];
    query: GroupConditionModel;
    onChange(query: GroupConditionModel): void;
}

export const QueryBuilder: FC<QueryBuilderProps> = ({ combinators, fields, query, onChange }) => {
    function handleRemoveClick(id: string) {
        let flattenedQuery = flattenQuery(query);
        flattenedQuery = removeIdFromFlattenedQuery(id, flattenedQuery);

        const unflattenedQuery = unflattenQuery(flattenedQuery);
        onChange(unflattenedQuery);
    }

    function handleCombinatorChange(id: string, value: string) {
        const flattenedQuery = flattenQuery(query);
        flattenedQuery[id] = { ...flattenedQuery[id], combinatorId: value };

        const unflattenedQuery = unflattenQuery(flattenedQuery);
        onChange(unflattenedQuery);
    }

    function handleAddConditionClick(id: string) {
        const flattenedQuery = flattenQuery(query);
        const target = flattenedQuery[id];

        const newRuleId = uuid();
        const newRule: ConditionModel = {
            id: newRuleId,
            fieldId: '',
            operatorId: '',
            value: '',
            displayValue: '',
        };

        if (isFlatGroupCondition(target)) {
            flattenedQuery[newRuleId] = newRule;
            target.rules.push(newRuleId);
        }

        const unflattenedQuery = unflattenQuery(flattenedQuery);
        onChange(unflattenedQuery);
    }

    function handleAddGroupedConditionClick(id: string) {
        const flattenedQuery = flattenQuery(query);
        const target = flattenedQuery[id];

        const newGroupedConditionId = uuid();
        const newGroupedCondition: FlatGroupConditionModel = {
            id: newGroupedConditionId,
            combinatorId: combinators[0].combinatorId,
            rules: [],
        };

        if (isFlatGroupCondition(target)) {
            flattenedQuery[newGroupedConditionId] = newGroupedCondition;
            target.rules.push(newGroupedConditionId);
        }

        const unflattenedQuery = unflattenQuery(flattenedQuery);
        onChange(unflattenedQuery);
    }

    const handleOnChange = useCallback(
        (id: string, values: Partial<ConditionModel>) => {
            const flattenedQuery = flattenQuery(query);
            flattenedQuery[id] = { ...flattenedQuery[id], ...values };

            const unflattenedQuery = unflattenQuery(flattenedQuery);
            onChange(unflattenedQuery);
        },
        [onChange, query]
    );

    return (
        <div>
            <QueryConditionGroup
                key={query.id}
                isFirst
                combinators={combinators}
                fields={fields}
                conditionGroup={query}
                onRemove={handleRemoveClick}
                onCombinatorChange={handleCombinatorChange}
                onAddCondition={handleAddConditionClick}
                onAddGroupedCondition={handleAddGroupedConditionClick}
                onChange={handleOnChange}
            />
        </div>
    );
};
