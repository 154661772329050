import { useState, useCallback } from 'react';

type AlertType = 'success' | 'error';
type AlertContent = JSX.Element | string;

interface Alert {
    content: AlertContent;
    type: AlertType;
    isOpen: boolean;
    onClose(): void;
}

const defaultAlert: Alert = {
    content: '',
    type: 'success',
    isOpen: false,
    onClose: () => {},
};

export type AlertHook = ReturnType<typeof useAlert>;

export function useAlert(initAlert: Alert = defaultAlert) {
    const [alert, setAlert] = useState<Alert>(initAlert);

    const open = useCallback(() => {
        setAlert((a) => ({ ...a, isOpen: true }));
    }, []);

    const close = useCallback(() => {
        setAlert((a) => ({ ...a, isOpen: false }));
        alert.onClose();
    }, [alert]);

    const setType = useCallback((type: AlertType) => {
        setAlert((a) => ({ ...a, type }));
    }, []);

    const setContent = useCallback((content: AlertContent) => {
        setAlert((a) => ({ ...a, content }));
    }, []);

    const setOnClose = useCallback((onClose: () => void) => {
        setAlert((a) => ({ ...a, onClose }));
    }, []);

    const showAlert = useCallback(
        ({ type, content }: { type: AlertType; content: AlertContent }) => {
            setContent(content);
            setType(type);
            open();
        },
        [open, setContent, setType]
    );

    return {
        ...alert,
        open,
        close,
        setType,
        setContent,
        setOnClose,
        showAlert,
    };
}
