import React, { forwardRef } from 'react';
import { createUseStyles } from 'react-jss';

const useTableBodyStyles = createUseStyles({
    tableBody: {
        verticalAlign: 'top',
    },
});

export const TableBody = React.memo(
    forwardRef<HTMLTableSectionElement, any>((props, ref) => {
        const classes = useTableBodyStyles();

        return (
            <tbody ref={ref} className={classes.tableBody}>
                {props.children}
            </tbody>
        );
    })
);

TableBody.displayName = 'TableBody';
