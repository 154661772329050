import Loader from '@web/components/loader';
import React, { FC, useEffect } from 'react';
import { BmisLayout } from '@web/bmis/components/bmis-layout';
import { Typography } from '@packages/ui/shared';
import { bmisService } from '@web/services/singletons';
import { createUseStyles } from 'react-jss';
import { useBmisDispatch, useBmisState, setRowGuid, setVin } from '@packages/contexts/bmis';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDisplayDate, getDisplayValue } from '@web/utils';

const useBmisDetailStyles = createUseStyles({
    title: {
        flex: 1,
        display: 'flex',
        padding: '32px 30px',
    },
    content: {
        padding: '0px 30px',
    },
    flexBasis50: {
        flexBasis: '50%',
    },
    sectionDivider: {
        marginBottom: 24,
        borderBottom: '1px solid #E3E3E3',
        width: '100%',
    },
});

export const BmisDetail: FC = () => {
    const classes = useBmisDetailStyles();
    const bmisDispatch = useBmisDispatch();
    const { selectedBmisRow, loading } = useBmisState();
    const { t } = useTranslation();
    const { vin: vinString, rowGuid } = useParams<{ vin: string; rowGuid?: string }>();

    useEffect(() => {
        setVin(bmisDispatch, bmisService, vinString);
        setRowGuid(bmisDispatch, rowGuid || null);
    }, [bmisDispatch, vinString, rowGuid]);

    if (!selectedBmisRow && loading) {
        return <Loader />;
    }

    return (
        <BmisLayout>
            <div className={classes.title}>
                <Typography variant="h3">{t('Battery and Electrical System Analysis')}</Typography>
            </div>
            <div className={classes.content}>
                <div className="d-flex flex-wrap">
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Latest Battery Test Decision')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.finalBatteryTestDecision)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Test Code')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.starterTestCode)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Voltage')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.voltage, 'V')}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Battery Serial Number')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.serialNumber)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Battery Service Date')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayDate(selectedBmisRow?.serviceDateAndTime)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Report Date')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayDate(selectedBmisRow?.reportDate)}</Typography>
                        </div>
                    </div>
                    <div className={classes.sectionDivider} />
                </div>
                <div className="d-flex flex-wrap">
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('VIN')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.vin)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Asset Guide')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.assetGuid)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Affiliate Code')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.affiliateCode)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Affiliate Guid')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.affiliateGuid)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Alternator Decision')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.alternatorDecision)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Amp Hour')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.ampHour.toFixed(1))}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Auth Code')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.authCode)}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                        <div>
                            <Typography variant="label">{t('Battery Location')}</Typography>
                        </div>
                        <div className="pt-1">
                            <Typography>{getDisplayValue(selectedBmisRow?.batteryLocation)}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </BmisLayout>
    );
};
