import { HttpClient } from '../http';

export interface ListenerTeardown {
    [listener: string]: () => void;
}

export interface TokenBody {
    fingerprint: string;
    clientDeviceTypeId: string;
    pushToken: string;
}

export abstract class PushNotificationsService {
    protected abstract deviceFingerprint: TokenBody['fingerprint'];
    protected abstract clientDeviceTypeId: TokenBody['clientDeviceTypeId'];
    protected pushToken: string = '';
    protected listenerTeardown: ListenerTeardown = {};

    constructor(protected http: HttpClient) {}

    abstract async init(): Promise<void>;

    registerDeviceToken(pushToken: string) {
        this.pushToken = pushToken;

        const body: TokenBody = {
            fingerprint: this.deviceFingerprint,
            clientDeviceTypeId: this.clientDeviceTypeId,
            pushToken,
        };

        return this.http.orchestrateRequest({
            method: 'POST',
            url: '/client-devices',
            body,
        });
    }

    terminate() {
        Object.keys(this.listenerTeardown).forEach((evt) => {
            const teardown = this.listenerTeardown[evt];
            teardown();
        });

        this.pushToken = '';
        this.listenerTeardown = {};
    }
}
