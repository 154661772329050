import { Dispatch, Reducer } from 'react';
import { action, on, props, reducer, union, payload } from 'ts-action';
import deepMerge from 'deepmerge';

import { Asset, AssetType, Qmr, AssetProcessingState, PatchQmrDto } from '@packages/models/api';

interface NewQmr {
    retailerId?: string;
    vin?: string;
    carlineId?: string;
    modelYear?: number;
    engineNumber?: string;
    transmissionNumber?: string;
}

export interface QmrState {
    newQmr: NewQmr;
    qmr: Qmr | null;
    draftMedia: DraftMedia[];
    submitAttempted: boolean;
    localPatch: PatchQmrDto | null;
}

export interface DraftMedia {
    assetId: Asset['assetId'];
    assetTypeId: AssetType;
    rawSource: any; // RN PickerImage or Web File/blob
    thumbnailSource?: any;
    overlaySource?: any;
    note?: string;
    name?: string;
    sizeInBytes?: number;
    uploadId?: string;
    processingStateId?: AssetProcessingState;
    replacesAssetId?: Asset['assetId'];
    contentType?: string;
}

export const qmrActions = {
    setSubmitAttempted: action('SET_SUBMIT_ATTEMPTED', props<{ submitAttempted: boolean }>()),
    setNewQmr: action('SET_NEW_QMR', props<Partial<NewQmr>>()),
    updateQmr: action('UPDATE_QMR', props<{ qmr: Partial<Qmr> }>()),
    addDraftMedia: action('ADD_MEDIA_DRAFT', props<DraftMedia>()),
    updateDraftMedia: action(
        'UPDATE_DRAFT_MEDIA',
        props<{
            draftMedia: Partial<DraftMedia>;
            replaceId: string;
        }>()
    ),
    removeDraftMedia: action('REMOVE_DRAFT_MEDIA', props<{ assetId: string }>()),
    setDraftMedia: action('SET_DRAFT_MEDIA', props<{ draftMedia: DraftMedia[] }>()),
    updateAsset: action('UPDATE_ASSET', props<{ asset: Asset }>()),
    applyLocalPatch: action('APPLY_LOCAL_PATCH', payload<PatchQmrDto | null>()),
};

const actionsUnion = union(...Object.values(qmrActions));
type QmrActions = typeof actionsUnion.actions;

export type QmrActionDispatcher = Dispatch<QmrActions>;

export const initialState: QmrState = {
    newQmr: {},
    qmr: null,
    draftMedia: [],
    submitAttempted: false,
    localPatch: null,
};

export const qmrReducer: Reducer<QmrState, QmrActions> = reducer(
    initialState,
    on(qmrActions.setSubmitAttempted, (state, { submitAttempted }) => {
        return {
            ...state,
            submitAttempted,
        };
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(qmrActions.setNewQmr, (state, { type, ...newQmr }) => {
        return {
            ...state,
            newQmr,
        };
    }),
    on(qmrActions.updateQmr, (state, { qmr }) => {
        const uploadedAssetIds = qmr.assets?.map((a) => a.assetId) ?? [];
        const draftIds = (state.draftMedia || []).map((i) => i.assetId);
        const shouldUpdateDrafts = draftIds.some((d) => uploadedAssetIds.includes(d));

        return {
            ...state,
            qmr: qmr as Qmr,
            draftMedia: shouldUpdateDrafts
                ? state.draftMedia.filter((d) => {
                      return !uploadedAssetIds.includes(d.assetId);
                  })
                : state.draftMedia || [],
        };
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(qmrActions.addDraftMedia, (state, { type, ...newDraft }) => {
        let currentDraftMedia = state.draftMedia;

        if (newDraft.replacesAssetId) {
            currentDraftMedia = currentDraftMedia.filter(
                (oldDraft) => oldDraft.replacesAssetId !== newDraft.replacesAssetId
            );
        }

        return {
            ...state,
            draftMedia: [...currentDraftMedia, newDraft],
        };
    }),
    on(qmrActions.updateDraftMedia, (state, { draftMedia: updatedDraftMedia, replaceId }) => {
        return {
            ...state,
            draftMedia: state.draftMedia.map((draftMedia) => {
                if (draftMedia.assetId === replaceId) {
                    return deepMerge(draftMedia, updatedDraftMedia, {
                        arrayMerge: (_, y) => y,
                    });
                }

                return draftMedia;
            }),
        };
    }),
    on(qmrActions.removeDraftMedia, (state, { assetId }) => {
        return {
            ...state,
            draftMedia: state.draftMedia.filter((dM) => dM.assetId !== assetId),
        };
    }),
    on(qmrActions.setDraftMedia, (state, { draftMedia }) => {
        const uploadedAssetIds = state.qmr?.assets?.map((a) => a.assetId) ?? [];

        return {
            ...state,
            draftMedia: draftMedia.filter((a) => !uploadedAssetIds.includes(a.assetId)),
        };
    }),
    on(qmrActions.updateAsset, (state, { asset }) => {
        if (!state.qmr) {
            return state;
        }

        if (!Array.isArray(state.qmr.assets)) {
            return {
                ...state,
                qmr: {
                    ...state.qmr,
                    assets: [asset],
                },
            };
        }

        const qmrAssetIndex = state.qmr.assets.findIndex((qmrAsset) => qmrAsset.assetId === asset.assetId);

        if (qmrAssetIndex === -1) {
            return {
                ...state,
                qmr: {
                    ...state.qmr,
                    assets: [...state.qmr.assets, asset],
                },
            };
        }

        return {
            ...state,
            qmr: {
                ...state.qmr,
                assets: Object.assign([], state.qmr.assets, {
                    [qmrAssetIndex]: asset,
                }),
            },
        };
    }),
    on(qmrActions.applyLocalPatch, (state, { payload: actionPayload }) => {
        if (actionPayload === null) {
            return {
                ...state,
                localPatch: null,
            };
        }

        return {
            ...state,
            qmr: Object.assign({}, state.qmr, actionPayload),
            localPatch: {
                ...state.localPatch,
                ...actionPayload,
            },
        };
    })
);
