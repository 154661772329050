import React, { FC } from 'react';
import { Menu, MenuItem, Highlighter, MenuProps } from 'react-bootstrap-typeahead';
import { Typography, useHoverState } from '@packages/ui/shared';
import { Retailer } from '@packages/models/api';

interface RetailerSearchResultsProps extends MenuProps {
    title: string;
    results: Retailer[];
    typeaheadState: { text: string };
}

const ResultItem: FC<{ result: Retailer; index: number; searchText: string }> = ({ result, index, searchText }) => {
    const { isHovered, hoverEventHandlers } = useHoverState();
    return (
        <MenuItem {...hoverEventHandlers} key={result.retailerId} option={result} position={index}>
            <div>
                <Typography color={isHovered ? 'white' : 'textDarkPrimary'}>
                    <Highlighter search={searchText}>{`${result.name} (#${result.code})`}</Highlighter>
                </Typography>
            </div>

            <Typography color={isHovered ? 'white' : 'textDarkPrimary'} variant="caption">
                {' '}
                <Highlighter search={searchText}>{`${result.city}, ${result.state} ${result.postalCode}`}</Highlighter>
            </Typography>
        </MenuItem>
    );
};

const RetailerSearchResults: FC<RetailerSearchResultsProps> = ({ title, results, typeaheadState, ...props }) => {
    return (
        <Menu {...props}>
            <Menu.Header>
                <Typography variant="h6">{title}</Typography>
            </Menu.Header>

            {results.map((result, index) => (
                <ResultItem key={result.retailerId} result={result} index={index} searchText={typeaheadState.text} />
            ))}
        </Menu>
    );
};

export default RetailerSearchResults;
