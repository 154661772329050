import uuid from 'uuid';
import { HttpClient } from '@packages/core/http';
import { ConfigService, Constants } from '@packages/core/config';
import { PushNotificationsService as AbstractPushNotificationsService } from '@packages/core/push-notifications';
import { StorageService, WebStorageKeys } from '@packages/core/storage';
import * as firebase from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';

export class PushNotificationsService extends AbstractPushNotificationsService {
    protected deviceFingerprint = uuid();
    protected clientDeviceTypeId = 'WEB_BROWSER';

    private firebaseConfig: {
        apiKey?: string;
        projectId?: string;
        messagingSenderId?: string;
        appId?: string;
    };

    private didRegisterServiceWorker = false;

    constructor(storageService: StorageService, configService: ConfigService, http: HttpClient) {
        super(http);

        storageService.getItem(WebStorageKeys.BrowserDeviceId).then((deviceFingerprint) => {
            console.log('[Messaging Debug]: Device Fingerprint', deviceFingerprint || this.deviceFingerprint);

            if (!deviceFingerprint) {
                return storageService.setItem(WebStorageKeys.BrowserDeviceId, this.deviceFingerprint);
            }

            this.deviceFingerprint = deviceFingerprint;
        });

        const vapidKey = configService.get(Constants.Env.FirebaseVapidKey);
        this.firebaseConfig = {
            apiKey: configService.get(Constants.Env.FirebaseApiKey),
            projectId: configService.get(Constants.Env.FirebaseProjectId),
            messagingSenderId: configService.get(Constants.Env.FirebaseMessagingSenderId),
            appId: configService.get(Constants.Env.FirebaseAppId),
        };

        try {
            const firebaseApp = firebase.initializeApp(this.firebaseConfig);

            const messaging = getMessaging();

            console.log('[Messaging Debug]: VAPID key', vapidKey);
            if (vapidKey) {
                getToken(messaging, { vapidKey }).catch((error) => {
                    console.log('[Messaging Debug]: Error getting or registering token', error);
                });
            }
        } catch (e) {
            console.log('[Messaging Debug]: Unable to initialize messaging', e);
        }
    }

    registerServiceWorker() {
        console.log('[Messaging Debug]: Registering service worker');
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                // the source file lives in the `public/` folder
                // path here is relative to the final `build/static/js` folder
                .register('../../firebase-messaging-sw.js')
                .then(() => {
                    this.didRegisterServiceWorker = true;
                    console.log('[Messaging Debug]: Service Worker registered');
                })
                .catch((e) => {
                    console.log('[Messaging Debug]: Unable to register Firebase Messaging service worker', e);
                });
        }
    }

    async init() {
        if (!this.didRegisterServiceWorker) {
            console.log('[Messaging Debug]: Init failed - Messaging not available');
            return;
        }

        const messaging = getMessaging();

        try {
            // Even "data-only" messages _require_ permissions
            // unlike mobile, we can't get a token on Web without permissions.

            // await messaging.requestPermission();

            const getAndRegisterToken = async () => {
                try {
                    getToken(messaging)
                        .then((pushToken) => {
                            return this.registerDeviceToken(pushToken);
                        })
                        .catch((error) => {
                            console.log('[Messaging Debug]: Error getting or registering token', error);
                        });

                    // const pushToken = await getToken(messaging);
                    // await this.registerDeviceToken(pushToken);
                } catch (error) {
                    console.log('[Messaging Debug]: Error getting or registering token', error);
                }
            };

            // this.listenerTeardown.onTokenRefresh = messaging.onTokenRefresh(getAndRegisterToken);
            this.listenerTeardown.onLogout = () => {
                try {
                    deleteToken(messaging);
                } catch (error) {
                    if (error.code === 'messaging/token-unsubscribe-failed') {
                        console.log('[Messaging Debug]: Failed to clear push token', { error, token: this.pushToken });
                        return;
                    }

                    throw error;
                }
            };

            await getAndRegisterToken();

            // TODO: Message handling probably needs to move into
            // a UI/React hook on pages/components interested in
            // receiving & processing incoming messages.
            // This is only here for testing/debugging

            const foregroundOnMessageTeardown = onMessage(messaging, (m: any) => {
                console.log('[Messaging Debug]: Firebase Foreground onMessage', m);
            });

            const onMessageDebug = (m: any) => {
                console.log('[Messaging Debug]: Service Worker background-posted onMessage', m);
            };

            navigator.serviceWorker.addEventListener('message', onMessageDebug);
            this.listenerTeardown.onMessage = () => {
                foregroundOnMessageTeardown();
                navigator.serviceWorker.removeEventListener('message', onMessageDebug);
            };
        } catch (error) {
            if (
                error.code === 'messaging/permission-blocked' ||
                error.customData.serverStatus === 'PERMISSION_DENIED'
            ) {
                console.log('[Messaging Debug]: The notification permission was not granted and blocked instead.', {
                    error,
                });
            }

            console.error('[Messaging Debug]: Unable to start listenting for notifications', { error });
        }
    }
}
