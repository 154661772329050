import React from 'react';

const VinSessionCarIcon = () => {
    return (
        <div>
            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Left Icon">
                    <path
                        id="Vector"
                        d="M12 6.05625L9.64583 3.60625L10.8125 2.35937L12 3.60625L14.9583 0.5L16.125 1.725L12 6.05625ZM5.33333 21.5C5.09722 21.5 4.89931 21.4161 4.73958 21.2484C4.57986 21.0807 4.5 20.8729 4.5 20.625V13.625L6.25 8.375C6.33333 8.1125 6.48264 7.90104 6.69792 7.74062C6.91319 7.58021 7.15278 7.5 7.41667 7.5H16.5833C16.8472 7.5 17.0868 7.58021 17.3021 7.74062C17.5174 7.90104 17.6667 8.1125 17.75 8.375L19.5 13.625V20.625C19.5 20.8729 19.4201 21.0807 19.2604 21.2484C19.1007 21.4161 18.9028 21.5 18.6667 21.5H17.8333C17.5972 21.5 17.3993 21.4161 17.2396 21.2484C17.0799 21.0807 17 20.8729 17 20.625V19.75H7V20.625C7 20.8729 6.92014 21.0807 6.76042 21.2484C6.60069 21.4161 6.40278 21.5 6.16667 21.5H5.33333ZM6.83333 11.875H17.1667L16.2917 9.25H7.70833L6.83333 11.875ZM8.25 17.125C8.59722 17.125 8.89236 16.9974 9.13542 16.7422C9.37847 16.487 9.5 16.1771 9.5 15.8125C9.5 15.4479 9.37847 15.138 9.13542 14.8828C8.89236 14.6276 8.59722 14.5 8.25 14.5C7.90278 14.5 7.60764 14.6276 7.36458 14.8828C7.12153 15.138 7 15.4479 7 15.8125C7 16.1771 7.12153 16.487 7.36458 16.7422C7.60764 16.9974 7.90278 17.125 8.25 17.125ZM15.75 17.125C16.0972 17.125 16.3924 16.9974 16.6354 16.7422C16.8785 16.487 17 16.1771 17 15.8125C17 15.4479 16.8785 15.138 16.6354 14.8828C16.3924 14.6276 16.0972 14.5 15.75 14.5C15.4028 14.5 15.1076 14.6276 14.8646 14.8828C14.6215 15.138 14.5 15.4479 14.5 15.8125C14.5 16.1771 14.6215 16.487 14.8646 16.7422C15.1076 16.9974 15.4028 17.125 15.75 17.125Z"
                        fill="white"
                    />
                </g>
            </svg>
        </div>
    );
};

export default VinSessionCarIcon;
