import { Dispatch, Reducer } from 'react';
import { action, on, props, reducer, union } from 'ts-action';

import { Account, Retailer } from '@packages/models/api';

export enum AuthStatus {
    Unknown = 'UNKNOWN',
    Checking = 'CHECKING_AUTH',
    Saving = 'SAVING_TOKEN',
    FetchingProfile = 'FETCHING_PROFILE',
    Authenticated = 'AUTHENTICATED',
    Unauthenticated = 'UNAUTHENTICATED',
}

export interface AuthState {
    status: AuthStatus;
    token: string;
    account: Account | null;
    retailers: Retailer[];
}

export const authActions = {
    resetState: action('RESET_AUTH_STATE'),
    setAuthStatus: action('SET_AUTH_STATUS', props<{ status: AuthState['status']; token?: string }>()),
    setAccount: action('SET_AUTH_ACCOUNT', props<{ account: AuthState['account'] }>()),
    setRetailers: action('SET_RETAILERS', props<{ retailers: AuthState['retailers'] }>()),
    updateToken: action('UPDATE_TOKEN', props<{ token: AuthState['token'] }>()),
};

const actionsUnion = union(...Object.values(authActions));
type AuthActions = typeof actionsUnion.actions;

export type AuthActionDispatcher = Dispatch<AuthActions>;

export const initialState = {
    status: AuthStatus.Unknown,
    token: '',
    account: null,
    retailers: [],
} as AuthState;

export const authReducer: Reducer<AuthState, AuthActions> = reducer(
    initialState,
    on(authActions.resetState, (state) => {
        return {
            ...state,
            ...initialState,
            status: AuthStatus.Unauthenticated,
        };
    }),
    on(authActions.setAuthStatus, (state, { status, token }) => {
        const newState: AuthState = { ...state, status };
        if (typeof token !== 'undefined') {
            newState.token = token;
        }

        return newState;
    }),
    on(authActions.setAccount, (state, { account }) => {
        return {
            ...state,
            account,
        };
    }),
    on(authActions.setRetailers, (state, { retailers }) => {
        return {
            ...state,
            retailers,
        };
    }),
    on(authActions.updateToken, (state, { token }) => {
        return {
            ...state,
            token,
        };
    })
);
