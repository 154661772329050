import * as React from 'react';
import { categoryReducer, initialState, CategoryActionDispatcher } from './categories.state';
import { CategoriesWithArticles } from '../types';

type Props = {
    categories: Array<CategoriesWithArticles>;
};

export const defaultCategories: Array<CategoriesWithArticles> = [];

export const CategoriesContext = React.createContext(defaultCategories);
export const CategoriesDispatchContext = React.createContext<undefined | CategoryActionDispatcher>(undefined);

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setCategories] = React.useReducer(categoryReducer, initialState);

    return (
        <CategoriesContext.Provider value={state.categories}>
            <CategoriesDispatchContext.Provider value={setCategories}>{children}</CategoriesDispatchContext.Provider>
        </CategoriesContext.Provider>
    );
};
