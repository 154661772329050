import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertBar, Button, DynamicJSX, Icon, TextBadge, Typography } from '@packages/ui/shared';

import { RequestFeedbackModal, SendFeedbackModal } from '@web/feedback/components';
import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';

import { feedbackService } from '@web/services/singletons';
import { FeedbackGroupType, FeedbackSession, FeedbackSessionStatusId } from '@packages/models/api';
import { useAbortController } from '@packages/core/http';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useQuery from '@web/core/hooks/use-query';
import { useAuthState } from '@packages/contexts/auth';

interface FeedbackTableRowModel extends FeedbackSession {
    rowId: string;
}

export const FeedbackBrowser: FC = () => {
    const { t } = useTranslation();
    const { abortSignalRef, signalAbort } = useAbortController();
    const { account } = useAuthState();
    const navigate = useNavigate();
    const location = useLocation();
    const pageQueryParams = useQuery();
    const groupType = (pageQueryParams.get('groupType') as FeedbackGroupType) || FeedbackGroupType.All;
    const entityId = pageQueryParams.get('entityId') || '';

    const [showRequestFeedbackModal, setShowRequestFeedbackModal] = useState(false);
    const [showFeedbackRequestedAlert, setShowFeedbackRequestedAlert] = useState(false);

    const [showSendFeedbackModal, setShowSendFeedbackModal] = useState(false);
    const [showFeedbackSentAlert, setShowFeedbackSentAlert] = useState(
        !!location.state && !!location.state.feedbackSent
    );

    const [isLoading, setIsLoading] = useState(false);
    const [feedbackRows, setFeedbackRows] = useState<FeedbackTableRowModel[]>([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);

    const fetchData = useCallback(async () => {
        signalAbort();
        setIsLoading(true);

        try {
            const sessionsResponse = await feedbackService.fetchFeedbackSessions({
                groupType,
                entityId,
                page,
                size,
                signal: abortSignalRef.current,
            });

            if (!sessionsResponse.success && sessionsResponse.aborted) {
                return;
            } else if (!sessionsResponse.success) {
                throw sessionsResponse.data;
            }

            setTotal(sessionsResponse.data.totalCount);
            setFeedbackRows(
                sessionsResponse.data.sessions.map((session) => {
                    return {
                        rowId: session.feedbackSessionId,
                        ...session,
                    };
                })
            );
        } catch (error) {
            window.alert(error.message);
        }

        setIsLoading(false);
    }, [abortSignalRef, entityId, groupType, page, signalAbort, size]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            {account?.feedbackCapabilities.giveOrRequestFeedback && (
                <>
                    <RequestFeedbackModal
                        show={showRequestFeedbackModal}
                        onHide={() => setShowRequestFeedbackModal(false)}
                        onFeedbackRequested={() => {
                            setShowRequestFeedbackModal(false);
                            setShowFeedbackRequestedAlert(true);
                            fetchData();
                        }}
                    />

                    <SendFeedbackModal
                        show={showSendFeedbackModal}
                        onHide={() => setShowSendFeedbackModal(false)}
                        onFeedbackSent={(feedbackSessionId) => {
                            setShowSendFeedbackModal(false);

                            navigate(`/feedback/${feedbackSessionId}`);
                        }}
                    />

                    <AlertBar
                        success
                        show={showFeedbackRequestedAlert}
                        onClose={() => {
                            setShowFeedbackRequestedAlert(false);
                        }}
                    >
                        <Typography variant="h5">
                            {t('feedback:browser.alerts.feedbackRequested', 'Feedback Request Sent')}
                        </Typography>
                    </AlertBar>

                    <AlertBar
                        success
                        show={showFeedbackSentAlert}
                        onClose={() => {
                            navigate(location.pathname, { replace: true });
                            setShowFeedbackSentAlert(false);
                        }}
                    >
                        <Typography variant="h5">
                            {t('feedback:browser.alerts.feedbackSent', 'Feedback Sent')}
                        </Typography>
                    </AlertBar>
                </>
            )}

            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <div className="mb-6 d-flex justify-content-between">
                        <Typography variant="h2">{t('feedback:browser.title', 'Feedback')}</Typography>
                        {account?.feedbackCapabilities.giveOrRequestFeedback && (
                            <div className="d-flex">
                                <Button
                                    onPress={() => setShowRequestFeedbackModal(true)}
                                    iconRight={<Icon color="white" name="arrow-back" />}
                                >
                                    {t('feedback:browser.actions.requestFeedback', 'Request Feedback')}
                                </Button>

                                <Button
                                    style={{ marginLeft: 8 }}
                                    onPress={() => setShowSendFeedbackModal(true)}
                                    iconRight={<Icon color="white" name="arrow-forward" />}
                                >
                                    {t('feedback:browser.actions.sendFeedback', 'Send Feedback')}
                                </Button>
                            </div>
                        )}
                    </div>

                    <TableRenderer<FeedbackTableRowModel>
                        isLoading={isLoading}
                        tableRowsData={feedbackRows}
                        tableRowsDataSetter={setFeedbackRows}
                        tableHeaderRowRenderer={() => {
                            return (
                                <TableRow>
                                    <TableHeader sortable onSort={() => {}}>
                                        <Typography variant="label">
                                            {t('feedback:browser.headers.feedback', 'Feedback')}
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader sortable onSort={() => {}}>
                                        <Typography variant="label">
                                            {t('feedback:browser.headers.date', 'Date')}
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader sortable onSort={() => {}}>
                                        <Typography variant="label">
                                            {t('feedback:browser.headers.status', 'Status')}
                                        </Typography>
                                    </TableHeader>
                                </TableRow>
                            );
                        }}
                        tableBodyRowRenderer={(rowData) => {
                            return (
                                <TableRow key={rowData.rowId}>
                                    <TableCell>
                                        <div>
                                            <Typography numberOfLines={1}>
                                                <Link to={`/feedback/${rowData.feedbackSessionId}`}>
                                                    {rowData.bodyTitle}
                                                </Link>
                                            </Typography>
                                        </div>
                                        <div>
                                            <DynamicJSX jsx={rowData.bodyHtml} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>
                                            {rowData.feedbackSessionCreatedTimestampDescription}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <div className="d-inline-block">
                                            <TextBadge
                                                variant={
                                                    rowData.feedbackSessionStatusId ===
                                                    FeedbackSessionStatusId.AwaitingResponse
                                                        ? 'yellow'
                                                        : 'green'
                                                }
                                            >
                                                {rowData.feedbackSessionStatusIdDescription}
                                            </TextBadge>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />

                    <TablePagination {...{ page, size, total }} onClick={setPage} onSizeChange={setSize} />
                </div>
            </div>
        </>
    );
};
