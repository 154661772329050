import { WorksheetAction, WorksheetMode } from '../types';
import {
    baseWorksheetInfo,
    saveWorksheet,
    useWorksheetDispatch,
    useWorksheetState,
} from '@packages/contexts/worksheets';
import { useState, useMemo, useEffect, useCallback } from 'react';
import {
    checkAllAttributeElementEqual,
    checkElementIsEqual,
    checkOptionsIsEqual,
    getConditionalParentElementIndex,
    manageElement,
    prepareFormElementsWithOrder,
    removeUnnecessaryPropertiesFromElement,
} from '../utils/worksheet-form';
import { isEmpty } from 'lodash';
import useWorksheetData from '../hooks/use-worksheet-data';
import {
    CreateWorksheetFormRequestBody,
    DraggableWorksheetFormComponent,
    UpdateWorksheetFormRequestBody,
    WorksheetFormElement,
    WorksheetForm,
    ExistingElements,
    AddVersionWorksheetFormRequestBody,
    WorksheetFormDto,
    CreateOverlayAsset,
    DeleteAssetBody,
} from '@packages/models/api/worksheets';
import { createWorksheet } from '../dto/create.worksheet.dto';
import { storageService, worksheetsService } from '@web/services/singletons';
import uuid from 'uuid';
import { differenceBy, cloneDeep } from 'lodash';
import { FormElement, FormElementAction, FormElementFile } from '@packages/models/api';
import { useAuthState } from '@packages/contexts/auth';
import useWorksheetFormTranslation from '../hooks/use-worksheet-form-translation';
import { useNavigate } from 'react-router-dom';
import { isConditionalElementType } from '../utils';
import { createDtoChangeCaption, findAssetsAndDeleteDto, getAssetDto } from '../utils/worksheet-assets';
import { checkAssetUploading, fetchAssets, processResponses, updateElements } from '../utils/refetch-assets';
interface Props {
    worksheetFormId: string | undefined;
    worksheetAction?: WorksheetAction;
    maxVersion?: number;
}

interface WorksheetSaveAndExitParameters {
    setModalBodyMessage: (value: React.SetStateAction<string>) => void;
    setCreatedWorksheet: (value: React.SetStateAction<boolean>) => void;
    setIsLoading: (
        value: React.SetStateAction<{
            saveAndExit: boolean;
            save: boolean;
        }>
    ) => void;
    setShowCreateWorksheet: (value: React.SetStateAction<boolean>) => void;
    isExit: boolean;
    mode: WorksheetMode;
}

interface UpdateAddVersionParameters {
    newWorksheet: CreateWorksheetFormRequestBody;
    worksheetSettings: {
        worksheetId: string;
        caseType: string;
        worksheetGroupId: string;
        existingElements: ExistingElements[] | undefined;
        oldWorksheetElements?: WorksheetFormElement[];
    };
}

let isRunning = true;

const useWorksheetFormDetails = ({ worksheetFormId, worksheetAction, maxVersion }: Props) => {
    const [elements, setElements] = useState<DraggableWorksheetFormComponent[]>([]);
    const [worksheetInfo, setWorkSheetInformation] = useState<WorksheetForm>(baseWorksheetInfo);
    const { modalDataTranslate } = useWorksheetFormTranslation();
    const [worksheetDisplayVersion, setWorksheetDisplayVersion] = useState(1);
    const [refetchAsset, setRefetchAsset] = useState(false);
    const navigate = useNavigate();

    const modalBodyMessage = useMemo((): { [x in WorksheetAction]: string } => {
        const messages = modalDataTranslate.createOrUpdateWorksheet.bodyMessage.succuss;
        return {
            [WorksheetAction.New]: messages.create,
            [WorksheetAction.AddVersion]: messages.addVersion,
            [WorksheetAction.Edit]: messages.update,
        };
    }, [modalDataTranslate]);

    //permission for api calls
    const { account } = useAuthState();
    const permissions = useMemo(() => {
        const worksheetPermissions = {
            createWorksheet: false,
            editWorksheet: false,
        };
        if (account) {
            const {
                techlineCapabilities: { canCreateWorkSheetsTechlineCase, canEditWorkSheetsTechlineCase },
            } = account;
            worksheetPermissions.createWorksheet = canCreateWorkSheetsTechlineCase;
            worksheetPermissions.editWorksheet = canEditWorkSheetsTechlineCase;
        }
        return worksheetPermissions;
    }, [account]);

    const worksheetDispatch = useWorksheetDispatch();
    const { worksheetData, fetching, actions } = useWorksheetData({
        worksheetFormId: worksheetFormId,
        worksheetAction: worksheetAction,
    });
    const worksheetState = useWorksheetState();
    const { worksheet } = worksheetState;

    const worksheetDetails = useMemo(() => {
        if (worksheet) {
            return worksheet;
        }
        return worksheetData;
    }, [worksheet, worksheetData]);

    const setWorkSheetInfo = useCallback((nextState: Partial<WorksheetForm>) => {
        setWorkSheetInformation((prevState) => {
            return { ...prevState, ...nextState };
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(worksheetDetails)) {
            setWorkSheetInfo({
                worksheetGroupName: worksheetDetails?.worksheetGroupName,
                worksheetId: worksheetDetails?.worksheetId,
                caseTypeId: worksheetDetails?.caseTypeId,
                caseType: worksheetDetails?.caseType,
                northstarId: worksheetDetails?.northstarId,
                description: worksheetDetails?.description,
                displayname: worksheetDetails?.displayname,
                createdAccount: worksheetDetails?.createdAccount,
                createdTimestampForDisplay: worksheetDetails?.createdTimestampForDisplay,
                createdTimestamp: worksheetDetails?.createdTimestamp,
                lastUpdatedTimestamp: worksheetDetails?.lastUpdatedTimestamp,
                lastUpdatedAccount: worksheetDetails?.lastUpdatedAccount,
                lastUpdatedTimestampForDisplay: worksheetDetails?.lastUpdatedTimestampForDisplay,
                worksheetGroupId: worksheetDetails?.worksheetGroupId || '',
                version: worksheetAction === WorksheetAction.AddVersion ? maxVersion : worksheetDetails?.version,
                ...(worksheetAction !== WorksheetAction.New && {
                    existingElements: worksheetDetails?.existingElements,
                    oldWorksheetElements: worksheetDetails?.oldWorksheetElements || worksheetDetails?.elements,
                }),
            });
            setWorksheetDisplayVersion(worksheetDetails?.version || 1);
        }
    }, [maxVersion, setWorkSheetInfo, worksheetAction, worksheetDetails]);

    const checkEvery5Sec = useCallback(
        async (
            assets: { asset: FormElementFile; eleId: string; conditionalElementId?: string; optionId?: string }[],
            count: number = 0
        ) => {
            const responses = await fetchAssets(assets);
            const { completedAsset, uploadingAsset } = processResponses(responses, assets);

            if (completedAsset.length) {
                setElements((prev) => {
                    return updateElements(completedAsset, prev);
                });
            }
            if (uploadingAsset.length && count < 11 && isRunning) {
                setTimeout(() => {
                    checkEvery5Sec(uploadingAsset, count + 1);
                }, 5000);
            } else {
                isRunning = false;
            }
        },
        []
    );

    const pullAsset = useCallback(
        (elements: DraggableWorksheetFormComponent[]) => {
            if (refetchAsset) {
                return;
            }
            isRunning = true;
            setRefetchAsset(true);
            const allUploadingAssets: {
                asset: FormElementFile;
                eleId: string;
                conditionalElementId?: string;
                optionId?: string;
            }[] = [];
            elements.forEach((ele) => {
                allUploadingAssets.push(...checkAssetUploading(ele).map((d) => ({ ...d, eleId: ele.formElementId })));
                if (ele.optionConditionsElements?.length) {
                    ele.optionConditionsElements.forEach((oce) => {
                        allUploadingAssets.push(
                            ...checkAssetUploading(oce).map((d) => ({
                                ...d,
                                eleId: ele.formElementId,
                                conditionalElementId: oce.formElementId,
                            }))
                        );
                    });
                }
            });
            checkEvery5Sec(allUploadingAssets);
        },
        [checkEvery5Sec, refetchAsset]
    );

    useEffect(() => {
        if (!isEmpty(worksheetDetails)) {
            const elements = prepareFormElementsWithOrder([...(worksheetDetails?.elements || [])]);
            setElements(elements);
            setTimeout(() => {
                pullAsset(elements);
            }, 5000);
        }
        return () => {
            isRunning = false;
        };
    }, [worksheetDetails?.elements, worksheetDetails]);

    const saveState = useCallback(
        async (worksheetData: WorksheetForm, worksheetElements: DraggableWorksheetFormComponent[]) => {
            const worksheetSettings = {
                ...worksheetData,
            };
            let worksheet: WorksheetForm = createWorksheet({ worksheetSettings, fromComponents: worksheetElements });
            if (worksheetFormId) {
                worksheet = {
                    ...worksheet,
                    worksheetGroupId: worksheetData.worksheetGroupId,
                    createdAccount: worksheetData.createdAccount,
                    lastUpdatedAccount: worksheetData.lastUpdatedAccount,
                    createdTimestampForDisplay: worksheetData.createdTimestampForDisplay,
                    lastUpdatedTimestampForDisplay: worksheetData.lastUpdatedTimestampForDisplay,
                    createdTimestamp: worksheetData.createdTimestamp,
                    lastUpdatedTimestamp: worksheetData.lastUpdatedTimestamp,
                    worksheetId: worksheetFormId,
                    oldWorksheetElements: worksheetSettings.oldWorksheetElements,
                    existingElements: worksheetSettings.existingElements,
                };
            }
            if (worksheetSettings.caseType) {
                await saveWorksheet({
                    worksheet: {
                        ...worksheet,
                        caseType: worksheetSettings.caseType,
                    },
                    storageService,
                    worksheetDispatch,
                });
            }
        },
        [worksheetDispatch, worksheetFormId]
    );

    const newOverlayAssets = useCallback(
        async (elements: { body: CreateOverlayAsset; file: File; parentIndex?: number }[]) => {
            try {
                if (elements.length) {
                    const cloudUrls = await worksheetsService.createAssets(elements.map((a) => a.body));
                    if (!cloudUrls.success) {
                        throw new Error(cloudUrls.data.message);
                    } else {
                        await Promise.all(
                            cloudUrls.data.assets.map((r, index) => {
                                const file = elements[index].file;
                                const url = r.overlayPresignedUpload.url;
                                const headers = r.overlayPresignedUpload.httpHeaders;
                                return worksheetsService.uploadAsset(url, headers, file!);
                            })
                        );
                    }
                }
            } catch (error) {
                throw error;
            }
        },
        []
    );

    const deleteAssets = useCallback(
        async (elements: DeleteAssetBody[]) => {
            try {
                return Promise.all(
                    elements.map((ele) => {
                        return worksheetsService.deleteAsset(ele, worksheetFormId!).then((res) => {
                            if (!res.success) {
                                throw new Error(res.data.message);
                            }
                        });
                    })
                );
            } catch (error) {
                throw error;
            }
        },
        [worksheetFormId]
    );

    const newAssets = useCallback(
        async (elements: FormElement[]) => {
            try {
                const { newAssets: assetDto, deleteAssets: deleteAssetsArr } = getAssetDto(elements);

                if (deleteAssetsArr.length) {
                    await deleteAssets(deleteAssetsArr);
                }

                const overlayElement: { body: CreateOverlayAsset; file: File; parentIndex?: number }[] = [];
                const filterWithoutOverlayElement = assetDto.filter((ele, index) => {
                    if (ele.body.hasOverlay) {
                        overlayElement.push({ ...ele, parentIndex: index - 1 });
                        return false;
                    }
                    return true;
                });

                if (filterWithoutOverlayElement.length) {
                    const cloudUrls = await worksheetsService.createAssets(
                        filterWithoutOverlayElement.map((a) => a.body)
                    );
                    if (!cloudUrls.success) {
                        throw new Error(cloudUrls.data.message);
                    } else {
                        await Promise.all(
                            cloudUrls.data.assets.map((r, index) => {
                                const file = filterWithoutOverlayElement[index].file;
                                if (overlayElement.length) {
                                    const overlayEleIndex = overlayElement.findIndex(
                                        (ele) => ele.parentIndex === index
                                    );
                                    if (overlayEleIndex >= 0) {
                                        overlayElement[overlayEleIndex]['body']['replacesAssetId'] = r.asset.assetId;
                                    }
                                }
                                const url = r.presignedUpload.url;
                                const headers = r.presignedUpload.httpHeaders;
                                return worksheetsService.uploadAsset(url, headers, file!);
                            })
                        );
                    }
                }
                await newOverlayAssets(overlayElement);
            } catch (error) {
                throw error;
            }
        },
        [deleteAssets, newOverlayAssets]
    );

    const updateAssetCaption = useCallback(
        async (elements: FormElement[]) => {
            try {
                const changeCaptionAssets: {
                    worksheetElementOptionId?: string;
                    worksheetElementId?: string;
                    caption: string;
                    assetId: string;
                }[] = createDtoChangeCaption(elements);
                if (changeCaptionAssets.length) {
                    const res = await Promise.all(
                        changeCaptionAssets.map((data) => {
                            const { assetId, ...body } = data;
                            return worksheetsService.updateAsset(body, assetId!);
                        })
                    );
                    res.forEach((r) => {
                        if (!r.success) {
                            throw Error(r.data.message);
                        }
                    });
                }
            } catch (error) {
                console.log('Update caption error:', error);
                throw error;
            }
        },
        [worksheetFormId]
    );

    const updateWorksheetManageElementsAction = useCallback(
        (elements: WorksheetFormElement[], oldElements: WorksheetFormElement[]) => {
            // changeElementIds is an object where keys and values are element IDs
            const changeElementIds: { [x: string]: string } = {};
            /**
             * The 'assets' property is absent in the DTO element, hence it cannot be compared.
             *
             * Since 'formElementFile' and 'optionFile' are used exclusively for frontend operations
             * and are not present in the oldElement, we disregard these properties for comparison.
             */
            const elementsClone = elements.map((element) => {
                if (element.action !== FormElementAction.Insert) {
                    const oldElement = oldElements.find((ele) => ele.worksheetElementId === element.worksheetElementId);
                    if (oldElement) {
                        const equal = checkElementIsEqual(element, oldElement);
                        const asset = element.assets?.find((a) => a.assetId === element.formElementFile?.assetId);
                        // If element is not equal then check any changes related attribute so action replace.
                        // First priority action is replace.
                        if (equal || element.formElementFile?.file) {
                            element.action = FormElementAction.Replace;
                        }
                        // Check is any change in caption.
                        else if (equal || element.formElementFile?.caption !== asset?.caption) {
                            element.action = FormElementAction.Replace;
                        }
                        //Check is there any changes in option's attribute.
                        else {
                            //If element has not action then also check if option is added then action update.
                            if (!element.action && oldElement.options.length < element.options.length) {
                                element.action = FormElementAction.Update;
                            }

                            if (!checkOptionsIsEqual(element.options, oldElement.options)) {
                                element.action = FormElementAction.Replace;
                            }
                        }
                    }
                }
                if (element.action) {
                    changeElementIds[element.worksheetElementId] = element.worksheetElementId;
                }
                return element;
            });

            if (Object.values(changeElementIds).length) {
                // Check if any conditional element has changed, if so, set the parent element action to replace.
                const conditionElementsIndexes = getConditionalParentElementIndex(elementsClone, changeElementIds);
                conditionElementsIndexes.forEach((idx) => {
                    elementsClone[idx].action = FormElementAction.Replace;
                });
            }
            // Filter which element has changes.
            return elementsClone.filter((ele) => ele.action);
        },
        []
    );

    const updateWorksheet = useCallback(
        async ({ newWorksheet, worksheetSettings }: UpdateAddVersionParameters) => {
            const newWorksheetClone = cloneDeep(newWorksheet);
            const worksheetSettingsClone = cloneDeep(worksheetSettings);

            const newElements = updateWorksheetManageElementsAction(
                newWorksheetClone.elements,
                worksheetSettingsClone.oldWorksheetElements || []
            );

            // Filter the deleted elements
            const deletedElements = differenceBy(
                worksheetSettingsClone.oldWorksheetElements,
                newWorksheetClone.elements,
                'worksheetElementId'
            ).map((element) => ({ ...element, action: FormElementAction.Delete }));

            // Filter deleted options
            const deletedOptions = differenceBy(
                worksheetSettings.oldWorksheetElements
                    ?.filter((ele) => isConditionalElementType(ele.worksheetElementTypeId))
                    .map((ele) => ele.options)
                    .flatMap((opt) => opt),
                newWorksheet.elements
                    ?.filter((ele) => isConditionalElementType(ele.worksheetElementTypeId))
                    .map((ele) => ele.options)
                    .flatMap((opt) => opt),
                'optionId'
            );

            const deleteAssetElementDto = deletedElements.reduce((arr: DeleteAssetBody[], ele) => {
                if (ele.assets?.length) {
                    arr.push(findAssetsAndDeleteDto(ele.assets, ele.worksheetElementId));
                }

                if (isConditionalElementType(ele.worksheetElementTypeId)) {
                    ele.options.forEach((opt) => {
                        if (opt.assets?.length) {
                            arr.push(findAssetsAndDeleteDto(opt.assets, opt.optionId, true));
                        }
                    });
                }
                return arr;
            }, []);

            const deleteAssetOptionsDto = deletedOptions.reduce((arr: DeleteAssetBody[], opt) => {
                if (opt.assets?.length) {
                    arr.push(findAssetsAndDeleteDto(opt.assets, opt.optionId, true));
                }
                return arr;
            }, []);

            await deleteAssets([...deleteAssetElementDto, ...deleteAssetOptionsDto]);

            newWorksheetClone.elements = [...newElements, ...deletedElements];
            newWorksheet.elements = removeUnnecessaryPropertiesFromElement(newWorksheetClone.elements);
            let updatedWorksheet: UpdateWorksheetFormRequestBody = {
                ...newWorksheetClone,
                worksheetId: worksheetSettingsClone.worksheetId,
                caseType: worksheetSettingsClone.caseType,
                worksheetGroupId: worksheetSettingsClone.worksheetGroupId || '',
                existingElements: worksheetSettingsClone.existingElements,
            };
            return await worksheetsService.updateWorksheetForm(updatedWorksheet);
        },
        [deleteAssets, updateWorksheetManageElementsAction]
    );

    const addVersionWorksheetManageElementAction = useCallback(
        ({
            elements,
            oldElements,
            existingElements,
        }: {
            elements: WorksheetFormElement[];
            oldElements: WorksheetFormElement[];
            existingElements: ExistingElements[];
        }) => {
            const existingElementsClone: ExistingElements[] = cloneDeep(existingElements);
            const elementsClone: WorksheetFormElement[] = cloneDeep(elements);
            let changeElementIds: { [oldId: string]: string } = {};
            elementsClone.forEach((element) => {
                if (element.action !== FormElementAction.Insert) {
                    const oldElement = oldElements.find((ele) => ele.worksheetElementId === element.worksheetElementId);
                    if (oldElement) {
                        let equal = checkAllAttributeElementEqual(element, oldElement);
                        // If any change in element so update ids of that element and push in newElement
                        if (!equal) {
                            manageElement(element, changeElementIds);
                        }
                    }
                }
            });

            // Check if any changed in elements
            if (Object.keys(changeElementIds).length) {
                // Check if the changed element is a part of the element condition; if true, update the parent element.
                const conditionalParentsIndex = getConditionalParentElementIndex(elementsClone, changeElementIds);
                conditionalParentsIndex.forEach((idx) => {
                    //Check If parent component is part of the changeElementIds means in above process we already updated the uuid.
                    if (changeElementIds[elementsClone[idx].worksheetElementId]) {
                        elementsClone[idx].options.forEach((opt) => {
                            opt.trgtWorksheetElementIdList = opt.trgtWorksheetElementIdList.map(
                                (trgt) => changeElementIds[trgt] || trgt
                            );
                        });
                    } else {
                        manageElement(elementsClone[idx], changeElementIds);
                        elementsClone[idx].options.forEach((opt) => {
                            opt.optionId = uuid();
                            opt.trgtWorksheetElementIdList = opt.trgtWorksheetElementIdList.map(
                                (trgt) => changeElementIds[trgt] || trgt
                            );
                        });
                    }
                });

                // Get the deleted elements
                differenceBy(oldElements, elements, 'worksheetElementId').forEach((element) => {
                    changeElementIds[element.worksheetElementId] = element.worksheetElementId;
                });
                // If change in element than existing element isActive should be false
                existingElementsClone.forEach((ele) => {
                    if (changeElementIds[ele.elementId]) {
                        ele.isActive = false;
                        ele.elementId = changeElementIds[ele.elementId];
                    }
                });
            }

            const newElements: WorksheetFormElement[] = elementsClone.filter((ele) => ele.action);

            return { newElements, existingElementsClone };
        },
        []
    );

    const addVersionWorksheet = useCallback(
        async ({ newWorksheet, worksheetSettings }: UpdateAddVersionParameters) => {
            const newWorksheetClone = cloneDeep(newWorksheet);
            const worksheetSettingsClone = cloneDeep(worksheetSettings);

            const { newElements, existingElementsClone } = addVersionWorksheetManageElementAction({
                elements: newWorksheetClone.elements,
                oldElements: worksheetSettingsClone.oldWorksheetElements || [],
                existingElements: worksheetSettingsClone.existingElements || [],
            });

            newWorksheetClone.elements = removeUnnecessaryPropertiesFromElement(newElements, false);
            worksheetSettingsClone.existingElements = existingElementsClone;
            const body: AddVersionWorksheetFormRequestBody = {
                ...newWorksheetClone,
                version: newWorksheetClone.version + 1,
                worksheetId: worksheetSettingsClone.worksheetId,
                caseType: worksheetSettingsClone.caseType,
                worksheetGroupId: worksheetSettingsClone.worksheetGroupId || '',
                existingElements: worksheetSettingsClone.existingElements,
            };
            return await worksheetsService.addVersionWorksheetForm(body);
        },
        [addVersionWorksheetManageElementAction]
    );

    const manageSave = useCallback(
        async (
            response:
                | WorksheetFormDto
                | {
                      worksheet: WorksheetFormDto;
                  },
            mode: WorksheetMode
        ) => {
            // set empty worksheet
            await saveWorksheet({
                worksheet: undefined,
                storageService,
                worksheetDispatch,
            });
            if ([WorksheetAction.New, WorksheetAction.AddVersion].includes(worksheetAction!)) {
                const id = 'worksheet' in response ? response.worksheet.worksheetId : response.worksheetId;
                const status = 'worksheet' in response ? response.worksheet.worksheetStatus : response.worksheetStatus;
                const route =
                    mode === WorksheetMode.Edit
                        ? `/administration/worksheet/${id}/edit?action=EDIT&status=PENDING`
                        : `/administration/worksheet/${id}?action=${WorksheetAction.Edit}&status=${status}`;
                navigate(route);
            } else {
                isRunning = false;
                setRefetchAsset(false);
                await actions.fetchData();
            }
        },
        [actions, navigate, worksheetAction, worksheetDispatch]
    );

    const worksheetSaveAndExit = useCallback(
        async ({
            setModalBodyMessage,
            setCreatedWorksheet,
            setIsLoading,
            setShowCreateWorksheet,
            isExit,
            mode,
        }: WorksheetSaveAndExitParameters) => {
            const stopLoader = { save: false, saveAndExit: false };
            const checkPermission =
                worksheetAction === WorksheetAction.Edit ? !permissions.editWorksheet : !permissions.createWorksheet;

            if (checkPermission) {
                setModalBodyMessage(
                    worksheetAction === WorksheetAction.Edit
                        ? modalDataTranslate.commonBodyMessage.permission.edit
                        : modalDataTranslate.commonBodyMessage.permission.create
                );
                setShowCreateWorksheet(true);
                return;
            }
            setIsLoading({
                saveAndExit: isExit,
                save: !isExit,
            });
            try {
                const worksheetSettings = cloneDeep(worksheetInfo);
                const elementsClone = cloneDeep(elements);
                let newWorksheet = createWorksheet({
                    worksheetSettings,
                    fromComponents: elementsClone,
                });
                await saveState(worksheetSettings, elementsClone);

                let response;
                if (
                    worksheetSettings.worksheetId &&
                    worksheetSettings.caseType &&
                    worksheetAction !== WorksheetAction.New
                ) {
                    const updateWorksheetSetting = {
                        worksheetId: worksheetSettings.worksheetId,
                        caseType: worksheetSettings.caseType,
                        worksheetGroupId: worksheetSettings.worksheetGroupId || '',
                        existingElements: worksheetSettings.existingElements,
                        oldWorksheetElements: worksheetSettings.oldWorksheetElements,
                    };
                    const body = { newWorksheet, worksheetSettings: updateWorksheetSetting };
                    const updatedElements = elementsClone.filter(
                        (ele) => ![FormElementAction.Insert, FormElementAction.Delete].includes(ele.action!)
                    );
                    response = await (worksheetAction === WorksheetAction.AddVersion
                        ? (async () => {
                              await updateAssetCaption(updatedElements);
                              return addVersionWorksheet(body);
                          })()
                        : (async () => {
                              await newAssets(updatedElements);
                              await updateAssetCaption(updatedElements);
                              return updateWorksheet(body);
                          })());
                } else {
                    newWorksheet.elements = removeUnnecessaryPropertiesFromElement(newWorksheet.elements);
                    response = await worksheetsService.createWorksheetForm(newWorksheet);
                }

                if (!response.success) {
                    setModalBodyMessage(response.data.message || modalDataTranslate.commonBodyMessage.somethingWrong);
                    setCreatedWorksheet(false);
                } else {
                    const filterElement =
                        worksheetAction === WorksheetAction.Edit
                            ? elements.filter((ele) => ele.action === FormElementAction.Insert)
                            : elements;
                    await newAssets(filterElement);
                    setModalBodyMessage(modalBodyMessage[worksheetAction!]);
                    setCreatedWorksheet(true);
                    if (isExit) {
                        setElements([]);
                    } else {
                        await manageSave(response.data, mode);
                    }
                }
                setIsLoading(stopLoader);
                setShowCreateWorksheet(true);
            } catch (error) {
                console.log(error);
                setModalBodyMessage(error.message || modalDataTranslate.commonBodyMessage.somethingWrong);
                setCreatedWorksheet(false);
                setIsLoading(stopLoader);
                setShowCreateWorksheet(true);
            }
        },
        [
            worksheetAction,
            permissions.editWorksheet,
            permissions.createWorksheet,
            modalDataTranslate.commonBodyMessage.permission.edit,
            modalDataTranslate.commonBodyMessage.permission.create,
            modalDataTranslate.commonBodyMessage.somethingWrong,
            worksheetInfo,
            elements,
            saveState,
            addVersionWorksheet,
            updateWorksheet,
            newAssets,
            modalBodyMessage,
            manageSave,
        ]
    );

    return {
        elements,
        fetching,
        worksheetData,
        worksheetInfo,
        worksheetDisplayVersion,
        setElements,
        actions,
        setWorkSheetInfo,
        worksheetSaveAndExit,
        saveState,
    };
};

export default useWorksheetFormDetails;
