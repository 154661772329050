import { FormElementFile } from '@packages/models/api';
import { worksheetsService } from '@web/services/singletons';
import { AssetProcessingStateId, DraggableWorksheetFormComponent } from '@packages/models/api/worksheets';
import { findLatest } from './worksheet-form';
import { cloneDeep } from 'lodash';
import { isConditionalElementType } from '../utils';

export const isAssetUploading = (asset: FormElementFile) => {
    return asset.assetId && asset.status === AssetProcessingStateId.UPLOADING;
};

export const checkAssetUploading = (ele: DraggableWorksheetFormComponent) => {
    const allUploadingAssets: {
        asset: FormElementFile;
        optionId?: string;
    }[] = [];

    if (ele.formElementFile && isAssetUploading(ele.formElementFile)) {
        allUploadingAssets.push({ asset: ele.formElementFile });
    }
    if (isConditionalElementType(ele.formElementTypeId)) {
        ele.options.forEach((opt) => {
            if (opt.optionFile && isAssetUploading(opt.optionFile)) {
                allUploadingAssets.push({ asset: opt.optionFile, optionId: opt.optionId });
            }
        });
    }
    return allUploadingAssets;
};

export const fetchAssets = async (
    assets: { asset: FormElementFile; eleId: string; conditionalElementId?: string; optionId?: string }[]
) => {
    return Promise.all(assets.map((a) => worksheetsService.fetchAsset(a.asset.assetId!)));
};

export const processResponses = (
    responses: any[],
    assets: { asset: FormElementFile; eleId: string; conditionalElementId?: string; optionId?: string }[]
) => {
    const completedAsset: {
        asset: FormElementFile;
        eleId: string;
        conditionalElementId?: string;
        optionId?: string;
    }[] = [];
    const uploadingAsset: {
        asset: FormElementFile;
        eleId: string;
        conditionalElementId?: string;
        optionId?: string;
    }[] = [];

    responses.forEach((res, indx) => {
        if (res.success) {
            if (res.data.asset.assetProcessingStateId === AssetProcessingStateId.COMPLETE) {
                const dtoAsset = { ...assets[indx], asset: findLatest([res.data.asset]) };
                completedAsset.push(dtoAsset);
            } else {
                uploadingAsset.push(assets[indx]);
            }
        }
    });

    return { completedAsset, uploadingAsset };
};

export const updateElements = (
    completedAsset: { asset: FormElementFile; eleId: string; conditionalElementId?: string; optionId?: string }[],
    prevState: DraggableWorksheetFormComponent[]
) => {
    const clonePrev = cloneDeep(prevState);
    completedAsset.forEach((ca) => {
        const findIndex = clonePrev.findIndex((e) => e.formElementId === ca.eleId);
        if (findIndex > -1) {
            if (ca.conditionalElementId && clonePrev[findIndex].optionConditionsElements) {
                //@ts-ignore
                const conditionalIndex = clonePrev[findIndex].optionConditionsElements.findIndex(
                    (oc) => oc.formElementId === ca.conditionalElementId
                );
                if (conditionalIndex > -1) {
                    if (ca.optionId) {
                        //@ts-ignore
                        clonePrev[findIndex].optionConditionsElements[conditionalIndex].options.forEach((opt) => {
                            if (opt.optionId === ca.optionId) {
                                opt.optionFile = ca.asset;
                            }
                        });
                    } else {
                        //@ts-ignore
                        clonePrev[findIndex].optionConditionsElements[conditionalIndex].formElementFile = ca.asset;
                    }
                }
            } else {
                if (ca.optionId) {
                    clonePrev[findIndex].options.forEach((opt) => {
                        if (opt.optionId === ca.optionId) {
                            opt.optionFile = ca.asset;
                        }
                    });
                } else {
                    clonePrev[findIndex].formElementFile = ca.asset;
                }
            }
        }
    });
    return clonePrev;
};
