import React, { FC, useState } from 'react';
import { useLocation, Link, LinkProps, useMatch } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Scrollspy from 'react-scrollspy';
import { useTranslation } from 'react-i18next';

import colors from '@packages/core/styles/colors';

import {
    useQmrState,
    BaseQmrReportSection,
    QmrReportSectionStatus,
    getQmrRetailerSection,
    getQmrVehicleDetailsSection,
    getQmrMediaSection,
    getQmrConcernSection,
    getQmrCauseSection,
    getQmrCorrectionSection,
    getQmrDtcCodesSection,
    getQmrFailCodeSection,
    getQmrAdditionalInfoSection,
    getQmrOutcome,
} from '@packages/contexts/qmrs';
import { Icon, StsIconName, Typography } from '@packages/ui/shared';
import { useQmrAssetUploads } from '@packages/contexts/asset-uploads';
import NavigationItem from '@web/components/navigation-item';
import { QmrStatusId } from '@packages/models/api';

const useQmrReportSectionsStyles = createUseStyles({
    reportSectionItem: {
        position: 'relative',
        padding: '10px 20px',
        color: colors.textDarkPrimary,
        borderLeft: '2px solid transparent',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    reportSectionItemActive: {
        backgroundColor: colors.blueFour,
        borderLeftColor: colors.grayThree,
    },
    reportSectionItemComplete: {
        borderLeftColor: colors.blueOne,
    },
    reportSectionItemError: {
        borderLeftColor: colors.redOne,
    },
    sectionIcon: {
        marginRight: 16,
        zIndex: 2,
    },
    sectionVerticalLine: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 20 + 10 - 1, // item padding + (1/2 icon size) - (1/2 line width)
        width: 2,
        backgroundColor: colors.grayThree,
    },
    firstReportSectionItem: {
        '& $sectionVerticalLine': {
            top: 10 + 10, // item padding + (1/2 icon size),
        },
    },
    lastReportSectionItem: {
        '& $sectionVerticalLine': {
            bottom: 'initial',
            height: 10 + 10, // item padding + (1/2 icon size),
        },
    },
});

interface QmrReportSectionsProps {
    checklistContainerId: string;
    checklistSectionIds: string[];
}

interface QmrReportSection extends BaseQmrReportSection {
    id: string;
    title: string;
    to: LinkProps['to'];
    isHidden?: boolean;
}

const QmrReportSections: FC<QmrReportSectionsProps> = ({ checklistContainerId, checklistSectionIds }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const classes = useQmrReportSectionsStyles();
    const { qmr } = useQmrState();
    const { failedUploads } = useQmrAssetUploads(qmr?.qmrId);
    const { inProgressUploads } = useQmrAssetUploads(qmr?.qmrId);
    const [isSectionsExpanded, setIsSectionsExpanded] = useState(true);
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');

    const submitAttempted = false;
    const data = { qmr, submitAttempted };
    const retailerSection = getQmrRetailerSection(data);
    const vehicleSection = getQmrVehicleDetailsSection(data);
    const mediaSection = getQmrMediaSection({
        ...data,
        numUploading: inProgressUploads.length,
        numUploadErrors: failedUploads.length,
    });
    const concernSection = getQmrConcernSection(data);
    const causeSection = getQmrCauseSection(data);
    const correctionSection = getQmrCorrectionSection(data);
    const dtcSection = getQmrDtcCodesSection(data);
    const failSection = getQmrFailCodeSection(data);
    const additionalInfoSection = getQmrAdditionalInfoSection(data);
    const qmrOutCome = getQmrOutcome(data);

    const [spySectionId, setSpySectionId] = useState('');

    const sections: QmrReportSection[] = [
        {
            id: checklistSectionIds[0],
            title: t('qmr:sections.vehicleDetails.title'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[0],
            },
            ...vehicleSection,
        },
        {
            id: checklistSectionIds[1],
            title: t('qmr:sections.concern.title'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[1],
            },
            ...concernSection,
        },
        {
            id: checklistSectionIds[2],
            title: t('qmr:sections.cause.title'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[2],
            },
            ...causeSection,
        },
        {
            id: checklistSectionIds[3],
            title: t('qmr:sections.correction.title'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[3],
            },
            ...correctionSection,
        },
        {
            id: checklistSectionIds[4],
            title: t('qmr:sections.dtcCodes.title'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[4],
            },
            ...dtcSection,
        },
        {
            id: checklistSectionIds[5],
            title: t('qmr:sections.failCode.title'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[5],
            },
            ...failSection,
        },
        {
            id: checklistSectionIds[6],
            isHidden: !qmr?.capabilities.viewMediaOnQmr,
            title: t('qmr:sections.media.title'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[6],
            },
            ...mediaSection,
        },
        {
            id: checklistSectionIds[7],
            isHidden: qmr?.qmrStatus.qmrStatusId === QmrStatusId.Draft || !!editRouteMatch,
            title: t('qmr:sections.additionalInfo.title', 'Additional Information'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[7],
            },
            ...additionalInfoSection,
        },
        {
            id: checklistSectionIds[8],
            isHidden: !qmr?.outcomeAvailable || !!editRouteMatch,
            title: t('qmr:sections.qmrOutcome.title', 'QMR Outcome'),
            to: {
                ...location,
                hash: '#' + checklistSectionIds[8],
            },
            ...qmrOutCome,
        },
    ];

    return (
        <NavigationItem
            iconName="file"
            title={t('qmr:sections.title', 'Report')}
            isActive
            open={isSectionsExpanded}
            onCollapseToggle={() => setIsSectionsExpanded(!isSectionsExpanded)}
            onClick={() => setIsSectionsExpanded(!isSectionsExpanded)}
            mainNav={false}
        >
            <Scrollspy
                rootEl={'#' + checklistContainerId}
                componentTag={'div'}
                items={checklistSectionIds}
                currentClassName={classes.reportSectionItemActive}
                onUpdate={
                    ((item: HTMLElement) => {
                        setSpySectionId(item.id);
                    }) as any
                }
            >
                {sections
                    .filter((section) => !section.isHidden)
                    .map((section, index, filteredSections) => {
                        const isFirst = index === 0;
                        const isLast = index === filteredSections.length - 1;

                        const isCompleted = section.status === QmrReportSectionStatus.Completed;
                        const isSpyId = spySectionId === section.id;

                        return (
                            <Link
                                key={section.id}
                                replace
                                to={section.to}
                                className={classNames(['d-flex', classes.reportSectionItem], {
                                    [classes.reportSectionItemComplete]: isSpyId && isCompleted,
                                    [classes.reportSectionItemError]: section.status === QmrReportSectionStatus.Error,
                                    [classes.firstReportSectionItem]: isFirst,
                                    [classes.lastReportSectionItem]: isLast,
                                })}
                            >
                                <div className={classes.sectionVerticalLine} />
                                <div className={classes.sectionIcon}>
                                    <Icon
                                        size={20}
                                        name={section.iconName as StsIconName}
                                        color={!isCompleted && isSpyId ? 'white' : section.iconColor}
                                    />
                                </div>

                                <div className="d-flex flex-column overflow-hidden">
                                    <Typography variant="label" numberOfLines={1} ellipsizeMode="head">
                                        {section.title}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color="textDarkSecondary"
                                        numberOfLines={1}
                                        ellipsizeMode="head"
                                    >
                                        {section.description}
                                    </Typography>
                                </div>
                            </Link>
                        );
                    })}
            </Scrollspy>
        </NavigationItem>
    );
};

export default QmrReportSections;
