import { useTranslation } from 'react-i18next';
import React, { FC, useCallback, useImperativeHandle, useRef } from 'react';
import { Menu, MenuItem, Typeahead, TypeaheadState } from 'react-bootstrap-typeahead';
import { createUseStyles } from 'react-jss';

import { Icon, Typography, useHoverState } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    searchIcon: {
        position: 'absolute',
        right: 8,
        top: 8,
        zIndex: 2,
        backgroundColor: colors.white,
    },
    recentSearchMenu: {
        display: 'flex !important',
        flexDirection: 'column !important',
        padding: '0 !important',
    },
    recentSearchesList: {
        overflow: 'auto',
    },
    recentSearchHeader: {
        padding: '8px 16px',
    },
    recentSearchFooter: {
        padding: '16px 24px',
        borderTop: `1px solid ${colors.grayThree}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '&:hover *': {
            cursor: 'pointer',
        },
    },
});

interface RecentSearchTypeaheadProps {
    defaultInputValue?: string;
    onChange(value: string): void;
    placeholder?: string;
    recentSearches: string[];
    quickSearchQuery: string;
}

const RecentSearchTypeahead = React.forwardRef<React.LegacyRef<Typeahead<string>>, RecentSearchTypeaheadProps>(
    ({ defaultInputValue, onChange, placeholder, recentSearches, quickSearchQuery }, ref) => {
        const searchInputRef = useRef(null);
        const classes = useStyles();
        const { t } = useTranslation();

        useImperativeHandle(ref, () => searchInputRef.current as any, []);

        const handleChange = useCallback(
            (selected: string) => {
                onChange(selected.trim());

                if (searchInputRef.current) {
                    (searchInputRef.current as any).hideMenu();
                    (searchInputRef.current as any).inputNode.blur();
                }
            },
            [onChange]
        );

        return (
            <Typeahead
                ref={searchInputRef}
                id="qmr-quick-search"
                defaultInputValue={defaultInputValue}
                onChange={(selected) => {
                    if (!selected[0]) {
                        return;
                    }
                    handleChange(selected[0]);
                }}
                filterBy={() => true}
                onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                        handleChange((searchInputRef.current as any).inputNode.value);
                    }
                }}
                placeholder={placeholder}
                renderMenu={(results, menuProps, ...args) => {
                    const typeaheadState = (args as any)[0] as TypeaheadState<any>;

                    return (
                        <Menu {...menuProps} className={classes.recentSearchMenu}>
                            <div className={classes.recentSearchesList}>
                                {results.length > 0 ? (
                                    <div className={classes.recentSearchHeader}>
                                        <Typography variant="caption">
                                            {t('views:qmrs.recentSearches.label', 'Recent Searches')}
                                        </Typography>
                                    </div>
                                ) : null}

                                {results.map((result, index) => (
                                    <RecentSearchItem key={index} {...{ result, index }} />
                                ))}
                            </div>

                            <div
                                className={classes.recentSearchFooter}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleChange((searchInputRef.current as any).inputNode.value);
                                }}
                            >
                                <span className="mr-2">
                                    <Typography color={typeaheadState.text ? 'blueOne' : 'textDarkSecondary'}>
                                        {t('views:qmrs.recentSearches.hintEnter', 'Enter')}
                                    </Typography>
                                </span>
                                <Icon
                                    name="keyboard-return"
                                    color={typeaheadState.text ? 'blueOne' : 'textDarkSecondary'}
                                />
                            </div>
                        </Menu>
                    );
                }}
                options={recentSearches}
            >
                <div className={classes.searchIcon}>
                    <Icon
                        name={quickSearchQuery ? 'x-close' : 'magnifying-glass'}
                        onPress={() => {
                            if (quickSearchQuery) {
                                handleChange('');
                                (searchInputRef.current as any).clear();
                                return;
                            }

                            (searchInputRef.current as any).inputNode.focus();
                        }}
                    />
                </div>
            </Typeahead>
        );
    }
);

const RecentSearchItem: FC<{ result: any; index: number }> = ({ result, index }) => {
    const { isHovered, hoverEventHandlers } = useHoverState();
    return (
        <MenuItem {...hoverEventHandlers} option={result} position={index}>
            <div className="d-flex align-items-center">
                <span className="mr-2">
                    <Icon name="history" color={isHovered ? 'white' : 'textDarkPrimary'} />
                </span>
                <Typography color={isHovered ? 'white' : 'textDarkPrimary'}>{result}</Typography>
            </div>
        </MenuItem>
    );
};

export default RecentSearchTypeahead;
