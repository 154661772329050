import * as firebase from 'firebase/app';
import { getMessaging, Messaging, onMessage } from 'firebase/messaging';
import { useEffect, useRef } from 'react';

export function usePushNotifications(pushNotifcationsHander: (data: any) => void) {
    const messagingRef = useRef<Messaging>();

    useEffect(() => {
        if (messagingRef.current) {
            return;
        }

        try {
            messagingRef.current = getMessaging();
        } catch (e) {
            return;
        }

        return () => {
            messagingRef.current = undefined;
        };
    }, []);

    useEffect(() => {
        if (!messagingRef.current) {
            return;
        }

        const teardown = onMessage(messagingRef.current, (m) => {
            if (!m.data) {
                // No message data
                return;
            }
            pushNotifcationsHander(m.data);
        });

        return () => {
            teardown();
        };
    }, [pushNotifcationsHander]);
}
