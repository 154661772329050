import React, { FC, useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { useAbortController } from '@packages/core/http';
import { groupBy } from 'lodash';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import { failCodesService } from '@web/services/singletons';
import { useTranslation } from 'react-i18next';
import { Typography } from '@packages/ui/shared';
import { FailCodes } from '@packages/models/api/fail-codes';

interface FailCodeTeamsSearchBarProps {
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOption: any;
    setSelectedOption: React.Dispatch<React.SetStateAction<any>>;
    selectedTeamName?: string;
    setSearchFailCodeFlag?: React.Dispatch<React.SetStateAction<boolean>>;
    searchExportFailCode?: FailCodes[];
    setSearchExportFailCode?: React.Dispatch<React.SetStateAction<any>>;
}

export const FailCodeTeamsSearchBar: FC<FailCodeTeamsSearchBarProps> = ({
    isLoading,
    setIsLoading,
    selectedOption,
    setSelectedOption,
    selectedTeamName,
    setSearchFailCodeFlag,
    searchExportFailCode,
    setSearchExportFailCode,
}) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const searchInputRef = useRef<AsyncTypeahead<any>>(null);
    const [options, setOptions] = useState<Array<any>>([]);

    const queryFailCodes = useCallback(
        (query: string) => {
            setIsLoading(true);

            failCodesService
                .searchFailCode({
                    searchFailCodeParams: {
                        query: query,
                    },
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                })
                .then((response) => {
                    setIsLoading(false);
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }
                    let failCodes = response.data.failCodes;

                    if (selectedTeamName && selectedTeamName !== 'missing Fail Codes') {
                        failCodes = failCodes.filter((failCode) => failCode.grouping == selectedTeamName);
                    } else if (selectedTeamName === 'missing Fail Codes') {
                        failCodes = failCodes.filter((failCode) => failCode.grouping == undefined);
                    }
                    setOptions(failCodes);
                    const el = document.getElementById('fail-code-search');
                    if (!el) {
                        searchInputRef.current?.toggleMenu();
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        },
        [abortSignalRef]
    );

    return (
        <AsyncTypeahead
            ref={searchInputRef}
            id="fail-code-search"
            onChange={([selected]) => {
                setSelectedOption(selected);
                if (selectedTeamName && setSearchFailCodeFlag) {
                    setSearchFailCodeFlag(true);
                }
                if (searchExportFailCode && setSearchExportFailCode) {
                    setSearchExportFailCode([]);
                }
            }}
            onInputChange={() => {
                searchInputRef.current?.hideMenu();
            }}
            isLoading={isLoading}
            options={options}
            onSearch={queryFailCodes}
            labelKey={(o) => `${o.failCode}`}
            filterBy={() => true}
            useCache={false}
            selected={selectedOption ? [selectedOption] : []}
            renderMenu={(results, menuProps, state) => {
                let index = 0;
                const sections = groupBy(results, (item) => {
                    if (item.failCode) {
                        return t('modals:failCodeSearch.resultSections.failCodes', 'Fail Code');
                    } else {
                        return 'unknown';
                    }
                });

                const items = Object.keys(sections)
                    .filter((n) => n !== 'unknown')
                    .sort()
                    .map((sectionName) => {
                        return (
                            <Fragment key={sectionName}>
                                {index !== 0 && <Menu.Divider />}

                                <Menu.Header>
                                    <Typography variant="label">{sectionName}</Typography>
                                </Menu.Header>

                                {sections[sectionName].map((i) => {
                                    const item = (
                                        <MenuItem key={index} option={i} position={index}>
                                            <Highlighter search={state.text}>
                                                {`${i.failCode} - ${i.description}`}
                                            </Highlighter>
                                        </MenuItem>
                                    );

                                    index += 1;
                                    return item;
                                })}
                            </Fragment>
                        );
                    });

                return <Menu {...menuProps}>{items}</Menu>;
            }}
            placeholder={t('modals:failCodeSearch.searchPlacehodler', 'Search')}
        />
    );
};
