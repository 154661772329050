import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

interface DetailViewBodyProps {
    className?: string;
    children?: React.ReactNode;
}

const useDetailViewCodyStyles = createUseStyles({
    detailViewBody: {
        flex: 1,
        overflowY: 'auto',
        padding: '32px 30px',
    },
});

export const DetailViewBody: FC<DetailViewBodyProps> = ({ className, children }) => {
    const classes = useDetailViewCodyStyles();

    return <div className={classNames(classes.detailViewBody, className)}>{children}</div>;
};
