import { throttle } from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

interface Props {
    children: any;
    show: boolean;
    parentRef: React.RefObject<any>;
    handleMouseLeave: () => void;
    handleMouseEnter: () => void;
}

const WebWrapper: FC<Props> = ({ children, show, parentRef, handleMouseEnter, handleMouseLeave }) => {
    const ref = useRef<HTMLDivElement>(null);
    const portalContainerRef = useRef<HTMLDivElement | null>(null);
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    const throttledHandleResize = useRef(
        throttle(() => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }, 200)
    ).current;

    useEffect(() => {
        const handleResize = () => {
            throttledHandleResize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [throttledHandleResize]);

    useEffect(() => {
        if (!portalContainerRef.current) {
            portalContainerRef.current = document.createElement('div');
            document.body.appendChild(portalContainerRef.current);
        }

        return () => {
            if (portalContainerRef.current) {
                document.body.removeChild(portalContainerRef.current);
                portalContainerRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (ref.current && show) {
            const webContainerRef = ref.current;
            const parentContent = parentRef.current;
            const parentPosition = parentContent?.getBoundingClientRect();
            const { innerHeight, innerWidth } = window;
            const shouldAtTop = parentPosition && innerHeight - parentPosition.bottom < webContainerRef.offsetHeight;
            const style: React.CSSProperties = {
                zIndex: 9999,
                top: shouldAtTop
                    ? `${
                          parentPosition.top -
                          webContainerRef.offsetHeight +
                          parentPosition.height -
                          parentPosition.height
                      }px`
                    : `${parentPosition?.top + parentPosition.height}px`,
            };

            if (parentPosition) {
                const outSideWidthToWindow = innerWidth - (parentPosition.left + webContainerRef.offsetWidth);
                const shouldMoveToMoreLeft = innerWidth - parentPosition.left < webContainerRef.offsetWidth;
                style.left = shouldMoveToMoreLeft
                    ? `${parentPosition.left + outSideWidthToWindow - 10}px`
                    : `${parentPosition.left}px`;
            }

            Object.assign(webContainerRef.style, style);
        }
    }, [ref, show, parentRef, windowSize]);

    if (!portalContainerRef.current) {
        return null;
    }

    return ReactDOM.createPortal(
        <>
            <div
                ref={ref}
                className={`${show ? 'd-flex' : 'd-none'}`}
                style={{
                    overflow: 'hidden',
                    zIndex: 9999,
                    position: 'fixed',
                    top: 40,
                    borderRadius: 5,
                    border: '1px solid rgba(0, 0, 0, 0.18)',
                    boxShadow: '1px 8px 12px 4px rgba(0, 0, 0, 0.16)',
                    userSelect: 'none',
                    backgroundColor: '#fff',
                    padding: 20,
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {show && children}
            </div>
        </>,
        portalContainerRef.current
    );
};

export default WebWrapper;
