import { Typography } from '@packages/ui/shared/typography';
import Avatar from '@web/components/avatar';
import React, { FC } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface workSheetUserProps {
    style?: StyleProp<ViewStyle>;
    avatar?: { firstName?: string; lastName?: string; imageUrl?: string };
    label: string | JSX.Element;
    name: string;
    date?: string;
}

export const WorksheetUser: FC<workSheetUserProps> = ({ style, avatar, label, name, date }) => {
    return (
        <View style={[styles.userContainer, style]}>
            {typeof label === 'string' ? <Typography variant="h6">{label}</Typography> : <View>{label}</View>}

            <View style={styles.avatarWrapper}>
                <Avatar size={32} {...avatar} />
                <View style={avatar && styles.avatarNameWrapper}>
                    <Typography color="blueOne">{name}</Typography>

                    {!!date && <Typography variant="caption">{date}</Typography>}
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    userContainer: {
        minHeight: 82,
    },
    avatarWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8,
    },
    avatarNameWrapper: {
        marginLeft: 16,
    },
});
