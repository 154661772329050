import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';

import { useQmrState } from '@packages/contexts/qmrs';
import { qmrsService } from '@web/services/singletons';
import { Comment } from '@packages/models/api';
import { Button, Icon, TextareaHelper, TextInputHelper, Typography } from '@packages/ui/shared';
import { AdditionalInfoPopup } from '@packages/ui/qmr/popups';
import { QmrComment } from '@packages/ui/qmr';

export const AdditionalInformation: FC = () => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();

    const [commentInputValue, setCommentInputValue] = useState('');
    const [comments, setComments] = useState<Comment[]>(qmr ? qmr.additionalInfoComments : []);
    const [showModal, setShowModal] = useState(false);

    async function handleCommentSubmit() {
        try {
            if (!qmr) {
                throw new Error('No QMR was found. Cannot post additional information.');
            }

            const response = await qmrsService.addAdditionalInformation(qmr.qmrId, commentInputValue);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            const commentsClone = cloneDeep(comments);
            commentsClone.push(response.data.comment);

            setCommentInputValue('');
            setComments(commentsClone);
        } catch (error) {
            window.alert(error);
        }
    }

    return (
        <>
            <AdditionalInfoPopup
                show={showModal}
                onOkPress={() => setShowModal(false)}
                onModalHide={() => setShowModal(false)}
            />

            <div className="mb-7 d-flex justify-content-between align-items-center">
                <Typography variant="h4">Additional Information</Typography>
                <Button
                    variant="ghost-blue"
                    title={t('qmr:inputs.additionalInformation.hint', 'Who can see additional information?')}
                    iconLeft={<Icon name="info" color="blueOne" />}
                    onPress={() => setShowModal(true)}
                />
            </div>

            {comments.length > 0 ? (
                <>
                    {comments.map((comment) => {
                        return (
                            <QmrComment
                                key={comment.commentId}
                                hideAuthor={!qmr?.capabilities.canViewAdditionalInfoAuthorOnQmr}
                                authorTextVariant="bold"
                                comment={comment}
                            />
                        );
                    })}
                </>
            ) : (
                <div className="mb-4">
                    <Typography>
                        {t('qmr:report.additionalInformation.noData', 'No additional information has been submitted.')}
                    </Typography>
                </div>
            )}

            <hr className="mb-9" />

            {qmr?.capabilities.canAddAdditionalInfoToQmr && (
                <>
                    <div className="mt-3 bg-white">
                        <TextareaHelper
                            containerStyle={{ height: 80 }}
                            placeholder={t('qmr:sections.comments.newComment.placeholder', 'Add a comment...')}
                            value={commentInputValue}
                            onChangeText={setCommentInputValue}
                            multiline={true}
                        />
                    </div>

                    <div className="mt-2 d-flex justify-content-end">
                        <span className="mr-2">
                            <Button onPress={() => setCommentInputValue('')} variant="ghost-gray">
                                {t('qmr:sections.comments.newComment.actions.cancel', 'Cancel')}
                            </Button>
                        </span>

                        <Button variant="primary" onPress={handleCommentSubmit}>
                            {t('qmr:sections.comments.newComment.actions.addComment', 'Comment')}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
