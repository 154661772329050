import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
    NotificationsStateContext,
    NotificationsDispatchContext,
    fetchNotificationsSummary,
} from './notifications.context';
import { NotificationStatusId } from '@packages/models/api';
import { useAbortController } from '@packages/core/http';
import { NotificationsService } from './notifications.service';

export const useNotificationsState = () => {
    const state = useContext(NotificationsStateContext);

    if (state === undefined) {
        throw new Error('useNotificationsState must be used within a NotificationsProvider');
    }

    return state;
};

export const useNotificationsDispatch = () => {
    const dispatch = useContext(NotificationsDispatchContext);

    if (dispatch === undefined) {
        throw new Error('useNotificationsDispatch must be used within a NotificationsProvider');
    }

    return dispatch;
};

// We potentially want to make this method a global function. Right now, it has to be imported into the individual components that need it. But it would be nice if this function check is global check, any page that loads with the "notificationId" paramater in the URL will be passed here, instead of manually importing it to every component (QMR, feedback, CSV export, etc.)
// This function is used when an user opens an email and goes onto a specific link, so we mark the associated notification as "READ"
export const useMarkNotificationAsReadOnLoad = (notificationsService: NotificationsService) => {
    const location = useLocation();
    const notificationsDispatch = useNotificationsDispatch();
    const { abortSignalRef } = useAbortController();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const notificationId = searchParams.get('notificationId');

        if (notificationId) {
            markNotificationAsRead(notificationId);
        }
        // No dependencies: only need to check once if notificationId parameter exists on page load
    }, []);

    const markNotificationAsRead = async (notificationId: string) => {
        try {
            const response = await notificationsService.updateNotifications({
                notifications: [
                    {
                        notificationId: notificationId,
                        notificationStatusId: NotificationStatusId.Read,
                    },
                ],
            });

            fetchNotificationsSummary({
                notificationsService,
                notificationsDispatch,
                signal: abortSignalRef.current,
                ignoreCache: true,
            });

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    };
};
