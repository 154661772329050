import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Icon, Typography } from '../../shared';
import { Button } from '../../shared/button';
import { modalDefaultStyles, Popup, PopupProps } from '../../shared/popup';

interface Props extends PopupProps {
    onOpenPress: () => void;
}

export const PartRequestsConfirmOpenPopup: FC<Props> = ({ onOpenPress, ...props }) => {
    const { t } = useTranslation();

    return (
        <Popup {...props}>
            <View style={modalDefaultStyles.webHeader}>
                <Typography variant="h5">
                    {t('qmr:popups.partRequests.confirmOpen.title', 'Open Part Collection Request')}
                </Typography>
            </View>

            <View style={modalDefaultStyles.webContent}>
                <Icon name="warning" color="graySix" size={22} style={modalDefaultStyles.webContentIcon} />
                <View>
                    <Typography variant="h5">
                        {t(
                            'qmr:popups.partRequests.confirmOpen.message',
                            'Are you sure you want to open this part collection request?'
                        )}
                    </Typography>
                    <Typography variant="h5">
                        {t(
                            'qmr:popups.partRequests.confirmOpen.disclaimer',
                            'You must close this request once it is complete.'
                        )}
                    </Typography>
                </View>
            </View>

            <View style={modalDefaultStyles.webFooter}>
                <Button
                    style={modalDefaultStyles.webCancelButton}
                    variant="ghost-blue"
                    title={t('qmr:popups.partRequests.confirmOpen.cancel', 'Cancel')}
                    onPress={props.onModalHide}
                />
                <Button title={t('qmr:popups.partRequests.confirmOpen.open', 'Open Request')} onPress={onOpenPress} />
            </View>
        </Popup>
    );
};
