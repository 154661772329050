import { useCallback, useMemo, useState } from 'react';
import { AuthTokenService } from '@packages/contexts/auth';
import {
    TechlineStatusCode,
    WorksheetAnswerBody,
    WorksheetAnswerStatus,
    WorksheetElementAnswers,
    WorksheetSubmissionResponse,
} from '@packages/models/api';
import { flatMap } from 'lodash';
import { worksheetsService } from '@web/services/singletons';
import { HttpClient } from '@web/services/http-client';
import { StorageService } from '@packages/core/storage';
import { ConfigService } from '@packages/core/config';
import { getEnvConfig } from '@web/config';
import useUserDetailsForWorksheet from './useUserDetailsOrPermissions';
import { createDtoAnswerAssets } from '../utils/assets.utils';
interface Props {
    worksheetId?: string;
    caseNumber?: string;
    submissionId?: string;
}

export const useWorksheetSubmission = ({ worksheetId, caseNumber, submissionId }: Props) => {
    const storageService = useMemo(() => {
        return new StorageService(window.localStorage);
    }, []);
    const configService = useMemo(() => {
        return new ConfigService(storageService, getEnvConfig());
    }, [storageService]);
    const authTokenService = useMemo(() => {
        return new AuthTokenService(storageService);
    }, [storageService]);
    const httpClient = useMemo(() => {
        return new HttpClient(storageService, configService, authTokenService);
    }, [authTokenService, configService, storageService]);

    const [isLoading, setIsLoading] = useState<Record<WorksheetAnswerStatus, boolean>>({
        [WorksheetAnswerStatus.Draft]: false,
        [WorksheetAnswerStatus.Submitted]: false,
    });

    const { userName } = useUserDetailsForWorksheet();

    const createAssets = useCallback(
        async ({
            formElementAnswer,
            caseStatus,
            worksheetSubmissionRes,
        }: {
            formElementAnswer: Record<string, WorksheetElementAnswers>;
            caseStatus: TechlineStatusCode;
            worksheetSubmissionRes: WorksheetSubmissionResponse;
        }) => {
            if (!caseNumber) {
                return;
            }
            try {
                const { assets, techlinecasestatus, sourceIdFileMap } = createDtoAnswerAssets(
                    formElementAnswer,
                    caseNumber,
                    caseStatus,
                    worksheetSubmissionRes
                );
                if (assets.length) {
                    // @ts-ignore
                    worksheetsService.createAnswerAssets({ assets, techlinecasestatus }).then(async (res) => {
                        if (!res.success) {
                            return;
                        }
                        await Promise.all(
                            res.data.results.assets.map((asset, idx) => {
                                return worksheetsService.uploadAnswerAsset(
                                    asset.presignedUpload.url,
                                    asset.presignedUpload.httpHeaders,
                                    sourceIdFileMap[assets[idx]!.worksheetQuestionSubmissionSourceId]
                                );
                            })
                        );
                    });
                }
            } catch (error) {
                console.log('check errorr ===>', error);
            }
        },
        [caseNumber]
    );

    const onSubmit = useCallback(
        async ({
            formElementAnswer,
            status,
            caseStatus,
        }: {
            formElementAnswer: Record<string, WorksheetElementAnswers>;
            status: WorksheetAnswerStatus;
            caseStatus: TechlineStatusCode;
        }) => {
            setIsLoading((prev) => ({ ...prev, [status]: true }));
            const body: WorksheetAnswerBody = {
                submissionId: submissionId || '',
                caseNumber: caseNumber || '',
                userName: userName || '',
                worksheetId: worksheetId || '',
                answers: [],
                status: status,
            };
            body.answers = flatMap(formElementAnswer).map((ans) => ({
                elementId: ans.elementId,
                selectedOptions: ans.selectedOptions,
            }));
            await httpClient.refreshToken();
            const response = await worksheetsService.worksheetAnswerSubmission(body);
            console.log('check ====>?', formElementAnswer);
            if (response.success) {
                await createAssets({ formElementAnswer, caseStatus, worksheetSubmissionRes: response.data });
            }
            return response;
        },
        [caseNumber, createAssets, httpClient, submissionId, userName, worksheetId]
    );

    return {
        onSubmit,
        isLoading,
        setIsLoading,
    };
};
