import { useAbortController } from '@packages/core/http';
import { QmrStatusSummary } from '@packages/models/api';
import { qmrsService } from '@web/services/singletons';
import React, { createContext, FC, useCallback, useContext, useState } from 'react';

const SummariesContext = createContext<
    | {
          qmrSummaries: QmrStatusSummary[];
          fetchQmrSummaries: () => void;
      }
    | undefined
>(undefined);

export const SummariesProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { abortSignalRef } = useAbortController();
    const [qmrSummaries, setQmrSummaries] = useState<QmrStatusSummary[]>([]);

    const fetchQmrSummaries = useCallback(() => {
        return qmrsService
            .fetchQmrStatusSummaries(abortSignalRef.current)
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setQmrSummaries(response.data.qmrStatusSummaries);
            })
            .catch((e) => {
                alert(e.message);
            });
    }, [abortSignalRef]);

    return (
        <SummariesContext.Provider
            value={{
                qmrSummaries,
                fetchQmrSummaries,
            }}
        >
            {children}
        </SummariesContext.Provider>
    );
};

export const useSummariesState = () => {
    const state = useContext(SummariesContext);

    if (state === undefined) {
        throw new Error('useSummariesState must be used within an SummariesProvider');
    }

    return state;
};
