import { Qmr } from './qmr';

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/AssetType.java#L25
export enum AssetType {
    Video = 'VIDEO',
    Image = 'IMAGE',
    File = 'FILE',
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/AssetProcessingState.java
export enum AssetProcessingState {
    Uploading = 'UPLOADING',
    Processing = 'PROCESSING',
    Complete = 'COMPLETE',
    Error = 'ERROR',
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/AssetImageType.java#L30
// https://github.com/SubaruTechShare/sts-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/AssetVideoType.java#L30
// front-end view-model combining image, video & file types
export enum AssetMediaType {
    Original = 'ORIGINAL', // Image & Video
    OriginalReoriented = 'ORIGINAL_REORIENTED', // Image
    OriginalOverlaid = 'ORIGINAL_OVERLAID', // Image
    Thumbnail = 'THUMBNAIL', // Image
    ThumbnailSmall = 'THUMBNAIL_SMALL', // Image
    VideoStill = 'VIDEO_STILL', // Image
    File = 'FILE', // Non-Image/Video
}

export type AssetDisposition =
    | { assetDispositionId: 'QMR'; qmrId: Qmr['qmrId'] }
    | { assetDispositionId: 'ACCOUNT_AVATAR'; qmrId?: never }
    | { assetDispositionId: 'DOCUMENTATION'; qmrId?: never; articleId: string };

interface BaseAsset {
    contentType: string;
    createdTimestamp: string;
    createdTimestampDescription: string;
    url: string;
    sizeInBytes: number;
    sizeInBytesDescription: string;
}

interface BaseAssetMedia extends BaseAsset {
    height: number;
    heightDescription: string;
    width: number;
    widthDescription: string;
}

export interface AssetImageMedia extends BaseAssetMedia {
    assetImageId: string;
    assetImageTypeId: AssetMediaType;
    assetImageTypeIdDescription: string;
}

export interface AssetVideoMedia extends BaseAssetMedia {
    assetVideoId: string;
    assetVideoTypeId: AssetMediaType;
    assetVideoTypeIdDescription: string;
    durationInSeconds: number;
    durationInSecondsDescription: string;
}

export interface AssetFileMedia extends BaseAsset {
    assetFileId: string;
}

export type AssetMedia = AssetImageMedia | AssetVideoMedia | AssetFileMedia;

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/Asset.java
export interface Asset {
    assetId: string;
    assetTypeId: AssetType;
    assetTypeIdDescription: string;
    assetDispositionId: AssetDisposition['assetDispositionId'];
    assetDispositionIdDescription: string;
    assetProcessingStateId: AssetProcessingState;
    assetProcessingStateIdDescription: string;
    percentUploaded: number;
    percentUploadedDescription: string;
    hasOverlay: boolean;
    note: string;
    name: string;
    createdTimestamp: string;
    createdTimestampDescription: string;
    media: AssetMedia[];
    importedFromSiebel: boolean;
}

export interface PresignedUpload {
    expirationTimestamp: string;
    expirationTimestampDescription: string;
    httpHeaders: { [headerName: string]: string };
    httpMethod: 'PUT' | 'POST';
    url: string;
}

interface AssetOverlaySize {
    height: number;
    width: number;
}

interface AssetOverlayPath {
    color: string;
    width: number;
    points: AssetOverlayPathPoint[];
}

interface AssetOverlayPathPoint {
    x: number;
    y: number;
}

export interface CreateAssetCommand {
    assetId?: Asset['assetId'];
    qmrId?: string;
    assetDispositionId: AssetDisposition['assetDispositionId'];
    assetTypeId: AssetType;
    hasOverlay: boolean;
    note?: string;
    name?: string;
    restricted?: boolean;
    replacesAssetId?: Asset['assetId'];
    sizeInBytes?: number;
}

export interface CreatedAsset {
    asset: Asset;
    presignedUpload: PresignedUpload;
    overlayPresignedUpload?: PresignedUpload;
}

export interface GetAssetResponseDto {
    asset: Asset;
}

export interface CreateAssetsRequestDto {
    assets: CreateAssetCommand[];
}

export interface CreateAssetsResponseDto {
    assets: CreatedAsset[];
}
