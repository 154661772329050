import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Collapse } from 'react-bootstrap';

import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';
import { Button, Icon, StsIconName, Typography } from '@packages/ui/shared';

const useNavigationItemStyles = createUseStyles({
    navigationItem: ({ isActive, mainNav }: NavigationItemProps) => ({
        backgroundColor: isActive ? colors.white : 'transparent',
        borderTop: `1px solid ${mainNav && isActive ? colors.grayThree : 'transparent'}`,
        borderBottom: `1px solid ${isActive ? colors.grayThree : 'transparent'}`,
        '&:hover': {
            backgroundColor: isActive ? colors.white : colors.grayThree,
        },
    }),
    header: (props: NavigationItemProps) => ({
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > a, & > span': {
            cursor: 'pointer',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            padding: props.mainNav ? 14 : 20,
            '&:hover': {
                textDecoration: 'none',
            },
        },
        '& span': {
            color: props.isActive ? colors.blueOne : colors.graySix,
        },
        '&:hover': {
            '& img': {
                backgroundColor: colors.blueOne,
            },
            '& span': {
                color: colors.blueOne,
            },
        },
        backgroundColor: props.isMainNavActive ? colors.blueOne : 'transparent',
    }),
    icon: {
        display: 'flex',
        marginRight: 14,
    },
    notificationCount: {
        height: 16,
        minWidth: 16,
        ...fonts.xxs,
        marginLeft: 4,
        padding: '0 4px',
        borderRadius: 8,
        textAlign: 'center',
        color: colors.white,
        lineHeight: '1.5rem',
        ...fonts.robotoRegular,
        backgroundColor: colors.redOne,
    },
});

interface NavigationItemProps {
    to?: string;
    title: string;
    iconName?: StsIconName;
    open?: boolean;
    notificationCount?: number;
    isActive?: boolean;
    mainNav?: boolean;
    isMainNavActive?: boolean;
    onCollapseToggle?(open: boolean): void;
    onClick?(e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
    onHover?(title: string, ref?: React.ReactNode): void;
    onHoverLeave?(title: string, ref?: React.ReactNode): void;
    children?: React.ReactNode;
}

const NavigationItem: FC<NavigationItemProps> = ({
    children,
    onHover,
    onHoverLeave,
    to,
    title,
    iconName,
    open = false,
    notificationCount,
    isActive,
    mainNav = true,
    isMainNavActive = false,
    onCollapseToggle,
    onClick,
}) => {
    const hoverRefEl = React.useRef(null);

    const classes: any = useNavigationItemStyles({
        isActive,
        mainNav,
        hasChildren: !children,
        isMainNavActive,
    });

    function handleChevronButtonClick() {
        if (onCollapseToggle) {
            onCollapseToggle(!open);
        }
    }

    const handleHover = () => {
        if (onHover) {
            onHover(title, hoverRefEl?.current);
        }
    };

    const handleHoverLeave = () => {
        if (onHoverLeave) {
            onHoverLeave(title, hoverRefEl?.current);
        }
    };

    const WrapperComponent = !!to ? Link : 'span';

    return (
        <div className={classes.navigationItem}>
            <div className={classes.header}>
                <WrapperComponent
                    to={to || ''}
                    onClick={(e: any) => {
                        onClick && onClick(e);
                    }}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHoverLeave}
                    ref={hoverRefEl}
                >
                    {iconName && (
                        <div className={classes.icon}>
                            <Icon
                                name={iconName as StsIconName}
                                size={20}
                                color={isMainNavActive ? 'white' : isActive ? 'blueOne' : 'textDarkSecondary'}
                            />
                        </div>
                    )}

                    <Typography
                        variant="label"
                        color={isMainNavActive ? 'white' : isActive ? 'blueOne' : 'textDarkSecondary'}
                    >
                        {title}
                    </Typography>

                    {!!notificationCount && <div className={classes.notificationCount}>{notificationCount}</div>}
                </WrapperComponent>

                {children && (
                    <Button variant="link" onPress={handleChevronButtonClick} style={{ width: 44 }}>
                        <Icon
                            name={open ? 'arrow-small-up' : 'arrow-small-down'}
                            size={16}
                            color={isMainNavActive ? 'white' : isActive ? 'blueOne' : 'textDarkSecondary'}
                        />
                    </Button>
                )}
            </div>

            {children && (
                <Collapse in={open}>
                    <div>{children}</div>
                </Collapse>
            )}
        </div>
    );
};

export default NavigationItem;
