/* eslint-disable react-native/no-inline-styles */
import React, { FC, useMemo, useState } from 'react';
import Linkify from '../../../apps/web/src/administration/components/worksheet/Linkify';
import { Popover } from 'react-tiny-popover';
import { Badge } from 'react-bootstrap';
import { Typography, Icon } from '../shared';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { FormElementTypeId } from '@packages/models/api';

type WorksheetQuestionLabelAndTooltipProps = FC<{
    tooltip?: string;
    formElementLabel?: string;
    preview?: boolean;
    required?: boolean;
    formElementDescription?: string;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    formElementTypeId: FormElementTypeId;
}>;
const WorksheetLabelAndTooltip: WorksheetQuestionLabelAndTooltipProps = ({
    tooltip,
    formElementLabel,
    preview,
    required,
    formElementDescription,
    isExpanded,
    setIsExpanded,
    formElementTypeId,
}) => {
    const [show, setShow] = useState(false);
    const showCollapse = useMemo(() => {
        return ![FormElementTypeId.TextHeader, FormElementTypeId.TextParagraph].includes(formElementTypeId);
    }, [formElementTypeId]);
    return (
        <>
            <div className={`d-flex ${preview ? '' : 'justify-content-between'} `}>
                <div className="d-flex justify-content-center align-items-center">
                    {showCollapse && (
                        <TouchableOpacity
                            style={styles.optionalSectionToggle}
                            onPress={() => setIsExpanded(!isExpanded)}
                        >
                            <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} color="blueOne" />
                        </TouchableOpacity>
                    )}
                    <Typography variant="label" style={[styles.labelText]}>
                        {formElementLabel}
                        {required && <Typography color="redOne"> *</Typography>}
                    </Typography>
                </div>

                {tooltip && (
                    <>
                        <Popover
                            isOpen={show}
                            reposition={true}
                            content={
                                <Badge
                                    variant="light"
                                    style={{ maxWidth: '968px', maxHeight: '90%', border: '1px solid #D6D6D6' }}
                                    onMouseEnter={() => setShow(true)}
                                    onMouseLeave={() => setShow(false)}
                                >
                                    <div className="py-2 px-3 text-left">
                                        <Typography>
                                            <Linkify text={tooltip} />
                                        </Typography>
                                    </div>
                                </Badge>
                            }
                        >
                            <div
                                className="ml-2 mb-1 h-100"
                                style={{ cursor: 'pointer' }}
                                onMouseEnter={() => setShow(true)}
                                onMouseLeave={() => setShow(false)}
                            >
                                <Icon name="info" />
                            </div>
                        </Popover>
                    </>
                )}
            </div>
            {formElementDescription && isExpanded && (
                <Typography style={[styles.labelText, styles.description]}>
                    <Linkify text={formElementDescription} />
                </Typography>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    labelText: {
        wordBreak: 'break-word',
    },
    optionalSectionToggle: {
        marginRight: 5,
        minHeight: 48,
        flexDirection: 'row',
        alignItems: 'center',
    },
    description: {
        marginBottom: 10,
    },
});
export default WorksheetLabelAndTooltip;
