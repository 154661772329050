import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { createUseStyles } from 'react-jss';
import { Icon, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { useAuthState } from '@packages/contexts/auth';
import { analyticsService, qmrsDashboardDistrictStatsService } from '@web/services/singletons';
import { DistrictAndLocation, DistrictStatsResponseDto } from '@packages/contexts/qmrsdashboard';
import { httpClient } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { useTranslation } from 'react-i18next';
import { Badge, Overlay } from 'react-bootstrap';

type QmrsDistrictStatsProps = {
    displayStatsForAccunt:
        | { accountId?: string; name?: string; soaUsername?: string; districtId?: string; details?: string }
        | undefined;
    loggedInUserPermission: any[];
    selectedDistrict: any;
    setSelectedDistrict: React.Dispatch<React.SetStateAction<any>>;
};

const useQmrDashboardStyle = createUseStyles({
    rowstyle: {
        display: 'flex',
        alignItems: 'middle',
    },
    yellowIconOuter: {
        height: 20,
        width: 20,
        display: 'flex',
        justifyContent: 'center',
        background: colors.yellowFour,
        borderRadius: '50%',
        marginLeft: 4,
    },
    redIconOuter: {
        height: 20,
        width: 20,
        display: 'flex',
        justifyContent: 'center',
        background: colors.redFour,
        borderRadius: '50%',
        marginLeft: 4,
    },
    greenIconOuter: {
        height: 20,
        width: 20,
        display: 'flex',
        justifyContent: 'center',
        background: colors.greenFour,
        borderRadius: '50%',
        marginLeft: 4,
    },
    statsInfoFilterRow: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10,
        display: 'flex',
        paddingTop: '24px',
        justifyContent: 'space-between',
    },
    statsContent: {
        border: `2px solid ${colors.grayFour}`,
        borderRadius: 7,
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10,
        minHeight: 160,
        height: '100%',
    },
    controlPair: {
        display: 'flex',
        alignItems: 'center',
        gap: 0,
    },
    statsSelectionOptions: {
        display: 'flex',
        alignItems: 'center',
        gap: 0,
        paddingRight: 15,
    },
    statsSelectionName: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        paddingLeft: 15,
    },
    '@keyframes barWidth': {
        '0%': { width: '0%' },
        '10%': { width: '10%' },
        '25%': { width: '25%' },
        '50%': { width: '100%' },
        '75%': { width: '40%' },
        '100%': { width: '0%' },
    },

    horizontalbarwrap: {
        height: 20,
        width: '100%',
    },
    bar: {
        position: 'relative',
        width: '0%',
        height: 20,
        animationName: '$barWidth',
        'animation-duration': '3s',
        'animation-iteration-count': 'infinite',
        'animation-timing-function': 'linear',
        '&.bar1': {
            'animation-delay': '0s',
            background: colors.grayOne,
            top: 0,
        },
    },
    info: {
        '&:hover': {
            cursor: 'pointer',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2px',
        marginLeft: '1px',
    },
    toolTipContent: {
        position: 'absolute',
        top: '100%',
        width: '350px',
        transform: 'translateX(-50%)',
        zIndex: '1000 !important',
    },
    customBadge: {
        backgroundColor: colors.blueOneHover,
        color: 'white',
        borderRadius: '4px',
        fontSize: '14px',
        position: 'relative',
        marginLeft: '20px',
        marginBottom: '4px',
        padding: '6px',
    },
    tail: {
        position: 'absolute',
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderTop: `8px solid ${colors.blueOneHover}`,
        bottom: '-3px',
        left: 'calc(10% - 9px)',
        marginBottom: '1px',
    },
});

const LoadingBar = ({ loading, elementAfterLoading }: { loading: boolean; elementAfterLoading: any }) => {
    const classes = useQmrDashboardStyle();
    if (loading) {
        return (
            <div className={classes.horizontalbarwrap}>
                <div className={`${classes.bar} bar1`}></div>
            </div>
        );
    } else {
        return elementAfterLoading;
    }
};

const QmrsDistrictStats: FC<{ qmrsDistrictStats: QmrsDistrictStatsProps }> = (props) => {
    const { setSelectedDistrict, displayStatsForAccunt } = props.qmrsDistrictStats;
    const reactSelectRef = useRef<any>();
    const classes = useQmrDashboardStyle();
    const [period, setPeriod] = useState<string | undefined>('YTD');
    const [periodDisplayValue, setPeriodDisplayValue] = useState<string | undefined>('');
    const [compareTo, setCompareTo] = useState<string | undefined>();
    const [compareToDisplayValue, setCompareToDisplayValue] = useState<string | undefined>();
    const [compareToDisplayValueLookup, setCompareToDisplayValueLookup] = useState<any>({});
    const [districtStatsData, setDistrcitStatsData] = useState<DistrictAndLocation | undefined>();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [morphedAccountId, setMorphedAccountId] = useState<string | undefined>('');
    const { account } = useAuthState();
    const { abortSignalRef } = useAbortController();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [displayToolTip, setDisplayToolTip] = useState<boolean | undefined>(false);
    const [areaDetails, setAreaDetails] = useState<string | undefined>('');
    const { t } = useTranslation();
    const [districtId, setDistrictId] = useState<string | undefined>('');
    const [dataReset, setDataReset] = useState<boolean>(false);

    const setValues = (successResponse: any) => {
        let areaToolTipValue = successResponse?.districtAndLocation?.districtDetails
            ? successResponse?.districtAndLocation?.districtDetails
            : successResponse?.districtAndLocation?.regionDetails;
        setAreaDetails(areaToolTipValue);
        setSelectedDistrict({ label: areaToolTipValue });
    };

    const fetchLookupValues = async (period: string, compareTo: string) => {
        const searchParams = {
            period: period,
            comparedTo: compareTo,
        };
        try {
            setLoading(true);
            const response = await qmrsDashboardDistrictStatsService.fetchDistrictStatsData({ searchParams });
            if (response.success) {
                const successResponse: DistrictStatsResponseDto = response.data;
                setCompareToDisplayValueLookup(successResponse.districtAndLocation?.periodAndComparedToDates);
                setValues(successResponse);
                setLoading(false);
            } else throw response.data;
        } catch (err) {
            if ('AUTHORIZATION_REQUIRED' !== err.code) {
                alert(`${err.code}, ${err.message}`);
            } else {
                setLoading(false);
                setErrorMessage(err.code);
            }
        }
    };

    const fetchDistrictStats = async (period: string, compareTo: string) => {
        const searchParams = {
            period: period,
            comparedTo: compareTo,
        };
        try {
            setLoading(true);
            const response = await qmrsDashboardDistrictStatsService.fetchDistrictStatsData({ searchParams });
            if (response.success) {
                const successResponse: DistrictStatsResponseDto = response.data;
                setDistrcitStatsData(successResponse.districtAndLocation);
                setLoading(false);
            } else throw response.data;
        } catch (err) {
            if ('AUTHORIZATION_REQUIRED' !== err.code) {
                alert(`${err.code}, ${err.message}`);
            } else {
                setLoading(false);
                setErrorMessage(err.code);
            }
        }
    };

    useEffect(() => {
        fetchLookupValues('YTD', 'SamePeriodLastYear');
    }, []);

    useEffect(() => {
        fetchDistrictIdStats(districtId, 'YTD', 'SamePeriodLastYear');
    }, [districtId]);

    const fetchDistrictIdStats = async (districtId: any, period: string, comparedTo: string) => {
        if (districtId == '') {
            return;
        }
        const searchParams = {
            period: period,
            comparedTo: comparedTo,
            id: districtId,
        };
        try {
            setLoading(true);
            const response = await qmrsDashboardDistrictStatsService.fetchDistrictIdData({ searchParams });
            if (response.success) {
                const successResponse: DistrictStatsResponseDto = response.data;
                setDistrcitStatsData(successResponse.districtAndLocation);
                setValues(successResponse);
                setLoading(false);
            } else throw response.data;
        } catch (err) {
            if ('AUTHORIZATION_REQUIRED' !== err.code) {
                alert(`${err.code}, ${err.message}`);
            } else {
                setLoading(false);
                setErrorMessage(err.code);
            }
        }
    };

    const fetchOverRiddenStats = async (period: string, comparedTo: string, accountId: string) => {
        const config = {
            method: 'GET' as const,
            signal: abortSignalRef.current,
            ignoreCache: true,
        };
        const searchParams = {
            period,
            comparedTo,
            accountId,
        };
        //vaduga
        httpClient
            .orchestrateRequest<any>({
                ...config,
                url: `/qmr/dashboard/overrideAPIService?${new URLSearchParams(searchParams).toString()}`,
            })
            .then((response: any) => {
                const successResponse: DistrictStatsResponseDto = response.data;
                if (Object.keys(successResponse.districtAndLocation)?.length > 0) {
                    setDisplayToolTip(true);
                    setValues(successResponse);
                    setDistrcitStatsData(successResponse.districtAndLocation);
                } else {
                    alert('No District Data Available for the selected user');
                }
                setLoading(false);
            })
            .catch((e: any) => {
                alert('Encountered Error while trying to get district stats for selected user');
                setLoading(false);
            });
    };

    useEffect(() => {
        if (
            props.qmrsDistrictStats.displayStatsForAccunt &&
            !props.qmrsDistrictStats.displayStatsForAccunt.districtId
        ) {
            setMorphedAccountId(props.qmrsDistrictStats.displayStatsForAccunt.accountId);
        } else if (props.qmrsDistrictStats.selectedDistrict && props.qmrsDistrictStats.selectedDistrict.value) {
            setDistrictId(props.qmrsDistrictStats.selectedDistrict.value);
        } else if (
            props.qmrsDistrictStats.displayStatsForAccunt &&
            props.qmrsDistrictStats.displayStatsForAccunt.districtId
        ) {
            setDistrictId(props.qmrsDistrictStats.displayStatsForAccunt.districtId);
            setDataReset(!dataReset);
        } else {
            setDistrictId('');
            setDataReset(!dataReset);
            setMorphedAccountId(undefined);
        }
    }, [props.qmrsDistrictStats.displayStatsForAccunt, props.qmrsDistrictStats.selectedDistrict]);

    useEffect(() => {
        let compareToRequest;
        if (compareTo) {
            compareToRequest = compareTo;
        } else if (
            reactSelectRef?.current &&
            reactSelectRef?.current.getValue() &&
            reactSelectRef?.current?.getValue()[0]?.value
        ) {
            compareToRequest = reactSelectRef?.current?.getValue()[0]?.value;
            setCompareTo(reactSelectRef?.current?.getValue()[0]?.value);
            setCompareToDisplayValue(reactSelectRef?.current?.getValue()[0]?.label);
        }
        if (period && compareToRequest) {
            if (morphedAccountId) {
                fetchOverRiddenStats(
                    period,
                    compareToRequest,
                    morphedAccountId /* "5f0acf9b-1cce-4d3b-9f68-b0ef5d8437e8"*/
                );
            } else if (
                !(
                    props.qmrsDistrictStats.displayStatsForAccunt &&
                    props.qmrsDistrictStats.displayStatsForAccunt.districtId
                ) &&
                !(props.qmrsDistrictStats.selectedDistrict && props.qmrsDistrictStats.selectedDistrict.value) &&
                !(props.qmrsDistrictStats.selectedDistrict && props.qmrsDistrictStats.selectedDistrict.label)
            ) {
                fetchDistrictStats(period, compareToRequest);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, compareTo, reactSelectRef, morphedAccountId, dataReset]);

    const getMePlusMinus = (percentage: string | number) => {
        let valueToCompare = 0;
        if (typeof percentage === 'string') {
            valueToCompare = Number(percentage);
        } else {
            valueToCompare = percentage;
        }
        if (valueToCompare === 0) {
            return <div>&nbsp; &nbsp;</div>;
        } else if (valueToCompare > 0) {
            return (
                <Typography variant="h6" color="greenOne">
                    &nbsp; &nbsp;+
                </Typography>
            );
        } else if (valueToCompare < 0) {
            return (
                <Typography variant="h6" color="redOne">
                    &nbsp;&nbsp;
                </Typography>
            );
        }
        return <Icon name={'arrow-small-down'} color="yellowOne" size={20} />;
    };
    const getMeIcon = (percentage: string | number) => {
        let valueToCompare = 0;
        if (typeof percentage === 'string') {
            valueToCompare = Number(percentage);
        } else {
            valueToCompare = percentage;
        }
        if (valueToCompare === 0) {
            return (
                <div className={classes.yellowIconOuter}>
                    <Icon name={'minus'} color="yellowOne" size={20} />
                </div>
            );
        } else if (valueToCompare > 0) {
            return (
                <div className={classes.greenIconOuter}>
                    <Icon name={'arrow-small-up'} color="greenOne" size={20} />
                </div>
            );
        } else if (valueToCompare < 0) {
            return (
                <div className={classes.redIconOuter}>
                    <Icon name={'arrow-small-down'} color="redOne" size={20} />
                </div>
            );
        }

        return <Icon name={'arrow-small-down'} color="yellowOne" size={20} />;
    };

    const determineRedGreenYellow = (percentage: string | number) => {
        let valueToCompare = 0;
        if (typeof percentage === 'string') {
            valueToCompare = Number(percentage);
        } else {
            valueToCompare = percentage;
        }
        if (valueToCompare === 0) {
            return 'yellowOne';
        } else if (valueToCompare > 0) {
            return 'greenOne';
        } else if (valueToCompare < 0) {
            return 'redOne';
        }

        return 'yellowOne';
    };

    const { optionsStatsComponent } = useMemo(() => {
        let ytdDisplayValue = 'YTD';
        let thisMonthDisplayValue = 'This Month';
        let lastMonthDisplayValue = 'Last Month';
        if (compareToDisplayValueLookup) {
            ytdDisplayValue = `YTD (${compareToDisplayValueLookup['yearToDate']})`;
            thisMonthDisplayValue = `This Month (${compareToDisplayValueLookup['thisMonth']})`;
            lastMonthDisplayValue = `Last Month (${compareToDisplayValueLookup['lastMonth']})`;
        }
        setPeriod('YTD');
        setPeriodDisplayValue(`YTD (${compareToDisplayValueLookup['yearToDate']})`);
        let optionsStatsComponent = [
            { value: 'YTD', label: ytdDisplayValue },
            { value: 'ThisMonth', label: thisMonthDisplayValue },
            { value: 'LastMonth', label: lastMonthDisplayValue },
        ];
        return { optionsStatsComponent };
    }, [compareToDisplayValueLookup]);

    const { displayText, optionStatsComponentCompareTo } = useMemo(() => {
        let displayText = 'District Stats';
        let samePeriodLastYearDisplayValue = 'SamePeriodLastYear';
        let previousPeriodDisplayValue = 'PreviousPeriod';

        if (compareToDisplayValueLookup) {
            if (period === 'YTD') {
                samePeriodLastYearDisplayValue = `(${compareToDisplayValueLookup['yearToDateSamePeriodLastYear']})`;
                previousPeriodDisplayValue = `(${compareToDisplayValueLookup['yearToDatePreviousPeriod']})`;
            } else if (period === 'ThisMonth') {
                samePeriodLastYearDisplayValue = `(${compareToDisplayValueLookup['thisMonthSamePeriodLastYear']})`;
                previousPeriodDisplayValue = `(${compareToDisplayValueLookup['thisMonthPreviousPeriod']})`;
            } else if (period === 'LastMonth') {
                samePeriodLastYearDisplayValue = `(${compareToDisplayValueLookup['lastMonthSamePeriodLastYear']})`;
                previousPeriodDisplayValue = `(${compareToDisplayValueLookup['lastMonthPreviousPeriod']})`;
            }
        }

        let optionStatsComponentCompareTo = [
            { value: 'NationAverage', label: 'National Average' },
            { value: 'RegionalAverage', label: 'Regional Average' },
            { value: 'SamePeriodLastYear', label: `Same Period Last Year ${samePeriodLastYearDisplayValue}` },
            { value: 'PreviousPeriod', label: `Previous Period ${previousPeriodDisplayValue}` },
        ];
        if (morphedAccountId || (displayStatsForAccunt && displayStatsForAccunt.districtId)) {
            setDisplayToolTip(true);
            setTimeout(() => {
                setCompareTo('SamePeriodLastYear');
                setCompareToDisplayValue(`Same Period Last Year ${samePeriodLastYearDisplayValue}`);
            }, 100);
            return {
                displayText,
                optionsStatsComponent,
                optionStatsComponentCompareTo,
            };
        } else {
            const loggedInUserPermission = props.qmrsDistrictStats.loggedInUserPermission;
            if (loggedInUserPermission.some((permission) => permission.permissionId === 'DASHBOARD005')) {
                displayText = 'National Stats';
                setDisplayToolTip(false);
                optionStatsComponentCompareTo = [
                    { value: 'SamePeriodLastYear', label: `Same Period Last Year ${samePeriodLastYearDisplayValue}` },
                    { value: 'PreviousPeriod', label: `Previous Period ${previousPeriodDisplayValue}` },
                ];
                setTimeout(() => {
                    setCompareTo('SamePeriodLastYear');
                    setCompareToDisplayValue(`Same Period Last Year ${samePeriodLastYearDisplayValue}`);
                }, 100);
            } else if (loggedInUserPermission.some((permission) => permission.permissionId === 'DASHBOARD006')) {
                displayText = 'Regional Stats';
                setDisplayToolTip(true);
                optionStatsComponentCompareTo = [
                    { value: 'NationAverage', label: 'National Average' },
                    { value: 'SamePeriodLastYear', label: `Same Period Last Year ${samePeriodLastYearDisplayValue}` },
                    { value: 'PreviousPeriod', label: `Previous Period ${previousPeriodDisplayValue}` },
                ];
                setTimeout(() => {
                    setCompareTo('SamePeriodLastYear');
                    setCompareToDisplayValue(`Same Period Last Year ${samePeriodLastYearDisplayValue}`);
                }, 100);
            } else if (loggedInUserPermission.some((permission) => permission.permissionId === 'DASHBOARD003')) {
                displayText = 'District Stats';
                setDisplayToolTip(true);
                optionStatsComponentCompareTo = [
                    { value: 'NationAverage', label: 'National Average' },
                    { value: 'RegionalAverage', label: 'Regional Average' },
                    { value: 'SamePeriodLastYear', label: `Same Period Last Year ${samePeriodLastYearDisplayValue}` },
                    { value: 'PreviousPeriod', label: `Previous Period ${previousPeriodDisplayValue}` },
                ];
                setTimeout(() => {
                    setCompareTo('SamePeriodLastYear');
                    setCompareToDisplayValue(`Same Period Last Year ${samePeriodLastYearDisplayValue}`);
                }, 100);
            }

            return {
                displayText,
                optionStatsComponentCompareTo,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        account,
        morphedAccountId,
        props.qmrsDistrictStats.loggedInUserPermission,
        compareToDisplayValueLookup,
        period,
        dataReset,
    ]);

    const getFirstRow = useCallback(
        (type: 'qmr' | 'feedback' | 'tr') => {
            if (!districtStatsData) return '';
            let value = '';
            const { dealerSubmittedQMRs, percentageOfFeedbackProvided, percentageOfEscalationSubmitted } =
                districtStatsData;
            if (type === 'qmr') {
                value = `${dealerSubmittedQMRs} retailer-created QMRs`;
            } else if (type === 'feedback') {
                value = `${percentageOfFeedbackProvided}% of QMRs submitted`;
            } else {
                value = `${percentageOfEscalationSubmitted}% of submitted QMRs`;
            }
            return `(${value})`;
        },
        [districtStatsData]
    );

    const getThirdRow = useCallback(
        (type: 'qmr' | 'feedback' | 'tr') => {
            if (!districtStatsData) return '';
            const isNationalOrRegional = ['NationAverage', 'RegionalAverage'].includes(compareTo!);
            const { qmrAverage, feedbackAverage, escalationAverage } = districtStatsData.compareAverage;
            const {
                comparedToDealerAndDSQMSubmittedQMRs,
                comparedToFeedbackRequested,
                comparedToEscalationSubmitted,
                comparedToDealerQMRs,
                comparedToPercentageOfFeedbackProvided,
                comparedToPercentageOfEscalationSubmitted,
            } = districtStatsData;

            if (type === 'qmr') {
                return (
                    <span>
                        {comparedToDealerAndDSQMSubmittedQMRs}
                        <br />
                        {` (${comparedToDealerQMRs} retailer-created QMRs)`}
                    </span>
                );
            } else if (type === 'feedback') {
                return (
                    <span>
                        {comparedToFeedbackRequested}
                        <br />
                        {` (${comparedToPercentageOfFeedbackProvided}% of QMRs submitted)`}
                    </span>
                );
            } else if (type === 'tr') {
                return (
                    <span>
                        {comparedToEscalationSubmitted}
                        <br />
                        {` (${comparedToPercentageOfEscalationSubmitted}% of submitted QMRs)`}
                    </span>
                );
            }
        },
        [compareTo, districtStatsData]
    );

    const getFourthRow = useCallback(
        (type: 'qmr' | 'feedback' | 'tr') => {
            if (!districtStatsData) return '';
            let value: string | number = '';
            const { qmrPercentage, feedbackPercentage, escalationPercentage } = districtStatsData.comparePercentage;
            if (type === 'qmr') {
                value = qmrPercentage;
            } else if (type === 'feedback') {
                value = feedbackPercentage;
            } else if (type === 'tr') {
                value = escalationPercentage;
            }
            return `${value}%`;
        },
        [districtStatsData]
    );

    return (
        <>
            {errorMessage === undefined && (
                <Row className={classes.statsInfoFilterRow}>
                    <div className={classes.statsSelectionName}>
                        {displayToolTip && (
                            <div style={{ position: 'relative' }}>
                                <div
                                    ref={tooltipRef}
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    className={classes.info}
                                >
                                    <Icon size={16} name="info" color="blueOne" />
                                </div>
                                <Overlay
                                    container={tooltipRef.current}
                                    target={tooltipRef.current as any}
                                    show={showTooltip}
                                >
                                    {(overlayProps: any) => {
                                        return (
                                            <div
                                                className={classes.toolTipContent}
                                                ref={overlayProps.ref}
                                                style={overlayProps.style}
                                            >
                                                <Badge variant="secondary" className={classes.customBadge}>
                                                    <Typography color="white" style={{ fontSize: 12 }}>
                                                        {displayText === 'District Stats'
                                                            ? t(
                                                                  `Region: ${areaDetails?.split('-')[0]}, Zone: ${
                                                                      areaDetails?.split('-')[1]
                                                                  }, District: ${areaDetails?.split('-')[2]}`
                                                              )
                                                            : t(`Region: ${areaDetails}`)}
                                                    </Typography>
                                                </Badge>
                                                <span className={classes.tail}></span>
                                            </div>
                                        );
                                    }}
                                </Overlay>
                            </div>
                        )}
                        <Typography variant="caption3" color={displayText === 'National Stats' ? 'black' : 'blueOne'}>
                            {displayText}
                        </Typography>
                    </div>
                    <div className={classes.statsSelectionOptions}>
                        <div className={classes.controlPair}>
                            <Typography variant="default" color="black">
                                Period:
                            </Typography>
                            <Select
                                defaultValue={optionsStatsComponent[0]}
                                options={optionsStatsComponent}
                                value={{
                                    value: period,
                                    label: periodDisplayValue,
                                }}
                                isSearchable={false}
                                components={{ IndicatorSeparator: () => null }}
                                styles={{
                                    control: (styles) => ({
                                        ...styles,
                                        color: colors.blueOne,
                                        font: '18px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                                        fontWeight: '700',
                                        border: 0,
                                        boxShadow: 'none',
                                    }),
                                    singleValue: (styles) => ({
                                        ...styles,
                                        color: colors.blueOne,
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                        textTransform: 'capitalize',
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
                                    option: (base) => ({
                                        ...base,
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                        textTransform: 'capitalize',
                                    }),
                                }}
                                onChange={(value) => {
                                    if (value) {
                                        setPeriod(value.value);
                                        setPeriodDisplayValue(value.label);
                                        analyticsService.logEvent(ANALYTICS_EVENTS.QMRDASH_USER_CHANGED_PERIOD, {
                                            periodSelected: value,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <div className={classes.controlPair}>
                            <Typography variant="default" color="black">
                                Compared To:
                            </Typography>
                            <Select
                                ref={reactSelectRef}
                                defaultValue={optionStatsComponentCompareTo[0]}
                                options={optionStatsComponentCompareTo}
                                value={{
                                    value: compareTo,
                                    label: compareToDisplayValue,
                                }}
                                isSearchable={false}
                                components={{ IndicatorSeparator: () => null }}
                                styles={{
                                    control: (styles) => ({
                                        ...styles,
                                        color: colors.blueOne,
                                        font: '18px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                                        fontWeight: '700',
                                        border: 0,
                                        boxShadow: 'none',
                                    }),
                                    singleValue: (styles) => ({
                                        ...styles,
                                        color: colors.blueOne,
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                        textTransform: 'capitalize',
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 999999999999 }),
                                    option: (base) => ({
                                        ...base,
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                        textTransform: 'capitalize',
                                    }),
                                }}
                                onChange={(value) => {
                                    if (value) {
                                        setCompareTo(value.value);
                                        setCompareToDisplayValue(value.label);
                                        analyticsService.logEvent(
                                            ANALYTICS_EVENTS.QMRDASH_USER_CHANGED_PERIOD_COMPARED_TO,
                                            {
                                                periodSelected: value,
                                            }
                                        );
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Row>
            )}

            {errorMessage === undefined && (
                <Row className={classes.statsInfoFilterRow}>
                    <Col>
                        <div className={classes.statsContent}>
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                marginTop: 10,
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                color="blueOne"
                                                style={{ lineHeight: 26, textAlign: 'center' }}
                                            >
                                                {districtStatsData?.dealerAndDSQMSubmittedQMRs || ''}
                                            </Typography>
                                            <Typography
                                                variant="caption3"
                                                color="blueOne"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {getFirstRow('qmr')}
                                            </Typography>
                                        </div>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <Typography variant="h4" color="blueOne" style={{ textAlign: 'center' }}>
                                            QMRs Submitted
                                        </Typography>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <Typography variant="default" color="blueOne" style={{ textAlign: 'center' }}>
                                            {compareToDisplayValue?.split('(')[0]}: {getThirdRow('qmr')}
                                        </Typography>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <div className={classes.rowstyle}>
                                            {getMeIcon(districtStatsData?.comparePercentage?.qmrPercentage || 0)}
                                            {getMePlusMinus(districtStatsData?.comparePercentage?.qmrPercentage || 0)}
                                            <Typography
                                                variant="h6"
                                                color={determineRedGreenYellow(
                                                    districtStatsData?.comparePercentage?.qmrPercentage || 0
                                                )}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {getFourthRow('qmr')}
                                            </Typography>
                                        </div>
                                    }
                                />
                            }
                        </div>
                    </Col>
                    <Col>
                        <div className={classes.statsContent}>
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                marginTop: 10,
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                color="blueOne"
                                                style={{ lineHeight: 26, textAlign: 'center' }}
                                            >
                                                {districtStatsData?.feedbackRequested || ''}
                                            </Typography>
                                            <Typography
                                                variant="caption3"
                                                color="blueOne"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {getFirstRow('feedback')}
                                            </Typography>
                                        </div>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <Typography variant="h4" color="blueOne" style={{ textAlign: 'center' }}>
                                            Feedback Provided
                                        </Typography>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <Typography variant="default" color="blueOne" style={{ textAlign: 'center' }}>
                                            {compareToDisplayValue?.split('(')[0]}: {getThirdRow('feedback')}
                                        </Typography>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <div className={classes.rowstyle}>
                                            {getMeIcon(districtStatsData?.comparePercentage?.feedbackPercentage || 0)}
                                            {getMePlusMinus(
                                                districtStatsData?.comparePercentage?.feedbackPercentage || 0
                                            )}
                                            <Typography
                                                variant="h6"
                                                color={determineRedGreenYellow(
                                                    districtStatsData?.comparePercentage?.feedbackPercentage || 0
                                                )}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {getFourthRow('feedback')}
                                            </Typography>
                                        </div>
                                    }
                                />
                            }
                        </div>
                    </Col>
                    <Col>
                        <div className={classes.statsContent}>
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                marginTop: 10,
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                color="blueOne"
                                                style={{ lineHeight: 26, textAlign: 'center' }}
                                            >
                                                {districtStatsData?.escalationSubmitted || ''}
                                            </Typography>
                                            <Typography
                                                variant="caption3"
                                                color="blueOne"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {getFirstRow('tr')}
                                            </Typography>
                                        </div>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <Typography variant="h4" color="blueOne" style={{ textAlign: 'center' }}>
                                            TR Escalations
                                        </Typography>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <Typography variant="default" color="blueOne" style={{ textAlign: 'center' }}>
                                            {compareToDisplayValue?.split('(')[0]}: {getThirdRow('tr')}
                                        </Typography>
                                    }
                                />
                            }
                            {
                                <LoadingBar
                                    loading={loading}
                                    elementAfterLoading={
                                        <div className={classes.rowstyle}>
                                            {getMeIcon(districtStatsData?.comparePercentage?.escalationPercentage || 0)}
                                            {getMePlusMinus(
                                                districtStatsData?.comparePercentage?.escalationPercentage || 0
                                            )}
                                            <Typography
                                                variant="h6"
                                                color={determineRedGreenYellow(
                                                    districtStatsData?.comparePercentage?.escalationPercentage || 0
                                                )}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {getFourthRow('tr')}
                                            </Typography>
                                        </div>
                                    }
                                />
                            }
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default QmrsDistrictStats;
