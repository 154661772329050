import React, { FC, useState, useCallback, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useMatch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';

import { analyticsService, feedbackService, notificationsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { FeedbackSession, FeedbackFormAnswer, FeedbackSessionStatusId, FeedbackFormTypeId } from '@packages/models/api';
import { Button, Icon, StsIconName } from '@packages/ui/shared';
import { useMarkNotificationAsReadOnLoad } from '@packages/contexts/notifications';

import { Typography } from '@packages/ui/shared';
import {
    DetailView,
    DetailViewAside,
    DetailViewBody,
    DetailViewContent,
    DetailViewHeader,
} from '@web/components/detail-view';
import AsyncPage from '@web/components/async-page';
import { FeedbackDetailSidePanel, FeedbackFormElement, FeedbackRequestMessage } from '@web/feedback/components';
import { useAuthState } from '@packages/contexts/auth';
import { getQmrAssetDetails, getQmrVehicleDetails } from '@packages/contexts/qmrs';

import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const useFeedbackDetailStyles = createUseStyles({
    infoSection: {
        paddingBottom: 16,
    },
});

export const FeedbackDetail: FC = () => {
    const { feedbackSessionId } = useParams();

    const entityFeedbackRouteMatch = useMatch('/feedback/entity/:feedbackCreationUrlPath');
    const feedbackCreationUrlPath = entityFeedbackRouteMatch?.params.feedbackCreationUrlPath;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { abortSignalRef } = useAbortController();
    const { account } = useAuthState();

    const [feedbackSession, setFeedbackSession] = useState<FeedbackSession>();
    const [sessionChoices, setSessionChoices] = useState<Record<string, FeedbackFormAnswer>>({});

    useMarkNotificationAsReadOnLoad(notificationsService);

    const hasAnsweredChoices = useMemo(() => {
        return Object.values(sessionChoices).length > 0;
    }, [sessionChoices]);

    const isReceivedSession = useMemo(() => {
        if (!feedbackSession || !account) {
            return false;
        }

        if (
            feedbackSession.feedbackForm.formType === FeedbackFormTypeId.Entity &&
            account.systemCapabilities.manageFeedbackEntities
        ) {
            return true;
        }

        return feedbackSession.receivingAccount.accountId === account.accountId;
    }, [account, feedbackSession]);

    const isEntityFeedback = useMemo(() => {
        if (!feedbackSession || !account) {
            return false;
        }

        return (
            feedbackSession.feedbackForm.formType === FeedbackFormTypeId.Entity &&
            account.systemCapabilities.manageFeedbackEntities
        );
    }, [account, feedbackSession]);

    const isRequestedSession = useMemo(() => {
        if (!feedbackSession || !account) {
            return false;
        }

        return feedbackSession.sendingAccount.accountId === account.accountId;
    }, [account, feedbackSession]);

    const showSidePanel =
        (isReceivedSession && feedbackSession?.feedbackSessionStatusId === FeedbackSessionStatusId.Rated) ||
        (isRequestedSession &&
            feedbackSession?.feedbackRatingSession?.feedbackSessionStatusId === FeedbackSessionStatusId.Rated);

    const fetchFeedbackSession = useCallback(() => {
        return (
            feedbackCreationUrlPath
                ? feedbackService.startFeedbackSession({
                      feedbackRecipientIds: [],
                      feedbackRequest: false,
                      feedbackCreationUrlPath,
                      signal: abortSignalRef.current,
                  })
                : feedbackService.fetchFeedbackSessionById({
                      feedbackSessionId,
                      signal: abortSignalRef.current,
                  })
        )
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setFeedbackSession(response.data.session);
            })
            .catch((error) => {
                alert(error.message);
            });
    }, [abortSignalRef, feedbackSessionId, feedbackCreationUrlPath]);

    const submitFeedbackSession = useCallback(() => {
        const choices = Object.values(sessionChoices);
        if (!feedbackSession || !choices.length) {
            return;
        }

        feedbackService
            .completeFeedbackSession({
                feedbackSessionId: feedbackSession.feedbackSessionId,
                data: {
                    choices,
                },
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                analyticsService.logEvent(ANALYTICS_EVENTS.USER_GIVES_FEEDBACK, {
                    feedbackType: feedbackSession?.feedbackForm?.formType
                        ? feedbackSession?.feedbackForm?.formType
                        : 'N/A',
                    ...(feedbackSession?.feedbackForm?.formType === FeedbackFormTypeId.Entity
                        ? { entityName: feedbackSession.receivingAccount.name }
                        : {}),
                    ...(feedbackSession?.feedbackForm?.formType === FeedbackFormTypeId.Qmr
                        ? { tsId: feedbackSession.qmr?.stsIdentifier }
                        : {}),
                });
                navigate('/feedback', { state: { feedbackSent: true } });
            })
            .catch((error) => {
                alert(error.message);
            });
    }, [feedbackSession, navigate, sessionChoices]);

    const renderQmrInfo = useCallback(() => {
        if (!feedbackSession || !feedbackSession.qmr) {
            return null;
        }

        const vehicle = getQmrVehicleDetails(feedbackSession.qmr);
        const assets = getQmrAssetDetails(feedbackSession.qmr);

        return (
            <>
                <div className="d-flex justify-content-between">
                    <div>
                        <Typography variant="h4">QMR Information</Typography>
                        <div>
                            <Link to={`/qmrs/${feedbackSession.qmr?.displayIdentifier}`}>
                                <Typography variant="lead" color="blueOne">
                                    {feedbackSession.qmr.displayIdentifier}
                                </Typography>
                            </Link>
                        </div>
                    </div>

                    <Button
                        variant="tertiary"
                        onPress={() => {
                            navigate(`/qmrs/${feedbackSession.qmr?.displayIdentifier}`);
                        }}
                    >
                        View QMR
                    </Button>
                </div>

                <div className="mt-4 d-flex justify-content-between align-items-center">
                    <div>
                        <div>
                            <Typography variant="h6">Vehicle Information</Typography>
                        </div>

                        <div>
                            <Typography variant="h5">{vehicle.displayName}</Typography>
                        </div>

                        <div>
                            <Typography>VIN: {vehicle.vin}</Typography>
                        </div>
                    </div>

                    <div className="d-flex">
                        {assets.map((a) => {
                            const showThumbnailImage = !a.thumbnailMedia.iconName;
                            const thumbnailSrc = showThumbnailImage ? a.thumbnailMedia?.uri : undefined;
                            const backgroundImage = showThumbnailImage
                                ? thumbnailSrc && `url(${thumbnailSrc})`
                                : undefined;

                            return (
                                <div
                                    key={a.assetId}
                                    className="ml-2"
                                    style={{
                                        backgroundImage,
                                        height: 80,
                                        width: 80,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: '50% 50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {showThumbnailImage ? (
                                        !thumbnailSrc && <ActivityIndicator color={colors.blueOne} size="large" />
                                    ) : (
                                        <Icon name={(a.thumbnailMedia?.iconName || '') as StsIconName} />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="my-4">
                    <div>
                        <Typography variant="h6">Complaint</Typography>
                    </div>
                    <div>
                        <Typography>{feedbackSession.qmr.complaint}</Typography>
                    </div>
                </div>

                <div className="pt-4 my-4">
                    <hr />
                </div>
            </>
        );
    }, [feedbackSession, navigate]);

    return (
        <AsyncPage fetchData={fetchFeedbackSession}>
            <DetailView>
                <DetailViewHeader
                    breadcrumbs={[
                        {
                            to: '/feedback',
                            title: t('feedback:breadcrumbs.feedback', 'Feedback'),
                        },
                        {
                            to: '/#',
                            title: t('feedback:breadcrumbs.feedbackDetails', 'Feedback Details'),
                        },
                    ]}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <Typography variant="h2">{t('feedback:feedbackDetail.title', 'Feedback Detail')}</Typography>
                    </div>
                </DetailViewHeader>

                <DetailViewContent topOffset="breadcrumb">
                    <DetailViewBody>
                        {feedbackSession && (
                            <Row className="mb-6">
                                <Col xs={12}>{renderQmrInfo()}</Col>

                                <Col xs={6}>
                                    <div className="mb-2">
                                        <Typography variant="h5">
                                            {isReceivedSession
                                                ? t('feedback:feedbackDetail.feedbackFrom', 'Feedback from:')
                                                : isRequestedSession
                                                ? feedbackSession.feedbackSessionStatusId ===
                                                  FeedbackSessionStatusId.Rated
                                                    ? t('feedback:feedbackDetail.feedbackSentTo', 'Feedback to:')
                                                    : t('feedback:feedbackDetail.sendFeedbackTo', 'Send feedback to:')
                                                : ''}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="lead">
                                            {isReceivedSession
                                                ? feedbackSession.sendingAccount.name
                                                : feedbackSession.receivingAccount.name}
                                        </Typography>
                                    </div>

                                    {isEntityFeedback && (
                                        <div className="pt-6">
                                            <div className="mb-2">
                                                <Typography variant="h5">
                                                    {t('feedback:feedbackDetail.feedbackSentTo', 'Feedback to:')}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="lead">
                                                    {feedbackSession.receivingAccount.name}
                                                </Typography>
                                            </div>
                                        </div>
                                    )}
                                </Col>

                                {!!feedbackSession && !!feedbackSession.qmr && (
                                    <Col xs={6}>
                                        <div className="mb-2">
                                            <Typography variant="h5">
                                                {t('feedback:feedbackDetail.date', 'Date:')}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography variant="lead">
                                                {feedbackSession.feedbackSessionCreatedTimestampDescription}
                                            </Typography>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        )}

                        {feedbackSession && isRequestedSession && feedbackSession.requestMessage && (
                            <Row className="mb-6">
                                <Col>
                                    <FeedbackRequestMessage message={feedbackSession.requestMessage} />
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col>
                                {feedbackSession &&
                                    feedbackSession.feedbackForm.formElements.map((el, index) => {
                                        return (
                                            <FeedbackFormElement
                                                key={el.formElementId}
                                                item={el}
                                                readOnly={feedbackSession.isReadOnly}
                                                onAnswerChange={(newAnswer: FeedbackFormAnswer) => {
                                                    setSessionChoices({
                                                        ...sessionChoices,
                                                        [el.formElementId]: newAnswer,
                                                    });
                                                }}
                                            />
                                        );
                                    })}

                                {!feedbackSession?.isReadOnly && (
                                    <Button
                                        disabled={!hasAnsweredChoices}
                                        onPress={submitFeedbackSession}
                                        title={t('buttons:submit')}
                                    />
                                )}
                            </Col>
                        </Row>
                    </DetailViewBody>

                    {feedbackSession && showSidePanel && (
                        <DetailViewAside>
                            <FeedbackDetailSidePanel session={feedbackSession} onSubmitRating={fetchFeedbackSession} />
                        </DetailViewAside>
                    )}
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};
