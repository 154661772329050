import { Dispatch, Reducer } from 'react';
import { action, on, props, reducer, union } from 'ts-action';
import { ArticlesActionTypes } from './articles.actions';
import { Article } from '../types';

export interface ArticlesState {
    articles: Article[];
    selectedArticle: Article;
}

export const articlesActions = {
    setSelectedArticle: action(ArticlesActionTypes.setSelectedArticle, props<{ selectedArticle: Article }>()),
    deleteArticle: action(ArticlesActionTypes.deleteArticle),
    fetchArticles: action(ArticlesActionTypes.fetchArticles, props<{ articles: Article[] }>()),
    fetchArticle: action(ArticlesActionTypes.fetchArticle, props<{ article: Article }>()),
    submitArticle: action(ArticlesActionTypes.submitArticle, props<{ article: Article }>()),
};

const actionsUnion = union(...Object.values(articlesActions));
type ArticlesActions = typeof actionsUnion.actions;

export type ArticleActionDispatcher = Dispatch<ArticlesActions>;

export const initialState: ArticlesState = {
    articles: [],
    selectedArticle: {
        article: '',
        name: '',
        id: '',
        categoryId: '',
        assets: [],
    },
};

export const articlesReducer: Reducer<ArticlesState, ArticlesActions> = reducer(
    initialState,
    on(articlesActions.fetchArticles, (state, { articles }) => {
        return {
            ...state,
            articles,
        };
    }),
    on(articlesActions.fetchArticle, (state, { article }) => {
        return { ...state, selectedArticle: article };
    }),
    on(articlesActions.deleteArticle, (state) => {
        return {
            ...state,
            selectedArticle: initialState.selectedArticle,
        };
    }),
    on(articlesActions.submitArticle, (state, { article }) => {
        return {
            ...state,
            articles: [...state.articles, article],
        };
    }),
    on(articlesActions.setSelectedArticle, (state, { selectedArticle }) => {
        return {
            ...state,
            selectedArticle: {
                ...state.selectedArticle,
                ...selectedArticle,
            },
        };
    })
);
