import React, { FC } from 'react';
import { StyleProp, ViewStyle, View, StyleSheet } from 'react-native';

import colors from '@packages/core/styles/colors';
import { Icon } from './icon';
import { Typography } from './typography';

interface SuccessFailBadgeProps {
    success: boolean;
    style?: StyleProp<ViewStyle>;
    children?: React.ReactNode;
}
export const SuccessFailBadge: FC<SuccessFailBadgeProps> = ({ success, children, style }) => {
    return (
        <View style={[styles.container, success ? styles.successBadge : styles.failBadge, style]}>
            <View style={[styles.iconWrapper, success ? styles.successIcon : styles.failIcon]}>
                <Icon name={success ? 'checkmark' : 'x-close'} color="white" size={16} />
            </View>

            <Typography variant="label">{children}</Typography>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 12,
        alignSelf: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        height: 28,
        borderRadius: 18,
    },
    successBadge: {
        backgroundColor: colors.greenFour,
    },
    failBadge: {
        backgroundColor: colors.redFour,
    },
    iconWrapper: {
        marginRight: 12,
        height: 20,
        width: 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
    },
    successIcon: {
        backgroundColor: colors.greenOne,
    },
    failIcon: {
        backgroundColor: colors.redOne,
    },
});
