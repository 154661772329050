import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import useQuery from '@web/core/hooks/use-query';

import { Button, Icon, Typography } from '@packages/ui/shared';
import {
    SORT_DIRECTION,
    TableCell,
    TableHeader,
    TablePagination,
    TableRenderer,
    TableRow,
} from '@web/components/table';
import RecentSearchTypeahead from '@web/components/recent-search-typeahead';

import { toughbookAttachmentsService } from '@web/services/singletons';
import { Attachment } from '@packages/models/api';

interface ToughbookAttachmentTableRowModal extends Attachment {
    rowId: string;
    checked: boolean;
}

export const TouchbookAttachments: FC = () => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const { t } = useTranslation();

    const queryParams = useQuery();
    const quickSearchQuery = queryParams.get('query') || '';
    const pageIndex = parseInt(queryParams.get('page') || '0', 10);
    const pageSize = parseInt(queryParams.get('size') || '10', 10);
    const sortOrder = queryParams.get('sortOrder') || '';
    const sortDir = (queryParams.get('sortDir') as SORT_DIRECTION) || undefined;

    const [totalAttachments, setTotalAttachments] = useState(0);

    const searchInputRef = useRef(null);
    const [recentSearches, setRecentSearches] = useState<string[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [tableRows, setTableRows] = useState<ToughbookAttachmentTableRowModal[]>([]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            try {
                const response = await toughbookAttachmentsService.getAttachments({
                    size: pageSize,
                    page: pageIndex,
                    query: quickSearchQuery,
                    sortOrder: sortOrder,
                    sortDir: sortDir,
                });

                if (response.success) {
                    const attachmentsForTableRows = response.data.attachments.map((attachment) => {
                        return { ...attachment, rowId: '', checked: false };
                    });

                    setTableRows(attachmentsForTableRows);
                    setTotalAttachments(response.data.totalCount);
                } else {
                    throw response.data;
                }
            } catch (error) {
                window.alert(`Toughbook Error: ${error.message}`);
            }

            setIsLoading(false);
        }

        fetchData();
    }, [pageIndex, pageSize, quickSearchQuery, sortOrder, sortDir]);

    const RecentSearchTypeaheadChange = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));

            if (text) {
                params.set('query', text);

                if (!recentSearches.includes(text)) {
                    setRecentSearches([text, ...recentSearches]);
                }
            } else {
                params.delete('query');
            }

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, recentSearches, search]
    );

    const handleSort = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('sortOrder', sortBy);
            params.set('sortDir', sortDirection);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    return (
        <div className="pt-7 pl-7 pr-7 d-flex h-100">
            <div className="d-flex w-100 flex-column">
                <div className="mb-6 d-flex justify-content-between">
                    <Typography variant="h2">
                        {t('views:toughbookAttachments.title', 'Toughbook Attachments')}
                    </Typography>
                </div>
                <div className="mb-8 d-flex justify-content-between">
                    <div className="w-50">
                        <RecentSearchTypeahead
                            //@ts-ignore TODO: Figure out how to pass refs to forwardRef
                            ref={searchInputRef}
                            onChange={RecentSearchTypeaheadChange}
                            placeholder={t(
                                'views:toughbookAttachments.search.placeholder',
                                'Search by VIN, filename, filetype, or QMR ID...'
                            )}
                            defaultInputValue={quickSearchQuery ? quickSearchQuery : ''}
                            recentSearches={recentSearches}
                            quickSearchQuery={quickSearchQuery}
                        />
                    </div>
                </div>

                <TableRenderer<ToughbookAttachmentTableRowModal>
                    isLoading={isLoading}
                    tableRowsData={tableRows}
                    tableRowsDataSetter={setTableRows}
                    noResultsIconName="administration"
                    tableHeaderRowRenderer={() => {
                        return (
                            <TableRow>
                                <TableHeader
                                    sortable
                                    sortDirection={sortOrder === 'UPLOADED' ? sortDir : undefined}
                                    onSort={(_event, sortDirection) => {
                                        handleSort('UPLOADED', sortDirection);
                                    }}
                                >
                                    <Typography variant="label">
                                        {t('views:toughbookAttachments.table.uploaded', 'Uploaded')}
                                    </Typography>
                                </TableHeader>
                                <TableHeader
                                    sortable
                                    sortDirection={sortOrder === 'VIN' ? sortDir : undefined}
                                    onSort={(_event, sortDirection) => {
                                        handleSort('VIN', sortDirection);
                                    }}
                                >
                                    <Typography variant="label">
                                        {t('views:toughbookAttachments.table.vin', 'VIN')}
                                    </Typography>
                                </TableHeader>
                                <TableHeader
                                    sortable
                                    sortDirection={sortOrder === 'FILE_NAME' ? sortDir : undefined}
                                    onSort={(_event, sortDirection) => {
                                        handleSort('FILE_NAME', sortDirection);
                                    }}
                                >
                                    <Typography variant="label">
                                        {t('views:toughbookAttachments.table.fileName', 'File Name')}
                                    </Typography>
                                </TableHeader>
                                <TableHeader
                                    sortable
                                    sortDirection={sortOrder === 'FILE_SIZE' ? sortDir : undefined}
                                    onSort={(_event, sortDirection) => {
                                        handleSort('FILE_SIZE', sortDirection);
                                    }}
                                >
                                    <Typography variant="label">
                                        {t('views:toughbookAttachments.table.fileSize', 'File Size')}
                                    </Typography>
                                </TableHeader>
                                <TableHeader
                                    sortable
                                    sortDirection={sortOrder === 'QMR_LINK' ? sortDir : undefined}
                                    onSort={(_event, sortDirection) => {
                                        handleSort('QMR_LINK', sortDirection);
                                    }}
                                >
                                    <Typography variant="label">
                                        {t('views:toughbookAttachments.table.qmrLink', 'QMR Link')}
                                    </Typography>
                                </TableHeader>
                                <TableHeader>
                                    <Typography variant="label">
                                        {t('views:toughbookAttachments.table.download', 'Download')}
                                    </Typography>
                                </TableHeader>
                            </TableRow>
                        );
                    }}
                    tableBodyRowRenderer={(rowData) => {
                        return (
                            <TableRow key={rowData.rowId}>
                                <TableCell>
                                    <Typography>{rowData.uploadedTimestampDescription}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{rowData.vin}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{rowData.fileName}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{rowData.sizeInBytesDescription}</Typography>
                                </TableCell>
                                <TableCell>
                                    {rowData.qmrs.map((qmr, index) => {
                                        return (
                                            <div>
                                                <a href={qmr.qmrLink}>{qmr.qmrName}</a>
                                            </div>
                                        );
                                    })}
                                </TableCell>
                                <TableCell>
                                    <div className="d-flex justify-content-center">
                                        <a
                                            href={rowData.downloadUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Button variant="ghost-gray">
                                                <Icon name="download" />
                                            </Button>
                                        </a>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    }}
                />

                <TablePagination
                    page={pageIndex}
                    size={pageSize}
                    total={totalAttachments}
                    onClick={handlePaginationClick}
                    onSizeChange={handlePaginationSizeChange}
                />
            </div>
        </div>
    );
};
