import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Label, LoadingCircle, TextInputHelper, Typography } from '@packages/ui/shared';
import { DetailViewAside } from '@web/components/detail-view';
import { WorksheetUser } from './worksheets-user';
import Select from '@web/components/select';
import { UseWorksheetFormReturnType } from '../hooks/use-worksheet-form';
import { WorksheetForm } from '@packages/models/api';
import { WorksheetAction } from '../types';
import useWorksheetFormTranslation from '../hooks/use-worksheet-form-translation';

export enum FormRightBarType {
    Form = 'Form',
    Preview = 'Preview',
}
interface Props {
    state?: UseWorksheetFormReturnType['state'];
    worksheetInfo: WorksheetForm;
    actions?: UseWorksheetFormReturnType['actions'];
    type: FormRightBarType;
}

const WorksheetRightSideBar = ({ state, worksheetInfo, actions, type }: Props) => {
    const { dataTranslate } = useWorksheetFormTranslation();
    const creatorUser = worksheetInfo.createdAccount;
    const createdTime = worksheetInfo.createdTimestampForDisplay;
    const updatedUser = worksheetInfo.lastUpdatedAccount;
    const updatedTime = worksheetInfo.lastUpdatedTimestampForDisplay;
    const [searchParams] = useSearchParams();
    const worksheetAction = searchParams.get('action') as WorksheetAction;
    const worksheetId = searchParams.get('worksheetId');

    return (
        <DetailViewAside>
            <Row className="mb-8">
                <Col>
                    <Typography variant="h4" color="blueOne">
                        {dataTranslate.adminFormBuilder.title}
                    </Typography>
                </Col>
            </Row>
            <Row className="mb-6">
                <Col>
                    <div className="mb-1 d-flex align-items-center">
                        <Label required>{dataTranslate.adminFormBuilder.inputs.formName.label}</Label>
                    </div>
                    <div className="mb-6">
                        {type === FormRightBarType.Form && worksheetAction === WorksheetAction.New && !worksheetId ? (
                            <TextInputHelper
                                required
                                errorMessage={state?.nameErrorMessage}
                                value={worksheetInfo.worksheetGroupName}
                                onChangeText={(name) => actions?.handleNameChange(name)}
                                placeholder={dataTranslate.adminFormBuilder.inputs.formName.placeholder}
                            />
                        ) : (
                            <Typography>{worksheetInfo.worksheetGroupName}</Typography>
                        )}
                    </div>
                    <div className="mb-2">
                        <Typography variant="h6">{dataTranslate.adminFormBuilder.inputs.formType.label}</Typography>
                    </div>
                    <div className="mb-6">
                        {!actions?.isCaseTypeLoading ? (
                            <>
                                {type === FormRightBarType.Form &&
                                state?.caseTypes &&
                                worksheetAction === WorksheetAction.New ? (
                                    <Select
                                        value={worksheetInfo.caseTypeId}
                                        options={state.caseTypes.map((caseType) => ({
                                            title: caseType?.caseType,
                                            value: caseType?.caseTypeId,
                                        }))}
                                        onChange={(event) => actions?.handleChangeCaseType(event.currentTarget.value)}
                                    />
                                ) : (
                                    <Typography>{worksheetInfo.caseType}</Typography>
                                )}
                            </>
                        ) : (
                            <LoadingCircle style={{ position: 'absolute' }} />
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                {worksheetAction === WorksheetAction.Edit && (
                    <Col>
                        {creatorUser && (
                            <WorksheetUser
                                label={dataTranslate.adminFormList.headers.creator}
                                avatar={{
                                    firstName: creatorUser?.firstName,
                                    lastName: creatorUser?.lastName,
                                    imageUrl: 'https://picsum.photos/200/300',
                                }}
                                name={creatorUser?.name || ' '}
                                date={createdTime}
                            />
                        )}

                        {updatedUser && (
                            <WorksheetUser
                                label={dataTranslate.adminFormList.headers.lastEditor}
                                avatar={{
                                    firstName: updatedUser?.firstName,
                                    lastName: updatedUser?.lastName,
                                    imageUrl: 'https://picsum.photos/200/300',
                                }}
                                name={updatedUser?.name || ''}
                                date={updatedTime}
                            />
                        )}
                    </Col>
                )}
            </Row>
            {/* TODO :- for worksheets version */}
            {/* {worksheetAction !== WorksheetAction.New && (
                <>
                    <Row>
                        <Col>
                            <Typography variant="h6">{'Versions'}</Typography>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="d-flex justify-content-between align-item-center">
                                <View>
                                    <Typography color="blueOne" variant="label">
                                        name
                                    </Typography>
                                    <Typography variant="caption">{new Date().toDateString()}</Typography>
                                </View>
                                <Link to={''} style={{ textDecoration: 'none' }}>
                                    <Icon name="launch" color="blueOne" size={28} />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </>
            )} */}
        </DetailViewAside>
    );
};

export default WorksheetRightSideBar;
