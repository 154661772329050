import React, { FC, useEffect, useState } from 'react';
import { Modal, Button, Form, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { TextInputHelper } from '@packages/ui/shared';
import { useAuthState } from '@packages/contexts/auth';

const useStyles = createUseStyles({
    advancedSearchModal: {
        width: 567,
        maxWidth: '90%',
    },
});

interface AdvancedSearchModalProps extends ModalProps {
    onSave(name: string, isPublic: boolean, rolesToShareSelected: string[]): void;
    defaultSavedSearch?: any;
}

const AdvancedSearchModal: FC<AdvancedSearchModalProps> = ({ onSave, ...props }) => {
    const classes = useStyles();
    const { account } = useAuthState();

    const [nameInputValue, setNameInputValue] = useState('');
    const [publicCheckboxValue, setPublicCheckboxValue] = useState(false);
    const [rolesToShareSelected, setRolesToShareSelected] = useState<string[]>([]);
    const rolesToShare = [
        { label: 'DSQM', value: 'DSQM' },
        { label: 'RSQM', value: 'RSQM' },
        { label: 'SOA Super User', value: 'SOA_SUPER_USER' },
        { label: 'NASI + SIA Admin', value: 'NASI_SIA_ADMIN' },
        { label: 'QA Admin', value: 'QA_ADMIN' },
    ];

    useEffect(() => {
        setNameInputValue(props?.defaultSavedSearch?.name || '');
        setPublicCheckboxValue(props?.defaultSavedSearch?.publicSearch || false);
        setRolesToShareSelected(props?.defaultSavedSearch?.sharedUserRoles || []);
    }, [props.defaultSavedSearch]);

    return (
        <Modal dialogClassName={classes.advancedSearchModal} centered {...props}>
            <Modal.Header closeButton placeholder={''}>
                <h4 className="mb-0">Save Search</h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="mb-4">
                        <TextInputHelper
                            label="Name search"
                            placeholder="Enter name"
                            value={nameInputValue}
                            onChangeText={setNameInputValue}
                        />
                    </div>
                    {account?.systemCapabilities.canShareSearchWithUserRoles && (
                        <>
                            <Form.Check
                                id="save-search--public"
                                name="save-search"
                                value="PUBLIC"
                                label="Mark it as public"
                                disabled={rolesToShareSelected.length > 0}
                                checked={publicCheckboxValue}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPublicCheckboxValue(event.currentTarget.checked);
                                    if (event.currentTarget.checked) {
                                        setRolesToShareSelected([]);
                                    }
                                }}
                            />
                            <hr
                                style={{
                                    margin: '5px 0px',
                                    height: '5px',
                                }}
                            />
                            <p>Select user roles to share with</p>
                            {rolesToShare.map((ele) => (
                                <Form.Check
                                    id={`roles-to-share-${ele.value}`}
                                    name="roles-to-share"
                                    value={ele.value}
                                    label={ele.label}
                                    disabled={publicCheckboxValue}
                                    checked={
                                        rolesToShareSelected.length > 0 && rolesToShareSelected.indexOf(ele.value) > -1
                                    }
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (event.currentTarget.checked) {
                                            setPublicCheckboxValue(false);
                                            if (rolesToShareSelected.length > 0) {
                                                setRolesToShareSelected([...rolesToShareSelected, ele.value]);
                                            } else {
                                                setRolesToShareSelected([ele.value]);
                                            }
                                        } else {
                                            const restOfTheArray = rolesToShareSelected.filter(
                                                (arrEntry) => arrEntry !== ele.value
                                            );
                                            setRolesToShareSelected(restOfTheArray);
                                        }
                                    }}
                                />
                            ))}
                        </>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="mr-2" onClick={props.onHide}>
                    Cancel
                </Button>
                <Button
                    disabled={!nameInputValue}
                    variant="primary"
                    onClick={() => {
                        onSave(nameInputValue, publicCheckboxValue, rolesToShareSelected);
                    }}
                >
                    Save &amp; go to results
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AdvancedSearchModal;
