import React, { FC } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import classNames from 'classnames';

import fonts from '@web/jss/fonts';
import { Icon, StsIconName } from '@packages/ui/shared';

interface FormElementProps {
    title: string;
    iconName: StsIconName;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
}

export const FormElement: FC<FormElementProps> = ({ title, iconName, provided, snapshot }) => {
    const classes = useFormElementStyles();

    return (
        <>
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classNames({
                    [classes.formElementIsNotDragging]: !snapshot.isDragging,
                })}
            >
                <div
                    className={classNames({
                        [classes.formElement]: true,
                        [classes.formElementIsDragging]: snapshot.isDragging,
                    })}
                >
                    <div className={classes.formElementIconOuter}>
                        <Icon name={iconName} />
                    </div>
                    <span>{title}</span>
                </div>
            </div>
            {snapshot.isDragging && (
                <div className={classes.formElement}>
                    <div className={classes.formElementIconOuter}>
                        <Icon name={iconName} />
                    </div>
                    <span>{title}</span>
                </div>
            )}
        </>
    );
};

const useFormElementStyles = createUseStyles({
    formElement: {
        padding: 12,
        display: 'flex',
        marginBottom: 10,
        alignItems: 'center',
        backgroundColor: colors.white,
        border: `1px dashed ${colors.grayThree}`,
        '& span': {
            ...fonts.xs,
            display: 'block',
        },
    },
    formElementIsDragging: {
        transform: 'rotate(-10deg)',
    },
    formElementIsNotDragging: {
        transform: 'none !important',
    },
    formElementIconOuter: {
        width: 24,
        height: 24,
        flexShrink: 0,
        marginRight: 12,
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
    },
});
