import * as React from 'react';
import { DashboardContext, DashboardDispatchContext } from './dashboard.context';

export const useDashboardState = () => {
    const state = React.useContext(DashboardContext);

    if (state === undefined) {
        throw new Error('useDashboardState must be used within a DashboardProvider');
    }

    return state;
};

export const useDashboardDispatch = () => {
    const state = React.useContext(DashboardDispatchContext);
    if (state === undefined) {
        throw new Error('useDashboardState must be used within a DashboardProvider');
    }

    return state;
};
