import { format, isValid, parseISO } from 'date-fns';
import { DATE_FORMAT } from '@web/config/constants';

// Returns a formatted date string based on the provided date and format.
export function formatDate(date: Date | undefined | string, dateFormat: string = DATE_FORMAT.MM_DD_YEAR): string {
    let dateObj: Date;

    // Handle invalid or missing date
    if (!date) {
        throw new Error('Invalid date');
    }

    // Parse string to Date
    if (typeof date === 'string') {
        dateObj = parseISO(date);
    } else if (date instanceof Date) {
        dateObj = date;
    } else {
        throw new Error('Unexpected date type');
    }

    // Check if the date object is valid
    if (!isValid(dateObj)) {
        throw new Error('Invalid date');
    }

    return format(dateObj, dateFormat);
}
