import './polyfills';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'firebase/messaging';
import iconFont from '@packages/ui/assets/fonts/STS-Icons.ttf';
import { pushNotificationsService } from '@web/services/singletons';
import { getReleaseVersion, getEnvironment } from '@web/config';
import React from 'react';
import App from './app';

if (!__DEV__) {
    Sentry.init({
        dsn: 'https://635a946a8e8e445ab871149319bd6967@o495263.ingest.sentry.io/5613306',
        integrations: [new Integrations.BrowserTracing()],
        release: getReleaseVersion(),
        environment: getEnvironment(),
        // "Capturing a single trace involves minimal overhead, but capturing traces for every page load or every API request may add an undesirable load to your system"
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#sampling-transaction-events
        tracesSampleRate: 0.25,
    });
}

const style = document.createElement('style');
style.type = 'text/css';
style.appendChild(
    document.createTextNode(`
@font-face {
  src: url(${iconFont});
  font-family: STS-Icons;
}
`)
);
document.head.appendChild(style);

pushNotificationsService.registerServiceWorker();

ReactDOM.render(<App />, document.getElementById('root'));
