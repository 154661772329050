import React from 'react';
import { View, ViewStyle } from 'react-native';

interface FlexProps extends ViewStyle {
    children: any;
    /**
     * @desc Auto add Spacer between each child set to this size if greater than 0.
     */
    autoSpacing?: number;
}

interface SpacerProps {
    size?: number;
}

function Spacer({ size = 20 }: SpacerProps) {
    return <View style={{ flexBasis: size }} />;
}

/**
 * @desc Easily render a flexbox.
 * Default direction is `column`.
 */
export function Flex(props: FlexProps) {
    const { autoSpacing } = props;

    if (autoSpacing && autoSpacing > 0) {
        return (
            <View style={props}>
                {React.Children.map(props.children, (node, index) => {
                    const isLastNode = index === React.Children.count(props.children) - 1;

                    return (
                        <>
                            {node}
                            {!isLastNode && <Spacer size={props.autoSpacing} />}
                        </>
                    );
                })}
            </View>
        );
    }

    return <View style={props}>{props.children}</View>;
}

Flex.Spacer = Spacer;

export default Flex;
