import React, { FC } from 'react';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import colors from '@packages/core/styles/colors';
import { Icon, Typography } from '@packages/ui/shared';

const useCustomToggleStyles = createUseStyles({
    customToggle: {
        borderRadius: 3,
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        padding: '2px 4px 2px 8px',
        backgroundColor: colors.grayTwo,
    },
});

interface CustomToggleProps {
    onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
    children?: React.ReactNode;
}

const CustomToggle: FC<CustomToggleProps> = React.forwardRef(({ children, onClick }, ref) => {
    const classes = useCustomToggleStyles();

    function handleButtonClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.preventDefault();

        if (!onClick) {
            return;
        }

        onClick(event);
    }

    return (
        <div tabIndex={0} ref={ref as any} className={classes.customToggle} onClick={handleButtonClick}>
            {children}
        </div>
    );
});

const useDropdownStyles = createUseStyles({
    dropdown: {
        '& .dropdown-menu': {
            width: 200,
            padding: '8px 0 !important',
            borderRadius: `3px !important`,
            border: `1px solid ${colors.grayThree} !important`,
            boxShadow: `0px 16px 12px -12px rgba(0, 0, 0, 0.16) !important`,
        },
        '& .dropdown-item': {
            padding: '8px 16px',
            '& > div': {
                display: 'block',
            },
            '&:hover': {
                backgroundColor: colors.grayOne,
            },
            '&:active': {
                backgroundColor: colors.grayOne,
            },
        },
    },
});

export const ActionDropdown: FC<DropdownProps> = ({ children, ...props }) => {
    const classes = useDropdownStyles();

    return (
        <Dropdown {...props} className={classes.dropdown}>
            <Dropdown.Toggle as={CustomToggle} id="action-dropdown">
                <Typography variant="label">Actions</Typography>
                <Icon name="arrow-small-down" size={16} />
            </Dropdown.Toggle>
            <Dropdown.Menu>{children}</Dropdown.Menu>
        </Dropdown>
    );
};

export default ActionDropdown;
