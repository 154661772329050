import React from 'react';
import { StyleSheet, View } from 'react-native';

import colors from '@packages/core/styles/colors';

export const UnreadStatusIndicator = () => {
    return (
        <View style={styles.unreadIndicatorOuter}>
            <View style={styles.unreadIndicatorInner} />
        </View>
    );
};

const styles = StyleSheet.create({
    unreadIndicatorOuter: {
        width: 16,
        height: 16,
        marginLeft: 11,
        borderRadius: 8,
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(31, 81, 143, 0.15)',
    },
    unreadIndicatorInner: {
        width: 11,
        height: 11,
        borderRadius: 5.5,
        alignSelf: 'center',
        backgroundColor: colors.blueOne,
    },
});
