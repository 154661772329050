import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { TextInputHelper, Typography } from '@packages/ui/shared';
import { DraggableWorksheetFormComponent, FormElementFile, WorksheetFormElementOption } from '@packages/models/api';
import Select from '@web/components/select';
import AddField from './add-field';
import { AddFieldType } from './worksheet-form-component-edit-modal';
import colors from '@packages/core/styles/colors';
import { useValidationField } from './hooks/useValidationField';
import useTranslationData from './hooks/useTranslationData';
import { WorksheetCharacterLengthValidation } from '@web/administration/views/worksheets/utils';

enum RATING_FORMAT {
    ONE_TO_FIVE = 'ONE_TO_FIVE',
}

interface FormWorksheetEditLikertProps extends DraggableWorksheetFormComponent {
    onChange({
        formElementLabel,
        options,
        formElementFile,
        formElementDescription,
    }: {
        formElementLabel?: string;
        options?: WorksheetFormElementOption[] | undefined;
        worksheetElementTooltip?: string;
        formElementFile?: FormElementFile;
        formElementDescription?: string;
    }): void;
    addField: AddFieldType;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormEditLikert: FC<FormWorksheetEditLikertProps> = ({
    formElementLabel,
    formElementFile,
    options,
    onChange,
    formElementTooltip,
    formElementDescription,
    addField,
    setIsValid,
}) => {
    const [ratingFormateInputValue, setRatingFormateInputValue] = useState(RATING_FORMAT.ONE_TO_FIVE);
    const {
        fieldHandlers: { handleRequired },
        fieldErrorMessages,
        errorsInit,
        isValid,
    } = useValidationField();
    const { draggableElementsDataTranslate } = useTranslationData();
    useEffect(() => {
        setIsValid(isValid);
    }, [Object.keys(fieldErrorMessages || {})]);

    const handleOptionLabelChange = useCallback(
        (index: number, text: string) => {
            if (!options || text.trim().length > WorksheetCharacterLengthValidation.QuestionLength) {
                return;
            }
            const optionsClone = cloneDeep(options);
            handleRequired(text, options[index].optionId, errorsInit.ratingValue);
            optionsClone[index].optionLabel = text;

            onChange({ options: optionsClone });
        },
        [handleRequired, onChange, options]
    );

    return (
        <>
            <AddField
                addFieldValues={addField}
                onchangeText={onChange}
                formElementFile={formElementFile}
                formElementTooltip={formElementTooltip}
                formElementDescription={formElementDescription}
                formElementLabel={formElementLabel}
                errorMessage={fieldErrorMessages?.questionLabel}
                handleRequired={handleRequired}
            />

            <hr style={{ borderWidth: 2, borderColor: colors.grayTwo }} />

            <div className="mb-2 mt-5">
                <Typography variant="label">Options</Typography>
            </div>
            <div className="mb-6">
                <Select
                    value={ratingFormateInputValue}
                    options={[
                        {
                            title: '1 to 5',
                            value: RATING_FORMAT.ONE_TO_FIVE,
                        },
                    ]}
                    onChange={(event) => {
                        setRatingFormateInputValue(event.currentTarget.value as RATING_FORMAT);
                    }}
                />
            </div>
            <div className="mb-2">
                <Typography variant="label">Rating Values</Typography>
            </div>
            {ratingFormateInputValue === RATING_FORMAT.ONE_TO_FIVE && (
                <>
                    {options &&
                        options.map((option, index) => {
                            return (
                                <div key={`${option.optionId}-${index}`} className="d-flex mb-2 align-items-center">
                                    <div className="mr-2">
                                        <Typography>{index + 1}</Typography>
                                    </div>
                                    <TextInputHelper
                                        required
                                        errorMessage={fieldErrorMessages?.[option.optionId]}
                                        style={{ flex: 1 }}
                                        value={option.optionLabel}
                                        onChangeText={(text) => {
                                            handleOptionLabelChange(index, text);
                                        }}
                                        placeholder={draggableElementsDataTranslate.placeholder.questionLabel}
                                    />
                                </div>
                            );
                        })}
                </>
            )}
        </>
    );
};
