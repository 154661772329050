import React, { FC, useState, useCallback } from 'react';
import { Row, Col, Form, Table } from 'react-bootstrap';
import { AsyncTypeahead, TypeaheadState, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { uniqBy } from 'lodash';
import classNames from 'classnames';
import { useMatch } from 'react-router-dom';

import { qmrsService } from '@web/services/singletons';
import { useQmrDispatch, useQmrState, updateQmrDtcCodes, patchQmr } from '@packages/contexts/qmrs';
import { DtcCode } from '@packages/models/api';
import { useAbortController } from '@packages/core/http';
import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';
import { Icon, Button } from '@packages/ui/shared';

const useDtcCodesDraftStyles = createUseStyles({
    suggestedDtcText: {
        marginBottom: 0,
        ...fonts.xs,
        color: colors.black,
    },
    dtcCodeNameText: {
        marginBottom: 0,
        ...fonts.xs,
    },
    dtcCodeDescriptionText: {
        marginBottom: 0,
        ...fonts.xxs,
    },
    dtcTable: {
        '& thead': {
            height: 40,
            backgroundColor: colors.grayTwo,
        },

        '& tr': {
            border: 'none',
            borderRight: `1px solid ${colors.grayThree}`,
            borderLeft: `1px solid ${colors.grayThree}`,

            '& th': {
                '&:first-of-type': {
                    paddingLeft: 16,
                },
                '&:last-of-type': {
                    paddingRight: 24,
                },
                borderTop: `1px solid ${colors.grayThree}`,
                borderBottom: `1px solid ${colors.grayThree}`,
                fontSize: '1.1rem',
                fontWeight: 'normal',
                textTransform: 'uppercase',
                letterSpacing: 1,
                color: '#555555',
            },
            '& td': {
                borderTop: 'none',
                borderBottom: `1px solid ${colors.grayThree}`,
                padding: 16,
            },
        },
    },
    dtcCodeName: {
        margin: 0,
        marginLeft: 8,
        ...fonts.s,
    },
    dtcCodeDescription: {
        ...fonts.xxs,
        color: colors.textDarkTertiary,
    },
});

const DtcCodesDraft: FC = () => {
    const classes = useDtcCodesDraftStyles();
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');
    const dtcCodes = qmr?.dtcCodes ?? [];

    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<DtcCode[]>([]);

    const primaryMap = dtcCodes.reduce((map, code) => {
        map.set(code.dtcCodeId, !!code.markedAsPrimary);
        return map;
    }, new Map<string, boolean>());

    const freezeFrameMap = dtcCodes.reduce((map, code) => {
        map.set(code.dtcCodeId, !!code.freezeFrameDataAvailable);
        return map;
    }, new Map<string, boolean>());

    const queryDtcCodes = (query: string) => {
        if (!query) {
            return;
        }

        setIsLoading(true);

        qmrsService
            .fetchDtcCodes({
                searchParams: { query },
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    setIsLoading(false);
                    throw response.data;
                }

                const NonSelectedDtcCodes = response.data.dtcCodes.filter((dtcCode) => {
                    if (!qmr || !qmr.dtcCodes) {
                        return true;
                    }

                    const matchingDtcCode = qmr.dtcCodes.find((qmrDtcCode) => {
                        return qmrDtcCode.dtcCodeId === dtcCode.dtcCodeId;
                    });

                    return matchingDtcCode ? false : true;
                });

                setSearchResults(NonSelectedDtcCodes);
                setIsLoading(false);
            });
    };

    const saveChanges = (updatedCodes: DtcCode[]) => {
        if (!qmr) {
            return;
        }

        if (!!editRouteMatch) {
            return patchQmr({
                qmrId: qmr.qmrId,
                qmrsService,
                qmrDispatch,
                isLocalPatch: true,
                qmrPatch: {
                    dtcCodes: updatedCodes,
                },
            });
        }

        return updateQmrDtcCodes({
            qmrId: qmr.qmrId,
            dtcCodes: updatedCodes.map((code) => {
                return {
                    dtcCodeId: code.dtcCodeId,
                    markedAsPrimary: !!code.markedAsPrimary,
                    freezeFrameDataAvailable: !!code.freezeFrameDataAvailable,
                };
            }),
            qmrsService,
            qmrDispatch,
            signal: abortSignalRef.current,
        });
    };

    return (
        <>
            <Row>
                <Col>
                    <h4 className="mb-3">
                        {t('qmr:sections.dtc.title')}

                        <small className="d-inline text-muted ml-2">({t('text:optional')})</small>
                    </h4>

                    <Form.Label className="mb-2">{t('qmr:inputs.dtc.label')}</Form.Label>

                    {dtcCodes.length > 0 && (
                        <Table className={classNames('mb-4', classes.dtcTable)}>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="d-flex justify-content-between">
                                            {t('qmr:sections.dtc.dtcCodeHeader', 'Dtc Code')}
                                            <div>{t('qmr:sections.dtc.dtcFfd', 'Mark Freeze Frame Data in SSM')}</div>
                                        </div>
                                    </th>
                                    <th className="text-right">
                                        {t('qmr:sections.dtc.dtcPrimaryHeader', 'Mark as Primary')}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {dtcCodes.map((dtcCode, index) => (
                                    <tr key={dtcCode.dtcCodeId}>
                                        <td>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-row">
                                                    <Button
                                                        variant="ghost-gray"
                                                        disabled={
                                                            !!editRouteMatch ? !qmr?.capabilities.deleteDtcOnQmr : false
                                                        }
                                                        onPress={() => {
                                                            if (
                                                                window.confirm(
                                                                    t(
                                                                        'screens:addDtcCodes.confirmDelete.message',
                                                                        'Deleting a DTC Code cannot be undone. Are you sure you want to delete this DTC Code?'
                                                                    )
                                                                )
                                                            ) {
                                                                saveChanges([
                                                                    ...dtcCodes.slice(0, index),
                                                                    ...dtcCodes.slice(index + 1),
                                                                ]);
                                                            }
                                                        }}
                                                    >
                                                        <Icon name="trash" />
                                                    </Button>

                                                    <p className={classes.dtcCodeName}>
                                                        {dtcCode.dtcCode}

                                                        <small className={classes.dtcCodeDescription}>
                                                            {dtcCode.description}
                                                        </small>
                                                    </p>
                                                </div>

                                                <Form.Check
                                                    disabled={
                                                        !!editRouteMatch ? !qmr?.capabilities.editDtcOnQmr : false
                                                    }
                                                    className="ml-auto mr-6"
                                                    inline
                                                    label=" "
                                                    type="checkbox"
                                                    id={`${dtcCode.dtcCodeId}-ffd`}
                                                    name="freezeFrameDataAvailable"
                                                    checked={freezeFrameMap.get(dtcCode.dtcCodeId)}
                                                    onChange={() => {
                                                        saveChanges([
                                                            ...dtcCodes.slice(0, index),
                                                            {
                                                                ...dtcCode,
                                                                freezeFrameDataAvailable:
                                                                    !dtcCode.freezeFrameDataAvailable,
                                                            },
                                                            ...dtcCodes.slice(index + 1),
                                                        ]);
                                                    }}
                                                />
                                            </div>
                                        </td>

                                        <td>
                                            <div className="d-flex justify-content-end">
                                                <Form.Check
                                                    disabled={
                                                        !!editRouteMatch ? !qmr?.capabilities.editDtcOnQmr : false
                                                    }
                                                    className="mr-4"
                                                    inline
                                                    label={' '}
                                                    id={`${dtcCode.dtcCodeId}-primary`}
                                                    name="markedAsPrimary"
                                                    checked={primaryMap.get(dtcCode.dtcCodeId)}
                                                    onChange={() => {
                                                        saveChanges([
                                                            ...dtcCodes.slice(0, index),
                                                            {
                                                                ...dtcCode,
                                                                markedAsPrimary: !dtcCode.markedAsPrimary,
                                                            },
                                                            ...dtcCodes.slice(index + 1),
                                                        ]);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}

                    <div className="mb-5">
                        <AsyncTypeahead
                            emptyLabel="none"
                            disabled={
                                !!editRouteMatch
                                    ? !qmr?.capabilities.editDtcOnQmr && dtcCodes.length >= 50
                                    : dtcCodes.length >= 50
                            }
                            isLoading={isLoading}
                            labelKey={(option) => `${option.dtcCode} - ${option.description}`}
                            renderMenu={(results, menuProps, ...args) => {
                                const typeaheadState = (args as any)[0] as TypeaheadState<DtcCode>;

                                if (!typeaheadState.text) {
                                    return null;
                                }

                                const title = isLoading
                                    ? t('text:loading.searching', 'Searching')
                                    : typeaheadState.text && results.length === 0
                                    ? t('text:noMatches', 'No Matches')
                                    : t('text:suggested');

                                return (
                                    <Menu {...menuProps}>
                                        <Menu.Header>
                                            <p className={classes.suggestedDtcText}>{title}</p>
                                        </Menu.Header>
                                        {results.map((result, index) => {
                                            if ((result as any).paginationOption) {
                                                return null;
                                            }

                                            return (
                                                <MenuItem key={result.dtcCodeId} option={result} position={index}>
                                                    <p className={classes.dtcCodeNameText}>
                                                        <Highlighter search={typeaheadState.text}>
                                                            {`${result.dtcCode} - ${result.description}`}
                                                        </Highlighter>
                                                    </p>
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                );
                            }}
                            renderMenuItemChildren={(item) => {
                                return (
                                    <>
                                        <p className="mb-0">
                                            {item.dtcCode}
                                            {item.description && <span>{`- ${item.description}`}</span>}
                                        </p>
                                    </>
                                );
                            }}
                            flip={true}
                            id="qmr-dtcCodes"
                            selected={[]}
                            placeholder={
                                dtcCodes.length > 0 ? t('qmr:inputs.dtc.addAnother') : t('qmr:inputs.dtc.placeholder')
                            }
                            useCache={false}
                            minLength={1}
                            onSearch={queryDtcCodes}
                            onChange={async ([code]) => {
                                if (!qmr) {
                                    return;
                                }

                                const updatedQmr = await saveChanges(
                                    uniqBy([...dtcCodes, code], (dtcCode) => dtcCode.dtcCodeId)
                                );

                                if (!editRouteMatch && !updatedQmr) {
                                    return;
                                }

                                setSearchResults([]);
                            }}
                            options={searchResults}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default DtcCodesDraft;
