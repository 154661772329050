import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import { AccountLocation as AccountLocationTable, Button, Icon, LoadingCircle, Typography } from '@packages/ui/shared';
import { ProcessedFailCodes } from '../views/fail-code-teams';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';
import { failCodesService } from '@web/services/singletons';
import { FailCode } from '@packages/models/api/fail-codes';

interface AddFailCodePanelProps {
    processedFailCodes: ProcessedFailCodes[];
    missingFailCodes: FailCode[];
    setShowToast: React.Dispatch<React.SetStateAction<{ show: boolean; message: string }>>;
    selectedTeamName: string;
    onDataChanged: () => void;
    setSelectedTeamName: React.Dispatch<React.SetStateAction<any>>;
    selectedFailCode: ProcessedFailCodes;
    setSelectedFailCode: React.Dispatch<
        React.SetStateAction<{ team: string; failcodes: FailCode[]; rowId: string; initialFailCodes: FailCode[] }>
    >;
    failcode: string;
}

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.grayOne,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    panelCenterContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    panelContainer: {
        padding: 15,
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    radioDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '85%',
        marginTop: 2,
    },
    radio: {
        display: 'flex',
        gap: '2px',
    },
});

export const AddFailCodePanel: FC<AddFailCodePanelProps> = (props) => {
    const {
        processedFailCodes,
        missingFailCodes,
        setShowToast,
        selectedTeamName,
        onDataChanged,
        setSelectedTeamName,
        selectedFailCode,
        setSelectedFailCode,
        failcode,
    } = props;
    const classes = useStyles();
    const { dismissAllOverlays } = useContentOverlayState();
    const [isLoading, setIsLoading] = useState(false);
    const [failCodeTeam, setFailCodeTeam] = useState<ProcessedFailCodes[]>([]);
    const [failCodesWithTeams, setFailCodesWithTeams] = useState<FailCode[]>([]);
    const [failCode, setFailCode] = useState<FailCode>({ failCode: '', description: '', grouping: '' });
    const [missingFailCode, setMissingFailCode] = useState<FailCode>({ failCode: '', description: '' });
    const [radio, setRadio] = useState<string>('');
    const { toggledOverlays } = useContentOverlayState();
    const [failCodeTeamName, setFailCodeTeamName] = useState<string>('');
    const [failCodeTeams, setFailCodeTeams] = useState<string[]>([]);
    const [newName, setNewName] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [existanceFlag, setExistanceFlag] = useState<boolean>(false);
    const [processedFailCodeTeams, setProcessedFailCodeTeams] = useState<ProcessedFailCodes[]>([]);

    useEffect(() => {
        const filteredTeamNames = processedFailCodes.map((team) => team.team);
        const filteredTeams = processedFailCodes.flatMap((team) => team.failcodes);
        setFailCodesWithTeams(filteredTeams);
        setFailCodeTeams(filteredTeamNames);
    }, []);

    useEffect(() => {
        if (failCodeTeam.length > 0) {
            const filteredTeams = processedFailCodes.filter((team) => team.team !== failCodeTeam[0].team);
            const allFailCodes = filteredTeams.flatMap((team) => team.failcodes);
            setFailCodesWithTeams(allFailCodes);
        }
    }, [failCodeTeam]);

    useEffect(() => {
        const filteredTeams = processedFailCodes.filter((team) => team.team !== selectedFailCode.team);
        setProcessedFailCodeTeams(filteredTeams);
    }, [selectedFailCode]);

    useEffect(() => {
        const check = checkExistance(failCodeTeams, failCodeTeamName);
        setExistanceFlag(check);
    }, [failCodeTeams, failCodeTeamName]);

    useEffect(() => {
        const check = checkExistance(failCodeTeams, newName);
        setExistanceFlag(check);
    }, [newName, failCodeTeams]);

    const handleOptionChange = (event: any) => {
        event.target.value == 'missingFailCode'
            ? toggledOverlays.transferFailCode
                ? setFailCodeTeam([])
                : setFailCode({ failCode: '', description: '', grouping: '' })
            : toggledOverlays.transferFailCode
              ? setFailCodeTeamName('')
              : setMissingFailCode({ failCode: '', description: '' });
        setRadio(event.target.value);
    };

    const handleCancelButtonPress = () => {
        dismissAllOverlays();
        setFailCode({ failCode: '', description: '', grouping: '' });
        setFailCodeTeam([]);
        setFailCodesWithTeams([]);
        setMissingFailCode({ failCode: '', description: '' });
        setRadio('');
        setNewName('');
        setFailCodeTeamName('');
        setFailCodeTeams([]);
        setIsValid(true);
        setSelectedFailCode({ team: '', failcodes: [], rowId: '', initialFailCodes: [] });
    };

    const validateTeamName = (name: string): boolean => {
        const pattern = /^[a-zA-Z0-9 \-]+$/;
        return pattern.test(name);
    };

    const handleAddFailCodeToTeam = () => {
        toggledOverlays.editFailCodeTeam
            ? editFailCodeTeamName(selectedTeamName, newName)
            : toggledOverlays.addFailCode || toggledOverlays.addFailCodeToTeam || toggledOverlays.addMissingFailCode
              ? addFailCode(
                    selectedTeamName,
                    missingFailCode.failCode !== '' ? missingFailCode.failCode : failCode.failCode,
                    missingFailCode.failCode !== '' ? 'missingFailCode' : 'failCodeWithTeam'
                )
              : toggledOverlays.transferFailCode
                ? addFailCodeTeam(
                      radio == 'failCodeWithTeam' ? failCodeTeam[0].team : failCodeTeamName,
                      failcode,
                      'failCodeWithTeam'
                  )
                : addFailCodeTeam(
                      failCodeTeamName,
                      missingFailCode.failCode !== '' ? missingFailCode.failCode : failCode.failCode,
                      missingFailCode.failCode !== '' ? 'missingFailCode' : 'failCodeWithTeam'
                  );
    };

    const handleEditTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event?.target.value;
        setNewName(name);
        setIsValid(validateTeamName(name));
    };

    const checkExistance = (failCodeTeams: string[], teamName: string) => {
        return failCodeTeams.some(
            (team) => team.replace(/\s/g, '').toLowerCase() == teamName.replace(/\s/g, '').toLowerCase()
        );
    };

    async function addFailCode(team: string, failCode: string, flag: string) {
        setIsLoading(true);
        try {
            const response = await failCodesService.addFailCode({
                addFailCodeParams: {
                    action: flag == 'missingFailCode' ? 'Create' : 'Assign',
                    team: team,
                    failCode: failCode,
                },
                ignoreCache: true,
            });

            if (response.success) {
                setShowToast({ show: true, message: 'Fail Code added successfully.' });
                onDataChanged();
            }
        } catch (error) {
            window.alert(`Fail Code Team Error: ${error.message}`);
        }
        setIsLoading(false);
        handleCancelButtonPress();
    }

    async function addFailCodeTeam(team: string, failCode: string, flag: string) {
        setIsLoading(true);
        try {
            if (flag == 'failCodeWithTeam') {
                await failCodesService.deleteFailCode({
                    deleteParams: {
                        action: 'Remove',
                        failCode: failCode,
                    },
                    ignoreCache: true,
                });
            }
            const response = await failCodesService.addFailCodeTeam({
                addParams: {
                    action: 'Create',
                    team: team,
                    failCode: failCode,
                },
                ignoreCache: true,
            });

            if (response.success) {
                setShowToast({
                    show: true,
                    message: toggledOverlays.transferFailCode
                        ? 'Fail Code moved successfully.'
                        : 'Fail Code team added successfully.',
                });
                onDataChanged();
            }
        } catch (error) {
            window.alert(`Fail Code Team Error: ${error.message}`);
        }
        setIsLoading(false);
        handleCancelButtonPress();
    }

    async function editFailCodeTeamName(team: string, newTeam: string) {
        setIsLoading(true);
        try {
            const response = await failCodesService.renameFailCodeTeam({
                renameParams: {
                    action: 'Rename',
                    team: team,
                    newTeam: newTeam,
                },
                ignoreCache: true,
            });

            if (response.success) {
                setShowToast({ show: true, message: 'Fail Code team re-named successfully.' });
                onDataChanged();
            }
        } catch (error) {
            window.alert(`Fail Code Team rename: ${error.message}`);
        }
        setIsLoading(false);
        handleCancelButtonPress();
    }

    const rightPanelDiv = document.getElementById('right-side-panel');
    if (!rightPanelDiv) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={classes.panel} data-testid="test-addFailCodePanel">
            <div className={classes.panelHeader}>
                {toggledOverlays.editFailCodeTeam ? (
                    <Typography>{'Edit Fail Code Team'}</Typography>
                ) : toggledOverlays.transferFailCode ? (
                    <Typography>{'Move Fail Code'}</Typography>
                ) : (
                    <Typography>
                        {toggledOverlays.createFailCodeTeam ? 'Create new fail code team' : 'Add Fail Code'}
                    </Typography>
                )}
                <div className={classes.closeButton} onClick={dismissAllOverlays}>
                    <Icon name="x-close" style={{ display: 'flex' }} />
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                {isLoading && (
                    <div className={classes.panelCenterContainer}>
                        <LoadingCircle size={64} borderWidth={8} borderColor={colors.grayOne} />
                    </div>
                )}

                {!isLoading && (
                    <div className={classes.panelContainer}>
                        <div className="mb-2">
                            <div className="mb-1">
                                {toggledOverlays.editFailCodeTeam ? (
                                    <div className="mb-2">
                                        <div className="mb-1">
                                            <Typography variant="caption2" color="graySix">
                                                Selected Team Name
                                            </Typography>
                                        </div>
                                        <div className="mb-2">
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                value={selectedTeamName}
                                                disabled
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <Typography variant="caption2" color="graySix">
                                                New Name
                                            </Typography>
                                            <div>
                                                {existanceFlag && (
                                                    <Typography variant="error" color="redOne">
                                                        {`*Fail Code with team name`}{' '}
                                                        <span style={{ textTransform: 'capitalize' }}>{newName}</span>{' '}
                                                        {`already exists`}
                                                    </Typography>
                                                )}
                                            </div>
                                        </div>
                                        {!isValid && newName.length > 0 && (
                                            <Typography variant="error" color="redOne">
                                                *Invalid team name. Only letters, numbers, spaces, and hyphens are
                                                allowed.
                                            </Typography>
                                        )}
                                        <div className="mb-2">
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                value={newName}
                                                onChange={handleEditTeamNameChange}
                                                data-testid="new-name-input"
                                            />
                                        </div>
                                    </div>
                                ) : toggledOverlays.createFailCodeTeam ? (
                                    <div className="mb-2">
                                        <div className="mb-1">
                                            <Typography variant="caption2" color="graySix">
                                                Fail Code Team Name
                                            </Typography>
                                            <div>
                                                {existanceFlag && (
                                                    <Typography variant="error" color="redOne">
                                                        {`*Fail Code with team name`}{' '}
                                                        <span style={{ textTransform: 'capitalize' }}>
                                                            {failCodeTeamName}
                                                        </span>{' '}
                                                        {`already exists`}
                                                    </Typography>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                value={failCodeTeamName}
                                                onChange={(e) => {
                                                    setFailCodeTeamName(e.target.value);
                                                }}
                                                data-testid="create-new-fail-code-input"
                                            />
                                        </div>
                                    </div>
                                ) : toggledOverlays.transferFailCode ? (
                                    <div className="mb-2">
                                        <div className="mb-1">
                                            <Typography variant="caption2" color="graySix">
                                                Fail Code
                                            </Typography>
                                        </div>
                                        <div className="mb-2">
                                            <Form.Control type="text" autoComplete="off" value={failcode} disabled />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mb-2">
                                        <Typography variant="caption2" color="graySix">
                                            Select Team
                                        </Typography>
                                        {toggledOverlays.addFailCode && (
                                            <div className="mb-2 mt-1">
                                                <Typeahead
                                                    id="add-fail-code-async-typeahead"
                                                    placeholder="Select Fail Code Teams"
                                                    labelKey="team"
                                                    selected={[selectedFailCode]}
                                                    onChange={(selections) => {
                                                        setFailCodeTeam(selections);
                                                        setSelectedTeamName(selections[0].team);
                                                        setFailCode({ failCode: '', description: '', grouping: '' });
                                                    }}
                                                    options={processedFailCodes}
                                                    disabled
                                                />
                                            </div>
                                        )}
                                        {(toggledOverlays.addFailCodeToTeam || toggledOverlays.addMissingFailCode) && (
                                            <div className="mb-2 mt-1">
                                                <Typeahead
                                                    id="add-fail-code-async-typeahead"
                                                    placeholder="Select Fail Code Teams"
                                                    labelKey="team"
                                                    selected={failCodeTeam}
                                                    onChange={(selections) => {
                                                        setFailCodeTeam(selections);
                                                        setSelectedTeamName(
                                                            selections.length > 0 ? selections[0].team : ''
                                                        );
                                                        setFailCode({ failCode: '', description: '', grouping: '' });
                                                    }}
                                                    options={processedFailCodes}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {!toggledOverlays.editFailCodeTeam && (
                            <div>
                                <div className="mb-1 mt-5">
                                    <Typography>
                                        {toggledOverlays.createFailCodeTeam
                                            ? 'Assign a Fail Code to the team'
                                            : toggledOverlays.transferFailCode
                                              ? 'Move Fail Code To The Team'
                                              : 'Select Fail Code'}
                                    </Typography>
                                    {!toggledOverlays.addMissingFailCode && (
                                        <div className={classes.radioDiv}>
                                            <div className={classes.radio}>
                                                <input
                                                    type="radio"
                                                    id="failCodeWithTeam"
                                                    name="failCodeOption"
                                                    value="failCodeWithTeam"
                                                    style={{ marginBottom: 6, marginRight: 3 }}
                                                    onChange={handleOptionChange}
                                                    checked={radio == 'failCodeWithTeam'}
                                                    data-testid="radio-fail-code-with-team"
                                                />
                                                <label htmlFor="failCodeWithTeam" style={{ fontSize: '14px' }}>
                                                    {toggledOverlays.transferFailCode
                                                        ? 'Existing Team'
                                                        : 'Fail Code With Team'}
                                                </label>
                                            </div>
                                            <div className={classes.radio}>
                                                <input
                                                    type="radio"
                                                    id="missingFailCode"
                                                    name="failCodeOption1"
                                                    value="missingFailCode"
                                                    style={{ marginBottom: 6, marginRight: 3 }}
                                                    onChange={handleOptionChange}
                                                    checked={radio == 'missingFailCode'}
                                                />
                                                <label htmlFor="missingFailCode" style={{ fontSize: '14px' }}>
                                                    {toggledOverlays.transferFailCode
                                                        ? 'New Team'
                                                        : 'Missing Fail Code'}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!toggledOverlays.addMissingFailCode && (
                                    <div className="mb-2">
                                        <div className="mb-1">
                                            <Typography variant="caption2" color="graySix">
                                                {toggledOverlays.transferFailCode
                                                    ? 'Select Team'
                                                    : 'Fail Codes with teams.'}
                                            </Typography>
                                        </div>
                                        {toggledOverlays.transferFailCode ? (
                                            <div className="mb-2 mt-1">
                                                <Typeahead
                                                    id="add-fail-code-async-typeahead"
                                                    placeholder="Select Fail Code Teams"
                                                    labelKey="team"
                                                    selected={failCodeTeam}
                                                    onChange={(selections) => {
                                                        setFailCodeTeam(selections);
                                                        setSelectedTeamName(
                                                            selections.length > 0 ? selections[0].team : ''
                                                        );
                                                        setFailCode({ failCode: '', description: '', grouping: '' });
                                                    }}
                                                    options={processedFailCodeTeams}
                                                    disabled={
                                                        radio == 'missingFailCode' ||
                                                        radio == '' ||
                                                        !(failCodeTeam.length == 0 || failCodeTeamName == '')
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div className="mb-2" data-testid="manage-fail-codes-with-typeahead">
                                                <Typeahead
                                                    id="manage-users-async-typeahead"
                                                    placeholder="Select the fail code"
                                                    onChange={(selected: any) => {
                                                        setFailCode(selected[0]);
                                                    }}
                                                    options={failCodesWithTeams}
                                                    labelKey={(o) => `${o.failCode} - ${o.description}`}
                                                    disabled={
                                                        radio == 'missingFailCode' ||
                                                        radio == '' ||
                                                        !(failCodeTeam.length == 0 || failCodeTeamName == '')
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="mb-2">
                                    <div className="mb-1">
                                        <Typography variant="caption2" color="graySix">
                                            {toggledOverlays.transferFailCode ? 'New Team Name' : 'Missing Fail Codes'}
                                        </Typography>
                                        <div>
                                            {existanceFlag && (
                                                <Typography variant="error" color="redOne">
                                                    {`*Fail Code with team name`}{' '}
                                                    <span style={{ textTransform: 'capitalize' }}>
                                                        {failCodeTeamName}
                                                    </span>{' '}
                                                    {`already exists`}
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                    {toggledOverlays.transferFailCode ? (
                                        <div className="mb-2">
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                value={failCodeTeamName}
                                                onChange={(e) => {
                                                    setFailCodeTeamName(e.target.value);
                                                }}
                                                data-testid="create-new-fail-code-input"
                                                disabled={
                                                    toggledOverlays.addMissingFailCode
                                                        ? selectedTeamName != 'missing Fail Codes'
                                                            ? false
                                                            : true
                                                        : radio == 'failCodeWithTeam' ||
                                                          radio == '' ||
                                                          !(failCodeTeam.length == 0 || failCodeTeamName == '')
                                                }
                                                placeholder="Enter Team Name"
                                            />
                                        </div>
                                    ) : (
                                        <div className="mb-2" data-testid="manage-missing-fail-codes-typeahead">
                                            <Typeahead
                                                id="manage-users-async-typeahead"
                                                placeholder="Select the missing fail code"
                                                onChange={(selected: any) => {
                                                    if (selected && selected.length > 0) {
                                                        setMissingFailCode(selected[0]);
                                                    }
                                                }}
                                                options={missingFailCodes}
                                                disabled={
                                                    toggledOverlays.addMissingFailCode
                                                        ? selectedTeamName != 'missing Fail Codes'
                                                            ? false
                                                            : true
                                                        : radio == 'failCodeWithTeam' ||
                                                          radio == '' ||
                                                          !(failCodeTeam.length == 0 || failCodeTeamName == '')
                                                }
                                                labelKey={(o) => `${o.failCode} - ${o.description}`}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="d-flex mt-5 justify-content-end">
                            <Button variant="ghost-blue" onPress={handleCancelButtonPress}>
                                Cancel
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onPress={handleAddFailCodeToTeam}
                                disabled={
                                    toggledOverlays.addMissingFailCode
                                        ? selectedTeamName !== 'missing Fail Codes' && missingFailCode.failCode !== ''
                                            ? false
                                            : true
                                        : toggledOverlays.editFailCodeTeam
                                          ? newName.length > 0 && isValid && !existanceFlag
                                              ? false
                                              : true
                                          : toggledOverlays.transferFailCode
                                            ? failCodeTeam.length > 0
                                                ? false
                                                : !existanceFlag && failCodeTeamName.length > 0
                                                  ? false
                                                  : true
                                            : !(
                                                  failCodeTeam.length > 0 ||
                                                  failCodeTeamName !== '' ||
                                                  selectedTeamName.length > 0
                                              ) ||
                                              !(failCode.failCode !== '' || missingFailCode.failCode !== '') ||
                                              existanceFlag
                                }
                                data-testid="btn-submit-team"
                            >
                                {toggledOverlays.editFailCodeTeam
                                    ? 'Edit Name'
                                    : toggledOverlays.createFailCodeTeam
                                      ? 'Create Team'
                                      : toggledOverlays.transferFailCode
                                        ? 'Move Fail Code'
                                        : 'Add Fail Code'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>,
        rightPanelDiv
    );
};
