import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FailCode } from '@packages/models/api';
import { failCodesService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';

const getGridStyles = createUseStyles({
    grid: ({ url, zoom }: { url: string; zoom: number }) => ({
        backgroundSize: '100% 100%',
        width: `calc(38vw - 56px + ${zoom}px)`,
        height: `calc(32vw + ${zoom}px)`,
        backgroundImage: `url(${url})`,
        borderCollapse: 'collapse',
        marginLeft: '56px',
    }),
    gridRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    gridCell: {
        border: '1px solid #000',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
            'background-color': 'rgb(69 200 218 / 50%)',
        },
    },
    gridCellSelected: {
        backgroundColor: 'rgb(69 200 218 / 50%)',
        cursor: 'pointer',
    },
    xLabels: ({ zoom }: { zoom: number }) => ({
        position: 'absolute',
        left: 'calc(50% - 6px)',
        pointerEvents: 'none',
        top: '-28px',
        fontWeight: 500,
    }),
    yLabels: ({ zoom }: { zoom: number }) => ({
        position: 'absolute',
        left: '-20px',
        pointerEvents: 'none',
        top: '15px',
        fontWeight: 500,
    }),
    vLabel: {
        position: 'absolute',
        top: '45px',
        left: '39px',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    secondCodeLabel: {
        position: 'absolute',
        top: '0',
        right: '29%',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    thirdCodeLabel: {
        position: 'absolute',
        transform: 'rotate(90deg)',
        top: '50%',
        left: '-78px',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    gridWrapper: {
        marginTop: '20px',
        maxHeight: 'calc(600px + 22px)',
        minHeight: '622px',
        overflow: 'auto',
        paddingTop: '70px',
        position: 'relative',
    },
});

interface gridProps {
    url: string;
    zoom: number;
    range: string;
    updateFailCode: (arg: FailCode) => void;
}

export const Grid: FC<gridProps> = ({ url, updateFailCode, zoom, range }) => {
    let classes = getGridStyles({ url, zoom });

    const { abortSignalRef } = useAbortController();

    let xLabels = range === 'A-M' ? alphaRange('A', 'M') : alphaRange('N', 'Z');
    let yLabels = alphaRange('A', 'M');

    function alphaRange(start: string, stop: string) {
        var result = [];
        for (var idx = start.charCodeAt(0), end = stop.charCodeAt(0); idx <= end; ++idx) {
            result.push(String.fromCharCode(idx));
        }
        return result;
    }

    function giveCoords(row: string, col: string) {
        setSelectedCell({
            row: row,
            col: col,
        });
        let failCode = `V${col}${row}`;
        failCodesService
            .fetchIsValidFailCode({
                failCode: failCode,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }
                let code = response.data.failCodes;
                if (code.length === 0) {
                    alert(`You’ve selected a fail code that’s not valid, please choose another location nearby.`);
                } else {
                    updateFailCode(code[0]);
                }
            })
            .catch((error) => {
                alert('Error checking validity of failcode: ' + error + '. Please refresh & try again.');
                console.error('Error checking validity of failcode:', error);
            });
    }

    const [selectedCell, setSelectedCell] = useState({
        row: '',
        col: '',
    });

    return (
        <div className={classes.gridWrapper}>
            <table id="grid" className={classes.grid}>
                <tbody>
                    {[...Array(10)].map((x, i) => (
                        <tr key={i}>
                            {[...Array(13)].map((y, j) => (
                                <td
                                    className={
                                        yLabels[i] == selectedCell.row && xLabels[j] == selectedCell.col
                                            ? classes.gridCellSelected
                                            : classes.gridCell
                                    }
                                    key={j}
                                    onClick={() => giveCoords(yLabels[i], xLabels[j])}
                                >
                                    {j === 0 && <span className={classes.yLabels}>{yLabels[i]}</span>}

                                    {i === 0 && <span className={classes.xLabels}>{xLabels[j]}</span>}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <label className={classes.vLabel}>V</label>
            <label className={classes.secondCodeLabel}>2ND FAILURE CODE DIGIT</label>
            <label className={classes.thirdCodeLabel}>3RD FAILURE CODE DIGIT</label>
        </div>
    );
};

export default Grid;
