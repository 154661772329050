import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Col, Modal, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon, Button } from '@packages/ui/shared';
import { analyticsService, feedbackService, httpClient, qmrsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { FeedbackFormAnswer, FeedbackFormTypeId, FeedbackForm } from '@packages/models/api';
import { FeedbackFormElement } from '@web/feedback/components';
import { fetchQmr, useQmrDispatch, useQmrState } from '@packages/contexts/qmrs';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import './qmr-feedback-modal.css';

interface QmrFeedbackModalProps {
    show: boolean;
    onHide(): void;
}

const QmrFeedbackModal: FC<QmrFeedbackModalProps> = (props) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackFormId, setFeedbackFormId] = useState('');
    const [qmrFeedbackForm, setQmrFeedbackForm] = useState<FeedbackForm>();
    const [isMinimized, setIsMinimized] = useState(false);
    const [sessionChoices, setSessionChoices] = useState<Record<string, FeedbackFormAnswer>>({});

    const { leftColElements, rightColElements } = useMemo(() => {
        return {
            leftColElements: qmrFeedbackForm?.formElements.slice(0, 4) || [],
            rightColElements: qmrFeedbackForm?.formElements.slice(4) || [],
        };
    }, [qmrFeedbackForm]);

    const hasAnsweredChoices = useMemo(() => {
        return Object.values(sessionChoices).length > 0;
    }, [sessionChoices]);

    useEffect(() => {
        fetchQmrFeedbackForm();
    }, [abortSignalRef]);

    const fetchQmrFeedbackForm = useCallback(() => {
        feedbackService.fetchQmrFeedbackForm({ signal: abortSignalRef.current }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            const { feedbackForm } = response.data;
            setQmrFeedbackForm(feedbackForm);
            setFeedbackFormId(feedbackForm.feedbackFormId);
            setSessionChoices(() => {
                const ans: { [key: string]: FeedbackFormAnswer } = {};
                feedbackForm.formElements.forEach((ele) => {
                    if (ele.selectedOptions?.length) {
                        ans[ele.formElementId] = {
                            elementId: ele.formElementId,
                            options: ele.selectedOptions,
                        };
                    }
                });
                return ans;
            });
        });
    }, [abortSignalRef]);

    const handleAnswer = useCallback((newAnswer: FeedbackFormAnswer) => {
        setSessionChoices((prev) => ({
            ...prev,
            [newAnswer.elementId]: newAnswer,
        }));
    }, []);

    const handleMinimize = useCallback(() => {
        setIsMinimized(true);
    }, []);

    const handleExpand = useCallback(() => {
        setIsMinimized(false);
    }, []);

    const showTooltip = (props: any) => (
        <Tooltip {...props} className="custom-tooltip">
            Minimize
        </Tooltip>
    );

    const showModal = !isMinimized && props.show;

    return (
        <>
            <Modal show={showModal} centered={true} onHide={props.onHide} size="lg" backdrop="static">
                <Modal.Header className="d-flex justify-content-between align-items-center" placeholder={''}>
                    <Modal.Title>{t('modals:qmrFeedback.title', 'QMR Feedback')}</Modal.Title>

                    <div className="d-inline-flex">
                        <OverlayTrigger placement="bottom" delay={{ show: 600, hide: 400 }} overlay={showTooltip}>
                            <Button
                                onPress={handleMinimize}
                                iconLeft={<Icon name="minus" size={32} />}
                                variant="ghost-blue"
                            >
                                {t('modals:createQmr.actions.minimize', '')}
                            </Button>
                        </OverlayTrigger>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col>
                            {leftColElements.map((ele) => (
                                <FeedbackFormElement
                                    key={ele.formElementId}
                                    item={{ ...ele, selectedOptions: sessionChoices[ele.formElementId]?.options }}
                                    value={sessionChoices[ele.formElementId]?.options[0]?.answerText}
                                    onAnswerChange={handleAnswer}
                                />
                            ))}
                        </Col>
                        {Boolean(rightColElements.length) && (
                            <Col>
                                {rightColElements.map((ele) => (
                                    <FeedbackFormElement
                                        key={ele.formElementId}
                                        item={{ ...ele, selectedOptions: sessionChoices[ele.formElementId]?.options }}
                                        value={sessionChoices[ele.formElementId]?.options[0]?.answerText}
                                        onAnswerChange={handleAnswer}
                                    />
                                ))}
                            </Col>
                        )}
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        isLoading={isSubmitting}
                        variant="primary"
                        disabled={!hasAnsweredChoices}
                        onPress={() => {
                            if (!qmr || !feedbackFormId) {
                                return;
                            }

                            const choices = Object.values(sessionChoices);
                            console.log('choices', choices);
                            httpClient.refreshToken();
                            setIsSubmitting(true);

                            feedbackService
                                .submitQmrFeedback({
                                    qmrId: qmr.qmrId,
                                    feedbackFormId,
                                    answers: { choices },
                                    signal: abortSignalRef.current,
                                })
                                .then((response) => {
                                    if (!response.success && response.aborted) {
                                        return;
                                    } else if (!response.success) {
                                        throw response.data;
                                    }

                                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_GIVES_FEEDBACK, {
                                        feedbackType: FeedbackFormTypeId.Qmr,
                                        tsId: qmr.stsIdentifier,
                                    });

                                    setIsSubmitting(false);
                                    fetchQmr({
                                        qmrId: qmr.qmrId,
                                        qmrsService,
                                        qmrDispatch,
                                        signal: abortSignalRef.current,
                                        ignoreCache: true,
                                    });
                                    props.onHide();
                                })
                                .catch((e) => {
                                    setIsSubmitting(false);
                                    alert(e.message);
                                });
                        }}
                    >
                        {t('modals:createQmr.actions.submit', 'Submit Feedback')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isMinimized && (
                <Button onPress={handleExpand} iconLeft={<Icon color="white" name="open-in-full" />} variant="danger">
                    {t('modals:createQmr.actions.expand', 'Expand Feedback')}
                </Button>
            )}
        </>
    );
};

export default QmrFeedbackModal;
