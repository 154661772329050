import * as React from 'react';
import { Modal, Button, Form, Row, Col, Card, Accordion, useAccordionToggle } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { qmrsService } from '@web/services/singletons';
import { useQmrState, patchQmr, useQmrDispatch } from '@packages/contexts/qmrs';
import { useTranslation } from 'react-i18next';
import { FailCodeContainer } from './fail-code-no-trouble-found';
import { Icon } from '@packages/ui/shared';
import { Typeahead } from 'react-bootstrap-typeahead';

interface ViewMatchingProps {
    returnToHome(): void;
    onHide(): void;
    failCodes: Array<FailCodeContainer>;
    handleSuccess(value: string): void;
}

const noOp = () => {};

const failCodeViewMatchingStyles = createUseStyles({
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .form-check-input': {
            marginTop: '.1rem',
        },
        '& > *': {
            maxWidth: '25%',
        },
    },
    spacerDiv: {
        margin: '15px 0',
    },
    noAdd: {
        '&.subcomm-button': {
            background: colors.textDarkSecondary,
            '&:hover': {
                background: colors.grayFive,
            },
        },
    },
});

interface AccordionProps {
    eventKey: string;
    setSelected: (eventKey: string) => void;
    selectedEventKey: string;
    isMulti?: boolean;
    body: React.ReactNode;
    failCode: FailCodeContainer;
}

interface Option {
    label: string | React.ReactNode;
    value: string;
    sectionNumber: string | number;
    sectionName: string;
    subsectionNumber: string | number;
    subsectionName: string;
    failCode: string;
}

const ToggleAccordion: React.FC<AccordionProps> = ({
    eventKey,
    setSelected,
    selectedEventKey,
    body,
    failCode,
    isMulti = false,
}) => {
    const { t } = useTranslation();
    const classes = failCodeViewMatchingStyles();
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
        if (selectedEventKey === eventKey) {
            setSelected('');
        } else {
            setSelected(eventKey);
        }
    });

    if (!isMulti) {
        return (
            <Card onClick={decoratedOnClick}>
                <Card.Header>
                    <div className={classes.headerContainer}>
                        <Card.Title>
                            <div>
                                {failCode.failCode} - {failCode.description}
                            </div>
                        </Card.Title>
                    </div>
                </Card.Header>
                <Accordion.Collapse eventKey={eventKey}>
                    <Card.Body>{body}</Card.Body>
                </Accordion.Collapse>
            </Card>
        );
    }

    return (
        <Card onClick={decoratedOnClick}>
            <Card.Header>
                <Card.Title className={classes.headerContainer}>
                    <Row>
                        <Col>
                            <Form.Check
                                label={`${failCode.failCode} - ${failCode.description}`}
                                type="radio"
                                id={`lookupBySection${eventKey}`}
                                name={`lookupBySection${eventKey}`}
                                checked={selectedEventKey === eventKey}
                                onChange={noOp}
                            />
                        </Col>
                    </Row>

                    {body}

                    <Row>
                        <Col>
                            <div>
                                <Icon name={selectedEventKey === String(eventKey) ? 'chevron-down' : 'chevron-up'} />
                            </div>
                        </Col>
                    </Row>
                </Card.Title>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body>{body}</Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

// TODO:  Two possible ways this goes, one is single selected and the other is multi-selected.

export const FailCodeViewMatching = (props: ViewMatchingProps) => {
    const { failCodes } = props;
    const { t } = useTranslation();
    const isMulti = failCodes.length > 1;
    const qmrDispatch = useQmrDispatch();
    const qmrState = useQmrState();
    const { qmr } = qmrState;
    const qmrId = qmr && qmr.qmrId ? qmr.qmrId : undefined;

    const [filteredResults, setFilteredResults] = React.useState(failCodes);
    const [selectedOptionValue, setSelectedOptionValue] = React.useState<Array<Option>>([]);
    const [selectedOptions, setSelectedOptions] = React.useState<Array<Option>>([]);
    const first = failCodes[0];
    const [selectedEventKey, setSelectedEventKey] = React.useState(
        `${first.failCode}-${first.sectionNumber}-${first.subsectionNumber}-${first.sectionName}-${first.subsectionName}`
    );

    React.useEffect(() => {
        const selectedOptions = failCodes.map((f) => {
            return {
                label: `Section: ${f.sectionNumber} - ${f.sectionName}. Subsection: ${f.subsectionNumber} - ${f.subsectionName}`,
                value: f.failCode,
                sectionNumber: f.sectionNumber,
                sectionName: f.sectionName,
                subsectionNumber: f.subsectionNumber,
                subsectionName: f.subsectionName,
                failCode: f.failCode,
            };
        });
        setSelectedOptions(selectedOptions);
    }, [failCodes]);

    const handleBodyClick = (eventKey: string) => {
        setSelectedEventKey(eventKey);
    };

    const classes = failCodeViewMatchingStyles();

    const handleSubmit = async () => {
        const failCode = selectedOptionValue[0];
        if (qmrId && failCode) {
            const updatedQmr = await patchQmr({
                qmrId,
                qmrPatch: {
                    failCode: failCode.failCode,
                    failCodeSectionName: failCode.sectionName,
                    failCodeSubsectionName: failCode.subsectionName,
                },
                qmrsService,
                qmrDispatch,
            });

            if (!updatedQmr) {
                return;
            }

            props.handleSuccess(t('qmr:sections:failCode.successPart'));
            props.onHide();
        }
    };

    const handleResultsChange = (selected: Option[]) => {
        setSelectedOptionValue(selected);
        const sel = selected[0];
        if (!sel) {
            setFilteredResults(failCodes);
            return;
        }
        const { sectionNumber, subsectionNumber, failCode, sectionName, subsectionName } = sel;
        const res = failCodes.find((f) => {
            return (
                f.failCode === failCode &&
                f.sectionNumber === sectionNumber &&
                f.subsectionNumber === subsectionNumber &&
                f.subsectionName === subsectionName &&
                f.sectionName === sectionName
            );
        });

        if (res) {
            setSelectedEventKey(
                `${res.failCode}-${res.sectionNumber}-${res.subsectionNumber}-${res.sectionName}-${res.subsectionName}`
            );
            setFilteredResults([res]);
        } else {
            setFilteredResults(failCodes);
        }
    };

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                <h4 className="mb-0">{t('qmr:sections:failCode.failCodeFound')}</h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Typeahead
                        id="fail-code-view-matching__section_subsection"
                        onChange={handleResultsChange}
                        options={selectedOptions}
                        placeholder={t('qmr:sections:failCode.chooseSectionSubsection')}
                        selected={selectedOptionValue}
                    />
                    <Accordion activeKey={selectedEventKey}>
                        {isMulti ? (
                            <>
                                <div className={classes.spacerDiv}>
                                    {t('qmr:sections:failCode.multipleMatches', {
                                        displayIdentifier: qmr?.partNumber || qmr?.displayIdentifier,
                                    })}
                                </div>
                                <Row className="mb-3">
                                    <Col>
                                        <b>{t('qmr:sections:failCode.pleaseChoose')}</b>:
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <div>
                                <p>
                                    {t('qmr:sections:failCode.multipleMatches', {
                                        displayIdentifier: qmr?.partNumber || qmr?.displayIdentifier,
                                    })}
                                </p>
                            </div>
                        )}
                        {filteredResults.map((f, idx) => {
                            return (
                                <ToggleAccordion
                                    key={idx}
                                    isMulti={isMulti}
                                    selectedEventKey={selectedEventKey}
                                    setSelected={handleBodyClick}
                                    failCode={f}
                                    body={
                                        <>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <b>Section: </b>
                                                        <span>
                                                            {f.sectionNumber} - {f.sectionName}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <b>Subsection: </b>
                                                        <span>
                                                            {f.subsectionNumber} - {f.subsectionName}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    eventKey={`${f.failCode}-${f.sectionNumber}-${f.subsectionNumber}-${f.sectionName}-${f.subsectionName}`}
                                ></ToggleAccordion>
                            );
                        })}
                    </Accordion>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className={classes.noAdd} variant="dark" onClick={props.returnToHome}>
                    {t('qmr:sections:failCode.doNotAdd')}
                </Button>
                <Button onClick={handleSubmit} variant="primary">
                    {t('qmr:sections:failCode.continue')}
                </Button>
            </Modal.Footer>
        </>
    );
};
