import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';
import { focus } from '@web/jss/mixins';

export const scModal = {
    '.modal-content': {
        border: 0,
        borderRadius: 4,
    },
    '.subcomm-modal': {
        '&__header': {
            minHeight: 58,
            padding: '16px 32px',
            position: 'relative',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            backgroundColor: colors.grayOne,
            '& button.close': {
                top: '50%',
                right: 18,
                width: 40,
                height: 40,
                opacity: 1,
                float: 'none',
                borderRadius: 20,
                textShadow: 'none',
                color: 'transparent',
                position: 'absolute',
                transform: 'translateY(-50%)',
                ...focus(),
                '&:before, &:after': {
                    width: 16,
                    height: 2,
                    top: '50%',
                    left: '50%',
                    content: '""',
                    borderRadius: 1,
                    display: 'block',
                    position: 'absolute',
                    backgroundColor: colors.graySix,
                },
                '&:before': {
                    transform: 'translate(-50%, -50%) rotate(-45deg)',
                },
                '&:after': {
                    transform: 'translate(-50%, -50%) rotate(45deg)',
                },
                '&:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):hover': {
                    opacity: 1,
                },
                '&:not(:disabled):not(.disabled):hover': {
                    backgroundColor: colors.grayThree,
                },
            },
        },
        '&__title': {
            margin: 0,
            ...fonts.m,
        },
        '&__body': {
            padding: '24px 32px',
        },
        '&__footer': {
            borderTop: `1px solid ${colors.grayThree}`,
            display: 'flex',
            padding: '16px 32px',
            justifyContent: 'flex-end',
        },
    },
};
