import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Typography, TextareaHelper, Button } from '@packages/ui/shared';
import { useQmrInfoRequests, useQmrState, useQmrInfoRequestActions } from '@packages/contexts/qmrs';
import { qmrsService } from '@web/services/singletons';

interface OpenInfoRequestModalProps {
    show: boolean;
    onHide(): void;
    onSuccess(): void;
}

const Divider = () => <div style={{ marginTop: 10 }} />;

const OpenInfoRequestModal = ({ show, onHide, onSuccess }: OpenInfoRequestModalProps) => {
    const { qmr } = useQmrState();
    const { query } = useQmrInfoRequests({ qmrsService });
    const { openInfoRequestMutation } = useQmrInfoRequestActions({ qmrsService, onSuccess: query.refetch });
    const navigate = useNavigate();

    const [text, setText] = useState('');

    const onOpenInfoRequest = async () => {
        try {
            await openInfoRequestMutation.mutateAsync({ request: text });
            onHide();
            onSuccess();
            setText('');
            navigate(`/qmrs/${qmr?.displayIdentifier}?tab=INFO`);
        } catch (error) {
            alert('Error: Failed to open request for more information');
        }
    };

    return (
        <Modal show={show} centered onHide={onHide} size="lg">
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>Open a Request for More Information</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Typography variant="label">What information needs to be included?</Typography>
                <Divider />
                <Typography>
                    Please explain what additional information is needed for this QMR. This note is shared with QA Admin
                    and DSQMs. DSQMs will still need to request this information from the QMR creator.
                </Typography>
                <Divider />
                <TextareaHelper value={text} onChangeText={setText} placeholder="Enter Request..." />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="ghost-gray" style={{ marginRight: 5 }} onPress={onHide}>
                    Cancel
                </Button>
                <Button onPress={onOpenInfoRequest} isLoading={openInfoRequestMutation.isLoading}>
                    Open Info Request
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OpenInfoRequestModal;
