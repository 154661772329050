import Color from 'color';
import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';
import { focus } from '@web/jss/mixins';

export const scButton = {
    '.subcomm-button': {
        /* ----------------------------------------------------------- */
        /* Default variant */
        /* ----------------------------------------------------------- */
        border: 0,
        ...fonts.s,
        borderRadius: 3,
        overflow: 'hidden',
        appearance: 'none',
        padding: '8px 12px',
        ...fonts.robotoMedium,
        color: `${colors.white} !important`,
        position: 'relative',
        backgroundColor: colors.blueOne,
        '&:hover': {
            backgroundColor: Color(colors.blueOne)
                .lighten(0.16)
                .hex(),
        },
        '&:active': {
            backgroundColor: Color(colors.blueOne)
                .darken(0.16)
                .hex(),
        },
        '&:disabled': {
            color: `${colors.graySix} !important`,
            backgroundColor: `${colors.brandPrimaryDisabled} !important`,
            '&:hover': {
                color: `${colors.graySix} !important`,
                backgroundColor: `${colors.brandPrimaryDisabled} !important`,
            },
        },
        ...focus(),
        '&-block': {
            width: '100%',
            display: 'block',
        },

        /* ----------------------------------------------------------- */
        /* Primary variant */
        /* ----------------------------------------------------------- */
        '&-primary': {
            backgroundColor: `${colors.blueOne} !important`,
            '&:hover': {
                backgroundColor: `${Color(colors.blueOne)
                    .lighten(0.16)
                    .hex()} !important`,
            },
            '&:active': {
                backgroundColor: `${Color(colors.blueOne)
                    .darken(0.16)
                    .hex()} !important`,
            },
        },

        /* ----------------------------------------------------------- */
        /* Secondary variant */
        /* ----------------------------------------------------------- */
        '&-secondary': {
            backgroundColor: `${colors.graySix} !important`,
            '&:hover': {
                backgroundColor: `${Color(colors.graySix)
                    .lighten(0.16)
                    .hex()} !important`,
            },
            '&:active': {
                backgroundColor: `${Color(colors.graySix)
                    .darken(0.16)
                    .hex()} !important`,
            },
        },

        /* ----------------------------------------------------------- */
        /* Success variant */
        /* ----------------------------------------------------------- */
        '&-success': {
            backgroundColor: `${colors.greenOne} !important`,
            '&:hover': {
                backgroundColor: `${Color(colors.greenOne)
                    .lighten(0.16)
                    .hex()} !important`,
            },
            '&:active': {
                backgroundColor: `${Color(colors.greenOne)
                    .darken(0.16)
                    .hex()} !important`,
            },
        },

        /* ----------------------------------------------------------- */
        /* Danger variant */
        /* ----------------------------------------------------------- */
        '&-danger': {
            backgroundColor: `${colors.redOne} !important`,
            '&:hover': {
                backgroundColor: `${Color(colors.redOne)
                    .lighten(0.16)
                    .hex()} !important`,
            },
            '&:active': {
                backgroundColor: `${Color(colors.redOne)
                    .darken(0.16)
                    .hex()} !important`,
            },
        },

        /* ----------------------------------------------------------- */
        /* Light variant */
        /* ----------------------------------------------------------- */
        '&-light': {
            color: `${colors.black} !important`,
            backgroundColor: `${colors.grayTwo} !important`,
            '&:hover': {
                backgroundColor: `${colors.grayOne} !important`,
            },
            '&:active': {
                backgroundColor: `${Color(colors.grayTwo)
                    .darken(0.08)
                    .hex()} !important`,
            },
        },

        /* ----------------------------------------------------------- */
        /* Link variant */
        /* ----------------------------------------------------------- */
        '&-link': {
            color: `${colors.blueOne} !important`,
            backgroundColor: 'transparent !important',
            '&:hover': {
                backgroundColor: `${colors.grayOne} !important`,
            },
            '&:active': {
                backgroundColor: `${Color(colors.grayOne)
                    .darken(0.16)
                    .hex()} !important`,
            },
            '&:disabled': {
                color: `${colors.graySix} !important`,
                backgroundColor: 'transparent !important',
            },
        },
    },
};
