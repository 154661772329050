import { useContext, useEffect, useState } from 'react';

import { useAbortController } from '@packages/core/http';

import { ConfigService } from '@packages/core/config';

import { AuthStateContext, AuthDispatchContext, setToken, checkAuth } from './auth.context';
import { AuthStatus, authActions } from './auth.state';
import { AuthService } from './auth.service';
import { AuthTokenService } from './auth-token.service';
import { StorageService } from '@packages/core/storage';

enum InitializationStatus {
    NotInitialized,
    Initializing,
    Initialized,
}

export const useAuthState = () => {
    const state = useContext(AuthStateContext);

    if (state === undefined) {
        throw new Error('useAuthState must be used within an AuthProvider');
    }

    return state;
};

export const useAuthDispatch = () => {
    const dispatch = useContext(AuthDispatchContext);

    if (dispatch === undefined) {
        throw new Error('useAuthDispatch must be used within an AuthProvider');
    }

    return dispatch;
};

export const useAuthCallback = ({
    accessToken,
    configService,
    authService,
    authTokenService,
    storageService,
    onAuthenticated,
    onUnauthenticated,
}: {
    accessToken: string | null | undefined;
    configService: ConfigService;
    authService: AuthService;
    authTokenService: AuthTokenService;
    storageService: StorageService;
    onAuthenticated: (fromAccessToken: boolean) => void;
    onUnauthenticated: () => void;
}) => {
    const { abortSignalRef } = useAbortController();
    const { status: authStatus } = useAuthState();
    const authDispatch = useAuthDispatch();

    const [initStatus, setInitStatus] = useState(InitializationStatus.NotInitialized);

    useEffect(() => {
        if (initStatus !== InitializationStatus.Initialized) {
            return;
        }

        if (authStatus === AuthStatus.Authenticated) {
            onAuthenticated(!!accessToken);
        } else if (authStatus === AuthStatus.Unauthenticated) {
            onUnauthenticated();
        }
    }, [initStatus, authStatus, onAuthenticated, onUnauthenticated, accessToken]);

    useEffect(() => {
        if (initStatus !== InitializationStatus.NotInitialized) {
            return;
        }

        const initialize = async () => {
            await configService.ready();

            setInitStatus(InitializationStatus.Initializing);

            try {
                const statusUpdate: { status: AuthStatus; token?: string } = {
                    status: AuthStatus.Authenticated,
                };

                if (accessToken) {
                    statusUpdate.token = accessToken;

                    await setToken({
                        token: accessToken,
                        authTokenService,
                        authService,
                        storageService,
                        authDispatch,
                        signal: abortSignalRef.current,
                    });
                } else {
                    const storedToken = await checkAuth({
                        authTokenService,
                        authService,
                        storageService,
                        authDispatch,
                    });

                    if (!storedToken) {
                        throw new Error('Not Authenticated');
                    }

                    statusUpdate.token = storedToken;
                }

                setInitStatus(InitializationStatus.Initialized);

                authDispatch(authActions.setAuthStatus(statusUpdate));
            } catch (e) {
                setInitStatus(InitializationStatus.Initialized);

                const status = AuthStatus.Unauthenticated;
                authDispatch(authActions.setAuthStatus({ status }));
            }
        };

        initialize();
    }, [abortSignalRef, accessToken, authDispatch, authService, authTokenService, configService, initStatus]);
};
