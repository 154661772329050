import { Dispatch, Reducer } from 'react';
import { Bmis } from '@packages/models/api';
import { action, on, props, reducer, union } from 'ts-action';

export enum BmisActionsList {
    CLEAR_BMIS_DATA = 'CLEAR_BMIS_DATA',
    HAS_ERRORS = 'HAS_ERRORS',
    HAS_FETCHED = 'HAS_FETCHED',
    HAS_LOADED = 'HAS_LOADED',
    IS_LOADING = 'IS_LOADING',
    SELECT_BMIS_ROW = 'SELECT_TEST_ROW',
    SET_BMIS_DATA = 'SET_BMIS_DATA',
    SET_ROW_GUID = 'SET_ROW_GUID',
    SET_VIN = 'SET_VIN',
}

export const bmisActions = {
    clearBmisData: action(BmisActionsList.CLEAR_BMIS_DATA),
    hasErrors: action(BmisActionsList.HAS_ERRORS, props<{ errors: string[] }>()),
    hasFetched: action(BmisActionsList.HAS_FETCHED),
    hasLoaded: action(BmisActionsList.HAS_LOADED),
    isLoading: action(BmisActionsList.IS_LOADING),
    selectBmisRow: action(BmisActionsList.SELECT_BMIS_ROW),
    setBmisData: action(BmisActionsList.SET_BMIS_DATA, props<{ bmisData: Bmis[] | [] }>()),
    setRowGuid: action(BmisActionsList.SET_ROW_GUID, props<{ rowGuid: string | null }>()),
    setVin: action(BmisActionsList.SET_VIN, props<{ vin: string | undefined }>()),
};

const actionsUnion = union(...Object.values(bmisActions));
type BmisActions = typeof actionsUnion.actions;
export type BmisActionDispatcher = Dispatch<BmisActions>;

export interface BmisState {
    bmisData: Bmis[] | null;
    rowGuid: string | null;
    selectedBmisRow: Bmis | null;
    vin?: string;
    errors: string[];
    hasFetched: boolean;
    loading: boolean;
}

export const initialState: BmisState = {
    bmisData: null,
    rowGuid: null,
    selectedBmisRow: null,
    vin: undefined,
    errors: [],
    hasFetched: false,
    loading: false,
};

export const bmisReducer: Reducer<BmisState, BmisActions> = reducer(
    initialState,
    on(bmisActions.clearBmisData, (state) => {
        return {
            ...state,
            bmisData: null,
            selectedBmisRow: null,
        };
    }),
    on(bmisActions.setRowGuid, (state, { rowGuid }) => {
        return {
            ...state,
            rowGuid,
        };
    }),
    on(bmisActions.hasFetched, (state) => {
        return {
            ...state,
            hasFetched: true,
        };
    }),
    on(bmisActions.isLoading, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(bmisActions.hasLoaded, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(bmisActions.hasErrors, (state, { errors }) => {
        return {
            ...state,
            errors,
        };
    }),
    on(bmisActions.selectBmisRow, (state) => {
        if (!state.bmisData || !state.bmisData.length || !state.rowGuid) {
            return {
                ...state,
                selectedBmisRow: null,
            };
        }
        return {
            ...state,
            selectedBmisRow: state.bmisData.find((item: Bmis) => item.rowGuid === state.rowGuid) || null,
        };
    }),
    on(bmisActions.setBmisData, (state, { bmisData }) => {
        return {
            ...state,
            bmisData,
        };
    }),
    on(bmisActions.setVin, (state, { vin }) => {
        return {
            ...state,
            vin,
        };
    })
);
