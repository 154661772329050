import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tab } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { createUseStyles } from 'react-jss';
import { Popover } from 'react-tiny-popover';

import { InvestigationCategory, InvestigationModel, InvestigationStatus } from '@packages/models/api';
import { Button, Icon, TextareaHelper, TextInputHelper, Typography } from '@packages/ui/shared';
import { investigationService } from '@web/services/singletons';
import Select from '@web/components/select';
import colors from '@packages/core/styles/colors';
import { useAuthState } from '@packages/contexts/auth';

const useStyles = createUseStyles({
    qmrStatisticsTable: {
        width: '100%',
        '& tr': {
            borderRadius: 4,
            '& td': {
                padding: '6px 8px',
                '&:first-child': {
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                },
                '&:last-child': {
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                },
            },
            '&:nth-child(odd)': {
                '& td': {
                    backgroundColor: colors.grayTwo,
                },
            },
        },
    },
    actionsPopover: {
        zIndex: 10,
        minWidth: 173,
        borderRadius: 3,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
    },
    popoverActionsSection: { padding: '8px 0' },
});

interface InvestigationDetailsTabProps {
    eventKey: string;
    investigation: InvestigationModel;
    onInvestigationChange(investigation: InvestigationModel): void;
}

export const InvestigationDetailsTab = ({
    eventKey,
    investigation,
    onInvestigationChange,
}: InvestigationDetailsTabProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { account } = useAuthState();

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditting, setIsEditting] = useState(false);

    const [titleInputValue, setTitleInputValue] = useState(investigation.title);
    const [descriptionInputValue, setDescriptionInputValue] = useState(investigation.description);
    const [categoryTypeaheadOptions, setCategoryTypeaheadOptions] = useState<InvestigationCategory[]>([]);
    const [categoryTypeaheadValues, setCategoryTypeaheadValues] = useState<InvestigationCategory[]>([]);
    const [statusOptions, setStatusOptions] = useState<InvestigationStatus[]>([]);

    const handleOnEnter = useCallback(async () => {
        try {
            const [categoriesResponse, statusesResponse] = await Promise.all([
                investigationService.getInvestigationCategories(),
                investigationService.getInvestigationStatuses(),
            ]);

            if (categoriesResponse.success) {
                const investigationsCurrentCategory = categoriesResponse.data.categories.find(
                    (category) => investigation.categoryId === category.investigationCategoryId
                );

                setCategoryTypeaheadOptions(categoriesResponse.data.categories);
                if (investigationsCurrentCategory) {
                    setCategoryTypeaheadValues([investigationsCurrentCategory]);
                }
            } else {
                throw categoriesResponse.data;
            }

            if (statusesResponse.success) {
                setStatusOptions(statusesResponse.data.statuses);
            } else {
                throw statusesResponse.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }, [investigation.categoryId]);

    const handleCancelButtonPress = useCallback(() => {
        const investigationsCurrentCategory = categoryTypeaheadOptions.find(
            (category) => investigation.categoryId === category.investigationCategoryId
        );

        setTitleInputValue(investigation.title);
        setDescriptionInputValue(investigation.description);
        if (investigationsCurrentCategory) {
            setCategoryTypeaheadValues([investigationsCurrentCategory]);
        }
        setIsEditting(false);
    }, [categoryTypeaheadOptions, investigation.categoryId, investigation.description, investigation.title]);

    const handleSaveButtonPress = useCallback(async () => {
        try {
            setIsSubmitting(true);

            const response = await investigationService.updateInvestigation({
                investigationId: investigation.investigationId,
                body: {
                    title: titleInputValue,
                    description: descriptionInputValue,
                    categoryId: categoryTypeaheadValues[0].investigationCategoryId,
                },
            });

            if (response.success) {
                setIsSubmitting(false);
                setIsEditting(false);

                onInvestigationChange(response.data.investigation);
            } else {
                throw response.data;
            }
        } catch (error) {
            setIsSubmitting(false);
            window.alert(error.message);
        }
    }, [
        categoryTypeaheadValues,
        descriptionInputValue,
        investigation.investigationId,
        onInvestigationChange,
        titleInputValue,
    ]);

    const handleStatusChange = useCallback(
        async (event: React.FormEvent<HTMLSelectElement>) => {
            setIsSubmitting(true);

            try {
                const response = await investigationService.updateInvestigation({
                    investigationId: investigation.investigationId,
                    body: {
                        statusId: event.currentTarget.value,
                    },
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                onInvestigationChange(response.data.investigation);
            } catch (error) {
                window.alert(error.message);
            }

            setIsSubmitting(false);
        },
        [investigation.investigationId, onInvestigationChange]
    );

    return (
        <Tab.Pane eventKey={eventKey} className="p-6" onEnter={handleOnEnter}>
            <section className="d-flex align-items-center justify-content-between mb-5">
                <div style={{ flex: 1 }}>
                    <div>
                        <Typography variant="h6">Title</Typography>
                    </div>
                    <div>
                        {isEditting ? (
                            <TextInputHelper value={titleInputValue} onChangeText={setTitleInputValue} />
                        ) : (
                            <Typography variant="labelRegular">{investigation.title}</Typography>
                        )}
                    </div>
                </div>
                {account?.investigationCapabilities.editAnInvestigation && !isEditting && (
                    <Popover
                        isOpen={menuIsOpen}
                        positions={['bottom', 'top']}
                        onClickOutside={() => setMenuIsOpen(false)}
                        contentLocation={({ childRect, popoverRect, position }) => {
                            if (position === 'top') {
                                return {
                                    top: childRect.top - 4 - popoverRect.height,
                                    left: childRect.left - (popoverRect.width - childRect.width),
                                };
                            } else {
                                return {
                                    top: childRect.bottom + 4,
                                    left: childRect.left - (popoverRect.width - childRect.width),
                                };
                            }
                        }}
                        containerClassName={classes.actionsPopover}
                        content={
                            <div className={classes.popoverActionsSection}>
                                <Button
                                    alignTitle="left"
                                    variant="ghost-gray"
                                    onPress={() => {
                                        setIsEditting(true);
                                        setMenuIsOpen(false);
                                    }}
                                >
                                    <Typography variant="labelRegular">
                                        {t('qmrs:qmrDetail.actions.edit', 'Edit Information')}
                                    </Typography>
                                </Button>
                            </div>
                        }
                    >
                        <div>
                            <Button active={menuIsOpen} variant="tertiary" onPress={() => setMenuIsOpen(true)}>
                                <Icon name="more-dots-vertical" />
                            </Button>
                        </div>
                    </Popover>
                )}
            </section>
            <section className="mb-5">
                <div>
                    <Typography variant="h6">Description</Typography>
                </div>
                <div>
                    {isEditting ? (
                        <TextareaHelper value={descriptionInputValue} onChangeText={setDescriptionInputValue} />
                    ) : (
                        <Typography variant="labelRegular">{investigation.description}</Typography>
                    )}
                </div>
            </section>
            <Row className="mb-5">
                <Col>
                    <section>
                        <div>
                            <Typography variant="h6">Category</Typography>
                        </div>
                        <div>
                            {isEditting ? (
                                <Typeahead
                                    id="investigation-modal__category-typeahead"
                                    placeholder="Select Category"
                                    labelKey="name"
                                    options={categoryTypeaheadOptions}
                                    selected={categoryTypeaheadValues}
                                    onChange={(selected) => {
                                        setCategoryTypeaheadValues(selected);
                                    }}
                                />
                            ) : (
                                <Typography variant="labelRegular">{investigation.category}</Typography>
                            )}
                        </div>
                    </section>
                </Col>
                {!isEditting && (
                    <Col>
                        <section>
                            <div>
                                <Typography variant="h6">Last Update</Typography>
                            </div>
                            <div>
                                <Typography variant="labelRegular">
                                    {investigation.lastUpdatedTimestampDescription}
                                </Typography>
                            </div>
                        </section>
                    </Col>
                )}
            </Row>
            {!isEditting && (
                <section className="mb-5">
                    <div className="mb-2">
                        <Typography variant="h6">QMRs</Typography>
                    </div>
                    <div>
                        <table className={classes.qmrStatisticsTable}>
                            <tbody>
                                {investigation.qmrStatusSummaries.map((qmrStatusSummary) => {
                                    return (
                                        <tr>
                                            <td className="text-left">
                                                <Typography variant="default">
                                                    {qmrStatusSummary.description}
                                                </Typography>
                                            </td>
                                            <td className="text-right">
                                                <Typography variant="default">
                                                    {qmrStatusSummary.countDescription}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td className="text-left">
                                        <Typography variant="h6">Total</Typography>
                                    </td>
                                    <td className="text-right">
                                        <Typography variant="h6">{investigation.qmrStatusSummaryTotal}</Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            )}
            <hr className="mb-5" />

            {!isEditting && (
                <>
                    <section className="mb-5">
                        <div>
                            <Typography variant="caption2" color="graySix">
                                Change Status
                            </Typography>
                        </div>
                        <Select
                            required
                            value={investigation.statusId}
                            options={statusOptions.map((status) => {
                                return { value: status.investigationStatusId, title: status.name };
                            })}
                            onChange={handleStatusChange}
                            disabled={!account?.investigationCapabilities.editAnInvestigation || isSubmitting}
                        />
                    </section>
                    <hr className="mb-5" />
                </>
            )}

            {isEditting && (
                <section className="mb-5 d-flex align-items-center justify-content-end">
                    <Button
                        variant="ghost-blue"
                        title={t('buttons:cancel')}
                        style={{ marginRight: 8 }}
                        onPress={handleCancelButtonPress}
                    />
                    <Button isLoading={isSubmitting} title="Save Changes" onPress={handleSaveButtonPress} />
                </section>
            )}
        </Tab.Pane>
    );
};
