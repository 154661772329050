import React, { FC, Fragment, useState } from 'react';
import { Col, Container, Modal, Row, Tab, Tabs, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as firebase from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { Constants } from '@packages/core/config';

import {
    httpClient,
    authService,
    configService,
    pushNotificationsService,
    storageService,
} from '@web/services/singletons';
import { DebugMessaging, DebugPermissions, DebugAccounts, Typography } from '@packages/ui/shared';
import { fetchAuthUserProfile, useAuthDispatch, useAuthState } from '@packages/contexts/auth';
import { useAbortController } from '@packages/core/http';

const EnvList = () => {
    return (
        <>
            {Object.entries(
                // @ts-ignore --- buildConfig is private
                configService.buildConfig.availableEnvironments
            ).map(([envName, config]) => (
                <Fragment key={envName}>
                    <div>
                        <Button
                            onClick={() => {
                                configService.changeEnv(envName).then(() => {
                                    window.location.reload();
                                });
                            }}
                        >
                            {'Switch to ' + envName}
                        </Button>
                        <pre>{JSON.stringify(config, null, 4)}</pre>
                    </div>

                    <hr />
                </Fragment>
            ))}
        </>
    );
};

const Debugger: FC = () => {
    const { account, status: currentAuthStatus } = useAuthState();
    const authDispatch = useAuthDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const { abortSignalRef } = useAbortController();
    const navigate = useNavigate();

    const enableUserSwitcher =
        configService.getBoolean(Constants.Env.DebugUserSwitcher) && account?.systemCapabilities.canSwitchAccounts;

    const enableEnvSwitcher = __DEV__ || configService.getBoolean(Constants.Env.DebugAvailableEnvironments);

    const [segment, setSegment] = useState<'user' | 'envs' | undefined>(
        enableUserSwitcher ? 'user' : enableEnvSwitcher ? 'envs' : undefined
    );

    const hasDebugCapabilities = enableUserSwitcher || enableEnvSwitcher;

    const isProdEnv = configService.debugEnvName === 'production';
    return (
        <>
            <Button variant={isProdEnv ? 'danger' : 'success'} onClick={() => setIsOpen(true)} block>
                <Typography variant="labelRegular" color="white">
                    {isProdEnv ? 'Prod Debugger' : 'Debugger'}
                </Typography>
            </Button>

            <Modal show={isOpen} size="lg" onHide={() => setIsOpen(false)}>
                <Modal.Header placeholder={''}>
                    <Modal.Title>Debugger</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ overflow: 'scroll', maxHeight: 600 }}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Button
                                    onClick={() => {
                                        storageService.clear().then(() => {
                                            window.location.reload();
                                        });
                                    }}
                                >
                                    Clear Storage & Refresh
                                </Button>

                                <p>Current Env: {configService.debugEnvName}</p>
                                <p>
                                    <b>Only available during testing</b>
                                </p>
                            </Col>

                            {hasDebugCapabilities && (
                                <Col xs={12}>
                                    <Tabs id="debug-tabs" activeKey={segment} onSelect={(k: any) => setSegment(k)}>
                                        {enableUserSwitcher && (
                                            <Tab eventKey="user" title="Users">
                                                <DebugAccounts
                                                    account={account}
                                                    httpClient={httpClient}
                                                    onAccountChange={(accessToken) => {
                                                        storageService.clear().then(() => {
                                                            navigate(`/auth?accessToken=${accessToken}`);
                                                            window.location.reload();
                                                        });
                                                    }}
                                                />
                                            </Tab>
                                        )}

                                        {enableEnvSwitcher && (
                                            <Tab eventKey="envs" title="Available Envs.">
                                                <EnvList />
                                            </Tab>
                                        )}

                                        <Tab eventKey="messaging" title="Messaging">
                                            <DebugMessaging
                                                account={account}
                                                messaging={getMessaging}
                                                service={pushNotificationsService}
                                            />
                                        </Tab>

                                        <Tab eventKey="permissions" title="Permissions">
                                            <DebugPermissions
                                                account={account}
                                                httpClient={httpClient}
                                                onRefetch={() => {
                                                    if (!account) {
                                                        return;
                                                    }

                                                    fetchAuthUserProfile({
                                                        accountId: account.accountId,
                                                        currentAuthStatus,
                                                        authService,
                                                        authDispatch,
                                                        signal: abortSignalRef.current,
                                                    });
                                                }}
                                            />
                                        </Tab>
                                    </Tabs>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="link"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Debugger;
