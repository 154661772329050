import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { createUseStyles } from 'react-jss';
import { Icon, Label, Typography, Button } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { httpClient, techlineService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { FailCode } from '@packages/models/api';
import { useAuthState } from '@packages/contexts/auth';
import Grid from './grid';
import { DimensionValue } from 'react-native';

interface FailCodeSearchProps {
    returnToHome: () => void;
    onHide: () => void;
    updateCaseDetails: any;
    vehicleDetails: any;
}

const electricalGridLookupStyles = createUseStyles({
    disabled: {
        pointerEvents: 'none',
        color: colors.brandPrimaryDisabled,
    },
    btnGroup: {
        '&.subcomm-modal__footer': {
            display: 'flex',
            padding: '16px 32px',
            borderTop: '1px solid #e3e3e3',
            justifyContent: 'space-between',
            alignContent: '',
        },
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
    },
    flexStart: {},
    btnWrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        '&:hover': {
            cursor: 'pointer',
        },
        color: colors.brandPrimaryDark,
    },
    typeContainer: {
        color: colors.brandPrimaryDark,
    },
    grid: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 40,
    },
    gridControlsArea: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    'gridControlsArea>button': { width: '38px', margin: '0 5px' },
    electricalGrid: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '100vh',
    },
});

export type LocationProps = 'stepOne' | 'stepTwo' | 'stepThree';

interface Option {
    label: string;
    code: string;
    name: string;
}

interface StepOne {
    handleSearchTerm: (value: any) => void;
    searchTerm: any[];
    handleSubmit: () => void;
    goBack: () => void;
    vehicleDetails: any;
}

interface StepTwo {
    handleFailCode: (failcode: any) => void;
    failcode: any[];
    handleSubmit: () => void;
    goBack: () => void;
    searchTerm: any[];
    vehicleDetails: any;
}

const ElectricalGridStepOne = (props: StepOne) => {
    const { handleSearchTerm, searchTerm, handleSubmit, goBack } = props;
    const { abortSignalRef } = useAbortController();
    const [electricalGrids, setElectricalGrids] = useState<Option[]>([]);
    const classes = electricalGridLookupStyles();

    useEffect(() => {
        async function getGrids() {
            const resp = await techlineService.fetchFailCodeWiringDiagramSubsections({
                modelCode: props.vehicleDetails?.modelNumber,
                modelYear: props.vehicleDetails?.modelYear,
                optionCode: props.vehicleDetails?.optionCode,
                vin: props.vehicleDetails?.vin,
                signal: abortSignalRef.current,
            });
            if (!resp.success && resp.aborted) {
                return;
            } else if (!resp.success) {
                throw new Error(resp.data.message);
            }
            const subsectionOptions = resp.data.subsectionFigures.map(
                (s: { figureCode: any; subsectionName: any; xAxisRange: any }) => {
                    return {
                        label: `${s.figureCode} - ${s.subsectionName}`,
                        code: `${s.figureCode}`,
                        name: `${s.subsectionName}`,
                        range: `${s.xAxisRange}`,
                    };
                }
            );
            setElectricalGrids(subsectionOptions);
        }
        getGrids();
    }, [
        abortSignalRef,
        props.vehicleDetails?.modelNumber,
        props.vehicleDetails?.modelYear,
        props.vehicleDetails?.optionCode,
        props.vehicleDetails?.vin,
    ]);

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                Lookup by Electrical Grid Location
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Label required>Select the correct wiring doc:</Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Typeahead
                                id="fail-code-lookup-electrical-grid-modal__section"
                                onChange={handleSearchTerm}
                                options={electricalGrids}
                                placeholder="Search"
                                selected={searchTerm}
                            />
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={classes.btnGroup}>
                <div className={classes.flexStart}>
                    <div onClick={goBack} className={classes.btnWrapper}>
                        <Icon name="chevron-left" color="brandPrimaryDark" />
                        <div className={classes.typeContainer}>
                            <Typography>Back</Typography>
                        </div>
                    </div>
                </div>
                <div>
                    <Button disabled={!searchTerm.length} onPress={handleSubmit} variant="primary">
                        Continue to Electrical Grid Location
                    </Button>
                </div>
            </Modal.Footer>
        </>
    );
};

const ElectricalGridStepTwo = (props: StepTwo) => {
    const { handleSubmit, goBack, searchTerm, handleFailCode } = props;
    const classes = electricalGridLookupStyles();
    const [selectedFailCode, setSelectedFailCode] = useState<FailCode>();
    const [electricalGridUrl, setElectricalGridUrl] = useState('');
    const [range, setRange] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const auth = useAuthState();
    const [zoom, setZoom] = useState(0);

    const updateFailCode = (failCode: FailCode): void => {
        setSelectedFailCode(failCode);
        handleFailCode(failCode);
    };

    const zoomIn = function () {
        setZoom(zoom + 30);
    };

    const zoomOut = function () {
        setZoom(zoom - 30);
    };

    if (electricalGridUrl === '') {
        const options = {
            headers: {
                'X-Subcomm-Access-Token': auth.token,
            },
        };
        fetch(`${httpClient.baseUrl}/fail-codes/electrical-wiring-diagram?figureId=${searchTerm[0].code}`, options)
            .then((res) => res.blob())
            .then((blob) => {
                const backgroundObjectUrl = window.URL.createObjectURL(blob);
                setElectricalGridUrl(backgroundObjectUrl);
                setRange(searchTerm[0].range);
            });
    }

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                Lookup by Electrical Grid Location
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Label style={{ width: '500px' as DimensionValue }}>
                                Select the appropriate area on the {searchTerm[0].name}:
                            </Label>
                        </Col>
                        <div className={classes.gridControlsArea}>
                            <div className="mr-2">
                                <Button onPress={zoomIn}>
                                    <Icon name="magnify-plus-outline" color="white" />
                                </Button>
                            </div>
                            <Button onPress={zoomOut}>
                                <Icon name="magnify-minus-outline" color="white" />
                            </Button>
                        </div>
                    </Row>
                </Form>
                <div className={classes.grid}>
                    <Grid url={electricalGridUrl} updateFailCode={updateFailCode} zoom={zoom} range={range}></Grid>
                </div>
            </Modal.Body>
            <Modal.Footer className={classes.btnGroup}>
                <div className={classes.flexStart}>
                    <div onClick={goBack} className={classes.btnWrapper}>
                        <Icon name="chevron-left" color="brandPrimaryDark" />
                        <div className={classes.typeContainer}>
                            <Typography>Back</Typography>
                        </div>
                    </div>
                </div>
                <div>
                    <Button
                        variant="primary"
                        onPress={() => {
                            handleSubmit();
                        }}
                        isLoading={isSaving}
                    >
                        Add Fail Code
                    </Button>
                </div>
            </Modal.Footer>
        </>
    );
};

export const TechlineFailCodeLookupElectricalGrid = (props: FailCodeSearchProps) => {
    const [searchTerm, setSearchTerm] = React.useState<[]>([]);
    const [location, setLocation] = React.useState<LocationProps>('stepOne');
    const [failcode, setFailCode] = React.useState<[]>([]);

    const handleSearchTerm = (value: any) => {
        setSearchTerm(value);
    };

    const handleFailCode = (failcode: any): void => {
        setFailCode(failcode);
    };

    const handleSubmitStepOne = () => {
        setLocation('stepTwo');
    };

    const handleSubmitStepTwo = () => {
        confirmChoice(failcode, searchTerm);
    };

    const handleGoBackToStepOne = () => {
        setLocation('stepOne');
    };

    const confirmChoice = (selectedFailCode: any, searchTerm: any) => {
        if (selectedFailCode.length === 0) {
            alert('No Fail Code has been selected.');
            return;
        } else {
            handleFailCodeSubmit(failcode, searchTerm);
        }
    };

    const handleFailCodeSubmit = (selectedFailCode: any, searchTerm: any) => {
        if (!selectedFailCode || !searchTerm) {
            return;
        }

        let failcode = selectedFailCode.failCode;
        let sectionName = searchTerm[0].name;

        props.updateCaseDetails({
            repairCode: failcode,
            failCode: failcode,
            section: 'Electrical Location Grids',
            subSection: sectionName,
        });
        props.onHide();
    };

    return (
        <>
            {location === 'stepOne' && (
                <ElectricalGridStepOne
                    goBack={props.returnToHome}
                    searchTerm={searchTerm}
                    handleSearchTerm={handleSearchTerm}
                    handleSubmit={handleSubmitStepOne}
                    vehicleDetails={props.vehicleDetails}
                />
            )}
            {location === 'stepTwo' && (
                <ElectricalGridStepTwo
                    goBack={handleGoBackToStepOne}
                    handleSubmit={handleSubmitStepTwo}
                    searchTerm={searchTerm}
                    handleFailCode={handleFailCode}
                    failcode={failcode}
                    vehicleDetails={props.vehicleDetails}
                />
            )}
        </>
    );
};
