import colors from '@packages/core/styles/colors';
import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';

import { Typography, Icon, StsIconName } from './';

interface EmptyListProps {
    iconName?: StsIconName;
    iconColor?: keyof typeof colors;
    title?: string;
    description?: string;
    children?: React.ReactNode;
}

export const EmptyList: FC<EmptyListProps> = ({ iconName, iconColor, title, description, children }) => {
    return (
        <View style={styles.container}>
            <View style={styles.messageOuter}>
                {iconName && (
                    <View style={styles.iconOuter}>
                        <Icon hero name={iconName} color={iconColor} />
                    </View>
                )}

                {title && (
                    <Typography variant="h3" style={styles.titleText} color="graySix">
                        {title}
                    </Typography>
                )}

                {description ? (
                    <Typography variant="default" color="graySix" style={styles.descriptionText}>
                        {description}
                    </Typography>
                ) : null}

                {children}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 56,
        paddingHorizontal: 24,
    },
    messageOuter: {
        alignItems: 'center',
    },
    iconOuter: {
        marginBottom: 32,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleText: {
        marginBottom: 16,
        textAlign: 'center',
    },
    descriptionText: {
        textAlign: 'center',
    },
});
