import React, { FC, useEffect, useMemo } from 'react';
import { DraggableWorksheetFormComponent, FormElementFile, FormElementTypeId } from '@packages/models/api';
import AddField from './add-field';
import { AddFieldType } from './worksheet-form-component-edit-modal';
import colors from '@packages/core/styles/colors';
import { useValidationField } from './hooks/useValidationField';
import useTranslationData from './hooks/useTranslationData';

interface FormWorksheetEditTextStaticProps extends DraggableWorksheetFormComponent {
    onChange({
        formElementLabel,
        formElementFile,
        formElementDescription,
    }: {
        formElementLabel?: string;
        formElementFile?: FormElementFile;
        formElementDescription?: string;
    }): void;
    addField: AddFieldType;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormEditTextStatic: FC<FormWorksheetEditTextStaticProps> = ({
    formElementLabel,
    formElementTooltip,
    formElementFile,
    formElementTypeId,
    formElementDescription,
    onChange,
    addField,
    setIsValid,
}) => {
    const {
        fieldHandlers: { handleRequired },
        fieldErrorMessages,
        isValid,
    } = useValidationField();
    const {
        draggableElementsDataTranslate: { labels },
    } = useTranslationData();
    const isHeader = useMemo(() => {
        return formElementTypeId === FormElementTypeId.TextHeader;
    }, [formElementTypeId]);

    useEffect(() => {
        setIsValid(isValid);
    }, [Object.keys(fieldErrorMessages || {})]);
    return (
        <>
            <AddField
                addFieldValues={addField}
                onchangeText={onChange}
                formElementFile={formElementFile}
                formElementTooltip={formElementTooltip}
                formElementDescription={formElementDescription}
                formElementLabel={formElementLabel}
                errorMessage={fieldErrorMessages?.questionLabel}
                handleRequired={handleRequired}
                questionLabel={isHeader ? labels.titleText : labels.paragraphText}
                questionLabelType={isHeader ? 'textField' : 'paragraph'}
                errorMessageType={isHeader ? 'questionLabel' : 'paragraph'}
            />
            <hr style={{ borderWidth: 2, borderColor: colors.grayTwo }} />
        </>
    );
};
