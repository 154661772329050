import colors from '@packages/core/styles/colors';
import React, { FC } from 'react';
import { View, StyleSheet } from 'react-native';
import { Typography } from './typography';

interface CollapseProps {
    badge: string | number;
}

export const Badge: FC<CollapseProps> = ({ badge }) => {
    return (
        <View style={styles.badgeWrapper}>
            <Typography variant="caption" color="white">
                {badge}
            </Typography>
        </View>
    );
};

const styles = StyleSheet.create({
    badgeWrapper: {
        backgroundColor: colors.blueOne,
        borderRadius: 100,
        minWidth: 18,
        height: 18,
        marginHorizontal: 5,
        paddingHorizontal: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
