import React, { FC } from 'react';
import { FlatList, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Comment } from '@packages/models/api';
import { EmptyList } from '../shared';
import { QmrComment } from './qmr-comment';

interface Props {
    comments: Comment[];
    commentOptionsRenderer: (comment: Comment) => JSX.Element | null;
    editCommentId?: string;
    editCommentRenderer?: (comment: Comment) => JSX.Element;
}

export const QmrCommentsList: FC<Props> = ({
    comments,
    commentOptionsRenderer,
    editCommentId,
    editCommentRenderer,
}) => {
    const { t } = useTranslation();

    return (
        <FlatList
            contentContainerStyle={Platform.select({
                web: undefined,
                default: { paddingTop: 24, paddingHorizontal: 20 },
            })}
            data={comments}
            keyboardDismissMode="interactive"
            renderItem={({ item: comment }) => {
                return (
                    <QmrComment
                        comment={comment}
                        commentOptionsRenderer={commentOptionsRenderer}
                        editCommentRenderer={
                            comment.commentId === editCommentId && typeof editCommentRenderer === 'function'
                                ? editCommentRenderer
                                : undefined
                        }
                    />
                );
            }}
            ListEmptyComponent={
                <EmptyList
                    iconName="chat-bubble"
                    title={t('shared:qmrCommentList.emptyList.title', 'No comments have been added')}
                    description={t(
                        'shared:qmrCommentList.emptyList.description',
                        'Comments will not be displayed to retailers'
                    )}
                />
            }
            keyExtractor={(item) => item.commentId}
        />
    );
};
