import React, { FC } from 'react';
import { ConditionalElement, DraggableWorksheetFormComponent, FormElementOption } from '@packages/models/api';
import { createUseStyles } from 'react-jss';
import { FormComponent } from '../../../apps/web/src/administration/components/worksheet/form-component';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import classNames from 'classnames';
interface Props {
    parentItem: DraggableWorksheetFormComponent;
    handleDragEnd: (result: DropResult) => void;
    onEditClick?(item: DraggableWorksheetFormComponent): void;
    onDeleteClick?(item: DraggableWorksheetFormComponent): void;
    onCopyClick?(item: DraggableWorksheetFormComponent): void;
    onChildDrag?(item: DraggableWorksheetFormComponent): void;
    optionConditionsElements: ConditionalElement[] | undefined;
    option: FormElementOption;
}

export const ConditionalOptions: FC<Props> = ({
    parentItem,
    handleDragEnd,
    onEditClick,
    onChildDrag,
    onCopyClick,
    onDeleteClick,
    optionConditionsElements,
    option,
}) => {
    const classes = useFormComponentStyles();
    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={`CONDITIONAL_FORM_COMPONENTS-${option.optionId}`}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            className={classNames({
                                [classes.formContainer]: true,
                            })}
                        >
                            {optionConditionsElements?.map((element, index) => {
                                if (element.targetOptionId === option.optionId) {
                                    return (
                                        <Draggable
                                            key={element.formElementId}
                                            draggableId={element.formElementId}
                                            index={index}
                                        >
                                            {(providedd, snapshott) => (
                                                <FormComponent
                                                    provided={providedd}
                                                    snapshot={snapshott}
                                                    item={element}
                                                    editIconName="pencil"
                                                    deleteIconName="trash"
                                                    copyIconName="copy"
                                                    draggableIcon="draggable"
                                                    onEditClick={(elementt) => {
                                                        onEditClick?.(elementt);
                                                    }}
                                                    onDeleteClick={(elementt) => {
                                                        onDeleteClick?.(elementt);
                                                    }}
                                                    onChildDrag={(elementt) => {
                                                        onChildDrag?.(elementt);
                                                    }}
                                                    onCopyClick={(elementt) => onCopyClick?.(elementt)}
                                                    parentItem={parentItem}
                                                />
                                            )}
                                        </Draggable>
                                    );
                                }
                            })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

const useFormComponentStyles = createUseStyles({
    formContainer: {
        padding: '0 64px',
    },
});
