import React, { FC } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Vehicle } from '@packages/models/api';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@packages/ui/shared';

interface PossibleVehicleCardProps {
    vehicle: Vehicle;
    onSelect: () => void;
}
const PossibleVehicleCard: FC<PossibleVehicleCardProps> = ({ vehicle, onSelect }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <div className="mb-0">
                    <Typography variant="h4">
                        {vehicle.modelYear} {vehicle.carlineName}
                    </Typography>
                </div>
                <div className="mb-2">
                    <Typography variant="h5">
                        {t('qmr:details.vin')}: <Typography variant="lead">{vehicle.vin}</Typography>
                    </Typography>
                </div>

                <Row>
                    <Col>
                        <Typography color="graySix">{t('qmr:details.transmissionNumber')}:</Typography>
                    </Col>
                    <Col>
                        <Typography variant="lead">{vehicle.transmissionNumber}</Typography>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Typography color="graySix">{t('qmr:details.engine')}:</Typography>
                    </Col>
                    <Col>
                        <Typography variant="lead">{vehicle.engineNumber}</Typography>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Typography color="graySix">{t('qmr:details.color')}:</Typography>
                    </Col>
                    <Col>
                        <Typography variant="lead">{vehicle.colorCode}</Typography>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Typography color="graySix">{t('qmr:details.model')}:</Typography>
                    </Col>
                    <Col>
                        <Typography variant="lead">{vehicle.modelCode}</Typography>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Typography color="graySix">{t('qmr:details.modelOption')}:</Typography>
                    </Col>
                    <Col>
                        <Typography variant="lead">{vehicle.optionCode}</Typography>
                    </Col>
                </Row>
                <Button variant="primary" onPress={() => onSelect()}>
                    {t('modals:editVehicle.actions.selectVehicle')}
                </Button>
            </Card.Body>
        </Card>
    );
};

export default PossibleVehicleCard;
