import { WorksheetForm } from '@packages/models/api/worksheets';
import { worksheetsService } from '@web/services/singletons';
import { useCallback, useState } from 'react';
import { WorksheetAction } from '../types';
import { IWorksheetsState, useWorksheetState } from '@packages/contexts/worksheets';

interface Props {
    worksheet?: IWorksheetsState['worksheet'];
    worksheetFormId: string | undefined;
    worksheetAction?: WorksheetAction;
}

const useWorksheetData = ({ worksheetFormId, worksheetAction }: Props) => {
    const [worksheetData, setWorksheetData] = useState<WorksheetForm>();
    const [fetching, setFetching] = useState<boolean>(false);
    const worksheetState = useWorksheetState();
    const { worksheet } = worksheetState;

    const fetchData = useCallback(async () => {
        if (!worksheetFormId || worksheet || worksheetAction === WorksheetAction.New) {
            return;
        }
        setFetching(true);
        worksheetsService.fetchWorksheetFormById(worksheetFormId).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }
            const data = response.data;
            setWorksheetData(data);
            setFetching(false);
        });
    }, [worksheet, worksheetAction, worksheetFormId]);

    return {
        worksheetData,
        fetching,
        actions: {
            fetchData,
        },
    };
};

export type UseWorksheetFormReturnType = ReturnType<typeof useWorksheetData>;

export default useWorksheetData;
