import React, { createContext, FC, useCallback, useContext, useState } from 'react';

type OverlayName =
    | 'notifications'
    | 'qmrQuickFilters'
    | 'advancedSearch'
    | 'manageUsers'
    | 'addUser'
    | 'investigationDetails'
    | 'investigationQuickFilters'
    | 'escalationQuickFilters'
    | 'techlineQuickFilters'
    | 'worksheetFilter'
    | 'addFailCode'
    | 'createFailCodeTeam'
    | 'editFailCodeTeam'
    | 'addFailCodeToTeam'
    | 'addMissingFailCode'
    | 'transferFailCode';
type OverlayToggledState = Record<OverlayName, boolean>;

const ContentOverlayContext = createContext<
    | {
          toggledOverlays: OverlayToggledState;
          showOverlay: boolean;
          toggleOverlay: (overlayName: OverlayName) => void;
          dismissAllOverlays: () => void;
          draggable: boolean;
          toggleDraggable: (draggable: boolean) => void;
      }
    | undefined
>(undefined);

const initialState = {
    notifications: false,
    qmrQuickFilters: false,
    advancedSearch: false,
    manageUsers: false,
    addUser: false,
    investigationDetails: false,
    investigationQuickFilters: false,
    escalationQuickFilters: false,
    techlineQuickFilters: false,
    worksheetFilter: false,
    addFailCode: false,
    createFailCodeTeam: false,
    editFailCodeTeam: false,
    addFailCodeToTeam: false,
    addMissingFailCode: false,
    transferFailCode: false,
};

export const ContentOverlayProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [toggledOverlays, setToggledOverlays] = useState<OverlayToggledState>({ ...initialState });
    const [draggable, setDraggable] = useState(false);

    const toggleDraggable = useCallback((draggable: boolean) => {
        setDraggable(draggable);
    }, []);

    const toggleOverlay = useCallback(
        (overlayName: OverlayName) => {
            setToggledOverlays({
                ...toggledOverlays,
                [overlayName]: !toggledOverlays[overlayName],
            });
        },
        [toggledOverlays]
    );

    const dismissAllOverlays = useCallback(() => {
        setToggledOverlays({ ...initialState });
    }, []);

    const showOverlay = Object.values(toggledOverlays).some((isToggled) => isToggled);

    return (
        <ContentOverlayContext.Provider
            value={{
                dismissAllOverlays,
                toggledOverlays,
                showOverlay,
                toggleOverlay,
                draggable,
                toggleDraggable,
            }}
        >
            {children}
        </ContentOverlayContext.Provider>
    );
};

export const useContentOverlayState = () => {
    const state = useContext(ContentOverlayContext);

    if (state === undefined) {
        throw new Error('useContentOverlayState must be used within an ContentOverlayProvider');
    }

    return state;
};
