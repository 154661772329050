import React, { useState } from 'react';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { caseStatusMap as statusMap } from '@web/utils';

const useStyles = createUseStyles({
    vehicleInfoContainerItem: {
        flex: 1,
    },
});

interface Props {
    type: string;
    vehicleDetails?: any;
}

export const VehicleInfo = ({ type, vehicleDetails }: Props) => {
    const classes = useStyles();

    const [showAll, setShowAll] = useState(false);

    const renderHeaderButton = () => {
        if (statusMap['DRAFT'].includes(type)) {
            return (
                // <Button variant="tertiary" onPress={() => {}}>
                <Typography color="textDarkSecondary"></Typography>
                // </Button>
            );
        } else if (statusMap['OPEN'].includes(type) || statusMap['PENDING_TECHLINE'].includes(type)) {
            return (
                <Button
                    variant="link"
                    onPress={() => {
                        setShowAll((preVal) => !preVal);
                    }}
                >
                    <Typography variant="h6" color="blueOne">
                        {showAll ? 'View less' : 'View more'}
                    </Typography>
                    <Icon size={24} name={showAll ? 'minus' : 'plus'} color={'blueOne'} />
                </Button>
            );
        }
    };

    return (
        <div>
            <div className="d-flex flex-row align-items-center justify-content-between">
                <Typography variant="h3">Vehicle information</Typography>
                {renderHeaderButton()}
            </div>
            <div className="d-flex flex-row">
                <div className={`d-flex flex-column ${classes.vehicleInfoContainerItem}`}>
                    <div className="mt-5 mb-5 d-flex flex-column">
                        <Typography variant="h6">VIN</Typography>
                        <div className="mt-1">
                            <Typography>{vehicleDetails?.vin || '--'}</Typography>
                        </div>
                    </div>

                    <div className="mb-5 d-flex flex-column">
                        <Typography variant="h6">Engine number</Typography>
                        <div className="mt-1">
                            <Typography>{vehicleDetails?.engineId || '--'}</Typography>
                        </div>
                    </div>

                    {(showAll ||
                        !(statusMap['OPEN'].includes(type) || statusMap['PENDING_TECHLINE'].includes(type))) && (
                        <>
                            <div className="mb-5 d-flex flex-column">
                                <Typography variant="h6">Color</Typography>
                                <div className="mt-1">
                                    <Typography>{vehicleDetails?.colorCategory || '--'}</Typography>
                                </div>
                            </div>

                            <div className="mb-5 d-flex flex-column">
                                <Typography variant="h6">Model option</Typography>
                                <div className="mt-1">
                                    <Typography>{vehicleDetails?.optionCode || '--'}</Typography>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={`d-flex flex-column ${classes.vehicleInfoContainerItem}`}>
                    <div className="mt-5 mb-5 d-flex flex-column">
                        <Typography variant="h6">Model year</Typography>
                        <div className="mt-1">
                            <Typography>{vehicleDetails?.modelYear || '--'}</Typography>
                        </div>
                    </div>

                    <div className="mb-5 d-flex flex-column">
                        <Typography variant="h6">Carline</Typography>
                        <div className="mt-1">
                            <Typography>{vehicleDetails?.model || '--'}</Typography>
                        </div>
                    </div>

                    {showAll && (
                        <>
                            <div className="mb-5 d-flex flex-column">
                                <Typography variant="h6">Transmission number</Typography>
                                <div className="mt-1">
                                    <Typography>{vehicleDetails?.vehicleTransmissionNumber || '--'}</Typography>
                                </div>
                            </div>

                            <div className="mb-5 d-flex flex-column">
                                <Typography variant="h6">Model</Typography>
                                <div className="mt-1">
                                    <Typography>{vehicleDetails?.modelNumber || '--'}</Typography>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
