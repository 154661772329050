import React, { FC, useCallback, useEffect, useState } from 'react';
import { FlatList, View, TouchableOpacity } from 'react-native';

import { Typography } from './typography';
import { Button } from './button';
import { Collapse } from './collapse';
import notifee from '@notifee/react-native';

export const DebugMessaging: FC<{ account: any; service: any; messaging: any }> = ({ account, service, messaging }) => {
    const [deviceInfo, setDeviceInfo] = useState({
        type: '',
        fingerprint: '',
        token: '',
    });
    const [messages, setMessages] = useState<any[]>([]);
    const [selectedIdx, setSelectedIdx] = useState(-1);

    const [supportsMessaging, setSupportsMessaging] = useState(true);

    const readDeviceInfo = useCallback(() => {
        setDeviceInfo({
            type: service.clientDeviceTypeId,
            fingerprint: service.deviceFingerprint,
            token: service.pushToken,
        });
    }, [service.clientDeviceTypeId, service.deviceFingerprint, service.pushToken]);

    useEffect(() => {
        readDeviceInfo();
    }, [readDeviceInfo]);

    useEffect(() => {
        function addMessage(msg: any) {
            setMessages([...messages, msg]);
        }

        try {
            const messagingInstance = messaging();

            messagingInstance.onMessage((m: any) => {
                addMessage(m);
            });

            notifee.onBackgroundEvent(async ({ type, detail }) => {
                addMessage(detail);
            });
        } catch (e) {
            if (e.code === 'messaging/unsupported-browser') {
                setSupportsMessaging(false);
            }
        }
    }, [messages, messaging]);

    if (!account) {
        return (
            <View>
                <Typography>Must be signed in.</Typography>
            </View>
        );
    }

    if (!supportsMessaging) {
        return (
            <View>
                <Typography>This browser does not support messaging</Typography>
            </View>
        );
    }

    return (
        <View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Button
                    style={{ flex: 1 }}
                    disabled={!deviceInfo.token}
                    variant="danger"
                    title="Disconnect"
                    onPress={() => {
                        service.terminate();
                        readDeviceInfo();
                        setMessages([]);
                    }}
                />

                <Button
                    disabled={!!deviceInfo.token}
                    style={{ flex: 1, marginLeft: 4 }}
                    variant="success"
                    title="Connect"
                    onPress={() => {
                        service.init().then(readDeviceInfo);
                    }}
                />
            </View>

            <Typography variant="label">
                Account id: <Typography>{account.accountId}</Typography>
            </Typography>

            <Typography variant="label">
                Device Type: <Typography>{deviceInfo.type}</Typography>
            </Typography>

            <Typography variant="label">
                Device fingerprint: <Typography>{deviceInfo.fingerprint}</Typography>
            </Typography>

            <Typography variant="label">
                Device token: <Typography>{deviceInfo.token}</Typography>
            </Typography>

            <Typography variant="label">
                Messages & Notifications received -{' '}
                <Typography variant="caption">(tap message to expand json)</Typography>
            </Typography>

            <FlatList
                data={messages}
                style={{ minHeight: 300 }}
                keyExtractor={(item, idx) => {
                    return '' + idx;
                }}
                renderItem={({ item, index }) => {
                    const isSelected = selectedIdx === index;

                    return (
                        <TouchableOpacity
                            onPress={() => {
                                setSelectedIdx(isSelected ? -1 : index);
                            }}
                            style={{ minHeight: 40 }}
                        >
                            <Typography>Message {index + 1}</Typography>

                            <Collapse open={isSelected}>
                                <Typography>{JSON.stringify(item, null, 4)}</Typography>
                            </Collapse>
                        </TouchableOpacity>
                    );
                }}
            />
        </View>
    );
};
