import React, { FC } from 'react';
import { StyleSheet, TouchableOpacity, TouchableOpacityProps, View, StyleProp, TextStyle } from 'react-native';

import colors from '@packages/core/styles/colors';
import { Icon } from './icon';
import { Typography } from './typography';

export interface ListButtonProps extends TouchableOpacityProps {
    title: string;
    titleTextStyle?: StyleProp<TextStyle>;
    secondaryText?: string;
    badge?: string;
    light?: boolean;
    radio?: boolean;
    selected?: boolean;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element | null;
}

export const ListButton: FC<ListButtonProps> = ({
    title,
    titleTextStyle,
    secondaryText,
    badge,
    light,
    radio,
    selected,
    leftIcon,
    rightIcon,
    style,
    children,
    ...props
}) => {
    return (
        <TouchableOpacity {...props} style={[styles.listButton, light && styles.lightButton, style]}>
            {(radio || !!leftIcon) && (
                <View style={styles.leftIconContainer}>
                    {leftIcon || (
                        <Icon
                            name={selected ? 'radio-marked' : 'radio-unmarked'}
                            color={selected ? 'blueOne' : undefined}
                        />
                    )}
                </View>
            )}

            <View style={styles.listButtonTextWrapper}>
                {title ? (
                    <Typography variant="h5" style={titleTextStyle}>
                        {title}
                    </Typography>
                ) : (
                    <></>
                )}

                {secondaryText ? (
                    <Typography variant="caption" color="graySix" numberOfLines={1}>
                        {secondaryText}
                    </Typography>
                ) : undefined}

                {children}
            </View>

            <View style={styles.rightSideOuter}>
                {badge && (
                    <View style={styles.badgeWrapper}>
                        <Typography variant="caption" color="white">
                            {badge}
                        </Typography>
                    </View>
                )}
                {rightIcon}
            </View>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    listButton: {
        borderRadius: 3,
        minHeight: 60,
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 16,
        paddingRight: 12,
        marginBottom: 12,
        backgroundColor: colors.grayThree,
    },
    lightButton: {
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.16,
        shadowRadius: 4,
        elevation: 2,
    },
    leftIconContainer: {
        marginLeft: -4,
        paddingRight: 12,
    },
    listButtonTextWrapper: {
        flex: 1,
    },
    badgeWrapper: {
        backgroundColor: colors.blueOne,
        borderRadius: 100,
        minWidth: 18,
        height: 18,
        paddingHorizontal: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
    rightSideOuter: {
        alignItems: 'center',
        flexDirection: 'row',
    },
});

ListButton.defaultProps = {
    rightIcon: <Icon name="chevron-right" color="blueOne" />,
};
