import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';

export const scDropdown = {
    '.dropdown': {
        '& .dropdown-menu': {
            padding: 0,
            borderRadius: 3,
            overflow: 'hidden',
            border: `1px solid ${colors.grayThree}`,
            boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.16)',
        },
        '& .dropdown-item': {
            '&:hover, &:focus': {
                outline: 'none',
                backgroundColor: colors.grayTwo,
            },
        },
    },
    '.subcomm-dropdown': {
        '&__header': {
            ...fonts.xs,
            color: colors.black,
            ...fonts.robotoMedium,
        },
        '&__divider': {
            height: 1,
            backgroundColor: colors.grayThree,
        },
    },
};
