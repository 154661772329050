import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAbortController } from '@packages/core/http';
import { Retailer } from '@packages/models/api';
import { useQmrState, patchQmr, useQmrDispatch } from '@packages/contexts/qmrs';
import { fetchAuthUserRetailers, useAuthDispatch, useAuthState } from '@packages/contexts/auth';

import { qmrsService } from '@web/services/singletons';
import { Label, Button } from '@packages/ui/shared';
import RetailerSearchInput from '@web/components/retailer-search-input';

type EditRetailerModalProps = {
    show: boolean;
    onHide: () => void;
};

const EditRetailerModal: FC<EditRetailerModalProps> = (props) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const authDispatch = useAuthDispatch();
    const { account } = useAuthState();
    const qmrDispatch = useQmrDispatch();
    const { qmr } = useQmrState();

    const [selectedRetailer, setSelectedRetailer] = useState<Retailer>();

    const handleSelectedRetailer = (retailer?: Retailer) => {
        if (qmr?.capabilities.editRetailerOnQmr) {
            setSelectedRetailer(retailer);
        }
    };

    useEffect(() => {
        if (!account || !props.show) {
            return;
        }

        fetchAuthUserRetailers({
            searchParams: { canCreateQmr: true },
            qmrsService,
            authDispatch,
            signal: abortSignalRef.current,
        }).then((response) => {
            // if user has access to one retailer, pre-select it
            if (response?.data.retailers.length === 1) {
                setSelectedRetailer(response.data.retailers[0]);
            }
        });
    }, [abortSignalRef, account, authDispatch, props.show]);

    // reset input on dismiss
    useEffect(() => {
        if (!props.show && qmr) {
            setSelectedRetailer(qmr.retailer);
        }
    }, [props.show, qmr]);

    function getCreationForm() {
        return (
            <Row className="mb-4">
                <Col xs={12}>
                    <Label required controlId="retailer">
                        {t('qmr:inputs.retailer.label')}
                    </Label>

                    <RetailerSearchInput
                        selectedRetailer={selectedRetailer}
                        onChange={handleSelectedRetailer}
                        disabled={!qmr?.capabilities.editRetailerOnQmr}
                    />
                </Col>
            </Row>
        );
    }

    return (
        <Modal
            show={props.show}
            centered={true}
            onHide={() => {
                props.onHide();
            }}
            size="lg"
        >
            <Modal.Header placeholder={''}>
                <Modal.Title>{t('modals:editRetailer.title', 'Change Retailer')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>{getCreationForm()}</Form>
            </Modal.Body>

            <Modal.Footer>
                <div className="mr-2">
                    <Button
                        variant="ghost-blue"
                        onPress={() => {
                            props.onHide();
                        }}
                    >
                        {t('buttons:cancel')}
                    </Button>
                </div>

                <Button
                    variant="primary"
                    disabled={!selectedRetailer}
                    onPress={() => {
                        if (!qmr || !selectedRetailer) {
                            return;
                        }

                        patchQmr({
                            qmrId: qmr.qmrId,
                            qmrsService,
                            qmrDispatch,
                            signal: abortSignalRef.current,
                            qmrPatch: {
                                retailerId: selectedRetailer.retailerId,
                            },
                        })
                            .then((updatedQmr) => {
                                if (!updatedQmr) {
                                    return;
                                }

                                props.onHide();
                            })
                            .catch((e) => {
                                console.error(e);
                                alert(e.message);
                            });
                    }}
                >
                    {t('modals:editRetailer.actions.update', 'Update')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditRetailerModal;
