import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InvestigationMeetingNoteModel, InvestigationMeetingNoteType } from '@packages/models/api';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { MeetingNoteForm, MeetingNoteFormOnSubmitArgument } from '.';
import { investigationService } from '@web/services/singletons';
import { useAuthState } from '@packages/contexts/auth';

interface MeetingNoteProps {
    meetingNote: InvestigationMeetingNoteModel;
    meetingNoteTypeOptions: InvestigationMeetingNoteType[];
    onMeetingNoteUpdate(meetingNote: InvestigationMeetingNoteModel): void;
}

export const MeetingNote = ({ meetingNote, meetingNoteTypeOptions, onMeetingNoteUpdate }: MeetingNoteProps) => {
    const { t } = useTranslation();
    const { account } = useAuthState();

    const [isEditting, setIsEditting] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleUpdateMeetingNoteButtonPress = useCallback(
        async (meetingNoteForm: MeetingNoteFormOnSubmitArgument) => {
            setIsSubmitting(true);

            try {
                const response = await investigationService.updateMeetingNote({
                    investigationId: meetingNote.investigationId,
                    meetingNoteId: meetingNote.meetingNoteId,
                    body: {
                        meetingTypeId: meetingNoteForm.meetingNoteTypeValue,
                        meetingDate: meetingNoteForm.meetingNoteDateValue,
                        body: meetingNoteForm.meetingNoteDescriptionValue,
                    },
                });

                if (response.success) {
                    setIsSubmitting(false);
                    setIsEditting(false);

                    onMeetingNoteUpdate(response.data.meetingNote);
                } else {
                    throw response.data;
                }
            } catch (error) {
                window.alert(error.message);
                setIsSubmitting(false);
            }
        },
        [meetingNote.investigationId, meetingNote.meetingNoteId, onMeetingNoteUpdate]
    );

    return (
        <div className="mb-6" key={meetingNote.meetingNoteId}>
            {isEditting ? (
                <MeetingNoteForm
                    meetingNoteTypeDefaultValue={meetingNote.meetingTypeId}
                    meetingNoteDateDefaultValue={meetingNote.meetingDateDescription}
                    meetingNoteDescriptionDefaultValue={meetingNote.body}
                    meetingNoteTypeOptions={meetingNoteTypeOptions}
                    showCancelButton={isSubmitting ? false : true}
                    onCancelButtonPress={() => {
                        setIsEditting(false);
                    }}
                    isSubmitting={isSubmitting}
                    submitButtonTitle={t(
                        'investigations:panel.meetingNotes.buttons.updateMeetingNote.title',
                        'Update meeting note'
                    )}
                    onSubmitButtonPress={handleUpdateMeetingNoteButtonPress}
                />
            ) : (
                <>
                    <div className="d-flex">
                        <div style={{ flex: 1 }}>
                            <div className="mb-1">
                                <Typography variant="h5" color="blueOne">
                                    {meetingNote.meetingTypeDescription}
                                </Typography>
                            </div>
                            <div className="mb-3">
                                <Typography variant="caption" color="graySix">
                                    {meetingNote.meetingDate}
                                </Typography>
                            </div>
                        </div>
                        {account?.investigationCapabilities.editAnInvestigation && (
                            <Button
                                variant="tertiary"
                                onPress={() => {
                                    setIsEditting(true);
                                }}
                            >
                                <Icon size={14} name="pencil" />
                            </Button>
                        )}
                    </div>
                    <div>
                        <Typography>{meetingNote.body}</Typography>
                    </div>
                </>
            )}
        </div>
    );
};
