import { Asset, AssetProcessingStateId } from './worksheets';

export enum FormElementTypeId {
    TextHeader = 'TEXT_HEADER',
    TextParagraph = 'TEXT_PARAGRAPH',
    InputText = 'INPUT_TEXT',
    InputTextarea = 'INPUT_TEXTAREA',
    Rating = 'RATING',
    RatingWithText = 'RATING_WITH_TEXT',
    Checkbox = 'CHECKBOX',
    Radio = 'RADIO',
    Dropdown = 'DROPDOWN',
    Date = 'DATE',
    Time = 'TIME',
    File = 'FILE',
    DateTime = 'DATE_TIME',
}

export enum FormElementAction {
    Insert = 'INSERT',
    Update = 'UPDATE',
    Replace = 'REPLACE',
    Delete = 'DELETE',
}
export interface ConditionalElement extends DraggableFormElement {
    targetOptionId: string;
}

export interface FormElementFile {
    file?: File;
    url?: string;
    name?: string;
    caption?: string;
    assetId?: string;
    overlay?: {
        file?: File;
        url?: string;
        name?: string;
    };
    status?: AssetProcessingStateId;
}
export interface FormElement {
    formElementId: string;
    formElementLabel: string;
    formElementTypeId: FormElementTypeId;
    selectedOptions?: FormAnswerSelectedOption[];
    optionConditionsElements?: ConditionalElement[];
    options: FormElementOption[];
    required: boolean;
    formElementTooltip?: string;
    formElementDescription?: string;
    formElementHasRule: boolean;
    formElementFile?: FormElementFile;
    formElementOrder?: number;
    action?: FormElementAction;
    assets?: Asset[];
    answerAssets?: Asset[];
}

export interface FormElementOption {
    optionId: string;
    optionValue: string;
    optionLabel: string;
    optionPlaceholder: string;
    isOther?: boolean;
    trgtElementIdList?: string[];
    answerText?: string;
    optionFile?: FormElementFile;
    assets?: Asset[];
}

export interface FormAnswerSelectedOption {
    optionId: string;
    optionLabel?: string;
    answerText?: string;
}

export interface DraggableFormElement extends FormElement {
    title: string;
    description: string;
    iconName: string;
}

export interface DraggableFormComponent extends DraggableFormElement {}

export interface FromAnswerSelectedOption {
    optionId: string;
    answerText?: string;
}

export interface FormAnswer {
    elementId: string;
    options: FromAnswerSelectedOption[];
}

export const draggableFormElements: Record<FormElementTypeId, DraggableFormElement> = {
    [FormElementTypeId.TextHeader]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.TextHeader,
        formElementLabel: '',
        selectedOptions: [],
        options: [],
        title: 'Text: Header',
        description: 'Header',
        iconName: 'title',
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        required: false,
        formElementHasRule: false,
    },
    [FormElementTypeId.TextParagraph]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.TextParagraph,
        formElementLabel: '',
        selectedOptions: [],
        options: [],
        title: 'Text: Paragraph',
        description: 'Paragraph',
        iconName: 'paragraph',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.InputText]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.InputText,
        formElementLabel: 'Input Label',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '',
                optionLabel: '',
                optionPlaceholder: '',
            },
        ],
        title: 'Question: Short Text Input',
        description: 'Short Text Input',
        iconName: 'short-text',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.InputTextarea]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.InputTextarea,
        formElementLabel: 'Input Label',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '',
                optionLabel: '',
                optionPlaceholder: '',
            },
        ],
        title: 'Question: Long Text Input',
        description: 'Long Text Input',
        iconName: 'long-text',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.Rating]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.Rating,
        formElementLabel: 'Rate',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '1',
                optionLabel: 'Needs Improvement',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '2',
                optionLabel: 'Acceptable',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '3',
                optionLabel: 'Good',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '4',
                optionLabel: 'Very Good',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '5',
                optionLabel: 'Awesome',
                optionPlaceholder: '',
            },
        ],
        title: 'Question: Likert Scale',
        description: 'Likert Scale',
        iconName: 'likert',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.RatingWithText]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.RatingWithText,
        formElementLabel: 'Rate',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '1',
                optionLabel: 'Needs Improvement',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '2',
                optionLabel: 'Acceptable',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '3',
                optionLabel: 'Good',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '4',
                optionLabel: 'Very Good',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '5',
                optionLabel: 'Awesome',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '',
                optionLabel: 'Add Comment',
                optionPlaceholder: '',
            },
        ],
        title: 'Question: Likert Scale With Long Text Input',
        description: 'Likert Scale With Long Text Input',
        iconName: 'copy',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.Checkbox]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.Checkbox,
        formElementLabel: 'Select All That Apply',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '1',
                optionLabel: 'Option 1',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '2',
                optionLabel: 'Option 2',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '3',
                optionLabel: 'Option 3',
                optionPlaceholder: '',
            },
        ],
        title: 'Question: Checkbox Group',
        description: 'Checkbox Group',
        iconName: 'checkbox-group',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.Radio]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.Radio,
        formElementLabel: 'Select One',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '1',
                optionLabel: 'Option 1',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '2',
                optionLabel: 'Option 2',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '3',
                optionLabel: 'Option 3',
                optionPlaceholder: '',
            },
        ],
        title: 'Question: Radio Group',
        description: 'Radio Group',
        iconName: 'radio-group',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.Date]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.Date,
        formElementLabel: 'Date',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '',
                optionLabel: '',
                optionPlaceholder: 'Your placeholderDate here',
            },
        ],
        title: 'Question: Date',
        description: 'Date',
        iconName: 'long-text',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.Time]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.Time,
        formElementLabel: 'Time',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '',
                optionLabel: '',
                optionPlaceholder: 'Your placeholderDate here',
            },
        ],
        title: 'Question: Time',
        description: 'Time',
        iconName: 'long-text',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.File]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.File,
        formElementLabel: 'File or Image',
        selectedOptions: [],
        title: 'Question: File or Image',
        description: 'File or Image',
        iconName: 'long-text',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
        options: [],
    },
    [FormElementTypeId.Dropdown]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.Dropdown,
        formElementLabel: 'Select One',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '1',
                optionLabel: 'Option 1',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '2',
                optionLabel: 'Option 2',
                optionPlaceholder: '',
            },
            {
                optionId: '',
                optionValue: '3',
                optionLabel: 'Option 3',
                optionPlaceholder: '',
            },
        ],
        title: 'Question: Dropdown',
        description: 'Dropdown',
        iconName: 'select',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
    [FormElementTypeId.DateTime]: {
        formElementId: '',
        formElementTypeId: FormElementTypeId.DateTime,
        formElementLabel: 'Date and Time',
        selectedOptions: [],
        options: [
            {
                optionId: '',
                optionValue: '',
                optionLabel: '',
                optionPlaceholder: 'Your placeholderDate here',
            },
        ],
        title: 'Question: Date and Time',
        description: 'Date and Time',
        iconName: 'long-text',
        required: false,
        formElementTooltip: '',
        formElementFile: {},
        formElementDescription: '',
        formElementHasRule: false,
    },
};
