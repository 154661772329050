import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import { Button, Icon, LoadingCircle, Typography } from '@packages/ui/shared';
import { Form } from 'react-bootstrap';
import colors from '@packages/core/styles/colors';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import Select from '@web/components/select';
import { AccountRoleOption } from '@packages/models/api';
import { accountsService, feedbackAdminService } from '@web/services/singletons';
import { Typeahead } from 'react-bootstrap-typeahead';

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.grayOne,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    panelCenterContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    panelContainer: {
        padding: 24,
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

interface AddUserProps {
    onAccountCreated(soaUserName: string): void;
}

export const AddUserPanel = ({ onAccountCreated }: AddUserProps) => {
    const classes = useStyles();
    const { dismissAllOverlays } = useContentOverlayState();

    const [isLoading, setIsLoading] = useState(false);
    const [baseRoleOptions, setBaseRoleOptions] = useState<AccountRoleOption[]>([]);
    const [addOnRoleOptions, setAddOnRoleOptions] = useState<AccountRoleOption[]>([]);
    //form input values
    const [subaruUserName, setSubaruUserName] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [addOnRoles, setAddOnRoles] = useState<AccountRoleOption[]>([]);
    const [baseRoles, setBaseRoles] = useState<AccountRoleOption[]>([]);
    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            try {
                const rolesResponse = await feedbackAdminService.fetchAccountRoles();
                if (rolesResponse.success) {
                    setBaseRoleOptions(rolesResponse.data.baseRoles);
                    setAddOnRoleOptions(rolesResponse.data.addOnRoles);
                } else {
                    throw rolesResponse.data;
                }
            } catch (error) {
                window.alert(error.message);
            }

            setIsLoading(false);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handeSaveButtonClick = useCallback(async () => {
        setIsLoading(true);

        try {
            const createAccountRequestBody = {
                soaUsername: subaruUserName,
                firstName: firstName,
                lastName: lastName,
                emailAddress: email,
                roleTargetTypeId: 'GLOBAL', //we are not taking input for this, hardcoded
                baseRole: baseRoles[0].roleId,
                addOnRole: addOnRoles.map((addOnRole) => addOnRole.roleId),
                jobTitle: '',
            };

            const response = await accountsService.createAccount(createAccountRequestBody);
            if (response.success) {
                onAccountCreated(subaruUserName);
                dismissAllOverlays();
            } else {
                console.log('response------------------------', response);
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }

        setIsLoading(false);
    }, [addOnRoles, baseRoles, onAccountCreated, email, firstName, lastName, subaruUserName, dismissAllOverlays]);

    const rightPanelDiv = document.getElementById('right-side-panel');
    if (!rightPanelDiv) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <div className={classes.panelHeader}>
                <Typography>Add a new user manually to TechShare</Typography>
                <div className="d-flex align-items-center">
                    <Button onPress={handeSaveButtonClick}>Save Changes</Button>
                    <div className={classes.closeButton} onClick={dismissAllOverlays}>
                        <Icon name="x-close" style={{ display: 'flex' }} />
                    </div>
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                {isLoading && (
                    <div className={classes.panelCenterContainer}>
                        <LoadingCircle size={64} borderWidth={8} borderColor={colors.grayOne} />
                    </div>
                )}

                {!isLoading && (
                    <div className={classes.panelContainer}>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Subaru Username
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    value={subaruUserName}
                                    // @ts-ignore
                                    onChange={(e) => {
                                        setSubaruUserName(e.target.value);
                                    }}
                                />
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    First Name
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    value={firstName}
                                    // @ts-ignore
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                />
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Last Name
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    value={lastName}
                                    // @ts-ignore
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                    }}
                                />
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Email
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    value={email}
                                    // @ts-ignore
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Role
                                </Typography>
                            </div>

                            <div className="mb-2">
                                <Select
                                    value={baseRoles[0]?.roleId}
                                    options={[
                                        { value: 'SELECT', title: 'SELECT' },
                                        ...baseRoleOptions.map((option) => {
                                            return {
                                                value: option.roleId,
                                                title: option.description,
                                            };
                                        }),
                                    ]}
                                    onChange={(event) => {
                                        const selectedRole = baseRoleOptions.filter(
                                            (option) => option.roleId === event.currentTarget.value
                                        );
                                        setBaseRoles(selectedRole);
                                    }}
                                    required={true}
                                />
                            </div>

                            <hr />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1">
                                <Typography variant="caption2" color="graySix">
                                    Add On Role
                                </Typography>
                            </div>
                            <div className="mb-2">
                                <Typeahead
                                    id="manage-users-async-typeahead"
                                    placeholder="Search for roles..."
                                    labelKey="description"
                                    multiple
                                    selected={addOnRoles}
                                    onChange={(selections) => {
                                        setAddOnRoles(selections);
                                    }}
                                    options={addOnRoleOptions}
                                />
                            </div>
                            <hr />
                        </div>
                    </div>
                )}
            </div>
        </div>,
        rightPanelDiv
    );
};
