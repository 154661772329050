import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import fonts from '@web/jss/fonts';
import colors from '@packages/core/styles/colors';

interface AvatarProps {
    imageUrl?: string;
    size: number;
    firstName?: string;
    lastName?: string;
    className?: string;
}

interface UseAvatarStylesProps {
    size: number;
    imageUrl: string;
}

const Avatar: FC<AvatarProps> = ({ size, imageUrl, className, firstName, lastName }) => {
    const classes = useAvatarStyles({
        size,
        imageUrl,
    });

    return (
        <div className={classNames(classes.avatar, className)}>
            {!imageUrl && (firstName?.charAt(0) ?? '') + (lastName?.charAt(0) ?? '')}
        </div>
    );
};

const useAvatarStyles = createUseStyles({
    avatar: (props: UseAvatarStylesProps) => ({
        width: props.size,
        height: props.size,
        ...(props.size <= 32 ? fonts.xs : fonts.l),
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        color: colors.blueOne,
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        bacgroundRepear: 'no-repeat',
        backgroundColor: colors.white,
        ...(props.imageUrl ? { backgroundImage: `url(${props.imageUrl})` } : {}),
    }),
});

export default Avatar;
