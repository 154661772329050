import { useAbortController } from '@packages/core/http';
import { Retailer } from '@packages/models/api';
import { qmrsService } from '@web/services/singletons';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { AsyncTypeahead, TypeaheadState } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import RetailerSearchResults from './retailer-search-results';

interface Props {
    selectedRetailer?: Retailer;
    onChange: (retailer?: Retailer) => void;
    disabled?: boolean;
}

const RetailerSearchInput: FC<Props> = ({ selectedRetailer, onChange, disabled = false }) => {
    const { abortSignalRef } = useAbortController();
    const { t } = useTranslation();

    const retailerSearchRef = useRef<AsyncTypeahead<Retailer>>(null);
    const [isSearchingRetailers, setIsSearchingRetailers] = useState(false);
    const [retailerSearchQuery, setRetailerSearchQuery] = useState('');
    const [retailerSearchFocused, setRetailerSearchFocused] = useState(false);
    const [retailerOptions, setRetailerOptions] = useState<Retailer[]>([]);
    const [recentRetailers, setRecentRetailers] = useState<Retailer[]>([]);

    const queryRetailers = useCallback(
        async (query: string) => {
            setRetailerSearchQuery(query);

            if (!query) {
                return;
            }

            setIsSearchingRetailers(true);

            qmrsService
                .fetchRetailers({
                    searchParams: { canCreateQmr: true, query },
                    ignoreCache: true,
                    signal: abortSignalRef.current,
                })
                .then((res) => {
                    if (!res.success && res.aborted) {
                        return;
                    } else if (!res.success) {
                        alert(res.data.message);
                        return;
                    }

                    setRetailerOptions(res.data.retailers);
                });

            setIsSearchingRetailers(false);
        },
        [abortSignalRef]
    );

    useEffect(() => {
        // fetch recent retailers
        qmrsService
            .fetchRetailers({
                searchParams: { canCreateQmr: true, recent: true },
                signal: abortSignalRef.current,
            })
            .then((recentResponse) => {
                if (!recentResponse.success && recentResponse.aborted) {
                    return;
                } else if (!recentResponse.success) {
                    alert(recentResponse.data.message);
                    return;
                }

                setRecentRetailers(recentResponse.data.retailers);
            });
    }, [abortSignalRef]);

    return (
        <AsyncTypeahead
            disabled={disabled}
            ref={retailerSearchRef}
            id="newQmr-retailer"
            selected={selectedRetailer ? [selectedRetailer] : []}
            isLoading={isSearchingRetailers}
            labelKey={({ name, city, state, code }) => `${name}, ${city} ${state} (#${code})`}
            open={
                retailerSearchFocused
                    ? retailerSearchQuery
                        ? retailerOptions.length > 0
                        : recentRetailers.length > 0
                    : false
            }
            renderMenu={(results, menuProps, ...args) => {
                const typeaheadState = (args as any)[0] as TypeaheadState<Retailer>;

                if (!typeaheadState.text && results.length === 0) {
                    return null;
                }

                const title = isSearchingRetailers
                    ? t('text:loading.searching', 'Searching')
                    : results.length > 0
                    ? t('text:suggested')
                    : t('text:noMatches', 'No Matches');

                return (
                    <RetailerSearchResults
                        {...menuProps}
                        title={title}
                        results={results}
                        typeaheadState={typeaheadState}
                    />
                );
            }}
            flip={true}
            inputProps={{
                id: 'newQmr-retailer',
            }}
            onFocus={() => setRetailerSearchFocused(true)}
            onBlur={() => setRetailerSearchFocused(false)}
            useCache={false}
            minLength={1}
            onSearch={queryRetailers}
            onChange={([retailer]) => {
                onChange(retailer);

                if (retailer) {
                    //@ts-ignore
                    retailerSearchRef.current && retailerSearchRef.current.blur();
                }
            }}
            options={retailerSearchQuery ? retailerOptions : recentRetailers}
        />
    );
};

export default RetailerSearchInput;
