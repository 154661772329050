import React, { Fragment, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { techlineService } from '@web/services/singletons';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { useAbortController } from '@packages/core/http';
import { Label, Typography, Button, Icon, ListButton } from '@packages/ui/shared';
import Select, { SelectOption } from '@web/components/select';
import { FailCode, FailCodeSection, FailCodeSubsection } from '@packages/models/api';
import { FailCodeDetails } from '@packages/ui/qmr';

interface TechlineFailCodePartSearchProps {
    returnToHome: () => void;
    onHide: () => void;
    updateCaseDetails: any;
    prefillPartNumber?: boolean;
    vehicleDetails: any;
}

const failCodePartSearchStyles = createUseStyles({
    failCodePartSearch: {},
    partRecords: {
        alignItems: 'center',
    },
    partLabel: {
        flex: 5,
    },
});

export const TechlineFailCodePartSearch = (props: TechlineFailCodePartSearchProps) => {
    const { t } = useTranslation();
    const classes = failCodePartSearchStyles();
    const { abortSignalRef, signalAbort } = useAbortController();
    const searchInputRef = useRef<AsyncTypeahead<any>>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Array<any>>([]);
    const [selectedOption, setSelectedOption] = useState<any>();
    const [potentialFailCodeSections, setPotentialFailCodeSections] = useState<SelectOption[]>([]);
    const [selectedFailCodeSection, setSelectedFailCodeSection] = useState<FailCodeSection>();
    const [potentialFailCodeSubsections, setPotentialFailCodeSubsections] = useState<SelectOption[]>([]);
    const [selectedFailCodeSubsection, setSelectedFailCodeSubsection] = useState<FailCodeSubsection>();
    const [partFailCodes, setPartFailCodes] = useState<FailCode[]>([]);
    const [selectedPartFailCode, setSelectedPartFailCode] = useState<FailCode>();
    const [confirmedCodeSelection, setConfirmedCodeSelection] = useState(false);
    const [isOnlyMatch, setIsOnlyMatch] = useState(false);
    const [didCheckSections, setDidCheckSections] = useState(false);

    const failCodeSectionName = useMemo(() => {
        if (potentialFailCodeSections.length === 2) {
            return JSON.parse(potentialFailCodeSections[1].value).sectionName;
        }
    }, [potentialFailCodeSections]);

    const failCodeSubsectionName = useMemo(() => {
        if (potentialFailCodeSections.length === 2 && potentialFailCodeSubsections.length === 2) {
            return JSON.parse(potentialFailCodeSubsections[1].value).subsectionName;
        }
    }, [potentialFailCodeSections.length, potentialFailCodeSubsections]);

    /* --------------------------------------------------------- */
    /* Prefill partNumber if "View matching fail code(s)" */
    /* --------------------------------------------------------- */
    useEffect(() => {
        if (!props.prefillPartNumber) {
            return;
        }

        async function fetchPrefillData() {
            try {
                setIsLoading(true);

                const response = await techlineService.searchPartNumbers({
                    modelCode: props.vehicleDetails?.modelNumber,
                    modelYear: props.vehicleDetails?.modelYear,
                    optionCode: props.vehicleDetails?.optionCode,
                    query: '',
                    signal: abortSignalRef.current,
                });

                setIsLoading(false);

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setSelectedOption(response.data.parts[0]);
                setOptions(response.data.parts);
            } catch (error) {
                setIsLoading(false);
                window.alert(error.message);
            }
        }

        fetchPrefillData();
    }, [
        abortSignalRef,
        props.prefillPartNumber,
        props.vehicleDetails?.modelNumber,
        props.vehicleDetails?.modelYear,
        props.vehicleDetails?.optionCode,
    ]);

    const queryFailCodes = useCallback(
        async (query: string) => {
            setIsLoading(true);
            signalAbort();

            techlineService
                .searchPartNumbers({
                    modelCode: props.vehicleDetails?.modelNumber,
                    modelYear: props.vehicleDetails?.modelYear,
                    optionCode: props.vehicleDetails?.optionCode,
                    query,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                })
                .then((response) => {
                    setIsLoading(false);
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }

                    setOptions(response.data.parts);
                    const el = document.getElementById('fail-code-search');
                    if (!el) {
                        searchInputRef.current?.toggleMenu();
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        },
        [
            abortSignalRef,
            props.vehicleDetails?.modelNumber,
            props.vehicleDetails?.modelYear,
            props.vehicleDetails?.optionCode,
            signalAbort,
        ]
    );

    const handleSubmit = () => {
        if (!selectedOption) {
            return;
        }

        if (!selectedPartFailCode) {
            return;
        }

        props.updateCaseDetails({
            repairCode: selectedPartFailCode.failCode,
            failCode: selectedPartFailCode.failCode,
            section: selectedFailCodeSection?.sectionName,
            subSection: selectedFailCodeSubsection?.subsectionName,
        });
        props.onHide();
    };

    const fetchFailCodeSectionAndSubsectionOptions = useCallback(
        (code: FailCode) => {
            return techlineService
                .fetchPossibleSections({
                    modelCode: props.vehicleDetails?.modelNumber,
                    modelYear: props.vehicleDetails?.modelYear,
                    optionCode: props.vehicleDetails?.optionCode,
                    failCode: code.failCode,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                })
                .then((secResponse) => {
                    if (!secResponse.success && secResponse.aborted) {
                        return;
                    } else if (!secResponse.success) {
                        throw secResponse.data;
                    }

                    const secResults = secResponse.data.sections.map((section: { sectionName: any }) => ({
                        title: section.sectionName,
                        value: JSON.stringify(section),
                    }));

                    setPotentialFailCodeSections([
                        {
                            title: t('modals:failCodePartSearch.failCodeSectionPlaceholder', 'Select section'),
                            value: '',
                        },
                        ...(secResults as any[]),
                    ]);

                    if (secResults.length === 1) {
                        setSelectedFailCodeSection(JSON.parse(secResults[0].value));

                        return techlineService
                            .fetchPossibleSubsections({
                                modelCode: props.vehicleDetails?.modelNumber,
                                modelYear: props.vehicleDetails?.modelYear,
                                optionCode: props.vehicleDetails?.optionCode,
                                failCode: code.failCode,
                                sectionNumber: JSON.parse(secResults[0].value).sectionNumber,
                                sectionName: JSON.parse(secResults[0].value).sectionName,
                                signal: abortSignalRef.current,
                                ignoreCache: true,
                            })
                            .then((subsecResponse) => {
                                if (!subsecResponse.success && subsecResponse.aborted) {
                                    return;
                                } else if (!subsecResponse.success) {
                                    throw subsecResponse.data;
                                }

                                const subsecResults = subsecResponse.data.subsections.map(
                                    (subsection: { subsectionName: any }) => ({
                                        title: subsection.subsectionName,
                                        value: JSON.stringify(subsection),
                                    })
                                );

                                if (subsecResults.length === 1) {
                                    setSelectedFailCodeSubsection(JSON.parse(subsecResults[0].value));
                                }

                                setPotentialFailCodeSubsections([
                                    {
                                        title: t(
                                            'modals:failCodePartSearch.failCodeSubSectionPlaceholder',
                                            'Select subsection'
                                        ),
                                        value: '',
                                    },
                                    ...(subsecResults as any[]),
                                ]);
                            });
                    }
                })
                .finally(() => {
                    setDidCheckSections(true);
                });
        },
        [
            abortSignalRef,
            props.vehicleDetails?.modelNumber,
            props.vehicleDetails?.modelYear,
            props.vehicleDetails?.optionCode,
            t,
        ]
    );

    useEffect(() => {
        if (!selectedPartFailCode || !selectedFailCodeSection) {
            return;
        }
        techlineService
            .fetchPossibleSubsections({
                modelCode: props.vehicleDetails?.modelNumber,
                modelYear: props.vehicleDetails?.modelYear,
                optionCode: props.vehicleDetails?.optionCode,
                failCode: selectedPartFailCode.failCode,
                sectionNumber: selectedFailCodeSection.sectionNumber,
                sectionName: selectedFailCodeSection.sectionName,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const results = response.data.subsections.map((subsection: { subsectionName: any }) => ({
                    title: subsection.subsectionName,
                    value: JSON.stringify(subsection),
                }));

                if (results.length === 1) {
                    setSelectedFailCodeSubsection(JSON.parse(results[0].value));
                }

                setPotentialFailCodeSubsections([
                    {
                        title: t('modals:failCodePartSearch.failCodeSubSectionPlaceholder', 'Select subsection'),
                        value: '',
                    },
                    ...(results as any[]),
                ]);
            });
    }, [
        abortSignalRef,
        props.vehicleDetails?.modelNumber,
        props.vehicleDetails?.modelYear,
        props.vehicleDetails?.optionCode,
        selectedFailCodeSection,
        selectedOption,
        selectedPartFailCode,
        t,
    ]);

    useEffect(() => {
        if (!selectedOption || !selectedOption.partNumber) {
            return;
        }

        techlineService
            .lookupFailCodes({
                modelCode: props.vehicleDetails?.modelNumber,
                modelYear: props.vehicleDetails?.modelYear,
                optionCode: props.vehicleDetails?.optionCode,
                partNumber: selectedOption.partNumber,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const codes = response.data.failCodes;

                if (codes.length === 1) {
                    setSelectedPartFailCode(codes[0]);
                    setConfirmedCodeSelection(true);
                    setIsOnlyMatch(true);
                }

                setPartFailCodes(codes);
            });
    }, [
        props.vehicleDetails?.modelNumber,
        props.vehicleDetails?.modelYear,
        props.vehicleDetails?.optionCode,
        selectedOption,
    ]);

    useEffect(() => {
        if (!selectedPartFailCode || !confirmedCodeSelection) {
            return;
        }

        fetchFailCodeSectionAndSubsectionOptions(selectedPartFailCode);
    }, [confirmedCodeSelection, fetchFailCodeSectionAndSubsectionOptions, selectedPartFailCode]);

    const showSectionSelects = didCheckSections && (!failCodeSectionName || !failCodeSubsectionName);

    const disableAdd =
        showSectionSelects &&
        (!selectedFailCodeSection ||
            !selectedFailCodeSection.sectionName ||
            !selectedFailCodeSubsection ||
            !selectedFailCodeSubsection.subsectionName);

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>{t('modals:failCodePartSearch.title', 'Fail Code Search')}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={classes.failCodePartSearch}>
                {!confirmedCodeSelection && (
                    <>
                        <Row>
                            <Col>
                                <AsyncTypeahead
                                    ref={searchInputRef}
                                    id="fail-code-search"
                                    onChange={([selected]) => {
                                        setSelectedOption(selected);
                                        setSelectedPartFailCode(undefined);
                                        setConfirmedCodeSelection(false);
                                        setIsOnlyMatch(false);
                                    }}
                                    onInputChange={() => searchInputRef.current?.hideMenu()}
                                    isLoading={isLoading}
                                    options={options}
                                    onSearch={queryFailCodes}
                                    labelKey={(o) => `${o.partNumber} - ${o.partDescription}`}
                                    minLength={5}
                                    filterBy={() => true}
                                    useCache={false}
                                    selected={selectedOption ? [selectedOption] : []}
                                    renderMenu={(results, menuProps, state) => {
                                        let index = 0;
                                        const sections = groupBy(results, (item) => {
                                            if (item.partNumber) {
                                                return t('modals:failCodePartSearch.resultSections.parts', 'Parts');
                                            } else {
                                                // Typeahead library - throws in pagination objection along in `results`
                                                return 'unknown';
                                            }
                                        });

                                        const items = Object.keys(sections)
                                            // filter those out
                                            .filter((n) => n !== 'unknown')
                                            .sort()
                                            .map((sectionName) => {
                                                return (
                                                    <Fragment key={sectionName}>
                                                        {index !== 0 && <Menu.Divider />}

                                                        <Menu.Header>
                                                            <Typography variant="label">{sectionName}</Typography>
                                                        </Menu.Header>

                                                        {sections[sectionName].map((i) => {
                                                            const item = (
                                                                <MenuItem key={index} option={i} position={index}>
                                                                    <Highlighter search={state.text}>
                                                                        {`${i.partNumber} - ${i.partDescription}`}
                                                                    </Highlighter>
                                                                </MenuItem>
                                                            );

                                                            index += 1;
                                                            return item;
                                                        })}
                                                    </Fragment>
                                                );
                                            });

                                        return <Menu {...menuProps}>{items}</Menu>;
                                    }}
                                    placeholder={t('modals:failCodePartSearch.searchPlacehodler', 'Search')}
                                />
                            </Col>
                        </Row>

                        <hr className="my-4" />
                    </>
                )}

                <Row>
                    {!confirmedCodeSelection ? (
                        <>
                            {!selectedOption ? (
                                <Col>
                                    <Typography variant="lead" color="textDarkSecondary">
                                        {t(
                                            'modals:failCodePartSearch.instructions.title',
                                            'Use the search bar above to find a fail code by entering any of the following:'
                                        )}
                                    </Typography>

                                    <div className="mt-2">
                                        <Typography variant="lead" color="textDarkSecondary">
                                            {t(
                                                'modals:failCodePartSearch.instructions.steps',
                                                '1. Part number\r\n2. Part description\r\n'
                                            )}
                                        </Typography>
                                    </div>
                                </Col>
                            ) : (
                                <Col>
                                    {partFailCodes && partFailCodes.length > 1 && (
                                        <>
                                            <div className="mb-4">
                                                <Typography variant="lead" color="textDarkSecondary">
                                                    {`${t(
                                                        'modals:failCodePartSearch.multiplePartMatchInstructions',
                                                        'Multiple matching fail codes have been found for Part #{{ partNumber }} - {{partDescription}}. Would you like to add the matching Fail Code to this QMR?',
                                                        {
                                                            ...selectedOption,
                                                        }
                                                    )}`}
                                                </Typography>
                                            </div>
                                            <div className="mb-4">
                                                <Label>Please choose the correct Fail Code below:</Label>
                                                {partFailCodes.map((partFailCode) => {
                                                    const isSelected =
                                                        selectedPartFailCode?.failCode === partFailCode.failCode;

                                                    return (
                                                        <ListButton
                                                            key={partFailCode.failCode}
                                                            radio
                                                            selected={isSelected}
                                                            title={`${partFailCode.failCode} - ${partFailCode.description}`}
                                                            rightIcon={null}
                                                            onPress={() => {
                                                                setSelectedPartFailCode(partFailCode);
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </>
                                    )}
                                    {partFailCodes && partFailCodes.length < 1 && (
                                        <div className="mb-4">
                                            <Typography variant="lead" color="textDarkSecondary">
                                                {`${t(
                                                    'modals:failCodePartSearch.noFailCodeMatch',
                                                    'No Fail Codes matched for Part  #{{ partNumber }} - {{partDescription}} for this VIN.',
                                                    {
                                                        ...selectedOption,
                                                    }
                                                )}`}
                                            </Typography>
                                        </div>
                                    )}
                                </Col>
                            )}
                        </>
                    ) : (
                        <>
                            <Col>
                                {isOnlyMatch && (
                                    <div className="mb-4">
                                        <Typography variant="lead" color="textDarkSecondary">
                                            {`${t(
                                                'modals:failCodePartSearch.singleFailCodeMatch',
                                                'A matching fail code has been found for Part #{{ partNumber }} - {{partDescription}}. Would you like to add the matching Fail Code to this QMR?',
                                                {
                                                    ...selectedOption,
                                                }
                                            )}`}
                                        </Typography>
                                    </div>
                                )}

                                {showSectionSelects && (
                                    <div className="mb-4">
                                        <Typography variant="lead" color="textDarkSecondary">
                                            {failCodeSectionName
                                                ? t(
                                                      'modals:failCodePartSearch.multipleSubsectionMatchInstructions',
                                                      'Please select the subsection below to specify:'
                                                  )
                                                : t(
                                                      'modals:failCodePartSearch.multipleMatchInstructions',
                                                      'Please select the section and subsection below to specify:'
                                                  )}
                                        </Typography>
                                    </div>
                                )}

                                <div className="d-flex flex-column">
                                    <FailCodeDetails
                                        possibleMatch={isOnlyMatch}
                                        matchingPart={selectedOption}
                                        {...{
                                            failCode: selectedPartFailCode!,
                                            failCodeSectionName,
                                            failCodeSubsectionName,
                                        }}
                                    />
                                </div>

                                {showSectionSelects && (
                                    <>
                                        {!failCodeSectionName && (
                                            <div className="my-4">
                                                <Label>Section</Label>
                                                <Select
                                                    value={JSON.stringify(selectedFailCodeSection)}
                                                    options={potentialFailCodeSections}
                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                        if (!event.target.value) {
                                                            setSelectedFailCodeSection(undefined);
                                                            return;
                                                        }

                                                        setSelectedFailCodeSection(JSON.parse(event.target.value));
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <Label>Subsection</Label>
                                        <Select
                                            disabled={!selectedFailCodeSection}
                                            value={JSON.stringify(selectedFailCodeSubsection)}
                                            options={potentialFailCodeSubsections}
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                if (!event.target.value) {
                                                    setSelectedFailCodeSubsection(undefined);
                                                    return;
                                                }

                                                setSelectedFailCodeSubsection(JSON.parse(event.target.value));
                                            }}
                                        />
                                    </>
                                )}
                            </Col>
                        </>
                    )}
                </Row>
            </Modal.Body>

            <Modal.Footer className="justify-content-between">
                <Button
                    onPress={() => {
                        if (confirmedCodeSelection) {
                            setConfirmedCodeSelection(false);
                            return;
                        }
                        props.returnToHome();
                    }}
                    variant="ghost-blue"
                    iconLeft={<Icon name="chevron-left" color="blueOne" />}
                >
                    {t('modals:failCodePartSearch.actions.back', 'Back')}
                </Button>

                <Button
                    disabled={confirmedCodeSelection ? disableAdd : !selectedPartFailCode}
                    onPress={() => {
                        if (!confirmedCodeSelection) {
                            setConfirmedCodeSelection(true);
                            setSelectedFailCodeSection(undefined);
                            setSelectedFailCodeSubsection(undefined);
                            return;
                        }

                        handleSubmit();
                    }}
                >
                    {confirmedCodeSelection
                        ? t('modals:failCodePartSearch.actions.add', 'Add Fail Code')
                        : t('modals:failCodePartSearch.actions.selectFailCode', 'Select Fail Code')}
                </Button>
            </Modal.Footer>
        </>
    );
};
