import React, { useCallback, useMemo, useState } from 'react';
import {
    WorksheetAnswerStatus,
    WorksheetFormElement,
    WorksheetFormGetResponse,
    WorksheetSubmissions,
} from '@packages/models/api/worksheets';
import { worksheetsService } from '@web/services/singletons';
import { cloneDeep } from 'lodash';
import { elementQuestionTypeMapping } from '../utils/util';

interface worksheetSubmissionAnswers extends WorksheetFormGetResponse {
    submissionId: string;
}

const useWorksheetAnswerValidation = () => {
    const [worksheetName, setWorksheetName] = useState<string>();
    const [worksheetSubmissionAnswers, setWorksheetSubmissionAnswers] = useState<worksheetSubmissionAnswers[]>([]);
    const [worksheetAnswersValidationMapping, setWorksheetAnswersValidationMapping] = useState<{
        [key: string]: boolean;
    }>({});

    const isAllWorksheetAnswersValid = useMemo(() => {
        return Object.entries(worksheetAnswersValidationMapping).every((w) => w[1]);
    }, [worksheetAnswersValidationMapping]);

    const validateAllWorksheetsAnswers = useCallback(
        async ({
            worksheetSubmission,
            caseNumber,
        }: {
            worksheetSubmission?: WorksheetSubmissions[];
            caseNumber: string;
        }) => {
            if (!worksheetSubmission) {
                return true;
            }
            setWorksheetSubmissionAnswers([]);
            try {
                const draftWorksheets = worksheetSubmission.filter((w) => w.status === WorksheetAnswerStatus.Draft);
                for (const worksheet of draftWorksheets) {
                    const response = await worksheetsService.retrieveWorksheetSubmission({
                        submissionId: worksheet.submissionId,
                        caseNumber,
                    });

                    if (!response.success) {
                        console.log(response.data);
                        return false;
                    } else {
                        const data = response.data;
                        setWorksheetSubmissionAnswers((prev) => {
                            const prevClone = cloneDeep(prev);
                            prevClone.push({ ...data, submissionId: worksheet.submissionId });
                            return prevClone;
                        });
                        const isValid = await isValidWorksheetAnswer({ worksheetData: data });
                        setWorksheetName(worksheet.worksheetTitle);
                        setWorksheetAnswersValidationMapping((prev) => ({ ...prev, [worksheet.worksheetId]: isValid }));
                    }
                }
                return true;
            } catch (error) {
                alert('Error: ' + error + '. Please refresh & try again.');
                console.error(error);
                return false;
            }
        },
        []
    );

    const checkElementAnswer = useCallback((element: WorksheetFormElement) => {
        const { readOnlyFieldElementTypes, inputFormElementTypes, ratingWithTextArea, fileFormElementTypes } =
            elementQuestionTypeMapping;
        if (element.worksheetElementRequired && !readOnlyFieldElementTypes.includes(element.worksheetElementTypeId)) {
            // Check if the file type is a question, meaning it has only an asset as the answer.
            if (fileFormElementTypes.includes(element.worksheetElementTypeId)) {
                if (!element.answerAssets?.length) {
                    return false;
                }
            } else if (element.selectedOptions?.length) {
                // InputFormElementTypes has answer in answerText.
                if (inputFormElementTypes.includes(element.worksheetElementTypeId)) {
                    if (element.selectedOptions[0].answerText === '') {
                        return false;
                    }
                }
                // Check for rating with textarea because it have may be two selected options.
                // One for selected rating option and second for textarea answer.
                // Here we need to check is selected option only is textarea answer or not because is optional answer.
                // It have must be one rating option.
                else if (element.worksheetElementTypeId === ratingWithTextArea) {
                    const textAreaOptionId = element.options[element.options.length - 1];
                    if (element.selectedOptions.length === 1) {
                        return element.selectedOptions[0].optionId !== textAreaOptionId?.optionId;
                    } else {
                        const selectedRatingOption = element.selectedOptions.find(
                            (opt) => opt.optionId !== textAreaOptionId?.optionId
                        );
                        return selectedRatingOption?.optionLabel === '' ? false : true;
                    }
                } else if (element.selectedOptions[0].optionLabel === '') {
                    return false;
                }
            } else {
                return false;
            }
            return true;
        }
        return true;
    }, []);

    const isValidWorksheetAnswer = useCallback(
        ({ worksheetData }: { worksheetData: WorksheetFormGetResponse }): Promise<boolean> => {
            const worksheetElementsClone = cloneDeep(worksheetData.elements);

            // ReadOnlyFieldElementTypes has not a answer fields so don't check validation this types elements
            const { readOnlyFieldElementTypes, conditionalOptionFormElementTypes } = elementQuestionTypeMapping;

            return new Promise((res) => {
                if (worksheetElementsClone) {
                    const filterElementIds = worksheetElementsClone.reduce((_array: string[], element) => {
                        // Array of conditional element ids
                        if (conditionalOptionFormElementTypes.includes(element.worksheetElementTypeId)) {
                            element.options.forEach((opt) => {
                                const trgIdList = opt.trgtWorksheetElementIdList;
                                if (trgIdList?.length) {
                                    _array = [..._array, ...trgIdList];
                                }
                            });
                        }
                        return _array;
                    }, []);

                    // Check unconditional elements
                    const checkUnconditionalElement = worksheetElementsClone.every((element) => {
                        if (!filterElementIds.includes(element.worksheetElementId)) {
                            return checkElementAnswer(element);
                        }
                        return true;
                    });

                    // If unconditional elements is already invalid then return,
                    // Else need to check radio and checkbox elements if it's selected option
                    // Has conditional elements so also validate those elements.
                    if (!checkUnconditionalElement) {
                        res(checkUnconditionalElement);
                    } else {
                        const conditionalElements = worksheetElementsClone.every((element) => {
                            if (
                                !readOnlyFieldElementTypes.includes(element.worksheetElementTypeId) &&
                                conditionalOptionFormElementTypes.includes(element.worksheetElementTypeId)
                            ) {
                                if (element.selectedOptions?.length) {
                                    const selectedOptions = element.selectedOptions.map((option) => option.optionId);
                                    const trgIdList = element.options.reduce((ids: string[], option) => {
                                        //All selected options's conditional elements ids.
                                        if (selectedOptions.includes(option.optionId)) {
                                            ids = [...ids, ...option.trgtWorksheetElementIdList];
                                        }
                                        return ids;
                                    }, []);

                                    //Check selected options's conditional elements
                                    return worksheetElementsClone.every((element) => {
                                        if (trgIdList.includes(element.worksheetElementId)) {
                                            return checkElementAnswer(element);
                                        }
                                        return true;
                                    });
                                }
                                return true;
                            }
                            return true;
                        });
                        res(conditionalElements);
                    }
                }
            });
        },
        []
    );

    return {
        worksheetName,
        validateAllWorksheetsAnswers,
        worksheetSubmissionAnswers,
        checkElementAnswer,
        isValidWorksheetAnswer,
        isAllWorksheetAnswersValid,
        worksheetAnswersValidationMapping,
    };
};

export default useWorksheetAnswerValidation;
