import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';
import { chevron } from '@web/jss/mixins';

const useSelectStyles = createUseStyles({
    select: {
        position: 'relative',
        '& select': {
            ...fonts.s,
            height: 40,
            width: '100%',
            borderRadius: 3,
            cursor: 'pointer',
            appearance: 'none',
            color: colors.textDarkPrimary,
            padding: '0 40px 0 12px',
            backgroundColor: colors.white,
            border: `1px solid ${colors.grayFive}`,
            '&:hover': {
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
            },
            '&:focus, &:active': {
                outline: 'none',
                border: `1px solid ${colors.blueOne}`,
            },
            '&:disabled': {
                cursor: 'default',
                border: 0,
                backgroundColor: colors.grayThree,
                '&:hover': {
                    boxShadow: 'none',
                },
                '&:active': {
                    border: `1px solid ${colors.grayFive}`,
                },
            },
            '&:invalid': {
                color: colors.grayFive,
            },
        },
        ...chevron({
            leftOffset: 'auto',
            rightOffset: 12,
            color: colors.grayFive,
        }),
    },
});

interface SelectProps {
    id?: string;
    disabled?: boolean;
    value: string;
    options: SelectOption[];
    onChange(event: React.FormEvent<HTMLSelectElement>): void;
    className?: string;
    required?: boolean;
}

export interface SelectOption {
    value: string;
    title: string;
    disabled?: boolean;
}

const Select: FC<SelectProps> = (props) => {
    const classes = useSelectStyles();

    function handleOnChange(event: React.FormEvent<HTMLSelectElement>) {
        props.onChange(event);
    }

    return (
        <div className={classNames(classes.select, props.className)}>
            <Form.Control
                id={props.id}
                as="select"
                value={props.value}
                // @ts-ignore
                onChange={handleOnChange}
                bsPrefix="subcomm-select"
                disabled={props.disabled}
                required={props.required}
            >
                {props.options.map((option: SelectOption, index: number) => {
                    return (
                        <option key={index} value={option.value} disabled={option.disabled}>
                            {option.title}
                        </option>
                    );
                })}
            </Form.Control>
        </div>
    );
};

export default Select;
