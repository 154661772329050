import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';

import { Button, Icon, Typography } from '@packages/ui/shared';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import { GroupConditionModel, QueryBuilder } from './query-builder';

import { AdvancedSearchCombinator, AdvancedSearchField } from '@packages/models/api';
import { qmrsService } from '@web/services/singletons';

import colors from '@packages/core/styles/colors';
import AdvancedSearchModal from '@web/qmr/components/advanced-search-modal';
import { constructUrl } from '@web/utils/url-utils';
import { useAdvancedSearchContext } from '@packages/contexts/qmrs';

const useStyles = createUseStyles({
    advancedSearchPanel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 684,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    advancedSearchPanelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    advancedSearchPanelCenterContainer: {
        padding: 24,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

interface AdvancedSearchPanelProps {
    query: GroupConditionModel;
    onApply(query: GroupConditionModel): void;
}

const AdvancedSearchPanel = ({ query, onApply }: AdvancedSearchPanelProps) => {
    const classes = useStyles();
    const { dismissAllOverlays } = useContentOverlayState();
    const navigate = useNavigate();
    const { setRecentSavedSearches } = useAdvancedSearchContext();

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [combinators, setCombinators] = useState<AdvancedSearchCombinator[]>();
    const [fields, setFields] = useState<AdvancedSearchField[]>();
    const [internalQuery, setInternalQuery] = useState<GroupConditionModel>(query);

    const fetchData = async () => {
        const response = await qmrsService.getAdvancedSearchParameters();

        if (response.success) {
            setCombinators(response.data.combinators);
            setFields(response.data.fields);
        } else {
            throw response.data;
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function handleSave(name: string, publicSearch: boolean, rolesToShareSelected: string[]) {
        try {
            const rolesShareSelected =
                rolesToShareSelected && rolesToShareSelected.length > 0 ? [...rolesToShareSelected] : [];
            const response = await qmrsService.postAdvancedSearchQuery(
                internalQuery,
                name,
                publicSearch,
                rolesShareSelected
            );
            const recentSearchsResponse = await qmrsService.getAdvancedSearchRecentSavedSearches();

            if (recentSearchsResponse.success) {
                setRecentSavedSearches(recentSearchsResponse.data.savedSearches);
            }

            if (response.success) {
                navigate(constructUrl(`/qmrs/advanced-search/${response.data.searchId}`, {}));
                setShowSaveModal(false);
                dismissAllOverlays();
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }

    function handleApplyChangesButtonPress() {
        onApply(internalQuery);
        fetchData();
    }

    const rightPanelDiv = document.getElementById('right-side-panel');
    if (!rightPanelDiv) {
        return null;
    }

    return ReactDOM.createPortal(
        <>
            <AdvancedSearchModal
                show={showSaveModal}
                onSave={handleSave}
                onHide={() => {
                    setShowSaveModal(false);
                }}
            />

            <div className={classes.advancedSearchPanel}>
                <div className={classes.advancedSearchPanelHeader}>
                    <Typography>Untitled Advanced Search</Typography>
                    <div className={classes.closeButton} onClick={dismissAllOverlays}>
                        <Icon name="x-close" style={{ display: 'flex' }} />
                    </div>
                </div>
                <div className={classes.advancedSearchPanelCenterContainer}>
                    {combinators && fields && internalQuery && (
                        <QueryBuilder
                            combinators={combinators}
                            fields={fields}
                            query={internalQuery}
                            onChange={setInternalQuery}
                        />
                    )}
                    <hr className="mb-6" />
                    {internalQuery && (
                        <div className="d-flex">
                            <Button
                                disabled={internalQuery.rules.length === 0}
                                style={{ marginRight: 12 }}
                                onPress={handleApplyChangesButtonPress}
                            >
                                Apply Changes
                            </Button>
                            <Button
                                disabled={internalQuery.rules.length === 0}
                                onPress={() => {
                                    setShowSaveModal(true);
                                }}
                            >
                                Save this search
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>,
        rightPanelDiv
    );
};

export default AdvancedSearchPanel;
