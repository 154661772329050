import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Icon, isValidIconType, StsIconName, TextBadge, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { ReactComponent as OpenInNew } from '@packages/ui/assets/images/OpenInNew.svg';
import { analyticsService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

type UpdateCardBody = {
    heading: string;
    description: string;
    link: string;
};

type QmrsUpdateCardsProps = {
    headerWithIcon: boolean;
    headingType?: string;
    heading: string;
    body: UpdateCardBody[];
};

const useQmrDashboardStyle = createUseStyles({
    colPaddingZero: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    card: {
        padding: 20,
        display: 'flex',
        borderRadius: 8,
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '13.18px',
        flexGrow: '1',
        border: `0.82px solid ${colors.grayThree}`,
        boxSizing: 'content-box',
        margin: 10,
        position: 'relative',
    },
    gap10: {
        display: 'flex',
        gap: '15px',
    },
    buttonFlexContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '40px',
        alignItems: 'center',
    },
    buttonWrapper: {
        display: 'flex',
        gap: '2px',
        position: 'absolute',
        bottom: '0',
        right: '5px',
    },
    cardbodyFlexContainer: {
        flexGrow: '1',
        cursor: 'pointer',
    },
    cardBodyDescription: {
        minHeight: '80px',
    },
    buttonGrayCircle: {
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        background: colors.grayFour,
    },
    buttonBlueCircle: {
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        background: colors.blueOne,
    },
    cardHeaderStyle: {
        color: colors.blueOne,
        textTransform: 'none',
    },
    linkIcon: {
        height: '20px',
        fill: colors.blueOne,
        display: 'block',
        padding: '10px',
        '&:hover': {
            backgroundColor: colors.blueFour,
            cursor: 'pointer',
        },
    },
});
const QmrsUpdateCards: FC<{ updateCardInfo: QmrsUpdateCardsProps }> = ({ updateCardInfo }) => {
    const classes = useQmrDashboardStyle();
    const [activeBodyIndex, setActiveBodyIndex] = useState(0); // by default first will be displayed
    const [isMouseHover, setMouseHover] = useState(false);
    //update cards after certain time interval
    useEffect(() => {
        const randomInterval = 12 * 1000;
        const interval = setInterval(() => {
            if (isMouseHover === false) {
                const nextIndexTemp = activeBodyIndex + 1;
                const nextIndex = updateCardInfo.body.length === nextIndexTemp ? 0 : nextIndexTemp;
                setActiveBodyIndex(nextIndex);
            }
        }, randomInterval);
        return () => clearInterval(interval);
    }, [updateCardInfo.body, activeBodyIndex, isMouseHover]);
    return (
        <div className={`col-xs-12 col-sm-6 ${classes.colPaddingZero}`}>
            <div
                className={`${classes.card}`}
                onMouseEnter={() => {
                    setMouseHover(true);
                }}
                onMouseLeave={() => setMouseHover(false)}
            >
                <div className="w-50">
                    <div className="d-flex">
                        <TextBadge
                            variant="blue"
                            icon={
                                updateCardInfo.headerWithIcon === true &&
                                isValidIconType(updateCardInfo.headingType as StsIconName) ? (
                                    <Icon
                                        name={'checkmark' as StsIconName}
                                        size={10}
                                        color="blueOne"
                                        style={{ display: 'flex' }}
                                    />
                                ) : null
                            }
                        >
                            <span className={classes.cardHeaderStyle}>{updateCardInfo.heading}</span>
                        </TextBadge>
                    </div>
                </div>
                <div className={classes.gap10}>
                    <div
                        className={classes.cardbodyFlexContainer}
                        onClick={() => {
                            window.open(updateCardInfo.body[activeBodyIndex].link, '_blank');
                            if (updateCardInfo.heading == 'Have you seen?')
                                analyticsService.logEvent(ANALYTICS_EVENTS.QMRDASH_USER_CLICKED_HAVE_YOU_SEEN, {
                                    heading: updateCardInfo.heading,
                                    content: updateCardInfo.body,
                                });
                            else if (updateCardInfo.heading == 'Improvement of the month')
                                analyticsService.logEvent(
                                    ANALYTICS_EVENTS.QMRDASH_USER_CLICKED_COUNTER_MEASURES_OF_THE_MONTH,
                                    {
                                        heading: updateCardInfo.heading,
                                        content: updateCardInfo.body,
                                    }
                                );
                        }}
                    >
                        <div className={'w-100'}>
                            <Typography variant="h6" color="black">
                                {updateCardInfo.body[activeBodyIndex].heading}
                            </Typography>
                        </div>
                        <div className={`${classes.cardBodyDescription} w-100`}>
                            <Typography variant="labelRegular" color="graySix">
                                {updateCardInfo.body[activeBodyIndex].description}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.buttonFlexContainer}>
                        {updateCardInfo.body[activeBodyIndex].link && (
                            <div className="d-flex">
                                {/* <Button
                                    variant="tertiary"
                                    onPress={() => {
                                        window.open(updateCardInfo.body[activeBodyIndex].link, '_blank');
                                    }}
                                > */}
                                <OpenInNew
                                    className={classes.linkIcon}
                                    onClick={() => {
                                        window.open(updateCardInfo.body[activeBodyIndex].link, '_blank');
                                    }}
                                />
                                {/* </Button> */}
                            </div>
                        )}
                        <div className={classes.buttonWrapper}>
                            {updateCardInfo.body.map((body, index) => (
                                <Button
                                    variant="link"
                                    onPress={() => {
                                        setActiveBodyIndex(index);
                                    }}
                                    key={index}
                                >
                                    <div
                                        className={
                                            index === activeBodyIndex
                                                ? classes.buttonBlueCircle
                                                : classes.buttonGrayCircle
                                        }
                                    ></div>{' '}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QmrsUpdateCards;
