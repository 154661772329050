import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { Time } from '.';

interface Props {
    value: Time;
    show: boolean;
    scroll: number;
    onChange: (key: keyof Time, value: string) => void;
}

const Headers: Record<keyof Time, string> = {
    hh: 'HH',
    mm: 'MM',
    ss: 'SS',
    a: 'A/P',
    zone: 'Zone',
};
const TimeComponent: FC<Props> = ({ value, onChange, show, scroll }) => {
    useEffect(() => {
        handleScroll();
    }, [scroll]);

    // Initial render scroll till selected hh:mm:ss
    const handleScroll = useCallback(() => {
        const elements: { [key: string]: HTMLElement | null } = {};
        Object.keys(value).forEach((key) => {
            elements[key] = document.getElementById(`${key}${value[key as keyof Time]}`);
        });

        Object.entries(elements).forEach(([key, childElement]) => {
            const parent = document.getElementById(key);
            if (childElement && parent) {
                parent.scrollTo({
                    top: childElement.offsetTop - 40,
                });
            }
        });
    }, [value]);

    // Generate two-digit padded strings for hours, minutes, and seconds
    const { hoursArray, minuteOrSecondArray, meridiemArray } = useMemo(
        () => ({
            hoursArray: Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0')),
            minuteOrSecondArray: Array.from({ length: 60 }, (_, index) => index.toString().padStart(2, '0')),
            meridiemArray: ['AM', 'PM'],
        }),
        []
    );

    const timeComponents: { dataArray: string[]; key: keyof Time }[] = [
        { dataArray: hoursArray, key: 'hh' },
        { dataArray: minuteOrSecondArray, key: 'mm' },
        { dataArray: minuteOrSecondArray, key: 'ss' },
        { dataArray: meridiemArray, key: 'a' },
    ];

    const classes = useStyle();
    if (!show) {
        return <></>;
    }
    return (
        <>
            <div className={`d-flex justify-content-between`}>
                {timeComponents.map(({ dataArray, key }) => (
                    <div className={classes.wrapper} key={key} id={key}>
                        <p className={`${classes.header}`}>{Headers[key]}</p>
                        {dataArray.map((item) => {
                            return (
                                <p
                                    id={`${key}${item}`}
                                    key={item}
                                    onClick={() => onChange(key, item)}
                                    className={`${classes.digitBox} ${item === value[key] && classes.selected}`}
                                >
                                    {item}
                                </p>
                            );
                        })}
                    </div>
                ))}
            </div>
        </>
    );
};
const useStyle = createUseStyles({
    selected: {
        backgroundColor: colors.blueOne,
        color: colors.white,
        fontWeight: '500',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '60px',
        height: '100%',
        textAlign: 'center',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    digitBox: {
        padding: '6px 6px',
        borderRadius: '5px',
        margin: '3px',
        fontSize: '14px',
        cursor: 'default',
        '&:hover': {
            backgroundColor: colors.blueFour,
            color: colors.black,
        },
    },

    header: {
        borderBottom: '1px solid !important',
        color: colors.blueOne,
        fontSize: '14px',
        position: 'sticky',
        top: 0,
        backgroundColor: colors.white,
    },
});

export default TimeComponent;
