import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button, Icon, StsIconName, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    feedbackRequestMessage: {
        padding: 16,
        display: 'flex',
        borderRadius: 4,
        backgroundColor: colors.blueFour,
        border: `2px solid ${colors.blueOne}`,
    },
    details: {
        flex: 1,
        paddingLeft: 16,
    },
});

interface FeedbackRequestMessageProps {
    iconName?: StsIconName;
    title?: string;
    message?: string;
    callToActionTitle?: string;
    callToActionOnPress?(): void;
    className?: string;
    iconClassName?: string;
}

export const FeedbackRequestMessage: FC<FeedbackRequestMessageProps> = ({
    iconName,
    title,
    message,
    callToActionTitle,
    callToActionOnPress,
    className,
    iconClassName,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classNames(classes.feedbackRequestMessage, className)}>
            <div className={iconClassName}>
                <Icon name={iconName || 'chat-bubble'} color="blueOne" size={24} />
            </div>

            <div className={classes.details}>
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <Typography variant="h6">
                        {title || t('feedback:requestMessage', 'Feedback Request Message')}
                    </Typography>
                    {callToActionTitle && callToActionOnPress && (
                        <Button variant="link" onPress={callToActionOnPress}>
                            {callToActionTitle}
                        </Button>
                    )}
                </div>
                {message && <Typography variant="labelRegular">{message}</Typography>}
            </div>
        </div>
    );
};
