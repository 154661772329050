import jwtDecode from 'jwt-decode';

import { StorageService, StorageKeys } from '@packages/core/storage';
import { AuthTokenPayload } from '@packages/core/http';

export interface StoredToken {
    token: string | null;
    decodedToken: AuthTokenPayload | null;
}

export class AuthTokenService {
    constructor(private storageService: StorageService) {}

    private decodeAndValidate(token: string): AuthTokenPayload | null {
        let payload: AuthTokenPayload;

        try {
            payload = jwtDecode(token);
        } catch (e) {
            console.error('Invalid token', e);
            return null;
        }

        if (payload.exp < Date.now() / 1000) {
            return null;
        }

        return payload;
    }

    async getStoredToken() {
        let storedToken: StoredToken = { token: null, decodedToken: null };

        try {
            storedToken.token = await this.storageService.getItem(StorageKeys.AccessToken);
        } catch (e) {
            console.error('Unable to get stored token', e);
            return storedToken;
        }

        if (!storedToken.token) {
            return storedToken;
        }

        storedToken.decodedToken = this.decodeAndValidate(storedToken.token);

        return storedToken;
    }

    async storeToken(token: string): Promise<StoredToken | null> {
        const decodedToken = this.decodeAndValidate(token);

        if (!decodedToken) {
            return null;
        }

        try {
            await this.storageService.setItem(StorageKeys.AccessToken, token);
        } catch (e) {
            console.error('Unable to save token', e);
            return null;
        }

        return { token, decodedToken };
    }
}
