import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { Typography, Button, Icon } from '@packages/ui/shared';

const useConfirmDeleteAssetModalStyles = createUseStyles({
    changeConactModal: {
        width: 558,
        maxWidth: 'none',
    },
});

interface ChangeContactModalProps {
    show: boolean;
    deleteLoader: boolean;
    onConfirm(): void;
    onHide(): void;
}

const ConfirmDeleteAssetModal: FC<ChangeContactModalProps> = ({ onConfirm, onHide, deleteLoader, ...props }) => {
    const classes = useConfirmDeleteAssetModalStyles();
    const { t } = useTranslation();

    return (
        <Modal show={props.show} dialogClassName={classes.changeConactModal} onHide={onHide} centered>
            <Modal.Header closeButton placeholder={''}>
                <Typography variant="h4">{t('modals:deleteAsset.title', 'Delete Attachment?')}</Typography>
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex">
                    <Icon name="warning" />
                    <div className="d-flex flex-column ml-3">
                        <Typography variant="h5" style={{ marginBottom: 10 }}>
                            {t('modals:deleteAsset.message', 'Are you sure you want to delete this attachment?')}
                        </Typography>
                        <Typography variant="lead">
                            {t('modals:deleteAsset.hint', 'Deleting attachments cannot be undone.')}
                        </Typography>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button title={t('modals:deleteAsset.cancel', 'Cancel')} variant="link" onPress={onHide} />
                <Button
                    title={t('modals:deleteAsset.confirm', 'Delete')}
                    variant="danger"
                    onPress={onConfirm}
                    style={{ marginLeft: 20 }}
                    isLoading={deleteLoader}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeleteAssetModal;
