import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Typography } from '../../shared';
import { Button } from '../../shared/button';
import { modalDefaultStyles, Popup, PopupProps } from '../../shared/popup';

interface CorrectionInfoPopupProps extends PopupProps {
    onOkPress: () => void;
}

export const CorrectionInfoPopup: FC<CorrectionInfoPopupProps> = ({ onOkPress, ...props }) => {
    const { t } = useTranslation();

    return (
        <Popup {...props}>
            <View style={modalDefaultStyles.header}>
                <Typography variant="h4">
                    {t('qmr:popups.correctionInfo.title', 'Provide an effective correction')}
                </Typography>
            </View>

            <View style={modalDefaultStyles.content}>
                <Typography variant="lead">
                    {t(
                        'qmr:popups.correctionInfo.message',
                        'Please add relevant information in addition to the original concern on the Repair Order. Answer the following questions:\r\n\r\nWhen you first assessed the car, what issues did you find?\r\n\r\nDid you do a walkthrough and/or test drive with the customer? If so, what did you find?\r\n\r\nUnder what environmental conditions is the car experiencing an issue?'
                    )}
                </Typography>
            </View>

            <View style={modalDefaultStyles.footer}>
                <Button title={t('qmr:popups.correctionInfo.ok', 'Got it')} onPress={onOkPress} />
            </View>
        </Popup>
    );
};
