import { HttpClient } from '@packages/core/http';
import {
    AddVersionWorksheetFormRequestBody,
    CaseTypesResponse,
    CreateAssets,
    CreateAssetsResonseHttpHeaders as CreateAssetsResponseHttpHeaders,
    CreateAssetsResponse,
    CreateWorksheetFormRequestBody,
    DeleteDraftWorksheetSubmissionResponse,
    SaveCaseTypeRequestBody,
    UpdateWorksheetFormRequestBody,
    UpdateWorksheetFormStatusBody,
    UpdateWorksheetFormStatusResponse,
    WorksheetAnswerBody,
    WorksheetCaseTypeStatus,
    WorksheetFormDto,
    WorksheetFormGetResponse,
    WorksheetListByCaseType,
    WorksheetListByCaseTypeBody,
    WorksheetSubmissionResponse,
    WorksheetsFetchResponse,
    CreateAssetTypes,
    OverlayPresignedUpload,
    DeleteAssetBody,
} from '@packages/models/api';
import {
    AnswerPresignedUpload,
    Asset,
    CreateAnswerAssetsBody,
    CreateAnswerAssetsResponse,
} from '@packages/models/api/worksheets';

export class WorksheetsService {
    constructor(private http: HttpClient) {}

    fetchWorksheetFormById(worksheetFormId: string) {
        return this.http.orchestrateRequest<WorksheetFormGetResponse>({
            method: 'GET',
            url: `/admin/worksheet/${worksheetFormId}`,
        });
    }

    fetchCaseType({ status, isPilotUser }: { status: WorksheetCaseTypeStatus; isPilotUser?: boolean }) {
        const query = new URLSearchParams();
        query.append('status', status);
        if (isPilotUser !== undefined) {
            query.append('pilotUser', isPilotUser.toString());
        }
        return this.http.orchestrateRequest<CaseTypesResponse>({
            method: 'GET',
            url: `/admin/casetypes?${query.toString()}`,
        });
    }

    saveCaseType(body: SaveCaseTypeRequestBody) {
        return this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: '/northstar/caseTypeIntersect/v1',
            body,
        });
    }

    fetchWorksheets({
        queryString,
        ignoreCache = false,
        signal,
    }: {
        queryString?: string;
        signal?: AbortSignal;
        ignoreCache: boolean;
    }) {
        return this.http.orchestrateRequest<WorksheetsFetchResponse>({
            method: 'GET',
            url: `/admin/worksheets?${queryString}`,
            ignoreCache,
            signal,
        });
    }

    createAssets(data: CreateAssetTypes[]) {
        const body: CreateAssets = {
            assets: data,
        };
        return this.http.orchestrateRequest<CreateAssetsResponse>({
            method: 'POST',
            url: '/assets',
            body,
        });
    }

    uploadAsset(
        url: string,
        headers: CreateAssetsResponseHttpHeaders | OverlayPresignedUpload['httpHeaders'],
        body: File
    ) {
        const requestOptions: RequestInit = {
            method: 'PUT',
            body,
            headers: { ...headers },
        };
        return fetch(url, requestOptions);
    }

    deleteAsset(body: DeleteAssetBody, worksheetId: string) {
        return this.http.orchestrateRequest({
            method: 'DELETE',
            url: `/admin/${worksheetId}/asset`,
            body,
        });
    }

    fetchAsset(assetId: string) {
        return this.http.orchestrateRequest<{ asset: Asset }>({
            method: 'GET',
            url: `/assets/${assetId}`,
            ignoreCache: true,
        });
    }

    createWorksheetForm(body: CreateWorksheetFormRequestBody, signal?: AbortSignal) {
        return this.http.orchestrateRequest<{ worksheet: WorksheetFormDto }>({
            method: 'POST',
            url: '/admin/createWorksheet',
            body,
            signal,
        });
    }

    updateWorksheetForm(body: UpdateWorksheetFormRequestBody) {
        return this.http.orchestrateRequest<WorksheetFormGetResponse>({
            method: 'PUT',
            url: '/admin/updateWorksheet',
            body,
        });
    }

    addVersionWorksheetForm(body: AddVersionWorksheetFormRequestBody) {
        return this.http.orchestrateRequest<WorksheetFormDto>({
            method: 'POST',
            url: '/admin/AddWorksheetVersion',
            body,
        });
    }

    deleteWorksheetForm(worksheetId: string) {
        return this.http.orchestrateRequest<any>({
            method: 'PUT',
            url: `/admin/worksheet/delete/${worksheetId}`,
        });
    }

    updateWorksheetFormStatus(body: UpdateWorksheetFormStatusBody) {
        return this.http.orchestrateRequest<UpdateWorksheetFormStatusResponse>({
            method: 'PUT',
            url: '/admin/worksheet/statusUpdate',
            body,
        });
    }

    updateWorksheetFormStatusArchived(id: string) {
        return this.http.orchestrateRequest<UpdateWorksheetFormStatusResponse>({
            method: 'PUT',
            url: `/admin/worksheet/delete/${id}`,
        });
    }

    worksheetListByCaseType(body: WorksheetListByCaseTypeBody) {
        return this.http.orchestrateRequest<WorksheetListByCaseType>({
            method: 'POST',
            url: '/techline/getWorksheetTitles',
            body,
        });
    }

    retrieveWorksheetSubmission({ caseNumber, submissionId }: { caseNumber: string; submissionId: string }) {
        const prepareParams = {
            casenumber: caseNumber,
            submissionId,
        };
        const params = new URLSearchParams(prepareParams);

        return this.http.orchestrateRequest<WorksheetFormGetResponse>({
            method: 'GET',
            url: `/techline/retrieveWorksheetSubmission?${params}`,
        });
    }

    worksheetAnswerSubmission(body: WorksheetAnswerBody) {
        return this.http.orchestrateRequest<WorksheetSubmissionResponse>({
            method: 'PATCH',
            url: '/northstar/patchWorksheetSubmission',
            body,
        });
    }

    deleteDraftWorksheetSubmission(submissionId: string) {
        return this.http.orchestrateRequest<DeleteDraftWorksheetSubmissionResponse>({
            method: 'DELETE',
            url: `/northstar/deleteDraftWorksheetSubmission/${submissionId}`,
        });
    }

    createAnswerAssets(body: CreateAnswerAssetsBody) {
        return this.http.orchestrateRequest<CreateAnswerAssetsResponse>({
            method: 'POST',
            url: '/northstar/postAssets/v1',
            body,
        });
    }

    uploadAnswerAsset(url: string, headers: AnswerPresignedUpload['httpHeaders'], body: File) {
        const requestOptions: RequestInit = {
            method: 'PUT',
            body,
            headers: { ...headers },
        };
        return fetch(url, requestOptions);
    }

    updateAsset(
        body: { worksheetElementOptionId?: string; worksheetElementId?: string; caption: string },
        assetId: string
    ) {
        return this.http.orchestrateRequest({
            method: 'PATCH',
            url: `/assets/${assetId}`,
            body,
        });
    }
}
