import React, { FC, useRef, useEffect } from 'react';
import { StyleSheet, Animated, Easing, StyleProp, ViewStyle, Platform } from 'react-native';

import colors from '@packages/core/styles/colors';

interface LoadingCircleProps {
    size?: number;
    borderWidth?: number;
    borderColor?: string;
    accentColor?: string;
    style?: StyleProp<ViewStyle>;
}

export const LoadingCircle: FC<LoadingCircleProps> = ({ size, borderWidth, borderColor, accentColor, style }) => {
    const rotateAnimationValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        const rotateAnimation = Animated.timing(rotateAnimationValue, {
            toValue: 1,
            duration: 1000,
            easing: Easing.linear,
            useNativeDriver: Platform.select({ web: false, default: true }),
        });
        const rotateLoop = Animated.loop(rotateAnimation);

        rotateLoop.start();

        return () => {
            rotateLoop.stop();
        };
    }, [rotateAnimationValue]);

    return (
        <Animated.View
            style={[
                styles.loadingCircle,
                style,
                {
                    ...(size ? { width: size, height: size, borderRadius: size / 2 } : {}),
                    ...(borderWidth ? { borderWidth } : {}),
                    ...(borderColor ? { borderColor } : {}),
                    ...(accentColor ? { borderTopColor: accentColor } : {}),
                    transform: [
                        {
                            rotate: rotateAnimationValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: ['0deg', '360deg'],
                            }),
                        },
                    ],
                },
            ]}
        />
    );
};

const styles = StyleSheet.create({
    loadingCircle: {
        width: 24,
        height: 24,
        borderWidth: 4,
        borderRadius: 12,
        borderColor: colors.white,
        borderTopColor: colors.blueOne,
    },
});
