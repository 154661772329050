import { Dispatch } from 'react';
import { action, props, reducer, on, union } from 'ts-action';

import { PresignedUpload, Asset, AssetType, AssetDisposition, Qmr } from '@packages/models/api';

export interface AssetUploadsState {
    [uploadId: string]: AssetUploadTask;
}

export interface AssetUploadInfo {
    assetId?: Asset['assetId'];
    assetTypeId: AssetType;
    assetDisposition: AssetDisposition;
    assetUri: string | File;
    overlayUri?: string;
    name?: string;
    note?: string;
    restricted?: boolean;
    customUploadId?: string;
    replacesAssetId?: Asset['assetId'];
    eventHandlers?: AssetUploadEventHandlers;
    sizeInBytes?: number;
    contentType?: string;
}

type UploadCompletedEventHandler = (uploadId: string, asset: Asset) => void;
type UploadErrorEventHandler = (uploadId: string, asset: Asset, error: any) => void;
type UploadProgressEventHandler = (uplaodId: string, asset: Asset, progress: number) => void; // Web Only

export interface AssetUploadEventHandlers {
    onCompleted?: UploadCompletedEventHandler;
    onError?: UploadErrorEventHandler;
    onProgress?: UploadProgressEventHandler; // Web Only
}

export interface AssetUploadTask {
    qmrId?: Qmr['qmrId'];
    uploadId: string;
    asset: Asset;
    uploadInfo: AssetUploadInfo;
    presignedUpload: PresignedUpload;
    status: AssetUploadTaskStatus;
    error: any;
}

export enum AssetUploadTaskStatus {
    Uploading = 'uploading',
    Completed = 'completed',
    Error = 'error',
    Unknown = 'unkown',
}
export const assetUploadsActions = {
    resetState: action('RESET_UPLOADS_STATE'),
    addUploadTasks: action('ADD_UPLOAD_TASKS', props<{ uploadTasks: AssetUploadTask[] }>()),
    updateTask: action('UPDATE_TASK', props<{ uploadId: string } & Partial<AssetUploadTask>>()),
    cleanup: action('CLEANUP_UPLOADS', props<{ qmrId: Qmr['qmrId'] }>()),

    // `setUnknowns` used by Mobile app when it loses access
    // to upload events (i.e. when app is paused)
    setUnknowns: action('SET_UNKNOWNS'),
};

const actionsUnion = union(...Object.values(assetUploadsActions));

type AssetUploadsActions = typeof actionsUnion.actions;

export type AssetUploadsActionDispatcher = Dispatch<AssetUploadsActions>;

export const initialState: AssetUploadsState = {};

export const assetUploadsReducer = reducer(
    initialState,
    on(assetUploadsActions.resetState, () => {
        return { ...initialState };
    }),
    on(assetUploadsActions.addUploadTasks, (state, { uploadTasks }) => ({
        ...state,
        ...uploadTasks.reduce<AssetUploadsState>((newUploads, uploadTask) => {
            newUploads[uploadTask.uploadId] = uploadTask;
            return newUploads;
        }, {}),
    })),
    on(assetUploadsActions.updateTask, (state, { uploadId, error, ...task }) => {
        return {
            ...state,
            [uploadId]: {
                ...state[uploadId],
                ...task,
                error: error || null,
            },
        };
    }),
    on(assetUploadsActions.cleanup, (state, { qmrId }) => {
        return Object.entries(state).reduce<AssetUploadsState>((newState, [uploadId, upload]) => {
            if (upload.qmrId !== qmrId) {
                newState[uploadId] = upload;
            } else if (
                upload.status === AssetUploadTaskStatus.Uploading ||
                upload.status === AssetUploadTaskStatus.Unknown
            ) {
                newState[uploadId] = upload;
            }

            return newState;
        }, {});
    }),
    on(assetUploadsActions.setUnknowns, (state) => {
        return Object.entries(state).reduce<AssetUploadsState>((newState, [uploadId, upload]) => {
            if (upload.status === AssetUploadTaskStatus.Completed || upload.status === AssetUploadTaskStatus.Error) {
                newState[uploadId] = upload;
            } else {
                newState[uploadId] = {
                    ...upload,
                    status: AssetUploadTaskStatus.Unknown,
                };
            }

            return newState;
        }, {});
    })
);
