import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';

import { TextInputHelper, Typography } from '@packages/ui/shared';
import { RemoveButton } from './remove-button';
import Select from '@web/components/select';
import RetailerSearchInput from '@web/components/retailer-search-input';

import { ConditionModel } from '../models';
import {
    AdvancedSearchAutocompleteResult,
    AdvancedSearchField,
    ADVANCED_SEARCH_PICKER_TYPE_ID,
    Retailer,
} from '@packages/models/api';
import { qmrsService } from '@web/services/singletons';

const useStyles = createUseStyles({
    queryConditionOuter: {
        display: 'flex',
        marginBottom: 12,
        alignItems: 'flex-start',
    },
    removeButton: {
        marginTop: 10,
    },
    queryCondition: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    selectField: {
        width: 300,
    },
});

interface QueryConditionProps {
    fields: AdvancedSearchField[];
    condition: ConditionModel;
    onRemove(id: string): void;
    onChange(id: string, values: Partial<ConditionModel>): void;
}

export const QueryCondition: FC<QueryConditionProps> = ({ fields, condition, onRemove, onChange }) => {
    const classes = useStyles();

    const [isInstantiated, setIsInstantiated] = useState(false);
    const [typeaheadIsLoading, setTypeaheadIsLoading] = useState(false);
    const [typeaheadOptions, setTypeaheadOptions] = useState<AdvancedSearchAutocompleteResult[]>([]);
    const [retailerValue, setRetailerValue] = useState<Retailer>();
    const typeaheadRef = useRef<AsyncTypeahead<AdvancedSearchAutocompleteResult> | null>(null);

    /* -------------------------------------------------------------- */
    /* useEffect to populate retailerValue when query is being edited */
    /* -------------------------------------------------------------- */
    useEffect(() => {
        if (isInstantiated) {
            return;
        }

        setIsInstantiated(true);

        async function fetchRetailer() {
            try {
                const response = await qmrsService.getRetailerById(condition.value);

                if (!response.success && response.aborted) {
                    return;
                }

                if (response.success) {
                    setRetailerValue(response.data.retailer);
                } else {
                    throw response.data;
                }
            } catch (error) {
                window.alert(error.message);
            }
        }

        if (condition.fieldId === 'RETAILER') {
            fetchRetailer();
        }
    }, [condition, isInstantiated]);

    function getFieldsAsArray() {
        const options = fields.map((field) => {
            return {
                value: field.fieldId,
                title: field.name,
                disabled: false,
            };
        });

        options.unshift({
            value: '',
            title: 'Select field',
            disabled: true,
        });

        return options;
    }

    const selectedOption = useMemo(() => {
        const selectedOption = fields
            .filter((field) => field.fieldId === condition.fieldId)
            .map((field) => {
                return {
                    value: field.fieldId,
                    title: field.name,
                    disabled: false,
                };
            });
        return selectedOption;
    }, [condition.fieldId, fields]);

    function getOperatorsArrayForFieldId(fieldId: string) {
        const selectedField = fields.find((f) => f.fieldId === fieldId);

        if (!selectedField) {
            return [];
        }

        const options = selectedField.operators.map((operator) => {
            return {
                value: operator.operatorId,
                title: operator.operatorName,
                disabled: false,
            };
        });

        options.unshift({
            value: '',
            title: 'Select operator',
            disabled: true,
        });

        return options;
    }

    const handleTypeaheadSearch = useCallback(async (field: AdvancedSearchField, query: string) => {
        setTypeaheadIsLoading(true);

        try {
            const response = await qmrsService.getAdvancedSearchAutocompleteResults(field.fieldTypeId, query);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setTypeaheadOptions(response.data.results);
        } catch (error) {
            setTypeaheadOptions([]);
            window.alert(error.message);
        }

        setTypeaheadIsLoading(false);
    }, []);

    /* ------------------------------------------------------------ */
    /* useEffect to watch special retail fieldTypeId */
    /* ------------------------------------------------------------ */
    useEffect(() => {
        if (retailerValue) {
            onChange(condition.id, {
                value: retailerValue.retailerId,
                displayValue: `${retailerValue.name} (#${retailerValue.code})`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retailerValue]);

    function getInputComponentForFieldId(fieldId: string, operatorId: string) {
        const selectedField = fields.find((f) => f.fieldId === fieldId);

        if (!selectedField) {
            return null;
        }

        if (selectedField.fieldTypeId === 'BOOLEAN') {
            return null;
        }

        const options = selectedField.values.map((v) => {
            return {
                value: v.valueId,
                title: v.valueName,
                disabled: false,
            };
        });

        options.unshift({
            value: '',
            title: 'Select value',
            disabled: true,
        });

        switch (selectedField.pickerTypeId) {
            case ADVANCED_SEARCH_PICKER_TYPE_ID.TEXT:
                if (operatorId === 'NULL') {
                    return;
                }
                return (
                    <TextInputHelper
                        type="text"
                        value={condition.value}
                        onChangeText={(newValue) => {
                            onChange(condition.id, {
                                value: newValue,
                                displayValue: newValue,
                            });
                        }}
                    />
                );
            case ADVANCED_SEARCH_PICKER_TYPE_ID.NUMERIC:
                if (operatorId === 'WITHIN') {
                    return (
                        <div className="d-flex align-items-center">
                            <TextInputHelper
                                type="number"
                                value={condition.value}
                                onChangeText={(newValue) => {
                                    onChange(condition.id, {
                                        value: newValue,
                                        displayValue: newValue,
                                    });
                                }}
                            />
                            <Typography
                                style={{
                                    marginHorizontal: 12,
                                }}
                            >
                                and
                            </Typography>
                            <TextInputHelper
                                type="number"
                                value={condition.valueWithin}
                                onChangeText={(newValue) => {
                                    onChange(condition.id, {
                                        valueWithin: newValue,
                                        displayValueWithin: newValue,
                                    });
                                }}
                            />
                        </div>
                    );
                } else {
                    return (
                        <TextInputHelper
                            type="number"
                            value={condition.value}
                            onChangeText={(newValue) => {
                                onChange(condition.id, {
                                    value: newValue,
                                    displayValue: newValue,
                                });
                            }}
                        />
                    );
                }

            case ADVANCED_SEARCH_PICKER_TYPE_ID.DATE:
                if (operatorId === 'WITHIN') {
                    return (
                        <div className="d-flex align-items-center">
                            <TextInputHelper
                                type="date"
                                value={condition.value}
                                onChangeText={(newValue) => {
                                    onChange(condition.id, {
                                        value: newValue,
                                        displayValue: newValue,
                                    });
                                }}
                            />
                            <Typography
                                style={{
                                    marginHorizontal: 12,
                                }}
                            >
                                and
                            </Typography>
                            <TextInputHelper
                                type="date"
                                value={condition.valueWithin}
                                onChangeText={(newValue) => {
                                    onChange(condition.id, {
                                        valueWithin: newValue,
                                        displayValueWithin: newValue,
                                    });
                                }}
                            />
                        </div>
                    );
                } else {
                    return (
                        <TextInputHelper
                            type="date"
                            value={condition.value}
                            onChangeText={(newValue) => {
                                onChange(condition.id, {
                                    value: newValue,
                                    displayValue: newValue,
                                });
                            }}
                        />
                    );
                }
            case ADVANCED_SEARCH_PICKER_TYPE_ID.CHECKBOX:
            case ADVANCED_SEARCH_PICKER_TYPE_ID.DROPDOWN:
                return (
                    <Select
                        required
                        value={condition.value}
                        onChange={(event) => {
                            onChange(condition.id, {
                                value: event.currentTarget.value,
                                displayValue: event.currentTarget.innerHTML,
                            });
                        }}
                        options={options}
                    />
                );
            case ADVANCED_SEARCH_PICKER_TYPE_ID.AUTO_TEXT:
                if (selectedField.fieldTypeId === 'RETAILER') {
                    return <RetailerSearchInput selectedRetailer={retailerValue} onChange={setRetailerValue} />;
                }

                return (
                    <AsyncTypeahead
                        ref={typeaheadRef}
                        id={`async-typeahead-${condition.id}`}
                        filterBy={() => {
                            return true;
                        }}
                        placeholder="Search"
                        labelKey="description"
                        isLoading={typeaheadIsLoading}
                        onSearch={(query) => {
                            handleTypeaheadSearch(selectedField, query);
                        }}
                        options={typeaheadOptions}
                        onChange={(selected) => {
                            if (!selected[0]) {
                                return;
                            }

                            onChange(condition.id, {
                                value: selected[0].value,
                                displayValue: selected[0].description,
                            });
                        }}
                        defaultInputValue={condition.displayValue || ''}
                        selected={undefined}
                        useCache={false}
                    />
                );
            default:
                return null;
        }
    }

    return (
        <div className={classes.queryConditionOuter}>
            <RemoveButton
                className={classes.removeButton}
                onClick={() => {
                    onRemove(condition.id);
                }}
            />

            <div className={classes.queryCondition}>
                <Typeahead
                    className={`${classes.selectField} mb-3 ml-3 flex-shrink-0`}
                    id="choose-field"
                    labelKey="title"
                    onChange={(selected) => {
                        if (!selected[0]) {
                            return;
                        }
                        onChange(condition.id, {
                            fieldId: selected[0].value,
                            operatorId: '',
                            value: '',
                            displayValue: '',
                            valueWithin: '',
                            displayValueWithin: '',
                        });
                    }}
                    options={getFieldsAsArray()}
                    defaultSelected={selectedOption}
                    placeholder="Choose Field..."
                />

                {condition.fieldId && (
                    <Select
                        className="mb-3 ml-3 flex-shrink-0"
                        value={condition.operatorId}
                        onChange={(event) => {
                            onChange(condition.id, {
                                operatorId: event.currentTarget.value,
                                value: '',
                                displayValue: '',
                                valueWithin: '',
                                displayValueWithin: '',
                            });

                            if (typeaheadRef.current) {
                                // @ts-ignore
                                typeaheadRef.current.clear();
                            }
                        }}
                        options={getOperatorsArrayForFieldId(condition.fieldId)}
                        required
                    />
                )}

                {condition.fieldId && condition.operatorId && (
                    <div
                        className="mb-3 ml-3"
                        style={{
                            minWidth: 300,
                        }}
                    >
                        {getInputComponentForFieldId(condition.fieldId, condition.operatorId)}
                    </div>
                )}
            </div>
        </div>
    );
};
