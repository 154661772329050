import React, { useCallback, useMemo, useState } from 'react';
import useTranslationData from './useTranslationData';

interface errorMessages {
    [x: string]: string;
}
export interface validationField {
    fieldHandlers: {
        handleRequired: (text: string, fieldName: keyof errorMessages, errorMessageInit?: string) => boolean;
    };
    fieldErrorMessages: errorMessages | undefined;
    setErrorMsg: (fieldName: string, errorMsg?: string) => void;
    errorsInit: {
        questionLabel: string;
        paragraph: string;
        ratingValue: string;
        inputSettingLabel: string;
        options: string;
        optionLabel: string;
    };
    isValid: boolean;
    removeField: (fieldName: string) => void;
    setFieldErrorMessages: React.Dispatch<React.SetStateAction<errorMessages>>;
}
export const useValidationField = (): validationField => {
    const [fieldErrorMessages, setFieldErrorMessages] = useState<errorMessages>({});
    const { validationMessageDataTranslate } = useTranslationData();
    const errorsInit = useMemo(() => {
        return {
            questionLabel: validationMessageDataTranslate.questionLabel,
            paragraph: validationMessageDataTranslate.paragraph,
            ratingValue: validationMessageDataTranslate.ratingValue,
            inputSettingLabel: validationMessageDataTranslate.inputSettingLabel,
            options: validationMessageDataTranslate.options,
            optionLabel: validationMessageDataTranslate.optionLabel,
        };
    }, []);

    const isValid = useMemo(() => {
        if (!fieldErrorMessages || !(Object.keys(fieldErrorMessages || {}).length > 0)) return true;
        return !Object.values(fieldErrorMessages).some((value: string) => value);
    }, [Object.keys(fieldErrorMessages || {})]);

    const handleRequired = useCallback(
        (text: string, fieldName: keyof errorMessages, errorMessageInit?: string): boolean => {
            if (text.trim()) {
                setFieldErrorMessages((prev) => {
                    const emp = { ...prev, [fieldName]: '' };
                    return emp;
                });
                return true;
            }
            setFieldErrorMessages((prev) => {
                const upd = { ...prev, [fieldName]: `${errorMessageInit || fieldName} is required.` };
                return upd;
            });

            return false;
        },
        [fieldErrorMessages]
    );
    const removeField = useCallback(
        (fieldName: string) => {
            const fieldErrorMessagesClone = fieldErrorMessages;
            delete fieldErrorMessagesClone?.[fieldName];
            setFieldErrorMessages(fieldErrorMessagesClone);
        },
        [fieldErrorMessages]
    );
    const setErrorMsg = useCallback(
        (fieldName: any, errorMsg: any) => {
            setFieldErrorMessages((prev) => ({ ...prev, [fieldName]: errorMsg || '' }));
        },
        [fieldErrorMessages]
    );
    return {
        fieldHandlers: {
            handleRequired,
        },
        fieldErrorMessages,
        setErrorMsg,
        errorsInit,
        isValid,
        removeField,
        setFieldErrorMessages,
    };
};
