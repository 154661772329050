import { Account, AccountJobTitle } from './account';
import { EntityModel } from './entities';
import { DraggableFormElement, FormElement, FormElementTypeId, draggableFormElements } from './form';
import { Qmr } from './qmr';
import { pick } from 'lodash';

export enum FeedbackFormTypeId {
    Qmr = 'QMR',
    Person = 'PERSON',
    Entity = 'ENTITY',
    FeedbackRating = 'FEEDBACK_RATING',
}

export enum FeedbackFormElementTypeId {
    TextHeader = FormElementTypeId['TextHeader'],
    TextParagraph = FormElementTypeId['TextParagraph'],
    InputText = FormElementTypeId['InputText'],
    InputTextarea = FormElementTypeId['InputTextarea'],
    Rating = FormElementTypeId['Rating'],
    RatingWithText = FormElementTypeId['RatingWithText'],
    Checkbox = FormElementTypeId['Checkbox'],
    Radio = FormElementTypeId['Radio'],
    Dropdown = FormElementTypeId['Dropdown'],
}

export enum FeedbackSessionStatusId {
    Rated = 'RATED',
    AwaitingResponse = 'AWAITING_RESPONSE',
}

export enum FeedbackGroupType {
    All = 'ALL',
    Sent = 'SENT',
    Received = 'RECEIVED',
    Requested = 'REQUESTED',
    Entity = 'ENTITY',
}

export interface FeedbackFormElementOption {
    optionId: string;
    optionValue: string;
    optionLabel: string;
    optionPlaceholder: string;
}

export interface FeedbackFormElement extends FormElement {}

export interface DraggableFeedbackFormElement extends FeedbackFormElement {
    title: string;
    description: string;
    iconName: string;
}

export interface DraggableFeedbackFormComponent extends DraggableFeedbackFormElement {}

export const draggableFeedbackFormElements: Record<FormElementTypeId, DraggableFormElement> = pick(
    draggableFormElements,
    [FormElementTypeId.TextHeader],
    [FormElementTypeId.TextParagraph],
    [FormElementTypeId.InputText],
    [FormElementTypeId.InputTextarea],
    [FormElementTypeId.Rating],
    [FormElementTypeId.RatingWithText],
    [FormElementTypeId.Checkbox],
    [FormElementTypeId.Radio],
    [FormElementTypeId.Dropdown]
) as Record<FormElementTypeId, DraggableFormElement>;

export interface FeedbackRecipient {
    id: string;
    displayName: string;
    entity: boolean;
}

export interface FeedbackForm {
    feedbackFormId: string;
    formType: FeedbackFormTypeId;
    formTypeDescription: string;
    formTitle: string;
    formElements: FeedbackFormElement[];
    active: boolean;
    createdTimestamp: string;
    createdTimestampForDisplay: string;
    jobTitleTargets: AccountJobTitle[];
    entityTargets: EntityModel[];
}

export interface FeedbackGroup {
    feedbackGroupType: FeedbackGroupType;
    count: number;
    countDescription: string;
    description: string;
    listDescription: string;
    entityId?: string;
}

export interface FeedbackSession {
    feedbackForm: FeedbackForm;
    feedbackRatingSession?: FeedbackSession;
    feedbackSessionCreatedTimestamp: string;
    feedbackSessionCreatedTimestampDescription: string;
    feedbackSessionId: string;
    feedbackSessionStatusId: FeedbackSessionStatusId;
    feedbackSessionStatusIdDescription: string;
    isReadOnly: boolean;
    bodyTitle: string;
    bodyHtml: string;
    requestMessage: string;
    receivingAccount: Account;
    sendingAccount: Account;
    detail: string;
    qmr?: Qmr;
}

export interface FeedbackFromAnswerSelectedOption {
    optionId: string;
    answerText?: string;
}

export interface FeedbackFormAnswer {
    elementId: string;
    options: FeedbackFromAnswerSelectedOption[];
}

export interface FeedbackSessionConversationItem {
    author: Account;
    comment: string;
    createdTimestampDescription: string;
}

export interface FeedbackFormsAdminDto {
    forms: FeedbackForm[];
    totalCount: number;
}

export interface FeedbackFormAdminDto {
    form: FeedbackForm;
}

export interface FeedbackSummaryDto {
    summary: {
        feedbackGroups: FeedbackGroup[];
    };
}

export interface FeedbackSessionsDto {
    sessions: FeedbackSession[];
    totalCount: number;
}

export interface FeedbackSessionDto {
    session: FeedbackSession;
}

export interface FeedbackSessionAnswersDto {
    choices: FeedbackFormAnswer[];
}

export interface FeedbackSessionConversationDto {
    conversation: {
        conversationItems: FeedbackSessionConversationItem[];
    };
}
export interface FeedbackSessionRatingFormDto {
    feedbackForm: FeedbackForm;
}

export interface FeedbackRecipientsDto {
    recipients: {
        availableRecipients: FeedbackRecipient[];
        selectedRecipients: FeedbackRecipient[];
        sendFeedbackAvailable: boolean;
        requestFeedbackAvailable: boolean;
    };
}

export interface QmrFeedbackFormDto {
    feedbackForm: FeedbackForm;
}
