import React, { FC, useState, useEffect, useRef } from 'react';
import { Col, Form, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import ConfirmInjuryEwdModal from '@web/qmr/components/confirm-injury-ewd-modal';
import ConfirmDeathEwdModal from '@web/qmr/components/confirm-death-ewd-modal';
import { useQmrState, useQmrDispatch, useQmrEwdState, patchQmr } from '@packages/contexts/qmrs';
import { useAbortController } from '@packages/core/http';
import { qmrsService } from '@web/services/singletons';
import { Label, Icon } from '@packages/ui/shared';
import { UrfcaInfoPopup } from '@packages/ui/qmr/popups';

const EarlyWarningDetectionDraft: FC = () => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const qmrDispatch = useQmrDispatch();
    const { qmr } = useQmrState();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');

    const [showUrfca, setShowUrfca] = useState(false);
    const [didShowUrfca, setDidShowUrfca] = useState(false);
    const [reportedEwd, setReportedEwd] = useState<boolean>();

    const { ewdState, setEwdState, hasUnsavedChanges } = useQmrEwdState();

    useEffect(() => {
        setReportedEwd(ewdState && ewdState.ewdSignsExist);
    }, [ewdState]);

    // AutoSave EWD on change
    const timeoutRefs = useRef<{ [k: string]: NodeJS.Timeout }>({});
    useEffect(() => {
        if (!qmr || !ewdState || !hasUnsavedChanges) {
            return;
        }

        if (timeoutRefs.current.ewd) {
            clearTimeout(timeoutRefs.current.ewd);
        }

        patchQmr({
            qmrId: qmr.qmrId,
            qmrPatch: ewdState,
            isLocalPatch: true,
            qmrsService,
            qmrDispatch,
            signal: abortSignalRef.current,
        });

        timeoutRefs.current.ewd = global.setTimeout(() => {
            if (!editRouteMatch) {
                patchQmr({
                    qmrId: qmr.qmrId,
                    qmrPatch: ewdState,
                    qmrsService,
                    qmrDispatch,
                    signal: abortSignalRef.current,
                }).catch((e) => {
                    alert(e.message);
                });
            }
        }, 60000);
    }, [abortSignalRef, editRouteMatch, ewdState, hasUnsavedChanges, qmr, qmrDispatch]);

    /* ----------------------------------------------------------- */
    /* Injury EWD */
    /* ----------------------------------------------------------- */
    const [showConfirmInjuryEwdModal, setShowConfirmInjuryEwdModal] = useState<boolean>(false);

    useEffect(() => {
        if (!ewdState) {
            return;
        }

        setShowConfirmInjuryEwdModal(ewdState.ewdInjury && !ewdState.ewdInjuryCount);
    }, [ewdState]);

    function handleConfirmInjuryEwdModalHide() {
        if (!ewdState) {
            return;
        }

        setEwdState({
            ...ewdState,
            ewdInjury: false,
            ewdInjuryCount: 0,
        });
    }

    function handleConfirmInjuryModalConfirmButtonClick(numberOfPeopleInjured: number) {
        if (!ewdState) {
            return;
        }

        setEwdState({
            ...ewdState,
            ewdInjury: numberOfPeopleInjured > 0,
            ewdInjuryCount: numberOfPeopleInjured,
        });
    }

    /* ----------------------------------------------------------- */
    /* Death EWD */
    /* ----------------------------------------------------------- */
    const [showConfirmDeathEwdModal, setShowConfirmDeathEwdModal] = useState<boolean>(false);

    useEffect(() => {
        if (!ewdState) {
            return;
        }

        setShowConfirmDeathEwdModal(ewdState.ewdDeath && !ewdState.ewdDeathCount);
    }, [ewdState]);

    function handleConfirmDeathEwdModalHide() {
        if (!ewdState) {
            return;
        }

        setEwdState({
            ...ewdState,
            ewdDeath: false,
            ewdDeathCount: 0,
        });
    }

    function handleConfirmDeathModalConfirmButtonClick(numberOfPeopleKilled: number) {
        if (!ewdState) {
            return;
        }

        setEwdState({
            ...ewdState,
            ewdDeath: numberOfPeopleKilled > 0,
            ewdDeathCount: numberOfPeopleKilled,
        });
    }

    if (!ewdState) {
        return null;
    }

    return (
        <>
            <UrfcaInfoPopup
                show={showUrfca}
                onOkPress={() => setShowUrfca(false)}
                onModalHide={() => setShowUrfca(false)}
            />
            <ConfirmInjuryEwdModal
                show={showConfirmInjuryEwdModal}
                onHide={handleConfirmInjuryEwdModalHide}
                onConfirmButtonClick={handleConfirmInjuryModalConfirmButtonClick}
                countInputValue={'' + (ewdState.ewdInjuryCount || '')}
            />

            <ConfirmDeathEwdModal
                show={showConfirmDeathEwdModal}
                onHide={handleConfirmDeathEwdModalHide}
                onConfirmButtonClick={handleConfirmDeathModalConfirmButtonClick}
                countInputValue={'' + (ewdState.ewdDeathCount || '')}
            />

            <div>
                <div className="mb-3">
                    <Label required>
                        {t(
                            'qmr:inputs.didReportEwd.label',
                            'Did the customer report any of the following:\r\nRollover, Fire, Accident, Injury, Death, Property Damage?'
                        )}
                    </Label>
                </div>

                <Form.Check
                    className="mb-3"
                    disabled={!!editRouteMatch ? !qmr?.capabilities.editEwdOnQmr : false}
                    label={t('text:common.yes')}
                    type="radio"
                    id="ewd-yes"
                    checked={!!ewdState.ewdSignsExist}
                    onChange={() => {
                        if (!reportedEwd && !didShowUrfca) {
                            setShowUrfca(true);
                            setDidShowUrfca(true);
                        }

                        setEwdState({
                            ...ewdState,
                            ewdSignsExist: true,
                        });
                    }}
                />

                <Collapse in={ewdState.ewdSignsExist}>
                    <Form.Row className="pl-6">
                        <Col>
                            <Form.Check
                                className="mb-3"
                                label={t('qmr:inputs.ewd.rollover', 'Rollover')}
                                type="checkbox"
                                id="ewd-rollover"
                                disabled={
                                    !!editRouteMatch
                                        ? !qmr?.capabilities.editEwdOnQmr || !ewdState.ewdSignsExist
                                        : !ewdState.ewdSignsExist
                                }
                                checked={ewdState.ewdRollover}
                                onChange={() => {
                                    setEwdState({
                                        ...ewdState,
                                        ewdRollover: !ewdState.ewdRollover,
                                    });
                                }}
                            />

                            <Form.Check
                                className="mb-3"
                                label={t('qmr:inputs.ewd.fire', 'Fire')}
                                type="checkbox"
                                id="ewd-fire"
                                disabled={
                                    !!editRouteMatch
                                        ? !qmr?.capabilities.editEwdOnQmr || !ewdState.ewdSignsExist
                                        : !ewdState.ewdSignsExist
                                }
                                checked={ewdState.ewdFire}
                                onChange={() => {
                                    setEwdState({
                                        ...ewdState,
                                        ewdFire: !ewdState.ewdFire,
                                    });
                                }}
                            />

                            <Form.Check
                                className="mb-3"
                                label={t('qmr:inputs.ewd.accident', 'Accident')}
                                type="checkbox"
                                id="ewd-accident"
                                disabled={
                                    !!editRouteMatch
                                        ? !qmr?.capabilities.editEwdOnQmr || !ewdState.ewdSignsExist
                                        : !ewdState.ewdSignsExist
                                }
                                checked={ewdState.ewdAccident}
                                onChange={() => {
                                    setEwdState({
                                        ...ewdState,
                                        ewdAccident: !ewdState.ewdAccident,
                                    });
                                }}
                            />

                            <Form.Check
                                className="mb-3"
                                label={
                                    <>
                                        {ewdState.ewdInjury
                                            ? t('qmr:inputs.ewd.injuryCount', {
                                                  count: ewdState.ewdInjuryCount,
                                              })
                                            : t('qmr:inputs.ewd.injury')}

                                        {ewdState.ewdInjuryCount > 0 && (
                                            <button
                                                style={{ backgroundColor: 'transparent', border: 'none' }}
                                                disabled={!!editRouteMatch ? !qmr?.capabilities.editEwdOnQmr : false}
                                                onClick={() => {
                                                    setShowConfirmInjuryEwdModal(true);
                                                }}
                                            >
                                                <Icon name="pencil" size={20} />
                                            </button>
                                        )}
                                    </>
                                }
                                type="checkbox"
                                id="ewd-injury"
                                disabled={
                                    !!editRouteMatch
                                        ? !qmr?.capabilities.editEwdOnQmr || !ewdState.ewdSignsExist
                                        : !ewdState.ewdSignsExist
                                }
                                checked={ewdState.ewdInjury}
                                onChange={() => {
                                    if (!ewdState.ewdInjury) {
                                        setShowConfirmInjuryEwdModal(true);
                                    } else {
                                        setEwdState({
                                            ...ewdState,
                                            ewdInjury: false,
                                            ewdInjuryCount: 0,
                                        });
                                    }
                                }}
                            />

                            <Form.Check
                                className="mb-3"
                                label={
                                    <>
                                        {ewdState.ewdDeath
                                            ? t('qmr:inputs.ewd.deathCount', {
                                                  count: ewdState.ewdDeathCount,
                                              })
                                            : t('qmr:inputs.ewd.death')}

                                        {ewdState.ewdDeathCount > 0 && (
                                            <button
                                                style={{ backgroundColor: 'transparent', border: 'none' }}
                                                disabled={!!editRouteMatch ? !qmr?.capabilities.editEwdOnQmr : false}
                                                onClick={() => {
                                                    setShowConfirmDeathEwdModal(true);
                                                }}
                                            >
                                                <Icon name="pencil" size={20} />
                                            </button>
                                        )}
                                    </>
                                }
                                type="checkbox"
                                id="ewd-death"
                                disabled={
                                    !!editRouteMatch
                                        ? !qmr?.capabilities.editEwdOnQmr || !ewdState.ewdSignsExist
                                        : !ewdState.ewdSignsExist
                                }
                                checked={ewdState.ewdDeath}
                                onChange={() => {
                                    if (!ewdState.ewdDeath) {
                                        setShowConfirmDeathEwdModal(true);
                                    } else {
                                        setEwdState({
                                            ...ewdState,
                                            ewdDeath: false,
                                            ewdDeathCount: 0,
                                        });
                                    }
                                }}
                            />

                            <Form.Check
                                className="mb-3"
                                label={t('qmr:inputs.ewd.propertyDamage', 'Property Damage')}
                                type="checkbox"
                                id="ewd-property-damage"
                                disabled={
                                    !!editRouteMatch
                                        ? !qmr?.capabilities.editEwdOnQmr || !ewdState.ewdSignsExist
                                        : !ewdState.ewdSignsExist
                                }
                                checked={ewdState.ewdPropertyDamage}
                                onChange={() => {
                                    setEwdState({
                                        ...ewdState,
                                        ewdPropertyDamage: !ewdState.ewdPropertyDamage,
                                    });
                                }}
                            />
                        </Col>
                    </Form.Row>
                </Collapse>

                <Form.Check
                    disabled={!!editRouteMatch ? !qmr?.capabilities.editEwdOnQmr : false}
                    label={t('text:common.no')}
                    type="radio"
                    id="ewd-no"
                    checked={typeof ewdState.ewdSignsExist !== 'undefined' && !ewdState.ewdSignsExist}
                    onChange={() => {
                        setEwdState({
                            ...ewdState,
                            ewdSignsExist: false,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default EarlyWarningDetectionDraft;
