export const NotificationPreferenceOrderConfig = {
    // Defined in these configs come in order first, if not defined, it will be appended at the end
    // Needed for the UI to display the notifications in a consistent order
    // Since the backend will return it in an order that is not consistent all the time
    qmrNotifications: [
        'QMR_SUBMITTED_NOTIFY_CREATOR',
        'QMR_SUBMITTED',
        'QMR_STATUS_CHANGED',
        'QMR_COMMENT_ADDED',
        'QMR_ADDITIONAL_INFO_ADDED',
        'QMR_FEEDBACK_PROVIDED',
        'QMR_ATTACHMENT_ADDED',
        'QMR_TOUGHBOOK_ATTACHMENT_ADDED',
        'QMR_EDITED',
        'QMR_MORE_INFO_REQ_OPENED',
        'QMR_MORE_INFO_REQ_CLOSED',
        'QMR_MORE_INFO_COMMENT',
        'PART_COLLECTION_REQUEST_OPENED',
        'PART_COLLECTION_REQUEST_CLOSED',
        'QMR_CSV_EXPORT',
        'QMR_CSV_EXPORT_FAILURE',
        'QMR_ESCALATION_FAILURE',
        'QMR_FAIL_CODE_APPROVAL_REQUESTED',
    ],
    techlineNotifications: ['NORTHSTAR_NOTIFICATION'],
    investigationNotifications: ['INVESTIGATION_COMMENT_ADDED', 'INVESTIGATION_MEETING_NOTE_ADDED'],
    feedbackNotifications: ['FEEDBACK_SUBMITTED', 'FEEDBACK_REQUESTED', 'FEEDBACK_ACKNOWLEDGED'],
    failCodeCSVExportNotifications: ['FAILCODE_CSV_EXPORT'],
};

interface SpecialCaseConfig {
    [key: string]: {
        isInAppLockedOn?: boolean;
        isGlobalLockedOn?: boolean;
        hasInApp?: boolean;
        hasEmail?: boolean;
        hasPush?: boolean;
        tooltip?: string;
        shouldRenderCondition?: string;
    };
}

export const SpecialCasesConfig: SpecialCaseConfig = {
    // References
    // https://github.com/SubaruTechShare/sts-backend/blob/develop/src/main/java/io/subcomm/backend/notification/NotificationConstants.java
    // https://subaru4.sharepoint.com/:x:/r/sites/TechShare/_layouts/15/Doc.aspx?sourcedoc=%7B3AF8D0CF-791A-4BD6-B29B-37CE44558E2B%7D&file=Permissions%20(Active).xlsx&action=default&mobileredirect=true&DefaultItemOpen=1
    // TODO : maybe use an API to get it from backend...
    QMR_SUBMITTED_NOTIFY_CREATOR: {
        hasInApp: false,
        hasPush: false,
        tooltip:
            'When a QMR is submitted, the creator will receive a confirmation email (this is a email only notification).',
    },
    QMR_SUBMITTED: {
        tooltip:
            'When a QMR is submitted, the associated reviewer will receive a notification of a new QMR in their district.',
        // This is the closest capability for this. DSQMs and above are the ones who can review QMRs.
        shouldRenderCondition: 'systemCapabilities.canSearchRestrictedFields',
    },
    QMR_TOUGHBOOK_ATTACHMENT_ADDED: {
        hasPush: false,
        tooltip:
            "Receive a notification when an attachment is added through the 'Send to QMR' folder on the Toughbook and associated with your QMR.",
    },
    QMR_CSV_EXPORT: {
        shouldRenderCondition: 'systemCapabilities.canExportQMRsAsCsv',
    },
    QMR_CSV_EXPORT_FAILURE: {
        isGlobalLockedOn: true,
        shouldRenderCondition: 'systemCapabilities.canExportQMRsAsCsv',
    },
    QMR_ESCALATION_FAILURE: {
        isGlobalLockedOn: true,
        shouldRenderCondition: 'systemCapabilities.canViewEscalations',
    },
    INVESTIGATION_COMMENT_ADDED: {
        hasPush: false,
    },
    INVESTIGATION_MEETING_NOTE_ADDED: {
        hasPush: false,
    },
    NORTHSTAR_NOTIFICATION: {
        tooltip: 'Receive notifications related to any Techline case where you are a case team member.',
    },
    QMR_EDITED: {
        tooltip: 'Receive a notification when a QMR you are associated with is edited.',
    },
    QMR_MORE_INFO_REQ_OPENED: {
        // using canExportQMRsAsCsv because there is no specific capability for this and it is the closest thing
        shouldRenderCondition: 'systemCapabilities.canExportQMRsAsCsv',
    },
    QMR_MORE_INFO_COMMENT: {
        hasPush: false,
        // using canExportQMRsAsCsv because there is no specific capability for this and it is the closest thing
        shouldRenderCondition: 'systemCapabilities.canExportQMRsAsCsv',
    },
    QMR_MORE_INFO_REQ_CLOSED: {
        hasPush: false,
        // using canExportQMRsAsCsv because there is no specific capability for this and it is the closest thing
        shouldRenderCondition: 'systemCapabilities.canExportQMRsAsCsv',
    },
    PART_COLLECTION_REQUEST_CLOSED: {
        // using canExportQMRsAsCsv because there is no specific capability for this and it is the closest thing
        shouldRenderCondition: 'systemCapabilities.canExportQMRsAsCsv',
    },
    PART_COLLECTION_REQUEST_OPENED: {
        // using canExportQMRsAsCsv because there is no specific capability for this and it is the closest thing
        shouldRenderCondition: 'systemCapabilities.canExportQMRsAsCsv',
    },
    FAILCODE_CSV_EXPORT: {
        hasPush: false,
    },
};

export const getAccountCapabilitiesPerNotification = (obj: any, path: string) => {
    // This function is used to get the capabilities for a specific notification
    // Where obj is the account object and path is the path to the capability
    // Example usage: getAccountCapabilitiesPerNotification(account, 'systemCapabilities.canExportQMRsAsCsv');
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const QmrNotifcationLockedRoles = [
    // These roles always have locked on "in-app" notifications for QMRs per business requirement
    'RETAILER_USER',
    'RETAILER_ADMIN',
    'DSQM',
    'RSQM',
];

interface NotificationTypeNames {
    [key: string]: string;
}

export const NotificationTypeNames: NotificationTypeNames = {
    // these are the notifications that we want to have a special mapping name
    // if not in here, it will automatically be mapped to a human-readable name using regex script

    // For example, we want to clarify QMR_SUBMITTED that it is to "NOTIFY REVIEWER"
    // and the other is to "NOTIFY CREATOR"
    QMR_SUBMITTED: 'Submitted (Notify Reviewer)',
    QMR_SUBMITTED_NOTIFY_CREATOR: 'Submitted (Notify Creator)',
    NORTHSTAR_NOTIFICATION: 'Techline',
    QMR_CSV_EXPORT: 'CSV Export',
    QMR_CSV_EXPORT_FAILURE: 'CSV Export Failure',
    FAILCODE_CSV_EXPORT: 'Fail Code CSV Export',
};

export const NotificationAPINameToHumanReadable = (apiName: string) => {
    // Converts something like "INVESITGATION_COMMENT_ADDED" to "Investigation Comment Added"
    // or "QMR_ATTACHMENT_ADDED" to "Attachment Added"
    return apiName
        .replace(/_/g, ' ') // Replace underscores with spaces
        .toLowerCase() // Convert to lowercase to start with a clean slate
        .replace(/\b\w/g, (letter: string) => letter.toUpperCase()) // Capitalize the first letter of each word
        .replace(/^Qmr /, ''); // Remove 'Qmr ' if it is the first word
};

export const NotificationGroupInTitleCase = (groupName: string): string => {
    // Converts a group name like "qmrNotification" to "Qmr"

    // Split the string by 'Notifications'
    const parts = groupName.split('Notifications');

    // Extract the first part and capitalize the first letter
    const titleCaseString = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);

    return titleCaseString;
};
