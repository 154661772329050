/* eslint-disable react-native/no-inline-styles */
import React, { FC } from 'react';
import { View, StyleSheet } from 'react-native';
import { Typography } from './typography';
import { AccountLocation as IAccountLocation } from '@packages/models/api';
import colors from '@packages/core/styles/colors';

interface Props {
    elements: IAccountLocation['elements'];
    index: number;
    showCount?: boolean;
}

export const AccountLocation: FC<Props> = ({ showCount, elements, index }) => {
    return (
        <View style={styles.table}>
            <View style={styles.tableHeader}>
                <Typography variant="label">
                    Location
                    {showCount ? ' ' + (index + 1) : ''}
                </Typography>
            </View>

            {elements
                .filter((el) => el.shouldDisplay)
                .map((el, elIndex) => {
                    const isLast = elIndex === elements.length - 1;

                    return (
                        <View key={elIndex} style={[styles.field, { borderBottomWidth: isLast ? 0 : 1 }]}>
                            <Typography style={styles.fiieldTitle} variant="lead" color="textDarkSecondary">
                                {el.title}
                            </Typography>
                            <Typography>{el.value}</Typography>
                        </View>
                    );
                })}
        </View>
    );
};

const styles = StyleSheet.create({
    table: {
        borderColor: colors.grayThree,
        borderWidth: 1,
        marginBottom: 16,
    },
    tableHeader: {
        paddingVertical: 8,
        paddingHorizontal: 12,
        backgroundColor: colors.grayTwo,
        borderColor: colors.grayThree,
        borderBottomWidth: 1,
    },
    fiieldTitle: {
        marginBottom: 8,
    },
    field: {
        borderColor: colors.grayThree,
        borderBottomWidth: 1,
        padding: 16,
    },
});
