import React, { FC } from 'react';
import { BadgeVariants, Icon, TextBadge } from '@packages/ui/shared';
import { IconProps } from '@packages/ui/shared';

interface BmisBatteryBadgeProps {
    label: string | null | undefined;
}

export const BmisBatteryBadge: FC<BmisBatteryBadgeProps> = ({ label }) => {
    let variant: BadgeVariants;
    let iconProps: IconProps;

    if (!label) return null;
    const lowerCaseLabel = label.toLowerCase();

    if (lowerCaseLabel.includes('good')) {
        variant = 'green';
        iconProps = { name: 'check-circle-hollow' as const, color: 'greenOne' };
    } else if (lowerCaseLabel.includes('bad') || lowerCaseLabel.includes('replace')) {
        variant = 'red';
        iconProps = { name: 'x-circle' as const, color: 'redOne' };
    } else {
        variant = 'gray';
        iconProps = { name: 'circle-indeterminate' as const, color: 'black' };
    }

    return (
        <TextBadge
            style={{ paddingLeft: 8, paddingRight: 8 }}
            variant={variant}
            icon={<Icon {...iconProps} size={16} />}
        >
            {label ? label : 'N/A'}
        </TextBadge>
    );
};
