import React, { forwardRef, useState, useImperativeHandle, useRef, useEffect } from 'react';
import { StyleSheet, TextInput, TextInputProps, TextStyle, View, ViewStyle, Platform } from 'react-native';

import colors from '@packages/core/styles/colors';

import { Label } from './label';
import { Typography } from './typography';
import { useHoverState } from './hooks';
import { Icon } from './icon';

interface TextInputState {
    isHovered: boolean;
    isFocused: boolean;
    isDisabled: boolean;
    hasError: boolean;
}

export interface TextInputHelperProps extends TextInputProps {
    style?: ViewStyle;
    inputContainerStyle?: ViewStyle;
    label?: string;
    labelHint?: string;
    showCharCount?: boolean;
    textInputStyle?: TextStyle;
    rightIcon?: JSX.Element | ((rightIconProps: { inputState: TextInputState }) => JSX.Element);
    errorMessage?: string;
    required?: boolean;
    optional?: boolean;
    controlId?: string;
    type?: string;
    maxDate?: string;
    minDate?: string;
}

export const TextInputHelper = forwardRef<TextInput | null, TextInputHelperProps>(
    (
        {
            style,
            inputContainerStyle,
            label,
            labelHint,
            required,
            optional,
            showCharCount,
            textInputStyle,
            rightIcon,
            errorMessage,
            controlId,
            type,
            maxDate,
            minDate,
            ...props
        },
        ref
    ) => {
        const [isFocused, setIsFocused] = useState(false);
        const { isHovered, hoverEventHandlers } = useHoverState();

        const inputRef = useRef<TextInput>(null);

        useImperativeHandle(ref, () => inputRef.current, []);

        useEffect(() => {
            if (Platform.OS === 'web' && type) {
                inputRef.current &&
                    inputRef.current.setNativeProps({
                        type,
                        max: maxDate,
                        min: minDate,
                    });
            }
        }, [maxDate, minDate, type]);

        const isDisabled = typeof props.editable === 'boolean' && !props.editable;
        const hasError = !!errorMessage;

        const inputState = {
            isHovered,
            isFocused,
            isDisabled,
            hasError,
        };

        return (
            <View style={style}>
                {!!label && (
                    <Label
                        controlId={controlId}
                        required={required}
                        optional={optional}
                        charCount={showCharCount ? `${(props.value || '').length}/${props.maxLength}` : undefined}
                        hint={labelHint}
                    >
                        {label}
                    </Label>
                )}

                <View
                    {...hoverEventHandlers}
                    style={[
                        styles.inputContainer,
                        type === 'date' && styles.dateInput,
                        !isDisabled && isHovered && styles.hoveredInput,
                        !isDisabled && isFocused && styles.focusedInput,
                        !isDisabled && !!errorMessage && styles.invalidInput,
                        isDisabled && styles.disabledInput,
                        inputContainerStyle,
                    ]}
                >
                    <TextInput
                        nativeID={controlId}
                        ref={inputRef}
                        {...props}
                        onFocus={(e) => {
                            setIsFocused(true);
                            props.onFocus && props.onFocus(e);
                        }}
                        onBlur={(e) => {
                            setIsFocused(false);
                            props.onBlur && props.onBlur(e);
                        }}
                        underlineColorAndroid="transparent"
                        style={[styles.textInput, textInputStyle]}
                        placeholderTextColor={colors.textDarkTertiary}
                    />

                    <View style={styles.rightIconsWrapper}>
                        {hasError && <Icon style={rightIcon && styles.errorIconSpacer} name="error" color="redOne" />}

                        {typeof rightIcon === 'function' ? rightIcon({ inputState }) : rightIcon}
                    </View>
                </View>

                {hasError && (
                    <Typography variant="caption" color="redOne" style={styles.errorMessage}>
                        {errorMessage}
                    </Typography>
                )}
            </View>
        );
    }
);

const styles = StyleSheet.create({
    inputContainer: {
        height: Platform.select({ web: 40, default: 48 }),
        paddingHorizontal: 12,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 3,
        borderWidth: 1,
        borderColor: colors.grayFive,
    },
    focusedInput: {
        borderWidth: 2,
        borderColor: colors.blueOne,
    },
    invalidInput: {
        borderWidth: 2,
        borderColor: colors.redOne,
    },
    textInput: {
        flex: 1,
        fontSize: 17,
        lineHeight: 24,
        maxWidth: '100%',
        color: colors.textDarkPrimary,
        textAlignVertical: 'top',
    },
    hoveredInput: {
        shadowColor: colors.black,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.16,
        shadowRadius: 4,
    },
    disabledInput: {
        borderColor: colors.grayTwo,
        color: colors.textDarkSecondary,
        backgroundColor: colors.grayTwo,
    },
    rightIconsWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    errorIconSpacer: {
        marginRight: 12,
    },
    errorMessage: {
        marginTop: 8,
    },
    dateInput: {
        paddingRight: 0,
    },
});
