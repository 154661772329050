import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Switch } from 'react-native';
import { createUseStyles } from 'react-jss';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Select, { SelectOption } from '@web/components/select';

import colors from '@packages/core/styles/colors';
import { Typography, Icon, Button, TextInputHelper } from '@packages/ui/shared';
import useQuery from '@web/core/hooks/use-query';
import { useNavigate } from 'react-router-dom';
import { CarlineQuickFilterOption, QuickFilterOption } from '@packages/models/api';
import { analyticsService, qmrsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAuthState } from '@packages/contexts/auth';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { techlineService } from '@web/services/singletons';
import { Retailer } from '../views/all-cases';

const submissionOptions = [
    {
        label: 'All',
    },
    {
        label: 'Today',
    },
    {
        label: 'Yesterday',
    },
    {
        label: 'This week',
    },
    {
        label: 'This month',
    },
];

const statusOptions = [
    {
        label: 'Open',
        value: 'OPEN',
    },
    {
        label: 'Draft',
        value: 'DRAFT',
    },
    {
        label: 'Pending Techline',
        value: 'PENDING_TECHLINE',
    },
    {
        label: 'Update Requested',
        value: 'UPDATE_REQUESTED',
    },
    {
        label: 'Closed',
        value: 'CLOSED',
    },
];

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    filterHeader: {
        textTransform: 'uppercase',
        margin: '16px 24px',
    },
    filterContent: {
        margin: '0 24px 16px',
    },
    hr: {
        margin: '0 24px',
    },
});

interface Filters {
    vin: string;
    selectedStatus: [];
    selectedCasetypes: [];
    selectedSubmissionDate: [];
}

interface Props {
    applyFilters: (filters: any) => void;
    clearFilters: () => void;
    onClose: () => void;
    filters: Filters;
    selectedRetailer?: Retailer;
}

export const TechlineQuickFilters = ({ onClose, filters, applyFilters, clearFilters, selectedRetailer }: Props) => {
    const [vin, setVin] = useState(filters.vin);
    const [selectedStatus, setSelectedStatus] = useState(filters.selectedStatus as any);
    const [selectedCasetypes, setSelectedCasetypes] = useState(filters.selectedCasetypes as any);
    const [selectedSubmissionDate, setSelectedSubmissionDate] = useState(filters.selectedSubmissionDate as any);
    const [caseTypesOptions, setCaseTypesOptions] = useState([]);
    const { abortSignalRef } = useAbortController();
    const classes = useStyles();

    useEffect(() => {
        (async () => {
            const caseTypesRes = await techlineService.retrieveCaseTypes(false, abortSignalRef.current);
            if (caseTypesRes?.data?.results?.length) {
                setCaseTypesOptions(caseTypesRes.data.results);
            }
        })();
    }, [abortSignalRef]);

    const rightPanelDiv = document.getElementById('right-side-panel');

    if (!rightPanelDiv) {
        return null;
    }

    const applyAndClose = () => {
        onClose();
        applyFilters({
            vin,
            selectedStatus,
            selectedCasetypes,
            selectedSubmissionDate,
        });
    };

    const divider = <hr className={classes.hr} />;

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <div className={classes.panelHeader}>
                <Typography>Filters</Typography>
                <div className={classes.closeButton} onClick={onClose}>
                    <Icon name="x-close" />
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                <div className={`${classes.filterHeader} d-flex flex-column`}>
                    <Typography variant="caption2">Retailers</Typography>
                    <Typography style={{ textTransform: 'none' }} variant="h5">
                        {selectedRetailer?.retailerName}
                    </Typography>
                </div>
                {divider}
                <div className="mr-8 ml-8 mb-8 mt-5">
                    <TextInputHelper label="VIN" value={vin} onChangeText={setVin} placeholder="Enter VIN" />
                </div>
                {divider}
                <div className="mr-8 ml-8 mb-8 mt-5">
                    <Typography variant="label">STATUS</Typography>
                    <Typeahead
                        className="mt-3"
                        multiple
                        onChange={setSelectedStatus}
                        options={statusOptions as any}
                        placeholder="Choose status..."
                        selected={selectedStatus}
                    />
                </div>
                {divider}
                <div className="mr-8 ml-8 mb-8 mt-5">
                    <Typography variant="label">CASE TYPE</Typography>
                    {caseTypesOptions.length && (
                        <Typeahead
                            id="typeahead-case-type-select"
                            className="mt-3"
                            multiple
                            onChange={setSelectedCasetypes}
                            labelKey={'caseTypeName' as any}
                            options={caseTypesOptions}
                            placeholder="Choose case type..."
                            selected={selectedCasetypes}
                        />
                    )}
                </div>
                {divider}
                <div className="mr-8 ml-8 mb-8 mt-5">
                    <Typography variant="label">SUBMISSION DATE</Typography>
                    <Typeahead
                        className="mt-3"
                        onChange={setSelectedSubmissionDate}
                        options={submissionOptions as any}
                        placeholder="Choose submission date..."
                        selected={selectedSubmissionDate}
                    />
                </div>
                {divider}
                <div className="mr-8 ml-8 mb-8 mt-5 d-flex flex-row align-items-center justify-content-between">
                    <Button onPress={applyAndClose}>Apply</Button>
                    <Button
                        variant="ghost-blue"
                        onPress={() => {
                            clearFilters();
                            onClose();
                        }}
                    >
                        Clear all
                    </Button>
                </div>
            </div>
        </div>,
        rightPanelDiv
    );
};
