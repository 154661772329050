import React from 'react';
import { StsIconName } from '@packages/ui/shared';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { draggableFormElements } from '@packages/models/api';
import { FormElement as IWorksheetFormElement } from '@web/administration/components';
import { UseNewWorksheetFormStylesReturn } from '../worksheet-form/worksheets-form';

interface Props {
    classes: UseNewWorksheetFormStylesReturn;
}

const WorksheetLeftSideBar = ({ classes }: Props) => {
    return (
        <>
            <div className={classes.formElementsCol}>
                <Droppable droppableId="FORM_ELEMENTS" isDropDisabled={true}>
                    {(provided) => (
                        <div ref={provided.innerRef}>
                            {Object.values(draggableFormElements).map((item, index) => (
                                <Draggable
                                    key={item.formElementTypeId}
                                    draggableId={item.formElementTypeId}
                                    index={index}
                                >
                                    {(provided, snapshot) => {
                                        return (
                                            <IWorksheetFormElement
                                                provided={provided}
                                                snapshot={snapshot}
                                                title={item.title}
                                                iconName={'administration' || (item.iconName as StsIconName)}
                                            />
                                        );
                                    }}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </>
    );
};

export default WorksheetLeftSideBar;
