import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { Icon, Typography, Button } from '@packages/ui/shared';
import { useTranslation } from 'react-i18next';
import { patchAuthUserProfile, useAuthDispatch, useAuthState } from '@packages/contexts/auth';
import { useAbortController } from '@packages/core/http';
import { authService } from '@web/services/singletons';

const useEmailNotificationsModal = createUseStyles({
    emailNotificationsModal: {
        width: 552,
        maxWidth: 'none',
    },
});

interface EmailNotificationsModalProps {
    show: boolean;
    onHide(): void;
}

const EmailNotificationsModal: FC<EmailNotificationsModalProps> = (props) => {
    const { abortSignalRef } = useAbortController();
    const classes = useEmailNotificationsModal();
    const { account } = useAuthState();
    const authDispatch = useAuthDispatch();
    const { t } = useTranslation();

    return (
        <Modal
            show={props.show}
            dialogClassName={classes.emailNotificationsModal}
            onHide={() => {
                props.onHide();
            }}
            centered
        >
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>{t('modals:emailNotifications.title', 'Email notifications')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex">
                    <Icon name="bell" />
                    <div className="ml-4">
                        <Typography variant="h5">
                            {t('modals:emailNotifications.instruction', 'Turn email notifications on')}
                        </Typography>
                        <div className="mt-4">
                            <Typography variant="lead">
                                {t(
                                    'modals:emailNotifications.message',
                                    "You'll receive messages related to QMRs you follow, activity, and much more."
                                )}
                            </Typography>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    title={t('modals:emailNotifications.actions.deny', 'Deny')}
                    variant="ghost-gray"
                    onPress={() => props.onHide()}
                />

                <div className="ml-2">
                    <Button
                        title={t('modals:emailNotifications.actions.turnOn', 'Turn on')}
                        onPress={() => {
                            if (!account) {
                                return;
                            }

                            patchAuthUserProfile({
                                accountId: account.accountId,
                                authDispatch,
                                authService,
                                accountPatch: {
                                    emailNotificationsEnabled: true,
                                },
                                signal: abortSignalRef.current,
                            });
                            props.onHide();
                        }}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default EmailNotificationsModal;
