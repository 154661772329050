import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    backdrop: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContainer: {
        margin: 0,
        top: '50%',
        left: '50%',
        transform: [{ translateX: '-50%' }, { translateY: '-50%' }],
        width: 'fit-content',
    },
    hidden: {
        display: 'none',
    },
    visible: {
        display: 'flex',
    },
});
