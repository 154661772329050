import React, { FC, useEffect, useState } from 'react';
import { Bmis } from '@packages/models/api';
import { BmisBatteryBadge } from '@web/bmis/components/bmis-battery-badge';
import { BmisLayout } from '@web/bmis/components/bmis-layout';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { DATE_FORMAT } from '@web/config/constants';
import { Link, useParams } from 'react-router-dom';
import { TableCell, TableHeader, TableRenderer, TableRow } from '@web/components/table';
import { bmisService } from '@web/services/singletons';
import { setVin, useBmisDispatch, useBmisState } from '@packages/contexts/bmis';
import { useTranslation } from 'react-i18next';
import { getDisplayDate, getDisplayValue } from '@web/utils';

interface BmisTableRowModel extends Bmis {
    rowId: string;
    checked: boolean;
}

export const BmisList: FC = () => {
    const [bmisTableRows, setBmisTableRows] = useState<BmisTableRowModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const bmisDispatch = useBmisDispatch();
    const { bmisData } = useBmisState();
    const { t } = useTranslation();

    const { vin: vinString } = useParams<{ vin: string }>();

    useEffect(() => {
        setIsLoading(true);
        setVin(bmisDispatch, bmisService, vinString);
    }, [bmisDispatch, vinString]);

    useEffect(() => {
        if (bmisData) {
            setBmisTableRows(
                bmisData.map((item) => ({
                    ...item,
                    rowId: item.rowGuid,
                    checked: false,
                }))
            );
        }
        setIsLoading(false);
    }, [bmisData]);

    return (
        <BmisLayout>
            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <div className="mb-6 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <Typography variant="h2">{t('BMIS - Battery Tests')}</Typography>
                            <Icon name="info" style={{ marginLeft: 15 }} />
                        </div>
                        <div className="d-flex pl-0">
                            <Button
                                style={{ marginLeft: 8 }}
                                iconRight={<Icon color="blueOne" name="launch" />}
                                variant="ghost-blue"
                            >
                                {t('Check in Midtronics')}
                            </Button>
                        </div>
                    </div>

                    <TableRenderer<BmisTableRowModel>
                        isLoading={isLoading}
                        tableRowsData={bmisTableRows}
                        tableRowsDataSetter={setBmisTableRows}
                        tableHeaderRowRenderer={() => {
                            return (
                                <TableRow>
                                    <TableHeader>
                                        <Typography variant="label">{t('Latest Battery Test Decision')}</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">{t('Test Code')}</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">{t('Test Type')}</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">{t('Tool Type')}</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">{t('Location ID')}</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">{t('Battery Service Date')}</Typography>
                                    </TableHeader>
                                </TableRow>
                            );
                        }}
                        tableBodyRowRenderer={(rowData) => {
                            return (
                                <TableRow key={rowData.rowId}>
                                    <TableCell>
                                        <Typography numberOfLines={1}>
                                            <BmisBatteryBadge label={rowData?.finalBatteryTestDecision} />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>
                                            <Link to={`./${rowData?.rowId}`}>
                                                {getDisplayValue(rowData?.starterTestCode)}
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>{getDisplayValue(rowData?.testType)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>{getDisplayValue(rowData?.toolType)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>
                                            {getDisplayValue(rowData?.locationID)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>
                                            {getDisplayDate(rowData?.serviceDateAndTime, DATE_FORMAT.MON_DAY_YEAR)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />
                </div>
            </div>
        </BmisLayout>
    );
};
