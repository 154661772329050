import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { TextInputHelper, Typography } from '@packages/ui/shared';
import {
    DraggableWorksheetFormComponent,
    FormElementFile,
    FormElementOption,
    WorksheetFormElementOption,
} from '@packages/models/api';
import Select from '@web/components/select';
import AddField from './add-field';
import { AddFieldType } from './worksheet-form-component-edit-modal';
import colors from '@packages/core/styles/colors';
import { useValidationField } from './hooks/useValidationField';
import useTranslationData from './hooks/useTranslationData';
import { WorksheetCharacterLengthValidation } from '@web/administration/views/worksheets/utils';
enum RATING_FORMAT {
    ONE_TO_FIVE = 'ONE_TO_FIVE',
}

interface FormWorksheetEditLikertLongTextProps extends DraggableWorksheetFormComponent {
    onChange({
        formElementLabel,
        options,
        fromElementFileCaption,
        formElementFile,
        formElementDescription,
    }: {
        formElementLabel?: string;
        options?: WorksheetFormElementOption[] | undefined;
        worksheetElementTooltip?: string;
        fromElementFileCaption?: string;
        formElementFile?: FormElementFile;
        formElementDescription?: string;
    }): void;
    addField: AddFieldType;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormEditLikertLongText: FC<FormWorksheetEditLikertLongTextProps> = ({
    formElementLabel,
    formElementFile,
    formElementDescription,
    options,
    onChange,
    formElementTooltip,
    addField,
    setIsValid,
}) => {
    const [ratingOptions, setRatingOptions] = useState<FormElementOption[]>();
    const [textareaOption, setTextareaOption] = useState<FormElementOption>();
    const [ratingFormatInputValue, seRatingFormatInputValue] = useState(RATING_FORMAT.ONE_TO_FIVE);
    const {
        fieldHandlers: { handleRequired },
        fieldErrorMessages,
        errorsInit,
        isValid,
    } = useValidationField();
    const { draggableElementsDataTranslate } = useTranslationData();
    useEffect(() => {
        setIsValid(isValid);
    }, [Object.keys(fieldErrorMessages || {})]);

    useEffect(() => {
        if (!options) {
            return;
        }

        const optionsClone = cloneDeep(options);
        const textareaOption = optionsClone.pop();

        setRatingOptions(optionsClone);
        setTextareaOption(textareaOption);
    }, [options]);

    const handleOptionLabelChange = useCallback(
        (index: number, text: string) => {
            if (!ratingOptions || text.trim().length > WorksheetCharacterLengthValidation.QuestionLength) {
                return;
            }

            const ratingOptionsClone = cloneDeep(ratingOptions);
            handleRequired(text, ratingOptionsClone[index].optionId, errorsInit.ratingValue);
            ratingOptionsClone[index].optionLabel = text;

            if (textareaOption) {
                ratingOptionsClone.push(textareaOption);
            }

            onChange({ options: ratingOptionsClone });
        },
        [onChange, ratingOptions, textareaOption, handleRequired]
    );

    const handleTextareaLabelChangeText = useCallback(
        (text: string) => {
            if (!textareaOption || text.trim().length > WorksheetCharacterLengthValidation.QuestionLength) {
                return;
            }

            handleRequired(text, 'inputSettingLabel', errorsInit.inputSettingLabel);
            const textareaOptionClone = cloneDeep(textareaOption);
            textareaOptionClone.optionLabel = text;

            const optionsToSend = ratingOptions ? cloneDeep(ratingOptions) : [];
            optionsToSend.push(textareaOptionClone);

            onChange({ options: optionsToSend });
        },
        [onChange, ratingOptions, textareaOption, handleRequired]
    );

    const handleTextareaPlaceholderChangeText = useCallback(
        (text: string) => {
            if (!textareaOption || text.trim().length > WorksheetCharacterLengthValidation.QuestionLength) {
                return;
            }

            const textareaOptionClone = cloneDeep(textareaOption);
            textareaOptionClone.optionPlaceholder = text;

            const optionsToSend = ratingOptions ? cloneDeep(ratingOptions) : [];
            optionsToSend.push(textareaOptionClone);

            onChange({ options: optionsToSend });
        },
        [onChange, ratingOptions, textareaOption]
    );
    return (
        <>
            <AddField
                addFieldValues={addField}
                onchangeText={onChange}
                formElementFile={formElementFile}
                formElementTooltip={formElementTooltip}
                formElementDescription={formElementDescription || ''}
                formElementLabel={formElementLabel}
                errorMessage={fieldErrorMessages?.questionLabel}
                handleRequired={handleRequired}
            />

            <hr style={{ borderWidth: 2, borderColor: colors.grayTwo }} />

            <div className="mb-2 mt-5">
                <Typography variant="label">{draggableElementsDataTranslate.labels.options}</Typography>
            </div>
            <div className="mb-6">
                <Select
                    value={ratingFormatInputValue}
                    options={[
                        {
                            title: '1 to 5',
                            value: RATING_FORMAT.ONE_TO_FIVE,
                        },
                    ]}
                    onChange={(event) => {
                        seRatingFormatInputValue(event.currentTarget.value as RATING_FORMAT);
                    }}
                />
            </div>
            <div className="mb-2">
                <Typography variant="label">{draggableElementsDataTranslate.labels.ratingValues}</Typography>
            </div>
            <div className="mb-6">
                {ratingFormatInputValue === RATING_FORMAT.ONE_TO_FIVE && (
                    <>
                        {ratingOptions?.map((option, index) => {
                            return (
                                <div key={`${option.optionId}-${index}`} className="d-flex mb-2 align-items-center">
                                    <div className="mr-2">
                                        <Typography>{index + 1}</Typography>
                                    </div>
                                    <TextInputHelper
                                        required
                                        errorMessage={fieldErrorMessages?.[option.optionId]}
                                        style={{ flex: 1 }}
                                        value={option.optionLabel}
                                        onChangeText={(text) => {
                                            handleOptionLabelChange(index, text);
                                        }}
                                        placeholder={draggableElementsDataTranslate.placeholder.questionLabel}
                                    />
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
            <div className="mb-2">
                <Typography variant="label">{draggableElementsDataTranslate.labels.ratingValues}</Typography>
            </div>
            <div className="mb-6">
                <TextInputHelper
                    required
                    errorMessage={fieldErrorMessages?.inputSettingLabel}
                    value={textareaOption?.optionLabel}
                    onChangeText={handleTextareaLabelChangeText}
                    placeholder={draggableElementsDataTranslate.placeholder.questionLabel}
                />
            </div>
            <div>
                <TextInputHelper
                    value={textareaOption?.optionPlaceholder}
                    onChangeText={handleTextareaPlaceholderChangeText}
                    placeholder={draggableElementsDataTranslate.placeholder.optionPlaceholder}
                />
            </div>
        </>
    );
};
