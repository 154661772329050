import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { FailCodeStartModal } from './fail-code-start-modal';
import FailCodesLookupBySection from './fail-code-lookup-by-section';
import { FailCodeLookupElectricalGrid } from './fail-code-lookup-electrical-grid';
import { FailCodeSearch } from './fail-code-search';
import { FailCodePartSearch } from './fail-code-part-search';
import { FailCodeNoTroubleFound, FailCodeContainer } from './fail-code-no-trouble-found';
import { FailCodeViewMatching } from './fail-code-view-matching';
import { FailCodeAccidentInvestigation } from './fail-code-accident-investigation';
import { patchQmr, useQmrDispatch, useQmrState } from '@packages/contexts/qmrs';
import { analyticsService, failCodesService, qmrsService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { Button, Typography } from '@packages/ui/shared';
import { useAbortController } from '../../../../../packages/core/http';

interface Props {
    show: boolean;
    onHide(): void;
    handleSuccess(value: string): void;
}

export interface SearchProps {
    askApprover: boolean;
    searchFailCode: boolean;
    searchFailCodeByPart: boolean;
    lookBySection: boolean;
    lookupByElectricalGridLocation: boolean;
    noTroubleFound: boolean;
    accidentInvestigation: boolean;
    viewMatching: boolean;
}

export interface AnalyticalProp {
    enterTime: number;
}

export type ContentTypes =
    | 'start'
    | 'askApprover'
    | 'searchFailCode'
    | 'searchFailCodeByPart'
    | 'lookupBySection'
    | 'lookupByElectricalGridLocation'
    | 'noTroubleFound'
    | 'accidentInvestigation'
    | 'viewMatching';

const useFailCodeSearchModalStyles = createUseStyles({
    failCodeSearchModal: {
        width: 728,
        maxWidth: 'none',
    },
});

export const FailCodesContainerModal = (props: Props) => {
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const { abortSignalRef } = useAbortController();
    const classes = useFailCodeSearchModalStyles();
    const enterTimeRef = useRef<number>();

    const [isLoading, setIsloading] = useState(false);
    const [content, setContent] = useState<ContentTypes>('start');
    const [showMatchingFailCodes, setShowMatchingFailCodes] = useState(false);
    const [failCodes, setFailCodes] = useState<Array<FailCodeContainer>>([]);
    const [prefillPartNumber, setPrefillPartNumber] = useState(false);

    // Capture enterTime when modal shows.
    useEffect(() => {
        if (props.show) {
            enterTimeRef.current = Date.now();
        }
    }, [props.show]);

    // Track duration this modal was shown for
    useEffect(() => {
        // if has "entry time" & no longer showing === exited
        if (enterTimeRef.current && !props.show) {
            const exitTime = Date.now();

            analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLOSES_FAILCODE_MODAL, {
                duration: (exitTime - enterTimeRef.current) / 1000,
            });
        }
    }, [props.show]);

    /* --------------------------------------------------------- */
    /* Precheck if partNumber has matching failCodes */
    /* --------------------------------------------------------- */
    const handleEnter = useCallback(async () => {
        if (!qmr) {
            return;
        }

        if (!qmr.partNumber) {
            return;
        }

        setIsloading(true);

        try {
            const response = await failCodesService.lookupFailCodes({
                qmrId: qmr.qmrId,
                partNumber: qmr.partNumber,
            });

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            if (response.data.failCodes.length > 0) {
                setShowMatchingFailCodes(true);
            }
        } catch (error) {
            window.alert(error.message);
        }

        setIsloading(false);
    }, [qmr]);

    const handleSetContent = (searchProps: SearchProps) => {
        if (searchProps.askApprover) {
            setContent('askApprover');
        } else if (searchProps.lookBySection) {
            setContent('lookupBySection');
        } else if (searchProps.lookupByElectricalGridLocation) {
            setContent('lookupByElectricalGridLocation');
        } else if (searchProps.searchFailCode) {
            setContent('searchFailCode');
        } else if (searchProps.searchFailCodeByPart) {
            setContent('searchFailCodeByPart');
        } else if (searchProps.noTroubleFound) {
            setContent('noTroubleFound');
        } else if (searchProps.accidentInvestigation) {
            setContent('accidentInvestigation');
        } else if (searchProps.viewMatching) {
            // Just push them to the searchFailCodeByPart screen with the partnumber prefilled
            setPrefillPartNumber(true);
            setContent('searchFailCodeByPart');
        }
    };

    const handleShowMatchingFailCodesTrue = (failCodes: Array<FailCodeContainer>) => {
        setFailCodes(failCodes);
        setShowMatchingFailCodes(true);
    };

    const handleShowMatchingFailCodesFalse = () => {
        setShowMatchingFailCodes(false);
    };

    const returnToHome = () => {
        setContent('start');
    };

    const onHide = () => {
        setContent('start');

        props.onHide();
    };

    return (
        <Modal
            centered
            dialogClassName={classes.failCodeSearchModal}
            onHide={onHide}
            show={props.show}
            onEnter={handleEnter}
        >
            {content === 'start' && (
                <FailCodeStartModal
                    returnToHome={returnToHome}
                    onHide={onHide}
                    isLoading={isLoading}
                    showMatchingFailCodes={showMatchingFailCodes}
                    handleSetContent={handleSetContent}
                />
            )}
            {content === 'askApprover' && (
                <>
                    <Modal.Header closeButton placeholder={''}>
                        <Modal.Title>Fail Code</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="mb-2">
                            <Typography variant="h5">Ask Fail Code Approver for the Fail Code</Typography>
                        </div>
                        <Typography>
                            Confirm that you want to ask your Fail Code Approver to enter the fail code. Your QMR will
                            be completed by the Fail Code Approver after being submitted.
                        </Typography>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onPress={() => {
                                if (!qmr) {
                                    return;
                                }

                                patchQmr({
                                    qmrId: qmr?.qmrId,
                                    qmrsService,
                                    qmrDispatch,
                                    qmrPatch: {
                                        requestFailCodeApproval: true,
                                    },
                                    signal: abortSignalRef.current,
                                })
                                    .then((updatedQmr) => {
                                        if (updatedQmr) {
                                            onHide();
                                        }
                                    })
                                    .catch((e) => {
                                        alert(e.message);
                                    });
                            }}
                            variant="primary"
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </>
            )}
            {content === 'lookupBySection' && (
                <FailCodesLookupBySection
                    handleSuccess={props.handleSuccess}
                    returnToHome={returnToHome}
                    onHide={onHide}
                    show={props.show}
                />
            )}
            {content === 'searchFailCode' && (
                <FailCodeSearch handleSuccess={props.handleSuccess} returnToHome={returnToHome} onHide={onHide} />
            )}
            {content === 'searchFailCodeByPart' && (
                <FailCodePartSearch
                    handleSuccess={props.handleSuccess}
                    returnToHome={returnToHome}
                    onHide={onHide}
                    prefillPartNumber={prefillPartNumber}
                />
            )}
            {content === 'lookupByElectricalGridLocation' && (
                <FailCodeLookupElectricalGrid onHide={props.onHide} returnToHome={returnToHome} />
            )}
            {content === 'noTroubleFound' && (
                <FailCodeNoTroubleFound
                    handleSuccess={props.handleSuccess}
                    onHide={onHide}
                    returnToHome={returnToHome}
                    handleShowMatchingFailCodesTrue={handleShowMatchingFailCodesTrue}
                    handleShowMatchingFailCodesFalse={handleShowMatchingFailCodesFalse}
                />
            )}
            {content === 'accidentInvestigation' && (
                <FailCodeAccidentInvestigation
                    onHide={props.onHide}
                    returnToHome={returnToHome}
                    handleSuccess={props.handleSuccess}
                />
            )}
            {content === 'viewMatching' && (
                <FailCodeViewMatching
                    failCodes={failCodes}
                    returnToHome={returnToHome}
                    onHide={onHide}
                    handleSuccess={props.handleSuccess}
                />
            )}
        </Modal>
    );
};
