import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useWorksheetFormTranslation = () => {
    const { t } = useTranslation();

    const getModalsTranslate = useCallback(
        (
            target:
                | 'deleteWorksheet'
                | 'updateStatus'
                | 'requiredField'
                | 'commonBodyMessage'
                | 'deleteElement'
                | 'createOrUpdateWorksheet',
            path: string,
            variable?: { [x: string]: string }
        ) => {
            const base = 'worksheet:modals';
            return t(`${base}.${target}.${path}`, variable);
        },
        [t]
    );

    const modalDataTranslate = useMemo(() => {
        const data = {
            commonBodyMessage: {
                permission: {
                    create: getModalsTranslate('commonBodyMessage', 'permission.create'),
                    edit: getModalsTranslate('commonBodyMessage', 'permission.edit'),
                    updateStatus: getModalsTranslate('commonBodyMessage', 'permission.updateStatus'),
                    delete: getModalsTranslate('commonBodyMessage', 'permission.delete'),
                },
                somethingWrong: getModalsTranslate('commonBodyMessage', 'somethingWrong'),
            },
            deleteElement: {
                title: getModalsTranslate('deleteElement', 'title'),
                bodyMessage: (element: string) => getModalsTranslate('deleteElement', 'bodyMessage', { element }),
                confirmButton: getModalsTranslate('deleteElement', 'confirmButton'),
                cancelButton: getModalsTranslate('deleteElement', 'cancelButton'),
            },
            createOrUpdateWorksheet: {
                title: {
                    create: getModalsTranslate('createOrUpdateWorksheet', 'title.create'),
                    update: getModalsTranslate('createOrUpdateWorksheet', 'title.update'),
                },
                bodyMessage: {
                    succuss: {
                        create: getModalsTranslate('createOrUpdateWorksheet', 'bodyMessage.succuss.create'),
                        update: getModalsTranslate('createOrUpdateWorksheet', 'bodyMessage.succuss.update'),
                        addVersion: getModalsTranslate('createOrUpdateWorksheet', 'bodyMessage.succuss.addVersion'),
                    },
                },
            },
            requiredField: {
                title: getModalsTranslate('requiredField', 'title'),
                bodyMessage: {
                    worksheetTitle: getModalsTranslate('requiredField', 'bodyMessage.worksheetTitle'),
                    worksheetName: getModalsTranslate('requiredField', 'bodyMessage.worksheetName'),
                },
                confirmButton: getModalsTranslate('requiredField', 'confirmButton'),
            },
            updateStatus: {
                title: getModalsTranslate('updateStatus', 'title'),
                bodyMessage: (status: string) => getModalsTranslate('updateStatus', 'bodyMessage', { status }),
            },
            deleteWorksheet: {
                title: getModalsTranslate('deleteWorksheet', 'title'),
                bodyMessage: getModalsTranslate('deleteWorksheet', 'bodyMessage'),
            },
        };
        return data;
    }, [getModalsTranslate]);

    const getDataTranslate = useCallback(
        (
            target:
                | 'breadcrumbs'
                | 'view'
                | 'statusActions'
                | 'buttons'
                | 'adminFormList'
                | 'adminFormBuilder'
                | 'tabs'
                | 'filter',
            path: string
        ) => {
            const base = `worksheet:${target}`;
            return t(`${base}.${path}`);
        },
        [t]
    );

    const dataTranslate = useMemo(() => {
        const data = {
            breadcrumbs: {
                manageWorksheet: getDataTranslate('breadcrumbs', 'manageWorksheet'),
                worksheetForm: getDataTranslate('breadcrumbs', 'worksheetForm'),
                newWorksheetForm: getDataTranslate('breadcrumbs', 'newWorksheetForm'),
                editWorksheetForm: getDataTranslate('breadcrumbs', 'editWorksheetForm'),
                worksheet: getDataTranslate('breadcrumbs', 'worksheet'),
                worksheetDetails: getDataTranslate('breadcrumbs', 'worksheetDetails'),
            },
            statusActions: {
                addVersion: getDataTranslate('statusActions', 'addVersion'),
                sendToPilot: getDataTranslate('statusActions', 'sendToPilot'),
                archived: getDataTranslate('statusActions', 'archived'),
                publish: getDataTranslate('statusActions', 'publish'),
            },
            adminFormList: {
                title: getDataTranslate('adminFormList', 'title'),
                actions: {
                    addVersion: getDataTranslate('adminFormList', 'actions.addVersion'),
                    edit: getDataTranslate('adminFormList', 'actions.edit'),
                },
                headers: {
                    name: getDataTranslate('adminFormList', 'headers.name'),
                    caseType: getDataTranslate('adminFormList', 'headers.caseType'),
                    status: getDataTranslate('adminFormList', 'headers.status'),
                    lastUpdate: getDataTranslate('adminFormList', 'headers.lastUpdate'),
                    creator: getDataTranslate('adminFormList', 'headers.creator'),
                    updated: getDataTranslate('adminFormList', 'headers.updated'),
                    lastEditor: getDataTranslate('adminFormList', 'headers.lastEditor'),
                },
                buttons: {
                    saveAndExit: getDataTranslate('adminFormList', 'buttons.saveAndExit'),
                    save: getDataTranslate('adminFormList', 'buttons.save'),
                    publish: getDataTranslate('adminFormList', 'buttons.publish'),
                },
            },
            adminFormBuilder: {
                dropArea: getDataTranslate('adminFormBuilder', 'dropArea'),
                title: getDataTranslate('adminFormBuilder', 'title'),
                inputs: {
                    formTitle: {
                        label: getDataTranslate('adminFormBuilder', 'inputs.formTitle.label'),
                        placeholder: getDataTranslate('adminFormBuilder', 'inputs.formTitle.placeholder'),
                    },
                    formName: {
                        label: getDataTranslate('adminFormBuilder', 'inputs.formName.label'),
                        placeholder: getDataTranslate('adminFormBuilder', 'inputs.formName.placeholder'),
                    },
                    formType: {
                        label: getDataTranslate('adminFormBuilder', 'inputs.formType.label'),
                        options: {
                            qmr: getDataTranslate('adminFormBuilder', 'inputs.options.qmr'),
                            entity: getDataTranslate('adminFormBuilder', 'inputs.options.entity'),
                            person: getDataTranslate('adminFormBuilder', 'inputs.options.person'),
                        },
                    },
                    active: {
                        label: getDataTranslate('adminFormBuilder', 'inputs.active.label'),
                    },
                },
            },
            tabs: {
                all: {
                    tabLabel: getDataTranslate('tabs', 'all.tabLabel'),
                },
                pending: {
                    tabLabel: getDataTranslate('tabs', 'pending.tabLabel'),
                },
                pilot: {
                    tabLabel: getDataTranslate('tabs', 'pilot.tabLabel'),
                },
                published: {
                    tabLabel: getDataTranslate('tabs', 'published.tabLabel'),
                },
                archived: {
                    tabLabel: getDataTranslate('tabs', 'archived.tabLabel'),
                },
            },
            view: {
                searchPlaceholder: getDataTranslate('view', 'searchPlaceholder'),
                actions: {
                    filterToggle: {
                        active: getDataTranslate('view', 'actions.filterToggle.active'),
                        inactive: getDataTranslate('view', 'actions.filterToggle.inactive'),
                    },
                },
            },
            filter: {
                title: getDataTranslate('filter', 'title'),
                status: getDataTranslate('filter', 'status'),
                lastUpdate: getDataTranslate('filter', 'lastUpdate'),
                createdDate: getDataTranslate('filter', 'createdDate'),
                apply: getDataTranslate('filter', 'apply'),
                clearAll: getDataTranslate('filter', 'clearAll'),
            },
        };
        return data;
    }, [getDataTranslate]);

    return { modalDataTranslate, dataTranslate };
};

export default useWorksheetFormTranslation;
