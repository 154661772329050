/* eslint-disable react-native/no-inline-styles */
import { cloneDeep } from 'lodash';
import React, { ChangeEvent, FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Platform, StyleProp, TextStyle, ActivityIndicator } from 'react-native';
import { InputPickerHelper } from '../../../apps/web/src/administration/components/worksheet/inputPickers/input-picker-helper';
import { WorksheetCharacterLengthValidation } from '../../../apps/web/src/administration/views/worksheets/utils';
import colors from '@packages/core/styles/colors';
import {
    DraggableWorksheetFormComponent,
    FormAnswerSelectedOption,
    FormElementOption,
    FormElementTypeId,
    SelectedOption,
    WorksheetAnswerElement,
    FormElementFile,
    AssetProcessingStateId,
    WorksheetElementAnswers,
} from '@packages/models/api';
import { DropResult } from 'react-beautiful-dnd';
import {
    TextInputHelper,
    TextareaHelper,
    Typography,
    Icon,
    Range,
    Collapse,
    StsIconName,
    TextBadge,
    SuccessFailBadge,
    Button,
} from '../shared';
import { ConditionalOptions } from './conditional-options';
import Select from '../../../apps/web/src/components/select';
import { useValidation } from './useValidation';
import { useAnswerHandle } from './useAnswerHandle';
import { BaseWorksheetFormElement } from './base-worksheet-form-element';
import { AssetEditorModal } from './AssetEditorModal';

export type WorksheetFormElementComponent = FC<{
    item: DraggableWorksheetFormComponent;
    readOnly?: boolean;
    preview?: boolean;
    onAnswerChange?: (answer: WorksheetElementAnswers) => void;
    handleDragEnd: (result: DropResult) => void;
    onEditClick?(item: DraggableWorksheetFormComponent): void;
    onDeleteClick?(item: DraggableWorksheetFormComponent): void;
    onCopyClick?(item: DraggableWorksheetFormComponent): void;
    onChildDrag?(item: DraggableWorksheetFormComponent): void;
    handleIsValid?: (valid: boolean) => void;
    handleAnswerChange?: (formelementId: string, selectedAnswer: FormAnswerSelectedOption[]) => void;
    getCurrentAnswerByElementId?: (id: string) => WorksheetElementAnswers;
}>;

type WorksheetFormFileFieldProps = FC<{
    formElementFile?: FormElementFile;
    onChange?: (file: File | undefined, overlay: boolean) => void;
    showDelete?: boolean;
    onDelete?: () => void;
    editable?: boolean;
}>;

type FileSelectorProps = FC<{
    iconName?: StsIconName;
    onChange?: (data: File | undefined) => void;
    accept?: string;
}>;
const FileSelector: FileSelectorProps = ({ onChange, iconName = 'image', accept = 'image/*' }) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const triggerShowPicker = () => {
        if (fileRef.current) {
            fileRef.current.showPicker();
        }
    };

    const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0];
        onChange?.(file);
        if (fileRef.current) {
            fileRef.current.value = '';
        }
    };

    return (
        <>
            <input ref={fileRef} type="file" accept={accept} style={{ display: 'none' }} onChange={onChangeFile} />
            <Button variant="ghost-blue" size="small">
                <Icon
                    name={iconName}
                    onPress={() => {
                        triggerShowPicker();
                    }}
                />
            </Button>
        </>
    );
};
const WorksheetFormFileField: WorksheetFormFileFieldProps = ({
    formElementFile,
    onChange,
    showDelete,
    onDelete,
    editable = true,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const onEditImage = useCallback(
        (file: File | undefined, overlay: boolean = false) => {
            onChange?.(file, overlay);
        },
        [onChange]
    );
    const isUrlPresent = useMemo(() => {
        return formElementFile?.overlay?.url || formElementFile?.url;
    }, [formElementFile]);

    const isShowDelete = useMemo(() => {
        return formElementFile?.file || formElementFile?.overlay?.file;
    }, [formElementFile]);
    const isUploading = useMemo(() => {
        return formElementFile?.status === AssetProcessingStateId.UPLOADING;
    }, [formElementFile]);

    const computedStyle = {
        ...styles.imageContainer,
        ...((isUrlPresent && isLoading) || isUploading
            ? { border: `1px solid ${colors.grayFive}`, width: 200, height: 200 }
            : {}),
    };
    return (
        <div style={computedStyle}>
            {formElementFile?.status === AssetProcessingStateId.UPLOADING && (
                <ActivityIndicator style={{ margin: 10 }} color={colors.blueOne} size="large" />
            )}

            {isUrlPresent && (
                <>
                    {isLoading && <ActivityIndicator style={{ margin: 10 }} color={colors.blueOne} size="large" />}
                    <img
                        src={formElementFile?.overlay?.url || formElementFile?.url}
                        style={{
                            width: '100%',
                            maxHeight: 200,
                            objectFit: 'contain',
                            display: isLoading ? 'none' : 'block',
                        }}
                        onMouseEnter={() => {
                            setShowEdit(true);
                        }}
                        onLoad={() => {
                            setIsLoading(false);
                        }}
                    />
                    {showEdit && editable && (
                        <div
                            onMouseLeave={() => {
                                setShowEdit(false);
                            }}
                            className="gap-5"
                            style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                backgroundColor: 'rgba(248, 248, 248,0.6)',
                            }}
                        >
                            <Icon name="pencil" onPress={() => setShowEditor(true)} />
                            {showDelete && isShowDelete && editable && (
                                <Icon style={{ marginLeft: 10 }} name="trash" onPress={onDelete} />
                            )}
                        </div>
                    )}
                    {showEditor && (
                        <AssetEditorModal
                            formElementFile={formElementFile!}
                            viewOnly={false}
                            onClose={() => setShowEditor(false)}
                            onSave={(file) => onEditImage(file, true)}
                        />
                    )}
                </>
            )}
            {formElementFile?.caption && <Typography variant="lead">{formElementFile.caption}</Typography>}
        </div>
    );
};

const WorksheetLabel: FC<{
    label: string | undefined;
    required?: boolean | undefined;
    style?: StyleProp<TextStyle>;
}> = ({ label, required, style }) => {
    return (
        <>
            <Typography variant="label" style={[styles.labelText, style && style]}>
                {label}
                {required && <Typography color="redOne"> *</Typography>}
            </Typography>
        </>
    );
};

const WorksheetFormTextHeader: WorksheetFormElementComponent = ({ item: { formElementFile } }) => {
    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
        </>
    );
};

type FileActionsProps = FC<{
    onChange?: (data: File | undefined) => void;
    file?: FormElementFile;
}>;
const Actions: FileActionsProps = ({ onChange, file }) => {
    const aRef = useRef<HTMLAnchorElement>(null);
    const download = useCallback(() => {
        if (aRef.current && file?.name && file?.url) {
            aRef.current.href = file.url;
            aRef.current.download = file?.name;
            aRef.current.click();
        }
    }, [file]);

    const isLocalFile = useMemo(() => {
        return file?.url ? file.url.includes('localhost') : true;
    }, [file]);

    return (
        <>
            <a ref={aRef} style={{ display: 'none' }} />
            <FileSelector iconName="upload" accept="*" onChange={onChange} />
            <Button variant="ghost-blue" size="small" onPress={download} disabled={!file?.url}>
                <Icon name="download" />
            </Button>
            <Button
                variant="ghost-blue"
                size="small"
                onPress={() => onChange?.(undefined)}
                disabled={!file?.url || !isLocalFile}
            >
                <Icon name="x-close" />
            </Button>
        </>
    );
};

//File element
const WorksheetFormFile: WorksheetFormElementComponent = ({
    item: { formElementFile, formElementId, options, required },
    onAnswerChange,
    getCurrentAnswerByElementId,
    handleIsValid,
}) => {
    const imageName = formElementFile?.name;
    const url = formElementFile?.url;

    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });

    useEffect(() => {
        const ans = getCurrentAnswerByElementId?.(formElementId);
        setAnswerAsset(ans?.asset?.answerFile);
    }, []);

    const [answerAssets, setAnswerAsset] = useState<FormElementFile>();

    const onChange = useCallback(
        (file: File | undefined) => {
            console.log('check options', options);
            const fileObj = {
                file,
                url: file ? URL.createObjectURL(file) : undefined,
                name: file?.name,
            };
            onAnswerChange?.({
                asset: {
                    answerFile: fileObj,
                },
                elementId: formElementId,
                selectedOptions: [],
            });
            handleValidation({ currentValue: fileObj.url || '', prevValue: answerAssets?.url || '' });
            setAnswerAsset(fileObj);
        },
        [answerAssets?.url, formElementId, handleValidation, onAnswerChange, options]
    );

    return (
        <>
            {(imageName || formElementFile?.caption) && (
                <>
                    <Typography variant="lead" style={{ textAlign: 'left' }}>
                        <a href={url} target="_blank" download={imageName}>
                            {imageName}
                        </a>
                    </Typography>
                    <Typography variant="lead" style={{ textAlign: 'left' }}>
                        {formElementFile?.caption}
                    </Typography>
                </>
            )}
            <TextInputHelper
                placeholder={answerAssets?.name || 'Select File or Image'}
                rightIcon={<Actions onChange={onChange} file={answerAssets} />}
                editable={false}
                inputContainerStyle={{ backgroundColor: '#fff', marginTop: 10 }}
                errorMessage={errorMessage}
            />
        </>
    );
};

const WorksheetFormTextParagraph: WorksheetFormElementComponent = ({ item: { formElementFile } }) => {
    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
        </>
    );
};

const WorksheetFormInputText: WorksheetFormElementComponent = ({
    item: { formElementId, formElementFile, options, selectedOptions, required },
    onAnswerChange,
    readOnly,
    handleIsValid,
    handleAnswerChange,
    getCurrentAnswerByElementId,
}) => {
    useEffect(() => {
        const ans = getCurrentAnswerByElementId?.(formElementId);
        setAnswerAsset(ans?.asset?.answerFile);
    }, []);

    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });
    const { handleAnswer } = useAnswerHandle({ handleAnswerChange, onAnswerChange });
    const [value, setValue] = useState(selectedOptions && selectedOptions[0] ? selectedOptions[0].answerText : '');
    const [answerAssets, setAnswerAsset] = useState<FormElementFile>();
    const getSelectedOptionAns = useCallback(() => {
        return [
            {
                optionId: options[0].optionId,
                answerText: value || '',
                optionLabel: '',
            },
        ];
    }, [options, value]);

    const onDeleteAsset = useCallback(() => {
        const prevAns = getCurrentAnswerByElementId?.(formElementId);
        const answerFile = { file: undefined, url: undefined, name: undefined };
        onAnswerChange?.({
            ...prevAns,
            asset: {
                answerFile,
            },
            elementId: formElementId,
            selectedOptions: getSelectedOptionAns(),
        });
        setAnswerAsset(answerFile);
    }, [formElementId, getCurrentAnswerByElementId, getSelectedOptionAns, onAnswerChange]);

    const onChange = useCallback(
        (file: File | undefined, overlay: boolean = false) => {
            const prevAns = getCurrentAnswerByElementId?.(formElementId);
            const fileObj = {
                file,
                url: file ? URL.createObjectURL(file) : undefined,
                name: file?.name,
            };
            let answerFile = {
                ...prevAns?.asset?.answerFile,
            };
            if (overlay) {
                answerFile.overlay = fileObj;
            } else {
                answerFile = { ...fileObj };
            }
            onAnswerChange?.({
                ...prevAns,
                asset: {
                    answerFile,
                },
                elementId: formElementId,
                selectedOptions: getSelectedOptionAns(),
            });
            setAnswerAsset(answerFile);
        },
        [answerAssets?.overlay, formElementId, getCurrentAnswerByElementId, getSelectedOptionAns, onAnswerChange]
    );

    return (
        <>
            {[options?.[options?.length - 1]].map((option) => {
                return (
                    <View key={option.optionId}>
                        <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
                        {answerAssets && (
                            <div className="mb-5 mt-5">
                                <WorksheetFormFileField
                                    formElementFile={answerAssets}
                                    onChange={(file) => onChange(file, true)}
                                    onDelete={onDeleteAsset}
                                    showDelete={true}
                                />
                            </div>
                        )}
                        <TextInputHelper
                            key={option.optionId}
                            required={required}
                            value={value}
                            editable={!readOnly}
                            errorMessage={errorMessage}
                            onChangeText={(text) => {
                                if (text.trim().length > WorksheetCharacterLengthValidation.AnswerLength) {
                                    return;
                                }
                                handleValidation({ currentValue: text, prevValue: value || '' });
                                const answerOption = [
                                    {
                                        optionId: option.optionId,
                                        answerText: text,
                                        optionLabel: option.optionLabel,
                                    },
                                ];
                                const prevAns = getCurrentAnswerByElementId?.(formElementId);
                                handleAnswer(formElementId, answerOption, prevAns?.asset?.answerFile);
                                setValue(text);
                            }}
                            placeholder={option.optionPlaceholder}
                            rightIcon={<FileSelector onChange={onChange} />}
                        />
                    </View>
                );
            })}
        </>
    );
};

const WorksheetFormInputTextarea: WorksheetFormElementComponent = ({
    item: { formElementId, options, selectedOptions, formElementFile, required },
    onAnswerChange,
    readOnly,
    handleIsValid,
    handleAnswerChange,
    getCurrentAnswerByElementId,
}) => {
    useEffect(() => {
        const ans = getCurrentAnswerByElementId?.(formElementId);
        setAnswerAsset(ans?.asset?.answerFile);
    }, []);

    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });
    const { handleAnswer } = useAnswerHandle({ handleAnswerChange, onAnswerChange });

    const [value, setValue] = useState(selectedOptions && selectedOptions[0] ? selectedOptions[0].answerText : '');
    const [answerAssets, setAnswerAsset] = useState<FormElementFile>();

    const getSelectedOptionAns = useCallback(() => {
        return [
            {
                optionId: options[0].optionId,
                answerText: value || '',
                optionLabel: '',
            },
        ];
    }, [options, value]);

    const onDeleteAsset = useCallback(() => {
        const prevAns = getCurrentAnswerByElementId?.(formElementId);

        onAnswerChange?.({
            ...prevAns,
            asset: {
                answerFile: { file: undefined, url: undefined, name: undefined },
            },
            elementId: formElementId,
            selectedOptions: getSelectedOptionAns(),
        });
    }, [formElementId, getCurrentAnswerByElementId, getSelectedOptionAns, onAnswerChange]);

    const onChange = useCallback(
        (file: File | undefined, overlay: boolean = false) => {
            const prevAns = getCurrentAnswerByElementId?.(formElementId);
            const fileObj = {
                file,
                url: file ? URL.createObjectURL(file) : undefined,
                name: file?.name,
            };
            let answerFile = {
                ...prevAns?.asset?.answerFile,
            };
            if (overlay) {
                answerFile.overlay = fileObj;
            } else {
                answerFile = { ...fileObj, overlay: { ...answerAssets?.overlay } };
            }

            onAnswerChange?.({
                ...prevAns,
                asset: {
                    answerFile,
                },
                elementId: formElementId,
                selectedOptions: getSelectedOptionAns(),
            });
            setAnswerAsset(answerFile);
        },
        [answerAssets?.overlay, formElementId, getCurrentAnswerByElementId, getSelectedOptionAns, onAnswerChange]
    );

    return (
        <>
            {[options?.[options?.length - 1]].map((option) => {
                return (
                    <View key={option.optionId}>
                        <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
                        {answerAssets && (
                            <div className="mb-5 mt-5">
                                <WorksheetFormFileField
                                    formElementFile={answerAssets}
                                    onChange={(file) => onChange(file, true)}
                                    onDelete={onDeleteAsset}
                                    showDelete={true}
                                />
                            </div>
                        )}
                        <TextareaHelper
                            key={option.optionId}
                            value={value}
                            required={required}
                            editable={!readOnly}
                            errorMessage={errorMessage}
                            onChangeText={(text) => {
                                if (text.trim().length > WorksheetCharacterLengthValidation.AnswerLength) {
                                    return;
                                }
                                handleValidation({ currentValue: text, prevValue: value || '' });
                                const answerOption = [
                                    {
                                        optionId: option.optionId,
                                        optionLabel: option.optionLabel,
                                        answerText: text,
                                    },
                                ];
                                const prevAns = getCurrentAnswerByElementId?.(formElementId);
                                setValue(text);
                                handleAnswer(formElementId, answerOption, prevAns?.asset?.answerFile);
                            }}
                            placeholder={option.optionPlaceholder}
                            buttonElement={
                                <div className="d-flex justify-content-end p-1">
                                    <FileSelector onChange={onChange} />
                                </div>
                            }
                        />
                    </View>
                );
            })}
        </>
    );
};
const WorksheetTime: WorksheetFormElementComponent = ({
    item: { options, formElementFile, formElementId, selectedOptions, required },
    readOnly,
    preview,
    onAnswerChange,
    handleIsValid,
    handleAnswerChange,
}) => {
    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });
    const { handleAnswer } = useAnswerHandle({ handleAnswerChange, onAnswerChange });

    const [value, setValue] = useState<string>('');

    useEffect(() => {
        const selectedValue = selectedOptions?.[0]?.answerText;
        setValue(selectedValue || '');
    }, [selectedOptions]);
    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
            <InputPickerHelper
                type={'time'}
                iconName={'clock'}
                data={value}
                errorMessage={errorMessage}
                onChange={(time: string) => {
                    handleValidation({ currentValue: time, prevValue: value });
                    setValue(time);
                    const answerOption = [
                        {
                            optionId: options?.[0].optionId,
                            optionLabel: options?.[0].optionLabel,
                            answerText: time,
                        },
                    ];
                    handleAnswer?.(formElementId, answerOption);
                }}
                iconDisable={readOnly || !preview}
            />
        </>
    );
};
const WorksheetDateTime: WorksheetFormElementComponent = ({
    item: { options, formElementFile, formElementId, selectedOptions, required },
    readOnly,
    preview,
    onAnswerChange,
    handleIsValid,
}) => {
    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });

    const [value, setValue] = useState<string>('');

    useEffect(() => {
        const selectedValue = selectedOptions?.[0]?.answerText;
        setValue(selectedValue || '');
    }, [selectedOptions]);
    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
            <InputPickerHelper
                type={'dateTime'}
                iconName={'calendar'}
                data={value}
                errorMessage={errorMessage}
                onChange={(dateTime: string) => {
                    handleValidation({ currentValue: dateTime, prevValue: value });
                    setValue(dateTime);
                    onAnswerChange?.({
                        elementId: formElementId,
                        selectedOptions: [
                            {
                                optionId: options?.[0].optionId,
                                optionLabel: options?.[0].optionLabel,
                                answerText: dateTime,
                            },
                        ],
                    });
                }}
                iconDisable={readOnly || !preview}
            />
        </>
    );
};

const WorksheetFormDate: WorksheetFormElementComponent = ({
    item: { options, formElementFile, required, formElementId, selectedOptions },
    readOnly,
    preview,
    onAnswerChange,
    handleIsValid,
    handleAnswerChange,
}) => {
    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });
    const { handleAnswer } = useAnswerHandle({ handleAnswerChange, onAnswerChange });
    const [value, setValue] = useState<string>('');
    useEffect(() => {
        const selectedValue = selectedOptions?.[0]?.answerText;
        setValue(selectedValue || '');
    }, [selectedOptions]);
    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
            <InputPickerHelper
                type={'date'}
                iconName={'calendar'}
                data={value}
                errorMessage={errorMessage}
                onChange={(date: string) => {
                    handleValidation({ currentValue: date, prevValue: value });
                    setValue(date);
                    const answer = [
                        {
                            optionId: options?.[0].optionId,
                            optionLabel: options?.[0].optionLabel,
                            answerText: date,
                        },
                    ];
                    handleAnswer(formElementId, answer);
                }}
                iconDisable={!preview || readOnly}
            />
        </>
    );
};

const WorksheetFormRating: WorksheetFormElementComponent = ({
    item: { formElementId, options, formElementFile, selectedOptions, required },
    onAnswerChange,
    readOnly,
    handleIsValid,
    handleAnswerChange,
}) => {
    const { handleAnswer } = useAnswerHandle({ handleAnswerChange, onAnswerChange });
    const [ratingOptions] = useMemo(() => {
        return [
            options.map((option, index) => ({
                id: option.optionId,
                value: index,
                message: option.optionLabel,
            })),
        ];
    }, [options]);
    const selectedOptionIndex = useMemo(() => {
        return ratingOptions.findIndex((option) => option.id === selectedOptions?.[0]?.optionId);
    }, [ratingOptions, selectedOptions]);
    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });
    const [value, setValue] = useState(selectedOptionIndex !== -1 ? selectedOptionIndex + 1 : undefined);

    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
            <Range
                disabled={readOnly}
                value={value}
                values={ratingOptions}
                onChange={(newValue) => {
                    setValue(newValue);
                    const option = options.find((opt) => opt.optionId === ratingOptions[newValue - 1].id);
                    if (option) {
                        handleValidation({ currentValue: option?.optionId, prevValue: value?.toString() || '' });
                        const answer = [{ optionId: option.optionId, optionLabel: option.optionLabel, answerText: '' }];
                        handleAnswer(formElementId, answer);
                    }
                }}
            />
            <Typography color="redOne" style={{ textAlign: 'center' }}>
                {errorMessage}
            </Typography>
        </>
    );
};

const WorksheetFormRatingWithText: WorksheetFormElementComponent = ({
    item: { formElementId, selectedOptions = [], options, formElementFile, required },
    readOnly,
    onAnswerChange,
    handleIsValid,
    handleAnswerChange,
}) => {
    const { handleAnswer } = useAnswerHandle({ handleAnswerChange, onAnswerChange });
    const [ratingOptions, textareaOption] = useMemo(() => {
        const clone = cloneDeep(options);
        const lastOption = clone.pop();

        return [
            clone.map((option, index) => ({
                id: option.optionId,
                value: index,
                message: option.optionLabel,
            })),
            lastOption,
        ];
    }, [options]);

    const selectedOptionIndex = useMemo(() => {
        const selectedOption = selectedOptions.find((opt) => opt.optionId !== textareaOption?.optionId);
        return ratingOptions.findIndex((option) => option.id === selectedOption?.optionId);
    }, [ratingOptions, selectedOptions, textareaOption?.optionId]);

    const commentText = useMemo(() => {
        const option = selectedOptions.find((opt) => opt.optionId === textareaOption?.optionId);
        if (option) {
            return option.answerText;
        }
        return '';
    }, [selectedOptions, textareaOption?.optionId]);

    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });

    const [value, setValue] = useState(selectedOptionIndex !== -1 ? selectedOptionIndex + 1 : undefined);
    const [textValue, setTextValue] = useState<string>(commentText || '');
    const [isExpanded, setIsExpanded] = useState(commentText?.trim() === '' ? false : true);
    const [answerOptions, setAnswerOptions] = useState(selectedOptions);
    if (!ratingOptions || !textareaOption) {
        return null;
    }

    const handleRatingAnswer = (option: FormElementOption, text: string) => {
        let answerAnswerClone = cloneDeep(answerOptions);
        const textareaIndex = answerAnswerClone.findIndex((opt) => opt.optionId === textareaOption.optionId);
        const textarea = answerAnswerClone[textareaIndex];
        const answerObj = {
            optionId: option.optionId,
            optionLabel: option.optionLabel,
            answerText: text,
        };
        // If text area option then it available so replace other wise push.
        // Else rating option replace with old option then text area option available push.
        if (textareaOption.optionId !== option.optionId) {
            answerAnswerClone = [answerObj];
            if (textareaIndex > -1) {
                answerAnswerClone.push(textarea);
            }
            handleValidation({ currentValue: option?.optionId, prevValue: value?.toString() || '' });
        } else if (textareaIndex > -1) {
            answerAnswerClone[textareaIndex] = answerObj;
        } else {
            answerAnswerClone.push(answerObj);
        }
        handleAnswer(formElementId, answerAnswerClone as SelectedOption[]);
        setAnswerOptions(answerAnswerClone);
        onAnswerChange?.({
            elementId: formElementId,
            selectedOptions: answerAnswerClone as SelectedOption[],
        });
    };
    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />

            <Range
                disabled={readOnly}
                value={value}
                values={ratingOptions}
                onChange={(newValue) => {
                    setValue(newValue);

                    const option = options.find((o) => o.optionId === ratingOptions[newValue - 1].id);
                    if (option) {
                        handleRatingAnswer(option, '');
                    }
                }}
            />
            <Typography color="redOne" style={{ textAlign: 'center' }}>
                {errorMessage}
            </Typography>
            <>
                <TouchableOpacity style={styles.optionalSectionToggle} onPress={() => setIsExpanded(!isExpanded)}>
                    <WorksheetLabel label={textareaOption ? textareaOption.optionLabel : ''} />

                    <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} color="blueOne" />
                </TouchableOpacity>

                <Collapse open={isExpanded}>
                    <TextareaHelper
                        value={textValue}
                        onChangeText={(newValue) => {
                            if (newValue.trim().length > WorksheetCharacterLengthValidation.AnswerLength) {
                                return;
                            }
                            setTextValue(newValue);

                            if (textareaOption) {
                                handleRatingAnswer(textareaOption, newValue);
                            }
                        }}
                        placeholder={textareaOption ? textareaOption.optionPlaceholder : ''}
                    />
                </Collapse>
            </>
        </>
    );
};

const WorksheetFormCheckbox: WorksheetFormElementComponent = ({
    item,
    readOnly,
    preview,
    onAnswerChange,
    handleDragEnd,
    onEditClick,
    onChildDrag,
    onCopyClick,
    onDeleteClick,
    handleIsValid,
    handleAnswerChange,
    getCurrentAnswerByElementId,
}) => {
    const { options, selectedOptions, formElementFile } = item;
    const selectedOption = useMemo(() => {
        return options?.filter((option) => {
            const selectedOpt = selectedOptions?.find((opt) => opt.optionId === option.optionId);
            if (selectedOpt) {
                if (option.isOther) {
                    option.answerText = selectedOpt.answerText;
                }
                return option;
            }
        });
    }, [options, selectedOptions]);
    const [selectedValues, setSelectedValues] = useState<FormElementOption[]>(selectedOption ? selectedOption : []);

    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />

            <Options
                item={item}
                readOnly={readOnly}
                preview={preview}
                setSelectedValues={setSelectedValues}
                selectedValues={selectedValues}
                onAnswerChange={onAnswerChange}
                onChildDrag={onChildDrag}
                onCopyClick={onCopyClick}
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
                handleDragEnd={handleDragEnd}
                handleIsValid={handleIsValid}
                handleAnswerChange={handleAnswerChange}
                getCurrentAnswerByElementId={getCurrentAnswerByElementId}
            />
        </>
    );
};

const WorksheetFormRadio: WorksheetFormElementComponent = ({
    item,
    readOnly,
    onAnswerChange,
    handleDragEnd,
    onEditClick,
    onChildDrag,
    onCopyClick,
    onDeleteClick,
    preview,
    handleIsValid,
    handleAnswerChange,
    getCurrentAnswerByElementId,
}) => {
    const { options, formElementFile, selectedOptions } = item;
    const selectedOption = useMemo(() => {
        return options.filter((option) => {
            if (option.optionId === selectedOptions?.[0]?.optionId) {
                if (option.isOther) {
                    option.answerText = selectedOptions?.[0]?.answerText;
                }
                return option;
            }
        });
    }, [options, selectedOptions]);
    const [selectedValues, setSelectedValues] = useState<FormElementOption[]>(selectedOption ? selectedOption : []);
    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
            <Options
                item={item}
                readOnly={readOnly}
                preview={preview}
                setSelectedValues={setSelectedValues}
                selectedValues={selectedValues}
                onAnswerChange={onAnswerChange}
                onChildDrag={onChildDrag}
                onCopyClick={onCopyClick}
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
                handleDragEnd={handleDragEnd}
                handleIsValid={handleIsValid}
                handleAnswerChange={handleAnswerChange}
                getCurrentAnswerByElementId={getCurrentAnswerByElementId}
            />
        </>
    );
};
interface OptionsProps {
    item: DraggableWorksheetFormComponent;
    readOnly: boolean | undefined;
    preview: boolean | undefined;
    setSelectedValues: React.Dispatch<React.SetStateAction<FormElementOption[]>>;
    selectedValues: FormElementOption[];
    onAnswerChange?: (answer: WorksheetAnswerElement) => void;
    handleDragEnd: (result: DropResult) => void;
    onEditClick?(item: DraggableWorksheetFormComponent): void;
    onDeleteClick?(item: DraggableWorksheetFormComponent): void;
    onCopyClick?(item: DraggableWorksheetFormComponent): void;
    onChildDrag?(item: DraggableWorksheetFormComponent): void;
    handleIsValid?: (valid: boolean) => void;
    handleAnswerChange?: (formelementId: string, selectedAnswer: FormAnswerSelectedOption[]) => void;
    getCurrentAnswerByElementId?: (id: string) => WorksheetElementAnswers;
}
const Options: FC<OptionsProps> = ({
    item,
    readOnly,
    preview,
    setSelectedValues,
    onAnswerChange,
    selectedValues,
    onChildDrag,
    onCopyClick,
    onDeleteClick,
    onEditClick,
    handleDragEnd,
    handleIsValid,
    handleAnswerChange: handleConditionalElementAnswer,
    getCurrentAnswerByElementId,
}) => {
    const { options, optionConditionsElements, required, formElementId, formElementTypeId } = item;
    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });
    const iconName = formElementTypeId === FormElementTypeId.Radio ? 'radio' : 'checkbox';
    const [invalidConditionalElements, setInvalidConditionElements] = useState(0);
    const [optionConditionsElementsClone, setOptionConditionsElementsClone] = useState(optionConditionsElements);
    const { handleAnswer } = useAnswerHandle({ handleAnswerChange: handleConditionalElementAnswer, onAnswerChange });
    const otherOptionTextAnswer = useMemo(() => {
        const otherOption = options.find((opt) => opt.isOther);
        if (otherOption) {
            const selectedOtherOption = selectedValues.find((opt) => opt.optionId === otherOption.optionId);
            return selectedOtherOption?.answerText || '';
        } else {
            return '';
        }
    }, [options, selectedValues]);
    const [otherOptionText, setOtherOptionText] = useState(otherOptionTextAnswer || '');

    useEffect(() => {
        setOptionConditionsElementsClone(optionConditionsElements);
    }, [optionConditionsElements]);

    useEffect(() => {
        countInvalidCount(selectedValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionConditionsElements]);

    const countInvalidCount = (selectedOption: FormElementOption[]) => {
        if (selectedOption.length && optionConditionsElementsClone?.length) {
            const readOnlyFieldElementTypes = [FormElementTypeId.TextHeader, FormElementTypeId.TextParagraph];
            const inputFormElementTypes = [
                FormElementTypeId.InputText,
                FormElementTypeId.InputTextarea,
                FormElementTypeId.Date,
                FormElementTypeId.Time,
                FormElementTypeId.DateTime,
            ];
            const ratingWithTextArea = FormElementTypeId.RatingWithText;
            const optionId = selectedOption.map((selected) => selected.optionId);
            const countRequiredFields = optionConditionsElementsClone.reduce((count, element) => {
                // check selected option's conditional element
                if (
                    !optionId.includes(element.targetOptionId) ||
                    readOnlyFieldElementTypes.includes(element.formElementTypeId)
                ) {
                    return count;
                }
                if (element.required) {
                    if (element.formElementTypeId === FormElementTypeId.File) {
                        if (!element.answerAssets?.length) {
                            count++;
                        }
                    } else if (element.selectedOptions?.length) {
                        if (inputFormElementTypes.includes(element.formElementTypeId)) {
                            if (element.selectedOptions[0].answerText === '') {
                                return ++count;
                            }
                        }
                        // Check for rating with textarea because it have may be two selected options.
                        // One for selected rating option and second for textarea answer.
                        // Here we need to check is selected option only is textarea answer or not because is optional answer.
                        // It have must be one rating option.
                        else if (element.formElementTypeId === ratingWithTextArea) {
                            const textAreaOptionId = element.options[element.options.length - 1];
                            if (element.selectedOptions.length === 1) {
                                return element.selectedOptions[0].optionId === textAreaOptionId?.optionId
                                    ? ++count
                                    : count;
                            } else {
                                const selectedRatingOption = element.selectedOptions.find(
                                    (opt) => opt.optionId !== textAreaOptionId?.optionId
                                );
                                return selectedRatingOption?.optionLabel === '' ? ++count : count;
                            }
                        } else if (element.selectedOptions[0].optionLabel === '') {
                            return ++count;
                        }
                    } else {
                        count++;
                    }
                }
                return count;
            }, 0);

            if (countRequiredFields) {
                handleIsValid?.(false);
            }
            setInvalidConditionElements(countRequiredFields);
        }
    };

    const handleInvalidCount = (valid: boolean) => {
        let isRadioValid = false;
        let isChange = false;
        // When field is valid decrease one count in invalidCount or not a valid than increase one in inValidFiledCount
        let invalidCountClone = invalidConditionalElements;
        if (valid) {
            invalidCountClone--;
        } else {
            invalidCountClone++;
        }
        if (!(invalidConditionalElements && invalidCountClone) && handleIsValid) {
            if (invalidConditionalElements && !invalidCountClone) {
                isRadioValid = true;
            }

            isChange = true;
        }

        setInvalidConditionElements(invalidCountClone);

        if (isChange && handleIsValid) {
            handleIsValid(isRadioValid);
        }
    };

    console.log('check invalid in oprions conditionall====>', invalidConditionalElements);

    // Handle when change selected option in optionConditional element.
    const handleAnswerChange = (id: string, selectedAnswer: FormAnswerSelectedOption[]) => {
        const clone = cloneDeep(optionConditionsElementsClone);
        clone?.forEach((ele) => {
            if (ele.formElementId === id) {
                ele.selectedOptions = selectedAnswer;
            }
        });

        setOptionConditionsElementsClone(clone);
    };

    // Handle parent onAnswerChange or handleAnswer
    const handleOnAnswerChange = (selectedOptions: FormElementOption[]) => {
        const answer = selectedOptions.map((opt) => ({
            optionId: opt.optionId,
            optionLabel: opt.optionLabel,
            answerText: opt.isOther ? otherOptionText : '',
        }));
        handleAnswer(formElementId, answer);
        setSelectedValues(selectedOptions);
    };
    return (
        <>
            {options.map((option: FormElementOption) => {
                const isOptionSelected = !!selectedValues?.find(
                    (selectedOption) => selectedOption?.optionId === option.optionId
                );
                const optionConditions =
                    optionConditionsElementsClone?.filter((element) => element.targetOptionId === option.optionId) ||
                    [];
                const conditions: string = optionConditions?.map((element) => element.formElementOrder).join('-');
                return (
                    <Fragment key={option.optionId}>
                        <View
                            style={[preview ? { marginBottom: 8 } : styles.inputContainer, { flexDirection: 'row' }]}
                            key={option.optionId}
                        >
                            <TouchableOpacity
                                disabled={readOnly}
                                style={[
                                    styles.selectOptionWrapper,
                                    {
                                        justifyContent: option.isOther ? 'flex-start' : 'space-between',
                                        flexWrap: option.isOther ? 'wrap' : 'nowrap',
                                    },
                                ]}
                            >
                                <div
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        let selectedValuesClone = cloneDeep(selectedValues);
                                        // store selected option length for validation, it is length before any changes in selectedValues
                                        const selectedOptionLength = selectedValues.length;
                                        if (item.formElementTypeId === FormElementTypeId.Checkbox) {
                                            // If already selected checkbox
                                            if (isOptionSelected) {
                                                selectedValuesClone = selectedValuesClone.filter(
                                                    (selectedOption) => selectedOption?.optionId !== option.optionId
                                                );
                                            } else {
                                                selectedValuesClone.push(option);
                                            }
                                        } else {
                                            selectedValuesClone = [option];
                                        }

                                        handleValidation({
                                            currentValue: selectedValuesClone.length
                                                ? selectedValuesClone.length.toString()
                                                : '',
                                            prevValue: selectedOptionLength ? selectedOptionLength.toString() : '',
                                        });
                                        if (invalidConditionalElements) {
                                            handleIsValid?.(true);
                                        }
                                        countInvalidCount(selectedValuesClone);
                                        handleOnAnswerChange(selectedValuesClone);
                                    }}
                                >
                                    <Icon
                                        size={28}
                                        name={
                                            (isOptionSelected
                                                ? `${iconName}-marked`
                                                : `${iconName}-unmarked`) as StsIconName
                                        }
                                        color={selectedValues ? 'blueOne' : 'grayFive'}
                                    />
                                    <WorksheetLabel
                                        label={`${option.optionLabel} ${option.isOther && isOptionSelected ? ':' : ''}`}
                                        style={{ marginLeft: 8, marginBottom: 0 }}
                                    />
                                </div>
                                {option.isOther && isOptionSelected ? (
                                    <input
                                        style={{ border: 0, borderBottom: '1px solid black', minWidth: '90%' }}
                                        value={otherOptionText}
                                        maxLength={WorksheetCharacterLengthValidation.AnswerLength}
                                        onChange={(e) => {
                                            const text = e.target.value;
                                            const selectedValueClone = cloneDeep(selectedValues);
                                            selectedValueClone.forEach((opt) => {
                                                if (opt.isOther) {
                                                    opt.answerText = text;
                                                }
                                            });
                                            handleOnAnswerChange(selectedValueClone);
                                            setOtherOptionText(text);
                                        }}
                                        readOnly={readOnly}
                                    />
                                ) : (
                                    <></>
                                )}
                                {!preview && option?.trgtElementIdList && option?.trgtElementIdList?.length > 0 && (
                                    <TextBadge variant="blue" textStyle={{ color: colors.blueOneActive }}>
                                        Conditional {conditions}
                                    </TextBadge>
                                )}
                            </TouchableOpacity>
                            {option.optionFile && (
                                <div style={{ marginLeft: 10 }}>
                                    <WorksheetFormFileField formElementFile={option.optionFile} editable={false} />
                                </div>
                            )}
                        </View>
                        {preview &&
                            isOptionSelected &&
                            optionConditionsElementsClone &&
                            optionConditionsElementsClone.length > 0 && (
                                <>
                                    <div style={{ marginLeft: 36 }}>
                                        {optionConditionsElementsClone?.map((element) => {
                                            if (element.targetOptionId === option.optionId) {
                                                return (
                                                    <BaseWorksheetFormElement
                                                        item={element}
                                                        onAnswerChange={onAnswerChange}
                                                        handleDragEnd={handleDragEnd}
                                                        key={element.formElementId}
                                                        readOnly={readOnly}
                                                        preview={preview}
                                                        handleIsValid={handleInvalidCount}
                                                        handleAnswerChange={handleAnswerChange}
                                                        getCurrentAnswerByElementId={getCurrentAnswerByElementId}
                                                    />
                                                );
                                            }
                                        })}
                                    </div>
                                </>
                            )}

                        {!preview && optionConditionsElements && optionConditionsElements.length > 0 && (
                            <ConditionalOptions
                                parentItem={item}
                                handleDragEnd={handleDragEnd}
                                onChildDrag={onChildDrag}
                                onCopyClick={onCopyClick}
                                onDeleteClick={onDeleteClick}
                                onEditClick={onEditClick}
                                optionConditionsElements={optionConditionsElements}
                                option={option}
                            />
                        )}
                    </Fragment>
                );
            })}
            <Typography color="redOne" style={{ textAlign: 'center' }}>
                {errorMessage}
            </Typography>
        </>
    );
};

const WorksheetDropdown: WorksheetFormElementComponent = ({
    item,
    onAnswerChange,
    readOnly,
    handleIsValid,
    handleAnswerChange,
}) => {
    const { options, selectedOptions, formElementFile, required, formElementId } = item;
    const { handleValidation, errorMessage } = useValidation({ handleIsValid, required });
    const { handleAnswer } = useAnswerHandle({ handleAnswerChange, onAnswerChange });

    const [value, setValue] = useState<string>(selectedOptions?.[0]?.optionId || '');

    return (
        <>
            <WorksheetFormFileField formElementFile={formElementFile} editable={false} />
            {readOnly ? (
                <div className="d-flex mt-2 mb-8">
                    <SuccessFailBadge success>{selectedOptions?.[0]?.optionLabel}</SuccessFailBadge>
                </div>
            ) : (
                <div className="mt-2 mb-8">
                    <Select
                        required
                        value={value}
                        options={
                            options
                                ? [
                                      { title: 'Select...', value: '', disabled: true },
                                      ...options.map((option) => ({
                                          title: option.optionLabel,
                                          value: option.optionId,
                                      })),
                                  ]
                                : []
                        }
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            const newVal = event.target.value;
                            const option = options.find((opt) => opt.optionId === newVal);
                            if (option) {
                                handleValidation({ currentValue: newVal, prevValue: value });
                                setValue(newVal);
                                const answer = [
                                    {
                                        optionId: option.optionId,
                                        optionLabel: option.optionLabel,
                                        answerText: '',
                                    },
                                ];
                                handleAnswer(formElementId, answer);
                            }
                        }}
                    />
                </div>
            )}
            <Typography color="redOne" style={{ textAlign: 'center' }}>
                {errorMessage}
            </Typography>
        </>
    );
};
export const WorksheetFormElements = {
    [FormElementTypeId.TextHeader]: WorksheetFormTextHeader,
    [FormElementTypeId.TextParagraph]: WorksheetFormTextParagraph,
    [FormElementTypeId.InputText]: WorksheetFormInputText,
    [FormElementTypeId.InputTextarea]: WorksheetFormInputTextarea,
    [FormElementTypeId.Rating]: WorksheetFormRating,
    [FormElementTypeId.RatingWithText]: WorksheetFormRatingWithText,
    [FormElementTypeId.Checkbox]: WorksheetFormCheckbox,
    [FormElementTypeId.Radio]: WorksheetFormRadio,
    [FormElementTypeId.File]: WorksheetFormFile,
    [FormElementTypeId.Date]: WorksheetFormDate,
    [FormElementTypeId.Time]: WorksheetTime,
    [FormElementTypeId.DateTime]: WorksheetDateTime,
    [FormElementTypeId.Dropdown]: WorksheetDropdown,
};

const styles = StyleSheet.create({
    imageContainer: {
        maxWidth: 200,
        // maxHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
    },
    labelText: {
        marginBottom: 8,
        wordBreak: 'break-word',
    },
    optionalSectionToggle: {
        marginTop: 8,
        minHeight: 48,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selectOptionWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '77%',
        wordBreak: 'break-all',
        alignContent: 'center',
    },
    inputContainer: {
        minHeight: Platform.select({ web: 40, default: 48 }),
        paddingHorizontal: 12,
        paddingVertical: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.grayThree,
        marginVertical: 5,
        border: `1px solid ${colors.grayFour}`,
    },
});
