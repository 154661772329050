import React, { FC } from 'react';
import { View, StyleProp, ViewStyle, StyleSheet, TouchableOpacity } from 'react-native';

import colors from '@packages/core/styles/colors';
import { Typography, Icon } from '../shared';

interface QmrListItemProps {
    style?: StyleProp<ViewStyle>;
    asset?: JSX.Element;
    title: string;
    identifier: string;
    retailerName: string;
    retailerCode: string;
    created: string;
    showDeleteIcon?: boolean;
    onPress?(): void;
    onDeletePress?(): void;
}

export const QmrListItem: FC<QmrListItemProps> = ({
    style,
    asset,
    title,
    identifier,
    retailerName,
    retailerCode,
    created,
    showDeleteIcon,
    onPress,
    onDeletePress,
}) => (
    <TouchableOpacity style={[styles.qmrListItem, style]} onPress={onPress}>
        {showDeleteIcon && (
            <View style={styles.trashIconOuter}>
                <TouchableOpacity onPress={onDeletePress} style={styles.iconOuter}>
                    <Icon name="trash" />
                </TouchableOpacity>
            </View>
        )}
        {asset && <View style={styles.assetOuter}>{asset}</View>}
        <View style={styles.informationOuter}>
            <Typography style={styles.vehicleTitle} variant="h5" numberOfLines={1}>
                {title}
            </Typography>
            <Typography style={styles.qmrIdentifier} variant="caption">
                {identifier}
            </Typography>
            <Typography style={styles.retailerTitle} variant="caption" numberOfLines={1} color="graySix">
                {retailerName} (#{retailerCode})
            </Typography>
            <Typography variant="caption" numberOfLines={1} color="graySix">
                Created on: {created}
            </Typography>
        </View>
        <View style={styles.iconOuter}>
            <Icon name="chevron-right" />
        </View>
    </TouchableOpacity>
);

const styles = StyleSheet.create({
    qmrListItem: {
        padding: 20,
        alignItems: 'center',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: colors.grayThree,
    },
    iconOuter: {
        width: 24,
        height: 24,
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    trashIconOuter: {
        paddingRight: 20,
    },
    assetOuter: {
        width: 76,
        height: 76,
        flexShrink: 0,
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: colors.grayFive,
    },
    informationOuter: {
        flex: 1,
        paddingHorizontal: 20,
        justifyContent: 'center',
    },
    vehicleTitle: {
        marginBottom: 6,
    },
    qmrIdentifier: {
        fontWeight: 'bold',
        marginBottom: 6,
    },
    retailerTitle: {
        marginBottom: 6,
    },
});
