enum CommonKeys {
    CurrentEnv = 'CURRENT_ENV',
    AccessToken = 'ACCESS_TOKEN',
    ApiRandomErrorProbability = 'API_RANDOM_ERROR_PROBABILITY',
    ApiSimulateDelay = 'API_SIMULATE_DELAY',
}

enum MobileKeys {
    DidShowQmrPopup = 'DID_SHOW_QMR_POPUP',
    DidShowMediaPopup = 'DID_SHOW_MEDIA_POPUP',
    DidShowPushPermissionPopup = 'DID_SHOW_PUSH_PERMISSION_POPUP',
    DidShowBiometricPermissionPopup = 'DID_SHOW_BIOMETRIC_PERMISSION_POPUP',
    IsBiometricSupported = 'IS_BIOMETRIC_SUPPORTED',
    BiometricsToken = 'BIOMETRICS_TOKEN',
    HasSeenBiometricPopup = 'HAS_SEEN_BIOMETRIC_POPUP',
    PopupResponse = 'POPUP_RESPONSE',
    NotNowTimestamp = 'NOT_NOW_TIMESTAMP',
    BiometricsAllowed = 'BIOMETRICS_ALLOWED',
    AuthTokenTimestamp = 'AUTH_TOKEN_TIMESTAMP',
}

enum WebKeys {
    BrowserDeviceId = 'DEVICE_ID',
    AuthRedirectUrl = 'AUTH_REDIRECT_URL',
}

// Used/imported in shared packages
export const StorageKeys = CommonKeys;

// Used/imported in mobile app
export const MobileStorageKeys = {
    ...CommonKeys,
    ...MobileKeys,
};

// Used/imported in web app
export const WebStorageKeys = {
    ...CommonKeys,
    ...WebKeys,
};

// Type used in storage service
export type StorageKey = CommonKeys | MobileKeys | WebKeys | string;
