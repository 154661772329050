import React, { FC } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { Typography, Button, Icon } from '@packages/ui/shared';
import { SavedSearch } from '@packages/models/api';

const useStyles = createUseStyles({
    dialog: {
        width: 558,
        maxWidth: 'none',
    },
});

interface Props extends ModalProps {
    searchToDelete?: SavedSearch;
    onConfirm(): void;
    isLoading?: boolean;
}

const AdvancedSearchDeleteModal: FC<Props> = ({ searchToDelete, onConfirm, isLoading, ...props }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Modal show={props.show} dialogClassName={classes.dialog} onHide={props.onHide} centered>
            <Modal.Header closeButton placeholder={''}>
                <Typography variant="h4">{t('modals:deleteSearch.title', 'Delete Saved Search')}</Typography>
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex">
                    <Icon name="warning" />
                    <div className="d-flex flex-column ml-3">
                        <Typography variant="h5" style={{ marginBottom: 10 }}>
                            {t(
                                'modals:deleteSearch.message',
                                `Are you sure you want to delete this search: ${searchToDelete?.name}?`
                            )}
                        </Typography>
                        <Typography variant="lead">
                            {t('modals:deleteSearch.hint', 'Deleting searches is permanent and cannot be undone.')}
                        </Typography>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button title={t('modals:deleteSearch.cancel', 'Cancel')} variant="link" onPress={props.onHide} />
                <Button
                    disabled={isLoading}
                    isLoading={isLoading}
                    title={t('modals:deleteSearch.confirm', 'Delete')}
                    variant="danger"
                    onPress={onConfirm}
                    style={{ marginLeft: 20 }}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default AdvancedSearchDeleteModal;
