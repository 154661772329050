import { FormElementTypeId } from '@packages/models/api/form';

// Any changes made to this constant should also be reflected in the worksheetFormElement Option component.
// The file path is package/ui/worksheet/worksheet-form-element.
export const elementQuestionTypeMapping = {
    readOnlyFieldElementTypes: [FormElementTypeId.TextHeader, FormElementTypeId.TextParagraph],
    inputFormElementTypes: [
        FormElementTypeId.InputText,
        FormElementTypeId.InputTextarea,
        FormElementTypeId.Date,
        FormElementTypeId.Time,
        FormElementTypeId.DateTime,
    ],
    ratingWithTextArea: FormElementTypeId.RatingWithText,
    conditionalOptionFormElementTypes: [FormElementTypeId.Radio, FormElementTypeId.Checkbox],
    fileFormElementTypes: [FormElementTypeId.File],
};
