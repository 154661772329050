import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { QmrsService } from 'qmrs';
import { TechlineContext, TechlineDispatchContext } from './techline.context';
import { ITechlineProps } from './techline.state';
import { useAbortController } from '@packages/core/http';
import { Retailer } from '@packages/models/api';
import { useTranslation } from 'react-i18next';

export const useTechlineState = () => {
    const state = useContext<ITechlineProps>(TechlineContext);

    if (state === undefined) {
        throw new Error('useTechlineState must be used within a TechlineProvider');
    }

    return state;
};

export const useTechlineDispatch = () => {
    const state = useContext(TechlineDispatchContext);
    if (state === undefined) {
        throw new Error('useTechlineDispatch must be used within a DashboardProvider');
    }

    return state;
};

export const useTechlineCards = () => {
    const { t } = useTranslation();

    const { techline } = useTechlineState();

    const articlesLink = useMemo(() => {
        return techline && techline.linkThree;
    }, [techline]);

    const tipsLink = useMemo(() => {
        return techline && techline.linkTwo;
    }, [techline]);

    const cards = useMemo(() => {
        const list = [];

        if (articlesLink) {
            list.push({
                iconName: 'videocam',
                label: t('screens:tabs.techline.cardTypes.howTo.label', "Techline How To's"),
                action: t('screens:tabs.techline.cardTypes.howTo.action', 'View'),
                actionUrl: articlesLink,
                description: t(
                    'screens:tabs.techline.cardTypes.howTo.description',
                    "Watch quick how to's and find the TechShare Special Edition Tech TIPS."
                ),
                actionCallBack: () => {},
            });
        }

        if (tipsLink) {
            list.push({
                iconName: 'info',
                label: t('screens:tabs.techline.cardTypes.submitACase.label', 'Before you submit a case'),
                action: t('screens:tabs.techline.cardTypes.submitACase.action', 'View TIPS'),
                actionUrl: tipsLink,
                description: t(
                    'screens:tabs.techline.cardTypes.submitACase.description',
                    'You can search through all TechTIPS by using our TechTIPS locator tool.'
                ),
                actionCallBack: () => {},
            });
        }

        return list;
    }, [articlesLink, t, tipsLink]);

    return cards;
};
