import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import JsxParser from 'react-jsx-parser';

import { Typography } from './typography';

const SupportedComponents = {
    View,
    Typography,
};

interface Props {
    jsx: string;
}

export const DynamicJSX: FC<Props> = ({ jsx }) => {
    // https://github.com/TroyAlford/react-jsx-parser/issues/86#issuecomment-634979428
    const trimmedJsx = useMemo(() => {
        // remove any white spaces between JSX tags
        // otherwise RN throws when trying to "render the spaces"
        return jsx
            .trim()
            .replace(/\r?\n|\r/g, '')
            .replace(/>\s+</g, '><');
    }, [jsx]);
    return (
        // https://github.com/TroyAlford/react-jsx-parser#proptypes--settings
        // TS types for this version of the library cause issues with CRA bundler
        // @ts-ignore
        <JsxParser
            blacklistedAttrs={[]}
            // bindings={{}}
            renderInWrapper={false}
            components={SupportedComponents}
            jsx={trimmedJsx}
        />
    );
};
