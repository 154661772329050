import React, { FC } from 'react';
import { View, ViewStyle, StyleSheet, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import colors from '@packages/core/styles/colors';
import { Icon } from './icon';

interface AssetProgressBarProps {
    percent: number;
    canCancel?: boolean;
    onCancel?(): void;
    style?: ViewStyle;
}

export const AssetProgressBar: FC<AssetProgressBarProps> = ({ percent, canCancel, onCancel, style }) => {
    function handleCanceButtonPress() {
        if (onCancel) {
            onCancel();
        }
    }

    return (
        <View style={[styles.progressBar, style]}>
            <LinearGradient colors={['#C7C7C7', '#D6D6D6']} style={styles.progressBarContainer}>
                <LinearGradient
                    colors={['#5183B9', '#30548F']}
                    style={[styles.progressBarFill, { width: `${percent}%` }]}
                />
            </LinearGradient>
            {canCancel && (
                <TouchableOpacity onPress={handleCanceButtonPress} style={styles.progressBarCancelButtonOuter}>
                    <Icon name="x-circle" />
                </TouchableOpacity>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    progressBar: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    progressBarContainer: {
        flex: 1,
        height: 6,
        borderRadius: 3,
        overflow: 'hidden',
    },
    progressBarFill: {
        height: '100%',
    },
    progressBarCancelButtonOuter: {
        width: 44,
        height: 44,
        marginRight: -9,
        alignItems: 'center',
        justifyContent: 'center',
    },
    progressBarCancelButton: {
        width: 18,
        height: 18,
        borderRadius: 9,
        backgroundColor: colors.grayFive,
    },
});
