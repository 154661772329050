import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import colors from '@packages/core/styles/colors';
import { Icon, StsIconName, Typography } from '@packages/ui/shared';
import { useClickState } from '@web/core/navigation/navigation.contex';

const useNavigationSubItemStyles = createUseStyles({
    navigationSubItem: ({ iconName }: { iconName: StsIconName }) => ({
        display: 'flex',
        padding: `10px ${iconName ? '0' : '14px'}`,
        color: colors.textDarkPrimary,
        '&:hover': {
            textDecoration: 'none',
        },
    }),
    labelWrapper: ({ iconName, isSubOfSub }: { iconName: StsIconName; isSubOfSub: boolean }) => {
        let paddingLeft = iconName ? 16 : 34;
        if (isSubOfSub) paddingLeft += 30;

        return {
            flex: 1,
            paddingLeft,
        };
    },
    badge: {
        height: 16,
        minWidth: 16,
        marginLeft: 4,
        display: 'flex',
        borderRadius: 8,
        padding: '0 4px',
        textAlign: 'center',
        color: colors.white,
        alignItems: 'center',
        backgroundColor: colors.blueFour,
    },
    isActive: {
        backgroundColor: colors.blueOne,
    },
});

interface NavigationSubItemProps {
    to: string;
    title: string;
    badge?: string;
    isActive?: boolean;
    noWrap?: boolean;
    iconName?: StsIconName;
    isSubOfSub?: boolean;
    children?: React.ReactNode;
}

const NavigationSubItem: FC<NavigationSubItemProps> = ({
    to,
    title,
    badge = '',
    isActive = false,
    noWrap = false,
    iconName,
    isSubOfSub,
}) => {
    const classes = useNavigationSubItemStyles({ noWrap, iconName, isSubOfSub });
    const { toggleClickState, isMobileDevice } = useClickState();

    const handleClick = () => {
        if (isMobileDevice) toggleClickState();
    };

    return (
        <Link
            replace
            to={to}
            className={classNames(classes.navigationSubItem, {
                [classes.isActive]: isActive,
            })}
            onClick={handleClick}
        >
            <div className={classNames(['d-flex justify-content-between', classes.labelWrapper])}>
                <div className="d-flex align-items-center">
                    {iconName && (
                        <Icon
                            name={iconName}
                            size={20}
                            color={isActive ? 'white' : 'textDarkSecondary'}
                            style={{ display: 'flex', marginRight: 12 }}
                        />
                    )}
                    <Typography color={isActive ? 'white' : 'black'}>{title}</Typography>
                </div>

                {badge && (
                    <div className={classes.badge}>
                        <Typography color="blueOne" style={{ fontSize: 11, lineHeight: 15, fontWeight: 'bold' }}>
                            {badge}
                        </Typography>
                    </div>
                )}
            </div>
        </Link>
    );
};

export default NavigationSubItem;
