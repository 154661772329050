import React, { FC } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Icon, Popup } from '..';

interface Props {
    children: any;
    show: boolean;
    onHide?: () => void;
}

const MobileWrapper: FC<Props> = ({ children, show, onHide }) => {
    return (
        <Popup show={show} contentStyle={styles.container} onModalHide={() => {}}>
            {onHide && (
                <View style={{ alignItems: 'flex-end' }}>
                    <TouchableOpacity
                        onPress={() => {
                            onHide();
                        }}
                    >
                        <Icon name="x-close" style={{ width: 20 }} />
                    </TouchableOpacity>
                </View>
            )}
            {children}
        </Popup>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        padding: 20,
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        zIndex: 9999,
    },
});

export default MobileWrapper;
