import { Account } from './account';

export enum NotificationStatusId {
    Read = 'READ',
    Unread = 'UNREAD',
    New = 'NEW',
}

export enum NotificationTypeId {
    QmrStatusChanged = 'QMR_STATUS_CHANGED',
    QmrSubmitted = 'QMR_SUBMITTED',
    QmrFeedbackProvided = 'QMR_FEEDBACK_PROVIDED',
    QmrCommentAdded = 'QMR_COMMENT_ADDED',
    QmrAdditionalInfoAdded = 'QMR_ADDITIONAL_INFO_ADDED',
    QmrAttachmentAdded = 'QMR_ATTACHMENT_ADDED',
    QmrEdited = 'QMR_EDITED',
    FeedbackSubmitted = 'FEEDBACK_SUBMITTED',
    FeedbackAcknowledged = 'FEEDBACK_ACKNOWLEDGED',
    FeedbackRequested = 'FEEDBACK_REQUESTED',
    QmrToughbookAttachmentAdded = 'QMR_TOUGHBOOK_ATTACHMENT_ADDED',
    QmrSyncFailure = 'QMR_SYNC_FAILURE',
    QmrSyncSuccess = 'QMR_SYNC_SUCCESS',
    SyncFailure = 'SYNC_FAILURE',
    SyncSuccess = 'SYNC_SUCCESS',
    AccountTitleNotFound = 'ACCOUNT_TITLE_NOT_FOUND',
    QmrSyncResponseFailure = 'QMR_SYNC_RESPONSE_FAILURE',
    QmrSyncResponseSuccess = 'QMR_SYNC_RESPONSE_SUCCESS',
    NotificationCreated = 'NOTIFICATION_CREATED',
    QmrCsvExport = 'QMR_CSV_EXPORT',
    QmrCsvExportFailure = 'QMR_CSV_EXPORT_FAILURE',
}

export enum NotificationTagId {
    Review = 'REVIEW',
    Acknowledged = 'ACKNOWLEDGED',
}

export enum NotificationGroupingId {
    Today = 'TODAY',
    Yesterday = 'YESTERDAY',
    ThisWeek = 'THIS_WEEK',
    LastWeek = 'LAST_WEEK',
    Older = 'OLDER',
}

export interface Notification {
    notificationId: string;
    notificationStatusId: NotificationStatusId;
    notificationStatusIdDescription: string;
    notificationTypeId: NotificationTypeId;
    notificationTypeIdDescription: string;
    notificationTagId: NotificationTagId;
    notificationTagIdDescription: string;
    createdTimestamp: string;
    createdTimestampDescription: string;
    notificationGroupingId: NotificationGroupingId;
    notificationGroupingIdDescription: string;
    link: string;
    message: string;
    data: any;
    senderAccount: Account;
    retailerName: string;
    retailerCode: string;
}

export interface NotificationsResponseDto {
    notifications: Notification[];
}

export interface NotificationsUpdateResponseDto {
    notifications: Notification[];
}

export interface NotificationStatusUpdate {
    notificationId: string;
    notificationStatusId: NotificationStatusId;
}

export interface UpdateNotificationStatusesDto {
    notifications: NotificationStatusUpdate[];
}

export interface NotificationCount {
    count: number;
    countDescription: string;
}

export interface NotificationSummary {
    [NotificationStatusId.Read]: NotificationCount;
    [NotificationStatusId.Unread]: NotificationCount;
    [NotificationStatusId.New]: NotificationCount;
}

// User Settings for Notification Preferences API Model
// https://github.com/SubaruTechShare/sts-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/NotificationPreferences.java
export interface NotificationPreference {
    notificationPreferenceId: string;
    accountId: string;
    notificationTypeId: NotificationTypeId | string;
    appNotification: boolean;
    emailNotification: boolean;
    mobilePushNotification: boolean;
    createdTimestamp?: string;
    lastUpdatedTimestamp?: string;
    lastUpdatedAccountId?: string;
    appSettingLockedOn?: boolean;
}

export interface NotificationPreferenceCategory {
    [key: string]: NotificationPreference[];
}

export interface NotificationPreferencesResponseDto {
    notificationPreferences: NotificationPreferenceCategory;
}

export interface UpdateNotificationPreferencesResponseDto {
    updatedNotificationPreferences: NotificationPreference[];
}

export interface UpdateNotificationPreferencesDto {
    notificationPreferences: NotificationPreference[];
}
