import { HttpClient } from '@packages/core/http';
import { BmisResponseDto } from '@packages/models/api/bmis';
import { Bmis, BmisRaw } from '@packages/models/api';

export class BmisService {
    constructor(private http: HttpClient) {}

    async fetchBmis({ vin, ignoreCache = false }: { vin: string; ignoreCache?: boolean }): Promise<Bmis[]> {
        const dateFields = ['createdOn', 'modifiedOn', 'reportDate', 'serviceDateAndTime', 'transmittedOn'];

        const response = await this.http.orchestrateRequest<BmisResponseDto>({
            method: 'GET',
            url: `/bmis/retrieveVin?filter=${vin}&$orderby=ReportDate`,
            ignoreCache,
        });

        if (!response.success && response.aborted) {
            return [];
        } else if (!response.success) {
            return Promise.reject(response.data);
        }

        if (!response?.data?.value?.length) {
            return [];
        }

        // This API returns keys in UpperCamelCase.
        // We want lowerCamelCase, so we'll transform it here.
        return response.data.value.map((rawBmis: BmisRaw) => {
            const parsedBmis: Partial<Bmis> = {};
            for (const key of Object.keys(rawBmis)) {
                const rawKey = key as keyof BmisRaw;
                const rawValue = rawBmis[rawKey];
                const lowerCaseKey = this.toLowerCamelCase(key) as keyof Bmis;
                let finalValue: any = rawValue;

                if (rawValue === null || rawValue === undefined) {
                    continue;
                }

                if (dateFields.includes(lowerCaseKey)) {
                    finalValue = new Date(rawValue as string) as Bmis[typeof lowerCaseKey];
                }

                parsedBmis[lowerCaseKey] = finalValue;
            }
            return parsedBmis;
        }) as Bmis[];
    }

    toLowerCamelCase(input: string): string {
        if (input.length === 0) {
            return input;
        }
        switch (input) {
            case 'CCA':
                return 'cca';

            case 'VIN':
                return 'vin';

            case 'ServiceDateandTime':
                return 'serviceDateAndTime';

            case 'VINCaptureMethod':
                return 'vinCaptureMethod';

            default:
                return input.charAt(0).toLowerCase() + input.slice(1);
        }
    }
}
