import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View, TouchableOpacity, FlatList, Keyboard, KeyboardEvent } from 'react-native';

import { HttpClient, useAbortController } from '@packages/core/http';

import { Typography } from './typography';
import { TextInputHelper } from './text-input-helper';
import { debounce } from 'lodash';
import colors from '@packages/core/styles/colors';

type AutocompleteAccount = {
    accountId: string;
    soaUsername: string;
    name: string;
};

export const DebugAccounts: FC<{
    account: any;
    httpClient: HttpClient;
    onAccountChange: (accessToken: string) => void;
}> = ({ account, httpClient, onAccountChange }) => {
    const { abortSignalRef } = useAbortController();
    const [accounts, setAccounts] = useState<AutocompleteAccount[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [keyboardHeight, setKeyboardHeight] = useState(0);

    useEffect(() => {
        const onKeyboardWillShow = (e: KeyboardEvent) => {
            setKeyboardHeight(e.endCoordinates.height);
        };

        const onKeyboardWillHide = () => {
            setKeyboardHeight(0);
        };

        Keyboard.addListener('keyboardWillShow', onKeyboardWillShow);
        Keyboard.addListener('keyboardWillHide', onKeyboardWillHide);

        return () => {
            Keyboard.removeListener('keyboardWillShow', onKeyboardWillShow);
            Keyboard.removeListener('keyboardWillHide', onKeyboardWillHide);
        };
    }, []);

    const searchAccounts = useCallback(
        (query = '') => {
            setAccounts([]);
            setLoading(true);

            httpClient
                .orchestrateRequest<{ autocompleteAccounts: AutocompleteAccount[] }>({
                    method: 'GET' as const,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                    url: `/accounts/autocomplete?query=${query}`,
                })
                .then((response) => {
                    setLoading(false);
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    } else {
                        setAccounts(response.data.autocompleteAccounts);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    setError(e.message);
                    console.log('Debugger error::', e);
                });
        },
        [abortSignalRef, httpClient]
    );

    const debouncedSearch = useMemo(() => debounce(searchAccounts, 300), [searchAccounts]);

    const getAccountToken = useCallback(
        (accountId: string) => {
            httpClient
                .orchestrateRequest<{ accessToken: string }>({
                    method: 'GET' as const,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                    url: `/accounts/${accountId}/access-token`,
                })
                .then((response) => {
                    if (response.success) {
                        onAccountChange(response.data.accessToken);
                    } else {
                        console.log('Debugger error::', { response });
                    }
                })
                .catch((e) => {
                    console.log('Debugger error::', e);
                });
        },
        [abortSignalRef, httpClient, onAccountChange]
    );

    return (
        <FlatList
            keyboardDismissMode="interactive"
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{ paddingHorizontal: 20 }}
            data={accounts}
            keyExtractor={(item) => item.accountId}
            renderItem={({ item }) => {
                return (
                    <TouchableOpacity
                        onPress={() => {
                            getAccountToken(item.accountId);
                        }}
                        style={{ padding: 8, borderBottomColor: colors.borderDark, borderBottomWidth: 1 }}
                    >
                        <Typography>{item.name}</Typography>
                        <Typography>{item.soaUsername}</Typography>
                    </TouchableOpacity>
                );
            }}
            stickyHeaderIndices={[0]}
            ListHeaderComponent={
                <View style={{ backgroundColor: 'white' }}>
                    <TextInputHelper
                        label="Search User Accounts"
                        placeholder=""
                        onChangeText={(text) => {
                            setLoading(true);
                            debouncedSearch(text);
                        }}
                    />
                </View>
            }
            ListFooterComponent={<View style={{ height: keyboardHeight }} />}
            ListEmptyComponent={<Typography>{loading ? 'Loading...' : error}</Typography>}
        />
    );
};
