import { HttpClient } from '@packages/core/http';

type AllowedQueryParams = 'period' | 'comparedTo';
type DistrictSearchParams = 'query';
type DistrictIdSearchParams = 'period' | 'comparedTo' | 'id';

export interface CompareAverage {
    qmrAverage: string | number;
    feedbackAverage: string | number;
    escalationAverage: string | number;
}
export interface ComparePercentage {
    qmrPercentage: string | number;
    feedbackPercentage: string | number;
    escalationPercentage: string | number;
}
export interface DistrictAndLocation {
    qmrSubmitted: string;
    feedbackRequested: string;
    escalationSubmitted: string;
    compare: string;
    compareAverage: CompareAverage;
    comparePercentage: ComparePercentage;
    periodAndComparedToDates: any;
    dealerSubmittedQMRs: string;
    dealerAndDSQMSubmittedQMRs: string;
    percentageOfEscalationSubmitted: string;
    comparedToEscalationSubmitted: string;
    percentageOfFeedbackProvided: string;
    comparedToFeedbackRequested: string;
    comparedToDealerAndDSQMSubmittedQMRs: string;
    comparedToDealerQMRs: string;
    comparedToPercentageOfEscalationSubmitted: string;
    comparedToPercentageOfFeedbackProvided: string;
    districtDetails?: string;
    regionDetails?: string;
}

export interface DistrictStatsResponseDto {
    districtAndLocation: DistrictAndLocation;
}

export interface DistrictsData {
    districtId: string;
    details: string;
}

export interface DistrictsResponseDto {
    autocompleteDistricts: DistrictsData[];
}

export interface AutoCompleteDistricts {
    districtId?: string;
    accountId?: string;
    soaUsername?: string;
    name?: string;
}

export interface DistrictSearchResponseDto {
    autocompleteDistricts?: AutoCompleteDistricts;
}

export class QmrsDashboardDistrictStatsService {
    constructor(private http: HttpClient) {}

    fetchDistrictStatsData({
        searchParams,
        ignoreCache = false,
        signal,
    }: {
        searchParams: Record<AllowedQueryParams, string>;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/qmr/dashboard/periodlocationcompare?' + new URLSearchParams(searchParams).toString();

        return this.http.orchestrateRequest<DistrictStatsResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchDistrictSearchData({
        searchParams,
        ignoreCache = false,
        signal,
    }: {
        searchParams: Record<DistrictSearchParams, string>;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/qmr/dashboard/searchDistrict?' + new URLSearchParams(searchParams).toString();

        return this.http.orchestrateRequest<DistrictSearchResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchDistrictSpecificData({
        districtSearchParams,
        ignoreCache = false,
        signal,
    }: {
        districtSearchParams: Record<any, string>;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/qmr/dashboard/districtStats?' + new URLSearchParams(districtSearchParams).toString();

        return this.http.orchestrateRequest<DistrictStatsResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchDistrictIdData({
        searchParams,
        ignoreCache = false,
        signal,
    }: {
        searchParams: Record<any, string>;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/qmr/dashboard/districtStats?' + new URLSearchParams(searchParams).toString();

        return this.http.orchestrateRequest<DistrictStatsResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchDistrictsData({ ignoreCache = false, signal }: { ignoreCache?: boolean; signal?: AbortSignal }) {
        const url = '/qmr/dashboard/allDistrict';

        return this.http.orchestrateRequest<DistrictsResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }
}
