import { HttpClient } from '@packages/core/http';
import { Account, RetailerResponseDto, TimeZoneResponseDto } from '@packages/models/api';

interface GetAccountsQueryParameters {
    testAccounts?: boolean;
    size?: number;
    page?: number;
    query?: string;
}

interface GetAdminAccountsQueryParameters {
    testAccounts?: boolean;
    size?: number;
    page?: number;
    fieldToSearch: string;
    query?: string;
    includeDisabled: boolean;
    sortField?: string;
    sortDirection?: string;
}
interface GetRetailerByRetailerQueryParameters {
    retailerCode: string;
    text: string;
}
export interface GetRetailerByRetailerResponseBody {
    accountId: string;
    accountSourceId: string;
    accountStatusId: string;
    defaultEmailAddressId: string;
    defaultPhoneNumberId: string;
    soaUserId: string;
    soaUsername: string;
    firstName: string;
    lastName: string;
    timeZone: string;
    locale: string;
    enabled: boolean;
    emailNotificationsEnabled: boolean;
    pushNotificationsEnabled: boolean;
    lastSyncedTimestamp: string;
    createdTimestamp: string;
    createdAccountId: string;
    lastUpdatedTimestamp: string;
    lastUpdatedAccountId: string;
    jobTitle: string;
}

interface GetAccountsResponseBody {
    totalCount: number;
    accounts: Account[];
}

interface UpdateAccountRequestBody {
    addOnRoleIds?: string[];
    accountRoles?: {
        accountRoleId: string;
        roleId: string;
    }[];
    roleTargets?: {
        roleTargetId: string;
        roleId: string;
    }[];
    defaultSavedSearchId?: string | null;
}

interface UpdateAccountResponseBody {
    account: Account;
}

interface CreateAccountRequestBody {
    soaUsername: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    roleTargetTypeId?: string;
    baseRole: string;
    addOnRole: string[];
    jobTitle: string;
}

interface GetUserInfoCardDetailsParameter {
    userName?: string;
    accountId?: string;
}

export class AccountsService {
    constructor(private http: HttpClient) {}

    getAccounts(queryParameters?: GetAccountsQueryParameters) {
        return this.http.orchestrateRequest<GetAccountsResponseBody>({
            method: 'GET',
            url: `/accounts${this.http.queryString(queryParameters || {})}`,
        });
    }

    getAdminAccounts(queryParameters?: GetAdminAccountsQueryParameters) {
        return this.http.orchestrateRequest<GetAccountsResponseBody>({
            method: 'GET',
            url: `/admin/accounts${this.http.queryString(queryParameters || {})}`,
        });
    }

    getRetailersByRetailerNumber(queryParameters: GetRetailerByRetailerQueryParameters) {
        return this.http.orchestrateRequest<GetRetailerByRetailerResponseBody[]>({
            method: 'GET',
            url: `/accounts/findUsers${this.http.queryString(queryParameters || {})}`,
            ignoreCache: true,
        });
    }

    getTimeZones(options?: { ignoreCache?: boolean; signal?: AbortSignal }) {
        return this.http.orchestrateRequest<TimeZoneResponseDto>({
            method: 'GET',
            url: '/accounts/time-zones',
            ...options,
        });
    }

    updateAccount(accountId: string, body: UpdateAccountRequestBody) {
        return this.http.orchestrateRequest<UpdateAccountResponseBody>({
            method: 'PATCH',
            url: `/accounts/${accountId}`,
            body,
        });
    }

    createAccount(createAccountRequest: CreateAccountRequestBody) {
        return this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/account',
            body: createAccountRequest,
        });
    }

    getUserInfoCardDetails(data: GetUserInfoCardDetailsParameter) {
        return this.http.orchestrateRequest<{ account: Account }>({
            method: 'GET',
            url: `/userInfoCardsAccount${this.http.queryString(data)}`,
        });
    }
}
