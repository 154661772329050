import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAbortController } from '@packages/core/http';
import { ADD_ON_ROLE_ID, CaseType, Qmr } from '@packages/models/api';
import { useAuthState } from '@packages/contexts/auth';
import { techlineService, analyticsService } from '@web/services/singletons';
import { TextInputHelper, Label, Icon, Button, Typography, TextBadge } from '@packages/ui/shared';
import { Typeahead } from 'react-bootstrap-typeahead';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { caseStatusMap as statusMap } from '@web/utils';
import { WorksheetToaster } from './worksheet/toaster';
import { useCaseType } from './worksheet/hooks/useCaseType';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
interface Props {
    show: boolean;
    onHide: (qmrId?: Qmr['qmrId']) => void;
}

interface Retailer {
    retailerName: string;
    retailerCode: string;
    retailerId: string;
    userName: string;
    retailerNumber: string;
}

const useStyles = createUseStyles({
    card: {
        height: '168px',
        width: '100%',
        borderLeft: `3px solid ${colors.grayThree}`,
        backgroundColor: colors.grayOne,
        maxHeight: '175px',
        overflow: 'hidden',
    },
    primaryOutlineBtn: {
        color: colors.blueOne,
        borderColor: colors.blueOne,
        padding: 10,
        borderRadius: 4,
        '&:hover, &:active': {
            backgroundColor: 'transparent',
            borderColor: colors.blueOne,
        },
    },
    previousCaseCardLeftContainer: {
        flex: 8,
    },
    previousCaseCardRightContainer: {
        flex: 2,
    },
    modalBody: {
        maxHeight: '600px',
        overflow: 'scroll',
    },
    customerConcern: {
        display: 'flex',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '35px',
    },
});
enum LoadingType {
    EDIT = 'EDIT',
    NEW = 'NEW',
}
export const CreateCaseModal: FC<Props> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { account } = useAuthState();
    const navigate = useNavigate();
    const [showToaster, setShowToaster] = useState<{ show: boolean; message: string }>({ show: false, message: '' });
    const [currentFormType, setCurrentFormType] = useState('disclaimerModal');
    const [showCreateCaseLoader, setShowCreateCaseLoader] = useState(false);
    const [vinInputValue, setVinInputValue] = useState('');
    const [vehicleDetails, setVehicleDetails] = useState<any>();
    const [caseTypesOptions, setCaseTypesOptions] = useState<CaseType[]>([]);
    const [selectedCasetypes, setSelectedCasetypes] = useState<CaseType[]>([]);
    const [selectedRetailer, setSelectedRetailer] = useState<Retailer[]>([]);
    const [allRetailers, setAllRetailers] = useState<Retailer[]>([]);
    const [checkVinLoader, setCheckVinLoader] = useState(false);
    const [vinError, setVinError] = useState('');
    const [caseList, setCaseList] = useState<any>();
    const [loadingCaseList, setLoadingCaseList] = useState(false);
    const [isEditLoading, setIsEditLoading] = useState<string>('');
    const [isCaseTypeSave, setIsCaseTypeSave] = useState<LoadingType | string>('');
    const isPilotUser = useMemo(() => {
        return account?.addOnRoles?.some((role) => role?.roleId === ADD_ON_ROLE_ID.PILOT_USER);
    }, [account]);

    const handleIsCaseTypeSave = useCallback((isSave: boolean) => {
        if (isSave) {
            setIsCaseTypeSave('');
        }
    }, []);
    const { saveCaseTypes, fetchCaseType, isCaseTypeLoading } = useCaseType({
        setCaseTypes: setCaseTypesOptions,
        setShowToaster,
        handleIsCaseTypeSave,
    });

    useEffect(() => {
        (async () => {
            try {
                const retailersRes = await techlineService.retrieveRetailer(false, abortSignalRef.current);
                if (retailersRes?.data?.results?.length) {
                    const retailers = retailersRes?.data?.results?.map((item: any) => item);
                    setAllRetailers(retailers);
                    setSelectedRetailer([retailers[0]]);
                }
            } catch (e) {
                alert('Error: ' + e + '. Please refresh & try again.');
                console.log(e);
            }
        })();
    }, [abortSignalRef, account]);

    useEffect(() => {
        fetchCaseType();
    }, [abortSignalRef, fetchCaseType, isPilotUser]);

    const onCreateNewCase = useCallback(async () => {
        try {
            if (showCreateCaseLoader) return;
            setShowCreateCaseLoader(true);
            const createDraftCaseRes = await techlineService.createDraftCase({
                username: account?.soaUsername || '',
                retailernumber: selectedRetailer[0]?.retailerNumber,
                vin: vinInputValue,
                signal: abortSignalRef.current,
                ignoreCache: true,
            });
            if (createDraftCaseRes?.data?.results?.caseNumber?.length) {
                const caseNumber = createDraftCaseRes?.data?.results?.caseNumber;
                setIsCaseTypeSave(LoadingType.NEW);
                const response = await saveCaseTypes({ caseTypes: selectedCasetypes, status: 1, caseNumber });
                if (response?.success) {
                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_CREATES_DRAFT_TECHLINE_CASE);
                    props.onHide(caseNumber);
                }
            }
            setShowCreateCaseLoader(false);
        } catch (err) {
            console.log(err);
            alert('Error: ' + err + '. Please refresh & try again.');
            setShowCreateCaseLoader(false);
        }
    }, [
        abortSignalRef,
        account?.soaUsername,
        props,
        saveCaseTypes,
        selectedCasetypes,
        selectedRetailer,
        showCreateCaseLoader,
        vinInputValue,
    ]);

    const fetchAllRetailers = async (query: string) => {
        try {
            const retailersRes = await techlineService.retrieveRetailer(true, abortSignalRef.current, query);
            if (retailersRes?.data?.results?.length) {
                setAllRetailers(retailersRes.data.results);
            }
        } catch (e) {
            alert('Error: ' + e + '. Please refresh & try again.');
            console.log(e);
        }
    };

    const fetchCaseList = useCallback(async () => {
        setCaseList([]);
        if (selectedRetailer?.[0]?.retailerNumber) {
            try {
                setLoadingCaseList(true);
                const allCasesRes = await techlineService.retrieveCases(
                    selectedRetailer?.[0]?.retailerNumber,
                    true,
                    abortSignalRef.current
                );
                if (allCasesRes?.data?.results?.length) {
                    const filteredCases = allCasesRes.data.results.filter(
                        (caseItem: { vin: string; techlineCaseStatus: string }) =>
                            caseItem.vin === vinInputValue &&
                            !statusMap['CLOSED'].includes(caseItem?.techlineCaseStatus)
                    );
                    setCaseList(filteredCases);
                    console.log('Selected caseType from fetch ', selectedCasetypes);
                    // if (true) {
                    //     await onCreateNewCase();
                    // }
                }
                setLoadingCaseList(false);
            } catch (e) {
                console.log(e);
                alert('Error: ' + e + '. Please refresh & try again.');
                setLoadingCaseList(false);
            }
        }
    }, [abortSignalRef, selectedCasetypes, selectedRetailer, vinInputValue]);

    const isConfirmVinDisabled = useMemo(() => {
        if (selectedRetailer?.[0]?.retailerNumber && vinInputValue && vehicleDetails) {
            return false;
        }
        return true;
    }, [selectedRetailer, vehicleDetails, vinInputValue]);

    const handleOnHide = () => {
        props.onHide();
    };

    const onSelectDisclaimerConfirm = () => {
        setCurrentFormType('selectRetalier');
    };

    const onSelectRetalierConfirm = () => {
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_SELECTS_RETAILER);
        setCurrentFormType('selectCaseTypes');
        fetchCaseList();
    };

    const onSelectCaseTypesContinue = () => {
        // setCurrentFormType('reviewCaseTipsAndArticles');
        if (caseList?.length) {
            setCurrentFormType('reviewPreviousCases');
        } else {
            onCreateNewCase();
        }
    };

    const onReviewCaseTipsAndArticlesContinue = () => {
        setCurrentFormType('reviewPreviousCases');
    };

    const onEditCase = async (item: any) => {
        if (item.caseNumber) {
            setIsEditLoading(item.caseNumber);
            setIsCaseTypeSave(LoadingType.EDIT);
            const response = await saveCaseTypes({
                caseTypes: selectedCasetypes,
                status: 1,
                caseNumber: item.caseNumber,
            });
            if (!response?.success) {
                setIsEditLoading('');
                return;
            }
            navigate(`/techline/${item.caseNumber}`);
        }
    };

    const handleCheckVinButtonClick = async () => {
        try {
            if (checkVinLoader) return;
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_CHECKS_A_VIN);
            setCheckVinLoader(true);
            setVehicleDetails(undefined);
            setVinError('');
            const isVinValidRes = await techlineService.validateVin(vinInputValue, true, abortSignalRef.current);
            if (isVinValidRes?.data?.results) {
                const vinDetailsRes = await techlineService.retrieveVinDetails(
                    vinInputValue,
                    true,
                    abortSignalRef.current
                );
                if (vinDetailsRes?.data?.results?.[0]) {
                    setVehicleDetails(vinDetailsRes?.data?.results?.[0]);
                }
            } else {
                setVinError('No VIN Found!');
            }
            setCheckVinLoader(false);
        } catch (error) {
            alert('Error: ' + error + '. Please refresh & try again.');
            console.log(error);
            setCheckVinLoader(false);
        }
    };

    const renderCaseDisclaimerModal = () => {
        return (
            <React.Fragment>
                <Modal.Header placeholder={''}>
                    <Modal.Title>Create a new case</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Typography>
                        Welcome to Subaru Techline on TechShare for Subaru Retailers.
                        <br />
                        <br />
                        <span style={{ fontStyle: 'italic' }}>
                            Disclaimer: Any information or advice furnished by the Subaru Technical Helpline is given
                            for the purpose of assisting technicians in the repair of a vehicle. Such information is not
                            intended to authorize repairs or to approve a repair procedure that a technician, in their
                            sole discretion has decided to perform.
                        </span>
                    </Typography>
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={handleOnHide}>
                            Cancel
                        </Button>
                    </div>

                    <Button variant="primary" isLoading={false} disabled={false} onPress={onSelectDisclaimerConfirm}>
                        Accept
                    </Button>
                </Modal.Footer>
            </React.Fragment>
        );
    };

    const renderVehicleDetails = () => {
        return (
            <div className="mt-5">
                <hr className="my-3 mb-5" />
                <Row className="mb-4">
                    <Col xs={12}>
                        <Typography variant="h5">Confirm vehicle details</Typography>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col xs={3}>
                        <div className="d-flex flex-column">
                            <Typography variant="h5">Model year</Typography>
                            <div className="mt-4">
                                <Typography>{vehicleDetails?.modelYear}</Typography>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className="d-flex flex-column">
                            <Typography variant="h5">Carline</Typography>
                            <div className="mt-4">
                                <Typography>{vehicleDetails?.model}</Typography>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className="d-flex flex-column">
                            <Typography variant="h5">Engine number</Typography>
                            <div className="mt-4">
                                <Typography>{vehicleDetails?.engineId}</Typography>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className="d-flex flex-column">
                            <Typography variant="h5">Transmission number</Typography>
                            <div className="mt-4">
                                <Typography>{vehicleDetails?.vehicleTransmissionNumber}</Typography>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs={3}>
                        <div className="d-flex flex-column">
                            <Typography variant="h5">Color</Typography>
                            <div className="mt-4">
                                <Typography>{vehicleDetails?.colorCategory}</Typography>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className="d-flex flex-column">
                            <Typography variant="h5">Model</Typography>
                            <div className="mt-4">
                                <Typography>{vehicleDetails?.modelNumber}</Typography>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className="d-flex flex-column">
                            <Typography variant="h5">Model option</Typography>
                            <div className="mt-4">
                                <Typography>{vehicleDetails?.optionCode}</Typography>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderSelectRetailer = () => {
        if (!allRetailers?.length) return;
        return (
            <Typeahead
                id="select-retailer"
                selected={selectedRetailer}
                labelKey={(option) => `${option.retailerName} (#${option.retailerNumber})`}
                onChange={(selected: any) => {
                    setSelectedRetailer(selected);
                }}
                options={allRetailers}
                onInputChange={(text: string) => {
                    fetchAllRetailers(text);
                }}
            />
        );
    };

    const renderSelectRetalierForm = () => {
        return (
            <React.Fragment>
                <Modal.Header placeholder={''}>
                    <Modal.Title>Create a new case</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Row className="mb-4">
                            <Col xs={8}>{renderSelectRetailer()}</Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <TextInputHelper
                                    controlId="newQmr-vin"
                                    maxLength={17}
                                    value={vinInputValue}
                                    onChangeText={(text) => {
                                        setVinInputValue(text.toUpperCase());
                                        setVehicleDetails(undefined);
                                    }}
                                    autoCapitalize="characters"
                                    required={true}
                                    label={t('qmr:inputs.vin.label')}
                                    labelHint={t('qmr:inputs.vin.hint', 'VIN should be either 8 or 17 characters long')}
                                    placeholder={t('qmr:inputs.vin.placeholder')}
                                    showCharCount
                                    rightIcon={({ inputState }) => {
                                        const canCheckVin = vinInputValue.length === 8 || vinInputValue.length === 17;
                                        const disableCheckButton = inputState.isDisabled || !canCheckVin;
                                        return (
                                            <Button
                                                variant="primary"
                                                isLoading={checkVinLoader}
                                                style={{
                                                    height: inputState.hasError || inputState.isFocused ? 36 : 38,
                                                    marginRight: -12,
                                                }}
                                                disabled={disableCheckButton}
                                                onPress={handleCheckVinButtonClick}
                                            >
                                                {t('modals:createQmr.actions.checkVin')}
                                            </Button>
                                        );
                                    }}
                                    editable={true}
                                    errorMessage={vinError}
                                />
                            </Col>
                        </Row>
                    </Form>
                    {vehicleDetails && renderVehicleDetails()}
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={handleOnHide}>
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={false}
                        disabled={isConfirmVinDisabled}
                        onPress={onSelectRetalierConfirm}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </React.Fragment>
        );
    };

    const renderCaseTypesForm = () => {
        return (
            <React.Fragment>
                <Modal.Header placeholder={''}>
                    <Modal.Title>Create a new case</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <div>
                            <Label>Case type(s)</Label>
                            <Typeahead
                                id="typeahead-case-type-select"
                                className="mt-3"
                                multiple
                                onChange={setSelectedCasetypes}
                                labelKey={'caseType'}
                                options={caseTypesOptions}
                                placeholder="Choose case type..."
                                selected={selectedCasetypes}
                                isLoading={isCaseTypeLoading}
                            />
                        </div>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={handleOnHide}>
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={loadingCaseList || showCreateCaseLoader}
                        disabled={!selectedCasetypes.length || loadingCaseList}
                        onPress={onSelectCaseTypesContinue}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </React.Fragment>
        );
    };

    const renderQuickTipCard = () => {
        return (
            <div className={`${classes.card} d-flex flex-row p-2`}>
                <Icon size={24} name="info" />
                <div className="ml-2">
                    <Typography variant="h6" color="blueOne">
                        Quick tip
                    </Typography>
                    <div className="mt-2 mb-4">
                        <Typography color="black">
                            You can search through all TechTIPS by using our TechTIPS locator tool.
                        </Typography>
                    </div>
                    <button type="button" className={`${classes.primaryOutlineBtn} btn btn-outline-primary`}>
                        <Typography variant="h6" color="blueOne">
                            View TIPS locator
                        </Typography>
                    </button>
                </div>
            </div>
        );
    };

    const renderArticlesCard = () => {
        return (
            <div className={`${classes.card} d-flex flex-row p-2`}>
                <Icon size={24} name="menu" />
                <div className="ml-2">
                    <Typography variant="h6" color="blueOne">
                        Article
                    </Typography>
                    <div className="mt-2 mb-4">
                        <Typography color="black">
                            Before creating a case, be prepared by reviewing the latest Revisited TIPS article.
                        </Typography>
                    </div>
                    <button type="button" className={`${classes.primaryOutlineBtn} btn btn-outline-primary`}>
                        <Typography variant="h6" color="blueOne">
                            View TIPS article
                        </Typography>
                    </button>
                </div>
            </div>
        );
    };

    const renderReviewCaseTipsAndArticles = () => {
        return (
            <React.Fragment>
                <Modal.Header placeholder={''}>
                    <Modal.Title>Review case tips & articles</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <Typography variant="h6">You have selected “Transmission” for your case type</Typography>
                        <div className="mt-3 mb-3">
                            <Typography>
                                Please, check if any of these articles or tips helps you resolve your issue. Otherwise,
                                please create a new case.
                            </Typography>
                        </div>
                        <Row>
                            <Col xs={6}>{renderArticlesCard()}</Col>
                            <Col xs={6}>{renderQuickTipCard()}</Col>
                        </Row>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={handleOnHide}>
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={false}
                        disabled={false}
                        onPress={onReviewCaseTipsAndArticlesContinue}
                    >
                        Continue creating a new case
                    </Button>
                </Modal.Footer>
            </React.Fragment>
        );
    };

    const renderReviewPreviousCases = () => {
        return (
            <React.Fragment>
                <Modal.Header placeholder={''}>
                    <Modal.Title>Review previous cases for this same VIN</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className={`${classes.modalBody}`}>
                        <Typography>
                            If any of these cases have the same issue you’re working on, please edit that case.
                        </Typography>
                        <div className="mt-1 mb-3">
                            <Typography>Otherwise, please create a new one.</Typography>
                        </div>
                        {caseList.map((caseDetails: any) => (
                            <div className="mt-5">
                                {caseDetails?.techlineCaseStatus && (
                                    <div className={`${classes.card} d-flex flex-row p-2`}>
                                        <div className={`${classes.previousCaseCardLeftContainer} d-flex flex-column`}>
                                            <div className="d-flex flex-row align-items-center">
                                                <Typography variant="h6" color="blueOne">
                                                    {caseDetails.caseNumber}
                                                </Typography>
                                                <div className="ml-1">
                                                    <TextBadge>{caseDetails.techlineCaseStatus || '--'}</TextBadge>
                                                </div>
                                                <div className="ml-1">
                                                    <Typography color="brandSecondary">
                                                        {caseDetails.createdOn
                                                            ? format(
                                                                  parseISO(caseDetails.createdOn),
                                                                  "MMM d, yyyy hh:mm aaaaa'm'"
                                                              )
                                                            : '--'}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row mt-2">
                                                <Typography variant="h6" color="brandSecondary">
                                                    RO#:{' '}
                                                    <Typography color="brandSecondary">
                                                        {caseDetails.repairOrderNumber || '--'}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                            <div className="d-flex flex-row mt-1">
                                                <Typography variant="h6" color="brandSecondary">
                                                    Case type:{' '}
                                                    <Typography color="brandSecondary">
                                                        {caseDetails.caseType || '--'}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                            <div className="d-flex flex-row mt-1">
                                                <Typography variant="h6" color="brandSecondary">
                                                    Created date:{' '}
                                                    <Typography color="brandSecondary">
                                                        {caseDetails.createdOnDescription}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                            <div className="d-flex flex-row mt-1">
                                                <Typography variant="h6" color="brandSecondary">
                                                    Technician:{' '}
                                                    <Typography color="brandSecondary">
                                                        {caseDetails.fullName || '--'}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                            <div className={`${classes.customerConcern} d-flex flex-row mt-1`}>
                                                <Typography variant="h6" color="brandSecondary">
                                                    Customer concern:{' '}
                                                    <Typography color="brandSecondary">
                                                        {caseDetails.customerConcern || '--'}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                        </div>
                                        <div
                                            className={`${classes.previousCaseCardRightContainer} d-flex flex-column align-items-end`}
                                        >
                                            <Button
                                                onPress={() => onEditCase(caseDetails)}
                                                variant="ghost-blue"
                                                isLoading={
                                                    isEditLoading === caseDetails?.caseNumber ||
                                                    (isEditLoading === caseDetails?.caseNumber &&
                                                        isCaseTypeSave === LoadingType.EDIT)
                                                }
                                                style={{ borderColor: colors.blueOne, borderWidth: 1 }}
                                            >
                                                Edit case
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={handleOnHide}>
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={showCreateCaseLoader || isCaseTypeSave === LoadingType.NEW}
                        disabled={loadingCaseList}
                        onPress={onCreateNewCase}
                    >
                        Create new case
                    </Button>
                </Modal.Footer>
            </React.Fragment>
        );
    };

    return (
        <>
            <WorksheetToaster
                show={showToaster.show}
                onClose={() => setShowToaster({ show: false, message: '' })}
                body={showToaster.message}
            />
            <Modal show={props.show} centered={true} onHide={handleOnHide} size="lg">
                {currentFormType === 'disclaimerModal' && renderCaseDisclaimerModal()}
                {currentFormType === 'selectRetalier' && renderSelectRetalierForm()}
                {currentFormType === 'selectCaseTypes' && renderCaseTypesForm()}
                {/* {currentFormType === 'reviewCaseTipsAndArticles' && renderReviewCaseTipsAndArticles()} */}
                {currentFormType === 'reviewPreviousCases' && renderReviewPreviousCases()}
            </Modal>
        </>
    );
};
