import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { failCodesService, techlineService } from '@web/services/singletons';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { useAbortController } from '@packages/core/http';
import { Label, Typography, Button, Icon } from '@packages/ui/shared';
import Select, { SelectOption } from '@web/components/select';
import { FailCodeSection, FailCodeSubsection } from '@packages/models/api';
import colors from '@packages/core/styles/colors';

interface TechlineFailCodeSearchProps {
    returnToHome: () => void;
    onHide: () => void;
    updateCaseDetails: any;
    vehicleDetails: any;
}

const failCodeSearchStyles = createUseStyles({
    failCodeSearch: {},
    partRecords: {
        alignItems: 'center',
    },
    partLabel: {
        flex: 5,
    },
    newFailCodeButton: {
        border: 0,
        margin: 0,
        width: '100%',
        borderRadius: 0,
        display: 'block',
        textAlign: 'left',
        appearance: 'none',
        fontSize: '1.6rem',
        lineHeight: '2.2rem',
        padding: '13.5px 16px',
        color: colors.textDarkPrimary,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: colors.grayOne,
        },
    },
});

export const TechlineFailCodeSearch = (props: TechlineFailCodeSearchProps) => {
    const { t } = useTranslation();
    const classes = failCodeSearchStyles();
    const { abortSignalRef } = useAbortController();

    const searchInputRef = useRef<AsyncTypeahead<any>>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [options, setOptions] = useState<Array<any>>([]);
    const [selectedOption, setSelectedOption] = useState<any>();
    const [potentialFailCodeSections, setPotentialFailCodeSections] = useState<SelectOption[]>([]);
    const [selectedFailCodeSection, setSelectedFailCodeSection] = useState<FailCodeSection>();
    const [potentialFailCodeSubsections, setPotentialFailCodeSubsections] = useState<SelectOption[]>([]);
    const [selectedFailCodeSubsection, setSelectedFailCodeSubsection] = useState<FailCodeSubsection>();

    const queryFailCodes = useCallback(
        (query: string) => {
            setIsLoading(true);

            techlineService
                .searchFailCodes({
                    modelCode: props.vehicleDetails?.modelNumber,
                    modelYear: props.vehicleDetails?.modelYear,
                    optionCode: props.vehicleDetails?.optionCode,
                    query,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                })
                .then((response) => {
                    setIsLoading(false);
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }
                    setOptions(response.data.failCodes);
                    const el = document.getElementById('fail-code-search');
                    if (!el) {
                        searchInputRef.current?.toggleMenu();
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        },
        [
            abortSignalRef,
            props.vehicleDetails?.modelNumber,
            props.vehicleDetails?.modelYear,
            props.vehicleDetails?.optionCode,
        ]
    );

    const handleSubmit = () => {
        if (
            !selectedOption?.failCode ||
            !selectedFailCodeSection?.sectionName ||
            !selectedFailCodeSubsection?.subsectionName
        ) {
            return;
        }
        props.updateCaseDetails({
            repairCode: selectedOption.failCode,
            failCode: selectedOption.failCode,
            section: selectedFailCodeSection.sectionName,
            subSection: selectedFailCodeSubsection.subsectionName,
        });
        props.onHide();
    };

    useEffect(() => {
        if (!selectedOption || !selectedOption.failCode) {
            return;
        }
        techlineService
            .fetchPossibleSections({
                modelCode: props.vehicleDetails?.modelNumber,
                modelYear: props.vehicleDetails?.modelYear,
                optionCode: props.vehicleDetails?.optionCode,
                ...(selectedOption.new ? {} : { failCode: selectedOption.failCode }),
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const results = response.data.sections.map((section: { sectionName: any }) => ({
                    title: section.sectionName,
                    value: JSON.stringify(section),
                }));

                if (results.length === 1) {
                    setSelectedFailCodeSection(JSON.parse(results[0].value));
                }

                setPotentialFailCodeSections([
                    { title: t('modals:failCodeSearch.failCodeSectionPlaceholder', 'Select section'), value: '' },
                    ...(results as any[]),
                ]);
            });
    }, [
        abortSignalRef,
        props.vehicleDetails?.modelNumber,
        props.vehicleDetails?.modelYear,
        props.vehicleDetails?.optionCode,
        selectedOption,
        t,
    ]);

    useEffect(() => {
        if (!selectedOption || !selectedOption.failCode || !selectedFailCodeSection) {
            return;
        }

        async function fetchSubSections() {
            try {
                if (!selectedOption || !selectedOption.failCode || !selectedFailCodeSection) {
                    return;
                }

                const response = await techlineService.fetchPossibleSubsections({
                    ...(selectedOption.new ? {} : { failCode: selectedOption.failCode }),
                    sectionNumber: selectedFailCodeSection.sectionNumber,
                    sectionName: selectedFailCodeSection.sectionName,
                    signal: abortSignalRef.current,
                    modelCode: props.vehicleDetails?.modelNumber,
                    modelYear: props.vehicleDetails?.modelYear,
                    optionCode: props.vehicleDetails?.optionCode,
                });

                if (response && !response.success && response.aborted) {
                    return;
                } else if (response && !response.success) {
                    throw response.data;
                }

                const results = response.data.subsections.map((subsection: { subsectionName: any }) => ({
                    title: subsection.subsectionName,
                    value: JSON.stringify(subsection),
                }));

                if (results.length === 1) {
                    setSelectedFailCodeSubsection(JSON.parse(results[0].value));
                }

                setPotentialFailCodeSubsections([
                    {
                        title: t('modals:failCodeSearch.failCodeSubSectionPlaceholder', 'Select subsection'),
                        value: '',
                    },
                    ...(results as any[]),
                ]);
            } catch (error) {
                window.alert(error.message);
            }
        }

        fetchSubSections();
    }, [
        abortSignalRef,
        props.vehicleDetails?.modelNumber,
        props.vehicleDetails?.modelYear,
        props.vehicleDetails?.optionCode,
        selectedFailCodeSection,
        selectedOption,
        t,
    ]);

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>{t('modals:failCodeSearch.title', 'Fail Code Search')}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={classes.failCodeSearch}>
                {selectedOption && selectedOption.new && (
                    <Row className="mb-3">
                        <Col>
                            <Typography variant="lead" color="textDarkSecondary">
                                {t(
                                    'modals:failCodeSearch.newMatchDisclaimer',
                                    'The Fail Code you’ve chosen is not found in TechShare. Please continue only if you are sure this is the correct Fail Code for your Techline case.'
                                )}
                            </Typography>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <AsyncTypeahead
                            ref={searchInputRef}
                            id="fail-code-search"
                            onChange={([selected]) => {
                                setSelectedOption(selected);
                            }}
                            onInputChange={() => searchInputRef.current?.hideMenu()}
                            isLoading={isLoading}
                            options={options}
                            onSearch={queryFailCodes}
                            labelKey={(o) => `${o.failCode} - ${o.description}`}
                            filterBy={() => true}
                            useCache={false}
                            selected={selectedOption ? [selectedOption] : []}
                            renderMenu={(results, menuProps, state) => {
                                let index = 0;
                                const sections = groupBy(results, (item) => {
                                    if (item.failCode) {
                                        return t('modals:failCodeSearch.resultSections.failCodes', 'Fail Code');
                                    } else {
                                        // Typeahead library - throws in pagination objection along in `results`
                                        return 'unknown';
                                    }
                                });

                                const items = Object.keys(sections)
                                    // filter those out
                                    .filter((n) => n !== 'unknown')
                                    .sort()
                                    .map((sectionName) => {
                                        return (
                                            <Fragment key={sectionName}>
                                                {index !== 0 && <Menu.Divider />}

                                                <Menu.Header>
                                                    <Typography variant="label">{sectionName}</Typography>
                                                </Menu.Header>

                                                {sections[sectionName].map((i) => {
                                                    const item = (
                                                        <MenuItem key={index} option={i} position={index}>
                                                            <Highlighter search={state.text}>
                                                                {`${i.failCode} - ${i.description}`}
                                                            </Highlighter>
                                                        </MenuItem>
                                                    );

                                                    index += 1;
                                                    return item;
                                                })}
                                            </Fragment>
                                        );
                                    });

                                if (!items.length) {
                                    const stateParsed = state.text.split(' - ')[0].toUpperCase();

                                    return (
                                        <Menu {...menuProps}>
                                            <div className="px-4 py-3">
                                                <Typography variant="lead" color="grayFive">
                                                    No matches found.
                                                </Typography>
                                            </div>
                                            <button
                                                className={classes.newFailCodeButton}
                                                onClick={() => {
                                                    setSelectedOption({
                                                        new: true,
                                                        failCode: stateParsed,
                                                        description: 'No description found',
                                                    });

                                                    if (searchInputRef.current) {
                                                        //@ts-ignore
                                                        searchInputRef.current.blur();
                                                    }
                                                }}
                                            >
                                                Add "{stateParsed}" as Manual Fail Code Anyway.
                                                <br />
                                                <Typography variant="lead" color="grayFive">
                                                    Warning: Manual fail codes are not advised.
                                                </Typography>
                                            </button>
                                        </Menu>
                                    );
                                }

                                return <Menu {...menuProps}>{items}</Menu>;
                            }}
                            placeholder={t('modals:failCodeSearch.searchPlacehodler', 'Search')}
                        />
                    </Col>
                </Row>

                <hr className="my-4" />

                <Row>
                    {selectedOption ? (
                        <Col>
                            <div className="mb-4">
                                {selectedOption.new ? (
                                    <Typography variant="lead" color="textDarkSecondary">
                                        {t(
                                            'modals:failCodeSearch.newMatchInstructions',
                                            'Please select the section and subsection below to specify:'
                                        )}
                                    </Typography>
                                ) : (
                                    <Typography variant="lead" color="textDarkSecondary">
                                        {t(
                                            'modals:failCodeSearch.multipleMatchInstructions',
                                            'Your search returned multiple options. Please select the section and subsection below to specify:'
                                        )}
                                    </Typography>
                                )}
                            </div>

                            <div className="mb-4">
                                <Label>Section</Label>
                                <Select
                                    value={JSON.stringify(selectedFailCodeSection)}
                                    options={potentialFailCodeSections}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        if (!event.target.value) {
                                            setSelectedFailCodeSection(undefined);
                                            return;
                                        }
                                        setSelectedFailCodeSection(JSON.parse(event.target.value));
                                    }}
                                />
                            </div>

                            <Label>Subsection</Label>
                            <Select
                                disabled={!selectedFailCodeSection}
                                value={JSON.stringify(selectedFailCodeSubsection)}
                                options={potentialFailCodeSubsections}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    if (!event.target.value) {
                                        setSelectedFailCodeSubsection(undefined);
                                        return;
                                    }
                                    setSelectedFailCodeSubsection(JSON.parse(event.target.value));
                                }}
                            />
                        </Col>
                    ) : (
                        <Col>
                            <Typography variant="lead" color="textDarkSecondary">
                                {t(
                                    'modals:failCodeSearch.instructions.title',
                                    'Use the search bar above to find a fail code by entering any of the following:'
                                )}
                            </Typography>

                            <div className="mt-2">
                                <Typography variant="lead" color="textDarkSecondary">
                                    {t(
                                        'modals:failCodeSearch.instructions.steps',
                                        '1. Fail Code\r\n2. Fail Code description'
                                    )}
                                </Typography>
                            </div>
                        </Col>
                    )}
                </Row>
            </Modal.Body>

            <Modal.Footer className="justify-content-between">
                <Button
                    onPress={props.returnToHome}
                    variant="ghost-blue"
                    iconLeft={<Icon name="chevron-left" color="blueOne" />}
                >
                    {t('modals:failCodeSearch.actions.back', 'Back')}
                </Button>

                <Button
                    disabled={
                        !selectedOption ||
                        (!!selectedOption.failCode && (!selectedFailCodeSection || !selectedFailCodeSubsection))
                    }
                    onPress={handleSubmit}
                    isLoading={isSaving}
                >
                    {t('modals:failCodeSearch.actions.add', 'Add Fail Code')}
                </Button>
            </Modal.Footer>
        </>
    );
};
