import React, { createContext, FC, useReducer } from 'react';

import {
    ApiResponse,
    Asset,
    Qmr,
    CreateQmrDto,
    QmrResponseDto,
    PatchQmrDto,
    PatchQmrAssetDto,
    UpdateQmrDtcCodesDto,
} from '@packages/models/api';

import { QmrsService } from './qmrs.service';
import { initialState, QmrActionDispatcher, qmrReducer, QmrState, qmrActions } from './qmrs.state';
import { getQmrEwdState } from './qmrs.utils';

export const QmrStateContext = createContext<QmrState | undefined>(undefined);
export const QmrDispatchContext = createContext<QmrActionDispatcher | undefined>(undefined);

export const QmrProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(qmrReducer, initialState);

    return (
        <QmrStateContext.Provider value={state}>
            <QmrDispatchContext.Provider value={dispatch}>{children}</QmrDispatchContext.Provider>
        </QmrStateContext.Provider>
    );
};

interface QmrDataAccess {
    qmrsService: QmrsService;
    qmrDispatch: QmrActionDispatcher;
    ignoreCache?: boolean;
    signal?: AbortSignal;
}

export async function fetchQmr({
    qmrId,
    qmrsService,
    qmrDispatch,
    signal,
    ignoreCache,
}: { qmrId: Qmr['qmrId'] } & QmrDataAccess) {
    qmrDispatch(qmrActions.applyLocalPatch(null));

    return updateQmr({
        apiPromise: qmrsService.fetchQmr({
            qmrId,
            ignoreCache,
            signal,
        }),
        qmrDispatch,
    });
}

async function updateQmr({
    apiPromise,
    qmrDispatch,
}: {
    apiPromise: Promise<ApiResponse<QmrResponseDto>>;
    qmrDispatch: QmrActionDispatcher;
}) {
    const response = await apiPromise;

    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    qmrDispatch(qmrActions.updateQmr({ qmr: response.data.qmr }));

    return response.data.qmr;
}

export async function createQmr({
    newQmr,
    qmrsService,
    qmrDispatch,
    signal,
}: { newQmr: CreateQmrDto } & QmrDataAccess) {
    return updateQmr({
        apiPromise: qmrsService.createQmr(newQmr, signal),
        qmrDispatch,
    });
}

export async function saveQmr({ qmr, qmrsService, qmrDispatch, signal }: { qmr: Qmr } & QmrDataAccess) {
    const {
        qmrId,
        retailerId,
        qmrStatus,
        vin,
        modelYear,
        carlineId,
        transmissionNumber,
        engineNumber,
        complaint,
        complaintDuplicated,
        repairOrderNumber,
        mileage,
        failureDate,
        cause,
        partNumber,
        correction,
        issueResolved,
        repairDate,
        dtcCodes,
    } = qmr;

    const ewdState = getQmrEwdState(qmr);

    const apiPromise = qmrsService.saveQmr(
        qmrId,
        {
            retailerId,
            vin,
            qmrStatusId: qmrStatus.qmrStatusId,
            modelYear,
            carlineId,
            transmissionNumber,
            engineNumber,
            complaint,
            repairOrderNumber,
            mileage,
            failureDate,
            cause,
            partNumber,
            correction,
            issueResolved,
            repairDate,
            ...ewdState,
            dtcCodesSet: true,
            dtcCodes: Array.isArray(dtcCodes)
                ? dtcCodes.map((c) => {
                      return {
                          dtcCodeId: c.dtcCodeId,
                          markedAsPrimary: !!c.markedAsPrimary,
                          freezeFrameDataAvailable: !!c.freezeFrameDataAvailable,
                      };
                  })
                : [],
            // failCode: null, // TODO: Fail Code Implementation
            complaintDuplicated: complaintDuplicated || false,
        },
        signal
    );

    return updateQmr({
        apiPromise,
        qmrDispatch,
    });
}

export async function patchQmr({
    qmrId,
    qmrPatch,
    isLocalPatch = false,
    qmrsService,
    qmrDispatch,
    signal,
}: {
    qmrId: Qmr['qmrId'];
    qmrPatch: Partial<PatchQmrDto>;
    isLocalPatch?: boolean;
} & QmrDataAccess) {
    if (isLocalPatch) {
        qmrDispatch(qmrActions.applyLocalPatch(qmrPatch));
        return;
    }

    return updateQmr({
        apiPromise: qmrsService.patchQmr({ qmrId, signal, ...qmrPatch }),
        qmrDispatch,
    });
}

export async function updateQmrDtcCodes({
    qmrId,
    dtcCodes,
    qmrsService,
    qmrDispatch,
    signal,
}: {
    qmrId: Qmr['qmrId'];
    dtcCodes: UpdateQmrDtcCodesDto['dtcCodes'];
} & QmrDataAccess) {
    return updateQmr({
        apiPromise: qmrsService.updateQmrDtcCodes(qmrId, { dtcCodesSet: true, dtcCodes }, signal),
        qmrDispatch,
    });
}

export async function updateQmrRestrictedAssetIds({
    qmrId,
    restrictedAssetIds,
    qmrsService,
    qmrDispatch,
    signal,
}: {
    qmrId: Qmr['qmrId'];
    restrictedAssetIds: Asset['assetId'][];
} & QmrDataAccess) {
    return updateQmr({
        apiPromise: qmrsService.updateQmrRestrictedAssetIds(
            qmrId,
            {
                restrictedAssetIds,
            },
            signal
        ),
        qmrDispatch,
    });
}

export async function updateQmrAsset({
    assetId,
    assetPatch,
    qmrsService,
    qmrDispatch,
    signal,
}: { assetId: Asset['assetId']; assetPatch: PatchQmrAssetDto } & QmrDataAccess) {
    const response = await qmrsService.patchQmrAsset({
        assetId,
        signal,
        ...assetPatch,
    });

    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    qmrDispatch(qmrActions.updateAsset({ asset: response.data.asset }));

    return response.data.asset;
}

export async function deleteQmrAssets({
    qmrId,
    assetIds,
    qmrsService,
    qmrDispatch,
    signal,
}: { qmrId: Qmr['qmrId']; assetIds: Asset['assetId'][] } & QmrDataAccess) {
    return updateQmr({
        apiPromise: qmrsService.deleteQmrAssets(qmrId, { assetIds }, signal),
        qmrDispatch,
    });
}

export async function setQmrNoRepairModeFailCode({
    qmrId,
    locationCode,
    frequencyCode,
    concernCode,
    qmrsService,
    qmrDispatch,
    signal,
}: { qmrId: Qmr['qmrId']; locationCode: string; frequencyCode: string; concernCode: string } & QmrDataAccess) {
    return updateQmr({
        apiPromise: qmrsService.setQmrNoRepairModeFailCode({
            qmrId,
            locationCode,
            frequencyCode,
            concernCode,
            signal,
        }),
        qmrDispatch,
    });
}
