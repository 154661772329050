import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import colors from '@packages/core/styles/colors';

const useTableStyles = createUseStyles({
    tableOuter: {
        flex: 1,
        width: '100%',
        overflow: 'auto',
        position: 'relative',
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
    },
    table: {
        width: '100%',
    },
});

interface TableProps {
    className?: string;
    children?: React.ReactNode;
}

export const Table: FC<TableProps> = React.memo(({ children, className }) => {
    const classes = useTableStyles();

    return (
        <div className={classNames(classes.tableOuter, className)}>
            <table className={classes.table}>{children}</table>
        </div>
    );
});

Table.displayName = 'Table';
