import { Reducer, Dispatch } from 'react';
import { action, on, props, reducer, union } from 'ts-action';
import { NotificationStatusId, NotificationSummary } from '@packages/models/api';

export interface NotificationsState {
    notificationSummary: NotificationSummary;
}

export const notificationsActions = {
    resetState: action('RESET_NOTIFICATIONS_STATE'),
    updateNotificationSummary: action(
        'UPDATE_NOTIFICATION_SUMMARY',
        props<{ notificationSummary: NotificationSummary }>()
    ),
};

const actionsUnion = union(...Object.values(notificationsActions));
type NotificationsActions = typeof actionsUnion.actions;

export type NotificationsActionsDispatcher = Dispatch<NotificationsActions>;

export const initialState = {
    notificationSummary: {
        [NotificationStatusId.New]: {},
        [NotificationStatusId.Read]: {},
        [NotificationStatusId.Unread]: {},
    },
} as NotificationsState;

export const notificationsReducer: Reducer<NotificationsState, NotificationsActions> = reducer(
    initialState,
    on(notificationsActions.resetState, () => {
        return { ...initialState };
    }),
    on(notificationsActions.updateNotificationSummary, (state, { notificationSummary }) => {
        return {
            ...state,
            notificationSummary: notificationSummary,
        };
    })
);
