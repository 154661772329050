import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useQmrState, patchQmr, useQmrDispatch } from '@packages/contexts/qmrs';
import { analyticsService, failCodesService, qmrsService } from '@web/services/singletons';
import {
    ApiResponse,
    FailCode,
    FailCodeSection,
    FailCodeSubsection,
    FailCodeSubsectionsResponse,
} from '@packages/models/api';
import { useTranslation } from 'react-i18next';
import Select, { SelectOption } from '@web/components/select';
import { Label, Button, Icon } from '@packages/ui/shared';
import { useAbortController } from '@packages/core/http';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

interface AccidentInvestigationProps {
    onHide(): void;
    returnToHome(): void;
    handleSuccess(value: string): void;
}

export type FailCodeContainer = FailCode & FailCodeSection & FailCodeSubsection;

export const FailCodeAccidentInvestigation = (props: AccidentInvestigationProps) => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const qmrId = qmr?.qmrId ?? '';
    const { abortSignalRef } = useAbortController();

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState('');

    const [selectedOption, setSelectedOption] = useState<any>();
    const [failCodes, setFailCodes] = useState<FailCode[]>([]);
    const [potentialFailCodeSections, setPotentialFailCodeSections] = useState<SelectOption[]>([]);
    const [selectedFailCodeSection, setSelectedFailCodeSection] = useState<FailCodeSection>();
    const [potentialFailCodeSubsections, setPotentialFailCodeSubsections] = useState<SelectOption[]>([]);
    const [selectedFailCodeSubsection, setSelectedFailCodeSubsection] = useState<FailCodeSubsection>();

    useEffect(() => {
        if (!qmr) {
            return;
        }
        failCodesService
            .fetchFailCodeIncident({
                qmrId: qmr.qmrId,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const results = response.data.failCodes;
                setFailCodes(results);
            });
    }, []);

    useEffect(() => {
        if (!qmr || !selectedOption) {
            return;
        }

        failCodesService
            .fetchIncidentSections({
                qmrId: qmr.qmrId,
                failCode: selectedOption.failCode,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const results = response.data.failCodeSections.map((section) => ({
                    title: section.sectionName,
                    value: JSON.stringify(section),
                }));

                if (results.length === 1) {
                    setSelectedFailCodeSection(JSON.parse(results[0]?.value));
                }

                setPotentialFailCodeSections([
                    { title: t('modals:failCodeSearch.failCodeSectionPlaceholder', 'Select section'), value: '' },
                    ...(results as any[]),
                ]);
            });
    }, [abortSignalRef, qmr, selectedOption, t]);

    useEffect(() => {
        if (!qmr || !selectedOption || !selectedFailCodeSection) {
            return;
        }

        async function fetchSubSections() {
            try {
                if (!qmr || !selectedOption || !selectedFailCodeSection) {
                    return;
                }

                const response = await failCodesService.fetchIncidentSubsections({
                    qmrId: qmr.qmrId,
                    failCode: selectedOption.failCode,
                    sectionName: selectedFailCodeSection.sectionName,
                    signal: abortSignalRef.current,
                });

                if (response && !response.success && response.aborted) {
                    return;
                } else if (response && !response.success) {
                    throw response.data;
                }

                const results = response.data.failCodeSubsections.map((subsection) => ({
                    title: subsection.subsectionName,
                    value: JSON.stringify(subsection),
                }));

                if (results.length === 1) {
                    setSelectedFailCodeSubsection(JSON.parse(results[0].value));
                }

                setPotentialFailCodeSubsections([
                    {
                        title: t('modals:failCodeSearch.failCodeSubSectionPlaceholder', 'Select subsection'),
                        value: '',
                    },
                    ...(results as any[]),
                ]);
            } catch (error) {
                window.alert(error.message);
            }
        }

        fetchSubSections();
    }, [abortSignalRef, qmr, selectedFailCodeSection, selectedOption, t]);

    const handleReturnToHome = async () => {
        props.returnToHome();
    };

    const handleSubmit = () => {
        if (!qmr || !selectedOption) {
            return;
        }

        if (!selectedFailCodeSection || !selectedFailCodeSubsection) {
            return;
        }
        setIsSaving(true);

        return patchQmr({
            qmrId: qmr.qmrId,
            qmrPatch: {
                failCode: selectedOption.failCode,
                failCodeSectionName: selectedFailCodeSection.sectionName,
                failCodeSubsectionName: selectedFailCodeSubsection.subsectionName,
            },
            qmrsService,
            qmrDispatch,
        })
            .then((updatedQmr) => {
                if (!updatedQmr) {
                    return;
                }

                setIsSaving(false);
                analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_ADDS_FAILCODE);
                // props.handleSuccess(t('qmr:sections:failCode.successPart'));
                props.onHide();
            })
            .catch((e) => {
                setIsSaving(false);
                alert(e.message);
            });
    };

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                <h4 className="mb-0">{t('qmr:sections:failCode.accidentInvestigation')}</h4>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger" show>
                        {error}
                    </Alert>
                )}

                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <Row>
                        <Col xs={12} className="mb-4">
                            <Label>Fail code</Label>

                            <Typeahead
                                id="fail-code-accident-investigation-fail-code-section"
                                onChange={([selected]) => {
                                    setSelectedOption(selected);
                                }}
                                labelKey={(o) => `${o.failCode} - ${o.description}`}
                                options={failCodes}
                                placeholder={t('qmr:sections:failCode.enterLetterCode')}
                                selected={selectedOption ? [selectedOption] : []}
                            />
                        </Col>
                        {selectedOption && (
                            <>
                                <Col xs={12} className="mb-4">
                                    <Label>{t('qmr:sections:failCode.section')}</Label>
                                    <Select
                                        value={JSON.stringify(selectedFailCodeSection)}
                                        options={potentialFailCodeSections}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                            if (!event.target.value) {
                                                setSelectedFailCodeSection(undefined);
                                                return;
                                            }

                                            setSelectedFailCodeSection(JSON.parse(event.target.value));
                                        }}
                                    />
                                </Col>

                                <Col xs={12} className="mb-4">
                                    <Label>{t('qmr:sections:failCode.subsection')}</Label>
                                    <Select
                                        disabled={!selectedFailCodeSection}
                                        value={JSON.stringify(selectedFailCodeSubsection)}
                                        options={potentialFailCodeSubsections}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                            if (!event.target.value) {
                                                setSelectedFailCodeSubsection(undefined);
                                                return;
                                            }

                                            setSelectedFailCodeSubsection(JSON.parse(event.target.value));
                                        }}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                )}
            </Modal.Body>

            <Modal.Footer className="justify-content-between">
                <Button
                    onPress={props.returnToHome}
                    variant="ghost-blue"
                    iconLeft={<Icon name="chevron-left" color="blueOne" />}
                >
                    {t('modals:failCodeSearch.actions.back', 'Back')}
                </Button>

                <Button
                    disabled={
                        !selectedOption ||
                        (!!selectedOption.failCode && (!selectedFailCodeSection || !selectedFailCodeSubsection))
                    }
                    onPress={handleSubmit}
                    isLoading={isSaving}
                >
                    {t('modals:failCodeSearch.actions.add', 'Add Fail Code')}
                </Button>
            </Modal.Footer>
        </>
    );
};
