import { HttpClient } from '@packages/core/http';
import { GetAttachmentsDto } from '@packages/models/api';

export class ToughbookAttachmentsService {
    constructor(private http: HttpClient) {}

    getAttachments(queryParameters?: Record<string, any>) {
        return this.http.orchestrateRequest<GetAttachmentsDto>({
            method: 'GET',
            url: `/toughbook${this.http.queryString(queryParameters || {})}`,
        });
    }
}
