import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { Typography } from '@packages/ui/shared';

const useTermsAndConditionsModalStyles = createUseStyles({
    termsAndConditionsModal: {
        width: 552,
        maxWidth: 'none',
    },
});

interface TermsAndConditionsProps {
    show: boolean;
    onHide(): void;
}

const TermsAndConditions: FC<TermsAndConditionsProps> = (props) => {
    const classes = useTermsAndConditionsModalStyles();

    return (
        <Modal
            show={props.show}
            dialogClassName={classes.termsAndConditionsModal}
            onHide={() => {
                props.onHide();
            }}
            centered
        >
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>Terms and conditions</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-4">
                    <Typography variant="h4">Terms & Conditions Title</Typography>
                </div>

                {/* <div className="mb-4">
                    <Typography variant="h6">Subtitle</Typography>
                </div> */}

                <Typography variant="labelRegular">Not yet implemented.</Typography>
            </Modal.Body>
        </Modal>
    );
};

export default TermsAndConditions;
