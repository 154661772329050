import { HttpClient } from '@packages/core/http';
import { DistrictsResponseDto, RegionsResponseDto, ZonesResponseDto } from '@packages/models/api';

export class LocationsService {
    constructor(private http: HttpClient) {}

    getRegions() {
        return this.http.orchestrateRequest<RegionsResponseDto>({
            method: 'GET',
            url: '/location/regions',
        });
    }

    getZones(regionId?: string) {
        return this.http.orchestrateRequest<ZonesResponseDto>({
            method: 'GET',
            url: `/location/zones${regionId ? `?regionId=${regionId}` : ''}`,
        });
    }

    getDistricts(zoneId?: string) {
        return this.http.orchestrateRequest<DistrictsResponseDto>({
            method: 'GET',
            url: `/location/districts${zoneId ? `?zoneId=${zoneId}` : ''}`,
        });
    }
}
