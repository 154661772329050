import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { ThemeProvider } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { SharedContextProviders } from '@packages/contexts';

import { initI18n } from '@web/utils';
import globalStyles from '@web/jss/global';
import { AppNavigator } from '@web/core/navigation';
import { ContentOverlayProvider } from '@web/core/hooks/use-content-overlay';
import { SummariesProvider } from '@web/core/hooks/use-summaries';
import { ClickStateProvider } from './core/navigation/navigation.contex';

initI18n();

const useGlobalStyles = createUseStyles(globalStyles);

const queryClient = new QueryClient({ defaultOptions: { mutations: { retry: 3 } } });

const App: FC = () => {
    useGlobalStyles();

    return (
        <QueryClientProvider client={queryClient}>
            <SharedContextProviders>
                <ThemeProvider
                    prefixes={{
                        btn: 'subcomm-button',
                        card: 'subcomm-card',
                        'form-label': 'subcomm-form__label',
                        'form-control': 'subcomm-form__control',
                        'form-check': 'subcomm-form__check',
                        'modal-title': 'subcomm-modal__title',
                        'modal-header': 'subcomm-modal__header',
                        'modal-body': 'subcomm-modal__body',
                        'modal-footer': 'subcomm-modal__footer',
                        'dropdown-header': 'subcomm-dropdown__header',
                        'dropdown-divider': 'subcomm-dropdown__divider',
                    }}
                >
                    <Router>
                        <SummariesProvider>
                            <ContentOverlayProvider>
                                <ClickStateProvider>
                                    <AppNavigator />
                                </ClickStateProvider>
                            </ContentOverlayProvider>
                        </SummariesProvider>
                    </Router>
                </ThemeProvider>
            </SharedContextProviders>
        </QueryClientProvider>
    );
};

export default App;
