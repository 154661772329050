import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Typography } from '../typography';
import { Button } from '../button';
import { modalDefaultStyles, Popup, PopupProps } from '../popup';
import { Constants } from '@packages/core/config';

interface SessionTimeoutPopupProps extends PopupProps {
    onExtendSession: () => void;
    onTimeout: () => void;
}

export const SessionTimeoutPopup: FC<SessionTimeoutPopupProps> = ({ onExtendSession, onTimeout, ...props }) => {
    const { t } = useTranslation();
    const [remainingSeconds, setRemainingSeconds] = useState<number>(Constants.TimeoutPopupDurationInSeconds);

    useEffect(() => {
        if (!props.show) {
            setRemainingSeconds(Constants.TimeoutPopupDurationInSeconds);
            return;
        }

        if (remainingSeconds === 0) {
            onTimeout();
            return;
        }

        setTimeout(() => {
            setRemainingSeconds(remainingSeconds - 1);
        }, 1000);
    }, [onTimeout, props.show, remainingSeconds]);

    const remainingTimeString = useMemo(() => {
        const tmpDate = new Date(0);
        tmpDate.setSeconds(remainingSeconds);

        return tmpDate.toISOString().substr(14, 5);
    }, [remainingSeconds]);

    return (
        <Popup {...props}>
            <View style={modalDefaultStyles.header}>
                <Typography variant="h4">{t('shared:popups.sessionTimeout.title', 'Session Timeout')}</Typography>
            </View>

            <View style={modalDefaultStyles.content}>
                <Typography variant="lead">
                    {t(
                        'shared:popups.sessionTimeout.message',
                        'Your session will expire in {{seconds}} and you will be logged out. Click the button below to extend your session.',
                        { seconds: remainingTimeString }
                    )}
                </Typography>
            </View>

            <View style={modalDefaultStyles.footer}>
                <Button
                    title={t('shared:popups.sessionTimeout.extendSession', 'Extend Session')}
                    onPress={onExtendSession}
                />
            </View>
        </Popup>
    );
};
