import React, { ReactElement } from 'react';
import { Button, ButtonVariant, Icon, Typography } from '@packages/ui/shared';
import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
export interface WorksheetModalProps {
    show: boolean;
    onHideModal: () => void;
    footer?: boolean;
    handleAction?: (x: boolean) => void;
    warningMessage?: string;
    title?: string;
    bodyMessage?: string | ReactElement;
    confirmButton?: string;
    confirmButtonLoading?: boolean;
    confirmButtonColor?: ButtonVariant;
    cancelButtonColor?: ButtonVariant;
    cancelButton?: string;
    closeButton?: boolean;
}
export const WorksheetModal: FC<WorksheetModalProps> = ({
    show,
    footer = true,
    onHideModal,
    handleAction,
    warningMessage,
    title,
    bodyMessage,
    confirmButton,
    cancelButton,
    confirmButtonColor,
    cancelButtonColor,
    closeButton,
    confirmButtonLoading,
}) => {
    return (
        <Modal show={show} centered={true} onHide={onHideModal}>
            {title && (
                <Modal.Header closeButton={closeButton} placeholder={''}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            )}

            <Modal.Body>
                {warningMessage && (
                    <div className="d-flex align-items-center mb-2">
                        <Icon name="warning" color="black" style={{ marginRight: 5 }} />
                        <Typography style={{ fontWeight: 'bold' }}>{warningMessage}</Typography>
                    </div>
                )}
                {bodyMessage && <Typography>{bodyMessage}</Typography>}
            </Modal.Body>

            {footer && (
                <Modal.Footer>
                    {cancelButton && (
                        <Button
                            variant={cancelButtonColor || 'ghost-gray'}
                            onPress={() => handleAction?.(false)}
                            style={{ marginRight: 5 }}
                        >
                            {cancelButton}
                        </Button>
                    )}
                    {confirmButton && (
                        <Button
                            variant={confirmButtonColor || 'primary'}
                            onPress={() => handleAction?.(true)}
                            isLoading={confirmButtonLoading}
                        >
                            {confirmButton}
                        </Button>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    );
};
