import { AccountResponseDto, Account, PatchAccountDto, PermissionsResponse } from '@packages/models/api';
import { HttpClient } from '@packages/core/http';
import { AuthTokenService } from './auth-token.service';

export class AuthService {
    constructor(
        private http: HttpClient,
        private authTokenService: AuthTokenService
    ) {}

    getAccount(id: string, signal?: AbortSignal) {
        return this.http.orchestrateRequest<AccountResponseDto>({
            method: 'GET',
            url: `/accounts/${id}`,
            signal,
        });
    }

    getTestAccounts() {
        return this.http.orchestrateRequest<{
            accounts: { accessToken: string; account: Account }[];
        }>({
            method: 'GET',
            url: '/accounts?testAccounts=true',
            ignoreCache: true,
        });
    }

    patchAccount(accountId: string, body: PatchAccountDto, signal?: AbortSignal) {
        return this.http.orchestrateRequest<AccountResponseDto>({
            method: 'PATCH',
            url: `/accounts/${accountId}`,
            body,
            signal,
        });
    }

    fetchPermissions({ accountId, signal }: { accountId: string; signal?: AbortSignal }) {
        return this.http.orchestrateRequest<PermissionsResponse>({
            method: 'GET',
            url: `/accounts/${accountId}/permissions-debug`,
            signal: signal,
            ignoreCache: true,
        });
    }

    logout(accountId: string, signal?: AbortSignal) {
        return this.http.orchestrateRequest<void>({
            method: 'POST',
            url: '/saml/logout/',
            headers: {
                'Content-Type': 'application/json',
            },
            body: { accountId: accountId },
            signal,
        });
    }
}
