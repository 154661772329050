import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InvestigationMeetingNoteType } from '@packages/models/api';

import { Button, TextareaHelper, TextInputHelper } from '@packages/ui/shared';
import Select from '@web/components/select';

export interface MeetingNoteFormOnSubmitArgument {
    meetingNoteTypeValue: string;
    meetingNoteDateValue: string;
    meetingNoteDescriptionValue: string;
}

interface MeetingNoteFormProps {
    meetingNoteTypeOptions: InvestigationMeetingNoteType[];
    meetingNoteTypeDefaultValue?: string;
    meetingNoteDateDefaultValue?: string;
    meetingNoteDescriptionDefaultValue?: string;
    showCancelButton?: boolean;
    onCancelButtonPress?(): void;
    submitButtonTitle: string;
    isSubmitting?: boolean;
    onSubmitButtonPress({
        meetingNoteTypeValue,
        meetingNoteDateValue,
        meetingNoteDescriptionValue,
    }: MeetingNoteFormOnSubmitArgument): void;
}

export const MeetingNoteForm = ({
    meetingNoteTypeOptions,
    meetingNoteTypeDefaultValue,
    meetingNoteDateDefaultValue,
    meetingNoteDescriptionDefaultValue,
    showCancelButton,
    onCancelButtonPress,
    submitButtonTitle,
    isSubmitting,
    onSubmitButtonPress,
}: MeetingNoteFormProps) => {
    const { t } = useTranslation();

    const [meetingNoteTypeValue, setMeetingNoteTypeValue] = useState(meetingNoteTypeDefaultValue || '');
    const [meetingNoteDateValue, setMeetingNoteDateValue] = useState(meetingNoteDateDefaultValue || '');
    const [meetingNoteDescriptionValue, setMeetingNoteDescriptionValue] = useState(
        meetingNoteDescriptionDefaultValue || ''
    );

    const handleSubmitButtonPress = useCallback(() => {
        onSubmitButtonPress({
            meetingNoteTypeValue,
            meetingNoteDateValue,
            meetingNoteDescriptionValue,
        });
    }, [meetingNoteDateValue, meetingNoteDescriptionValue, meetingNoteTypeValue, onSubmitButtonPress]);

    return (
        <>
            <div className="mb-3">
                <Select
                    value={meetingNoteTypeValue}
                    options={[
                        {
                            value: '',
                            title: t(
                                'investigations:panel.meetingNotes.inputs.meetingNoteType.placeholder',
                                'Meeting Type'
                            ),
                            disabled: true,
                        },
                        ...meetingNoteTypeOptions.map((meetingNoteType) => {
                            return {
                                value: meetingNoteType.meetingTypeId,
                                title: meetingNoteType.meetingType,
                            };
                        }),
                    ]}
                    onChange={(event) => {
                        setMeetingNoteTypeValue(event.currentTarget.value);
                    }}
                    required
                />
            </div>
            <div className="mb-3">
                <TextInputHelper
                    type="date"
                    value={meetingNoteDateValue}
                    placeholder={t('investigations:panel.meetingNotes.inputs.meetingDate.placeholder', 'Meeting Date')}
                    onChangeText={setMeetingNoteDateValue}
                />
            </div>
            <div className="mb-3">
                <TextareaHelper
                    value={meetingNoteDescriptionValue}
                    placeholder={t(
                        'investigations:panel.meetingNotes.inputs.meetingDescription.placeholder',
                        'Meeting Description'
                    )}
                    onChangeText={setMeetingNoteDescriptionValue}
                />
            </div>
            <div className="mb-4 d-flex justify-content-end">
                {showCancelButton && (
                    <div className="mr-2">
                        <Button
                            size="small"
                            variant="ghost-blue"
                            title={t('buttons:cancel')}
                            onPress={onCancelButtonPress}
                        />
                    </div>
                )}
                <Button
                    isLoading={isSubmitting}
                    size="small"
                    title={submitButtonTitle}
                    onPress={handleSubmitButtonPress}
                    disabled={!meetingNoteTypeValue || !meetingNoteDateValue || !meetingNoteDescriptionValue}
                />
            </div>
        </>
    );
};
