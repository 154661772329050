import React, { FC, useCallback, useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import colors from '@packages/core/styles/colors';

import {
    Icon,
    LoadingCircle,
    Button,
    NotificationPreferenceOrderConfig,
    SpecialCasesConfig,
    QmrNotifcationLockedRoles,
    NotificationTypeNames,
    NotificationAPINameToHumanReadable,
    getAccountCapabilitiesPerNotification,
    Typography,
} from '@packages/ui/shared';
import { Account, NotificationPreference, PatchAccountDto } from '@packages/models/api';

import './account-settings-notifications.css';
import { notificationsService } from '@web/services/singletons';
import { createUseStyles } from 'react-jss';

interface NotificationSettingPaneProps {
    enableEmail: boolean | undefined;
    setEnableEmail: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    account: Account | null;
    patchAccount: (patch: PatchAccountDto) => Promise<any>;
}

interface TooltipsState {
    [key: string]: TooltipInfo;
}

interface TooltipInfo {
    show: boolean;
    content: string;
}

const useStyles = createUseStyles({
    notificationSettingLoading: {
        position: 'fixed', // Fixed position relative to the viewport
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    tableHeader: {
        borderRight: '1px solid #dee2e6',
    },
    tableCell: {
        borderRight: '1px solid #dee2e6',
    },
    notificationSwitch: {
        display: 'flex',
        justifyContent: 'end',
    },
    notificationCellContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: '0.25rem',
    },
});

const NotificationSettingPane: FC<NotificationSettingPaneProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [tooltips, setTooltips] = useState<TooltipsState>({});
    const [notificationPreferences, setNotificationPreferences] = useState<any>(undefined);

    const changedPreferences: {
        notificationPreferenceId: string;
        accountId: string;
        notificationTypeId: string;
        appNotification: boolean;
        emailNotification: boolean;
        mobilePushNotification: boolean;
    }[] = [];

    // Tooltip handling
    const handleMouseEnter = (id: string, content: string) => {
        setTooltips((prevTooltips) => ({
            ...prevTooltips,
            [id]: { show: true, content },
        }));
    };

    const handleMouseLeave = (id: string) => {
        setTooltips((prevTooltips) => ({
            ...prevTooltips,
            [id]: { ...prevTooltips[id], show: false },
        }));
    };

    const getFlattenNotificationPreferences = (): NotificationPreference[] => {
        if (!notificationPreferences) {
            return [];
        }

        return Object.values(notificationPreferences).flat() as NotificationPreference[];
    };

    const isAnyNotificationOn = (method: string) => {
        if (!notificationPreferences) {
            return false;
        }

        const allNotifications: NotificationPreference[] = getFlattenNotificationPreferences();
        return allNotifications.some((notification: NotificationPreference) => {
            const specialCase = SpecialCasesConfig[notification.notificationTypeId];
            const isGlobalLockedOn = specialCase?.isGlobalLockedOn || false;

            // Exclude notifications that are globally locked, because they are always ON. We ignore them for this condition check.
            if (isGlobalLockedOn) {
                return false;
            }

            // For email notifications, also check if email is globally enabled
            if (method === 'emailNotification' && !props.enableEmail) {
                return false;
            }
            return notification[method as keyof NotificationPreference];
        });
    };

    const [isGlobalInAppOn, setIsGlobalInAppOn] = useState(isAnyNotificationOn('appNotification'));
    const [isGlobalEmailOn, setIsGlobalEmailOn] = useState(isAnyNotificationOn('emailNotification'));
    const [isGlobalMobilePushOn, setIsGlobalMobilePushOn] = useState(isAnyNotificationOn('mobilePushNotification'));

    useEffect(() => {
        fetchNotificationPreferences();
    }, []);

    useEffect(() => {
        setIsGlobalInAppOn(isAnyNotificationOn('appNotification'));
        setIsGlobalEmailOn(isAnyNotificationOn('emailNotification'));
        setIsGlobalMobilePushOn(isAnyNotificationOn('mobilePushNotification'));
    }, [notificationPreferences]);

    const handleGlobalDeliveryMethodChange = async (method: string, value: boolean) => {
        setIsLoading(true);
        if (!notificationPreferences) {
            return;
        }

        const allNotifications: NotificationPreference[] = getFlattenNotificationPreferences();
        allNotifications.forEach((notification: NotificationPreference) => {
            // Determine if the notification should be changed based on global locks or specific in-app locks
            const isGlobalLockedOn = SpecialCasesConfig[notification.notificationTypeId]?.isGlobalLockedOn || false;
            const isInAppLockedOn =
                method === 'appNotification' && SpecialCasesConfig[notification.notificationTypeId]?.isInAppLockedOn;

            // If there is a global lock or an in-app lock (for in-app notifications), do not change its status
            if (!isGlobalLockedOn && !isInAppLockedOn) {
                changedPreferences.push({
                    notificationPreferenceId: notification.notificationPreferenceId,
                    accountId: notification.accountId,
                    notificationTypeId: notification.notificationTypeId,
                    appNotification: method === 'appNotification' ? value : notification.appNotification,
                    emailNotification: method === 'emailNotification' ? value : notification.emailNotification,
                    mobilePushNotification:
                        method === 'mobilePushNotification' ? value : notification.mobilePushNotification,
                });
            }
        });

        try {
            const response = await notificationsService.updateNotificationPreferences({
                notificationPreferences: changedPreferences,
            });

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }
            changedPreferences.length = 0;
        } catch (error) {
            window.alert(error.message);
            console.error(error);
        }

        if (method === 'emailNotification' && value === true) {
            props.setEnableEmail(value);
            props.patchAccount({ emailNotificationsEnabled: value });
        }

        fetchNotificationPreferences();
        setIsLoading(false);
    };

    const resetPreferencesToDefault = async () => {
        setIsLoading(true);
        try {
            const response = await notificationsService.deleteNotificationPreferences();
            props.setEnableEmail(true);
            props.patchAccount({ emailNotificationsEnabled: true });

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
            fetchNotificationPreferences();
        } catch (error) {
            window.alert(error.message);
            console.error(error);
        }

        setIsLoading(false);
    };

    const fetchNotificationPreferences = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await notificationsService.fetchNotificationPreferences(undefined, true);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }
            let data = response.data.notificationPreferences;
            // Edge Case: Merge CSV export notifs into the QMR notifications category
            if (data.csvExportNotifications && data.qmrNotifications) {
                // Merge the csvExportNotifications into qmrNotifications
                data.qmrNotifications = [...data.qmrNotifications, ...data.csvExportNotifications];
                // Delete the original csvExportNotifications category
                delete data.csvExportNotifications;
            }

            // Update the state with the modified data
            setNotificationPreferences(data);
        } catch (error) {
            window.alert(error.message);
            console.error(error);
        }

        setIsLoading(false);
    }, []);

    const handleToggleChange = async (notificationPreference: NotificationPreference, deliveryMethodType: string) => {
        // Determine the key under which the notification preference exists
        const key = Object.keys(notificationPreferences).find((k) =>
            notificationPreferences[k].some(
                (np: { notificationPreferenceId: string }) =>
                    np.notificationPreferenceId === notificationPreference.notificationPreferenceId
            )
        );

        if (!key) {
            return; // If the notification preference is not found, exit the function
        }

        // Update the state with the new preferences
        setNotificationPreferences((prevPreferences: { [x: string]: any[] }) => {
            return {
                ...prevPreferences,
                [key]: prevPreferences[key].map((pref) => {
                    if (pref.notificationPreferenceId === notificationPreference.notificationPreferenceId) {
                        return {
                            ...pref,
                            appNotification:
                                deliveryMethodType === 'appNotification' ? !pref.appNotification : pref.appNotification,
                            emailNotification:
                                deliveryMethodType === 'emailNotification'
                                    ? !pref.emailNotification
                                    : pref.emailNotification,
                            mobilePushNotification:
                                deliveryMethodType === 'mobilePushNotification'
                                    ? !pref.mobilePushNotification
                                    : pref.mobilePushNotification,
                        };
                    }
                    return pref;
                }),
            };
        });

        changedPreferences.push({
            notificationPreferenceId: notificationPreference.notificationPreferenceId,
            accountId: notificationPreference.accountId,
            notificationTypeId: notificationPreference.notificationTypeId,
            // for the changed preference, set the opposite value (!), for the rest, set the same value
            appNotification:
                deliveryMethodType === 'appNotification'
                    ? !notificationPreference.appNotification
                    : notificationPreference.appNotification,
            emailNotification:
                deliveryMethodType === 'emailNotification'
                    ? !notificationPreference.emailNotification
                    : notificationPreference.emailNotification,
            mobilePushNotification:
                deliveryMethodType === 'mobilePushNotification'
                    ? !notificationPreference.mobilePushNotification
                    : notificationPreference.mobilePushNotification,
        });

        try {
            const response = await notificationsService.updateNotificationPreferences({
                notificationPreferences: changedPreferences,
            });

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }
            changedPreferences.length = 0;
        } catch (error) {
            window.alert(error.message);
            console.error(error);
        }
    };

    const renderTooltip = (id: string) => {
        return (
            <>
                <Icon size={16} name="question-mark" color="blueOne" />
                {tooltips[id]?.show && (
                    <div
                        className="py-2 px-2 text-center"
                        style={{
                            position: 'absolute',
                            background: 'gray',
                            borderRadius: '5px',
                            zIndex: 100,
                            // if id is "mobileIcon", add a margin so it's not constrained to the right of the screen (since it's the last element)
                            marginLeft: id === 'mobileIcon' ? '-7.5rem' : '',
                        }}
                    >
                        <Typography color="white">{tooltips[id].content}</Typography>
                    </div>
                )}
            </>
        );
    };

    const shouldRenderNotification = (notificationTypeId: string) => {
        const specialCase = SpecialCasesConfig[notificationTypeId];
        if (!specialCase || !specialCase.shouldRenderCondition) {
            return true; // Render by default if there's no special condition
        }
        // Check if the condition specified in the config is satisfied
        return getAccountCapabilitiesPerNotification(props.account, specialCase.shouldRenderCondition);
    };

    const renderNotificationRows = () => {
        const allNotificationsFlat: NotificationPreference[] = getFlattenNotificationPreferences();

        allNotificationsFlat.forEach((notification: NotificationPreference) => {
            if (!NotificationTypeNames[notification.notificationTypeId]) {
                NotificationTypeNames[notification.notificationTypeId] = NotificationAPINameToHumanReadable(
                    notification.notificationTypeId
                );
            }
        });

        if (props.account?.baseRoleIds.some((roleId) => QmrNotifcationLockedRoles.includes(roleId))) {
            allNotificationsFlat.forEach((notification: NotificationPreference) => {
                if (SpecialCasesConfig[notification.notificationTypeId]) {
                    SpecialCasesConfig[notification.notificationTypeId].isInAppLockedOn = true;
                } else {
                    SpecialCasesConfig[notification.notificationTypeId] = { isInAppLockedOn: true };
                }
            });
        }
        // 1.) We predefine the order of category & individual type to display.
        // 2.) We then filter and order the notifications as per the predefined order.
        // 3.) We then find and append notifications not included in the predefined order.
        //    - So that means even if a type ISN'T included, it will still be displayed at the end.
        // 4.) Finally, we render the table rows based on the ordered notifications.

        if (!notificationPreferences) {
            return null;
        }

        return Object.entries(NotificationPreferenceOrderConfig)
            .filter(([categoryKey]) => {
                if (
                    props.account?.investigationCapabilities.viewInvestigations === false &&
                    categoryKey === 'investigationNotifications'
                ) {
                    return false;
                }
                return true;
            })
            .map(([categoryKey, typeOrder]) => {
                const notifications = notificationPreferences[categoryKey];
                if (!notifications) {
                    return null; // Gracefully handle if predefined category not in preferences
                }

                // Step 1: Filter and order the notifications as per the predefined order
                const orderedNotifications = typeOrder
                    .map((typeKey) =>
                        notifications.find((n: { notificationTypeId: string }) => n.notificationTypeId === typeKey)
                    )
                    .filter(Boolean); // Remove undefined entries

                // Step 2: Find and append notifications not included in the predefined order
                const remainingNotifications = notifications.filter(
                    (n: { notificationTypeId: string }) => !typeOrder.includes(n.notificationTypeId)
                );

                // Combine the ordered notifications with the remaining ones
                const allNotifications = [...orderedNotifications, ...remainingNotifications];

                if (allNotifications.length === 0) {
                    return null;
                }

                return (
                    <React.Fragment key={categoryKey}>
                        <tr>
                            <th
                                colSpan={4}
                                style={{
                                    textAlign: 'left',
                                    paddingTop: '20px',
                                    paddingBottom: '10px',
                                    backgroundColor: '#f8f9fa',
                                }}
                            >
                                {/* Gotta love regex manipulation! If we get more categories, we should map it to a list though */}
                                {/* This converts the API returned category name "qmrNotification" or "investigationNotification" to a friendlier name: "QMR Notiifcations" */}
                                {categoryKey
                                    .replace(/([A-Z])/g, ' $1') // First, convert camelCase to space-separated words
                                    // Ensure the first character of the string is uppercase (handles point 2 for all cases except "qmr")
                                    .replace(/^./, function (str) {
                                        return str.toUpperCase();
                                    })
                                    // Check if the result starts with "Qmr" (since the first letter is already uppercase) and, if so, convert it to uppercase
                                    .replace(/^Qmr/, 'QMR')
                                    .replace(/C\s*S\s*V/g, 'CSV')
                                    .trim()}
                            </th>
                        </tr>
                        {allNotifications.map((notification: NotificationPreference, index) =>
                            shouldRenderNotification(notification.notificationTypeId) ? (
                                <tr key={index}>
                                    <td className={classes.tableCell}>
                                        {/* Use predefined name or else just use regex */}
                                        {NotificationTypeNames[notification.notificationTypeId] ||
                                            notification.notificationTypeId.replace(/_/g, ' ').toLowerCase()}
                                        {/* if SpecialCasesConfig.notificationTypeId.tooltip exists, render the tooltip icon */}
                                        {SpecialCasesConfig[notification.notificationTypeId]?.tooltip && (
                                            <span
                                                className="align-middle ml-1"
                                                onMouseEnter={() =>
                                                    handleMouseEnter(
                                                        notification.notificationTypeId,
                                                        SpecialCasesConfig[notification.notificationTypeId].tooltip ||
                                                            ''
                                                    )
                                                }
                                                onMouseLeave={() => handleMouseLeave(notification.notificationTypeId)}
                                            >
                                                {renderTooltip(notification.notificationTypeId)}
                                            </span>
                                        )}
                                    </td>
                                    <td className={classes.tableCell}>
                                        {SpecialCasesConfig[notification.notificationTypeId]?.hasInApp !== false && (
                                            <div className={classes.notificationCellContent}>
                                                {/* display lock only if conditions for locked on */}
                                                {SpecialCasesConfig[notification.notificationTypeId]?.isInAppLockedOn ||
                                                SpecialCasesConfig[notification.notificationTypeId]
                                                    ?.isGlobalLockedOn ? (
                                                    <Icon size={16} name="lock-closed" color="blueOne" />
                                                ) : null}

                                                <Form.Check
                                                    className={classes.notificationSwitch}
                                                    type="switch"
                                                    id={`${notification.notificationTypeId}-inapp`}
                                                    checked={notification.appNotification}
                                                    onChange={() => handleToggleChange(notification, 'appNotification')}
                                                    disabled={
                                                        SpecialCasesConfig[notification.notificationTypeId]
                                                            ?.isInAppLockedOn ||
                                                        SpecialCasesConfig[notification.notificationTypeId]
                                                            ?.isGlobalLockedOn ||
                                                        false
                                                    }
                                                />
                                            </div>
                                        )}
                                    </td>
                                    <td className={classes.tableCell}>
                                        {SpecialCasesConfig[notification.notificationTypeId]?.hasEmail !== false && (
                                            <div className={classes.notificationCellContent}>
                                                {/* display lock only if conditions for locked on */}
                                                {SpecialCasesConfig[notification.notificationTypeId]
                                                    ?.isGlobalLockedOn ? (
                                                    <Icon size={16} name="lock-closed" color="blueOne" />
                                                ) : null}
                                                <Form.Check
                                                    className={classes.notificationSwitch}
                                                    type="switch"
                                                    id={`${notification.notificationTypeId}-email`}
                                                    checked={props.enableEmail && notification.emailNotification}
                                                    onChange={() =>
                                                        handleToggleChange(notification, 'emailNotification')
                                                    }
                                                    disabled={
                                                        SpecialCasesConfig[notification.notificationTypeId]
                                                            ?.isGlobalLockedOn || false
                                                    }
                                                />
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {SpecialCasesConfig[notification.notificationTypeId]?.hasPush !== false && (
                                            <div className={classes.notificationCellContent}>
                                                {/* display lock only if conditions for locked on */}
                                                {SpecialCasesConfig[notification.notificationTypeId]
                                                    ?.isGlobalLockedOn ? (
                                                    <Icon size={16} name="lock-closed" color="blueOne" />
                                                ) : null}
                                                <Form.Check
                                                    className={classes.notificationSwitch}
                                                    type="switch"
                                                    id={`${notification.notificationTypeId}-mobile`}
                                                    checked={notification.mobilePushNotification}
                                                    onChange={() =>
                                                        handleToggleChange(notification, 'mobilePushNotification')
                                                    }
                                                    disabled={
                                                        SpecialCasesConfig[notification.notificationTypeId]
                                                            ?.isGlobalLockedOn || false
                                                    }
                                                />
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ) : null
                        )}
                    </React.Fragment>
                );
            });
    };

    return (
        <>
            {isLoading && (
                <div className={classes.notificationSettingLoading}>
                    <LoadingCircle size={64} borderWidth={8} borderColor={colors.grayOne} />
                </div>
            )}

            <Table id="notification-setting-table">
                <thead>
                    <tr>
                        <th style={{ width: '25%' }} className={classes.tableHeader}>
                            Type
                        </th>
                        <th style={{ width: '25%' }} className={classes.tableHeader}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <span>In-App</span>
                                <span
                                    className="d-flex ml-1"
                                    onMouseEnter={() =>
                                        handleMouseEnter(
                                            'inAppIcon',
                                            'In-App notifications are delivered within the TechShare application and are visible in the notification panel.'
                                        )
                                    }
                                    onMouseLeave={() => handleMouseLeave('inAppIcon')}
                                >
                                    {renderTooltip('inAppIcon')}
                                </span>
                                <Form.Check
                                    type="switch"
                                    className={'ml-1'}
                                    id="global-inapp"
                                    checked={isGlobalInAppOn}
                                    onChange={() => {
                                        handleGlobalDeliveryMethodChange('appNotification', !isGlobalInAppOn);
                                    }}
                                    disabled={props.account?.baseRoleIds.some((roleId) =>
                                        QmrNotifcationLockedRoles.includes(roleId)
                                    )}
                                />
                            </div>
                        </th>
                        <th style={{ width: '25%' }} className={classes.tableHeader}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <span>Email</span>
                                <span
                                    className="d-flex ml-1"
                                    onMouseEnter={() =>
                                        handleMouseEnter(
                                            'emailIcon',
                                            props.account?.emailAddresses[0]?.emailAddress
                                                ? `Email notifications are delivered to ${props.account.emailAddresses[0].emailAddress}.`
                                                : 'Email notifications are delivered to your email.'
                                        )
                                    }
                                    onMouseLeave={() => handleMouseLeave('emailIcon')}
                                >
                                    {renderTooltip('emailIcon')}
                                </span>
                                <Form.Check
                                    type="switch"
                                    className={'ml-1'}
                                    id="global-email"
                                    checked={isGlobalEmailOn}
                                    onChange={() => {
                                        handleGlobalDeliveryMethodChange('emailNotification', !isGlobalEmailOn);
                                    }}
                                />
                            </div>
                        </th>
                        <th style={{ width: '25%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <span>Mobile Push</span>
                                <span
                                    className="d-flex ml-1"
                                    onMouseEnter={() =>
                                        handleMouseEnter(
                                            'mobileIcon',
                                            'Mobile Push notifications are brief messages that appear on your device (if installed) without needing to open the app.'
                                        )
                                    }
                                    onMouseLeave={() => handleMouseLeave('mobileIcon')}
                                >
                                    {renderTooltip('mobileIcon')}
                                </span>
                                <Form.Check
                                    type="switch"
                                    className={'ml-1'}
                                    id="global-push"
                                    checked={isGlobalMobilePushOn}
                                    onChange={() => {
                                        handleGlobalDeliveryMethodChange(
                                            'mobilePushNotification',
                                            !isGlobalMobilePushOn
                                        );
                                    }}
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>{notificationPreferences && renderNotificationRows()}</tbody>{' '}
            </Table>

            <div className="d-flex justify-content-start mt-4">
                <Button variant="danger" onPress={resetPreferencesToDefault}>
                    {t('Reset to Default')}
                </Button>
            </div>
        </>
    );
};

export default NotificationSettingPane;
