import React, { FC } from 'react';

import { configService } from '@web/services/singletons';
import { DetailView, DetailViewBody, DetailViewContent, DetailViewHeader } from '@web/components/detail-view';
import { Typography } from '@packages/ui/shared';

const Settings: FC = () => {
    return (
        <DetailView>
            <DetailViewHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <Typography variant="h3">Settings</Typography>
                </div>
            </DetailViewHeader>

            <DetailViewContent topOffset="header">
                <DetailViewBody>
                    <pre>
                        {JSON.stringify(
                            // @ts-ignore --- buildConfig is private
                            configService.buildConfig,
                            null,
                            2
                        )}
                    </pre>
                </DetailViewBody>
            </DetailViewContent>
        </DetailView>
    );
};

export default Settings;
