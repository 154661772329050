import { AlertBar, Button, Icon, LoadingCircle, TextBadge, Typography } from '@packages/ui/shared';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { Table, TableBody, TableCell, TableHead, TableHeader, TablePagination, TableRow } from '@web/components/table';
import { useNavigate } from 'react-router-dom';
import { CreateCaseModal, TechlineQuickFilters } from '../components';
import { useContentOverlayState, useQuery } from '@web/core/hooks';
import { techlineService, httpClient, analyticsService } from '@web/services/singletons';
import { useAuthState } from '@packages/contexts/auth';
import { useAbortController } from '@packages/core/http';
import { caseStatusMap as statusMap, getBadgeVariantTechline } from '@web/utils';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const ALLOW_VIEW_DRAFT_PERMISSION = 'TECHLINE015';

const useStyles = createUseStyles({
    tableHead: {
        fontWeight: 600,
    },
    tableColumn: {
        width: 100,
    },
    loaderCircleOuter: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    createCaseCard: {
        height: '272px',
        width: '365px',
    },
    quickTipCard: {
        height: '136px',
        width: '365px',
    },
    card: {
        height: '168px',
        width: '365px',
        borderLeft: `3px solid ${colors.grayThree}`,
        backgroundColor: colors.grayOne,
    },
    cardBody: {
        flex: 0.7,
    },
    cardBorderRadius: {
        borderRadius: 5,
    },
    primaryOutlineBtn: {
        color: colors.blueOne,
        borderColor: colors.blueOne,
        padding: 10,
        borderRadius: 4,
        '&:hover, &:active': {
            backgroundColor: 'transparent',
            borderColor: colors.blueOne,
        },
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    actionsPopover: {
        minWidth: 200,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        borderRadius: 4,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
    },
    popoverActionsSection: {
        padding: '4px 0',
    },
    dropdownButton: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        width: '100%',
        borderRadius: 3,
        cursor: 'pointer',
        padding: '0 12px',
        appearance: 'none',
        color: colors.blueOne,
        textAlign: 'left',
        backgroundColor: colors.white,
        border: `1px solid ${colors.blueOne}`,
    },
    dropdownButtonIcon: {
        marginLeft: '5px',
        marginTop: '6px',
    },
    caseNumberLink: {
        color: '#007bff',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
});
const emptyFilters = {
    vin: '',
    selectedStatus: [],
    selectedCasetypes: [],
    selectedSubmissionDate: [],
};
export interface Retailer {
    retailerName: string;
    retailerCode: string;
    retailerId: string;
    userName: string;
    retailerNumber: string;
}
export const AllCases = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const queryParams = useQuery();
    const showSubmitCaseSuccessForCaseNumber = queryParams.get('showSubmitCaseSuccessFor');

    const { abortSignalRef } = useAbortController();
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const { account } = useAuthState();
    const [filters, setFilters] = useState(emptyFilters);
    const [currentStatusFilter, setCurrentStatusFilter] = useState('ALL');
    const [searchQuery, setSearchQuery] = useState('');
    const [isRetailerPopoverOpen, setIsRetailerPopoverOpen] = useState(false);
    const [selectedRetailer, setSelectedRetailer] = useState<Retailer[]>([]);
    const [allRetailers, setAllRetailers] = useState<Retailer[]>([]);
    const [loadingCaseList, setLoadingCaseList] = useState(false);
    const [allCases, setAllCases] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(0);
    const selectedTabHeadStyles = {
        borderBottomColor: colors.blueOne,
        color: colors.blueOne,
        borderBottomWidth: 2,
        paddingBottom: 5,
    };
    const [showCreateCaseModal, setShowCreateCaseModal] = useState(false);
    const [showSubmitCaseSuccessPopup, setShowSubmitCaseSuccessPopup] = useState(false);
    const [permissions, setPermissions] = useState<any>({});

    useEffect(() => {
        if (showSubmitCaseSuccessForCaseNumber) {
            setShowSubmitCaseSuccessPopup(true);
            setTimeout(() => {
                setShowSubmitCaseSuccessPopup(false);
                navigate('/techline/all-cases', { replace: true });
            }, 3000);
        }
    }, [navigate, queryParams, showSubmitCaseSuccessForCaseNumber]);

    useEffect(() => {
        (async () => {
            try {
                const retailersRes = await techlineService.retrieveRetailer(false, abortSignalRef.current);
                if (retailersRes?.data?.results?.length) {
                    setAllRetailers(retailersRes.data.results);
                    setSelectedRetailer([retailersRes.data.results[0]]);
                }
            } catch (e) {
                alert('Error: ' + e + '. Please refresh & try again.');
                console.log(e);
            }
        })();
    }, [abortSignalRef, account]);

    const fetchPermissions = useCallback(() => {
        if (!account || !account.accountId) {
            return;
        }
        const config = {
            method: 'GET' as const,
            signal: abortSignalRef.current,
            ignoreCache: true,
        };
        httpClient
            .orchestrateRequest<any>({
                ...config,
                url: `/accounts/${account.accountId}/permissions-debug`,
            })
            .then((permissionsResponse) => {
                if (permissionsResponse.success) {
                    let allPermissions = [...(permissionsResponse.data?.globalPermissions || [])];
                    if (permissionsResponse.data?.retailerPermissions?.length) {
                        permissionsResponse.data.retailerPermissions.forEach((item: any) => {
                            if (item.permissions?.length) {
                                allPermissions = [...allPermissions, ...item.permissions];
                            }
                        });
                    }
                    setPermissions(allPermissions);
                }
            })
            .catch((e) => {
                alert('Error: ' + e + '. Please refresh & try again.');
                console.log('error::', e);
            });
    }, [abortSignalRef, account]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    const fetchAllRetailers = async (query: string) => {
        try {
            const retailersRes = await techlineService.retrieveRetailer(true, abortSignalRef.current, query);
            if (retailersRes?.data?.results?.length) {
                setAllRetailers(retailersRes.data.results);
            }
        } catch (e) {
            alert('Error: ' + e + '. Please refresh & try again.');
            console.log(e);
        }
    };

    const fetchAllCases = useCallback(async () => {
        setAllCases([]);
        if (selectedRetailer?.[0]?.retailerNumber) {
            try {
                setLoadingCaseList(true);
                const allCasesRes = await techlineService.retrieveCases(
                    selectedRetailer?.[0]?.retailerNumber,
                    true,
                    abortSignalRef.current
                );
                if (allCasesRes?.data?.results?.length) {
                    setAllCases(allCasesRes.data.results);
                }
                setLoadingCaseList(false);
            } catch (e) {
                console.log(e);
                setLoadingCaseList(false);
            }
        }
    }, [abortSignalRef, selectedRetailer]);
    useEffect(() => {
        fetchAllCases();
    }, [fetchAllCases, selectedRetailer]);
    const filtersCount = useMemo(() => {
        let count = 0;
        if (filters.vin && filters.vin.length) {
            count += 1;
        }
        if (filters.selectedStatus && filters.selectedStatus.length) {
            count += 1;
        }
        if (filters.selectedCasetypes && filters.selectedCasetypes.length) {
            count += 1;
        }
        if (filters.selectedSubmissionDate && filters.selectedSubmissionDate.length) {
            count += 1;
        }
        return count;
    }, [filters]);
    const applyStatusFilter = useCallback(
        (allCases = []) => {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_SEARCHES_TECHLINE_CASES);
            if (currentStatusFilter === 'ALL') return allCases;
            if (allCases.length) {
                return allCases.filter((caseItem: { techlineCaseStatus: string }) =>
                    statusMap[currentStatusFilter].includes(caseItem.techlineCaseStatus)
                );
            }
            return [];
        },
        [currentStatusFilter]
    );
    const applyOtherSideFilters = useCallback(
        (allCases = []) => {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_SEARCHES_TECHLINE_CASES);
            if (allCases.length) {
                return allCases.filter((caseItem: { vin: string; techlineCaseStatus: string; caseType: string }) => {
                    if (filters.vin?.length && caseItem.vin !== filters.vin) return false;
                    if (
                        filters.selectedStatus?.length &&
                        !filters.selectedStatus
                            .map((status: any) => status.value)
                            .filter((status) => statusMap[status].includes(caseItem.techlineCaseStatus))?.length
                    )
                        return false;
                    if (
                        filters.selectedCasetypes?.length &&
                        !filters.selectedCasetypes
                            .map((caseType: any) => caseType.caseTypeName)
                            .filter((caseType) => caseType === caseItem.caseType)?.length
                    )
                        return false;
                    return true;
                });
            }
            return [];
        },
        [filters]
    );

    const showDraftCases = useMemo(() => {
        if (permissions.length) {
            return !!permissions.find(
                (permission: { permissionId: string }) => permission.permissionId === ALLOW_VIEW_DRAFT_PERMISSION
            );
        }
        return false;
    }, [permissions]);

    const displayCases = useMemo(() => {
        if (!allCases.length) return [];
        let finalCases = [...allCases];
        if (!showDraftCases) {
            finalCases = finalCases.filter(
                (caseItem: { techlineCaseStatus: string }) => !statusMap['DRAFT'].includes(caseItem.techlineCaseStatus)
            );
        }
        const statusFilteredCases = applyStatusFilter(finalCases);
        const otherFilteredCases = applyOtherSideFilters(statusFilteredCases);
        return [...otherFilteredCases].slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
    }, [allCases, applyOtherSideFilters, applyStatusFilter, pageIndex, pageSize, showDraftCases]);

    const toggleTechlineFilters = (filters?: any) => {
        toggleOverlay('techlineQuickFilters');
    };
    const handlePaginationClick = useCallback((index: number) => {
        setPageIndex(index);
    }, []);
    const handlePaginationSizeChange = useCallback((size: number) => {
        setPageSize(size);
    }, []);

    const navigateToCaseDetails = (item: any) => {
        if (item.caseNumber) {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_OPENS_EXISTING_CASE);
            window.open(`/techline/${item.caseNumber}`, '_blank');
        }
    };
    const renderTable = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>
                            <Typography variant="h5">Case No</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">VIN number</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Status</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Case type</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Created by</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">RO</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Last updated</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Created Date</Typography>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayCases.map((item: any) => {
                        return (
                            <TableRow key={item.caseNumber}>
                                <TableCell className={`${classes.tableColumn}`} width={150}>
                                    <Typography>
                                        <div
                                            className={`${classes.caseNumberLink}`}
                                            onClick={() => navigateToCaseDetails(item)}
                                        >
                                            {item.caseNumber}
                                        </div>
                                    </Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableColumn}`}>
                                    <Typography>{item.vin}</Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableColumn} d-flex`}>
                                    <TextBadge variant={getBadgeVariantTechline(item.techlineCaseStatus)}>
                                        {item.techlineCaseStatus}
                                    </TextBadge>
                                </TableCell>
                                <TableCell className={`${classes.tableColumn}`}>
                                    <Typography>{item.caseType}</Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableColumn}`}>
                                    <Typography>{item.fullName}</Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableColumn}`}>
                                    <Typography>{item.repairOrderNumber}</Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableColumn}`}>
                                    <Typography>{item.modifiedOnDescription}</Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableColumn}`}>
                                    <Typography>{item.createdOnDescription}</Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {loadingCaseList && (
                        <div className="mt-20 mb-10">
                            <TableRow>
                                <TableCell>
                                    <div className={classes.loaderCircleOuter}>
                                        <LoadingCircle size={64} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </div>
                    )}
                    {!displayCases.length && !loadingCaseList && (
                        <TableRow>
                            <div className="ml-5 mt-10 mb-10">
                                <Typography>No Matching Records Found!</Typography>
                            </div>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    };
    const renderSelectRetailer = () => {
        if (!allRetailers?.length) return;
        return (
            <div className="w-25">
                <Typeahead
                    id="select-retailer"
                    selected={selectedRetailer}
                    labelKey={(option) => `${option.retailerName} (#${option.retailerNumber})`}
                    onChange={(selected: any) => {
                        setSelectedRetailer(selected);
                    }}
                    options={allRetailers}
                    onInputChange={(text: string) => {
                        fetchAllRetailers(text);
                    }}
                />
            </div>
        );
    };
    return (
        <div>
            {showSubmitCaseSuccessPopup && (
                <div className="mt-2">
                    <AlertBar
                        success
                        show={true}
                        onClose={() => {
                            // setRestrictedStatusChanged(false);
                        }}
                    >
                        <Typography>
                            Case “{showSubmitCaseSuccessForCaseNumber}” has been successfully submitted
                        </Typography>
                    </AlertBar>
                </div>
            )}
            <div className="pt-7 pl-7 pr-7 d-flex h-100 w-100">
                <div className="d-flex flex-column w-100">
                    <div className="d-flex flex-row justify-content-between">
                        <Typography color="black" variant="h2">
                            Case list
                        </Typography>
                        {renderSelectRetailer()}
                    </div>
                    <div className="d-flex flex-row mt-5">
                        <div
                            onClick={() => setCurrentStatusFilter('ALL')}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'ALL' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                All
                            </Typography>
                        </div>
                        {showDraftCases && (
                            <div
                                onClick={() => setCurrentStatusFilter('DRAFT')}
                                className={`${classes.cursorPointer} ml-5 d-flex`}
                            >
                                <Typography
                                    style={currentStatusFilter === 'DRAFT' ? selectedTabHeadStyles : {}}
                                    color="grayFive"
                                    variant="h6"
                                >
                                    Draft
                                </Typography>
                            </div>
                        )}
                        <div
                            onClick={() => setCurrentStatusFilter('OPEN')}
                            className={`${classes.cursorPointer} ml-5 d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'OPEN' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Open
                            </Typography>
                        </div>
                        <div
                            onClick={() => setCurrentStatusFilter('UPDATE_REQUESTED')}
                            className={`${classes.cursorPointer} ml-5 d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'UPDATE_REQUESTED' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Update Requested
                            </Typography>
                        </div>
                        <div
                            onClick={() => setCurrentStatusFilter('PENDING_TECHLINE')}
                            className={`${classes.cursorPointer} ml-5 d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'PENDING_TECHLINE' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Pending Techline
                            </Typography>
                        </div>
                        <div
                            onClick={() => setCurrentStatusFilter('CLOSED')}
                            className={`${classes.cursorPointer} ml-5 d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'CLOSED' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Closed
                            </Typography>
                        </div>
                        {/* <div
                        onClick={() => setCurrentStatusFilter('dealer update')}
                        className={`${classes.cursorPointer} ml-5 d-flex`}
                    >
                        <Typography
                            style={currentStatusFilter === 'dealer update' ? selectedTabHeadStyles : {}}
                            color="grayFive"
                            variant="h6"
                        >
                            Dealer update
                        </Typography>
                    </div> */}
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mt-5">
                        <div style={{ flex: 5 }} className="d-flex flex-row align-items-center">
                            {/* <div className="w-50">
                            <RecentSearchTypeahead
                                onChange={(query) => setSearchQuery(query)}
                                placeholder="Search for a case"
                                recentSearches={[]}
                                quickSearchQuery={searchQuery}
                            />
                        </div> */}
                            <div className="ml-0">
                                {filtersCount ? (
                                    <div className="d-flex flex-row">
                                        <Button
                                            active={false}
                                            variant="info"
                                            iconLeft={<Icon color="blueOne" name="filter-list" />}
                                            onPress={toggleTechlineFilters}
                                        >
                                            <Typography variant="h5" color="blueOne">
                                                Applied filters ({filtersCount})
                                            </Typography>
                                        </Button>
                                        <div className="ml-5">
                                            <Button variant="ghost-blue" onPress={() => setFilters(emptyFilters)}>
                                                Clear all
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <Button
                                        active={false}
                                        variant="ghost-blue"
                                        iconLeft={<Icon color="blueOne" name="filter-list" />}
                                        onPress={toggleTechlineFilters}
                                    >
                                        Filters
                                    </Button>
                                )}
                            </div>
                        </div>
                        {account?.techlineCapabilities?.canCreateDLTechlineCase && (
                            <div className="d-flex justify-content-end" style={{ flex: 5 }}>
                                <Button
                                    iconRight={
                                        <Icon
                                            size={16}
                                            raised
                                            name="plus"
                                            raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                                        />
                                    }
                                    onPress={() => {
                                        setShowCreateCaseModal(true);
                                        analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_CREATE_A_TECHLINE_CASE);
                                    }}
                                >
                                    Start a new case
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="d-flex mt-5 flex-column">
                        <div className="d-flex flex-column mt-5">
                            {renderTable()}
                            <TablePagination
                                page={pageIndex}
                                size={pageSize}
                                total={allCases?.length}
                                onClick={handlePaginationClick}
                                onSizeChange={handlePaginationSizeChange}
                                // displayCount={`0 - 10 of 20`}
                            />
                        </div>
                    </div>
                </div>
                {showCreateCaseModal && (
                    <CreateCaseModal
                        show={showCreateCaseModal}
                        onHide={(identifier) => {
                            setShowCreateCaseModal(false);
                            if (identifier) {
                                navigate('/techline/' + identifier);
                            }
                        }}
                    />
                )}
                {toggledOverlays.techlineQuickFilters && (
                    <TechlineQuickFilters
                        filters={filters as any}
                        applyFilters={setFilters}
                        clearFilters={() => setFilters(emptyFilters)}
                        onClose={toggleTechlineFilters}
                        selectedRetailer={selectedRetailer[0]}
                    />
                )}
            </div>
        </div>
    );
};
