import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Typography } from '../../shared';
import { Button } from '../../shared/button';
import { modalDefaultStyles, Popup, PopupProps } from '../../shared/popup';

interface AdditionalInfoPopupProps extends PopupProps {
    onOkPress: () => void;
}

export const AdditionalInfoPopup: FC<AdditionalInfoPopupProps> = ({ onOkPress, ...props }) => {
    const { t } = useTranslation();

    return (
        <Popup {...props}>
            <View style={modalDefaultStyles.header}>
                <Typography variant="h4">{t('qmr:popups.additionalInfo.title', 'Additional Information')}</Typography>
            </View>

            <View style={modalDefaultStyles.content}>
                <Typography variant="lead">
                    {t(
                        'qmr:popups.additionalInfo.message',
                        'Additional information is added to a QMR after the QMR was submitted and can be seen by anyone viewing this QMR.'
                    )}
                </Typography>
            </View>

            <View style={modalDefaultStyles.footer}>
                <Button title={t('qmr:popups.additionalInfo.ok', 'Got it')} onPress={onOkPress} />
            </View>
        </Popup>
    );
};
