import React, { useCallback, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

import { DetailView, DetailViewBody, DetailViewContent, DetailViewHeader } from '@web/components/detail-view';
import { Button, TextBadge, Typography } from '@packages/ui/shared';
import { TrReply, TrReplyForm } from '@web/escalations/components';
import AsyncPage from '@web/components/async-page';
import { useParams } from 'react-router-dom';
import { escalationsService, configService } from '@web/services/singletons';
import { TrReplyModel } from '@packages/models/api';
import { getBadgeVariant, getBadgeVariantTRSharedReply } from '@web/utils';
import { useAuthState } from '@packages/contexts/auth';

export const EscalationDetail = () => {
    const [trReplyIsOpen, setTrReplyIsOpen] = useState(false);
    const [trReply, setTrReply] = useState<TrReplyModel>();
    const [showCloseReplyModal, setShowCloseReplyModal] = useState(false);
    const { trReplyId } = useParams<{ trReplyId: string }>() || '';
    const { account } = useAuthState();
    const trReplyStatusDescription = trReply?.trReplyStatus?.description || '';
    const trReplySharedStatus = trReply?.replyShared || 'NO_REPLY';

    const fetchData = useCallback(async () => {
        if (trReplyId) {
            const response = await escalationsService.fetchEscalationById(trReplyId);

            if (response.success) {
                setTrReply(response.data.trReply);
            }
        }
    }, [trReplyId]);

    const handleOnHide = () => {
        setShowCloseReplyModal(false);
    };

    const handleCloseTrReply = useCallback(async () => {
        const body = {
            trReplyId: trReplyId!,
            closeTrReply: true,
        };

        try {
            const response = await escalationsService.trReply({
                body: body,
            });

            if (response.success) {
                setShowCloseReplyModal(false);
                fetchData();
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert('Error in attempting to close sharing TR reply, please try again. Error: ' + error.message);
            console.error('Error in handleCloseTrReply()', error);
        }
    }, [trReplyId]);

    const renderCloseReplyModal = () => {
        return (
            <React.Fragment>
                <Modal.Header placeholder={''}>
                    <Modal.Title>Closing TR Shared Reply</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Typography>
                        Please confirm that you would like to close this TR Shared Reply. By closing this TR Reply, it
                        will NOT be shared to technicians or other entities as a QMR outcome. Elevated users will still
                        be able to view this Technical Report.
                    </Typography>
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={handleOnHide}>
                            Cancel
                        </Button>
                    </div>

                    <Button variant="danger" isLoading={false} disabled={false} onPress={handleCloseTrReply}>
                        Confirm Closing TR Reply
                    </Button>
                </Modal.Footer>
            </React.Fragment>
        );
    };

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <DetailViewHeader
                    breadcrumbs={[
                        { to: '/escalations', title: 'Escalations' },
                        {
                            to: `/escalations/${trReply?.trReplyId}`,
                            title: `${trReply?.qmrIdentifier} - `.concat(
                                trReply?.trIdentifer ? `TR# ${trReply?.trIdentifer}` : `TR# Pending`
                            ),
                        },
                    ]}
                >
                    <div className="d-flex align-items-center">
                        <Typography variant="h3">TR Reply Status</Typography>
                        <div className="ml-2">
                            <TextBadge variant={getBadgeVariant(trReply?.trReplyStatus?.trReplyStatusId ?? 'gray')}>
                                {trReplyStatusDescription}
                            </TextBadge>
                        </div>
                    </div>
                </DetailViewHeader>
                <DetailViewContent topOffset="breadcrumb">
                    <DetailViewBody>
                        <Row className="mb-6">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    TR Data
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">QMR ID</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.qmrIdentifier}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Distributor Name</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.distributorName || '--'}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">TR Reporter</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.trReporter || '--'}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Issued Date</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.issuedDateDescription}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Last Modified by</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.lastModifiedBy || '--'}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Modified Date</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.modifiedDateDescription}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Dist RefNo</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.distRefNo || '--'}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">SQIP No. </Typography>
                                </div>
                                <Typography variant="lead">{trReply?.sqip || '--'}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Subject</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.subject}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Summary</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.summary}</Typography>
                            </Col>
                        </Row>

                        <hr className="mb-8" />

                        <Row className="mb-6">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    Vehicle Information
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">VIN 17</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.vin17}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">VIN 8</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.vin8}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">VIN FIRST 3</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.vinFirst3}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">VIN MIDDLE 8</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.vinMiddle8}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">VIN LAST 6</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.vinLast6}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Model</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.model}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Model Year</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.modelYear}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Trouble Date</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.troubleDateDescription}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Mileage</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.mileage}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">MileageUnit</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.mileageUnit}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Factory</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.factory}</Typography>
                            </Col>
                        </Row>

                        <hr className="mb-8" />

                        <Row className="mb-6">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    TR Detail
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Trouble</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.trouble}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Importance</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.importance}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Sales Date</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.salesDateDescription}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Dealer Code</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.dealerCode}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-6">
                            <Col>
                                <Typography variant="h6" color="grayFive">
                                    DTC CODES
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">DTC 1</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.dtc1}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">DTC 2</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.dtc2}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">DTC 3</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.dtc3}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">DTC 4</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.dtc4}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">CID</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.cid}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">CVN</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.cvn}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Priority</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.priority}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Input Source</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.inputSource}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Duplicate the issue</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.duplicateTheIssue}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Is SSMdata Available?</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.isSsmDataAvailable}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Is FFD Available?</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.isFfdAvailable}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Production Date</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.productionDateDescription}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Same Trouble Count</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.sameTroubleCount}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Description Of Trouble</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.descriptionOfTrouble}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Customer Complaints</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.customerComplaints}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Possible Cause</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.possibleCause}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Corrective Work and Result</Typography>
                                </div>
                                <div className="mb-2">
                                    <Typography variant="h6">Corrective Work And Result Repair Date</Typography>
                                </div>
                                <div className="mb-6">
                                    <Typography variant="lead">
                                        {trReply?.correctiveWorkAndResultRepairDateDescription}
                                    </Typography>
                                </div>
                                <Typography variant="lead">{trReply?.correctiveWorkAndResult}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Dist Comment</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.distComment}</Typography>
                            </Col>
                        </Row>

                        <hr className="mb-8" />

                        <Row className="mb-6">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    Part / Trouble Data
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Main Cause Part</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.mainCausePart || '--'}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Part Number</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.partNumber || '--'}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Shipped qty</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.shippedQty}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Parts Status</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.partsStatus}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Shipped From</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.shippedFrom}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Shipped To</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.shippedTo}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Shipped Date</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.shippedDateDescription}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Invoice No</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.invoiceNo || '--'}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Transporter</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.transporter || '--'}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Part ID</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.partId}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Trouble Code</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.troubleCode}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Sympt Code</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.symptCode}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">System</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.system}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">EG# Product-No.</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.egProductNo}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">EG# Replaced-No.</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.egReplacedNo}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">TM# Product-No.</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.tmProductNo}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">TM# Replaced-No.</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.tmReplacedNo}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">EG Code</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.egCode}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">TM Code</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.tmCode}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Color Code</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.colorCode}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">OP Code</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.opCode}</Typography>
                            </Col>
                        </Row>

                        <hr className="mb-8" />

                        <Row className="mb-6">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    TR Reply
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">TR-Reply Status</Typography>
                                </div>
                                <div className="d-flex">
                                    <TextBadge variant={getBadgeVariant(trReplyStatusDescription)}>
                                        {trReplyStatusDescription}
                                    </TextBadge>
                                </div>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Inquiry</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.inquiry}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Final Result</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.finalResult}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">TR No.</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.trIdentifer}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Revision</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.revision}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Reply No.</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.replyNo}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">SQIP Control Item</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.sqipControlItem}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Same Case</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.sameCase}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Lapsed Days(From Issued Date)</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.lapsedDaysIssuedDate}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">#SBR Status</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.sbrStatus}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">#Lapsed Days(From SBR Status)</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.lapsedDaysSbrStatus}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">TR Issued Date</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.issuedDateDescription}</Typography>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <div className="mb-2">
                                    <Typography variant="h6">Reply Issued Date</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.replyIssuedDateDescription}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Investigation Result</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.investigationResult}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Cause</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.cause}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">Counter Measure</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.counterMeasure}</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-8">
                            <Col>
                                <div className="mb-6">
                                    <Typography variant="h3">SBR Comment</Typography>
                                </div>
                                <Typography variant="lead">{trReply?.sbrComment}</Typography>
                            </Col>
                        </Row>

                        <hr className="mb-8" />

                        <Row className="mb-6">
                            <Col>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <Typography variant="h4" color="blueOne">
                                            Shared Reply
                                        </Typography>
                                        <div className="ml-2">
                                            <TextBadge variant={getBadgeVariantTRSharedReply(trReplySharedStatus)}>
                                                {trReplySharedStatus}
                                            </TextBadge>
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{ gap: '0.5em' }}>
                                        {account?.systemCapabilities.canShareTrReply && (
                                            <Button
                                                variant="success"
                                                title="Share TR Reply"
                                                onPress={() => {
                                                    setTrReplyIsOpen(true);
                                                }}
                                                disabled={trReplyIsOpen}
                                            />
                                        )}
                                        {account?.systemCapabilities.canShareTrReply && (
                                            <Button
                                                variant="danger"
                                                title={
                                                    trReplySharedStatus === 'SHARED'
                                                        ? "TR Reply already shared. Can't unshare by closing."
                                                        : 'Close TR Reply'
                                                }
                                                onPress={() => {
                                                    setShowCloseReplyModal(true);
                                                }}
                                                disabled={
                                                    trReplySharedStatus === 'SHARED' || trReplySharedStatus === 'CLOSED'
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                {configService.debugEnvName !== 'production' && (
                                    <div className="ml-2">
                                        <Typography variant="caption">
                                            Internal Note (only shown in dev + stage env): In our test environments,
                                            after escalating an QMR to TR system, we have a mock/simulated flow of these
                                            TR replies, so these TR replies are manually inserted into our database
                                            (either through backend server or manual INSERT statements). Because of
                                            this, the SHARED reply status might not be accurate (e.g. NO_REPLY in
                                            reality should be PENDING since we do indeed have a reply, but it is not
                                            updated because the status is only updated during escalation syncs, which is
                                            not ran regularly in our testing environments). Likewise, the warning
                                            related to overriding a previously shared reply (if it hasn't been shared)
                                            is of the same reason, the manual insert is inputted with the shared outcome
                                            details.
                                        </Typography>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {trReplyIsOpen && (
                            <Row className="mb-8">
                                <Col>
                                    <TrReplyForm
                                        trReplyId={trReplyId || ''}
                                        qmrId={trReply?.qmrIdentifier || ''}
                                        sqipControlGroup={trReply?.sqip}
                                        investigationId={trReply?.trReplyMessageInvestigation?.investigationId}
                                        // Pass bool indicating if TR Reply is ALREADY shared with SQIP or Investigation or this QMR
                                        isTrReplySharedWithSqipOrInvestigation={
                                            !!(
                                                trReply?.trReplyMessageSqip ||
                                                trReply?.trReplyMessageInvestigation ||
                                                trReply?.trReplyMessageQmr
                                            )
                                        }
                                        onClose={() => {
                                            setTrReplyIsOpen(false);
                                        }}
                                        onSend={(data: any) => {
                                            setTrReplyIsOpen(false);
                                            setTrReply(data.trReply);
                                            fetchData();
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        {trReplySharedStatus !== 'CLOSED' &&
                        (trReply?.trReplyMessageSqip ||
                            trReply?.trReplyMessageInvestigation ||
                            trReply?.trReplyMessageQmr) ? (
                            <Row className="mb-8">
                                <Col>
                                    <TrReply
                                        className="mb-6"
                                        sqip={trReply.trReplyMessageSqip}
                                        sqipControlGroup={trReply.sqip}
                                        investigation={trReply.trReplyMessageInvestigation}
                                        qmr={trReply.trReplyMessageQmr}
                                        qmrId={trReply?.qmrIdentifier}
                                        investigationId={trReply.trReplyMessageInvestigation?.investigationId}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <Row className="mb-8">
                                <Col>
                                    <Typography variant="lead">
                                        {trReplySharedStatus === 'CLOSED'
                                            ? 'TR Shared Reply Has Been Closed. If you\'d like to share this TR reply, please click on the "Share TR Reply" button above.'
                                            : 'Not shared yet'}
                                    </Typography>
                                </Col>
                            </Row>
                        )}

                        <Modal show={showCloseReplyModal} centered={true} onHide={handleOnHide} size="lg">
                            {renderCloseReplyModal()}
                        </Modal>
                    </DetailViewBody>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};
