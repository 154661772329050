import { StorageKey } from './storage-keys';

interface AsyncStorageStatic {
    getItem(key: string, callback?: (error?: Error, result?: string) => void): Promise<string | null>;
    setItem(key: string, value: string, callback?: (error?: Error) => void): Promise<void>;
    removeItem(key: string, callback?: (error?: Error) => void): Promise<void>;
    mergeItem(key: string, value: string, callback?: (error?: Error) => void): Promise<void>;
    clear(callback?: (error?: Error) => void): Promise<void>;
    getAllKeys(callback?: (error?: Error, keys?: string[]) => void): Promise<string[]>;
    multiGet(
        keys: string[],
        callback?: (errors?: Error[], result?: [string, string | null][]) => void
    ): Promise<[string, string | null][]>;

    multiSet(keyValuePairs: string[][], callback?: (errors?: Error[]) => void): Promise<void>;
    multiRemove(keys: string[], callback?: (errors?: Error[]) => void): Promise<void>;
    multiMerge(keyValuePairs: string[][], callback?: (errors?: Error[]) => void): Promise<void>;
}

export class StorageService {
    constructor(private storageEngine: Storage | AsyncStorageStatic, private prefix = 'subcomm:') {}

    private prefixKey(key: string) {
        return this.prefix + key;
    }

    async setItem(key: StorageKey, value: any) {
        const item = JSON.stringify(value);

        return await this.storageEngine.setItem(this.prefixKey(key), item);
    }

    async getItem(key: StorageKey) {
        const item = await this.storageEngine.getItem(this.prefixKey(key));

        if (item) {
            return JSON.parse(item);
        }

        return item;
    }

    async removeItem(key: StorageKey) {
        return await this.storageEngine.removeItem(this.prefixKey(key));
    }

    async clear() {
        return await this.storageEngine.clear();
    }
}
