import {
    TechlineStatusCode,
    WorksheetAnswerAssetBody,
    WorksheetAssetType,
    WorksheetElementAnswers,
    WorksheetSubmissionResponse,
} from '@packages/models/api';

export const createDto = (answer: WorksheetElementAnswers, caseNumber: string, elementId: string) => {
    const asset = answer.asset?.answerFile;
    if (!asset || !asset.file) {
        return null;
    }
    const dto: WorksheetAnswerAssetBody = {
        assetTypeId: asset?.file.type.includes('image') ? WorksheetAssetType.IMAGE : WorksheetAssetType.FILE,
        assetDispositionId: 'TECHSHARE',
        caseNumber,
        fileName: asset.file.name,
        restricted: false,
        hasOverlay: false,
        note: '',
        worksheetQuestionSubmissionSourceId: elementId,
    };
    return dto;
};

export const createDtoAnswerAssets = (
    formElementAnswer: Record<string, WorksheetElementAnswers>,
    caseNumber: string,
    techlinecasestatus: TechlineStatusCode,
    worksheetSubmissionRes: WorksheetSubmissionResponse
) => {
    const elementIds = Object.keys(formElementAnswer);
    const sourceIdFileMap: { [key: string]: File } = {};
    const assets = Object.values(formElementAnswer)
        .map((ass, idx) => {
            const id = worksheetSubmissionRes.results.answers.find(
                (ans) => ans.elementId === elementIds[idx]
            )?.sourceId;
            if (ass.asset?.answerFile.file && id) {
                sourceIdFileMap[id] = ass.asset?.answerFile.file;
            }
            return createDto(ass, caseNumber, id!);
        })
        .filter((ass) => ass !== null);
    return {
        assets,
        techlinecasestatus,
        sourceIdFileMap,
    };
};
