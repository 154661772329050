import React, { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Divider, Icon, StsIconName, Typography, useHoverState } from '@packages/ui/shared';
import {
    CaseType,
    WorksheetAnswerElement,
    WorksheetAnswerStatus,
    WorksheetListByCaseType,
    WorksheetListByCaseTypeBody,
    WorksheetSubmissions,
} from '@packages/models/api/worksheets';
import { Menu, MenuItem, Token, Typeahead } from 'react-bootstrap-typeahead';
import { worksheetsService } from '@web/services/singletons';
import { WorksheetAdd } from './worksheet-add';
import { TechlineStatusCode, WorksheetOnCaseSubmit } from '@packages/models/api';
import { groupBy, differenceBy } from 'lodash';
import { WorksheetToaster } from './toaster';
import { useParams } from 'react-router-dom';
import { useCaseType } from './hooks/useCaseType';
import { WorksheetModal } from '@web/administration/views/worksheets/components/worksheet-modal';
import useWorksheetAnswerValidation from './hooks/useWorksheetAnswerValidation';
import useWorksheetManage from './hooks/useWorksheetMange';
import useUserDetailsForWorksheet from './hooks/useUserDetailsOrPermissions';

export interface WorksheetValid {
    submitted: boolean;
    draft: boolean;
    caseSelected: boolean;
}

interface Props {
    worksheetSubmission?: WorksheetSubmissions[];
    caseTypesDefault?: { caseType: string; caseTypeSourceId: string; isPrimary: string }[];
    handleIsWorksheetValid: (newState: Partial<WorksheetValid>) => void;
    caseStatus?: TechlineStatusCode;
}
export const WorksheetReport = forwardRef<
    {
        checkAllWorksheet: () => Promise<boolean>;
        submissionWorksheets: () => WorksheetOnCaseSubmit;
    },
    Props
>(({ worksheetSubmission, caseTypesDefault, handleIsWorksheetValid, caseStatus }, ref) => {
    const { caseNumber } = useParams();
    const typeaheadRef = useRef<Typeahead<CaseType> | null>(null);
    const [selectedCaseTypes, setSelectedCaseType] = useState<CaseType[]>([]);
    const [previousSelectedCaseType, setPreviousSelectedCaseType] = useState<CaseType[]>([]);
    // worksheetList is selected caseType's worksheets
    const [worksheetList, setWorksheetList] = useState<WorksheetListByCaseType>({});
    const [showToaster, setShowToaster] = useState<{ show: boolean; message: string }>({
        show: false,
        message: '',
    });
    const [caseTypes, setCaseTypes] = useState<CaseType[]>([]);
    // List of worksheet which already draft or submitted.
    const [worksheetSubmissionList, setWorksheetSubmissionList] = useState<WorksheetSubmissions[]>([]);
    // Loading state when remove case type form selected case type
    const [isCaseTypeRemove, setIsCaseTypeRemove] = useState<boolean>(false);
    const [removeCaseTypeModal, setRemoveCaseTypeModal] = useState({ show: false, index: -1 });
    const [showWorksheetValidationModal, setShowWorksheetValidationModal] = useState(false);
    //hook for caseType function
    const { saveCaseTypes, fetchCaseType, isCaseTypeLoading, defaultSelectedDto } = useCaseType({
        setCaseTypes,
        setShowToaster,
    });
    const { groupOfCaseTypeIds } = useWorksheetManage();
    const {
        validateAllWorksheetsAnswers,
        worksheetSubmissionAnswers,
        isAllWorksheetAnswersValid,
        worksheetAnswersValidationMapping,
    } = useWorksheetAnswerValidation();

    const {
        permissions: { canAddAndEditWorksheet, canAddAndEditCaseType },
        isPilotUser,
    } = useUserDetailsForWorksheet();

    const isCloseCase = useMemo(() => {
        if (caseStatus) {
            return [TechlineStatusCode.CLOSED, TechlineStatusCode.CLOSED_RESOLVED].includes(caseStatus);
        }
        return false;
    }, [caseStatus]);

    const submittedOrDratWorksheetGroup = useMemo(() => {
        return groupBy(worksheetSubmissionList, 'worksheetCaseTypeSourceId');
    }, [worksheetSubmissionList]);

    // Array of caseTypeIds which selected case has submitted worksheet.
    const worksheetStatusWiseCaseTypeIds = useMemo(() => {
        return groupOfCaseTypeIds({
            worksheetsWiseCaseType: submittedOrDratWorksheetGroup,
            selectedCaseTypes,
        });
    }, [selectedCaseTypes, submittedOrDratWorksheetGroup]);

    // Validation for case submit
    const isWorksheetsValid = useMemo(() => {
        let valid: WorksheetValid = { submitted: false, draft: false, caseSelected: false };
        valid.submitted = !!worksheetStatusWiseCaseTypeIds[WorksheetAnswerStatus.Submitted].length;
        valid.draft = !!worksheetStatusWiseCaseTypeIds[WorksheetAnswerStatus.Draft].length;
        valid.caseSelected = Boolean(caseTypesDefault?.length);
        return valid;
    }, [caseTypesDefault?.length, worksheetStatusWiseCaseTypeIds]);

    useEffect(() => {
        fetchCaseType();
    }, []);

    useEffect(() => {
        handleIsWorksheetValid(isWorksheetsValid);
    }, [isWorksheetsValid]);

    useEffect(() => {
        setWorksheetSubmissionList(worksheetSubmission || []);
    }, [worksheetSubmission]);

    useEffect(() => {
        if (worksheetSubmissionList && caseNumber) {
            validateAllWorksheetsAnswers({
                worksheetSubmission: worksheetSubmissionList,
                caseNumber: caseNumber,
            });
        }
    }, [worksheetSubmissionList, caseNumber]);

    // When user submit the worksheet check all worksheets are valid or not.
    useImperativeHandle(ref, () => {
        return {
            async checkAllWorksheet() {
                if (!isAllWorksheetAnswersValid) {
                    setShowWorksheetValidationModal(true);
                }
                return isAllWorksheetAnswersValid;
            },
            submissionWorksheets() {
                return {
                    submissions: worksheetSubmissionAnswers.map((worksheet) => ({
                        submissionId: worksheet.submissionId,
                        worksheetId: worksheet.worksheetId,
                        answers: worksheet.elements.reduce((_array: WorksheetAnswerElement[], ele) => {
                            const obj = {
                                elementId: ele.worksheetElementId,
                                selectedOptions: ele.selectedOptions || [],
                            };
                            _array.push(obj);
                            return _array;
                        }, []),
                    })),
                };
            },
        };
    }, [caseNumber, isAllWorksheetAnswersValid, worksheetSubmissionAnswers, worksheetSubmissionList]);

    // When  component render has already selected caseTypes.
    const defaultSelected: CaseType[] | undefined = useMemo(() => {
        const selected = defaultSelectedDto({ caseTypes, caseTypesDefault });
        setSelectedCaseType(selected || []);
        setPreviousSelectedCaseType(selected || []);
        return selected;
    }, [caseTypes, caseTypesDefault]);

    useEffect(() => {
        fetchWorksheetList();
    }, [defaultSelected]);

    const deleteWorksheetDraft = useCallback(async (submissionId: string) => {
        const response = await worksheetsService.deleteDraftWorksheetSubmission(submissionId);
        if (response.success) {
            setWorksheetSubmissionList((prev) => {
                if (!prev?.length) {
                    return [];
                }
                const prevClone = [...prev];
                const index = prevClone.findIndex((worksheet) => worksheet.submissionId === submissionId);
                if (index !== -1) {
                    prevClone.splice(index, 1);
                }
                return prevClone;
            });
        }
        return response;
    }, []);

    const fetchWorksheetList = useCallback(async () => {
        if (selectedCaseTypes.length) {
            const body: WorksheetListByCaseTypeBody = {
                caseTypes: selectedCaseTypes.map((caseType) => {
                    return {
                        caseType: caseType.caseType,
                        northstarId: caseType.northstarId,
                    };
                }),
                isPilotUser: isPilotUser,
            };
            await worksheetsService.worksheetListByCaseType(body).then((response) => {
                if (response.success) {
                    setWorksheetList(response.data);
                }
            });
        }
    }, [isPilotUser, selectedCaseTypes]);

    const handleOnChange = useCallback(
        async (selected: CaseType[]) => {
            setSelectedCaseType(selected);

            if (selected.length > selectedCaseTypes.length) {
                typeaheadRef.current?.toggleMenu();
            }
        },
        [selectedCaseTypes]
    );

    const handleOnBlur = useCallback(async () => {
        const addedOption = differenceBy(selectedCaseTypes, previousSelectedCaseType, 'caseTypeId');
        if (addedOption.length) {
            const response = await saveCaseTypes({
                caseTypes: addedOption,
                status: 1,
                caseNumber: caseNumber || '',
            });
            if (response?.success) {
                setPreviousSelectedCaseType(selectedCaseTypes);
                fetchWorksheetList();
            }
        }
    }, [caseNumber, fetchWorksheetList, previousSelectedCaseType, saveCaseTypes, selectedCaseTypes]);

    const handleOnRemove = useCallback(
        async (index: any) => {
            setIsCaseTypeRemove(true);
            const removeOption = [selectedCaseTypes[index]];
            await saveCaseTypes({ caseTypes: removeOption, status: 2, caseNumber: caseNumber || '' });
            setSelectedCaseType((prev) => prev.filter((opt, idx) => idx !== index));
            setIsCaseTypeRemove(false);
            setRemoveCaseTypeModal({ show: false, index: -1 });
        },
        [caseNumber, saveCaseTypes, selectedCaseTypes]
    );

    //MenuItems for Typeahead menu
    const CaseItem: FC<{ result: CaseType; index: number; iconName: StsIconName; disabled?: boolean }> = ({
        result,
        index,
        iconName,
        disabled,
    }) => {
        const { isHovered, hoverEventHandlers } = useHoverState();
        return (
            <MenuItem {...hoverEventHandlers} option={result} position={index} disabled={disabled}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon color={isHovered ? 'white' : 'textDarkPrimary'} name={iconName} />
                    <span>{result.caseType}</span>
                </div>
            </MenuItem>
        );
    };

    return (
        <>
            <WorksheetToaster
                show={showToaster.show}
                onClose={() => setShowToaster({ show: false, message: '' })}
                body={showToaster.message}
            />
            <WorksheetModal
                show={removeCaseTypeModal.show}
                onHideModal={() => setRemoveCaseTypeModal({ show: false, index: -1 })}
                title="Remove Case Type"
                bodyMessage={
                    'This case type has associated worksheets in DRAFT status. Please delete those first before removing this case type.'
                }
                confirmButton="Ok"
                confirmButtonColor="info"
                confirmButtonLoading={isCaseTypeRemove}
                handleAction={() => {
                    setRemoveCaseTypeModal({ show: false, index: -1 });
                }}
            />
            <WorksheetModal
                show={showWorksheetValidationModal}
                onHideModal={() => setShowWorksheetValidationModal(false)}
                title="Worksheet Validation"
                bodyMessage={
                    'Some of the required fields in your worksheets are empty. Please review your worksheets and make sure all of the required fields are complete.'
                }
                confirmButton="Ok"
                confirmButtonColor="info"
                confirmButtonLoading={isCaseTypeRemove}
                handleAction={() => {
                    setShowWorksheetValidationModal(false);
                }}
            />
            <section className="mt-21">
                <Typography variant="h4">Case Type(s)</Typography>

                <div className="mt-5">
                    <Typography>
                        Select all the types you think are necessary for all this case
                        <Typography color="redOne">*</Typography>
                    </Typography>

                    <Typeahead
                        multiple
                        className="mb-10 mt-3"
                        id="manage-case-type-typeahead"
                        placeholder="Select case types"
                        ref={typeaheadRef}
                        labelKey={'caseType'}
                        selected={selectedCaseTypes}
                        inputProps={{ readOnly: true }}
                        disabled={!(canAddAndEditCaseType && canAddAndEditWorksheet) || isCloseCase}
                        options={caseTypes}
                        isLoading={isCaseTypeLoading || isCaseTypeRemove}
                        onBlur={handleOnBlur}
                        onChange={handleOnChange}
                        renderMenu={(results, menuProps) => {
                            return (
                                <Menu {...menuProps}>
                                    {selectedCaseTypes.map((type) => {
                                        return (
                                            <CaseItem
                                                result={type}
                                                index={1}
                                                iconName="checkbox-marked"
                                                disabled={true}
                                                key={type.caseTypeId}
                                            />
                                        );
                                    })}
                                    {results.map((type, index) => {
                                        const pluseIndex = selectedCaseTypes.length;
                                        return (
                                            <CaseItem
                                                result={type}
                                                index={index + pluseIndex}
                                                iconName="checkbox-unmarked"
                                                disabled={false}
                                                key={type.caseTypeId}
                                            />
                                        );
                                    })}
                                </Menu>
                            );
                        }}
                        renderToken={(option, props, idx) => {
                            return (
                                <Token
                                    option={option}
                                    readOnly={worksheetStatusWiseCaseTypeIds[WorksheetAnswerStatus.Submitted].includes(
                                        option.caseTypeId
                                    )}
                                    disabled={isCloseCase}
                                    onRemove={() => {
                                        if (submittedOrDratWorksheetGroup[option.northstarId]?.length) {
                                            setRemoveCaseTypeModal({ show: true, index: idx });
                                        } else {
                                            handleOnRemove(idx);
                                        }
                                    }}
                                >
                                    {option.caseType}
                                </Token>
                            );
                        }}
                    />
                </div>

                <Divider />
                <div style={{ marginTop: 50 }}>
                    <Typography variant="h4">Worksheets</Typography>

                    {selectedCaseTypes.map((caseType) => {
                        return (
                            <WorksheetAdd
                                selectedCaseType={caseType}
                                worksheetList={worksheetList[caseType.caseType]}
                                worksheetSubmittedOrDraft={submittedOrDratWorksheetGroup[caseType.northstarId]}
                                deleteWorksheetDraft={deleteWorksheetDraft}
                                key={caseType.caseTypeId}
                                caseStatus={caseStatus}
                                isCloseCase={isCloseCase}
                                worksheetAnswersValidationMapping={worksheetAnswersValidationMapping}
                            />
                        );
                    })}
                </div>
            </section>
        </>
    );
});
