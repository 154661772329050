import React from 'react';

import { Account } from '@packages/models/api';
import { getAccountInitials } from '@packages/core/utils';

import { CircleBadge } from './circle-badge';
import { Typography } from './typography';

interface AccountBadgeProps {
    account: Account;
}

export function AccountBadge({ account }: AccountBadgeProps) {
    return (
        <CircleBadge>
            <Typography>{getAccountInitials(account)}</Typography>
        </CircleBadge>
    );
}

export default AccountBadge;
