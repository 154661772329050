import { Account } from './account';

export enum CommentApiSupplement {
    Account = 'CREATED_ACCOUNT', // Special supplement to "include everything"
}

export interface Comment {
    commentId: string;
    body: string;
    createdAccountId: string;
    createdTimestamp: string;
    createdTimestampDescription: string;
    createdAccount: Account;
    commentCapabilities: {
        canEditComment: boolean;
        canDeleteComment: boolean;
    };
}

export interface CommentsResponseDto {
    comments: Comment[];
}
