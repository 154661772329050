import * as React from 'react';
import { action, on, props, reducer, union } from 'ts-action';
import { ITechline } from '@packages/models/api';

export const baseTechline = {
    techlineId: '',
    message: '',
    showButton: false,
    buttonLink: '',
    buttonText: '',
    linkOne: '',
    linkTwo: '',
    linkThree: '',
    header: '',
    backgroundImage: '',
};
export enum TechlineActionsList {
    GET_TECHLINE = 'GET_TECHLINE',
    UPDATE_TECHLINE = 'UPDATE_TECHLINE',
    DELETE_TECHLINE = 'DELETE_TECHLINE',
    CREATE_TECHLINE = 'CREATE_TECHLINE',
    SHOW_INFOTAINMENT_IFRAME = 'SHOW_INFOTAINMENT_IFRAME',
    GET_INFOTAINMENT_PORTAL_KEY = 'GET_INFOTAINMENT_PORTAL_KEY',
    GET_INFOTAINMENT_CASES_PORTAL_KEY = 'GET_INFOTAINMENT_CASES_PORTAL_KEY',
}

export interface ITechlineProps {
    techline: ITechline;
    showInfotainmentIframe: boolean;
}

export interface ITechlineResponse {
    techline: { techline: ITechline };
}

export interface ITechlineRedux {
    techline: ITechline;
}

export interface ITechlineState {
    techline: ITechline;
    loading: boolean;
    errors: string[];
    showInfotainmentIframe: boolean;
}

export const techlineActions = {
    getTechline: action(TechlineActionsList.GET_TECHLINE, props<ITechlineRedux>()),
    updateTechline: action(TechlineActionsList.UPDATE_TECHLINE, props<ITechlineRedux>()),
    createTechline: action(TechlineActionsList.CREATE_TECHLINE, props<ITechlineRedux>()),
    deleteTechline: action(TechlineActionsList.DELETE_TECHLINE),
    show: action(TechlineActionsList.GET_INFOTAINMENT_PORTAL_KEY, props<ITechlineRedux>()),
    getInfotainmentPortalKey: action(TechlineActionsList.GET_INFOTAINMENT_PORTAL_KEY, props<ITechlineRedux>()),
    getInfotainmentCasesPortalKey: action(
        TechlineActionsList.GET_INFOTAINMENT_CASES_PORTAL_KEY,
        props<ITechlineRedux>()
    ),
    showInfotainmentIframe: action(
        TechlineActionsList.SHOW_INFOTAINMENT_IFRAME,
        props<{ showInfotainmentIframe: boolean }>()
    ),
};

const actionsUnion = union(...Object.values(techlineActions));
export type TechlineActions = typeof actionsUnion.actions;

export type TechlineActionDispatcher = React.Dispatch<TechlineActions>;

const initialState: ITechlineState = {
    techline: baseTechline,
    loading: false,
    errors: [],
    showInfotainmentIframe: false,
};

export const techlineReducer: React.Reducer<ITechlineState, TechlineActions> = reducer(
    initialState,
    on(techlineActions.getTechline, (state, { techline }) => {
        return {
            ...state,
            techline,
        };
    }),
    on(techlineActions.updateTechline, (state, { techline }) => {
        return {
            ...state,
            techline,
        };
    }),
    on(techlineActions.createTechline, (state, { techline }) => {
        return {
            ...state,
            techline,
        };
    }),
    on(techlineActions.deleteTechline, (state) => {
        return {
            ...state,
            techline: baseTechline,
        };
    }),
    on(techlineActions.getInfotainmentPortalKey, (state, { techline }) => {
        return {
            ...state,
            techline,
        };
    }),
    on(techlineActions.getInfotainmentCasesPortalKey, (state, { techline }) => {
        return {
            ...state,
            techline,
        };
    }),
    on(techlineActions.showInfotainmentIframe, (state, { showInfotainmentIframe }) => {
        return {
            ...state,
            showInfotainmentIframe,
        };
    })
);
