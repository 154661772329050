import { useContext, useMemo } from 'react';

import { AssetUploadsStateContext, AssetUploadsDispatchContext } from './asset-uploads.context';
import { AssetUploadTaskStatus } from './asset-uploads.state';

export const useAssetUploadsState = () => {
    const state = useContext(AssetUploadsStateContext);

    if (state === undefined) {
        throw new Error('useAssetUploadsState must be used within a AssetUploadsProvider');
    }

    return state;
};

export const useAssetUploadsDispatch = () => {
    const dispatch = useContext(AssetUploadsDispatchContext);

    if (dispatch === undefined) {
        throw new Error('useAssetUploadsDispatch must be used within a AssetUploadsProvider');
    }

    return dispatch;
};

export const useQmrAssetUploads = (qmrId?: string) => {
    const uploads = useAssetUploadsState();

    const { qmrUploads, successUploads, failedUploads, inProgressUploads } = useMemo(() => {
        const byQmrId = Object.values(uploads).filter((upload) => upload.qmrId === qmrId);

        const success = [];
        const fail = [];
        const inprogress = [];

        for (const uploadTask of byQmrId) {
            switch (uploadTask.status) {
                case AssetUploadTaskStatus.Completed:
                    success.push(uploadTask);
                    break;
                case AssetUploadTaskStatus.Error:
                    fail.push(uploadTask);
                    break;
                case AssetUploadTaskStatus.Uploading:
                    inprogress.push(uploadTask);
                    break;
            }
        }

        return {
            qmrUploads: byQmrId,
            successUploads: success,
            failedUploads: fail,
            inProgressUploads: inprogress,
        };
    }, [qmrId, uploads]);

    return {
        qmrUploads,
        successUploads,
        failedUploads,
        inProgressUploads,
        hasSuccessUploads: successUploads.length > 0,
        hasFailUploads: failedUploads.length > 0,
        hasInProgressUploads: inProgressUploads.length > 0,
    };
};
