import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import Select from '@web/components/select';
import {
    SORT_DIRECTION,
    TableCell,
    TableCheckbox,
    TableHeader,
    TablePagination,
    TableRenderer,
    TableRow,
} from '@web/components/table';
import { Button, TextBadge, Typography, Icon } from '@packages/ui/shared';
import RecentSearchTypeahead from '@web/components/recent-search-typeahead';
import useQuery from '@web/core/hooks/use-query';
import { ManageUsersPanel } from '@web/administration/components/manage-users-panel';
import { AddUserPanel } from '@web/administration/components/add-user-panel';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import { accountsService } from '@web/services/singletons';
import { Account } from '@packages/models/api';
import { BulkUserEnableButton } from '../components';
import { useAuthState } from '@packages/contexts/auth';

// eslint-disable-next-line react-hooks/exhaustive-deps
type UserModel = {
    username: string;
    firstName: string;
    lastName: string;
    locationInfo: string;
    jobTitle: string;
    role: string;
    status: string;
};

type UserTableRowModal = {
    rowId: string;
    checked: boolean;
} & Account;

export const ManageUsers: FC = () => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const { t } = useTranslation();
    const queryParams = useQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { account } = useAuthState();

    const searchInputRef = useRef(null);
    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const quickSearchQuery = queryParams.get('query') || '';
    const sortOrder = queryParams.get('sortOrder') || '';
    const sortDir = queryParams.get('sortDir') || '';

    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<UserTableRowModal[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [selectedUsers, setSelectedUsers] = useState<UserTableRowModal[]>([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);

    const [selectedAccount, setSelectedAccount] = useState<Account>();

    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const [selectedSearchby, setSelectedSearchby] = useState('');
    const [includeDisabledUsers, setIncludeDisabledUsers] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            const response = await accountsService.getAdminAccounts({
                page: pageIndex,
                size: pageSize,
                fieldToSearch: selectedSearchby,
                query: quickSearchQuery,
                includeDisabled: includeDisabledUsers,
                sortField: sortOrder,
                sortDirection: sortDir,
            });

            if (response.success) {
                const accountsAsTableRows = response.data.accounts.map((account) => {
                    const accountClone = cloneDeep(account) as UserTableRowModal;

                    accountClone.rowId = account.accountId;
                    accountClone.checked = false;

                    return accountClone;
                });

                setTotalUsers(response.data.totalCount);
                setUsers(accountsAsTableRows);
            } else {
                throw response.data;
            }

            setIsLoading(false);
        }

        fetchData();
    }, [pageIndex, pageSize, quickSearchQuery, includeDisabledUsers, sortOrder, sortDir]);

    const handleSelectionChange = useCallback((selectedUsers: UserTableRowModal[]) => {
        setSelectedUsers(selectedUsers);
    }, []);

    const handleSort = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('sortOrder', sortBy);
            params.set('sortDir', sortDirection);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));
            setPageIndex(index);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));
            setPageSize(size);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const RecentSearchTypeaheadChange = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));

            if (text) {
                params.set('query', text);

                if (!recentSearches.includes(text)) {
                    setRecentSearches([text, ...recentSearches]);
                }
            } else {
                params.delete('query');
            }

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, recentSearches, search]
    );

    function handleAccountUpdate(account: Account) {
        const usersClone = cloneDeep(users);
        const indexToUpdate = users.findIndex((user) => user.accountId === account.accountId);
        usersClone[indexToUpdate] = { rowId: account.accountId, checked: false, ...account };

        setSelectedAccount(account);
        setUsers(usersClone);
    }

    function handleAccountCreated(subaruUserName: string) {
        alert(`account created successfully, soa subaru username:  ${subaruUserName}`);
    }
    return (
        <>
            {toggledOverlays.manageUsers && selectedAccount && (
                <ManageUsersPanel account={selectedAccount} onAccountUpdate={handleAccountUpdate} />
            )}

            {toggledOverlays.addUser && <AddUserPanel onAccountCreated={handleAccountCreated} />}

            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <div className="mb-6 d-flex justify-content-between">
                        <Typography variant="h2">Users</Typography>
                    </div>
                    <div className="mb-8 d-flex">
                        <div className="w-25 mr-8">
                            <Select
                                value={selectedSearchby}
                                options={[
                                    {
                                        value: '',
                                        title: 'select',
                                        disabled: true,
                                    },
                                    {
                                        value: 'SOA_USERNAME',
                                        title: 'User Name',
                                    },
                                    {
                                        value: 'FIRST_NAME',
                                        title: 'First Name',
                                    },
                                    {
                                        value: 'LAST_NAME',
                                        title: 'Last Name',
                                    },
                                    {
                                        value: 'RETAILER',
                                        title: 'Retailer',
                                    },
                                    {
                                        value: 'JOB_TITLE',
                                        title: 'Job Title',
                                    },
                                    {
                                        value: 'ROLE_ID',
                                        title: 'Role',
                                    },
                                    {
                                        value: 'ADDONROLE_ID',
                                        title: ' Add-on Role',
                                    },
                                ]}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    setSelectedSearchby(event.target.value);
                                }}
                            />
                        </div>
                        <div className="w-50">
                            <RecentSearchTypeahead
                                //@ts-ignore TODO: Figure out how to pass refs to forwardRef
                                ref={searchInputRef}
                                onChange={RecentSearchTypeaheadChange}
                                placeholder={t(
                                    'views:manageUsers.search.placeholder',
                                    'Enter a name or Subaru Username'
                                )}
                                defaultInputValue={quickSearchQuery ? quickSearchQuery : ''}
                                recentSearches={recentSearches}
                                quickSearchQuery={quickSearchQuery}
                            />
                        </div>
                        <div className="w-50 ml-8 mt-2">
                            <Form.Check
                                label="Include Disabled"
                                type="checkbox"
                                id="viewMatchingFailCodes"
                                name="failCodeSearch"
                                checked={includeDisabledUsers}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setIncludeDisabledUsers(event.currentTarget.checked);
                                }}
                            />
                        </div>
                        <>
                            <Button
                                iconLeft={<Icon name="plus" color="white" />}
                                onPress={() => {
                                    toggleOverlay('addUser');
                                }}
                            >
                                Add User
                            </Button>
                        </>

                        {true && (
                            <>
                                <div style={{ width: '20px' }}></div>
                                <BulkUserEnableButton
                                    onUploadSuccess={() => {
                                        alert('User accounts enabled successfully!');
                                    }}
                                />
                            </>
                        )}
                    </div>

                    <TableRenderer<UserTableRowModal>
                        isLoading={isLoading}
                        tableRowsData={users}
                        tableRowsDataSetter={setUsers}
                        selectAll={selectAll}
                        selectAllSetter={setSelectAll}
                        onSelectionChange={handleSelectionChange}
                        noResultsIconName="administration"
                        tableHeaderRowRenderer={(selectAllValue, selectAllChangeHandler) => {
                            return (
                                <TableRow>
                                    <TableHeader fixed hideBorder>
                                        <TableCheckbox
                                            checked={selectAllValue}
                                            onChange={selectAllChangeHandler}
                                            iconName="minus"
                                        />
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        onSort={(_event, sortDirection) => {
                                            handleSort('SOA_USERNAME', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Username</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        onSort={(_event, sortDirection) => {
                                            handleSort('FIRST_NAME', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            First Name
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        onSort={(_event, sortDirection) => {
                                            handleSort('LAST_NAME', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            Last Name
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        onSort={(_event, sortDirection) => {
                                            handleSort('LOCATION_INFO', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            Location Info
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        onSort={(_event, sortDirection) => {
                                            handleSort('JOB_TITLE', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            Job Title
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        onSort={(_event, sortDirection) => {
                                            handleSort('ROLE_ID', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Role</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        onSort={(_event, sortDirection) => {
                                            handleSort('ADD_ON_ROLE ', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Add-on Role</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        onSort={(_event, sortDirection) => {
                                            handleSort('STATUS', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Status</Typography>
                                    </TableHeader>
                                </TableRow>
                            );
                        }}
                        tableBodyRowRenderer={(rowData, rowSelectHandler) => {
                            const status = rowData.enabled
                                ? t('views:manageUsers.status.active')
                                : t('views:manageUsers.status.inactive');
                            return (
                                <TableRow key={rowData.rowId} checked={rowData.checked}>
                                    <TableCell fixed>
                                        <TableCheckbox
                                            value={rowData.rowId}
                                            checked={rowData.checked}
                                            onChange={rowSelectHandler}
                                        />
                                    </TableCell>
                                    <TableCell>{rowData.soaUsername}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="link"
                                            style={{ height: 'auto', justifyContent: 'flex-start' }}
                                            onPress={() => {
                                                setSelectedAccount(rowData);
                                                toggleOverlay('manageUsers');
                                            }}
                                        >
                                            {rowData.firstName}
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{rowData.lastName}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>{rowData.locationsDescription}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>{rowData.jobTitleDescription}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <div className="d-flex">
                                            <Typography numberOfLines={1}>{rowData.baseRolesDescription}</Typography>
                                            {rowData.baseRoleOverridden && (
                                                <TextBadge style={{ marginLeft: 8 }}>Overriden</TextBadge>
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Typography numberOfLines={1}>{rowData.addOnRolesDescription}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <div className="d-flex">
                                            <TextBadge variant={rowData.enabled ? 'green' : 'red'}>{status}</TextBadge>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />

                    <TablePagination
                        page={pageIndex}
                        size={pageSize}
                        total={totalUsers}
                        onClick={handlePaginationClick}
                        onSizeChange={handlePaginationSizeChange}
                    />
                </div>
            </div>
        </>
    );
};
