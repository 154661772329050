import { useMemo } from 'react';
import { ADD_ON_ROLE_ID } from '@packages/models/api';
import { useAuthState } from '@packages/contexts/auth';

const useUserDetailsOrPermissions = () => {
    const { account } = useAuthState();
    const isPilotUser = useMemo(() => {
        return account?.addOnRoles?.some((role) => role?.roleId === ADD_ON_ROLE_ID.PILOT_USER) || false;
    }, [account]);

    const userName = useMemo(() => {
        return account?.soaUsername;
    }, [account?.soaUsername]);

    const permissions = useMemo(() => {
        const worksheetPermission = {
            canAddAndEditCaseType: false,
            canAddAndEditWorksheet: false,
            canAddComment: false,
            canAddOrEditAttachment: false,
            canCloseCase: false,
        };
        if (account) {
            const {
                techlineCapabilities: {
                    canAddWorksheetTechlineCase,
                    canEditTechlineCase,
                    canAddCommentsTechlineCase,
                    canAddAttachmentTechlineCase,
                    canCloseTechlineCase,
                },
            } = account;
            worksheetPermission.canAddAndEditCaseType = canEditTechlineCase;
            worksheetPermission.canAddAndEditWorksheet = canAddWorksheetTechlineCase;
            worksheetPermission.canAddComment = canAddCommentsTechlineCase;
            worksheetPermission.canAddOrEditAttachment = canAddAttachmentTechlineCase;
            worksheetPermission.canCloseCase = canCloseTechlineCase;
        }
        return worksheetPermission;
    }, [account]);

    return { permissions, isPilotUser, userName };
};

export default useUserDetailsOrPermissions;
