import React, { FC, useCallback, useEffect, useState } from 'react';
import { Modal, Tab, Nav, Row, Col, Form } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Select, { SelectOption } from '@web/components/select';
import classNames from 'classnames';

import { Button, Typography, AccountLocation } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { patchAuthUserProfile, useAuthDispatch, useAuthState } from '@packages/contexts/auth';
import { PatchAccountDto, TimeZone } from '@packages/models/api';
import { useAbortController } from '@packages/core/http';
import { accountsService, authService, dashboardService, storageService } from '@web/services/singletons';

import NotificationSettingPane from '@web/components/account-settings-notifications';

const useAccountSettingsModalStyles = createUseStyles({
    modalBody: {
        minHeight: 466,
        display: 'flex',
    },
    tabLinksWrapper: {
        marginTop: 16,
    },
    tabLink: {
        color: colors.textDarkPrimary,
        '&:hover': {
            color: colors.textDarkPrimary,
        },
        '&.active': {
            fontWeight: 'bold',
            borderBottom: `2px solid ${colors.blueOne}`,
        },
    },
});

interface AccountSettingsModalProps {
    show: boolean;
    onHide(): void;
    initialActiveTabKey?: string; // Optional prop for initial active tab
    handleSetInitialTab(): void;
}

const AccountSettingsModal: FC<AccountSettingsModalProps> = (props) => {
    const { t } = useTranslation();
    const classes = useAccountSettingsModalStyles();
    const { abortSignalRef } = useAbortController();
    const { account } = useAuthState();
    const authDispatch = useAuthDispatch();

    const [timeZones, setTimeZones] = useState<TimeZone[]>([]);

    const [dashboardSelectValue, setDashboardSelectValue] = useState(account?.defaultSavedSearchId ?? '');
    const [dashboardSelectOptions, setDashboardSelectOptions] = useState<SelectOption[]>([]);

    const [defaultPhoneId, setDefaultPhoneId] = useState(account?.defaultPhoneNumberId ?? '');
    const [defaultEmailId, setDefaultEmailId] = useState(account?.defaultEmailAddressId ?? '');
    const [defaultTimeZone, setDefaultTimeZone] = useState(account?.timeZone ?? '');
    const [enableEmail, setEnableEmail] = useState(account?.emailNotificationsEnabled);

    const [isSaving, setIsSaving] = useState(false);

    const patchAccount = useCallback(
        (accountPatch: PatchAccountDto) => {
            if (!account) {
                return Promise.reject();
            }

            setIsSaving(true);

            return patchAuthUserProfile({
                authDispatch,
                authService,
                accountId: account.accountId,
                accountPatch,
                signal: abortSignalRef.current,
            })
                .catch((e) => {
                    alert(e.message);
                })
                .finally(() => {
                    setIsSaving(false);
                });
        },
        [abortSignalRef, account, authDispatch]
    );

    useEffect(() => {
        if (!props.show) {
            return;
        }

        props.handleSetInitialTab();

        async function fetchData() {
            try {
                const [timeZoneResponse, savedSearchesResponse] = await Promise.all([
                    accountsService.getTimeZones({ signal: abortSignalRef.current }),
                    dashboardService.getDashboardSavedSearches({ size: 50 }),
                ]);

                if (timeZoneResponse.success) {
                    setTimeZones(timeZoneResponse.data.timeZones);
                } else {
                    if (!timeZoneResponse.aborted) {
                        throw timeZoneResponse.data;
                    }
                }

                if (savedSearchesResponse.success) {
                    const options = savedSearchesResponse.data.savedSearches.map((s) => {
                        return {
                            value: s.savedSearchId,
                            title: s.name,
                        };
                    });

                    options.unshift({
                        value: '',
                        title: 'My Drafts',
                    });

                    setDashboardSelectOptions(options);
                } else {
                    if (!savedSearchesResponse.aborted) {
                        throw savedSearchesResponse.data;
                    }
                }
            } catch (error) {
                window.alert(error.message);
            }
        }

        fetchData();
    }, [abortSignalRef, props.show]);

    useEffect(() => {
        if (!account) {
            return;
        }

        setDefaultPhoneId(account.defaultPhoneNumberId);
        setDefaultEmailId(account.defaultEmailAddressId);
        setDefaultTimeZone(account.timeZone);
        setEnableEmail(account.emailNotificationsEnabled);
    }, [account]);

    async function handleDashboardSettingsSave() {
        setIsSaving(true);

        try {
            if (!account) {
                throw new Error('Could not find account.');
            }

            // If user selects the default "My Draft", it is an empty string
            // and it means we should remove the default saved search (refer to fetchData()), we must set to null.
            await accountsService.updateAccount(account.accountId, {
                defaultSavedSearchId: dashboardSelectValue || null,
            });
            await patchAccount({});
        } catch (error) {
            window.alert(error);
            console.error(error);
        }

        setIsSaving(false);
    }

    return (
        <Modal size="lg" show={props.show} onHide={props.onHide} centered>
            <Tab.Container id="settings-tabs" defaultActiveKey={props.initialActiveTabKey || 'profile'}>
                <Modal.Header closeButton className="pb-0" placeholder={''}>
                    <Typography variant="h4">{t('modals:accountSettings.title', 'Account Settings')}</Typography>

                    <Nav className={classes.tabLinksWrapper}>
                        <Nav.Item>
                            <Nav.Link eventKey="profile" className={classNames('px-0 pb-3 mr-4', classes.tabLink)}>
                                {t('modals:accountSettings.tabs.profile.label', 'Profile')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="notifications"
                                className={classNames('px-0 pb-3 mx-4', classes.tabLink)}
                            >
                                {t('modals:accountSettings.tabs.notifications.label', 'Notifications')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="dashboard" className={classNames('px-0 pb-3 mx-4', classes.tabLink)}>
                                {t('modals:accountSettings.tabs.dashboard.label', 'Dashboard')}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Modal.Header>

                <Modal.Body className={classes.modalBody}>
                    <Tab.Content className="w-100">
                        <Tab.Pane eventKey="profile" className="pt-0">
                            <Row>
                                <Col xs={6} className="mb-4">
                                    <div>
                                        <Typography variant="label">
                                            {t('modals:accountSettings.profile.username', 'Subarunet Username')}
                                        </Typography>
                                    </div>

                                    <Typography variant="lead">{account?.soaUsername || 'N/A'}</Typography>
                                </Col>

                                <Col xs={6} className="mb-4">
                                    <div>
                                        <Typography variant="label">
                                            {t('modals:accountSettings.profile.fullName', 'Full Name')}
                                        </Typography>
                                    </div>

                                    <Typography variant="lead">{account?.name}</Typography>
                                </Col>

                                <Col xs={6} className="mb-4">
                                    <div>
                                        <Typography variant="label">
                                            {t('modals:accountSettings.profile.phoneNumber', 'Phone')}
                                        </Typography>
                                    </div>

                                    {account && account.phoneNumbers.length > 1 ? (
                                        <Select
                                            value={defaultPhoneId}
                                            options={
                                                account?.phoneNumbers.map((pN) => ({
                                                    title: pN.phoneNumberDescription,
                                                    value: pN.phoneNumberId,
                                                })) ?? []
                                            }
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                setDefaultPhoneId(event.target.value);
                                            }}
                                        />
                                    ) : account?.phoneNumbers.length === 1 ? (
                                        <Typography variant="lead">
                                            {account.phoneNumbers[0].phoneNumberDescription}
                                        </Typography>
                                    ) : (
                                        <Typography variant="lead">N/A</Typography>
                                    )}
                                </Col>

                                <Col xs={6} className="mb-4">
                                    <div>
                                        <Typography variant="label">
                                            {t('modals:accountSettings.profile.email', 'Email')}
                                        </Typography>
                                    </div>

                                    {account && account.emailAddresses.length > 1 ? (
                                        <Select
                                            value={defaultEmailId}
                                            options={account.emailAddresses.map((eA) => ({
                                                title: eA.emailAddress,
                                                value: eA.emailAddressId,
                                            }))}
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                setDefaultEmailId(event.target.value);
                                            }}
                                        />
                                    ) : account?.emailAddresses.length === 1 ? (
                                        <Typography variant="lead">
                                            {account?.emailAddresses[0].emailAddress}
                                        </Typography>
                                    ) : (
                                        <Typography variant="lead">N/A</Typography>
                                    )}
                                </Col>

                                <Col xs={6} className="mb-4">
                                    <div>
                                        <Typography variant="label">
                                            {t('modals:accountSettings.profile.timezone', 'Timezone')}
                                        </Typography>
                                    </div>

                                    <Select
                                        value={defaultTimeZone}
                                        options={timeZones.map((tZ) => ({ title: tZ.description, value: tZ.timeZone }))}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                            setDefaultTimeZone(event.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} className="mb-4">
                                    {account?.locations.map((locationProfile, idx) => {
                                        return (
                                            <AccountLocation
                                                key={idx}
                                                index={idx}
                                                showCount={account.locations.length > 1}
                                                elements={locationProfile.elements}
                                            />
                                        );
                                    })}
                                </Col>
                            </Row>

                            <Row>
                                {account?.baseRolesDescription && (
                                    <Col xs={6} className="mb-4">
                                        <div>
                                            <Typography variant="label">
                                                {t('modals:accountSettings.profile.userRole', 'User Role(s)')}
                                            </Typography>
                                        </div>

                                        <Typography variant="lead">{account?.baseRolesDescription}</Typography>
                                    </Col>
                                )}

                                {account?.addOnRolesDescription && (
                                    <Col xs={6} className="mb-4">
                                        <div>
                                            <Typography variant="label">
                                                {t('modals:accountSettings.profile.addOnRoles', 'Add On Roles')}
                                            </Typography>
                                        </div>

                                        <Typography variant="lead">{account?.addOnRolesDescription}</Typography>
                                    </Col>
                                )}

                                <Col xs={12}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-75">
                                            <Typography color="textDarkSecondary">
                                                {account?.isRetailerUser
                                                    ? t(
                                                          'modals:accountSettings.retailerContactInfo',
                                                          'If anything looks incorrect, contact your DEM admin (Dealer Employee Maintenance Administrator).'
                                                      )
                                                    : t(
                                                          'modals:accountSettings.contactInfo',
                                                          'If anything looks incorrect, contact the Subaru Helpdesk\r\nat 1-800-SOA-STAR (1-800-762-7827).'
                                                      )}
                                            </Typography>
                                        </div>

                                        <div className="ml-4">
                                            <Button
                                                isLoading={isSaving}
                                                onPress={() => {
                                                    if (isSaving) {
                                                        return;
                                                    }

                                                    patchAccount({
                                                        defaultEmailAddressId: defaultEmailId || undefined,
                                                        defaultPhoneNumberId: defaultPhoneId || undefined,
                                                        timeZone: defaultTimeZone || undefined,
                                                    }).then(() => {
                                                        props.onHide();
                                                    });
                                                }}
                                            >
                                                {t('modals:accountSettings.action.save', 'Save Changes')}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="notifications">
                            <NotificationSettingPane
                                enableEmail={enableEmail}
                                setEnableEmail={setEnableEmail}
                                account={account}
                                patchAccount={patchAccount}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey="dashboard" className="h-100">
                            <div className="d-flex flex-column justify-content-between h-100">
                                <div>
                                    <div>
                                        <Typography variant="label">
                                            {t(
                                                'modals:accountSettings.dashboard.instructions',
                                                'Select which QMR view you want to display on the homescreen.'
                                            )}
                                        </Typography>
                                    </div>
                                    <div className="mb-2">
                                        <Typography variant="caption" color="graySix">
                                            {t(
                                                'modals:accountSettings.dashboard.notice',
                                                'You must first create a saved QMR search to update this view.'
                                            )}
                                        </Typography>
                                    </div>
                                    <Select
                                        value={dashboardSelectValue}
                                        options={dashboardSelectOptions}
                                        onChange={(event) => {
                                            setDashboardSelectValue(event.currentTarget.value);
                                        }}
                                    />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        isLoading={isSaving}
                                        onPress={handleDashboardSettingsSave}
                                        disabled={
                                            dashboardSelectValue === account?.defaultSavedSearchId ||
                                            // Checks if the user has selected the default "My Drafts" and the account has no default saved search
                                            (dashboardSelectValue === '' && account?.defaultSavedSearchId === undefined)
                                        }
                                    >
                                        {t('modals:accountSettings.action.save', 'Save Changes')}
                                    </Button>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Modal.Body>
            </Tab.Container>
        </Modal>
    );
};

AccountSettingsModal.defaultProps = {
    handleSetInitialTab: () => {},
};

export default AccountSettingsModal;
