import React, { FC, useCallback, useEffect, useState } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';

import { HttpClient, useAbortController } from '@packages/core/http';

import { Typography } from './typography';
import { Button } from './button';
import { Collapse } from './collapse';

export const DebugPermissions: FC<{ account: any; httpClient: HttpClient; onRefetch: () => void }> = ({
    account,
    httpClient,
    onRefetch,
}) => {
    const { abortSignalRef } = useAbortController();
    const [capabilities, setCapabilities] = useState<any>(null);
    const [permissions, setPermissions] = useState<any>({});

    const [showCapabilities, setShowCapabilities] = useState(false);
    const [showRoleContexts, setShowRoleContexts] = useState(false);
    const [showRetailerPermissions, setShowRetailerPermissions] = useState(false);
    const [showGlobalPermissions, setGlobalShowPermissions] = useState(false);
    const [showOrgPermissions, setShowOrgPermissions] = useState(false);

    const fetchPermissions = useCallback(() => {
        if (!account || !account.accountId) {
            return;
        }

        setCapabilities(null);
        setPermissions({});

        const config = {
            method: 'GET' as const,
            signal: abortSignalRef.current,
            ignoreCache: true,
        };

        httpClient
            .orchestrateRequest<any>({
                ...config,
                url: `/accounts/${account.accountId}/permissions-debug`,
            })
            .then((permissionsResponse) => {
                if (permissionsResponse.success) {
                    setPermissions(permissionsResponse.data);
                } else {
                    console.log('Debugger error::', { permissionsResponse });
                }
            })
            .catch((e) => {
                console.log('Debugger error::', e);
            });
    }, [abortSignalRef, account, httpClient]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    if (!account) {
        return (
            <View>
                <Typography>Must be signed in.</Typography>
            </View>
        );
    }

    return (
        <View>
            <Button
                title="Re-fetch permissions"
                onPress={() => {
                    typeof onRefetch === 'function' && onRefetch();
                    fetchPermissions();
                }}
            />

            <Typography variant="label">
                Account id: <Typography>{account.accountId}</Typography>
            </Typography>

            <TouchableOpacity
                onPress={() => {
                    setShowCapabilities(!showCapabilities);
                }}
                style={{ minHeight: 40 }}
            >
                <Typography variant="label">Capabilities</Typography>

                {capabilities && (
                    <Collapse open={showCapabilities}>
                        <Typography>{JSON.stringify(capabilities, null, 4)}</Typography>
                    </Collapse>
                )}
            </TouchableOpacity>

            <TouchableOpacity
                onPress={() => {
                    setShowRoleContexts(!showRoleContexts);
                }}
            >
                <Typography variant="label">Role Contexts</Typography>

                {permissions.roleContexts && (
                    <Collapse open={showRoleContexts}>
                        <ScrollView>
                            <Typography>{JSON.stringify(permissions.roleContexts, null, 4)}</Typography>
                        </ScrollView>
                    </Collapse>
                )}
            </TouchableOpacity>

            <TouchableOpacity
                onPress={() => {
                    setShowRetailerPermissions(!showRetailerPermissions);
                }}
            >
                <Typography variant="label">Retailer Permissions</Typography>

                {permissions.retailerPermissions && (
                    <Collapse open={showRetailerPermissions}>
                        <Typography>{JSON.stringify(permissions.retailerPermissions, null, 4)}</Typography>
                    </Collapse>
                )}
            </TouchableOpacity>

            <TouchableOpacity
                onPress={() => {
                    setGlobalShowPermissions(!showGlobalPermissions);
                }}
            >
                <Typography variant="label">Global Permissions</Typography>

                {permissions.globalPermissions && (
                    <Collapse open={showGlobalPermissions}>
                        <Typography>{JSON.stringify(permissions.globalPermissions, null, 4)}</Typography>
                    </Collapse>
                )}
            </TouchableOpacity>

            <TouchableOpacity
                onPress={() => {
                    setShowOrgPermissions(!showOrgPermissions);
                }}
            >
                <Typography variant="label">Org Permissions</Typography>
                {permissions.orgPermissions && (
                    <Collapse open={showOrgPermissions}>
                        {permissions.orgPermissions?.map((r: any) => {
                            return <Typography>{r}</Typography>;
                        })}
                    </Collapse>
                )}
            </TouchableOpacity>
        </View>
    );
};
