import React, { FC } from 'react';
import { BmisBatteryBadge } from '@web/bmis/components/bmis-battery-badge';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { bmisActions, useBmisDispatch, useBmisState } from '@packages/contexts/bmis';
import { getDisplayValue } from '@web/utils';
import { useNavigate, useParams } from 'react-router-dom';

export const BmisHeader: FC = () => {
    const bmisDispatch = useBmisDispatch();
    const navigate = useNavigate();
    const { vin: vinString } = useParams<{ vin: string }>();
    const { selectedBmisRow } = useBmisState();

    function handleButtonPress() {
        navigate(`/vin/${vinString}/bmis`);
        bmisDispatch(bmisActions.setRowGuid({ rowGuid: null }));
    }

    return (
        <>
            <div className="d-flex align-items-center">
                <div className="mr-4">
                    <Button variant="link" onPress={handleButtonPress}>
                        <Icon name="arrow-back" />
                    </Button>
                </div>
                <div className="mb-0 mr-4">
                    <Typography variant="h5">{getDisplayValue(selectedBmisRow?.starterTestCode)}</Typography>
                </div>
                <BmisBatteryBadge label={selectedBmisRow?.finalBatteryTestDecision} />
            </div>
            <div className="d-flex align-items-center">
                <div className="ml-6">
                    <Button variant="link-header-button">
                        <Icon name="download" color="blueOne" />
                    </Button>
                </div>
                <div className="ml-4">
                    <Button variant="link-header-button">
                        <Icon name="more-dots-vertical" color="blueOne" />
                    </Button>
                </div>
            </div>
        </>
    );
};
