import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
    const location = useLocation();

    return useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export default useQuery;
