export enum WorksheetAddFieldEnum {
    Description = 'DESCRIPTION',
    Condition = 'CONDITION',
    ToolTips = 'TOOLTIPS',
}

export enum WorksheetAction {
    Edit = 'EDIT',
    New = 'NEW',
    AddVersion = 'ADD VERSION',
}

export enum WorksheetMode {
    Edit = 'Edit',
    Preview = 'Preview',
}
