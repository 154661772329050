import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import { ReactComponent as NoMatchIcon } from '@web/assets/404.svg';
import { Typography, Button } from '@packages/ui/shared';

const useNoMatchStyles = createUseStyles({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        margin: '40px 0 14px 0',
        textAlign: 'center',
    },
    message: {
        marginBottom: 40,
        textAlign: 'center',
    },
    action: {},
});

const NoMatch = () => {
    const classes = useNoMatchStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={classes.container}>
            <NoMatchIcon />

            <div className={classes.title}>
                <Typography variant="h3">{t('nav:noMatch.title', "Oops! There's been a wrong turn.")}</Typography>
            </div>

            <div className={classes.message}>
                <Typography variant="lead">
                    {t(
                        'nav:noMatch.message',
                        'The page you’re looking for was not found.\r\nWe suggest heading back home.'
                    )}
                </Typography>
            </div>

            <Button
                title={t('buttons:backToHome', 'Back to Home')}
                onPress={() => {
                    navigate('/');
                }}
            />
        </div>
    );
};

export default NoMatch;
