import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import AsyncPage from '@web/components/async-page';
import { DetailView, DetailViewBody, DetailViewContent } from '@web/components/detail-view';
import { Icon, Typography } from '@packages/ui/shared';
import { WorksheetFormHeader } from '../components/worksheet-form-header';
import { WorksheetFormElement } from '../components/worksheet-form-element';
import Loader from '@web/components/loader';
import { WorksheetAction, WorksheetMode } from '../types';
import { WorkSheetStatusEnum } from '@packages/models/api';
import useWorksheetFormDetails from '../worksheet-form/use-worksheet-form-detail.hooks';
import WorksheetRightSideBar, { FormRightBarType } from '../components/worksheet-form-right-sidebar';
import { WorksheetModal } from '../components/worksheet-modal';
import { DropResult } from 'react-beautiful-dnd';
import { FormElementModalProvider } from '@packages/contexts/form-element-modal';
import useWorksheetFormTranslation from '../hooks/use-worksheet-form-translation';

export const WorksheetDetail: FC = () => {
    const navigate = useNavigate();
    const { worksheetFormId } = useParams<{ worksheetFormId: string }>();
    const [createdWorksheet, setCreatedWorksheet] = useState<boolean>(false);
    const [showCreateWorksheet, setShowCreateWorksheet] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState({ saveAndExit: false, save: false });
    const [modalBodyMessage, setModalBodyMessage] = useState<string>('');
    const { modalDataTranslate } = useWorksheetFormTranslation();
    const [exit, setExit] = useState(false);
    const [searchParams] = useSearchParams();
    const maxVersion = parseInt(searchParams.get('maxVersion') || '1');
    const worksheetStatus = (searchParams.get('status') as WorkSheetStatusEnum) || WorkSheetStatusEnum.Pending;
    const worksheetAction = useMemo(() => {
        return searchParams.get('action') as WorksheetAction;
    }, [searchParams]);

    const { elements, fetching, worksheetInfo, worksheetDisplayVersion, actions, worksheetSaveAndExit, saveState } =
        useWorksheetFormDetails({
            worksheetFormId: worksheetFormId,
            worksheetAction: worksheetAction,
            maxVersion,
        });

    const handleSaveAndExit = useCallback(
        async (isExit: boolean) => {
            if (!worksheetInfo.displayname || !worksheetInfo.worksheetGroupName) {
                setShowErrorModal(true);
                setModalBodyMessage(
                    !worksheetInfo.worksheetGroupName
                        ? modalDataTranslate.requiredField.bodyMessage.worksheetName
                        : modalDataTranslate.requiredField.bodyMessage.worksheetTitle
                );
                return;
            }
            setExit(isExit);
            await worksheetSaveAndExit({
                setModalBodyMessage,
                setCreatedWorksheet,
                setIsLoading,
                setShowCreateWorksheet,
                isExit,
                mode: WorksheetMode.Preview,
            });
        },
        [worksheetInfo, worksheetSaveAndExit, modalDataTranslate]
    );

    const handleModalActionOnSave = useCallback(
        (ans: any) => {
            if (createdWorksheet && exit) {
                navigate('/administration/worksheets');
            }
            setShowCreateWorksheet(false);
        },
        [createdWorksheet, navigate]
    );

    const handleEdit = useCallback(() => {
        saveState(worksheetInfo, elements);
        if ([WorksheetAction.Edit, WorksheetAction.AddVersion].includes(worksheetAction)) {
            navigate(
                `/administration/worksheet/${worksheetFormId}/edit?action=${worksheetAction}&status=${worksheetStatus}${
                    worksheetAction === WorksheetAction.AddVersion ? `&maxVersion=${maxVersion}` : ''
                }`
            );
        } else {
            navigate(`/administration/worksheet/new?action=${worksheetAction}&status=${WorkSheetStatusEnum.Pending}`);
        }
    }, [elements, maxVersion, navigate, saveState, worksheetAction, worksheetFormId, worksheetInfo, worksheetStatus]);

    return (
        <AsyncPage fetchData={actions.fetchData}>
            {fetching ? (
                <Loader />
            ) : (
                <>
                    <WorksheetModal
                        show={showCreateWorksheet}
                        title={
                            worksheetFormId
                                ? modalDataTranslate.createOrUpdateWorksheet.title.update
                                : modalDataTranslate.createOrUpdateWorksheet.title.create
                        }
                        onHideModal={() => {
                            if (exit) {
                                navigate('/administration/worksheets');
                            }
                            setShowCreateWorksheet(false);
                        }}
                        bodyMessage={modalBodyMessage}
                        confirmButton="Ok"
                        handleAction={handleModalActionOnSave}
                        closeButton={false}
                    />
                    <WorksheetModal
                        show={showErrorModal}
                        title={modalDataTranslate.requiredField.title}
                        onHideModal={() => setShowErrorModal(false)}
                        warningMessage={modalBodyMessage}
                        confirmButton={modalDataTranslate.requiredField.confirmButton}
                        handleAction={() => {
                            setShowErrorModal(false);
                        }}
                        closeButton={false}
                    />
                    <DetailView>
                        <WorksheetFormHeader
                            handlePreview={handleEdit}
                            handleSaveAndExit={handleSaveAndExit}
                            worksheetInfo={worksheetInfo}
                            worksheetDisplayVersion={worksheetDisplayVersion}
                            isLoading={isLoading}
                            icon="pencil"
                            worksheetStatus={worksheetStatus}
                            worksheetFormId={worksheetFormId}
                            isPreview={true}
                        />
                        <DetailViewContent topOffset="breadcrumb">
                            <DetailViewBody>
                                <Row className="mb-8">
                                    <Col>
                                        <div className="d-flex align-items-center">
                                            <Typography variant="h3">{worksheetInfo?.displayname}</Typography>
                                            <div className="ml-1 d-flex">
                                                <Icon name="info" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <FormElementModalProvider>
                                    <Row>
                                        <Col>
                                            {elements?.map((el: any) => {
                                                return (
                                                    <WorksheetFormElement
                                                        key={el.formElementId}
                                                        item={el}
                                                        handleDragEnd={(result: DropResult) => {}}
                                                        preview
                                                    />
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </FormElementModalProvider>
                            </DetailViewBody>
                            <WorksheetRightSideBar worksheetInfo={worksheetInfo} type={FormRightBarType.Preview} />
                        </DetailViewContent>
                    </DetailView>
                </>
            )}
        </AsyncPage>
    );
};
