import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect } from 'react';

import { TextInputHelper } from '@packages/ui/shared';
import { DraggableWorksheetFormComponent, FormElementFile, WorksheetFormElementOption } from '@packages/models/api';
import AddField from './add-field';
import { AddFieldType } from './worksheet-form-component-edit-modal';
import colors from '@packages/core/styles/colors';
import { useValidationField } from './hooks/useValidationField';
import useTranslationData from './hooks/useTranslationData';
import { WorksheetCharacterLengthValidation } from '@web/administration/views/worksheets/utils';

interface FormWorksheetEditTextInputProps extends DraggableWorksheetFormComponent {
    onChange({
        formElementLabel,
        options,
        formElementFile,
        formElementDescription,
    }: {
        formElementLabel?: string;
        options?: WorksheetFormElementOption[] | undefined;
        worksheetElementTooltip?: string;
        formElementDescription?: string;
        formElementFile?: FormElementFile;
    }): void;
    addField: AddFieldType;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormEditTextInput: FC<FormWorksheetEditTextInputProps> = ({
    formElementLabel,
    formElementTooltip,
    formElementFile,
    formElementDescription,
    options,
    onChange,
    addField,
    setIsValid,
}) => {
    const {
        fieldHandlers: { handleRequired },
        fieldErrorMessages,
        isValid,
    } = useValidationField();
    const { draggableElementsDataTranslate } = useTranslationData();

    useEffect(() => {
        setIsValid(isValid);
    }, [Object.keys(fieldErrorMessages || {})]);

    const handlePlaceholderChangeText = useCallback(
        (text: string) => {
            if (!options || text.trim().length > WorksheetCharacterLengthValidation.QuestionLength) {
                return;
            }

            const optionsClone = cloneDeep(options);
            optionsClone[options?.length - 1].optionPlaceholder = text;

            onChange({ options: optionsClone });
        },
        [onChange, options]
    );

    return (
        <>
            <AddField
                addFieldValues={addField}
                onchangeText={onChange}
                formElementFile={formElementFile}
                formElementTooltip={formElementTooltip}
                formElementDescription={formElementDescription}
                formElementLabel={formElementLabel}
                errorMessage={fieldErrorMessages?.questionLabel}
                handleRequired={handleRequired}
            />

            <hr style={{ borderWidth: 2, borderColor: colors.grayTwo }} />

            <div className="mt-8">
                <TextInputHelper
                    value={options?.[options?.length - 1]?.optionPlaceholder}
                    onChangeText={(text) => {
                        handlePlaceholderChangeText(text);
                    }}
                    placeholder={draggableElementsDataTranslate.placeholder.optionPlaceholder}
                />
            </div>
        </>
    );
};
