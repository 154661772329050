import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect } from 'react';

import { DraggableWorksheetFormComponent, WorksheetFormElementOption } from '@packages/models/api';
import { AddFieldType } from './worksheet-form-component-edit-modal';
import AddField from './add-field';
import { InputPickerHelper } from './inputPickers/input-picker-helper';
import colors from '@packages/core/styles/colors';
import { useValidationField } from './hooks/useValidationField';

interface FormWorksheetEditDateTimeProps extends DraggableWorksheetFormComponent {
    onChange({
        formElementLabel,
        options,
        worksheetElementTooltip,
        formElementDescription,
    }: {
        formElementLabel?: string;
        options?: WorksheetFormElementOption[];
        worksheetElementTooltip?: string;
        formElementDescription?: string;
    }): void;
    addField: AddFieldType;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormEditDateTime: FC<FormWorksheetEditDateTimeProps> = ({
    formElementLabel,
    formElementFile,
    options,
    onChange,
    addField,
    formElementTooltip,
    formElementDescription,
    setIsValid,
}) => {
    const {
        fieldHandlers: { handleRequired },
        fieldErrorMessages,
        isValid,
    } = useValidationField();
    useEffect(() => {
        setIsValid(isValid);
    }, [Object.keys(fieldErrorMessages || {})]);

    const handleTimeChange = useCallback(
        (time: string) => {
            if (!options) {
                return;
            }
            const optionsClone = cloneDeep(options);
            optionsClone[0].optionLabel = time;
            onChange({ options: optionsClone });
        },
        [onChange, options]
    );

    return (
        <>
            <AddField
                addFieldValues={addField}
                onchangeText={onChange}
                formElementFile={formElementFile}
                formElementTooltip={formElementTooltip}
                formElementDescription={formElementDescription}
                formElementLabel={formElementLabel}
                errorMessage={fieldErrorMessages?.questionLabel}
                handleRequired={handleRequired}
            />
            <hr style={{ borderWidth: 2, borderColor: colors.grayTwo }} />

            <InputPickerHelper
                type="dateTime"
                onChange={(time) => handleTimeChange(time)}
                iconName={'calendar'}
                data={options?.[0].optionLabel || ''}
            />
        </>
    );
};

export default FormEditDateTime;
