import { useAbortController } from '@packages/core/http';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { techlineService } from '@web/services/singletons';
import { constructUrl } from '@web/utils/url-utils';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = createUseStyles({
    iframe: {
        border: '0',
    },
});

export const TechlineOpen: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { retailerId } = useParams<{ retailerId: string }>();
    const navigate = useNavigate();

    const [portalUrl, setPortalUrl] = useState('');

    const goToTechline = useCallback(
        (params = {}) => {
            navigate(constructUrl('/techline', params));
        },
        [navigate]
    );

    useEffect(() => {
        if (retailerId === ':retailerId') {
            goToTechline({ view: true });
        }
        techlineService
            .getInfotainmentCasesPortalKey({
                retailerId,
                ignoreCache: true,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }
                setPortalUrl(response.data.infotainmentUrl + response.data.encryptedRetailerId);
            })
            .catch(() => {});
    }, [abortSignalRef, goToTechline, retailerId]);

    return (
        <div className="pt-7 pl-7 pr-7 d-flex h-100">
            <div className="d-flex w-100 flex-column">
                <div className="mb-6 d-flex justify-content-between">
                    <Typography variant="h2">{t('techline:browser.title', 'Open Cases')}</Typography>
                    <div className="d-flex">
                        <Button
                            onPress={() => goToTechline({ create: true })}
                            iconRight={
                                <Icon
                                    size={16}
                                    raised
                                    name="plus"
                                    raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                                />
                            }
                        >
                            {t('techline:cardTypes.infotainment.action', 'Create Infotainment Case')}
                        </Button>
                    </div>
                </div>
                <iframe src={portalUrl} width="100%" height="1720" className={classes.iframe} />
            </div>
        </div>
    );
};
