import { AlertHook } from '@packages/ui/shared';
import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { FailCodesService } from '@packages/contexts/qmrs/fail-codes.service';
import { FailCodes } from '@packages/models/api/fail-codes';

export const useFailCodesCsvExport = ({ failCodesService }: { failCodesService: FailCodesService }) => {
    const initiateCsvExport = useCallback(
        async ({
            failCodes,
            alert,
            searchId,
        }: {
            failCodes: FailCodes[] | null;
            alert: AlertHook;
            searchId?: string | null;
        }) => {
            try {
                const response = await failCodesService.initiateCsvExport({ failCodes }, searchId);

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                alert.showAlert({
                    type: 'success',
                    content:
                        'Request to download Fail Codes as CSV is in progress. It may take a few minutes. You will receive a notification when it is ready to view.',
                });
            } catch (error) {
                alert.showAlert({
                    type: 'error',
                    content: 'Request to download Fail Codes as CSV has failed. Please try again.',
                });
            }
        },
        [failCodesService]
    );

    const initiateCsvExportMutation = useMutation(initiateCsvExport);

    const isLoading = initiateCsvExportMutation.isLoading;

    return {
        initiateCsvExportMutation,
        isLoading,
    };
};

export const useFailCodesTableActions = ({
    failCodes,
    alert,
    failCodesService,
}: {
    failCodes: FailCodes[] | null;
    alert: AlertHook;
    failCodesService: FailCodesService;
}) => {
    const failCodeCsvExport = useFailCodesCsvExport({ failCodesService });

    const actionHandlerMap: { [k: string]: () => any } = useMemo(
        () => ({
            DOWNLOAD: () => {
                failCodeCsvExport.initiateCsvExportMutation.mutateAsync({ failCodes, alert });
            },
        }),
        [failCodeCsvExport.initiateCsvExportMutation, failCodes, alert]
    );

    const handleActionPress = useCallback(
        (actionId: string) => {
            const handler = actionHandlerMap[actionId];

            if (!handler) {
                return;
            }

            handler();
        },
        [actionHandlerMap]
    );

    return {
        handleActionPress,
    };
};
