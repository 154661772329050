import * as React from 'react';
import {
    IWorksheetsState,
    IWorksheetsUpdates,
    initialState,
    WorksheetsActionDispatcher,
    worksheetsActions,
    worksheetsReducer,
} from './worksheets.state';
import { StorageService } from '@packages/core/storage';
import { WorksheetForm } from '@packages/models/api';

export const WorksheetDispatchContext = React.createContext<WorksheetsActionDispatcher | undefined>(undefined);

export const baseWorksheetInfo: WorksheetForm = {
    caseTypeId: '',
    northstarId: '',
    worksheetGroupId: '',
    worksheetGroupName: '',
    groupStatus: 'ACTIVE',
    worksheetId: undefined,
    displayname: '',
    description: '',
    version: 1,
    elements: [],
    worksheetStatus: '',
};
export const baseWorksheet = {
    caseTypeId: '',
    caseType: '',
    northstarId: '',
    groupStatus: '',
    worksheetGroupName: '',
    elements: [],
    description: '',
    displayname: '',
    worksheetStatus: '',
    version: 1,
};

export const baseWorkSheetsUpdates: IWorksheetsUpdates[] = [];

export const WorksheetContext = React.createContext<IWorksheetsState>({
    worksheet: undefined,
    worksheetsUpdates: baseWorkSheetsUpdates,
    hasFetched: false,
    loading: false,
    errors: [],
});

export const WorksheetsProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const [state, dispatch] = React.useReducer(worksheetsReducer, initialState);
    return (
        <WorksheetContext.Provider value={state}>
            <WorksheetDispatchContext.Provider value={dispatch}>{children}</WorksheetDispatchContext.Provider>
        </WorksheetContext.Provider>
    );
};

export async function saveWorksheet({
    worksheet,
    storageService,
    worksheetDispatch,
}: {
    worksheetsId?: string;
    worksheet: IWorksheetsState['worksheet'];
    storageService: StorageService;
    worksheetDispatch: WorksheetsActionDispatcher;
}) {
    storageService.setItem('newWorksheet', worksheet);
    worksheetDispatch(worksheetsActions.setWorksheet({ worksheet: worksheet }));
}
