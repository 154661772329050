import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import colors from '@packages/core/styles/colors';

const useTableRowStyles = createUseStyles({
    tableRow: ({ checked }: { checked: boolean }) => ({
        borderBottom: `1px solid ${colors.grayThree}`,
        backgroundColor: checked ? colors.blueFour : colors.white,
        '&:last-child': {
            borderBottom: 0,
        },
    }),
});

interface TableRowProps {
    checked?: boolean;
    className?: string;
    children?: React.ReactNode;
}

export const TableRow: FC<TableRowProps> = React.memo(({ checked, className, children }) => {
    const classes = useTableRowStyles({
        checked,
    });

    return <tr className={classNames(classes.tableRow, className)}>{children}</tr>;
});

TableRow.displayName = 'TableRow';
