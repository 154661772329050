import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useQmrState, getQmrVehicleDetails } from '@packages/contexts/qmrs';
import { Button, Typography } from '@packages/ui/shared';
import EditVehicleModal from '@web/components/edit-vehicle-modal';

const VehicleInformationDraft: FC = () => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const vehicleDetails = getQmrVehicleDetails(qmr);
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');
    const [showEditVehicleModal, setShowEditVehicleModal] = useState(false);

    const canEditVehicleDetails =
        !editRouteMatch ||
        (qmr &&
            (qmr.capabilities.editCarlineOnQmr ||
                qmr.capabilities.editColorOnQmr ||
                qmr.capabilities.editEngineNumberOnQmr ||
                qmr.capabilities.editModelOnQmr ||
                qmr.capabilities.editModelOptionOnQmr ||
                qmr.capabilities.editModelYearOnQmr ||
                qmr.capabilities.editTransmissionNumberOnQmr ||
                qmr.capabilities.editVinOnQmr));

    return (
        <>
            {canEditVehicleDetails && (
                <EditVehicleModal
                    show={showEditVehicleModal}
                    onHide={() => {
                        setShowEditVehicleModal(false);
                    }}
                />
            )}

            <div className="d-flex justify-content-between">
                <Typography variant="h5">{t('qmr:sections.vehicleInfo.title')}</Typography>
                {canEditVehicleDetails && (
                    <div className="d-inline-block">
                        <Button
                            variant="tertiary"
                            onPress={() => {
                                setShowEditVehicleModal(true);
                            }}
                        >
                            {t('qmr:sections.vehicleInfo.updateInfo')}
                        </Button>
                    </div>
                )}
            </div>

            <div className="d-flex flex-column">
                <Typography variant="h6">{vehicleDetails.displayName}</Typography>
                {(!editRouteMatch || qmr?.capabilities.viewVinOnQmr) && (
                    <Typography>
                        {t('qmr:details.vin')}: {vehicleDetails.vin}
                    </Typography>
                )}
            </div>
        </>
    );
};

export default VehicleInformationDraft;
