import { HttpClient } from '@packages/core/http';
import { EntityModel } from '@packages/models/api';

interface GetEntitiesQueryParameters {
    size?: number;
    page?: number;
    query?: string;
}

interface GetEntitiesResponseBody {
    totalCount: number;
    entities: EntityModel[];
}

interface GetEntityResponseBody {
    entity: EntityModel;
}

interface CreateEntityRequestBody {
    name: string;
    description: string;
    parentEntityId?: string;
    members: string[];
    visible: boolean;
    active: boolean;
}

interface CreateEntityResponseBody {
    entity: EntityModel;
}

export class EntitiesService {
    constructor(private http: HttpClient) {}

    getEntities(queryParameters?: GetEntitiesQueryParameters) {
        return this.http.orchestrateRequest<GetEntitiesResponseBody>({
            method: 'GET',
            url: `/entities${this.http.queryString(queryParameters || {})}`,
        });
    }

    getEntity(entityId: string) {
        return this.http.orchestrateRequest<GetEntityResponseBody>({
            method: 'GET',
            url: `/entities/${entityId}`,
        });
    }

    createEntity(body: CreateEntityRequestBody) {
        return this.http.orchestrateRequest<CreateEntityResponseBody>({
            method: 'POST',
            url: '/entities',
            body,
        });
    }

    updateEntity(entityId: string, body: CreateEntityRequestBody) {
        return this.http.orchestrateRequest<CreateEntityResponseBody>({
            method: 'PUT',
            url: `/entities/${entityId}`,
            body,
        });
    }
}
