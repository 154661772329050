import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Color from 'color';

import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    removeButton: {
        border: 0,
        width: 20,
        height: 20,
        flexShrink: 0,
        appearance: 'none',
        borderRadius: '50%',
        position: 'relative',
        backgroundColor: colors.blueOne,
        '&:hover': {
            backgroundColor: Color(colors.blueOne)
                .lighten(0.16)
                .hex(),
        },
        '&:active': {
            backgroundColor: Color(colors.blueOne)
                .darken(0.16)
                .hex(),
        },
        '&:after': {
            width: 10,
            height: 2,
            top: '50%',
            left: '50%',
            content: '""',
            display: 'block',
            position: 'absolute',
            backgroundColor: colors.white,
            transform: 'translate(-50%, -50%)',
        },
    },
});

interface RemoveButtonProps {
    className?: string;
    onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const RemoveButton: FC<RemoveButtonProps> = ({ className, onClick }) => {
    const classes = useStyles();

    return <button className={classNames(classes.removeButton, className)} onClick={onClick} />;
};
