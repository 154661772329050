import { ConditionModel, FlatGroupConditionModel, FlatQueryModel, GroupConditionModel, QueryModel } from '../models';

export function isCondition(rule: ConditionModel | GroupConditionModel): rule is ConditionModel {
    const hasField = typeof (rule as ConditionModel).fieldId !== 'undefined';
    const hasOperator = typeof (rule as ConditionModel).operatorId !== 'undefined';
    const hasValue = typeof (rule as ConditionModel).value !== 'undefined';
    return hasField && hasOperator && hasValue;
}

export function isGroupCondition(rule: ConditionModel | GroupConditionModel): rule is GroupConditionModel {
    const hasCombinatorId = typeof (rule as GroupConditionModel).combinatorId !== 'undefined';
    return hasCombinatorId;
}

export function isFlatGroupCondition(rule: ConditionModel | FlatGroupConditionModel): rule is FlatGroupConditionModel {
    const hasCombinatorId = typeof (rule as FlatGroupConditionModel).combinatorId !== 'undefined';
    return hasCombinatorId;
}

export function flattenQuery(top: GroupConditionModel): FlatQueryModel {
    let flatData: FlatQueryModel = {};

    flatData[top.id] = {
        ...top,
        rules: top.rules.map((rule: any) => {
            return rule.id;
        }),
    };

    function flatten(source: QueryModel) {
        for (let i = 0; i < source.length; i++) {
            const currentIteration = source[i];

            if (isGroupCondition(currentIteration)) {
                const flatGroup: FlatGroupConditionModel = {
                    ...currentIteration,
                    rules: currentIteration.rules.map((rule: any) => {
                        return rule.id;
                    }),
                };

                flatData[currentIteration.id] = flatGroup;
                flatten(currentIteration.rules);
            } else {
                flatData[currentIteration.id] = currentIteration;
            }
        }
    }

    flatten(top.rules);

    return flatData;
}

export function unflattenQuery(top: FlatQueryModel): GroupConditionModel {
    function unflatten(source: ConditionModel | FlatGroupConditionModel): ConditionModel | GroupConditionModel {
        if (isFlatGroupCondition(source)) {
            return {
                ...source,
                rules: source.rules.map((rule) => {
                    const currentIteration = top[rule];

                    if (isFlatGroupCondition(currentIteration)) {
                        return unflatten(currentIteration);
                    }

                    return top[rule];
                }),
            } as GroupConditionModel;
        }

        return source;
    }

    const first = Object.values(top)[0];

    return unflatten(first) as GroupConditionModel;
}

export function removeIdFromFlattenedQuery(id: string, query: FlatQueryModel) {
    const flattenedQuery: FlatQueryModel = {};
    const entries = Object.entries(query);
    const updatedEntries: [string, ConditionModel | FlatGroupConditionModel][] = entries.map(([key, value]) => {
        if (isFlatGroupCondition(value)) {
            if (value.rules.includes(id)) {
                value.rules = value.rules.filter((rule) => rule !== id);
                return [key, value];
            }

            return [key, value];
        }

        return [key, value];
    });

    updatedEntries.forEach(([key, value]) => {
        flattenedQuery[key] = value;
    });

    delete flattenedQuery[id];

    return flattenedQuery;
}
