import RecentSearchTypeahead from '@web/components/recent-search-typeahead';
import React, { useCallback, useEffect, useRef, useState, FC, useMemo } from 'react';
import { qmrsService, analyticsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { useLocation, useNavigate } from 'react-router-dom';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { useTranslation } from 'react-i18next';
import useQuery from '@web/core/hooks/use-query';
import { Button, Icon } from '@packages/ui/shared';
import CreateQmrModal from '@web/components/create-qmr-modal';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import QmrQuickFiltersPanel from '@web/qmr/components/qmr-quick-filters-panel';

interface QmrSearchBarProps {
    path: string;
    account: any;
    includeFilters: boolean;
}

const QmrSearch: FC<QmrSearchBarProps> = ({ path, account, includeFilters }) => {
    const { abortSignalRef } = useAbortController();
    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const [showCreateQmrModal, setShowCreateQmrModal] = useState(false);
    const searchInputRef = useRef(null);
    const { t } = useTranslation();
    const { pathname, search } = useLocation();
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = useQuery();
    const quickSearchQuery = queryParams.get('query') || '';
    const queryMatch = search?.match(/query=([^&]*)/);
    let lastSearchQuery = queryMatch !== null ? queryMatch[1] : '';
    const hasQuickFilters = useMemo(() => {
        return (
            queryParams.has('retailer') ||
            queryParams.has('submissionDate') ||
            queryParams.has('carline') ||
            queryParams.has('yearFrom') ||
            queryParams.has('yearTo') ||
            queryParams.has('isImported') ||
            queryParams.has('hasTurboIndicator') ||
            queryParams.has('hasAttachments')
        );
    }, [queryParams]);

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (!params.has('query')) {
            (searchInputRef.current as any).clear();
        }
    }, [search]);

    const fetchRecentSearches = useCallback(() => {
        qmrsService.fetchRecentQuickSearches({ ignoreCache: true, signal: abortSignalRef.current }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setRecentSearches(response.data.recentSearches);
        });
    }, [abortSignalRef]);

    const toggleQuickFilterPanel = useCallback(() => {
        toggleOverlay('qmrQuickFilters');
    }, [toggleOverlay]);

    useEffect(() => {
        if (location.state?.startNew) {
            setShowCreateQmrModal(true);
        }
    }, [location.state?.startNew]);

    useEffect(() => {
        fetchRecentSearches();
    }, [fetchRecentSearches]);

    const searchByText = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_SEARCHES_BY_TEXT);
            params.set('page', '0');
            if (text) {
                params.set('query', text);
                if (!recentSearches.includes(text)) {
                    const lastFiveRecentSearchs = [text, ...recentSearches].slice(0, 5);
                    setRecentSearches(lastFiveRecentSearchs);
                }
            } else {
                params.delete('query');
            }

            navigate(`${path}?${params.toString()}`);
        },
        [navigate, pathname, recentSearches, search]
    );

    function handleCreateQmrButtonClick() {
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_CREATE_A_QMR);
        setShowCreateQmrModal(true);
    }

    const handleClearQuickFilters = useCallback(() => {
        const newQueryParams = new URLSearchParams();

        if (quickSearchQuery) {
            newQueryParams.set('query', quickSearchQuery);
        }

        navigate(`/qmrs?${newQueryParams.toString()}`);
    }, [navigate, quickSearchQuery]);

    return (
        <div className="mt-4 d-flex" style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {toggledOverlays.qmrQuickFilters && (
                <QmrQuickFiltersPanel
                    hasActiveFilters={hasQuickFilters}
                    onClear={handleClearQuickFilters}
                    onClose={toggleQuickFilterPanel}
                />
            )}
            {(account?.systemCapabilities.createDraftQmr ||
                account?.systemCapabilities.createDraftQmrForAnyRetailer) && (
                <CreateQmrModal
                    show={showCreateQmrModal}
                    onHide={(identifier) => {
                        setShowCreateQmrModal(false);

                        if (identifier) {
                            navigate('/qmrs/' + identifier);
                        }
                    }}
                />
            )}
            <div className="d-flex" style={{ width: '60%', gap: '5px' }}>
                <div style={{ width: '80%' }}>
                    <RecentSearchTypeahead
                        ref={searchInputRef}
                        onChange={searchByText}
                        placeholder={t(
                            'views:qmrs.search.placeholder',
                            'Look for a QMR entering ID, Carline, DTC Code and more...'
                        )}
                        recentSearches={recentSearches}
                        quickSearchQuery={quickSearchQuery}
                        defaultInputValue={lastSearchQuery}
                    />
                </div>
                {includeFilters && (
                    <div>
                        <Button
                            active={hasQuickFilters}
                            variant="ghost-blue"
                            iconLeft={<Icon color="blueOne" name="filter-list" />}
                            onPress={toggleQuickFilterPanel}
                        >
                            {hasQuickFilters
                                ? t('views:qmrs.actions.filterToggle.active', 'Filters applied')
                                : t('views:qmrs.actions.filterToggle.inactive', 'Filters')}
                        </Button>
                        {hasQuickFilters && (
                            <div className="ml-1">
                                <Button variant="ghost-blue" onPress={handleClearQuickFilters}>
                                    <Icon color="blueOne" name="x-close" />
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div>
                {(account?.systemCapabilities.createDraftQmr ||
                    account?.systemCapabilities.createDraftQmrForAnyRetailer) && (
                    <div className="ml-auto d-flex">
                        <Button
                            iconRight={
                                <Icon
                                    size={16}
                                    raised
                                    name="plus"
                                    raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                                />
                            }
                            onPress={handleCreateQmrButtonClick}
                            style={{ marginRight: 10 }}
                        >
                            {t('buttons:startQmr')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QmrSearch;
