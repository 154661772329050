import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import { useAuthCallback, useAuthState } from '@packages/contexts/auth';

import {
    configService,
    authService,
    authTokenService,
    storageService,
    analyticsService,
} from '@web/services/singletons';
import { WebStorageKeys } from '@packages/core/storage';
import { Constants } from '@packages/core/config';
import { ReactComponent as TechShareLogo } from '@packages/ui/assets/images/techshare-logo.svg';
import subaruLogo from '@packages/ui/assets/images/subaru-logo.jpg';
import { Button, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { AccountStatusId } from '@packages/models/api';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const useAuthLoadingStyles = createUseStyles({
    page: {
        backgroundColor: colors.grayOne,
        paddingTop: 120,
        height: '100vh',
        overflow: 'scroll',
        paddingBottom: 120,
    },
    card: {
        width: 450,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: colors.white,
        boxShadow: `0px 1px 4px 0px rgba(0, 0, 0, 0.16)`,
        paddingTop: 20,
        paddingLeft: 72,
        paddingRight: 72,
        paddingBottom: 72,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    subaruLogo: {
        height: 48,
        width: 180,
        display: 'block',
        margin: '0 auto',
    },
    figureContainer: {
        marginBottom: 84,
        width: 250,
    },
    textContainer: {
        textAlign: 'center',
        maxWidth: '100%',
    },
    stsLogo: {
        height: 32,
        width: 208,
        marginBottom: 48,
    },
    loginBtn: {
        marginTop: 45,
        alignSelf: 'stretch',
    },
});

export const AuthLoading: FC = () => {
    const classes = useAuthLoadingStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const authRouteMatch = useMatch('/auth');
    const notAuthorizedMatch = useMatch('/not-authorized');
    const query = new URLSearchParams(location.search);
    const accessToken = query.get('accessToken');
    const { account } = useAuthState();

    const [showLoginPrompt, setShowLoginPrompt] = useState(false);
    const [showUnauthorizedPrompt, setShowUnauthorizedPrompt] = useState(false);

    const onAuthenticated = useCallback(
        (fromAccessToken: boolean) => {
            if (account) {
                analyticsService.setUser(account);
            }

            if (!fromAccessToken) {
                analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_LOGS_IN);
            }

            storageService.getItem(WebStorageKeys.AuthRedirectUrl).then((authRedirect) => {
                if (account?.accountStatusId === AccountStatusId.New) {
                    authRedirect = '/onboard';
                }
                navigate(authRedirect || '/', { replace: true });

                if (account?.accountStatusId !== AccountStatusId.New) {
                    storageService.removeItem(WebStorageKeys.AuthRedirectUrl);
                }
            });
        },
        [account, navigate]
    );

    const onUnauthenticated = useCallback(() => {
        setShowLoginPrompt(!showUnauthorizedPrompt);

        if (authRouteMatch) {
            navigate('/', { replace: true });
        } else if (notAuthorizedMatch) {
            return;
        } else {
            storageService.setItem(WebStorageKeys.AuthRedirectUrl, location);
        }
    }, [authRouteMatch, navigate, location, notAuthorizedMatch, showUnauthorizedPrompt]);

    useAuthCallback({
        authTokenService,
        accessToken,
        configService,
        authService,
        storageService,
        onAuthenticated,
        onUnauthenticated,
    });

    useEffect(() => {
        storageService.getItem(WebStorageKeys.AuthRedirectUrl).then((authRedirect) => {
            if (notAuthorizedMatch && !authRedirect) {
                // somone trying to access `/not-authorized` directly
                // without going through the "sign in" prompt first
                navigate('/');
                return;
            }

            setShowUnauthorizedPrompt(!!notAuthorizedMatch && !!authRedirect);
        });
    }, [navigate, notAuthorizedMatch]);

    if (authRouteMatch || (!showLoginPrompt && !showUnauthorizedPrompt)) {
        return null;
    }

    return (
        <div className={classes.page}>
            <div className={classes.card}>
                <figure className={classes.figureContainer}>
                    <img className={classes.subaruLogo} src={subaruLogo} alt="Subaru Logo" />
                    <hr />
                </figure>
                <TechShareLogo className={classes.stsLogo} />

                <div className={`${classes.textContainer} d-flex`}>
                    <Typography variant="lead">
                        {showUnauthorizedPrompt ? (
                            t(
                                'screens:login.notAuthorized',
                                "We're sorry, but it looks like you do not have access to TechShare right now. Please contact the Subaru Helpdesk at 1-800-SOA-STAR (1-800-762-7827) if you think this is incorrect."
                            )
                        ) : (
                            <>
                                {t('screens:login.noteOne', 'TechShare™ is exclusively for ')}
                                <br />
                                {t(
                                    'screens:login.noteTwo',
                                    'Subaru of America, Inc. employees and retailer partner employees. All others are prohibited.'
                                )}
                            </>
                        )}
                    </Typography>
                </div>

                <div className={classes.loginBtn}>
                    <Button
                        title={
                            showUnauthorizedPrompt
                                ? t('screens:login.goBack', 'Go back')
                                : t('buttons:signIn', 'Sign in')
                        }
                        onPress={() => {
                            analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_TO_LOGIN);

                            if (showUnauthorizedPrompt) {
                                navigate('/');
                                return;
                            }

                            let loginUrl =
                                configService.get(Constants.Env.SamlLoginUrl) + '?clientDeviceTypeId=WEB_BROWSER';

                            if (__DEV__) {
                                loginUrl += '&redirectBaseUrl=temporary-hack';
                            }

                            window.open(loginUrl, '_self');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
