import React, { FC, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Button, Typography, CommentInput, LoadingCircle, Icon, Margin, AccountBadge } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { useQmrInfoRequests, useQmrInfoRequestActions } from '@packages/contexts/qmrs';
import { qmrsService } from '@web/services/singletons';
import { Comment } from '@packages/models/api';

const useStyles = createUseStyles({
    infoRequestsHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 30px',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.grayFour}`,
    },
    requests: {
        padding: '34px 30px 46px',
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.grayFour}`,
    },
    noRequests: {
        textAlign: 'center',
        padding: '34px 30px 46px',
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.grayFour}`,
    },
    closedRequestsHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 30px',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.grayFour}`,
    },
    request: {
        padding: 15,
        borderBottom: `1px solid ${colors.grayFour}`,
    },
    requestComment: {
        marginBottom: 24,
        padding: '0 15px',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    requestClosedOuter: {
        padding: 20,
        borderRadius: 4,
        backgroundColor: colors.grayTwo,
        border: `1px solid ${colors.grayFour}`,
    },
    commentContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
        padding: '0 15px',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export const QmrInfoRequests: FC = () => {
    const [isOpeningNewRequest, setIsOpeningNewRequest] = useState(false); // true after clicking open new request to show initial comment input
    const [comment, setComment] = useState('');
    const [showCloseModal, setShowCloseModal] = useState(false);

    const classes = useStyles();

    const { openRequest, closedRequests, query } = useQmrInfoRequests({ qmrsService });
    const {
        closeInfoRequestMutation,
        openInfoRequestMutation,
        addCommentOnInfoRequestMutation,
        canOpenCloseInfoRequest,
    } = useQmrInfoRequestActions({ qmrsService, onSuccess: query.refetch });

    // #region CALLBACKS
    const onAddComment = useCallback(
        async ({ text, requestId }: { text: string; requestId?: string }) => {
            if (isOpeningNewRequest) {
                // opening new info request auto adds first comment
                await openInfoRequestMutation.mutateAsync({ request: text });
                setIsOpeningNewRequest(false);
            } else {
                if (!requestId) {
                    alert('Error: There is no associated id for Info Request.');
                    return;
                }

                await addCommentOnInfoRequestMutation.mutateAsync({ comment: text, requestId });
            }
        },
        [addCommentOnInfoRequestMutation, isOpeningNewRequest, openInfoRequestMutation]
    );

    const onCancelComment = useCallback(() => {
        if (isOpeningNewRequest) {
            setIsOpeningNewRequest(false);
        }
    }, [isOpeningNewRequest]);

    const onCloseInfoRequest = useCallback(async () => {
        if (!openRequest) {
            return;
        }

        await closeInfoRequestMutation.mutateAsync({ requestId: openRequest.qmrMoreInfoRequestId });
        setShowCloseModal(false);
    }, [closeInfoRequestMutation, openRequest]);
    // #endregion CALLBACKS

    // #region RENDER FNS
    const renderCommentInput = () => {
        if (!canOpenCloseInfoRequest) {
            return null;
        }

        return (
            <CommentInput
                comment={comment}
                onChange={setComment}
                isLoading={addCommentOnInfoRequestMutation.isLoading || openInfoRequestMutation.isLoading}
                onAddComment={({ text }) => onAddComment({ text, requestId: openRequest?.qmrMoreInfoRequestId })}
                onCancel={onCancelComment}
            />
        );
    };

    const renderComments = ({ comments }: { comments: Comment[] }) => {
        return (
            <>
                {comments.map((comment) => {
                    return (
                        <div key={comment.commentId} className={classes.commentContainer}>
                            <AccountBadge account={comment.createdAccount} />

                            <Margin right={10} />

                            <div>
                                <div>
                                    <Typography variant="labelRegular" color="blueOne">
                                        {comment.createdAccount.name}
                                    </Typography>
                                </div>
                                <div className="mb-2">
                                    <Typography variant="caption" color="graySix">
                                        {comment.createdTimestampDescription}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="labelRegular">{comment.body}</Typography>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const renderCloseRequestModal = () => {
        return (
            <Modal show={showCloseModal} centered={true} size="lg" onHide={() => setShowCloseModal(false)}>
                <Modal.Header closeButton placeholder={''}>
                    <Modal.Title>Close Info/Part Request</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex align-items-center">
                        <Icon name="warning" color="black" style={{ marginRight: 5 }} />
                        <Typography style={{ fontWeight: 'bold' }}>
                            Are you sure you want to close this info/part request?
                        </Typography>
                    </div>
                    <br />
                    <Typography>
                        Closed requests become archived and cannot be reopened. You will need to open a new info/part
                        request to make further comments.
                    </Typography>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="ghost-gray" onPress={() => setShowCloseModal(false)} style={{ marginRight: 5 }}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onPress={onCloseInfoRequest}
                        isLoading={closeInfoRequestMutation.isLoading}
                    >
                        Close Request
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    // #endregion RENDER FNS

    if (query.isLoading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <LoadingCircle size={50} />
            </div>
        );
    }

    return (
        <>
            {canOpenCloseInfoRequest && renderCloseRequestModal()}

            {/* Open Requests UI */}
            <div className={classes.infoRequestsHeader}>
                <Typography variant="caption2">Open Request</Typography>

                {openRequest && canOpenCloseInfoRequest && (
                    <Button
                        variant="danger"
                        size="small"
                        isLoading={closeInfoRequestMutation.isLoading}
                        onPress={() => {
                            setShowCloseModal(true);
                        }}
                    >
                        Close Request
                    </Button>
                )}
            </div>

            {openRequest ? (
                <div className={classes.requests}>
                    {renderComments({ comments: openRequest.comments })}
                    {renderCommentInput()}
                </div>
            ) : (
                // UI to open a new info request
                <div className={classes.noRequests}>
                    <div className="mb-5">
                        <Typography variant="lead" color="graySix">
                            {isOpeningNewRequest
                                ? 'Start info/part request with an initial comment'
                                : 'There are no open info/part requests'}
                        </Typography>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        {!isOpeningNewRequest && canOpenCloseInfoRequest && (
                            <Button
                                onPress={() => setIsOpeningNewRequest(true)}
                                iconRight={
                                    <Icon
                                        size={16}
                                        raised
                                        name="plus"
                                        raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                                    />
                                }
                            >
                                Open a new info/part request
                            </Button>
                        )}

                        {isOpeningNewRequest && renderCommentInput()}
                    </div>
                </div>
            )}

            {/* Closed Requests UI */}
            <div className={classes.closedRequestsHeader}>
                <Typography variant="caption2">Closed Requests</Typography>
                <Typography variant="caption2" color="graySix">
                    {closedRequests.length} Closed
                </Typography>
            </div>

            {closedRequests.map((closedRequest) => {
                return (
                    <details open key={closedRequest.qmrMoreInfoRequestId} className={classes.request}>
                        {/* The first comment is a toggle to close all other comments */}
                        <summary>{renderComments({ comments: closedRequest.comments.slice(0, 1) })}</summary>

                        <Margin bottom={24} />

                        {renderComments({ comments: closedRequest.comments.slice(1) })}

                        <div className={classes.requestClosedOuter}>
                            <Typography variant="labelRegular">
                                <Typography variant="label">{closedRequest.closedBy.name}</Typography> closed this
                                request
                            </Typography>
                            <br />
                            <Typography>{closedRequest.closedAtDescription}</Typography>
                        </div>
                    </details>
                );
            })}
        </>
    );
};
