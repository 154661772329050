import React, { FC, useRef, useState, useLayoutEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';

const useLoaderStyles = createUseStyles({
    loader: {
        width: '100%',
        padding: '64px 0',
        overflow: 'hidden',
        backgroundColor: colors.white,
    },
    carOuter: {
        height: 32,
        position: 'relative',
    },
    car: (animationDuration: number) => ({
        width: 56,
        height: 32,
        position: 'absolute',
        animation: `$drive ${animationDuration}ms linear infinite normal`,
    }),
    loaderText: {
        ...fonts.s,
        marginTop: 16,
        display: 'block',
        textAlign: 'center',
    },
    '@keyframes drive': {
        '0%': {
            left: '0%',
            transform: 'translate3d(-100%, 0, 0)',
        },
        '100%': {
            left: '100%',
            transform: 'translate3d(0%, 0, 0)',
        },
    },
});

type Props = {
    message?: string;
};

const Loader = (props: Props) => {
    const { t } = useTranslation();
    const [message] = useState(props.message || t('text:loading.default'));
    const [animationDuration, setAnimationDuration] = useState<number>(10000);
    const loaderRef = useRef<HTMLDivElement | null>(null);
    const classes = useLoaderStyles(animationDuration);

    useLayoutEffect(() => {
        function determineAnimationDuration() {
            if (!loaderRef.current) {
                return;
            }

            const loaderWidth = loaderRef.current.getBoundingClientRect().width;

            setAnimationDuration(loaderWidth * 8);
        }

        // not getting the right width immediately ¯\_(ツ)_/¯
        setTimeout(() => {
            determineAnimationDuration();
        }, 0);

        window.addEventListener('resize', determineAnimationDuration);

        return () => {
            window.removeEventListener('resize', determineAnimationDuration);
        };
    }, []);

    return (
        <div ref={loaderRef} className={classes.loader}>
            <div className={classes.carOuter}>
                <div className={classes.car}>
                    <img src="/static/icons/icon-loader-car.svg" alt="" />
                </div>
            </div>
            <span className={classes.loaderText}>{message}...</span>
        </div>
    );
};

export default Loader;
