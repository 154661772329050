import React, { FC } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, KeyboardAvoidingView, Platform, ModalProps } from 'react-native';
import Modal from './modal';

import colors from '@packages/core/styles/colors';

export interface PopupProps extends Partial<ModalProps> {
    contentStyle?: StyleProp<ViewStyle>;
    show?: boolean;
    onModalHide?: () => void;
}

export const Popup: FC<PopupProps> = ({ children, contentStyle, onModalHide, show }) => {
    return (
        <Modal transparent visible={show} onHide={onModalHide}>
            <KeyboardAvoidingView
                {...Platform.select({ web: { pointerEvents: 'none' } })}
                behavior="padding"
                style={styles.wrapper}
            >
                <View pointerEvents="auto" style={[styles.content, contentStyle]}>
                    {children}
                </View>
            </KeyboardAvoidingView>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modal: {
        margin: 0,
    },
    wrapper: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        borderRadius: 4,
        marginHorizontal: 20,
        backgroundColor: colors.white,
        shadowOffset: {
            height: 0,
            width: 8,
        },
        maxWidth: Platform.select({ web: 640 }),
        shadowRadius: 12,
        shadowColor: colors.black,
        shadowOpacity: 0.16,
    },
});

export const modalDefaultStyles = StyleSheet.create({
    header: {
        marginTop: 20,
        marginBottom: 24,
        marginHorizontal: 20,
        minHeight: 40 + 16,
        justifyContent: 'center',
        paddingBottom: 16,
        borderBottomWidth: 1,
        borderBottomColor: colors.grayThree,
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    headerIcon: {
        marginRight: 20,
    },
    headerImage: {
        marginTop: 40,
        marginBottom: 20,
        alignSelf: 'center',
    },
    content: {
        marginHorizontal: 20,
        marginBottom: 32,
    },
    footer: {
        marginBottom: 32,
        marginHorizontal: 20,
    },
    webHeader: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        borderBottomWidth: 1,
        borderBottomColor: colors.grayThree,
        backgroundColor: colors.grayOne,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    },
    webContent: {
        paddingVertical: 30,
        paddingHorizontal: 30,
        flexDirection: 'row',
    },
    webContentIcon: {
        marginRight: 16,
    },
    webFooter: {
        paddingVertical: 12,
        paddingHorizontal: 30,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderTopWidth: 1,
        borderTopColor: colors.grayThree,
    },
    webCancelButton: {
        marginRight: 8,
    },
});
