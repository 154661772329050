import React, { useState } from 'react';
import { Button, Icon, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { FailCodesModal } from './fail-codes-modal';
import { caseStatusMap as statusMap } from '@web/utils';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { analyticsService } from '@web/services/singletons';

const useStyles = createUseStyles({
    failCodeDescContainer: {
        border: `2px solid ${colors.grayTwo}`,
        borderRadius: 5,
    },
    flexOne: {
        flex: 1,
    },
    failCodeDescTrashIconContainer: {
        flex: 0.1,
    },
});

export const FailCode = ({
    vehicleDetails,
    updateCaseDetails,
    caseDetails,
    issueFeildFailCode,
}: {
    vehicleDetails: any;
    updateCaseDetails?: any;
    caseDetails: any;
    issueFeildFailCode?: string;
}) => {
    const classes = useStyles();
    const [showFailCodeSearchModal, setShowFailCodeSearchModal] = useState(false);

    const renderFailCodeDesc = () => {
        if (!caseDetails?.repairCode) return null;
        return (
            <div className={`d-flex flex-column ${classes.failCodeDescContainer}`}>
                <div style={{ backgroundColor: colors.grayOne }} className="d-flex flex-row align-items-center p-5">
                    <div className={`${classes.flexOne}`}>
                        <Typography variant="h6">Fail code & description</Typography>
                    </div>
                    <div className={`${classes.flexOne}`}>
                        <Typography variant="h6">Section & subsection</Typography>
                    </div>
                    <div className={`${classes.failCodeDescTrashIconContainer}`}></div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between p-5">
                    <div className={`${classes.flexOne} d-flex flex-column`}>
                        <Typography variant="h6" style={{ fontWeight: '400' }}>
                            {caseDetails.repairCode}
                        </Typography>
                        <Typography variant="caption">{caseDetails.repairCode}</Typography>
                    </div>
                    <div className={`${classes.flexOne} d-flex flex-column`}>
                        <Typography variant="h6" style={{ fontWeight: '400' }}>
                            {caseDetails.section}
                        </Typography>
                        <Typography variant="caption">{caseDetails.subSection}</Typography>
                    </div>
                    <div
                        className={`${classes.failCodeDescTrashIconContainer} align-items-center justify-content-center`}
                    >
                        {statusMap['DRAFT'].includes(caseDetails?.techlineStatusCode) && (
                            <Icon
                                onPress={() => {
                                    updateCaseDetails({
                                        section: '',
                                        subSection: '',
                                        repairCode: '',
                                    });
                                }}
                                size={24}
                                name="trash"
                                color={'blueOne'}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="mt-5">
            <div className="d-flex flex-row align-items-center justify-content-between">
                <Typography variant="h3">
                    Fail Code
                    {statusMap['DRAFT'].includes(caseDetails?.techlineStatusCode) && (
                        <Typography color="redOne"> *</Typography>
                    )}
                </Typography>
                {statusMap['DRAFT'].includes(caseDetails?.techlineStatusCode) && (
                    <Button
                        variant="primary"
                        disabled={false}
                        onPress={() => {
                            analyticsService.logEvent(ANALYTICS_EVENTS.USER_STARTS_ADDING_FAILCODE);
                            setShowFailCodeSearchModal(true);
                        }}
                        title={'Add fail code'}
                    />
                )}
            </div>
            {caseDetails?.repairCode && <hr className="mt-5 mb-5" color={colors.grayThree} />}
            {renderFailCodeDesc()}
            <hr className="mt-5 mb-5" color={colors.grayThree} />
            {/* {renderCaseTypes()} */}
            <FailCodesModal
                updateCaseDetails={(params: any) => {
                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_ADDS_FAILCODE);
                    updateCaseDetails(params);
                }}
                show={showFailCodeSearchModal}
                onHide={() => setShowFailCodeSearchModal(false)}
                vehicleDetails={vehicleDetails}
            />
        </div>
    );
};
