import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Typography } from '../../shared';
import { Button } from '../../shared/button';
import { modalDefaultStyles, Popup, PopupProps } from '../../shared/popup';

interface MeetingNotesPopupProps extends PopupProps {
    onOkPress: () => void;
}

export const MeetingNotesPopup: FC<MeetingNotesPopupProps> = ({ onOkPress, ...props }) => {
    const { t } = useTranslation();

    return (
        <Popup {...props}>
            <View style={modalDefaultStyles.header}>
                <Typography variant="h4">{t('investigations:popups.meetingNotes.title', 'Meeting Notes')}</Typography>
            </View>

            <View style={modalDefaultStyles.content}>
                <Typography variant="lead">
                    {t(
                        'investigations:popups.meetingNotes.message',
                        'Add meeting notes to keep track of what topics were brought up at different quality meetings and their outcomes.'
                    )}
                </Typography>
            </View>

            <View style={modalDefaultStyles.footer}>
                <Button title={t('investigations:popups.meetingNotes.ok', 'Got it')} onPress={onOkPress} />
            </View>
        </Popup>
    );
};
