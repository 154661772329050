import React, { FC, useState, useCallback, useEffect } from 'react';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useAbortController } from '@packages/core/http';
import { Vehicle, PatchQmrDto, Qmr } from '@packages/models/api';
import { useQmrState, patchQmr, useQmrDispatch } from '@packages/contexts/qmrs';

import { qmrsService } from '@web/services/singletons';
import PossibleVehicleCard from '@web/components/possible-vehicle-card';
import { TextInputHelper, Icon, Label, Button, Typography } from '@packages/ui/shared';
import Select, { SelectOption } from './select';

type EditVehicleModalProps = {
    show: boolean;
    onHide: () => void;
};

const EditVehicleModal: FC<EditVehicleModalProps> = (props) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');

    const [isUpdating, setIsUpdating] = useState(false);
    const [didCheckVin, setDidCheckVin] = useState(!!qmr?.vin);
    const [checkingVin, setCheckingVin] = useState(false);
    const [vinError, setVinError] = useState('');
    const [showContinueWithoutVinWarning, setShowContinueWithoutVinWarning] = useState(false);
    const [possibleVehicles, setPossibleVehicles] = useState<Vehicle[]>([]);

    const [carlineOptions, setCarlineOptions] = useState<SelectOption[]>([]);

    const [vinInputValue, setVinInputValue] = useState(qmr?.vin || '');
    const [selectedCarlineId, setSelectedCarlineId] = useState(qmr?.carlineId || '');
    const [modelYearInputValue, setModelYearInputValue] = useState<number | undefined>(qmr?.modelYear);
    const [transmissionNumberInputValue, setTransmissionNumberInputValue] = useState(qmr?.transmissionNumber);
    const [engineNumberInputValue, setEngineNumberInputValue] = useState(qmr?.engineNumber);
    const [colorInputValue, setColorInputValue] = useState(qmr?.colorCode);
    const [modelInputValue, setModelInputValue] = useState(qmr?.modelCode);
    const [modelOptionInputValue, setModelOptionInputValue] = useState(qmr?.optionCode);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();

    const updateVehicleDetails = () => {
        if (!qmr) {
            return;
        }

        setIsUpdating(true);

        const qmrPatch: PatchQmrDto = Object.fromEntries(
            Object.entries({
                ...selectedVehicle,
                vin: vinInputValue,
                modelYear: modelYearInputValue,
                transmissionNumber: transmissionNumberInputValue,
                engineNumber: engineNumberInputValue,
                carlineId: selectedCarlineId || null,
            }).filter((entry) => {
                const key = entry[0] as keyof Qmr;
                const value = entry[1];
                const qmrValue = qmr[key];

                if (typeof value !== 'number' && !value && !qmrValue) {
                    return false;
                }

                return qmrValue !== value;
            })
        );

        if (Object.keys(qmrPatch).length === 0) {
            // no changes
            setIsUpdating(false);
            props.onHide();
            setShowContinueWithoutVinWarning(false);
            return;
        }

        patchQmr({
            qmrId: qmr.qmrId,
            qmrsService,
            qmrDispatch,
            signal: abortSignalRef.current,
            qmrPatch,
            isLocalPatch: !!editRouteMatch,
        })
            .then((updatedQmr) => {
                if (!editRouteMatch && !updatedQmr) {
                    return;
                }

                setIsUpdating(false);
                props.onHide();
                setShowContinueWithoutVinWarning(false);
            })
            .catch((e) => {
                setIsUpdating(false);
                alert(e.message);
            });
    };

    const fetchCarlines = useCallback(async () => {
        return qmrsService
            .fetchCarlines({
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setCarlineOptions([
                    { title: t('qmr:inputs.carline.placeholder'), value: '' },
                    ...response.data.carlines.map((c) => ({
                        title: c.name,
                        value: c.carlineId,
                    })),
                ]);
            });
    }, [abortSignalRef, t]);

    useEffect(() => {
        if (props.show) {
            fetchCarlines();
        }
    }, [fetchCarlines, props.show]);

    // reset inputs on dismiss
    useEffect(() => {
        if (!props.show && qmr) {
            setShowContinueWithoutVinWarning(false);
            setSelectedVehicle(undefined);
            setPossibleVehicles([]);
            setVinError('');

            setVinInputValue(qmr.vin || '');
            setSelectedCarlineId(qmr.carlineId);
            setModelYearInputValue(qmr.modelYear || undefined);
            setTransmissionNumberInputValue(qmr.transmissionNumber || '');
            setEngineNumberInputValue(qmr.engineNumber || '');
        }
    }, [props.show, qmr]);

    function handleCheckVinButtonClick() {
        if (checkingVin) return;

        setVinError('');
        setCheckingVin(true);
        setPossibleVehicles([]);

        qmrsService
            .checkVin({
                vin: vinInputValue,
                signal: abortSignalRef.current,
                ignoreCache: true,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const vehicles = response.data.vehicles;

                setCheckingVin(false);
                setPossibleVehicles(vehicles);
                if (vehicles.length === 0) {
                    setVinError(t('qmr:inputs.vin.errors.noMatch', 'No matches found'));
                } else if (vehicles.length === 1) {
                    handleSelectVehicleClick(vehicles[0]);
                }
                setDidCheckVin(true);
            })
            .catch((e) => {
                setCheckingVin(false);
                setVinError(e.message);
            });
    }

    function handleSelectVehicleClick(vehicle: Vehicle) {
        setSelectedVehicle(vehicle);
        setPossibleVehicles([]);

        setVinInputValue(vehicle.vin);
        setModelYearInputValue(vehicle.modelYear);
        setSelectedCarlineId(vehicle.carlineId);
        setTransmissionNumberInputValue(vehicle.transmissionNumber || '');
        setEngineNumberInputValue(vehicle.engineNumber || '');
        setColorInputValue(vehicle.colorCode);
        setModelInputValue(vehicle.modelCode);
        setModelOptionInputValue(vehicle.optionCode);
    }

    function getCreationForm() {
        return (
            <>
                <Row>
                    <Col xs={12}>
                        <TextInputHelper
                            controlId="vin"
                            maxLength={17}
                            value={vinInputValue}
                            onChangeText={(text) => {
                                const vinVal = text.toUpperCase();
                                setVinInputValue(vinVal);
                                setDidCheckVin(vinVal === qmr?.vin);
                            }}
                            autoCapitalize="characters"
                            label={t('qmr:inputs.vin.label')}
                            labelHint={t('qmr:inputs.vin.hint', 'VIN should be either 8 or 17 characters long')}
                            placeholder={t('qmr:inputs.vin.placeholder')}
                            showCharCount
                            rightIcon={({ inputState }) => {
                                const canCheckVin =
                                    !didCheckVin && (vinInputValue.length === 8 || vinInputValue.length === 17);

                                const disableCheckButton =
                                    inputState.isDisabled ||
                                    !canCheckVin ||
                                    checkingVin ||
                                    (!!editRouteMatch && !qmr?.capabilities.editVinOnQmr);

                                return (
                                    <Button
                                        variant="primary"
                                        style={{
                                            height: inputState.hasError || inputState.isFocused ? 36 : 38,
                                            marginRight: -12,
                                        }}
                                        disabled={disableCheckButton}
                                        onPress={handleCheckVinButtonClick}
                                    >
                                        {checkingVin
                                            ? `${t('text:loading.checking')}...` // TODO: Loading spinner instead of text
                                            : t('modals:editVehicle.actions.checkVin')}
                                    </Button>
                                );
                            }}
                            editable={!!editRouteMatch ? qmr?.capabilities.editVinOnQmr && !checkingVin : !checkingVin}
                            errorMessage={vinError}
                        />
                    </Col>
                </Row>

                <hr className="my-5" />

                {possibleVehicles.length > 0 ? (
                    <>
                        <Row>
                            <Col xs={12}>
                                <p>{t('modals:editVehicle.multipleVinMatches', { count: possibleVehicles.length })}</p>
                                <p className="mb-5">
                                    {t('modals:editVehicle.selectVehicle', 'Please select the correct vehicle for VIN')}{' '}
                                    <span className="bold">{vinInputValue}</span>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            {possibleVehicles.map((possibleVehicle, index) => {
                                return (
                                    <Col xs={6} key={index}>
                                        <PossibleVehicleCard
                                            vehicle={possibleVehicle}
                                            onSelect={() => handleSelectVehicleClick(possibleVehicle)}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                ) : (
                    <>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <h5>{t('modals:editVehicle.details.title', 'Confirm Vehicle Details')}</h5>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col xs={6}>
                                <TextInputHelper
                                    editable={
                                        false
                                        // SD-1389 disables "no vin"
                                        // !!editRouteMatch
                                        //     ? qmr?.capabilities.editModelYearOnQmr && !vinInputValue
                                        //     : !vinInputValue
                                    }
                                    required
                                    controlId="modelYear"
                                    label={t('qmr:inputs.modelYear.label')}
                                    value={modelYearInputValue ? '' + modelYearInputValue : ''}
                                    maxLength={4}
                                    onChangeText={(newVal) => {
                                        setModelYearInputValue(parseInt(newVal, 10));
                                    }}
                                    placeholder={t('qmr:inputs.modelYear.placeholder')}
                                />
                            </Col>
                            <Col xs={6}>
                                <Label required controlId="carline">
                                    {t('qmr:inputs.carline.label')}
                                </Label>
                                <Select
                                    id="carline"
                                    value={selectedCarlineId}
                                    options={carlineOptions}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        setSelectedCarlineId(event.target.value);
                                    }}
                                    disabled={
                                        true
                                        // SD-1389 disables "no vin"
                                        // !!editRouteMatch
                                        //     ? !qmr?.capabilities.editCarlineOnQmr && !!vinInputValue
                                        //     : !!vinInputValue
                                    }
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col xs={6}>
                                <TextInputHelper
                                    editable={!!editRouteMatch ? qmr?.capabilities.editEngineNumberOnQmr : true}
                                    controlId="engineNumber"
                                    label={t('qmr:inputs.engineNumber.label')}
                                    value={engineNumberInputValue}
                                    onChangeText={setEngineNumberInputValue}
                                    placeholder={t('qmr:inputs.engineNumber.placeholder')}
                                />
                            </Col>
                            <Col xs={6}>
                                <TextInputHelper
                                    editable={!!editRouteMatch ? qmr?.capabilities.editTransmissionNumberOnQmr : true}
                                    controlId="transmissionNumber"
                                    label={t('qmr:inputs.transmissionNumber.label')}
                                    value={transmissionNumberInputValue}
                                    onChangeText={setTransmissionNumberInputValue}
                                    placeholder={t('qmr:inputs.transmissionNumber.placeholder')}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col xs={4}>
                                <TextInputHelper
                                    editable={!!editRouteMatch ? qmr?.capabilities.editColorOnQmr : false}
                                    label={t('qmr:inputs.color.label')}
                                    value={colorInputValue}
                                    onChangeText={setColorInputValue}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextInputHelper
                                    editable={!!editRouteMatch ? qmr?.capabilities.editModelOnQmr : false}
                                    label={t('qmr:inputs.model.label')}
                                    value={modelInputValue}
                                    onChangeText={setModelInputValue}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextInputHelper
                                    editable={!!editRouteMatch ? qmr?.capabilities.editModelOptionOnQmr : false}
                                    label={t('qmr:inputs.modelOptions.label')}
                                    value={modelOptionInputValue}
                                    onChangeText={setModelOptionInputValue}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    }

    return (
        <Modal
            show={props.show}
            centered={true}
            onHide={() => {
                props.onHide();
            }}
            size="lg"
        >
            <Modal.Header placeholder={''}>
                <Modal.Title>
                    {showContinueWithoutVinWarning
                        ? t('modals:editVehicle.noVinWarning.title', 'Remove VIN?')
                        : t('modals:editVehicle.title', 'Vehicle Information')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {showContinueWithoutVinWarning ? (
                    <div className="d-flex">
                        <Icon name="warning" />

                        <div className="d-flex flex-column ml-2">
                            <div className="mb-2">
                                <Typography variant="h5">
                                    {t(
                                        'modals:editVehicle.noVinWarning.confirmation',
                                        'Are you sure you want to remove the VIN?'
                                    )}
                                </Typography>
                            </div>

                            <Typography>
                                {t(
                                    'modals:editVehicle.noVinWarning.message',
                                    'The VIN is required on QMRs that are for a specific vehicle. If you are creating a QMR for all vehicles within a carline, then you may continue without the VIN.'
                                )}
                            </Typography>
                        </div>
                    </div>
                ) : (
                    <Form>{getCreationForm()}</Form>
                )}
            </Modal.Body>

            <Modal.Footer>
                {showContinueWithoutVinWarning ? (
                    <>
                        <div className="mr-2">
                            <Button
                                variant="primary"
                                onPress={() => {
                                    setShowContinueWithoutVinWarning(false);
                                }}
                            >
                                {t('modals:editVehicle.actions.enterVin', 'Go Back & Enter VIN now')}
                            </Button>
                        </div>

                        <Button
                            variant="secondary"
                            onPress={() => {
                                updateVehicleDetails();
                            }}
                        >
                            {t('modals:editVehicle.actions.saveNoVin')}
                        </Button>
                    </>
                ) : (
                    <>
                        <div className="mr-2">
                            <Button
                                variant="ghost-blue"
                                onPress={() => {
                                    props.onHide();
                                }}
                            >
                                {t('buttons:cancel')}
                            </Button>
                        </div>

                        <Button
                            variant="primary"
                            isLoading={isUpdating}
                            disabled={
                                possibleVehicles.length > 0 ||
                                vinInputValue.length < 17 ||
                                !didCheckVin ||
                                String(modelYearInputValue).length < 4
                            }
                            onPress={() => {
                                if (isUpdating || possibleVehicles.length) {
                                    return;
                                }

                                if (qmr && qmr.vin && !vinInputValue) {
                                    setShowContinueWithoutVinWarning(true);
                                    return;
                                }

                                updateVehicleDetails();
                            }}
                        >
                            {t('modals:editVehicle.actions.update', 'Update')}
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default EditVehicleModal;
