import React, { FC, createContext, useState, useCallback } from 'react';
import { EscalationModel, Qmr } from '@packages/models/api';

interface EscalationModalState {
    show: boolean;
    selectedQmrs: Qmr[];
    onSuccess?: (escalations: EscalationModel[]) => void;
}

interface EscalationModalContextConfig {
    show: boolean;
    selectedQmrs: Qmr[];
    onSuccess?: (escalations: EscalationModel[]) => void;
    showModal: (newState: Partial<EscalationModalState>) => void;
    hideModal: () => void;
}

export const EscalationModalContext = createContext({} as EscalationModalContextConfig);

export const EscalationModalProvider: FC = ({ children }) => {
    const [state, setState] = useState<EscalationModalState>({
        show: false,
        selectedQmrs: [],
    });

    const showModal = useCallback((newState: Partial<EscalationModalState>) => {
        setState({
            show: true,
            selectedQmrs: [],
            ...newState,
        });
    }, []);

    const hideModal = useCallback(() => {
        setState({
            show: false,
            selectedQmrs: [],
        });
    }, []);

    return (
        <EscalationModalContext.Provider
            value={{
                showModal,
                hideModal,
                ...state,
            }}
        >
            {children}
        </EscalationModalContext.Provider>
    );
};
