import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { Icon } from '@packages/ui/shared';

import colors from '@packages/core/styles/colors';

export enum SORT_DIRECTION {
    ASC = 'ASC',
    DESC = 'DESC',
}

const useTableHeaderStyles = createUseStyles({
    tableHeader: (props: any) => ({
        top: 0,
        zIndex: 1,
        padding: 0,
        position: 'sticky',
        width: props.width ? props.width : 'auto',
        '&:last-child': {
            '& > div': {
                borderRight: 0,
            },
        },
        '&.fixed-column': {
            zIndex: 2,
            left: props.fixedOffset ? props.fixedOffset : 0,
        },
    }),
    tableHeaderContent: (props: any) => ({
        height: 58,
        display: 'flex',
        padding: '0 16px',
        overflow: 'hidden',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
        width: typeof props.width === 'string' ? 'auto' : props.width,
        borderRight: `1px solid ${props.hideBorder ? 'transparent' : colors.grayThree}`,
        ...(props.sortable
            ? {
                  cursor: 'pointer',
                  '&:hover': {
                      backgroundColor: colors.grayTwo,
                  },
                  '&:active': {
                      backgroundColor: colors.grayThree,
                  },
              }
            : {}),
    }),
    sortableIcon: {
        display: 'block',
    },
    sortIconOuter: {
        width: 16,
        height: 16,
        marginLeft: 8,
    },
});

interface TableHeaderProps {
    width?: number | string;
    fixed?: boolean;
    fixedOffset?: number;
    sortable?: boolean;
    sortDirection?: SORT_DIRECTION | null;
    hideBorder?: boolean;
    onSort?(event: React.MouseEvent<HTMLDivElement>, sortDirection: SORT_DIRECTION): void;
    colspan?: number;
    className?: string;
    children?: React.ReactNode;
}

export const TableHeader: FC<TableHeaderProps> = React.memo((props) => {
    const classes = useTableHeaderStyles({
        width: props.width,
        fixedOffset: props.fixedOffset,
        sortable: props.sortable,
        hideBorder: props.hideBorder,
    });

    function handleSortButtonClick(event: React.MouseEvent<HTMLDivElement>) {
        if (!props.onSort) {
            return;
        }

        if (props.sortDirection === SORT_DIRECTION.ASC) {
            props.onSort(event, SORT_DIRECTION.DESC);
        } else {
            props.onSort(event, SORT_DIRECTION.ASC);
        }
    }

    return (
        <th
            className={classNames({
                [classes.tableHeader]: true,
                'fixed-column': props.fixed,
            })}
            colSpan={props.colspan}
        >
            <div
                className={classNames(classes.tableHeaderContent, props.className)}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    if (!props.sortable) {
                        return;
                    }

                    handleSortButtonClick(event);
                }}
                tabIndex={props.sortable ? 0 : -1}
            >
                {props.children}
                {props.sortable && (
                    <div className={classes.sortIconOuter}>
                        {props.sortDirection === SORT_DIRECTION.ASC && (
                            <Icon size={16} name="chevron-down" style={{ display: 'flex' }} />
                        )}
                        {props.sortDirection === SORT_DIRECTION.DESC && (
                            <Icon size={16} name="chevron-up" style={{ display: 'flex' }} />
                        )}
                    </div>
                )}
            </div>
        </th>
    );
});

TableHeader.displayName = 'TableHeader';
