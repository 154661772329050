import { useAuthState } from '@packages/contexts/auth';
import { ADD_ON_ROLE_ID } from '@packages/models/api';
import { useMemo } from 'react';

const useWorksheetPermission = () => {
    const { account } = useAuthState();
    const isPilotUser = useMemo(() => {
        return account?.addOnRoles?.some((role) => role?.roleId === ADD_ON_ROLE_ID.PILOT_USER);
    }, [account]);

    const permissions = useMemo(() => {
        const worksheetPermission = {
            canViewWorkSheetsTechlineCase: false,
            canEditWorkSheetsTechlineCase: false,
            canDeleteWorkSheetsTechlineCase: false,
            canCreateWorkSheetsTechlineCase: false,
        };
        if (account) {
            const {
                techlineCapabilities: {
                    canViewWorkSheetsTechlineCase,
                    canEditWorkSheetsTechlineCase,
                    canDeleteWorkSheetsTechlineCase,
                    canCreateWorkSheetsTechlineCase,
                },
            } = account;
            worksheetPermission.canViewWorkSheetsTechlineCase = canViewWorkSheetsTechlineCase;
            worksheetPermission.canEditWorkSheetsTechlineCase = canEditWorkSheetsTechlineCase;
            worksheetPermission.canDeleteWorkSheetsTechlineCase = canDeleteWorkSheetsTechlineCase;
            worksheetPermission.canCreateWorkSheetsTechlineCase = canCreateWorkSheetsTechlineCase;
        }
        return worksheetPermission;
    }, [account]);

    const isReadonlyPermission = useMemo(() => {
        return permissions.canViewWorkSheetsTechlineCase;
    }, [permissions]);

    const isEditOnlyPermission = useMemo(() => {
        return permissions.canEditWorkSheetsTechlineCase;
    }, [permissions]);

    const isDeleteOnlyPermission = useMemo(() => {
        return permissions.canDeleteWorkSheetsTechlineCase;
    }, [permissions]);

    const isCreateOnlyPermission = useMemo(() => {
        return permissions.canCreateWorkSheetsTechlineCase;
    }, [permissions]);

    return {
        isPilotUser,
        permissions,
        isReadonlyPermission,
        isEditOnlyPermission,
        isDeleteOnlyPermission,
        isCreateOnlyPermission,
    };
};

export default useWorksheetPermission;
