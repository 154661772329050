import * as React from 'react';
import {
    IFormElementState,
    FormElementActionDispatcher,
    initialState,
    formElementActions,
    formElementReducer,
    baseFormElement,
} from './form-element-modal.state';
import { FormElement } from '@packages/models/api';

export const FormElementModalDispatchContext = React.createContext<FormElementActionDispatcher | undefined>(undefined);

export const FormElementModalContext = React.createContext<IFormElementState>({
    formElement: baseFormElement,
} as unknown as IFormElementState);

export const FormElementModalProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const [state, dispatch] = React.useReducer(formElementReducer, initialState);
    return (
        <FormElementModalContext.Provider value={state}>
            <FormElementModalDispatchContext.Provider value={dispatch}>
                {children}
            </FormElementModalDispatchContext.Provider>
        </FormElementModalContext.Provider>
    );
};

export function saveFormElement({
    formElement,
    formElementDispatch,
}: {
    formElement: Partial<FormElement>;
    formElementDispatch: FormElementActionDispatcher;
}) {
    if (formElement) {
        formElementDispatch(formElementActions.setFormElement({ formElement: formElement as FormElement }));
    }
}
export function updateFormElement({
    formElement,
    formElementDispatch,
}: {
    formElement: Partial<FormElement>;
    formElementDispatch: FormElementActionDispatcher;
}) {
    if (formElement) {
        formElementDispatch(formElementActions.updateFormElement({ formElement: formElement as FormElement }));
    }
}
