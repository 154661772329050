import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import { Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

const useDetailViewHeaderStyles = createUseStyles({
    header: ({ hasBreadcrumbs }: { hasBreadcrumbs: boolean }) => ({
        flexShrink: 0,
        display: 'flex',
        padding: '0 30px',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: colors.white,
        height: hasBreadcrumbs ? 100 : 80,
        borderBottom: `1px solid ${colors.grayThree}`,
    }),
});

interface BreadcrumbModel {
    to: string;
    title: string;
}

interface DetailViewHeaderProps {
    breadcrumbs?: BreadcrumbModel[];
    children?: React.ReactNode;
}

export const DetailViewHeader: FC<DetailViewHeaderProps> = ({ breadcrumbs, children }) => {
    const classes = useDetailViewHeaderStyles({
        hasBreadcrumbs: !!(breadcrumbs && breadcrumbs.length > 0),
    });

    return (
        <div className={classes.header}>
            {breadcrumbs && (
                <div className="mb-1 w-100">
                    {breadcrumbs.map((breadcrumb, index) => {
                        const isLast = index === breadcrumbs.length - 1;

                        if (isLast) {
                            return (
                                <Typography key={index} variant="label">
                                    {breadcrumb.title}
                                </Typography>
                            );
                        } else {
                            return (
                                <span key={index}>
                                    <Typography variant="labelRegular">
                                        <Link to={breadcrumb.to}>{breadcrumb.title}</Link>
                                    </Typography>
                                    <Typography variant="labelRegular" color="grayFive">
                                        &nbsp;&nbsp;/&nbsp;&nbsp;
                                    </Typography>
                                </span>
                            );
                        }
                    })}
                </div>
            )}
            <div className="w-100">{children}</div>
        </div>
    );
};
