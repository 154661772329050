import { Retailer } from '@packages/models/api';
import { BadgeVariants, TextBadge, Typography } from '@packages/ui/shared';
import RecentSearchTypeahead from '@web/components/recent-search-typeahead';
import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';
import useQuery from '@web/core/hooks/use-query';
import { qmrsService } from '@web/services/singletons';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const approverVariants: Record<string, BadgeVariants> = {
    QMR_CREATOR: 'blue',
    APPROVAL_OPTIONAL: 'yellow',
    APPROVAL_REQUIRED: 'red',
};

interface RetailerRowModel extends Retailer {
    rowId: string;
}

export const ManageRetailers: FC = () => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const { t } = useTranslation();
    const queryParams = useQuery();

    const searchInputRef = useRef(null);
    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const quickSearchQuery = queryParams.get('query') || '';

    const [isLoading, setIsLoading] = useState(false);
    const [retailers, setRetailers] = useState<RetailerRowModel[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [totalRetailers, setTotalRetailers] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            const response = await qmrsService.searchRetailers({
                searchParams: {
                    page: pageIndex,
                    size: pageSize,
                    query: quickSearchQuery,
                },
                ignoreCache: true,
            });

            if (response.success) {
                setTotalRetailers(response.data.totalCount);
                setRetailers(response.data.results.map((r) => ({ ...r, rowId: r.retailerId })));
            } else {
                throw response.data;
            }

            setIsLoading(false);
        }

        fetchData();
    }, [pageIndex, pageSize, quickSearchQuery]);

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));
            setPageIndex(index);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));
            setPageSize(size);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const RecentSearchTypeaheadChange = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));

            if (text) {
                params.set('query', text);

                if (!recentSearches.includes(text)) {
                    setRecentSearches([text, ...recentSearches]);
                }
            } else {
                params.delete('query');
            }

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, recentSearches, search]
    );

    return (
        <>
            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <div className="mb-6 d-flex justify-content-between">
                        <Typography variant="h2">Retailers</Typography>
                    </div>
                    <div className="mb-8 d-flex justify-content-between">
                        <div className="w-50">
                            <RecentSearchTypeahead
                                //@ts-ignore TODO: Figure out how to pass refs to forwardRef
                                ref={searchInputRef}
                                onChange={RecentSearchTypeaheadChange}
                                placeholder={t('views:manageRetailers.search.placeholder', 'Enter a name or code')}
                                defaultInputValue={quickSearchQuery ? quickSearchQuery : ''}
                                recentSearches={recentSearches}
                                quickSearchQuery={quickSearchQuery}
                            />
                        </div>
                    </div>

                    <TableRenderer<RetailerRowModel>
                        isLoading={isLoading}
                        tableRowsData={retailers}
                        tableRowsDataSetter={setRetailers}
                        selectAll={selectAll}
                        selectAllSetter={setSelectAll}
                        noResultsIconName="administration"
                        tableHeaderRowRenderer={(selectAllValue, selectAllChangeHandler) => {
                            return (
                                <TableRow>
                                    <TableHeader fixed hideBorder>
                                        <Typography variant="label">Retailer Name</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">Retailer Number</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label" numberOfLines={1}>
                                            Fail Code Approval Setting
                                        </Typography>
                                    </TableHeader>
                                </TableRow>
                            );
                        }}
                        tableBodyRowRenderer={(rowData) => {
                            return (
                                <TableRow key={rowData.rowId} checked={rowData.checked}>
                                    <TableCell>
                                        <Typography>
                                            <Link to={`/administration/retailers/${rowData.retailerId}`}>
                                                {rowData.name}
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>#{rowData.code}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <div className="d-flex">
                                            <TextBadge variant={approverVariants[rowData.failCodeApproverSettingId]}>
                                                {rowData.failCodeApproverSettingDescription}
                                            </TextBadge>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />

                    <TablePagination
                        page={pageIndex}
                        size={pageSize}
                        total={totalRetailers}
                        onClick={handlePaginationClick}
                        onSizeChange={handlePaginationSizeChange}
                    />
                </div>
            </div>
        </>
    );
};
