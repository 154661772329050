import * as React from 'react';
import { VinSessionActionDispatcher, initialState, vinSessionReducer } from './vin-sessions.state';
import { VinSessionState } from '../types';

export const defaultArticles: VinSessionState = initialState;

export const VinSessionContext = React.createContext(defaultArticles);
export const VinSessionDispatchContext = React.createContext<undefined | VinSessionActionDispatcher>(undefined);

export const VinSessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setArticles] = React.useReducer(vinSessionReducer, initialState);

    return (
        <VinSessionContext.Provider value={state}>
            <VinSessionDispatchContext.Provider value={setArticles}>{children}</VinSessionDispatchContext.Provider>
        </VinSessionContext.Provider>
    );
};
