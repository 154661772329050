import * as React from 'react';
import { WorksheetDispatchContext, WorksheetContext } from './worksheets.context';

export const useWorksheetState = () => {
    const state = React.useContext(WorksheetContext);

    if (state === undefined) {
        throw new Error('useWorksheetState must be used within a WorksheetProvider');
    }

    return state;
};

export const useWorksheetDispatch = () => {
    const state = React.useContext(WorksheetDispatchContext);
    if (state === undefined) {
        throw new Error('useWorksheetState must be used within a WorksheetProvider');
    }

    return state;
};
