import {
    FormAnswerSelectedOption,
    FormElementFile,
    SelectedOption,
    WorksheetElementAnswers,
} from '@packages/models/api';

interface Props {
    handleAnswerChange?: (formelementId: string, selectedAnswer: FormAnswerSelectedOption[]) => void;
    onAnswerChange?: (answer: WorksheetElementAnswers) => void;
}

export const useAnswerHandle = ({ handleAnswerChange, onAnswerChange }: Props) => {
    const handleAnswer = (formElementId: string, answer: SelectedOption[], asset?: FormElementFile) => {
        handleAnswerChange?.(formElementId, answer);
        onAnswerChange?.({
            elementId: formElementId,
            selectedOptions: answer,
            asset: { answerFile: { ...asset } },
        });
    };
    return {
        handleAnswer,
    };
};
