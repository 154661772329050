import React, { useCallback, useEffect, useState } from 'react';
import { Button, Icon, TextInputHelper, Typography } from '@packages/ui/shared';
import { WorksheetAddFieldEnum } from '@web/administration/views/worksheets/types';
import { AddFieldType } from './worksheet-form-component-edit-modal';
import { FormElementFile, WorksheetFormElementOption } from '@packages/models/api';
import { ResizableTextarea } from './resizeable-textarea';
import useTranslationData from './hooks/useTranslationData';
import { useValidationField, validationField } from './hooks/useValidationField';
import { FileField } from './file-fields';
import { WorksheetCharacterLengthValidation } from '@web/administration/views/worksheets/utils';
import { MAX_FILE_SIZE } from './inputPickers/util';

interface AddFieldProps {
    onchangeText: ({
        formElementLabel,
        options,
        formElementDescription,
        formElementTooltip,
        formElementFile,
    }: {
        formElementLabel?: string;
        options?: WorksheetFormElementOption[];
        formElementTooltip?: string;
        description?: string;
        formElementDescription?: string;
        formElementFile?: FormElementFile;
    }) => void;
    addFieldValues: AddFieldType;
    formElementTooltip?: string;
    formElementDescription?: string;
    formElementLabel: string;
    errorMessage?: string;
    showQuestionLabel?: boolean;
    formElementFile?: FormElementFile;
    handleRequired: (text: string, fieldName: string | number, errorMessageInit?: string | undefined) => boolean;
    questionLabel?: string;
    questionLabelType?: 'paragraph' | 'textField';
    errorMessageType?: keyof validationField['errorsInit'];
}

const AddField = ({
    addFieldValues: addFieldCheck,
    onchangeText,
    formElementTooltip,
    formElementDescription,
    formElementLabel,
    errorMessage,
    handleRequired,
    showQuestionLabel = true,
    formElementFile,
    questionLabel,
    questionLabelType = 'textField',
    errorMessageType,
}: AddFieldProps) => {
    const { draggableElementsDataTranslate } = useTranslationData();
    const { errorsInit } = useValidationField();
    const [showFileField, setShowFileField] = useState(false);

    useEffect(() => {
        if (formElementFile?.url || formElementFile?.caption) {
            setShowFileField(true);
        } else {
            setShowFileField(false);
        }
    }, [formElementFile]);

    const handleOnChange = useCallback(
        (data: string, type: WorksheetAddFieldEnum) => {
            const dataLength = data.trim().length;
            if (
                type === WorksheetAddFieldEnum.Description &&
                !(dataLength > WorksheetCharacterLengthValidation.DescriptionLength)
            ) {
                onchangeText({ formElementDescription: data });
            }
            if (
                type === WorksheetAddFieldEnum.ToolTips &&
                !(dataLength > WorksheetCharacterLengthValidation.TooltipsLength)
            ) {
                onchangeText({ formElementTooltip: data });
            }
        },
        [onchangeText]
    );

    const handleLabelChangeText = useCallback(
        (text: string) => {
            if (text.trim().length > WorksheetCharacterLengthValidation.QuestionLength) {
                return;
            }
            let errorType: keyof validationField['errorsInit'] = errorMessageType || 'questionLabel';
            handleRequired(text, 'questionLabel', errorsInit[errorType]);
            onchangeText?.({ formElementLabel: text });
        },
        [handleRequired, onchangeText, errorMessageType]
    );

    return (
        <>
            {showQuestionLabel && (
                <>
                    <div className="mb-2">
                        <Typography variant="label">
                            {questionLabel || draggableElementsDataTranslate.labels.questionLabel}
                        </Typography>
                    </div>
                    <div className="mb-6 d-flex">
                        {questionLabelType === 'textField' ? (
                            <TextInputHelper
                                required
                                errorMessage={errorMessage}
                                value={formElementLabel}
                                onChangeText={handleLabelChangeText}
                                placeholder={draggableElementsDataTranslate.placeholder.questionLabel}
                                style={{ flex: 1 }}
                            />
                        ) : (
                            <ResizableTextarea
                                id={'paragraph'}
                                value={formElementLabel}
                                onChange={handleLabelChangeText}
                                placeholder={draggableElementsDataTranslate.placeholder.questionLabel}
                                errorMessage={errorMessage}
                            />
                        )}
                        {/*TODO :- for image */}
                        <div className="ml-2">
                            <Button
                                variant="link"
                                onPress={() => {
                                    setShowFileField(true);
                                }}
                            >
                                <Icon name="image" color="blueOne" />
                            </Button>
                        </div>
                    </div>
                </>
            )}
            {addFieldCheck[WorksheetAddFieldEnum.ToolTips].check && (
                <>
                    <div className="mb-2">
                        <Typography variant="label">{draggableElementsDataTranslate.labels.tooltip}</Typography>
                    </div>
                    <div className="mb-6">
                        <ResizableTextarea
                            id={'tooltip'}
                            value={formElementTooltip || ''}
                            onChange={(text: string) => handleOnChange(text, WorksheetAddFieldEnum.ToolTips)}
                            placeholder={draggableElementsDataTranslate.placeholder.tooltip}
                        ></ResizableTextarea>
                    </div>
                </>
            )}
            {addFieldCheck[WorksheetAddFieldEnum.Description].check && (
                <>
                    <div className="mb-2">
                        <Typography variant="label">{draggableElementsDataTranslate.labels.description}:</Typography>
                    </div>
                    <div className="mb-6">
                        <ResizableTextarea
                            id={'description'}
                            value={formElementDescription || ''}
                            onChange={(text: string) => handleOnChange(text, WorksheetAddFieldEnum.Description)}
                            placeholder={draggableElementsDataTranslate.placeholder.description}
                        ></ResizableTextarea>
                    </div>
                </>
            )}
            {showFileField && (
                <FileField
                    onChange={onchangeText}
                    formElementFile={formElementFile}
                    handleClose={() => setShowFileField(false)}
                    fileSizeLimit={MAX_FILE_SIZE}
                />
            )}
        </>
    );
};

export default AddField;
