import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
const useTranslationData = () => {
    const { t } = useTranslation();

    const getDraggableElementsTranslate = useCallback(
        (path: string, target: 'placeholder' | 'title' | 'labels' | 'buttons') => {
            const base = 'worksheet:draggableElements';
            return t(`${base}.${target}.${path}`);
        },
        [t]
    );

    const getValidationMessageTranslate = useCallback(
        (path: string) => {
            const base = 'worksheet:validationMessage';
            return t(`${base}.${path}`);
        },
        [t]
    );

    const draggableElementsDataTranslate = useMemo(() => {
        const dataObj = {
            placeholder: {
                questionLabel: getDraggableElementsTranslate('questionLabel', 'placeholder'),
                optionPlaceholder: getDraggableElementsTranslate('optionPlaceholder', 'placeholder'),
                tooltip: getDraggableElementsTranslate('tooltip', 'placeholder'),
                description: getDraggableElementsTranslate('description', 'placeholder'),
                imageCaption: getDraggableElementsTranslate('imageCaption', 'placeholder'),
                addOther: getDraggableElementsTranslate('addOther', 'placeholder'),
                search: getDraggableElementsTranslate('search', 'placeholder'),
                files: getDraggableElementsTranslate('files', 'placeholder'),
                date: getDraggableElementsTranslate('date', 'placeholder'),
                time: getDraggableElementsTranslate('time', 'placeholder'),
                dateTime: getDraggableElementsTranslate('dateTime', 'placeholder'),
                text: getDraggableElementsTranslate('text', 'placeholder'),
            },
            title: {
                files: getDraggableElementsTranslate('files', 'title'),
            },
            labels: {
                tooltip: getDraggableElementsTranslate('tooltip', 'labels'),
                description: getDraggableElementsTranslate('description', 'labels'),
                titleText: getDraggableElementsTranslate('titleText', 'labels'),
                paragraphText: getDraggableElementsTranslate('paragraphText', 'labels'),
                questionLabel: getDraggableElementsTranslate('questionLabel', 'labels'),
                options: getDraggableElementsTranslate('options', 'labels'),
                ratingValues: getDraggableElementsTranslate('ratingValues', 'labels'),
                inputSettings: getDraggableElementsTranslate('inputSettings', 'labels'),
                image: getDraggableElementsTranslate('image', 'labels'),
                required: getDraggableElementsTranslate('required', 'labels'),
                questionText: getDraggableElementsTranslate('questionText', 'labels'),
                continueWith: getDraggableElementsTranslate('continueWith', 'labels'),
                nothingHappens: getDraggableElementsTranslate('nothingHappens', 'labels'),
            },
            buttons: {
                addOption: getDraggableElementsTranslate('addOption', 'buttons'),
                addOther: getDraggableElementsTranslate('addOther', 'buttons'),
                changeImage: getDraggableElementsTranslate('changeImage', 'buttons'),
                save: getDraggableElementsTranslate('save', 'buttons'),
                cancel: getDraggableElementsTranslate('cancel', 'buttons'),
            },
        };
        return dataObj;
    }, [getDraggableElementsTranslate]);

    const validationMessageDataTranslate = useMemo(() => {
        const data = {
            title: getValidationMessageTranslate('title'),
            name: getValidationMessageTranslate('name'),
            questionLabel: getValidationMessageTranslate('questionLabel'),
            paragraph: getValidationMessageTranslate('paragraph'),
            ratingValue: getValidationMessageTranslate('ratingValue'),
            inputSettingLabel: getValidationMessageTranslate('inputSettingLabel'),
            options: getValidationMessageTranslate('options'),
            optionLabel: getValidationMessageTranslate('optionLabel'),
        };
        return data;
    }, [getValidationMessageTranslate]);

    return { draggableElementsDataTranslate, validationMessageDataTranslate };
};
export default useTranslationData;
