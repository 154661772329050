import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Typography } from '@packages/ui/shared';
import { Badge } from 'react-bootstrap';
import colors from '@packages/core/styles/colors';

interface NavigationItemHoverProps {
    isHovered?: boolean;
    title: string;
    forwardRef?: HTMLElement;
}

interface Coords {
    top: number;
    bottom: number;
    right: number;
    left: number;
}

const navigationItemHoverStyles = createUseStyles({
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        maxWidth: '8vw',
        maxHeight: '6vh',
        left: (props: Coords) => props.left,
        top: (props: Coords) => props.top,
        right: (props: Coords) => props.right,
        bottom: (props: Coords) => props.bottom,
        zIndex: 3,
        padding: '1vh 2vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.white,
        borderRadius: 5,
    },
});

export const NavigationItemHoverTitle = (props: NavigationItemHoverProps) => {
    const { title, forwardRef } = props;
    const positions = React.useMemo(() => {
        if (forwardRef) {
            const pos = forwardRef.getBoundingClientRect();
            return {
                top: pos.top,
                right: 0,
                left: 48 + 20,
                bottom: pos.bottom,
            };
        }
        return { left: 0, right: 0, top: 0, bottom: 0 };
    }, [forwardRef]);

    const styles = navigationItemHoverStyles({ ...props, ...positions });
    return (
        <Badge as="div" pill variant="secondary" className={styles.container}>
            <Typography color={'white'}>{title}</Typography>
        </Badge>
    );
};
