import React, { useCallback, useRef, useState } from 'react';
import { Badge, Col, Form, Modal, Overlay } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { escalationsService } from '@web/services/singletons';
import { Button, Icon, TextareaHelper, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { TrReplyModel, TrReplyRepsonseDto } from '@packages/models/api';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
    trReplyOuter: {
        padding: 30,
        borderRadius: 8,
        backgroundColor: colors.grayOne,
        border: `1px solid ${colors.grayThree}`,
    },
    shareWithOuter: {
        display: 'flex',
        borderRadius: 4,
        padding: '12px 16px',
        alignItems: 'center',
        backgroundColor: colors.white,
        justifyContent: 'space-between',
        border: `1px solid ${colors.grayThree}`,
        position: 'relative',
    },
    info: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

interface TrReplyProps {
    trReplyId: string;
    qmrId: string;
    isTrReplySharedWithSqipOrInvestigation: boolean;
    onClose(): void;
    onSend(data: TrReplyModel): void;
    sqipControlGroup?: string;
    investigationId?: string;
}

export const TrReplyForm = ({
    trReplyId,
    qmrId,
    isTrReplySharedWithSqipOrInvestigation,
    onClose,
    onSend,
    sqipControlGroup,
    investigationId,
}: TrReplyProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [shareWithSameControlGroup, setShareWithSameControlGroup] = useState(false);
    const [shareWithQmrsInInvestigation, setShareWithQmrsInInvestigation] = useState(false);
    const [retailerMessage, setRetailerMessage] = useState('');
    const [soaMessage, setSoaMessage] = useState('');
    const [sneMessage, setSneMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleFormSubmit = useCallback(async () => {
        // if (!shareWithSameControlGroup && !shareWithQmrsInInvestigation) {
        //     return;
        // }

        const body = {
            trReplyId: trReplyId,
            shareBySqip: shareWithSameControlGroup,
            shareByInvestigation: shareWithQmrsInInvestigation,
            retailerTechnicianCorporateMessage: retailerMessage,
            soaDsqmNasiMessage: soaMessage,
            sneSdcDsqmMessage: sneMessage,
        };

        try {
            setIsLoading(true);
            const response = await escalationsService.trReply({
                body: body,
            });

            if (response.success) {
                console.log(response.data);
                setIsLoading(false);
                onSend(response.data);
            } else {
                throw response.data;
            }
        } catch (error) {
            setIsLoading(false);
            window.alert(error.message);
        }
    }, [
        onSend,
        retailerMessage,
        shareWithQmrsInInvestigation,
        shareWithSameControlGroup,
        sneMessage,
        soaMessage,
        trReplyId,
    ]);

    const renderOverrideConfirmationModal = () => {
        return (
            <React.Fragment>
                <Modal.Header placeholder={''}>
                    <Modal.Title>Confirm Sharing TR Reply - Override</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Typography>
                        This TR reply outcome has already been shared. View the existing shared message at the bottom of
                        the page. By sharing this TR reply again, you will override the existing message shared for all
                        entities (retailers, DSQMs, etc.).
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography>
                        Please confirm that you understand that this will override the previous TR shared reply message
                        by sharing this TR reply again.
                    </Typography>
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={() => setShowConfirmationModal(false)}>
                            Cancel
                        </Button>
                    </div>

                    <Button variant="danger" isLoading={false} disabled={false} onPress={handleFormSubmit}>
                        Confirm TR Reply Share Override
                    </Button>
                </Modal.Footer>
            </React.Fragment>
        );
    };

    return (
        <div className={classes.trReplyOuter}>
            <Form
                onSubmit={(event) => {
                    event.preventDefault();
                }}
            >
                <div className="mb-7 d-flex align-items-center justify-content-between">
                    <Typography variant="h3" color="blueOne">
                        Share TR Reply
                    </Typography>
                    <Icon name="x-close" onPress={onClose} />
                </div>
                <div className="mb-5">
                    <Typography>
                        By sharing this TR reply, the system will send the following entities associated to this
                        particular QMR ({qmrId}) a message regarding tihs Technical Report.
                        <br />
                        <br />
                        (Optional) If you'd like to share the message to additional related QMRs, select the boxes below
                        related to SQIP Control Group or investigation.
                    </Typography>
                </div>
                <Form.Row className="mb-10">
                    <Col xs={6}>
                        <div className={classes.shareWithOuter}>
                            <Form.Check
                                id="tr-reply__share-with-same-control-group"
                                label="QMRs with same SQIP Control Group"
                                checked={shareWithSameControlGroup}
                                onChange={(event) => {
                                    setShareWithSameControlGroup(event.currentTarget.checked);
                                }}
                            />
                            <Link to={`/escalations?query=${sqipControlGroup}`} target="_blank">
                                <Icon name="launch" color="grayFive" />
                            </Link>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className={classes.shareWithOuter}>
                            <Form.Check
                                id="tr-reply__share-with-qmrs-in-investigation"
                                label="QMRs in the same investigation (that were or were not escalated)"
                                checked={shareWithQmrsInInvestigation}
                                onChange={(event) => {
                                    setShareWithQmrsInInvestigation(event.currentTarget.checked);
                                }}
                                disabled={investigationId == undefined}
                            />
                            {investigationId ? (
                                <Link to={`/investigations/${investigationId}`} target="_blank">
                                    <Icon name="launch" color="grayFive" />
                                </Link>
                            ) : (
                                <div
                                    ref={tooltipRef}
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    className={classNames(classes.info, 'd-flex align-items-center')}
                                >
                                    <Icon size={18} name="info" color="graySix" />
                                </div>
                            )}
                        </div>
                    </Col>
                </Form.Row>

                <div className="mb-2">
                    <Typography variant="h4" color="blueOne">
                        Edit Message
                    </Typography>
                </div>

                {isTrReplySharedWithSqipOrInvestigation && (
                    <div className="d-flex align-items-center mb-7">
                        <Icon name="warning" color="redOne" style={{ marginRight: 5 }} />
                        <Typography color="redOne" style={{ fontWeight: 'bold' }}>
                            WARNING: This TR reply outcome has already been shared. View the existing shared message at
                            the bottom of the page. By sharing this TR reply again, you will override the existing
                            message shared for all entities (retailers, DSQMs, etc.).
                        </Typography>
                    </div>
                )}

                <Form.Row>
                    <Col xs={6} className="mb-7">
                        <TextareaHelper
                            label="Retailer/Technician/Corporate:"
                            placeholder="Enter Message"
                            value={retailerMessage}
                            onChangeText={setRetailerMessage}
                            containerStyle={{
                                backgroundColor: colors.white,
                            }}
                            required
                        />
                    </Col>
                    <Col xs={6} className="mb-7">
                        <TextareaHelper
                            label="SOA DSQM/NASI:"
                            placeholder="Enter Message"
                            value={soaMessage}
                            onChangeText={setSoaMessage}
                            containerStyle={{
                                backgroundColor: colors.white,
                            }}
                            required
                        />
                    </Col>
                    <Col xs={6} className="mb-7">
                        <TextareaHelper
                            label="SNE/SDC DSQMs:"
                            placeholder="Enter Message"
                            value={sneMessage}
                            onChangeText={setSneMessage}
                            containerStyle={{
                                backgroundColor: colors.white,
                            }}
                            required
                        />
                    </Col>
                </Form.Row>

                <hr className="mb-7" />

                <div className="d-flex justify-content-end">
                    <div className="mr-5">
                        <Button variant="tertiary" title="Cancel" onPress={onClose} />
                    </div>
                    {/* Directly submit TR Reply share if it hasn't been shared with SQIP or Investigation */}
                    {/* But if it has already been shared, show a confirmation modal because we're going to override the previous. */}
                    <Button
                        variant="primary"
                        title="Send"
                        onPress={
                            isTrReplySharedWithSqipOrInvestigation
                                ? () => setShowConfirmationModal(true)
                                : handleFormSubmit
                        }
                    />{' '}
                </div>
            </Form>
            <Modal
                show={showConfirmationModal}
                centered={true}
                onHide={() => setShowConfirmationModal(false)}
                size="lg"
            >
                {renderOverrideConfirmationModal()}
            </Modal>
            <Overlay target={tooltipRef.current as any} show={showTooltip}>
                {(overlayProps: any) => {
                    return (
                        <Badge ref={overlayProps.ref} style={{ ...overlayProps.style }} variant="secondary">
                            <div className="py-2 px-3 text-left">
                                <Typography color="white">
                                    {t(
                                        'investigations:panel.tabs.comments.commentMessage',
                                        'This Escalated QMR is not a part of any investigation'
                                    )}
                                </Typography>
                            </div>
                        </Badge>
                    );
                }}
            </Overlay>
        </div>
    );
};
