import React, { FC, useState, useEffect, useRef } from 'react';
import { Animated, View, StyleSheet, LayoutChangeEvent } from 'react-native';

interface CollapseProps {
    open?: boolean;
    children?: React.ReactNode;
}

export const Collapse: FC<CollapseProps> = ({ open, children }) => {
    const collapseAnimation = useRef<Animated.Value>(new Animated.Value(0)).current;

    const [isInstantiated, setIsInstantiated] = useState<boolean>(false);
    const [collapseHeight, setCollapseHeight] = useState<number>(0);

    useEffect(() => {
        const initialValue = open ? 0 : collapseHeight;
        const targetValue = open ? collapseHeight : 0;
        const animationDuration = isInstantiated ? 300 : 0;

        collapseAnimation.setValue(initialValue);

        Animated.timing(collapseAnimation, {
            toValue: targetValue,
            duration: animationDuration,
        }).start();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapseAnimation, collapseHeight, open]);

    function handleCollapseLayout(event: LayoutChangeEvent) {
        if (isInstantiated) {
            return;
        }

        setCollapseHeight(event.nativeEvent.layout.height);
        setIsInstantiated(true);
    }

    return (
        <Animated.View style={[styles.collapseOuter, isInstantiated && { height: collapseAnimation }]}>
            <View onLayout={handleCollapseLayout}>{children}</View>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    collapseOuter: {
        overflow: 'hidden',
    },
});
