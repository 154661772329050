import { useContext } from 'react';
import { BmisDispatchContext, BmisStateContext } from './bmis.context';

export const useBmisState = () => {
    const state = useContext(BmisStateContext);

    if (state === undefined) {
        throw new Error('useBmisState must be used within a BmisProvider');
    }
    return state;
};

export const useBmisDispatch = () => {
    const dispatch = useContext(BmisDispatchContext);

    if (dispatch === undefined) {
        throw new Error('useBmisDispatch must be used within a BmisProvider');
    }
    return dispatch;
};
