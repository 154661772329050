import React, { FC } from 'react';
import { AssetUploadsProvider } from './asset-uploads';
import { AuthProvider } from './auth';
import { NotificationsProvider } from './notifications';
import { AdvancedSearchProvider } from './qmrs/advanced-search.context';
import { InvestigationModalProvider } from './investigations';
import { EscalationModalProvider } from './escalations';
import { TechlineProvider } from './techline/techline.context';
import { DashboardProvider } from './dashboard/dashboard.context';
import { WorksheetsProvider } from './worksheets';
import { QmrsDashboardProvider } from './qmrsdashboard/qmrsdashboard.context';
import { VinSessionProvider } from '../../apps/web/src/vin-sessions/context/vin-sessions.context';

export const SharedContextProviders: FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <AuthProvider>
            <InvestigationModalProvider>
                <EscalationModalProvider>
                    <AdvancedSearchProvider>
                        <NotificationsProvider>
                            <TechlineProvider>
                                <QmrsDashboardProvider>
                                    <DashboardProvider>
                                        <VinSessionProvider>
                                            <WorksheetsProvider>
                                                <AssetUploadsProvider>{children}</AssetUploadsProvider>
                                            </WorksheetsProvider>
                                        </VinSessionProvider>
                                    </DashboardProvider>
                                </QmrsDashboardProvider>
                            </TechlineProvider>
                        </NotificationsProvider>
                    </AdvancedSearchProvider>
                </EscalationModalProvider>
            </InvestigationModalProvider>
        </AuthProvider>
    );
};
