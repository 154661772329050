import React, { FC } from 'react';

import { TextareaHelper, Typography } from '@packages/ui/shared';

import { DraggableFormComponent } from '@packages/models/api';

interface FormEditTextStaticProps extends DraggableFormComponent {
    onChange({ formElementLabel }: { formElementLabel?: string }): void;
}

export const FormEditTextStatic: FC<FormEditTextStaticProps> = ({ formElementLabel, onChange }) => {
    function handleChangeText(text: string) {
        onChange({ formElementLabel: text });
    }

    return (
        <>
            <div className="mb-2">
                <Typography variant="label">Paragraph Text</Typography>
            </div>
            <TextareaHelper value={formElementLabel} onChangeText={handleChangeText} />
        </>
    );
};
