/* eslint-disable react-native/no-inline-styles */
import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';

import { Typography } from './typography';
import colors from '@packages/core/styles/colors';

import { Notification, NotificationStatusId } from '@packages/models/api';
import { UnreadStatusIndicator } from './unread-status-indicator';

interface NotificationProps {
    item: Notification;
    fluid?: boolean;
    onPress(notification: Notification): void;
}

export const NotificationListItem: FC<NotificationProps> = ({ item, fluid, onPress }) => {
    function handleNotificationPress() {
        onPress(item);
    }

    const isNew =
        item.notificationStatusId === NotificationStatusId.New ||
        item.notificationStatusId === NotificationStatusId.Unread;

    const formattedTimestamp = useMemo(() => {
        const timestampDate = new Date(item.createdTimestamp);
        // https://date-fns.org/v2.21.1/docs/format
        // based on notification group -- either  01:16pm  or  Fri 1/15
        const formatString = item.notificationGroupingId === 'TODAY' ? 'h:mm a' : 'E L/dd';
        return format(timestampDate, formatString);
    }, [item.createdTimestamp, item.notificationGroupingId]);

    return (
        <TouchableOpacity
            style={[
                styles.notificationListItem,
                {
                    paddingHorizontal: fluid ? 0 : 24,
                    backgroundColor: isNew ? colors.white : colors.grayOne,
                },
            ]}
            onPress={handleNotificationPress}
        >
            <View style={styles.notificationMessageOuter}>
                <Typography
                    variant={isNew ? 'label' : 'labelRegular'}
                    style={styles.notificationMessage}
                    color="blueOne"
                >
                    {item.message}
                </Typography>
                <View style={styles.notificationTimestampOuter}>
                    <Typography variant="caption" color="graySix">
                        {formattedTimestamp}
                    </Typography>
                    {isNew && <UnreadStatusIndicator />}
                </View>
            </View>
            <Typography variant="caption" style={styles.notificationVehicleText}>
                {item.data.vehicleModelYear} {item.data.carlineName}
            </Typography>

            {!!item.retailerName && (
                <Typography variant="caption" color="graySix">
                    {item.retailerName}
                    {!!item.retailerCode && ` (${item.retailerCode})`}
                </Typography>
            )}
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    notificationListItem: {
        paddingVertical: 16,
    },
    notificationTextBlue: {
        fontSize: 15,
        fontWeight: 'bold',
        color: colors.blueOne,
    },
    statusPill: {
        fontSize: 12,
        textTransform: 'uppercase',
        backgroundColor: colors.greenFour,
    },
    notificationMessageOuter: {
        marginBottom: 8,
        flexDirection: 'row',
    },
    notificationMessage: {
        flex: 1,
    },
    notificationTimestampOuter: {
        flexShrink: 0,
        paddingLeft: 16,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    notificationVehicleText: {
        marginBottom: 8,
    },
});
