/* eslint-disable react-native/no-inline-styles */
import React, { FC, useMemo, Children } from 'react';
import { StyleSheet, TouchableHighlight, TouchableHighlightProps, Platform, View } from 'react-native';

import colors from '@packages/core/styles/colors';

import { Typography } from './typography';
import { LoadingCircle } from './loading-circle';
import { useHoverState } from './hooks';

const styles = StyleSheet.create({
    base: {
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 3,
        position: 'relative',
    },
    center: {
        justifyContent: 'center',
    },

    small: {
        height: 30,
        paddingVertical: 4,
        paddingHorizontal: 8,
    },
    medium: {
        height: 40,
        paddingVertical: 8,
        paddingHorizontal: 16,
    },
    large: {
        height: 48,
        paddingVertical: 12,
        paddingHorizontal: 24,
    },

    iconLeft: {
        marginRight: 8,
    },

    text: {
        fontSize: 15,
        fontWeight: '500',
        lineHeight: 24,
    },
    textLarge: {
        fontSize: 17,
    },
    textCenter: {
        textAlign: 'center',
    },

    iconRight: {
        marginLeft: 8,
    },

    whiteText: {
        color: colors.white,
    },
    linkHeaderText: {
        fontSize: 15,
    },
    darkText: {
        color: colors.textDarkPrimary,
    },
    blueText: {
        color: colors.blueOne,
    },
    grayText: {
        color: colors.textDarkSecondary,
    },
    linkText: {
        textDecorationLine: 'underline',
    },
    disabledText: {
        // color: '#08203e',
    },

    disabled: {
        opacity: 0.32,
    },

    primaryEnabled: {
        backgroundColor: colors.blueOne,
    },
    primaryHover: {
        backgroundColor: colors.blueOneHover,
    },

    secondaryEnabled: {
        backgroundColor: colors.brandSecondary,
    },
    secondaryHover: {
        backgroundColor: colors.brandSecondaryHover,
    },

    tertiary: {
        backgroundColor: colors.grayTwo,
    },

    dangerEnabled: {
        backgroundColor: colors.redOne,
    },
    dangerHover: {
        backgroundColor: colors.redOneHover,
    },

    transferEnabled: {
        backgroundColor: colors.whiteOne,
    },
    transferHover: {
        backgroundColor: colors.whiteOneHover,
    },

    successEnabled: {
        backgroundColor: colors.greenOne,
    },
    successHover: {
        backgroundColor: colors.greenOneHover,
    },

    ghost: {
        backgroundColor: 'transparent',
    },
    ghostBlueHover: {
        backgroundColor: colors.blueFour,
    },
    ghostBlueActive: {
        backgroundColor: colors.blueThree,
    },
    link: {
        backgroundColor: 'transparent',
        paddingVertical: 0,
        paddingHorizontal: 0,
    },
    headerLink: {
        backgroundColor: 'transparent',
    },
    block: {
        width: '100%',
    },
});

type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'success'
    | 'ghost-blue'
    | 'ghost-gray'
    | 'link'
    | 'link-header-button'
    | 'info'
    | 'transfer';

interface ButtonState {
    isHovered: boolean;
    isActive?: boolean;
}

const variantStyleMap = {
    primary: ({ isHovered, isActive }: ButtonState) => ({
        underlayColor: colors.blueOneActive,
        buttonStyle: isHovered || isActive ? styles.primaryHover : styles.primaryEnabled,
        textStyle: styles.whiteText,
    }),
    secondary: ({ isHovered }: ButtonState) => ({
        underlayColor: colors.brandSecondaryActive,
        buttonStyle: isHovered ? styles.secondaryHover : styles.secondaryEnabled,
        textStyle: styles.whiteText,
    }),
    tertiary: ({ isActive }: ButtonState) => ({
        underlayColor: colors.grayTwo,
        buttonStyle: isActive ? { backgroundColor: '#cbcbcb' } : styles.tertiary,
        textStyle: styles.darkText,
    }),
    danger: ({ isHovered }: ButtonState) => ({
        underlayColor: colors.redOneActive,
        buttonStyle: isHovered ? styles.dangerHover : styles.dangerEnabled,
        textStyle: styles.whiteText,
    }),
    transfer: ({ isHovered }: ButtonState) => ({
        underlayColor: colors.whiteOne,
        buttonStyle: isHovered ? styles.transferHover : styles.transferEnabled,
        textStyle: styles.darkText,
    }),
    success: ({ isHovered }: ButtonState) => ({
        underlayColor: colors.greenOneActive,
        buttonStyle: isHovered ? styles.successHover : styles.successEnabled,
        textStyle: styles.whiteText,
    }),
    'ghost-blue': ({ isHovered, isActive }: ButtonState) => ({
        underlayColor: '#cbcbcb',
        buttonStyle: isActive ? styles.ghostBlueActive : isHovered ? styles.ghostBlueHover : styles.ghost,
        textStyle: styles.blueText,
    }),
    'ghost-gray': ({ isHovered }: ButtonState) => ({
        underlayColor: colors.white,
        buttonStyle: isHovered ? { backgroundColor: colors.grayTwo } : styles.ghost,
        textStyle: styles.grayText,
    }),
    link: () => ({
        underlayColor: colors.white,
        buttonStyle: styles.link,
        textStyle: [styles.blueText, styles.linkText],
    }),
    'link-header-button': () => ({
        underlayColor: 'transparent',
        buttonStyle: styles.headerLink,
        textStyle: [styles.whiteText, styles.linkHeaderText],
    }),
    info: () => ({
        underlayColor: '#dee6ef',
        buttonStyle: styles.ghostBlueHover,
        textStyle: styles.blueText,
    }),
} as const;

interface ButtonProps extends TouchableHighlightProps {
    title?: string | JSX.Element;
    alignTitle?: 'left' | 'center';
    iconLeft?: JSX.Element;
    iconRight?: JSX.Element;
    variant?: ButtonVariant;
    size?: ButtonSize;
    active?: boolean;
    isLoading?: boolean;
    block?: boolean;
    children?: React.ReactNode;
}

export const Button: FC<ButtonProps> = ({
    title: propTitle,
    alignTitle = 'center',
    variant = 'primary',
    iconLeft,
    iconRight,
    size = Platform.select({ web: 'medium', default: 'large' }),
    style,
    disabled,
    children,
    active,
    isLoading,
    block,
    ...btnProps
}) => {
    const { isHovered, hoverEventHandlers } = useHoverState();
    const { buttonStyle, textStyle, underlayColor } = variantStyleMap[variant]({ isHovered, isActive: active });

    const title = useMemo(() => {
        if (Children.count(children) === 0 || typeof children === 'string') {
            return (
                <Typography
                    style={[
                        styles.text,
                        size === 'large' && styles.textLarge,
                        textStyle,
                        disabled && styles.disabledText,
                        alignTitle === 'center' && styles.textCenter,
                        {
                            opacity: isLoading ? 0 : 1,
                        },
                    ]}
                >
                    {propTitle || children}
                </Typography>
            );
        }

        return children;
    }, [alignTitle, children, disabled, propTitle, size, textStyle, isLoading]);

    return (
        <TouchableHighlight
            {...hoverEventHandlers}
            underlayColor={underlayColor}
            activeOpacity={1}
            accessibilityRole="button"
            style={[
                styles.base,
                alignTitle === 'center' && styles.center,
                styles[size],
                buttonStyle,
                disabled && styles.disabled,
                block && styles.block,
                style,
            ]}
            disabled={disabled}
            {...btnProps}
        >
            <>
                {iconLeft && <View style={[styles.iconLeft]}>{iconLeft}</View>}

                {isLoading && <LoadingCircle style={{ position: 'absolute' }} />}

                {title}

                {iconRight && <View style={styles.iconRight}>{iconRight}</View>}
            </>
        </TouchableHighlight>
    );
};
