import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useQmrState } from '@packages/contexts/qmrs';
import { SuccessFailBadge, Typography } from '@packages/ui/shared';

const CorrectionSubmitted: FC = () => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();

    if (!qmr) {
        return null;
    }

    return (
        <>
            <Typography variant="h4">{t('qmr:sections.correction.title', 'Correction')}</Typography>

            <div className="my-4">
                <Typography>{qmr.correction}</Typography>
            </div>

            {!qmr.importedFromSiebel && (
                <div className="d-inline-block">
                    <SuccessFailBadge success={qmr.issueResolved}>
                        {qmr.issueResolved
                            ? t('qmr:report.correction.didResolve', "Issue resolved by the technician's work")
                            : t('qmr:report.correction.didNotResolve', "Issue not resolved by the technician's work")}
                    </SuccessFailBadge>
                </div>
            )}
        </>
    );
};

export default CorrectionSubmitted;
