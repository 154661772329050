import React, { FC } from 'react';
import { ContentRenderer, Popover } from 'react-tiny-popover';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';

interface Props {
    isOpen: boolean;
    options: any;
    children: JSX.Element;
    setShow: (x: boolean) => void;
}
export const SelectPopover: FC<Props> = ({ options, children, isOpen, setShow }) => {
    const classes = useNewWorksheetHeaderStyles();

    return (
        <Popover
            isOpen={isOpen}
            positions={['bottom', 'top']}
            onClickOutside={() => setShow(false)}
            contentLocation={({ childRect, popoverRect, position }) => {
                // Calculate position of popover based on window height and client position and based on calculation show the popover at top or bottom position
                if (childRect.y + childRect.height + popoverRect.height > window.innerHeight) {
                    return {
                        top: childRect.top - 4 - popoverRect.height,
                        left: childRect.left - (popoverRect.width - childRect.width),
                    };
                } else {
                    return {
                        top: childRect.bottom + 4,
                        left: childRect.left - (popoverRect.width - childRect.width),
                    };
                }
            }}
            containerClassName={classes.actionsPopover}
            content={<div className={classes.popoverActionsSection}> {options} </div>}
        >
            {children}
        </Popover>
    );
};
const useNewWorksheetHeaderStyles = createUseStyles({
    actionsPopover: {
        minWidth: 113,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
    },
    popoverActionsSection: {
        padding: '4px 0',
    },
});
