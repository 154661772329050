import React from 'react';
import { useParams } from 'react-router-dom';

import { httpClient, notificationsService } from '@web/services/singletons';
import { useAuthState } from '@packages/contexts/auth';
import { useMarkNotificationAsReadOnLoad } from '@packages/contexts/notifications';
import { Button } from '@packages/ui/shared';
import { useTranslation } from 'react-i18next';

export function FailCodeCsvExport() {
    const { t } = useTranslation();
    const { account, token } = useAuthState();
    const { exportId } = useParams<{ exportId: string }>();

    useMarkNotificationAsReadOnLoad(notificationsService);

    const onClickDownloadButton = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const options = {
            headers: {
                'X-Subcomm-Access-Token': token,
            },
        };
        let filename = '';
        fetch(`${httpClient.baseUrl}/failCode/csv-export/${exportId}`, options)
            .then((res) => {
                //here we are not able to access the content disposition header
                // hence trying with response url since this is an redirected request
                if (res.url) {
                    let parsedUrl = new URL(res.url);
                    const respContentDisp = Array.from(parsedUrl.searchParams).filter(
                        (sp) => sp[0] === 'response-content-disposition'
                    );
                    if (respContentDisp && respContentDisp.length > 0) {
                        const contentDispositionHeader = respContentDisp[0][1];
                        if (contentDispositionHeader) {
                            let filenameSplited = contentDispositionHeader.split('filename=');
                            if (filenameSplited && filenameSplited.length > 1) {
                                filename = filenameSplited[1].split(';')[0].replaceAll('"', '');
                            }
                        }
                    }
                } else {
                    let contentDispositionHeader = res.headers.get('Content-Disposition');
                    if (contentDispositionHeader) {
                        let filenameSplited = contentDispositionHeader.split('filename=');
                        if (filenameSplited && filenameSplited.length > 1) {
                            filename = filenameSplited[1].split(';')[0].replaceAll('"', '');
                        }
                    }
                }
                return res.blob();
            })
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                if (filename) {
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = file;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(file);
                } else {
                    window.location.assign(file);
                }
            });
    };

    return (
        <div className="d-flex flex-row justify-content-center align-items-top mt-10">
            {account?.systemCapabilities.canExportQMRsAsCsv && (
                <Button>
                    <a
                        href="javascript:void(0);"
                        style={{ textDecoration: 'none', color: 'white' }}
                        onClick={(e) => {
                            onClickDownloadButton(e);
                        }}
                    >
                        {t('buttons:downloadCsv', 'Download CSV')}
                    </a>
                </Button>
            )}
        </div>
    );
}

export default FailCodeCsvExport;
