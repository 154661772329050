import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface ClickStateContextType {
    isClicked: boolean;
    toggleClickState: () => void;
    isMobileDevice: boolean;
}

const ClickStateContext = createContext<ClickStateContextType | undefined>(undefined);

export const ClickStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    const toggleClickState = () => {
        setIsClicked((prevClicked) => !prevClicked);
    };

    useEffect(() => {
        const isMobile = window.matchMedia('(max-width: 767px)');
        if (!isMobile?.matches) {
            setIsClicked(true);
            setIsMobileDevice(false);
        } else {
            setIsClicked(false);
            setIsMobileDevice(true);
        }
    }, []);

    const value = {
        isClicked,
        toggleClickState,
        isMobileDevice,
    };

    return <ClickStateContext.Provider value={value}>{children}</ClickStateContext.Provider>;
};

export const useClickState = () => {
    const context = useContext(ClickStateContext);
    if (!context) {
        throw new Error('useClickState must be used within a ClickStateProvider');
    }
    return context;
};
