import { UAParser } from 'ua-parser-js';

import { HttpClient as BaseHttpClient, HttpHeaders } from '@packages/core/http';
import { StorageService } from '@packages/core/storage';
import { ConfigService } from '@packages/core/config';
import { AuthTokenService } from '@packages/contexts/auth';

export class HttpClient extends BaseHttpClient {
    constructor(storageService: StorageService, configService: ConfigService, authTokenService: AuthTokenService) {
        const locale = Array.isArray(navigator.languages) ? navigator.languages[0] : navigator.language;

        const defaultHeaders: HttpHeaders = {
            'content-type': 'application/json',
            'X-Locale': locale,
            'X-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'X-Client-Device-Type-Id': 'WEB_BROWSER',
        };

        const ua = new UAParser(navigator.userAgent);
        const os = ua.getOS();

        if (os.name) {
            defaultHeaders['X-Client-Device-Operating-System-Name'] = os.name;
        }

        if (os.version) {
            defaultHeaders['X-Client-Device-Operating-System-Version'] = os.version;
        }

        const device = ua.getDevice();

        if (device.model) {
            defaultHeaders['X-Client-Device-Model'] = device.model;
        }

        if (device.vendor) {
            defaultHeaders['X-Client-Device-Manufacturer'] = device.vendor;
        }

        const tokenGetter = async () => {
            const resp = await authTokenService.getStoredToken();
            return resp.token;
        };

        super(storageService, configService, {
            defaultHeaders,
            tokenGetter,
        });
    }
}
