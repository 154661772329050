import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Modal, ModalProps, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { createUseStyles } from 'react-jss';

import { InvestigationCategory, InvestigationModel } from '@packages/models/api';
import { investigationService } from '@web/services/singletons';
import { useInvestigationModal } from '@packages/contexts/investigations';

import { Button, Label, TextareaHelper, TextInputHelper } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    investigationModal: {},
    navLink: {
        padding: '24px 16px',
        color: colors.textDarkPrimary,
        '&:hover': {
            color: colors.textDarkPrimary,
        },
        '&.active': {
            fontWeight: 'bold',
            borderBottom: `2px solid ${colors.blueOne}`,
        },
    },
    modalHeader: {
        padding: '0 16px !important',
        borderBottom: `1px solid ${colors.grayFour}`,
        backgroundColor: `${colors.white} !important`,
    },
});

enum TAB_KEYS {
    SELECT_EXISTING = 'SELECT_EXISTING',
    CREATE_NEW = 'CREATE_NEW',
}

export const InvestigationModal = ({ onHide, ...props }: ModalProps) => {
    const { selectedQmrs, mode, onSuccess, allowInvestigationReassignment } = useInvestigationModal();
    const { t } = useTranslation();
    const classes = useStyles();
    const [tabKey, setTabKey] = useState(TAB_KEYS.SELECT_EXISTING);
    const [isLoading, setIsLoading] = useState(false);

    /* -------------------------------------------------- */
    /* Select existing form */
    /* -------------------------------------------------- */
    const [investigationTypeaheadIsLoading, setInvestigationTypeaheadIsLoading] = useState(false);
    const [investigationTypeaheadOptions, setInvestigationTypeaheadOptions] = useState<InvestigationModel[]>([]);
    const [investigationTypeaheadValues, setInvestigationTypeaheadValues] = useState<InvestigationModel[]>([]);
    const handleInvestigationTypeaheadSearch = useCallback(async (query: string) => {
        setInvestigationTypeaheadIsLoading(true);

        try {
            const params = new URLSearchParams({ query });
            const response = await investigationService.searchInvestigations({
                searchString: `${params.toString()}`,
            });

            if (response.success) {
                setInvestigationTypeaheadOptions(response.data.investigations);
            } else {
                throw response.data;
            }
        } catch (error) {
            setInvestigationTypeaheadOptions([]);
            window.alert(error.message);
        }

        setInvestigationTypeaheadIsLoading(false);
    }, []);

    /* -------------------------------------------------- */
    /* Create new form */
    /* -------------------------------------------------- */
    const [titleInputValue, setTitleInputValue] = useState('');
    const [descriptionInputValue, setDescriptionInputValue] = useState('');
    const [categoryTypeaheadOptions, setCategoryTypeaheadOptions] = useState<InvestigationCategory[]>([]);
    const [categoryTypeaheadValues, setCategoryTypeaheadValues] = useState<InvestigationCategory[]>([]);

    /* -------------------------------------------------- */
    /* Modal handlers */
    /* -------------------------------------------------- */
    const handleOnEnter = useCallback(async () => {
        try {
            const response = await investigationService.getInvestigationCategories();

            if (response.success) {
                setCategoryTypeaheadOptions(response.data.categories);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }, []);

    const handleOnExited = useCallback(() => {
        setTabKey(TAB_KEYS.SELECT_EXISTING);
        setInvestigationTypeaheadOptions([]);
        setInvestigationTypeaheadValues([]);
        setTitleInputValue('');
        setDescriptionInputValue('');
        setCategoryTypeaheadValues([]);
    }, []);

    const handleSubmitButtonPress = useCallback(async () => {
        if (tabKey === TAB_KEYS.SELECT_EXISTING) {
            try {
                setIsLoading(true);

                const response = await investigationService.addQmrsToExistingInvestigation({
                    investigationId: investigationTypeaheadValues[0].investigationId,
                    body: {
                        qmrIds: selectedQmrs.map((qmr) => qmr.qmrId),
                        ...(allowInvestigationReassignment ? { force: true } : {}),
                    },
                });

                if (response.success) {
                    typeof onSuccess === 'function' && onSuccess(response.data.investigation);
                } else {
                    throw response.data;
                }

                setIsLoading(false);
                onHide();
            } catch (error) {
                setIsLoading(false);
                window.alert(error.message);
            }
        } else {
            try {
                setIsLoading(true);

                const response = await investigationService.createInvestigation({
                    body: {
                        title: titleInputValue,
                        categoryId: categoryTypeaheadValues[0].investigationCategoryId,
                        description: descriptionInputValue,
                        qmrIds: selectedQmrs.map((qmr) => qmr.qmrId),
                        ...(allowInvestigationReassignment ? { force: true } : {}),
                    },
                });

                if (response.success) {
                    console.log(response.data);
                    typeof onSuccess === 'function' && onSuccess(response.data.investigation);
                } else {
                    throw response.data;
                }

                setIsLoading(false);
                onHide();
            } catch (error) {
                setIsLoading(false);
                window.alert(error.message);
            }
        }
    }, [
        allowInvestigationReassignment,
        categoryTypeaheadValues,
        descriptionInputValue,
        investigationTypeaheadValues,
        onHide,
        onSuccess,
        selectedQmrs,
        tabKey,
        titleInputValue,
    ]);

    const disabled =
        tabKey === TAB_KEYS.SELECT_EXISTING
            ? !!investigationTypeaheadValues.length === false || !!selectedQmrs.length === false
            : !titleInputValue ||
              !!categoryTypeaheadValues.length === false ||
              (mode === 'add' && !!selectedQmrs.length === false);

    useEffect(() => {
        if (mode === 'create') {
            setTabKey(TAB_KEYS.CREATE_NEW);
        }
    }, [mode]);

    return (
        <Modal
            centered
            dialogClassName={classes.investigationModal}
            onHide={onHide}
            onEnter={handleOnEnter}
            onExited={handleOnExited}
            {...props}
        >
            <Tab.Container id="investigation-tabs" activeKey={tabKey} onSelect={(key) => setTabKey(key as TAB_KEYS)}>
                <Modal.Header className={classes.modalHeader} placeholder={''}>
                    <Nav>
                        {mode === 'add' && (
                            <Nav.Item>
                                <Nav.Link eventKey={TAB_KEYS.SELECT_EXISTING} className={classes.navLink}>
                                    {t('modals:investigations.tabs.selectExisting.label', 'Select existing')}
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        <Nav.Item>
                            <Nav.Link eventKey={TAB_KEYS.CREATE_NEW} className={classes.navLink}>
                                {t('modals:investigations.tabs.createNew.label', 'Create new')}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Content className="w-100">
                        {mode === 'add' && (
                            <Tab.Pane eventKey={TAB_KEYS.SELECT_EXISTING} className="p-0">
                                <AsyncTypeahead
                                    id="investigation-modal__investigation-typeahead"
                                    placeholder="Select Investigation"
                                    labelKey="title"
                                    isLoading={investigationTypeaheadIsLoading}
                                    onSearch={handleInvestigationTypeaheadSearch}
                                    options={investigationTypeaheadOptions}
                                    selected={investigationTypeaheadValues}
                                    onChange={(selected) => {
                                        setInvestigationTypeaheadValues(selected);
                                    }}
                                />
                            </Tab.Pane>
                        )}

                        <Tab.Pane eventKey={TAB_KEYS.CREATE_NEW} className="p-0">
                            <Form.Row className="mb-4">
                                <Col>
                                    <TextInputHelper
                                        label="Title"
                                        placeholder="Enter Title"
                                        type="text"
                                        required
                                        value={titleInputValue}
                                        onChangeText={setTitleInputValue}
                                    />
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Label required>Category</Label>
                                        <Typeahead
                                            id="investigation-modal__category-typeahead"
                                            placeholder="Select Category"
                                            labelKey="name"
                                            options={categoryTypeaheadOptions}
                                            selected={categoryTypeaheadValues}
                                            onChange={(selected) => {
                                                setCategoryTypeaheadValues(selected);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <TextareaHelper
                                label="Description"
                                value={descriptionInputValue}
                                onChangeText={setDescriptionInputValue}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{
                            marginRight: 7,
                        }}
                        variant="ghost-blue"
                        onPress={onHide}
                        title={t('buttons:cancel', 'Cancel')}
                    />
                    <Button
                        isLoading={isLoading}
                        disabled={disabled}
                        variant="primary"
                        onPress={handleSubmitButtonPress}
                        title={
                            mode === 'add'
                                ? selectedQmrs.length === 1
                                    ? `${t('buttons:addToInvestigation', 'Add to Investigation')}`
                                    : `${t('buttons:addSelected', 'Add Selected')} (${selectedQmrs.length})`
                                : `${t('buttons:create', 'Create')}`
                        }
                    />
                </Modal.Footer>
            </Tab.Container>
        </Modal>
    );
};
