import * as React from 'react';
import {
    IDashboardUpdates,
    DashboardActionDispatcher,
    dashboardActions,
    dashboardReducer,
    initialState,
    IDashboardState,
    IDashboard,
    baseDashboard,
} from './dashboard.state';
import { DashboardService } from './dashboard.service';

export const DashboardDispatchContext = React.createContext<DashboardActionDispatcher | undefined>(undefined);

export const baseDashboardUpdates: IDashboardUpdates[] = [];

export const DashboardContext = React.createContext<IDashboardState>({
    dashboard: baseDashboard,
    dashboardUpdates: baseDashboardUpdates,
    hasFetched: false,
    loading: false,
    errors: [],
} as IDashboardState);

export const DashboardProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const [state, dispatch] = React.useReducer(dashboardReducer, initialState);
    return (
        <DashboardContext.Provider value={state}>
            <DashboardDispatchContext.Provider value={dispatch}>{children}</DashboardDispatchContext.Provider>
        </DashboardContext.Provider>
    );
};

export async function fetchDashboard({
    dashboardId,
    dashboardService,
    dashboardDispatch,
    signal,
}: {
    dashboardId?: string;
    dashboardService: DashboardService;
    signal?: AbortSignal;
    dashboardDispatch: DashboardActionDispatcher;
}) {
    dashboardDispatch(dashboardActions.isLoading());

    const response = await dashboardService.getDashboard({
        dashboardId,
        signal,
    });

    dashboardDispatch(dashboardActions.hasLoaded());

    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        dashboardDispatch(dashboardActions.hasErrors({ errors: [response.data.message] }));
        return Promise.reject(response.data);
    }

    const { dashboard } = response.data.dashboard;

    dashboardDispatch(dashboardActions.getDashboard({ dashboard }));

    return response.data;
}

export async function fetchDashboardUpdates({
    dashboardService,
    dashboardDispatch,
    signal,
}: {
    dashboardService: DashboardService;
    signal?: AbortSignal;
    dashboardDispatch: DashboardActionDispatcher;
}) {
    dashboardDispatch(dashboardActions.isLoading());

    const response = await dashboardService.getDashboardUpdate({
        signal,
    });

    dashboardDispatch(dashboardActions.hasLoaded());

    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        dashboardDispatch(dashboardActions.hasErrors({ errors: [response.data.message] }));
        return Promise.reject(response.data);
    }

    const { dashboardUpdates } = response.data.dashboard;

    dashboardDispatch(dashboardActions.getDashboardUpdate({ dashboardUpdates }));

    return response.data;
}

export async function createOrUpdateDashboard({
    dashboardId,
    newDashboard,
    dashboardService,
    dashboardDispatch,
    signal,
}: {
    dashboardId?: string;
    newDashboard: Partial<IDashboard>;
    newDashboardUpdates?: IDashboardUpdates[];
    dashboardService: DashboardService;
    signal?: AbortSignal;
    dashboardDispatch: DashboardActionDispatcher;
}) {
    dashboardDispatch(dashboardActions.isLoading());

    if (!dashboardId) {
        const response = await dashboardService.createDashboard({
            body: {
                dashboard: newDashboard,
            },
            signal,
        });
        dashboardDispatch(dashboardActions.hasLoaded());

        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            dashboardDispatch(dashboardActions.hasErrors({ errors: [response.data.message] }));
            return Promise.reject(response.data);
        }

        const { dashboard } = response.data.dashboard;

        dashboardDispatch(dashboardActions.createDashboard({ dashboard }));

        return response.data;
    } else {
        const response = await dashboardService.updateDashboard({
            body: {
                dashboard: newDashboard,
            },
            signal,
        });
        dashboardDispatch(dashboardActions.hasLoaded());

        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            dashboardDispatch(dashboardActions.hasErrors({ errors: [response.data.message] }));
            return Promise.reject(response.data);
        }

        const { dashboard } = response.data.dashboard;

        dashboardDispatch(dashboardActions.updateDashboard({ dashboard }));

        return response.data;
    }
}

export async function addOrUpdateDashboardUpdate({
    dashboardUpdate: startDashboard,
    dashboardService,
    dashboardDispatch,
    signal,
}: {
    dashboardUpdate: IDashboardUpdates;
    dashboardService: DashboardService;
    signal?: AbortSignal;
    dashboardDispatch: DashboardActionDispatcher;
}) {
    const { dashboardUpdateId } = startDashboard;

    // UUIDs will not pass on the backend, so remove the empty strings.
    const dashboardUpdate = Object.keys(startDashboard)
        .filter((k) => startDashboard[k as keyof IDashboardUpdates])
        .reduce((a, k) => ({ ...a, [k]: startDashboard[k as keyof IDashboardUpdates] }), {}) as IDashboardUpdates;

    dashboardDispatch(dashboardActions.isLoading());

    if (!dashboardUpdateId) {
        const response = await dashboardService.createDashboardUpdate({
            dashboardUpdate,
            signal,
        });
        dashboardDispatch(dashboardActions.hasLoaded());

        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            dashboardDispatch(dashboardActions.hasErrors({ errors: [response.data.message] }));
            return Promise.reject(response.data);
        }

        const { dashboardUpdates } = response.data.dashboard;

        dashboardDispatch(dashboardActions.updateDashboardUpdate({ dashboardUpdates }));

        return response.data;
    } else {
        const response = await dashboardService.updateDashboardUpdate({
            dashboardUpdate,
            signal,
        });
        dashboardDispatch(dashboardActions.hasLoaded());

        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            dashboardDispatch(dashboardActions.hasErrors({ errors: [response.data.message] }));
            return Promise.reject(response.data);
        }

        const { dashboardUpdates } = response.data.dashboard;

        dashboardDispatch(dashboardActions.updateDashboardUpdate({ dashboardUpdates }));

        return response.data;
    }
}

export async function deleteDashboardUpdate({
    updateDashboardId,
    dashboardService,
    dashboardDispatch,
    signal,
}: {
    updateDashboardId: string;
    dashboardService: DashboardService;
    signal?: AbortSignal;
    dashboardDispatch: DashboardActionDispatcher;
}) {
    dashboardDispatch(dashboardActions.isLoading());
    const response = await dashboardService
        .deleteDashboardUpdates({
            updateDashboardId,
            signal,
        })
        .catch((err) => {
            return err;
        });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        dashboardDispatch(dashboardActions.hasErrors({ errors: [response.data.message] }));
        return Promise.reject(response.data);
    }
    const { dashboardUpdates } = response.data.dashboard;

    dashboardDispatch(dashboardActions.updateDashboardUpdate({ dashboardUpdates }));

    return response.data;
}
