import React, { FC, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import {
    DraggableWorksheetFormComponent,
    FormAnswerSelectedOption,
    WorksheetElementAnswers,
} from '@packages/models/api';

import { WorksheetFormElementComponent, WorksheetFormElements } from './worksheet-form-elements';
import { View, StyleSheet } from 'react-native';
import WorksheetLabelAndTooltip from './worksheet-form-element-label';

export interface WorksheetFormElementRenderProps {
    item: DraggableWorksheetFormComponent;
    readOnly?: boolean;
    preview?: boolean;
    onAnswerChange?: (answer: WorksheetElementAnswers) => void;
    handleDragEnd: (result: DropResult) => void;
    onEditClick?(item: DraggableWorksheetFormComponent): void;
    onDeleteClick?(item: DraggableWorksheetFormComponent): void;
    onCopyClick?(item: DraggableWorksheetFormComponent): void;
    onChildDrag?(item: DraggableWorksheetFormComponent): void;
    handleIsValid?: (valid: boolean) => void;
    handleAnswerChange?: (formelementId: string, selectedAnswer: FormAnswerSelectedOption[]) => void;
    getCurrentAnswerByElementId?: (id: string) => WorksheetElementAnswers;
}

interface WorksheetFormElementProps extends WorksheetFormElementRenderProps {}

export const BaseWorksheetFormElement: FC<WorksheetFormElementProps> = ({
    item,
    readOnly,
    preview,
    onAnswerChange,
    handleDragEnd,
    onEditClick,
    onChildDrag,
    onCopyClick,
    onDeleteClick,
    handleIsValid,
    handleAnswerChange,
    getCurrentAnswerByElementId,
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const { formElementTypeId, required, formElementTooltip, formElementLabel, formElementDescription } = item;
    const FormElementComponent: WorksheetFormElementComponent = WorksheetFormElements[item.formElementTypeId];
    if (!FormElementComponent) {
        return null;
    }

    return (
        <View style={styles.formSection}>
            <WorksheetLabelAndTooltip
                required={required}
                tooltip={formElementTooltip}
                formElementLabel={formElementLabel}
                preview={preview}
                formElementDescription={formElementDescription}
                setIsExpanded={setIsExpanded}
                isExpanded={isExpanded}
                formElementTypeId={formElementTypeId}
            />
            {isExpanded && (
                <FormElementComponent
                    item={item}
                    handleDragEnd={handleDragEnd}
                    onChildDrag={onChildDrag}
                    onCopyClick={onCopyClick}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    readOnly={readOnly}
                    preview={preview}
                    onAnswerChange={onAnswerChange}
                    handleIsValid={handleIsValid}
                    handleAnswerChange={handleAnswerChange}
                    getCurrentAnswerByElementId={getCurrentAnswerByElementId}
                    {...item}
                />
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    formSection: {
        paddingBottom: 24,
        zIndex: 'unset' as unknown as number,
    },
});
