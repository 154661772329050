import React, { useState } from 'react';
import { Platform, StyleSheet, TextInput, TextInputProps, TextStyle, View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';

import { noop } from '@packages/core/utils';

import { TextInputHelper } from './text-input-helper';
import { Button } from './button';

interface Props {
    comment: string;
    onChange(text: string): void;
    onAddComment(args: { text: string }): Promise<void>;
    onCancel?(): void;
    isLoading?: boolean;
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
    },
    btnContainer: {
        flexDirection: 'row',
        marginTop: 10,
        justifyContent: 'flex-end',
    },
    cancelBtn: {
        marginRight: 5,
    },
});

export const CommentInput = ({ comment, onAddComment, onChange, isLoading = false, onCancel = noop }: Props) => {
    const { t } = useTranslation();

    const handleOnChange = (text: string) => {
        onChange(text);
    };

    const handleOnCancel = () => {
        onChange('');
        onCancel();
    };

    const handleOnAddComment = async () => {
        await onAddComment({ text: comment });
        onChange('');
    };

    return (
        <View style={styles.container}>
            <TextInputHelper value={comment} onChangeText={handleOnChange} placeholder="Add Comment..." />

            <View style={styles.btnContainer}>
                <Button onPress={handleOnCancel} variant="ghost-gray" style={styles.cancelBtn}>
                    {t('qmr:sections.comments.newComment.actions.cancel', 'Cancel')}
                </Button>

                <Button variant="primary" isLoading={isLoading} disabled={!comment} onPress={handleOnAddComment}>
                    {t('qmr:sections.comments.newComment.actions.addComment', 'Comment')}
                </Button>
            </View>
        </View>
    );
};
