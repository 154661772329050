import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';

import RenderJson from '@web/components/render-json';

interface ErrorDisplayProps {
    error: any;
    showBackButton?: boolean;
    showRetryButton?: boolean;
    onRetryButtonClick?(): void;
}

const ErrorDisplay: FC<ErrorDisplayProps> = ({ error, showBackButton, showRetryButton, onRetryButtonClick }) => {
    const navigate = useNavigate();

    function handleGoBackButtonClick() {
        navigate(-1);
    }

    function handleRetryClick() {
        if (onRetryButtonClick) {
            onRetryButtonClick();
        }
    }

    return (
        <Container>
            <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                    <p className="text-center">{error.message}</p>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                    <div className="text-center">
                        {showBackButton && (
                            <Button onClick={handleGoBackButtonClick} className="mr-2">
                                Go Back
                            </Button>
                        )}
                        {showRetryButton && <Button onClick={handleRetryClick}>Retry</Button>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <RenderJson json={error} />
                </Col>
            </Row>
        </Container>
    );
};

export default ErrorDisplay;
