import React, { FC } from 'react';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';

import { FailCode, FailCodePart } from '@packages/models/api';
import { Typography } from '../shared';

interface Props {
    failCode: FailCode;
    failCodeSectionName?: string;
    failCodeSubsectionName?: string;
    possibleMatch?: boolean;
    matchingPart?: FailCodePart;
    isValidFailCode?: boolean;
}

export const FailCodeDetails: FC<Props> = ({
    failCode,
    failCodeSectionName,
    failCodeSubsectionName,
    possibleMatch,
    matchingPart,
    isValidFailCode,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {matchingPart && (
                <>
                    <Typography style={styles.label} variant="h6">
                        {t('failCode:labels.part', 'Part')}
                    </Typography>
                    <Typography style={styles.details} variant="lead">
                        #{matchingPart?.partNumber} - {matchingPart?.partDescription}
                    </Typography>
                </>
            )}

            <>
                <Typography style={styles.label} variant="h6">
                    {possibleMatch
                        ? t('failCode:labels.possibleFailCodeMatch', 'Possible Matching Fail Code')
                        : t('failCode:labels.failCode', 'Fail Code')}
                </Typography>
                <Typography style={failCodeSectionName ? styles.details : undefined} variant="lead">
                    {failCode?.failCode} - {failCode?.description || 'No Description'}
                </Typography>
                {isValidFailCode === false && (
                    <Typography color="redOne" style={failCodeSectionName ? styles.details : undefined} variant="lead">
                        This is not a recognized fail code. Please update it.
                    </Typography>
                )}
            </>

            {failCodeSectionName && (
                <>
                    <Typography style={styles.label} variant="h6">
                        {t('failCode:labels.section', 'Section')}
                    </Typography>
                    <Typography style={styles.details} variant="lead">
                        {failCodeSectionName}
                    </Typography>
                </>
            )}

            {failCodeSubsectionName && (
                <>
                    <Typography style={styles.label} variant="h6">
                        {t('failCode:.labels.subsection', 'Subsection')}
                    </Typography>
                    <Typography style={styles.details} variant="lead">
                        {failCodeSubsectionName}
                    </Typography>
                </>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    label: {
        marginBottom: 8,
    },
    details: {
        marginBottom: 24,
    },
});
