import React, { createContext, FC, useReducer } from 'react';

import {
    notificationsActions,
    initialState,
    NotificationsActionsDispatcher,
    notificationsReducer,
    NotificationsState,
} from './notifications.state';
import { NotificationsService } from './notifications.service';

export const NotificationsStateContext = createContext<NotificationsState | undefined>(undefined);
export const NotificationsDispatchContext = createContext<NotificationsActionsDispatcher | undefined>(undefined);

export const NotificationsProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(notificationsReducer, initialState);

    return (
        <NotificationsStateContext.Provider value={state}>
            <NotificationsDispatchContext.Provider value={dispatch}>{children}</NotificationsDispatchContext.Provider>
        </NotificationsStateContext.Provider>
    );
};

interface NotificationsDataAccess {
    notificationsService: NotificationsService;
    notificationsDispatch: NotificationsActionsDispatcher;
    ignoreCache?: boolean;
    signal?: AbortSignal;
}

export async function fetchNotificationsSummary({
    notificationsService,
    notificationsDispatch,
    signal,
    ignoreCache,
}: NotificationsDataAccess) {
    const response = await notificationsService.fetchNotificationsSummary(signal, ignoreCache);

    if (!response.success) {
        return Promise.reject(response.data);
    }
    notificationsDispatch(
        notificationsActions.updateNotificationSummary({
            notificationSummary: response.data,
        })
    );
    return response.data;
}
