const colors = {
    white: '#ffffff',
    black: '#071424',

    grayOne: '#f8f8f8',
    grayTwo: '#f3f3f3',
    grayThree: '#e3e3e3',
    grayFour: '#d8d8d8',
    grayFive: '#999da3',
    graySix: '#59616c',

    borderDark: '#999da3',
    borderLight: '#FFFFFF',

    textDarkPrimary: '#071424',
    textDarkSecondary: '#4C525D',
    textDarkTertiary: '#4C525D',

    textLightPrimary: '#FFFFFF',

    blueZero: '#001957',
    blueOne: '#1f518f',
    blueOneHover: '#436ca1',
    blueOneActive: '#1a4378',
    blueTwo: '#7997bc',
    blueThree: '#bccbde',
    blueFour: '#dee6ef',

    greenOne: '#02845e',
    greenOneHover: '#2a9777',
    greenOneActive: '#016e4e',
    greenTwo: '#68b69f',
    greenThree: '#b3dacf',
    greenFour: '#daede8',

    yellowOne: '#fbc02d',
    yellowTwo: '#fdda81',
    yellowThree: '#feecc0',
    yellowFour: '#fff6e0',

    redOne: '#de2622',
    redOneHover: '#e34845',
    redOneActive: '#ba1f1c',
    redTwo: '#ec7d7b',
    redThree: '#f6bebd',
    redFour: '#fbdfdf',

    brandPrimaryDisabled: '#E0E0E0',
    brandPrimaryDark: '#133866',
    brandRangeTrack: '#0AA3DC',

    brandSecondary: '#4c525d',
    brandSecondaryHover: '#686d77',
    brandSecondaryActive: '#3f444e',

    whiteOne: 'rgb(233, 233, 233)',
    whiteOneHover: 'rgb(210, 210, 210)',
} as const;

export default colors;
