import { useState, useMemo } from 'react';

export function useHoverState() {
    const [showHover, setShowHover] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    const hoverEventHandlers = useMemo(
        () => ({
            onMouseEnter: () => {
                setIsHovered(true);
            },
            onMouseLeave: () => {
                setIsHovered(false);
            },
            onResponderGrant: () => {
                setShowHover(false);
            },
            onResponderRelease: () => {
                setShowHover(true);
            },
        }),
        []
    );

    return {
        isHovered: showHover && isHovered,
        hoverEventHandlers,
    };
}
