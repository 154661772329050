import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { throttle } from 'lodash';

interface Props {
    children: any;
    setShow: (c: boolean) => void;
    show: boolean;
    buttonRef: React.RefObject<HTMLDivElement>;
}

const InputPickerWrapper: FC<Props> = ({ children, setShow, show, buttonRef }) => {
    const classes = useStyle({ show });
    const pickerRef = useRef<HTMLDivElement>(null);
    const portalContainerRef = useRef<HTMLDivElement | null>(null);
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    const throttledHandleResize = useRef(
        throttle(() => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }, 200)
    ).current;

    useEffect(() => {
        const handleResize = () => {
            throttledHandleResize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [throttledHandleResize]);

    useEffect(() => {
        if (!portalContainerRef.current) {
            portalContainerRef.current = document.createElement('div');
            document.body.appendChild(portalContainerRef.current);
        }

        return () => {
            if (portalContainerRef.current) {
                document.body.removeChild(portalContainerRef.current);
                portalContainerRef.current = null;
            }
        };
    }, []);
    useEffect(() => {
        if (pickerRef.current && show) {
            const picker = pickerRef.current;
            const positionValues = picker.getBoundingClientRect();
            const buttonIcon = buttonRef.current;
            const buttonPosition = buttonIcon?.getBoundingClientRect();
            const { innerHeight } = window;
            const shouldAtTop = buttonPosition && innerHeight - buttonPosition.bottom < picker.offsetHeight;

            const style: React.CSSProperties = {
                position: 'absolute',
                zIndex: 9999,
                top: shouldAtTop
                    ? `${buttonPosition.top - picker.offsetHeight + buttonPosition.height}px`
                    : `${buttonPosition?.top}px`,
            };

            if (buttonPosition) {
                style.left = `${buttonPosition.x - positionValues.width / 2 + buttonPosition.width}px`;
            }

            Object.assign(picker.style, style);
        }
    }, [pickerRef, show, buttonRef, windowSize]);

    if (!portalContainerRef.current) return null;

    return ReactDOM.createPortal(
        <>
            {show && (
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 2000,
                    }}
                    onClick={() => setShow(false)}
                ></div>
            )}
            <div ref={pickerRef} className={`${show ? 'd-flex' : 'd-none'} ${classes.mainContainer}`}>
                {children}
            </div>
        </>,
        portalContainerRef.current
    );
};

export default InputPickerWrapper;

const useStyle = createUseStyles({
    mainContainer: {
        height: 250,
        overflow: 'hidden',
        backgroundColor: colors.white,
        zIndex: 9999,
        position: 'absolute',
        top: 40,
        padding: 10,
        borderRadius: 5,
        border: '1px solid rgba(0, 0, 0, 0.18)',
        justifyContent: 'space-between',
        boxShadow: '1px 8px 12px 4px rgba(0, 0, 0, 0.16)',
        userSelect: 'none',
    },
});
