import React from 'react';
import { Icon, StsIconName, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { caseStatusMap as statusMap } from '@web/utils';

export enum TechlineSections {
    VehicleInformation,
    CaseDetails,
    DTCSSymptoms,
    FailCode,
    Worksheets,
    Attachments,
    Resolution,
}

const useStyles = createUseStyles({
    navContainer: {
        width: '248px',
        backgroundColor: colors.grayTwo,
    },
    sideNavHead: {
        backgroundColor: colors.grayTwo,
        height: '32px',
    },
    sideNavItem: {
        backgroundColor: colors.white,
        height: '48px',
        cursor: 'pointer',
    },
    sectionVerticalLine: {
        height: '12px',
    },
});

interface TechlineSideNavProps {
    scrollToSection: (section: TechlineSections) => void;
    currentSelectedSection: TechlineSections;
    caseDetails: any;
    isCaseDetailsValid?: boolean;
    isFailCodeValid?: boolean;
    assetsDetails: any;
    detailsEditedMap?: any;
    isWorksheetsValid: boolean;
}

export const TechlineSideNav = ({
    currentSelectedSection,
    scrollToSection,
    caseDetails,
    isCaseDetailsValid,
    isFailCodeValid,
    assetsDetails,
    detailsEditedMap,
    isWorksheetsValid,
}: TechlineSideNavProps) => {
    const classes = useStyles();
    const sections = Object.values(TechlineSections);

    const renderNavTitle = () => {
        return (
            <div className={`${classes.sideNavItem} w-100`}>
                <div className="d-flex align-items-center w-100">
                    <div className="ml-5 mt-3 mb-3 d-flex flex-row align-items-center">
                        <Icon size={24} name="file" color={'blueOne'} />
                        <div className="ml-2">
                            <Typography color="blueOne" variant="h6">
                                Report
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNavItem = (
        title: string,
        icon: StsIconName,
        iconColor: keyof typeof colors,
        showTopLink: boolean,
        showBottomLink: boolean,
        key: TechlineSections
    ) => {
        const isActive = currentSelectedSection === key;
        return (
            <div
                style={{
                    backgroundColor: isActive ? colors.blueFour : colors.white,
                }}
                className={`${classes.sideNavItem} w-100`}
                onClick={() => scrollToSection(key)}
            >
                <div className="d-flex align-items-center w-100">
                    <div className="ml-5 d-flex flex-row align-items-center">
                        <div className="d-flex flex-column align-items-center">
                            <div
                                style={{
                                    borderLeft: `1px solid ${
                                        showTopLink ? '#a7a2a2' : isActive ? colors.blueFour : colors.white
                                    }`,
                                }}
                                className={classes.sectionVerticalLine}
                            />
                            <Icon size={24} name={icon} color={iconColor} />
                            <div
                                style={{ borderLeft: `1px solid ${showBottomLink ? '#a7a2a2' : colors.white}` }}
                                className={classes.sectionVerticalLine}
                            />
                        </div>
                        <div className="ml-2">
                            <Typography>{title}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={`${classes.navContainer} h-100`}>
            <div className={`${classes.sideNavHead} w-100`}>
                <div className="d-flex align-items-center w-100 h-100">
                    <div className="ml-5">
                        <Typography color="textDarkSecondary" variant="caption2">
                            CASE SECTIONS
                        </Typography>
                    </div>
                </div>
            </div>
            {renderNavTitle()}
            {renderNavItem(
                'Vehicle information',
                'check-circle-hollow',
                'blueOne',
                false,
                true,
                TechlineSections.VehicleInformation
            )}
            {renderNavItem(
                'Case details & Vehicle concern',
                isCaseDetailsValid || !detailsEditedMap?.caseDetails ? 'check-circle-hollow' : 'warning',
                isCaseDetailsValid ? 'blueOne' : !detailsEditedMap?.caseDetails ? 'grayFour' : 'redOne',
                true,
                true,
                TechlineSections.CaseDetails
            )}
            {renderNavItem(
                'DTCs',
                'check-circle-hollow',
                caseDetails.diagnosticTroubleCodes?.length ? 'blueOne' : 'grayFour',
                true,
                true,
                TechlineSections.DTCSSymptoms
            )}
            {renderNavItem(
                'Fail code',
                isFailCodeValid || !detailsEditedMap?.failCode ? 'check-circle-hollow' : 'warning',
                isFailCodeValid ? 'blueOne' : !detailsEditedMap?.failCode ? 'grayFour' : 'redOne',
                true,
                true,
                TechlineSections.FailCode
            )}
            {renderNavItem(
                'Worksheets',
                isWorksheetsValid || !detailsEditedMap?.worksheets ? 'check-circle-hollow' : 'warning',
                isWorksheetsValid ? 'blueOne' : !detailsEditedMap?.worksheets ? 'grayFour' : 'redOne',
                true,
                true,
                TechlineSections.Worksheets
            )}
            {caseDetails && statusMap['CLOSED'].includes(caseDetails.techlineStatusCode) ? (
                <div>
                    {renderNavItem(
                        'Attachments',
                        assetsDetails?.length || !detailsEditedMap?.attachments ? 'check-circle-hollow' : 'x-circle',
                        !detailsEditedMap?.attachments ? 'grayFour' : assetsDetails?.length ? 'blueOne' : 'grayFive',
                        true,
                        true,
                        TechlineSections.Attachments
                    )}
                    {renderNavItem(
                        'Resolution',
                        'check-circle-hollow',
                        'blueOne',
                        true,
                        false,
                        TechlineSections.Resolution
                    )}
                </div>
            ) : (
                renderNavItem(
                    'Attachments',
                    assetsDetails?.length || !detailsEditedMap?.attachments ? 'check-circle-hollow' : 'x-circle',
                    assetsDetails?.length ? 'blueOne' : 'grayFour',
                    true,
                    false,
                    TechlineSections.Attachments
                )
            )}
        </div>
    );
};
