import { useAbortController } from '@packages/core/http';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { investigationService } from '@web/services/singletons';
import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

const useConfirmDeleteCommentModalStyles = createUseStyles({
    confirmDeleteComment: {
        width: 558,
        maxWidth: 'none',
    },
});

interface ConfirmDeleteCommentModalProps {
    show: boolean;
    investigationId: string;
    investigationCommentId: string;
    onHide: (deleteSuccess?: boolean) => void;
}

const ConfirmDeleteCommentModal: FC<ConfirmDeleteCommentModalProps> = ({
    show,
    investigationId,
    investigationCommentId,
    onHide,
}) => {
    const classes = useConfirmDeleteCommentModalStyles();
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();

    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <Modal show={show} dialogClassName={classes.confirmDeleteComment} onHide={onHide} centered>
            <Modal.Header closeButton placeholder={''}>
                <Typography variant="h4">{t('modals:deleteComment.title', 'Delete Comment?')}</Typography>
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex">
                    <Icon name="warning" />
                    <div className="d-flex flex-column ml-3">
                        <Typography variant="h5" style={{ marginBottom: 10 }}>
                            {t('modals:deleteComment.message', 'Are you sure you want to delete this comment?')}
                        </Typography>
                        <Typography variant="lead">
                            {t('modals:deleteComment.hint', 'Deleting comments cannot be undone.')}
                        </Typography>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    title={t('modals:deleteComment.cancel', 'Cancel')}
                    variant="ghost-gray"
                    onPress={() => onHide(false)}
                />
                <Button
                    isLoading={isDeleting}
                    title={t('modals:deleteComment.confirm', 'Delete')}
                    variant="danger"
                    onPress={() => {
                        if (isDeleting) {
                            return;
                        }

                        setIsDeleting(true);
                        investigationService
                            .deleteInvestigationComment({
                                investigationId,
                                investigationCommentId,
                                signal: abortSignalRef.current,
                            })
                            .then((response) => {
                                if (!response.success && response.aborted) {
                                    return;
                                } else if (!response.success) {
                                    throw response.data;
                                }
                                setIsDeleting(false);
                                onHide(true);
                            })
                            .catch((e) => {
                                setIsDeleting(false);
                                alert(e.message);
                            });
                    }}
                    style={{ marginLeft: 20 }}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeleteCommentModal;
