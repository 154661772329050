import React, { useCallback, useState } from 'react';
import { Icon, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

export const Resolution = ({ caseDetails }: { caseDetails: any }) => {
    return (
        <div>
            <hr className="mt-5 mb-5" color={colors.grayThree} />
            <div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <Typography variant="h3">Resolution</Typography>
                </div>
                <div className="mt-5">
                    <div>
                        <Typography>
                            Closed By : {caseDetails?.closedByTechnician?.userName || 'Case not closed by technician'}
                        </Typography>
                    </div>
                    <div>
                        <Typography>Date : {caseDetails?.techLineCaseClosureDate || '--'}</Typography>
                    </div>
                    <div>
                        <Typography>
                            Was the vehicle repaired ? :{' '}
                            {(
                                caseDetails.wasTheVehicleRepaired
                                    ? JSON.parse(caseDetails.wasTheVehicleRepaired)
                                    : caseDetails.wasTheVehicleRepaired
                            )
                                ? 'Yes'
                                : 'No'}
                        </Typography>
                    </div>
                    <div>
                        <Typography>
                            Description :{' '}
                            {caseDetails?.techShareCaseClosureDescription || 'No closure description was provided.'}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};
