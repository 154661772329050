import { cloneDeep } from 'lodash';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

import { Button, Icon, Toggle, Typography } from '@packages/ui/shared';
import Select from '@web/components/select';

import {
    ConditionalElement,
    draggableFormElements,
    DraggableWorksheetFormComponent,
    FormElement,
    FormElementTypeId,
} from '@packages/models/api';
import {
    FormEditLikert,
    FormEditTextInput,
    FormEditLikertLongText,
    FormEditTextStatic,
} from '@web/administration/components/worksheet';
import { FormEditWorksheetSelectList } from '@web/administration/components/worksheet';
import { TouchableOpacity } from 'react-native';
import { Popover } from 'react-tiny-popover';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { WorksheetAddFieldEnum } from '../../views/worksheets/types';
import { saveFormElement, useFormElementModalDispatch } from '@packages/contexts/form-element-modal';
import { FormEditFile } from './form-edit-file';
import { FormEditDate } from './form-edit-date';
import { FormEditTime } from './form-edit-time';
import uuid from 'uuid';
import useTranslationData from './hooks/useTranslationData';
import FormEditDateTime from './form-edit-dateTime';
import { isConditionalElementType } from '@web/administration/views/worksheets/utils';

interface FormComponentEditModalPorps extends ModalProps {
    formComponent: DraggableWorksheetFormComponent | undefined;
    onSave(formComponent: DraggableWorksheetFormComponent): void;
}

const useNewWorksheetFormStyles = createUseStyles({
    actionsPopover: {
        minWidth: 113,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
        zIndex: 1060,
    },
    popoverActionsSection: {
        padding: '4px 0',
    },
});
export type AddFieldType = {
    [x in WorksheetAddFieldEnum]: { label: string; check: boolean };
};
const addField: AddFieldType = {
    [WorksheetAddFieldEnum.Description]: { label: 'Add Description', check: false },
    [WorksheetAddFieldEnum.ToolTips]: { label: 'Add Tooltip', check: false },
    [WorksheetAddFieldEnum.Condition]: { label: 'Add Conditional', check: false },
};
export const WorksheetFormComponentEditModal: FC<FormComponentEditModalPorps> = ({
    formComponent,
    onSave,
    show,
    onHide,
}) => {
    const classes = useNewWorksheetFormStyles();
    const [formComponentClone, setFromComponentClone] = useState<
        DraggableWorksheetFormComponent | ConditionalElement
    >();
    const [questionTypeSelectValue, setQuestionTypeSelectValue] = useState('');
    const [showAddField, setShowAddField] = useState(false);
    const [selectedAddField, setSelectedAddField] = useState(addField);
    const [required, setRequired] = useState(false);
    const formElementDispatch = useFormElementModalDispatch();
    const [isValid, setIsValid] = useState(true);
    const { draggableElementsDataTranslate } = useTranslationData();
    useEffect(() => {
        resetCheckBoxAddField();
        setShowAddField(false);
        setRequired(false);
        if (formComponent) {
            setFromComponentClone(cloneDeep(formComponent));
            selectedAddField[WorksheetAddFieldEnum.ToolTips].check = !!formComponent.formElementTooltip;
            selectedAddField[WorksheetAddFieldEnum.Description].check = !!formComponent.formElementDescription;
            selectedAddField[WorksheetAddFieldEnum.Condition].check =
                !!formComponent.formElementHasRule || !!formComponent?.optionConditionsElements?.length;
            setQuestionTypeSelectValue(formComponent.formElementTypeId);
            setRequired(formComponent.required);
        } else {
            setFromComponentClone(undefined);
            setQuestionTypeSelectValue('');
        }
    }, [formComponent]);

    useEffect(() => {
        if (!formComponentClone) {
            return;
        }
        const formComponentDto: FormElement = {
            ...formComponentClone,
        };
        saveFormElement({ formElement: formComponentDto, formElementDispatch });
    }, [formComponent]);

    const handleRequiredToggleButton = useCallback((checked: boolean) => {
        setRequired(checked);
    }, []);

    const handleSaveButtonClick = useCallback(() => {
        if (!formComponentClone) {
            return;
        }
        const formComponentDto: FormElement = {
            ...formComponentClone,
        };
        saveFormElement({ formElement: formComponentDto, formElementDispatch });

        // on unchecked add field
        Object.keys(selectedAddField).forEach((field, index: number) => {
            if (!selectedAddField[field as WorksheetAddFieldEnum].check) {
                if (field === WorksheetAddFieldEnum.Description) formComponentClone.formElementDescription = '';
                if (field === WorksheetAddFieldEnum.ToolTips) formComponentClone.formElementTooltip = '';
                if (field === WorksheetAddFieldEnum.Condition) {
                    formComponentClone.formElementHasRule = false;
                    formComponentClone.optionConditionsElements = [];
                    formComponentClone.options = formComponentClone.options.map((option) => {
                        if (option.trgtElementIdList) {
                            delete option?.trgtElementIdList;
                        }
                        return option;
                    });
                }
            }
        });

        onSave({ ...formComponentClone, required: required });
    }, [formComponentClone, formElementDispatch, onSave, required, selectedAddField]);

    const resetCheckBoxAddField = useCallback(() => {
        selectedAddField[WorksheetAddFieldEnum.Description].check = false;
        selectedAddField[WorksheetAddFieldEnum.ToolTips].check = false;
        selectedAddField[WorksheetAddFieldEnum.Condition].check = false;
    }, [selectedAddField]);

    const handleQuestionTypeSelectChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            if (!formComponentClone) {
                return;
            }
            const elementType = event.currentTarget.value as FormElementTypeId;

            const updatedFormComponent = Object.assign(cloneDeep(formComponentClone), {
                formElementTypeId: elementType,
            });
            const jsonComponent = draggableFormElements[elementType];
            updatedFormComponent.description = jsonComponent.description;
            updatedFormComponent.iconName = jsonComponent.iconName;
            updatedFormComponent.title = jsonComponent.title;

            // Resetting condition checkbox selection and remove target element condition ids list if old element is for RadioButton group
            if (isConditionalElementType(formComponentClone.formElementTypeId)) {
                updatedFormComponent.formElementHasRule = false;
                selectedAddField[WorksheetAddFieldEnum.Condition].check = false;
                updatedFormComponent.options = updatedFormComponent.options.map((option) => {
                    option.trgtElementIdList = [];
                    return option;
                });
            }

            // If element type is TEXT-HEADER or TEXT PARAGRAPH then it's does not have option so set empty array for that
            if ([FormElementTypeId.TextHeader, FormElementTypeId.TextParagraph].includes(elementType)) {
                updatedFormComponent.options = [];
            }
            //if user select date or time so option value must be empty string
            else if (
                [FormElementTypeId.Date, FormElementTypeId.Time, FormElementTypeId.DateTime].includes(elementType)
            ) {
                updatedFormComponent.options = jsonComponent.options.map((option) => {
                    option.optionId = uuid();
                    option.optionValue = '';
                    option.optionLabel = '';
                    return option;
                });
            }
            // If user selected any element type which having options and length is ZERO or element type is RATING | RATING WITH TEXT then set default options from json template object
            else if (
                updatedFormComponent.options?.length === 0 ||
                [FormElementTypeId.Rating, FormElementTypeId.RatingWithText].includes(elementType)
            ) {
                updatedFormComponent.options = jsonComponent.options.map((option, index) => {
                    option.optionId = uuid();
                    option.optionValue = (index + 1).toString();
                    return option;
                });
            }

            setQuestionTypeSelectValue(event.currentTarget.value);
            setFromComponentClone(updatedFormComponent);
        },
        [formComponentClone, selectedAddField]
    );

    const handleFormComponentChange = useCallback(
        (newProps: Partial<DraggableWorksheetFormComponent>) => {
            if (!formComponentClone) {
                return;
            }

            const updatedFormComponent = Object.assign(cloneDeep(formComponentClone), newProps);
            setFromComponentClone(updatedFormComponent);
        },
        [formComponentClone, setFromComponentClone]
    );

    const handleAddFieldChange = useCallback(
        (field: WorksheetAddFieldEnum) => {
            const selected = { ...selectedAddField };
            selected[field].check = !selected[field].check;
            setSelectedAddField(selected);
        },
        [selectedAddField]
    );

    return (
        <Modal show={show} centered={true} onHide={onHide} size="lg">
            <Modal.Header style={{ zIndex: 5 }} placeholder={''}>
                <div className="mb-2 d-flex justify-content-between">
                    <div className="mb-2 d-flex ">
                        <div>
                            <Select
                                value={questionTypeSelectValue}
                                options={Object.values(draggableFormElements).map((formElement, index) => {
                                    return {
                                        title: ` ${formElement.title}`,
                                        value: formElement.formElementTypeId,
                                    };
                                })}
                                onChange={handleQuestionTypeSelectChange}
                            />
                        </div>
                        <div className="d-flex align-item-center">
                            <div className="m-2">
                                <Typography variant="label">
                                    {draggableElementsDataTranslate.labels.required}
                                </Typography>
                            </div>
                            <Toggle checked={required} onChange={handleRequiredToggleButton}></Toggle>
                        </div>
                    </div>

                    <div>
                        <Popover
                            isOpen={showAddField}
                            positions={['bottom', 'top']}
                            onClickOutside={() => {
                                setShowAddField(false);
                            }}
                            contentLocation={({ childRect, popoverRect, position }) => {
                                // Calculate position of popover based on window height and client position and based on calculation show the popover at top or bottom position
                                if (childRect.y + childRect.height + popoverRect.height > window.innerHeight) {
                                    return {
                                        top: childRect.top - 4 - popoverRect.height,
                                        left: childRect.left - (popoverRect.width - childRect.width),
                                    };
                                } else {
                                    return {
                                        top: childRect.bottom + 4,
                                        left: childRect.left - (popoverRect.width - childRect.width),
                                    };
                                }
                            }}
                            containerClassName={classes.actionsPopover}
                            content={
                                <div className={classes.popoverActionsSection}>
                                    {Object.keys(selectedAddField).map((field) => {
                                        if (
                                            (!(
                                                formComponent?.formElementTypeId === FormElementTypeId.Radio ||
                                                formComponent?.formElementTypeId === FormElementTypeId.Checkbox
                                            ) ||
                                                'targetOptionId' in (formComponent || {})) &&
                                            field === WorksheetAddFieldEnum.Condition
                                        ) {
                                            return <Fragment key={field}></Fragment>;
                                        } else {
                                            return (
                                                <Button
                                                    key={field}
                                                    variant="ghost-gray"
                                                    onPress={() => handleAddFieldChange(field as WorksheetAddFieldEnum)}
                                                    iconLeft={
                                                        <Icon
                                                            color="blueOne"
                                                            name={
                                                                selectedAddField[field as WorksheetAddFieldEnum].check
                                                                    ? 'checkbox-marked'
                                                                    : 'checkbox-unmarked'
                                                            }
                                                        />
                                                    }
                                                >
                                                    <Typography style={{ width: '100%' }}>
                                                        {selectedAddField[field as WorksheetAddFieldEnum].label}
                                                    </Typography>
                                                </Button>
                                            );
                                        }
                                    })}
                                </div>
                            }
                        >
                            <div style={{ zIndex: 20 }}>
                                <TouchableOpacity
                                    onPress={() => {
                                        setShowAddField(!showAddField);
                                    }}
                                >
                                    <Icon
                                        size={30}
                                        name="more-dots-vertical"
                                        color="blueOne"
                                        raisedContainerStyle={{ width: 22, height: 22 }}
                                    />
                                </TouchableOpacity>
                            </div>
                        </Popover>
                    </div>
                </div>
            </Modal.Header>

            <hr />

            <Modal.Body>
                {formComponentClone?.formElementTypeId === FormElementTypeId.TextHeader && (
                    <FormEditTextStatic
                        onChange={handleFormComponentChange}
                        setIsValid={setIsValid}
                        {...formComponentClone}
                        addField={selectedAddField}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.TextParagraph && (
                    <FormEditTextStatic
                        setIsValid={setIsValid}
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.InputText && (
                    <FormEditTextInput
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.InputTextarea && (
                    <FormEditTextInput
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Rating && (
                    <FormEditLikert
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.RatingWithText && (
                    <FormEditLikertLongText
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Checkbox && (
                    <FormEditWorksheetSelectList
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        formElement={formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Radio && (
                    <FormEditWorksheetSelectList
                        onChange={handleFormComponentChange}
                        formElement={formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Dropdown && (
                    <FormEditWorksheetSelectList
                        onChange={handleFormComponentChange}
                        formElement={formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.File && (
                    <FormEditFile
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Date && (
                    <FormEditDate
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Time && (
                    <FormEditTime
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.DateTime && (
                    <FormEditDateTime
                        onChange={handleFormComponentChange}
                        {...formComponentClone}
                        addField={selectedAddField}
                        setIsValid={setIsValid}
                    />
                )}
                <hr color={colors.grayTwo} style={{ width: 1 }} />
            </Modal.Body>

            <Modal.Footer>
                <div className="mr-2">
                    <Button variant="ghost-blue" onPress={onHide}>
                        {draggableElementsDataTranslate.buttons.cancel}
                    </Button>
                </div>
                <Button variant="primary" onPress={handleSaveButtonClick} disabled={!isValid}>
                    {draggableElementsDataTranslate.buttons.save}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
