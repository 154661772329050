import React, { FC } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { LoadingCircle } from '@packages/ui/shared';

const useLoaderButtonStyles = createUseStyles({
    loaderButton: {
        position: 'relative',
    },
    childrenOuter: ({ isLoading }: { isLoading: boolean }) => ({
        opacity: isLoading ? 0 : 1,
    }),
});

interface LoaderButtonProps extends ButtonProps {
    isLoading: boolean;
    onClick?(event: React.MouseEvent): void;
}

const LoaderButton: FC<LoaderButtonProps> = ({ isLoading, onClick, children, ...props }) => {
    const classes = useLoaderButtonStyles({
        isLoading,
    });

    function handleClick(event: React.MouseEvent) {
        if (!onClick) {
            return;
        }

        if (isLoading) {
            return;
        }

        onClick(event);
    }

    return (
        <Button {...props} className={classes.loaderButton} onClick={handleClick}>
            {isLoading && (
                <LoadingCircle
                    size={24}
                    style={{ top: '50%', left: '50%', marginTop: -12, marginLeft: -12, position: 'absolute' }}
                />
            )}
            <div className={classes.childrenOuter}>{children}</div>
        </Button>
    );
};

export default LoaderButton;
