import React, { FC, useRef, useEffect } from 'react';
import { View, Text, StyleSheet, StyleProp, TextStyle, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Typography } from './typography';

export interface LabelProps {
    required?: boolean;
    optional?: boolean;
    charCount?: string;
    hint?: string;
    customRightElement?: JSX.Element;
    style?: StyleProp<TextStyle>;
    controlId?: string;
    children?: React.ReactNode;
}

export const Label: FC<LabelProps> = ({
    children,
    required,
    optional,
    charCount,
    hint,
    style,
    controlId,
    customRightElement,
}) => {
    const { t } = useTranslation();
    const labelRef = useRef<Text>(null);

    useEffect(() => {
        labelRef.current && controlId && labelRef.current.setNativeProps({ for: controlId });
    }, [controlId]);

    return (
        <View style={styles.labelWrapper}>
            <View>
                <Typography
                    ref={labelRef}
                    variant="label"
                    //@ts-ignore
                    accessibilityRole={Platform.select({ web: controlId && 'label', default: 'text' })}
                    style={style}
                >
                    {children}
                    {required && <Typography color="redOne"> *</Typography>}
                    {optional && (
                        <Typography variant="caption" color="graySix">
                            {' '}
                            ({t('text:optional', 'optional')})
                        </Typography>
                    )}
                </Typography>

                {hint && (
                    <Typography variant="caption" color="textDarkSecondary" style={styles.hint}>
                        {hint}
                    </Typography>
                )}
            </View>

            {customRightElement
                ? customRightElement
                : charCount && (
                      <Typography variant="caption" color="graySix" style={styles.charCount}>
                          {charCount}
                      </Typography>
                  )}
        </View>
    );
};

const styles = StyleSheet.create({
    labelWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
    },
    hint: {
        marginTop: 4,
    },
    charCount: {
        alignSelf: 'flex-end',
    },
});
