import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Label, TextareaHelper, TextInputHelper, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { Form } from 'react-bootstrap';
import { format } from 'date-fns';
import { UrfcaInfoPopup } from './urfca-info.popup';
import ConfirmInjuryEwdModal from './confirm-injury-ewd-modal';
import ConfirmDeathEwdModal from './confirm-death-ewd-modal';

const useStyles = createUseStyles({
    container: {
        borderTop: `1px solid ${colors.grayFour}`,
    },
    flexOne: {
        flex: 1,
    },
});

const dataValidation = {
    repairOrderNumber: 100,
    maxMileage: 100000000000,
    customerConcernLength: 2000,
    technicianConcernLength: 2000,
    previousOrderNumber: 100,
    previousMileageMax: 100000000000,
    stepsLength: 2000,
    previousDiagnoisisLength: 2000,
    conditionChangeLength: 2000,
};

export const CaseDetailsInput = ({
    updateCaseDetails,
    caseDetails,
    issueFeildCaseDetails,
}: {
    updateCaseDetails: any;
    caseDetails: any;
    issueFeildCaseDetails?: any;
}) => {
    const classes = useStyles();

    const [showUrfca, setShowUrfca] = useState(false);
    const [didShowUrfca, setDidShowUrfca] = useState(false);
    const [showConfirmInjuryEwdModal, setShowConfirmInjuryEwdModal] = useState<boolean>(false);
    const [showConfirmDeathEwdModal, setShowConfirmDeathEwdModal] = useState<boolean>(false);

    const [repairOrderNo, setRepairOrderNo] = useState(caseDetails.repairOrderNumber);
    const [repairOrderDate, setRepairOrderDate] = useState(
        caseDetails?.repairOrderOpenDate ? caseDetails?.repairOrderOpenDate.split('T')[0] : ''
    );
    const [mileage, setMileage] = useState(caseDetails.mileage || '');
    const [concernDesc, setConcernDesc] = useState(caseDetails.customerConcern);
    const [concernTechnicianDesc, setConcernTechnicianDesc] = useState(caseDetails.technicianDescription);
    const [canDuplicateConcern, setCanDuplicateConcern] = useState(
        caseDetails.hasBeenAbleToDuplicate
            ? JSON.parse(caseDetails.hasBeenAbleToDuplicate)
            : caseDetails.hasBeenAbleToDuplicate
    );
    const [stepsToDuplicate, setStepsToDuplicate] = useState(caseDetails.technician5StepsBeforeFailureDuplication);
    const [concernBefore, setConcernBefore] = useState(
        caseDetails.vehicleHasBeenInBefore
            ? JSON.parse(caseDetails.vehicleHasBeenInBefore)
            : caseDetails.vehicleHasBeenInBefore
    );
    const [previousReportOrderNumber, setPreviousReportOrderNumber] = useState(caseDetails.previousRONumber);
    const [previousMileage, setPreviousMileage] = useState(caseDetails.previousMileage);
    const [partsReplaced, setPartsReplaced] = useState(
        caseDetails.werePartsReplaced ? JSON.parse(caseDetails.werePartsReplaced) : caseDetails.werePartsReplaced
    );
    const [replacedParts, setReplacedParts] = useState(caseDetails.whatPartsWereReplaced);
    const [previousDiagnosis, setPreviousDiagnosis] = useState(caseDetails.previousDiagnosisAndRepairs);
    const [conditionImproveNoticeable, setConditionImproveNoticeable] = useState(caseDetails.didTheConditionImprove);
    const [customerReport, setCustomerReport] = useState(
        caseDetails.rolloverFireAccidentInjuryDeathDamage
            ? JSON.parse(caseDetails.rolloverFireAccidentInjuryDeathDamage)
            : caseDetails.rolloverFireAccidentInjuryDeathDamage
    );
    const [multiSelection, setMultiSelection] = useState({
        rollover: caseDetails.rolloverFlag ? JSON.parse(caseDetails.rolloverFlag) : caseDetails.rolloverFlag,
        fire: caseDetails.fireFlag ? JSON.parse(caseDetails.fireFlag) : caseDetails.fireFlag,
        accident: caseDetails.accidentFlag ? JSON.parse(caseDetails.accidentFlag) : caseDetails.accidentFlag,
        injury: caseDetails.injuryFlag ? JSON.parse(caseDetails.injuryFlag) : caseDetails.injuryFlag,
        death: caseDetails.deathFlag ? JSON.parse(caseDetails.deathFlag) : caseDetails.deathFlag,
        propertydamage: caseDetails.propertyDamage
            ? JSON.parse(caseDetails.propertyDamage)
            : caseDetails.propertyDamage,
        airbag: caseDetails.airbagRuptureFlag
            ? JSON.parse(caseDetails.airbagRuptureFlag)
            : caseDetails.airbagRuptureFlag,
        injuryCount: caseDetails.injuryNumberOfPeople || '' || 0,
        deathCount: caseDetails.deathNumberOfPeople || '' || 0,
    });
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    const updateLocalCaseDetails = useCallback(() => {
        setRepairOrderNo(caseDetails.repairOrderNumber);
        setRepairOrderDate(caseDetails?.repairOrderOpenDate ? caseDetails?.repairOrderOpenDate.split('T')[0] : '');
        setMileage(caseDetails.mileage || '');
        setConcernDesc(caseDetails.customerConcern);
        setConcernTechnicianDesc(caseDetails.technicianDescription);
        setCanDuplicateConcern(
            caseDetails.hasBeenAbleToDuplicate
                ? JSON.parse(caseDetails.hasBeenAbleToDuplicate)
                : caseDetails.hasBeenAbleToDuplicate
        );
        setStepsToDuplicate(caseDetails.technician5StepsBeforeFailureDuplication);
        setConcernBefore(
            caseDetails.vehicleHasBeenInBefore
                ? JSON.parse(caseDetails.vehicleHasBeenInBefore)
                : caseDetails.vehicleHasBeenInBefore
        );
        setPreviousReportOrderNumber(caseDetails.previousRONumber);
        setPreviousMileage(caseDetails.previousMileage);
        setPartsReplaced(
            caseDetails.werePartsReplaced ? JSON.parse(caseDetails.werePartsReplaced) : caseDetails.werePartsReplaced
        );
        setReplacedParts(caseDetails.whatPartsWereReplaced);
        setPreviousDiagnosis(caseDetails.previousDiagnosisAndRepairs);
        setConditionImproveNoticeable(caseDetails.didTheConditionImprove);
        setCustomerReport(
            caseDetails.rolloverFireAccidentInjuryDeathDamage
                ? JSON.parse(caseDetails.rolloverFireAccidentInjuryDeathDamage)
                : caseDetails.rolloverFireAccidentInjuryDeathDamage
        );
        setMultiSelection({
            rollover: caseDetails.rolloverFlag ? JSON.parse(caseDetails.rolloverFlag) : caseDetails.rolloverFlag,
            fire: caseDetails.fireFlag ? JSON.parse(caseDetails.fireFlag) : caseDetails.fireFlag,
            accident: caseDetails.accidentFlag ? JSON.parse(caseDetails.accidentFlag) : caseDetails.accidentFlag,
            injury: caseDetails.injuryFlag ? JSON.parse(caseDetails.injuryFlag) : caseDetails.injuryFlag,
            death: caseDetails.deathFlag ? JSON.parse(caseDetails.deathFlag) : caseDetails.deathFlag,
            propertydamage: caseDetails.propertyDamage
                ? JSON.parse(caseDetails.propertyDamage)
                : caseDetails.propertyDamage,
            airbag: caseDetails.airbagRuptureFlag
                ? JSON.parse(caseDetails.airbagRuptureFlag)
                : caseDetails.airbagRuptureFlag,
            injuryCount: caseDetails.injuryNumberOfPeople || '',
            deathCount: caseDetails.deathNumberOfPeople || '',
        });
    }, [caseDetails]);

    useEffect(() => {
        updateLocalCaseDetails();
    }, [caseDetails, updateLocalCaseDetails]);

    const handleConfirmInjuryEwdModalHide = () => {
        setMultiSelection({
            ...multiSelection,
            injury: false,
            injuryCount: 0,
        });
        setShowConfirmInjuryEwdModal(false);
        updateCaseDetails({ injuryNumberOfPeople: 0, injuryFlag: false });
    };

    const handleConfirmInjuryModalConfirmButtonClick = (numberOfPeopleInjured: number) => {
        setMultiSelection({
            ...multiSelection,
            injury: numberOfPeopleInjured > 0,
            injuryCount: numberOfPeopleInjured,
        });
        setShowConfirmInjuryEwdModal(false);
        updateCaseDetails({
            injuryNumberOfPeople: numberOfPeopleInjured > 0 ? numberOfPeopleInjured : 0,
            injuryFlag: numberOfPeopleInjured > 0,
        });
    };

    const handleConfirmDeathEwdModalHide = () => {
        setMultiSelection({
            ...multiSelection,
            death: false,
            deathCount: 0,
        });
        setShowConfirmDeathEwdModal(false);
        updateCaseDetails({ deathNumberOfPeople: 0, deathFlag: false });
    };

    const handleConfirmDeathModalConfirmButtonClick = (numberOfPeopleKilled: number) => {
        setMultiSelection({
            ...multiSelection,
            death: numberOfPeopleKilled > 0,
            deathCount: numberOfPeopleKilled,
        });
        setShowConfirmDeathEwdModal(false);
        updateCaseDetails({
            deathNumberOfPeople: numberOfPeopleKilled > 0 ? numberOfPeopleKilled : 0,
            deathFlag: numberOfPeopleKilled > 0,
        });
    };

    return (
        <div className={`${classes.container}`}>
            <UrfcaInfoPopup
                show={showUrfca}
                onOkPress={() => setShowUrfca(false)}
                onModalHide={() => setShowUrfca(false)}
            />
            <ConfirmInjuryEwdModal
                show={showConfirmInjuryEwdModal}
                onHide={handleConfirmInjuryEwdModalHide}
                onConfirmButtonClick={handleConfirmInjuryModalConfirmButtonClick}
                countInputValue={'' + (multiSelection.injuryCount || '')}
            />
            <ConfirmDeathEwdModal
                show={showConfirmDeathEwdModal}
                onHide={handleConfirmDeathEwdModalHide}
                onConfirmButtonClick={handleConfirmDeathModalConfirmButtonClick}
                countInputValue={'' + (multiSelection.deathCount || '')}
            />
            <div className="d-flex flex-column mt-5">
                <Typography variant="h3">Case details & Vehicle concern</Typography>
                <div className="mt-3 d-flex flex-row">
                    <div className={`${classes.flexOne} mr-10`}>
                        <TextInputHelper
                            required
                            label={'Repair order number'}
                            placeholder={'Enter Repair order number'}
                            value={repairOrderNo}
                            onChangeText={setRepairOrderNo}
                            onBlur={() => {
                                updateCaseDetails({ repairOrderNumber: repairOrderNo });
                                setShowErrorMsg(true);
                            }}
                            errorMessage={
                                showErrorMsg && issueFeildCaseDetails === 'repairOrderNumber'
                                    ? 'Repair order number is required'
                                    : ''
                            }
                            maxLength={dataValidation.repairOrderNumber}
                        />
                    </div>
                    <div className={`${classes.flexOne}`}>
                        <TextInputHelper
                            required
                            label={'Repair Order Date'}
                            placeholder={'Enter Repair Order Date'}
                            value={repairOrderDate}
                            onChangeText={setRepairOrderDate}
                            onBlur={() => {
                                updateCaseDetails({ repairOrderOpenDate: repairOrderDate });
                                setShowErrorMsg(true);
                            }}
                            type="date"
                            maxDate={format(new Date(), 'yyyy-LL-dd')}
                            minDate="1753-01-01"
                            errorMessage={
                                showErrorMsg && issueFeildCaseDetails === 'repairOrderOpenDate'
                                    ? 'Repair Order Date is required, Provide valid date'
                                    : ''
                            }
                        />
                    </div>
                </div>
                <div className="w-50 mt-3">
                    <TextInputHelper
                        required
                        label={'Mileage:'}
                        placeholder={'Enter Mileage:'}
                        value={mileage}
                        onChangeText={(text: string) => {
                            // Only allow numeric numbers to be inputted (since mileage)
                            const numericText = text.replace(/[^0-9]/g, '');
                            const numericValue = parseInt(text, 10);

                            if (
                                numericText == '' ||
                                (!isNaN(numericValue) && numericValue <= dataValidation.maxMileage)
                            ) {
                                setMileage(numericText);
                            }
                        }}
                        onBlur={() => {
                            const numericValue = mileage.replace(/^0+/, '');
                            setMileage(numericValue);
                            updateCaseDetails({ mileage: numericValue });
                            setShowErrorMsg(true);
                        }}
                        errorMessage={showErrorMsg && issueFeildCaseDetails === 'mileage' ? 'Mileage is required' : ''}
                    />
                </div>
                <div className="mt-3">
                    <TextareaHelper
                        editable={true}
                        value={concernDesc}
                        label={"Customer's concern description"}
                        required
                        placeholder={"Customer's concern description"}
                        errorMessage={
                            showErrorMsg && issueFeildCaseDetails === 'customerConcern'
                                ? "Customer's concern is required"
                                : ''
                        }
                        onChangeText={setConcernDesc}
                        onBlur={() => {
                            updateCaseDetails({ customerConcern: concernDesc });
                            setShowErrorMsg(true);
                        }}
                        maxLength={dataValidation.customerConcernLength}
                    />
                    <p style={{ fontSize: 'smaller', color: 'gray' }}>
                        Max character length: {dataValidation.customerConcernLength}
                    </p>
                </div>
                <div className="mt-3">
                    <TextareaHelper
                        editable={true}
                        value={concernTechnicianDesc}
                        label={"Technician's condition description"}
                        required
                        placeholder={"Technician's condition description"}
                        errorMessage={
                            showErrorMsg && issueFeildCaseDetails === 'technicianDescription'
                                ? "Technician's condition description is required"
                                : ''
                        }
                        onChangeText={setConcernTechnicianDesc}
                        onBlur={() => {
                            updateCaseDetails({ technicianDescription: concernTechnicianDesc });
                            setShowErrorMsg(true);
                        }}
                        maxLength={dataValidation.technicianConcernLength}
                    />
                    <p style={{ fontSize: 'smaller', color: 'gray' }}>
                        Max character length: {dataValidation.technicianConcernLength}
                    </p>
                </div>
                <Form.Group className="mt-3">
                    <div className="mb-3">
                        <Label required>Have you been able to duplicate the concern?</Label>
                    </div>
                    <Form.Check
                        className="mb-3"
                        label={'Yes'}
                        type="radio"
                        id="duplicate-condition-yes"
                        name="duplicate-condition"
                        checked={!!canDuplicateConcern}
                        onChange={() => {
                            updateCaseDetails({ hasBeenAbleToDuplicate: true });
                            setCanDuplicateConcern(true);
                            setShowErrorMsg(true);
                        }}
                    />
                    <Form.Check
                        label={'No'}
                        type="radio"
                        id="duplicate-condition-no"
                        name="duplicate-condition"
                        checked={canDuplicateConcern == false}
                        onChange={() => {
                            updateCaseDetails({
                                hasBeenAbleToDuplicate: false,
                                technician5StepsBeforeFailureDuplication: '',
                            });
                            setCanDuplicateConcern(false);
                            setShowErrorMsg(true);
                        }}
                    />
                </Form.Group>
                {canDuplicateConcern && (
                    <div className="mt-3">
                        <TextareaHelper
                            editable={true}
                            value={stepsToDuplicate}
                            label={'Steps completed to duplicate'}
                            required
                            placeholder={'Steps completed to duplicate'}
                            onChangeText={setStepsToDuplicate}
                            onBlur={() => {
                                updateCaseDetails({ technician5StepsBeforeFailureDuplication: stepsToDuplicate });
                                setShowErrorMsg(true);
                            }}
                            errorMessage={
                                showErrorMsg && issueFeildCaseDetails === 'technician5StepsBeforeFailureDuplication'
                                    ? 'Steps completed to duplicate is required'
                                    : ''
                            }
                            maxLength={dataValidation.stepsLength}
                        />
                        <p style={{ fontSize: 'smaller', color: 'gray' }}>
                            Max character length: {dataValidation.stepsLength}
                        </p>
                    </div>
                )}
                <Form.Group className="mt-3">
                    <div className="mb-3">
                        <Label required>Has the vehicle been in for this concern before?</Label>
                    </div>
                    <Form.Check
                        className="mb-3"
                        label={'Yes'}
                        type="radio"
                        id="concern-before-yes"
                        name="concern-before"
                        checked={!!concernBefore}
                        onChange={() => {
                            updateCaseDetails({ vehicleHasBeenInBefore: true });
                            setConcernBefore(true);
                            setShowErrorMsg(true);
                        }}
                    />
                    <Form.Check
                        label={'No'}
                        type="radio"
                        id="concern-before-no"
                        name="concern-before"
                        checked={concernBefore == false}
                        onChange={() => {
                            updateCaseDetails({
                                vehicleHasBeenInBefore: false,
                                previousRONumber: '',
                                previousMileage: '',
                                werePartsReplaced: '',
                                whatPartsWereReplaced: '',
                                previousDiagnosisAndRepairs: '',
                                didTheConditionImprove: '',
                            });
                            setConcernBefore(false);
                            setShowErrorMsg(true);
                        }}
                    />
                </Form.Group>
                {concernBefore && (
                    <>
                        <div className="mt-3 d-flex flex-row">
                            <div className={`${classes.flexOne} mr-10`}>
                                <TextInputHelper
                                    required
                                    label={'Previous repair order number'}
                                    placeholder={'Enter Previous repair order number'}
                                    value={previousReportOrderNumber}
                                    onChangeText={setPreviousReportOrderNumber}
                                    onBlur={() => {
                                        updateCaseDetails({ previousRONumber: previousReportOrderNumber });
                                        setShowErrorMsg(true);
                                    }}
                                    errorMessage={
                                        showErrorMsg && issueFeildCaseDetails === 'previousRONumber'
                                            ? 'Previous repair order number is required'
                                            : ''
                                    }
                                    maxLength={dataValidation.previousOrderNumber}
                                />
                            </div>
                            <div className={`${classes.flexOne}`}>
                                <TextInputHelper
                                    required
                                    label={'Previous mileage'}
                                    placeholder={'Enter Previous mileage'}
                                    value={previousMileage}
                                    onChangeText={(text: string) => {
                                        // Only allow numeric numbers to be inputted (since mileage)
                                        const numericText = text.replace(/[^0-9]/g, '');
                                        const numericValue = parseInt(text, 10);

                                        if (
                                            numericText == '' ||
                                            (!isNaN(numericValue) && numericValue <= dataValidation.previousMileageMax)
                                        ) {
                                            setPreviousMileage(numericText);
                                        }
                                    }}
                                    onBlur={() => {
                                        updateCaseDetails({ previousMileage: previousMileage });
                                        setShowErrorMsg(true);
                                    }}
                                    errorMessage={
                                        showErrorMsg && issueFeildCaseDetails === 'previousMileage'
                                            ? 'Previous mileage is required'
                                            : ''
                                    }
                                />
                            </div>
                        </div>
                        <Form.Group className="mt-3">
                            <div className="mb-3">
                                <Label required>Were parts replaced?</Label>
                            </div>
                            <Form.Check
                                className="mb-3"
                                label={'Yes'}
                                type="radio"
                                id="parts-replaced-yes"
                                name="parts-replaced"
                                checked={!!partsReplaced}
                                onChange={() => {
                                    setPartsReplaced(true);
                                    updateCaseDetails({ werePartsReplaced: true });
                                    setShowErrorMsg(true);
                                }}
                            />
                            <Form.Check
                                label={'No'}
                                type="radio"
                                id="parts-replaced-no"
                                name="parts-replaced"
                                checked={partsReplaced == false}
                                onChange={() => {
                                    setPartsReplaced(false);
                                    updateCaseDetails({
                                        werePartsReplaced: false,
                                        whatPartsWereReplaced: '',
                                        previousDiagnosisAndRepairs: '',
                                        didTheConditionImprove: '',
                                    });
                                    setShowErrorMsg(true);
                                }}
                            />
                        </Form.Group>
                        {partsReplaced && (
                            <>
                                <div className="mt-3 d-flex flex-row">
                                    <div className={`${classes.flexOne} mr-10`}>
                                        <TextInputHelper
                                            required
                                            label={'What parts were replaced?'}
                                            placeholder={'Enter What parts were replaced?'}
                                            value={replacedParts}
                                            onChangeText={setReplacedParts}
                                            onBlur={() => {
                                                updateCaseDetails({ whatPartsWereReplaced: replacedParts });
                                                setShowErrorMsg(true);
                                            }}
                                            errorMessage={
                                                showErrorMsg && issueFeildCaseDetails === 'whatPartsWereReplaced'
                                                    ? 'What parts were replaced is required'
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className={`${classes.flexOne}`}></div>
                                </div>
                                <div className="mt-3">
                                    <TextareaHelper
                                        editable={true}
                                        value={previousDiagnosis}
                                        label={'Previous Diagnosis and Repair'}
                                        required
                                        placeholder={'Previous Diagnosis and Repair'}
                                        onChangeText={setPreviousDiagnosis}
                                        onBlur={() => {
                                            updateCaseDetails({ previousDiagnosisAndRepairs: previousDiagnosis });
                                            setShowErrorMsg(true);
                                        }}
                                        errorMessage={
                                            showErrorMsg && issueFeildCaseDetails === 'previousDiagnosisAndRepairs'
                                                ? 'Previous Diagnosis and Repair required'
                                                : ''
                                        }
                                        maxLength={dataValidation.previousDiagnoisisLength}
                                    />
                                    <p style={{ fontSize: 'smaller', color: 'gray' }}>
                                        Max character length: {dataValidation.previousDiagnoisisLength}
                                    </p>
                                </div>
                                <div className="mt-3">
                                    <TextareaHelper
                                        editable={true}
                                        value={conditionImproveNoticeable}
                                        label={'Did the condition improve or change noticeably? If so, how?'}
                                        required
                                        placeholder={''}
                                        onChangeText={setConditionImproveNoticeable}
                                        onBlur={() => {
                                            updateCaseDetails({ didTheConditionImprove: conditionImproveNoticeable });
                                            setShowErrorMsg(true);
                                        }}
                                        errorMessage={
                                            showErrorMsg && issueFeildCaseDetails === 'didTheConditionImprove'
                                                ? 'Did the condition improve or change noticeably? If so, how? is required'
                                                : ''
                                        }
                                        maxLength={dataValidation.conditionChangeLength}
                                    />
                                    <p style={{ fontSize: 'smaller', color: 'gray' }}>
                                        Max character length: {dataValidation.conditionChangeLength}
                                    </p>
                                </div>
                            </>
                        )}
                    </>
                )}
                {/* TODO:this might needed in future so just commenting for now */}
                {/* <Form.Group className="mt-3">
                    <div className="mb-3">
                        <Label required>
                            Did the customer report any of the following: Rollover, Fire, Accident, Injury, Death,
                            Property Damage or airbag rupture?
                        </Label>
                    </div>
                    <Form.Check
                        className="mb-3"
                        label={'Yes'}
                        type="radio"
                        id="customer-report-yes"
                        name="customer-report"
                        checked={!!customerReport}
                        onChange={() => {
                            if (!didShowUrfca) {
                                setShowUrfca(true);
                                setDidShowUrfca(true);
                            }
                            updateCaseDetails({ rolloverFireAccidentInjuryDeathDamage: true });
                            setCustomerReport(true);
                        }}
                    />
                    <Form.Check
                        label={'No'}
                        type="radio"
                        id="customer-report-no"
                        name="customer-report"
                        checked={customerReport == false}
                        onChange={() => {
                            updateCaseDetails({
                                rolloverFireAccidentInjuryDeathDamage: false,
                                rolloverFlag: '',
                                fireFlag: '',
                                accidentFlag: '',
                                injuryFlag: '',
                                deathFlag: '',
                                propertyDamage: '',
                                airbagRuptureFlag: '',
                            });
                            setCustomerReport(false);
                        }}
                    />
                </Form.Group>
                {customerReport && (
                    <div className="mt-3">
                        <div className="mb-3">
                            <Label required>Which one?</Label>
                        </div>
                        <Form.Check
                            className="mb-3"
                            label={'Rollover'}
                            type="checkbox"
                            id="rollover"
                            name="rollover"
                            checked={!!multiSelection.rollover}
                            onChange={() => {
                                updateCaseDetails({ rolloverFlag: !multiSelection.rollover });
                                setMultiSelection({ ...multiSelection, rollover: !multiSelection.rollover });
                            }}
                        />
                        <Form.Check
                            className="mb-3"
                            label={'fire'}
                            type="checkbox"
                            id="fire"
                            name="fire"
                            checked={!!multiSelection.fire}
                            onChange={() => {
                                updateCaseDetails({ fireFlag: !multiSelection.fire });
                                setMultiSelection({ ...multiSelection, fire: !multiSelection.fire });
                            }}
                        />
                        <Form.Check
                            className="mb-3"
                            label={'accident'}
                            type="checkbox"
                            id="accident"
                            name="accident"
                            checked={!!multiSelection.accident}
                            onChange={() => {
                                updateCaseDetails({ accidentFlag: !multiSelection.accident });
                                setMultiSelection({ ...multiSelection, accident: !multiSelection.accident });
                            }}
                        />
                        <Form.Check
                            className="mb-3"
                            label={
                                <>
                                    injury {multiSelection.injuryCount > 0 ? `(${multiSelection.injuryCount})` : ''}
                                    {multiSelection.injuryCount > 0 && (
                                        <button
                                            style={{ backgroundColor: 'transparent', border: 'none' }}
                                            disabled={false}
                                            onClick={() => {
                                                setShowConfirmInjuryEwdModal(true);
                                            }}
                                        >
                                            <Icon name="pencil" size={20} />
                                        </button>
                                    )}
                                </>
                            }
                            type="checkbox"
                            id="injury"
                            name="injury"
                            checked={!!multiSelection.injury}
                            onChange={() => {
                                if (!multiSelection.injury) {
                                    setShowConfirmInjuryEwdModal(true);
                                } else {
                                    updateCaseDetails({ injuryFlag: !multiSelection.injury, injuryNumberOfPeople: 0 });
                                    setMultiSelection({
                                        ...multiSelection,
                                        injury: !multiSelection.injury,
                                        injuryCount: 0,
                                    });
                                }
                            }}
                        />
                        <Form.Check
                            className="mb-3"
                            label={
                                <>
                                    death {multiSelection.deathCount > 0 ? `(${multiSelection.deathCount})` : ''}
                                    {multiSelection.deathCount > 0 && (
                                        <button
                                            style={{ backgroundColor: 'transparent', border: 'none' }}
                                            disabled={false}
                                            onClick={() => {
                                                setShowConfirmDeathEwdModal(true);
                                            }}
                                        >
                                            <Icon name="pencil" size={20} />
                                        </button>
                                    )}
                                </>
                            }
                            type="checkbox"
                            id="death"
                            name="death"
                            checked={!!multiSelection.death}
                            onChange={() => {
                                if (!multiSelection.death) {
                                    setShowConfirmDeathEwdModal(true);
                                } else {
                                    updateCaseDetails({ deathFlag: !multiSelection.death, deathNumberOfPeople: 0 });
                                    setMultiSelection({
                                        ...multiSelection,
                                        death: !multiSelection.death,
                                        deathCount: 0,
                                    });
                                }
                            }}
                        />
                        <Form.Check
                            className="mb-3"
                            label={'property damage'}
                            type="checkbox"
                            id="propertyDamage"
                            name="propertyDamage"
                            checked={!!multiSelection.propertydamage}
                            onChange={() => {
                                updateCaseDetails({ propertyDamage: !multiSelection.propertydamage });
                                setMultiSelection({
                                    ...multiSelection,
                                    propertydamage: !multiSelection.propertydamage,
                                });
                            }}
                        />

                        <Form.Check
                            className="mb-3"
                            label={'Airbag Rupture '}
                            type="checkbox"
                            id="airbag"
                            name="airbag"
                            checked={!!multiSelection.airbag}
                            onChange={() => {
                                updateCaseDetails({ airbagRuptureFlag: !multiSelection.airbag });
                                setMultiSelection({ ...multiSelection, airbag: !multiSelection.airbag });
                            }}
                        />
                    </div>
                )} */}
            </div>
        </div>
    );
};
