import { Dispatch, Reducer } from 'react';
import { action, on, props, reducer, union } from 'ts-action';
import { CategoryActions } from './categories.actions';
import { CategoriesWithArticles } from '../types';

export const categoriesActions = {
    deleteCategory: action(CategoryActions.deleteCategory, props<{ categories: Array<CategoriesWithArticles> }>()),
    fetchCategories: action(CategoryActions.fetchCategories, props<{ categories: Array<CategoriesWithArticles> }>()),
    createCategory: action(CategoryActions.createCategory),
    editCategory: action(CategoryActions.editCategory, props<{ categories: Array<CategoriesWithArticles> }>()),
    queryCategories: action(CategoryActions.queryCategories, props<{ categories: Array<CategoriesWithArticles> }>()),
};

export interface CategoryState {
    categories: Array<CategoriesWithArticles>;
}

const actionsUnion = union(...Object.values(categoriesActions));
type CategoryActionsUnion = typeof actionsUnion.actions;

export type CategoryActionDispatcher = Dispatch<CategoryActionsUnion>;

export const initialState: CategoryState = {
    categories: [],
};

export const categoryReducer: Reducer<CategoryState, CategoryActionsUnion> = reducer(
    initialState,
    on(categoriesActions.fetchCategories, (state, { categories }) => {
        return {
            ...state,
            categories,
        };
    }),
    on(categoriesActions.createCategory, (state) => {
        return { ...state };
    }),
    on(categoriesActions.deleteCategory, (state, { categories }) => {
        return {
            ...state,
            categories,
        };
    }),
    on(categoriesActions.editCategory, (state, { categories }) => {
        return {
            ...state,
            categories,
        };
    }),
    on(categoriesActions.queryCategories, (state, { categories }) => {
        return {
            ...state,
            categories,
        };
    })
);
