import { cloneDeep } from 'lodash';
import React, { FC, useState } from 'react';

import { TextInputHelper, Typography } from '@packages/ui/shared';

import { DraggableFormComponent, FeedbackFormElementOption } from '@packages/models/api';
import Select from '@web/components/select';

enum RATING_FORMAT {
    ONE_TO_FIVE = 'ONE_TO_FIVE',
}

interface FormEditLikertProps extends DraggableFormComponent {
    onChange({
        formElementLabel,
        options,
    }: {
        formElementLabel?: string;
        options?: FeedbackFormElementOption[] | undefined;
    }): void;
}

export const FormEditLikert: FC<FormEditLikertProps> = ({ formElementLabel, options, onChange }) => {
    const [ratingFormateInputValue, setRatingFormateInputValue] = useState(RATING_FORMAT.ONE_TO_FIVE);

    function handleLabelChangeText(text: string) {
        onChange({ formElementLabel: text });
    }

    function handleOptionLabelChange(index: number, text: string) {
        if (!options) {
            return;
        }

        const optionsClone = cloneDeep(options);
        optionsClone[index].optionLabel = text;

        onChange({ options: optionsClone });
    }

    return (
        <>
            <div className="mb-2">
                <Typography variant="label">Question Text</Typography>
            </div>
            <div className="mb-6">
                <TextInputHelper
                    value={formElementLabel}
                    onChangeText={handleLabelChangeText}
                    placeholder="Enter a label"
                />
            </div>
            <div className="mb-2">
                <Typography variant="label">Rating Format (TODO: this is not implemented yet)</Typography>
            </div>
            <div className="mb-6">
                <Select
                    value={ratingFormateInputValue}
                    options={[
                        {
                            title: '1 to 5',
                            value: RATING_FORMAT.ONE_TO_FIVE,
                        },
                    ]}
                    onChange={(event) => {
                        setRatingFormateInputValue(event.currentTarget.value as RATING_FORMAT);
                    }}
                />
            </div>
            <div className="mb-2">
                <Typography variant="label">Rating Values</Typography>
            </div>
            {ratingFormateInputValue === RATING_FORMAT.ONE_TO_FIVE && (
                <>
                    {options &&
                        options.map((option, index) => {
                            return (
                                <div key={`${option.optionId}-${index}`} className="d-flex mb-2 align-items-center">
                                    <div className="mr-2">
                                        <Typography>{index + 1}</Typography>
                                    </div>
                                    <TextInputHelper
                                        style={{ flex: 1 }}
                                        value={option.optionLabel}
                                        onChangeText={(text) => {
                                            handleOptionLabelChange(index, text);
                                        }}
                                        placeholder="Enter a label"
                                    />
                                </div>
                            );
                        })}
                </>
            )}
        </>
    );
};
