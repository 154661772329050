import React from 'react';
import { Modal, View, TouchableWithoutFeedback, Animated, Platform } from 'react-native';

import styles from './styles';

interface Props {
    animationType?: 'none' | 'slide' | 'fade';
    transparent?: boolean;
    visible?: boolean;
    onHide?: () => void;
    onShow?: () => void;
    children?: React.ReactNode;
}
const ModalPopup = (props: Props) => {
    const { children, visible, onHide } = props;
    const [showModal, setShowModal] = React.useState(visible);
    const scaleValue = React.useRef(new Animated.Value(0)).current;

    React.useEffect(() => {
        toggleModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    const toggleModal = () => {
        if (visible) {
            setShowModal(true);
            Animated.timing(scaleValue, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true,
            }).start();
        } else {
            setTimeout(() => setShowModal(false), 500);
            Animated.timing(scaleValue, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true,
            }).start();
        }
    };

    return (
        <Modal transparent visible={showModal} onRequestClose={onHide}>
            <TouchableWithoutFeedback onPress={onHide}>
                <View style={styles.backdrop} />
            </TouchableWithoutFeedback>
            <Animated.View
                style={[
                    Platform.OS === 'web' && {
                        opacity: scaleValue,
                        ...styles.modalContainer,
                    },
                ]}
            >
                {children}
            </Animated.View>
        </Modal>
    );
};

export default ModalPopup;
