import uuid from 'uuid';
import { cloneDeep } from 'lodash';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';

import { Button, Icon, TextInputHelper, Typography } from '@packages/ui/shared';

import {
    ConditionalElement,
    DraggableWorksheetFormComponent,
    FormElementFile,
    FormElementOption,
    FormElementTypeId,
    WorksheetFormElementOption,
} from '@packages/models/api';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { WorksheetAddFieldEnum } from '../../views/worksheets/types';
import { AddFieldType } from './worksheet-form-component-edit-modal';
import AddField from './add-field';
import { FormQuantityMenuItemList } from './form-quantity-menu-item-list';
import colors from '@packages/core/styles/colors';
import { useValidationField } from './hooks/useValidationField';
import useTranslationData from './hooks/useTranslationData';
import { WorksheetCharacterLengthValidation } from '@web/administration/views/worksheets/utils';
import FilePicker from './inputPickers/FilePicker';
import { MAX_FILE_SIZE } from './inputPickers/util';

interface FormWorksheetEditSelectListProps {
    onChange({
        formElementLabel,
        options,
        formElementFile,
        formElementDescription,
        optionConditionsElements,
        formElementHasRule,
    }: {
        formElementLabel?: string;
        optionConditionsElements?: ConditionalElement[];
        options?: FormElementOption[] | undefined;
        worksheetElementTooltip?: string;
        formElementFile?: FormElementFile;
        formElementDescription?: string;
        formElementHasRule?: boolean;
    }): void;
    addField: AddFieldType;
    formElement: DraggableWorksheetFormComponent;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormEditWorksheetSelectList: FC<FormWorksheetEditSelectListProps> = ({
    onChange,
    addField,
    formElement,
    setIsValid,
}) => {
    const { formElementLabel, formElementTooltip, formElementFile, formElementDescription, options } = formElement;
    const [optionImage, setOptionImage] = useState<{ id: string; file?: FormElementFile }[]>([]);
    const [showOtherOption, setShowOtherOption] = useState(false);
    const { fieldHandlers, fieldErrorMessages, setErrorMsg, errorsInit, removeField, isValid } = useValidationField();
    const { handleRequired } = fieldHandlers;
    const [addOptionIds, setAddOptionIds] = useState<string[]>([]);
    const [filterOptions, setFilterOption] = useState<FormElementOption[]>([]);
    const { draggableElementsDataTranslate } = useTranslationData();
    useEffect(() => {
        setIsValid(isValid);
    }, [Object.keys(fieldErrorMessages || {})]);

    useEffect(() => {
        const optionClone = cloneDeep(options);
        if (optionClone) {
            const otherOptionIndex = optionClone.findIndex((option) => option.isOther);
            if (otherOptionIndex > -1) {
                setShowOtherOption(true);
                const otherOption = optionClone.splice(otherOptionIndex, 1)[0];
                optionClone.splice(optionClone.length, 0, otherOption);
                setShowOtherOption(true);
            } else {
                setShowOtherOption(false);
            }
            setFilterOption(optionClone);
        }
    }, [options]);

    useEffect(() => {
        options?.map((option) => {
            if (option.optionFile?.url) {
                setOptionImage((prev) => {
                    return [...prev, { id: option.optionId, file: option.optionFile }];
                });
            }
        });
    }, []);

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination } = result;
            if (!destination) {
                return;
            }
            const option = filterOptions[source.index];
            filterOptions.splice(source.index, 1);

            filterOptions.splice(destination.index, 0, option);
            onChange({ options: filterOptions });
        },
        [filterOptions, onChange]
    );

    const checkAddOptionIndexAndRemoveField = useCallback(
        (optionId: string, fieldName: string) => {
            const optAddIndex = addOptionIds.findIndex((id) => id === optionId);
            if (optAddIndex > -1 && addOptionIds[optAddIndex]) {
                removeField(fieldName);
                setAddOptionIds((prev) => {
                    const prevClone = [...prev];
                    prevClone.splice(optAddIndex, 1);
                    return prevClone;
                });
            }
        },
        [addOptionIds, removeField]
    );

    const handleOptionLabelChange = useCallback(
        (index: number, text: string) => {
            if (text.trim().length > WorksheetCharacterLengthValidation.QuestionLength) {
                return;
            }
            checkAddOptionIndexAndRemoveField(filterOptions[index].optionId, `new${filterOptions[index].optionId}`);
            handleRequired(text, filterOptions[index].optionId, errorsInit.optionLabel);

            filterOptions[index].optionLabel = text;

            onChange({ options: filterOptions });
        },
        [checkAddOptionIndexAndRemoveField, filterOptions, handleRequired, onChange]
    );

    const handleRemoveOptionPress = useCallback(
        (index: number) => {
            removeField(filterOptions[index].optionId);
            checkAddOptionIndexAndRemoveField(filterOptions[index].optionId, `new${filterOptions[index].optionId}`);

            const optionConditionClone = formElement.optionConditionsElements?.filter(
                (element) => element.targetOptionId !== filterOptions[index].optionId
            );
            if (filterOptions[index].isOther) {
                setShowOtherOption(false);
            }
            filterOptions.splice(index, 1);
            if (filterOptions.length === 0) {
                setErrorMsg('options', `${errorsInit.options} required`);
            }
            onChange({ optionConditionsElements: optionConditionClone, options: filterOptions });
        },
        [
            checkAddOptionIndexAndRemoveField,
            filterOptions,
            formElement.optionConditionsElements,
            onChange,
            removeField,
            setErrorMsg,
        ]
    );

    const handleAddOption = useCallback(
        (isOther: boolean = false) => {
            const idAndValue = uuid();
            const newOption: WorksheetFormElementOption = {
                optionId: idAndValue,
                optionValue: filterOptions.length.toString(),
                optionLabel: '',
                optionPlaceholder: '',
                isOther: isOther,
            };
            setAddOptionIds((prev) => [...prev, idAndValue]);
            if (showOtherOption) {
                filterOptions.splice(filterOptions.length - 1, 0, newOption);
            } else {
                filterOptions.push(newOption);
            }

            if (filterOptions.length > 0) {
                removeField('options');
            }
            setErrorMsg(`new${idAndValue}`, 'new');
            onChange({ options: filterOptions });
        },
        [filterOptions, onChange, removeField, setErrorMsg, showOtherOption]
    );

    const handleAddOther = useCallback(() => {
        if (showOtherOption) {
            return;
        }
        handleAddOption(true);
        setShowOtherOption(true);
    }, [handleAddOption, showOtherOption]);

    const handleOptionImage = useCallback(
        (index: number, file?: FormElementFile) => {
            if (!options) {
                return;
            }

            const optionsClone = cloneDeep(options);

            optionsClone[index].optionFile = file;

            onChange({ options: optionsClone });
        },
        [onChange, options]
    );

    const handleOptionFileChange = useCallback(
        (id: string, file?: File) => {
            if (!options) {
                return;
            }
            const optionIndex = options?.findIndex((option) => option.optionId === id);
            if (optionIndex === -1) {
                return;
            }
            const oldFile = options[optionIndex].optionFile;
            const fileData: FormElementFile = { ...oldFile, file, name: file?.name, url: undefined };
            if (file) {
                const fileUrl = URL.createObjectURL(file);
                fileData.url = fileUrl;
            }
            if (!file) {
                const index = optionImage.findIndex((option) => option.id === id);
                if (index !== -1) {
                    const newOptionImage = optionImage;
                    optionImage.splice(index, 1);
                    setOptionImage([...newOptionImage]);
                }
            } else {
                const checkOptionIndex = optionImage.findIndex((check) => check.id === id);
                if (checkOptionIndex > -1) {
                    const checkOption = optionImage.findIndex((option) => option.id === id);
                    setOptionImage((prev) => {
                        const prevClone = cloneDeep(prev);
                        prevClone[checkOption].file = fileData;
                        return prevClone;
                    });
                } else {
                    setOptionImage([...optionImage, { id: id, file: fileData }]);
                }
            }
            handleOptionImage(optionIndex, fileData);
        },
        [handleOptionImage, optionImage, options]
    );

    const handleImageOption = useCallback(
        (id: string) => {
            const checkOption = optionImage.find((check) => {
                return check.id === id ? true : false;
            });
            if (!checkOption) {
                setOptionImage([...optionImage, { id: id, file: {} }]);
            }
        },
        [optionImage]
    );

    return (
        <>
            <AddField
                addFieldValues={addField}
                onchangeText={onChange}
                formElementTooltip={formElementTooltip}
                formElementDescription={formElementDescription}
                formElementFile={formElementFile}
                formElementLabel={formElementLabel}
                errorMessage={fieldErrorMessages?.questionLabel}
                handleRequired={handleRequired}
            />

            <hr style={{ borderWidth: 2, borderColor: colors.grayTwo }} />

            <div className="mb-2 mt-5">
                <Typography variant="label">{draggableElementsDataTranslate.labels.options}</Typography>
            </div>
            <small style={{ color: colors.redOne }}>{fieldErrorMessages?.options}</small>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="options">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {filterOptions.map((option, index) => {
                                if (!option?.isOther) {
                                    return (
                                        <Draggable key={option.optionId} draggableId={option.optionId} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                                    <div className="d-flex mb-2 align-items-center">
                                                        <div className="mr-8">
                                                            <Typography color={'grayFour'}>{index + 1}</Typography>
                                                        </div>
                                                        <div className="mr-5" {...provided.dragHandleProps}>
                                                            <Icon name="menu" color={'grayFour'} />
                                                        </div>
                                                        <TextInputHelper
                                                            required
                                                            errorMessage={fieldErrorMessages?.[option.optionId]}
                                                            style={{ flex: 1 }}
                                                            value={option.optionLabel}
                                                            onChangeText={(text) => {
                                                                setErrorMsg(option.optionId);
                                                                handleOptionLabelChange(index, text);
                                                            }}
                                                            placeholder={
                                                                draggableElementsDataTranslate.placeholder.questionLabel
                                                            }
                                                        />
                                                        {addField[WorksheetAddFieldEnum.Condition].check && (
                                                            <div className="ml-2" style={{ width: '250px' }}>
                                                                <FormQuantityMenuItemList
                                                                    optionId={option.optionId}
                                                                    formElement={formElement}
                                                                    onChange={onChange}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="ml-5">
                                                            <Button
                                                                variant="link"
                                                                onPress={() => {
                                                                    handleImageOption(option.optionId);
                                                                }}
                                                            >
                                                                <Icon name="image" color="blueOne" />
                                                            </Button>
                                                        </div>
                                                        <div className="ml-5">
                                                            <Button
                                                                variant="link"
                                                                onPress={() => {
                                                                    handleRemoveOptionPress(index);
                                                                }}
                                                            >
                                                                <Icon name="x-close" color="blueOne" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-2 ml-21 mr-21">
                                                        <div className="w-100">
                                                            {optionImage.map((check) => {
                                                                if (check.id === option.optionId) {
                                                                    return (
                                                                        <FilePicker
                                                                            onChange={(file) => {
                                                                                handleOptionFileChange(
                                                                                    option.optionId,
                                                                                    file
                                                                                );
                                                                            }}
                                                                            iconName={'plus'}
                                                                            formElementFile={check.file}
                                                                            key={option.optionId}
                                                                            fileSizeLimit={MAX_FILE_SIZE}
                                                                        />
                                                                    );
                                                                }
                                                                return (
                                                                    <React.Fragment
                                                                        key={option.optionId}
                                                                    ></React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                } else {
                                    return <Fragment key={'isOther'}></Fragment>;
                                }
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {showOtherOption && (
                <div className="d-flex mb-2 align-items-center">
                    <TextInputHelper
                        required
                        errorMessage={fieldErrorMessages?.[filterOptions?.slice(-1)[0]?.optionId || '']}
                        style={{ flex: 1, marginLeft: 83 }}
                        value={filterOptions?.slice(-1)[0]?.optionLabel}
                        onChangeText={(text) => {
                            setErrorMsg(filterOptions?.slice(-1)[0]?.optionId || '');
                            handleOptionLabelChange((filterOptions?.length || 0) - 1, text);
                        }}
                        placeholder={draggableElementsDataTranslate.placeholder.addOther}
                    />
                    <div className="ml-5">
                        <Button
                            variant="link"
                            onPress={() => {
                                handleRemoveOptionPress((filterOptions?.length || 0) - 1);
                            }}
                        >
                            <Icon name="x-close" color="blueOne" />
                        </Button>
                    </div>
                </div>
            )}
            <div className="ml-16 d-flex">
                <Button variant="ghost-blue" onPress={() => handleAddOption()}>
                    {draggableElementsDataTranslate.buttons.addOption}
                </Button>
                {formElement.formElementTypeId !== FormElementTypeId.Dropdown && (
                    <>
                        <p className="mt-2">Or</p>
                        <Button variant="ghost-blue" onPress={() => handleAddOther()}>
                            {draggableElementsDataTranslate.buttons.addOther}
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};
