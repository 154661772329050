import { Icon, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import useTranslationData from './hooks/useTranslationData';

interface ResizableTextareaProps {
    value: string;
    onChange: (text: string) => void;
    placeholder?: string;
    id: string;
    errorMessage?: string;
}
export const ResizableTextarea: FC<ResizableTextareaProps> = ({ id, value, onChange, placeholder, errorMessage }) => {
    const classes = styleForTextArea(errorMessage);
    const { draggableElementsDataTranslate } = useTranslationData();
    useEffect(() => {
        const textarea = document.getElementById(id) as HTMLTextAreaElement;
        textarea.style.height = 'auto';
        textarea.style.height = value ? `${textarea.scrollHeight}px` : '40px';
    }, []);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) => {
            const value = event.target.value;
            onChange(value);
        },
        [onChange]
    );

    const handleResize = useCallback((event: React.SyntheticEvent<HTMLTextAreaElement, Event>) => {
        const target = event.target as HTMLTextAreaElement;
        target.style.height = 'auto';
        target.style.height = `${target.scrollHeight - 20}px`;
    }, []);
    return (
        <>
            <div className=" w-100 justify-content-between ">
                <div className={`${classes.inputContainer} w-100 d-flex justify-content-between`}>
                    <div className="w-100 d-flex align-contents-center overflow-hidden">
                        <textarea
                            value={value}
                            id={id}
                            onChange={handleChange}
                            onInput={handleResize}
                            className={`border-0 ${classes.textarea}`}
                            placeholder={placeholder || draggableElementsDataTranslate.placeholder.text}
                        />
                    </div>

                    {errorMessage && (
                        <div className="d-flex align-contents-center h-100">
                            <Icon name={'error'} color="redOne" style={{ fontWeight: '100' }} />
                        </div>
                    )}
                </div>
                {errorMessage && (
                    <Typography variant="caption" color="redOne" style={{ marginTop: 8 }}>
                        {errorMessage}
                    </Typography>
                )}
            </div>
        </>
    );
};
const styleForTextArea = createUseStyles({
    textarea: {
        resize: 'none',
        overflow: 'hidden',
        width: '100%',
        borderRadius: 5,
        padding: '8px 12px',
        fontSize: 17,
        color: colors.textDarkPrimary,
    },
    inputContainer: (error) => ({
        paddingHorizontal: 12,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 3,
        border: error ? `2px solid ${colors.redOne}` : '1px solid black',
    }),
});
