import { useAuthState } from '@packages/contexts/auth';
import { useAbortController } from '@packages/core/http';
import { FlatList } from 'react-native';
import colors from '@packages/core/styles/colors';
import { InvestigationModel, InvestigationCommentModel } from '@packages/models/api';
import { BaseComment, Icon, Typography, TextareaHelper, EmptyList, Button } from '@packages/ui/shared';
import ConfirmDeleteCommentModal from './confirm-delete-comment-modal';
import { investigationService } from '@web/services/singletons';
import classNames from 'classnames';
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Badge, Overlay, Spinner, Tab } from 'react-bootstrap';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { getPopoverContentLocation } from '../../utils/popover-content-location';

const useInvestigationCommentsStyles = createUseStyles({
    commentsContainer: {
        minHeight: 250,
    },
    addCommentContainer: {
        borderTop: `1px solid ${colors.grayThree}`,
    },
    info: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    actionsPopover: {
        zIndex: 100,
        minWidth: 150,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        borderRadius: 4,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
    },
    popoverActionsSection: {
        padding: '4px 0',
    },
});

interface InvestigationDetailsTabProps {
    eventKey: string;
    investigation: InvestigationModel;
}

export const InvestigationCommentsTab = ({ eventKey, investigation }: InvestigationDetailsTabProps) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const classes = useInvestigationCommentsStyles();
    const { account } = useAuthState();
    const investigationId = investigation.investigationId;

    const addCommentRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [comments, setComments] = useState<InvestigationCommentModel[]>(investigation.comments);
    const [newComment, setNewComment] = useState('');
    const [editComment, setEditComment] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [expandedOptionsCommentId, setExpandedOptionsCommentId] = useState('');
    const [commentIdForDeletion, setCommentIdForDeletion] = useState('');
    const [commentIdForEdit, setCommentIdForEdit] = useState('');

    const fetchComments = useCallback(() => {
        investigationService
            .getInvestigationComments({
                investigationId,
                signal: abortSignalRef.current,
                ignoreCache: true,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    return Promise.reject(response.data);
                }

                setIsLoading(false);
                setComments(response.data.investigationComments);
            })
            .catch((e) => {
                setIsLoading(false);
                alert(e.message);
            });
    }, [abortSignalRef, investigationId]);

    useEffect(() => {
        fetchComments();
    }, [fetchComments]);

    const addComment = useCallback(() => {
        if (isSaving) {
            return;
        }

        setIsSaving(true);
        investigationService
            .addInvestigationComment({
                investigationId,
                body: newComment,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setNewComment('');
                setIsSaving(false);
                fetchComments();
            })
            .catch((e) => {
                setIsSaving(false);
                alert(e.message);
            });
    }, [abortSignalRef, fetchComments, investigationId, isSaving, newComment]);

    return (
        <Tab.Pane eventKey={eventKey} className="p-6">
            <ConfirmDeleteCommentModal
                show={!!commentIdForDeletion}
                investigationId={investigation.investigationId}
                investigationCommentId={commentIdForDeletion}
                onHide={(didDelete) => {
                    if (didDelete) {
                        setComments(comments.filter((qC) => qC.investigationCommentId !== commentIdForDeletion));
                        fetchComments();
                    }

                    setCommentIdForDeletion('');
                }}
            />
            <div className={classNames(classes.commentsContainer, 'mb-4')}>
                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <FlatList
                        data={comments}
                        renderItem={({ item: comment }) => {
                            return (
                                <BaseComment
                                    authorName={comment.createdAccount?.name}
                                    timestamp={comment.createdTimestampDescription}
                                    body={comment.body}
                                    comment={comment}
                                    commentOptionsRenderer={(comment) => {
                                        const isOwnComment = comment.createdAccountId === account?.accountId;

                                        if (!isOwnComment) {
                                            return null;
                                        }

                                        const isOpen = expandedOptionsCommentId === comment.investigationCommentId;

                                        return (
                                            <Popover
                                                isOpen={isOpen}
                                                positions={['bottom', 'top']}
                                                onClickOutside={() => setExpandedOptionsCommentId('')}
                                                contentLocation={({ childRect, popoverRect, position }) => {
                                                    return getPopoverContentLocation(
                                                        childRect,
                                                        popoverRect,
                                                        position,
                                                        4
                                                    );
                                                }}
                                                containerClassName={classes.actionsPopover}
                                                content={
                                                    <div className={classes.popoverActionsSection}>
                                                        {isOwnComment && (
                                                            <>
                                                                <Button
                                                                    alignTitle="left"
                                                                    variant="ghost-gray"
                                                                    onPress={() => {
                                                                        setExpandedOptionsCommentId('');
                                                                        setCommentIdForEdit(
                                                                            comment.investigationCommentId
                                                                        );
                                                                        setEditComment(comment.body);
                                                                    }}
                                                                >
                                                                    <Icon name="pencil" size={16} />
                                                                    <Typography
                                                                        style={{ paddingLeft: 12 }}
                                                                        variant="labelRegular"
                                                                    >
                                                                        {t(
                                                                            'investigations:panel.tabs.comments.actions.edit',
                                                                            'Edit'
                                                                        )}
                                                                    </Typography>
                                                                </Button>

                                                                <Button
                                                                    alignTitle="left"
                                                                    variant="ghost-gray"
                                                                    onPress={() => {
                                                                        setExpandedOptionsCommentId('');
                                                                        setCommentIdForDeletion(
                                                                            comment.investigationCommentId
                                                                        );
                                                                    }}
                                                                >
                                                                    <Icon name="trash" size={16} color="redOne" />
                                                                    <Typography
                                                                        style={{ paddingLeft: 12 }}
                                                                        variant="labelRegular"
                                                                        color="redOne"
                                                                    >
                                                                        {t(
                                                                            'investigations:panel.tabs.comments.actions.delete',
                                                                            'Delete'
                                                                        )}
                                                                    </Typography>
                                                                </Button>
                                                            </>
                                                        )}
                                                    </div>
                                                }
                                            >
                                                <div>
                                                    <Button
                                                        active={isOpen}
                                                        variant="ghost-gray"
                                                        onPress={() => {
                                                            setExpandedOptionsCommentId(
                                                                isOpen ? '' : comment.investigationCommentId
                                                            );
                                                        }}
                                                    >
                                                        <Icon name="more-dots-vertical" />
                                                    </Button>
                                                </div>
                                            </Popover>
                                        );
                                    }}
                                    editCommentRenderer={
                                        comment.investigationCommentId === commentIdForEdit
                                            ? () => {
                                                  return (
                                                      <>
                                                          <div className="mt-3 bg-white">
                                                              <TextareaHelper
                                                                  containerStyle={{ height: 80 }}
                                                                  placeholder={t(
                                                                      'investigations:panel.tabs.comments.editComment.placeholder',
                                                                      'Add a comment...'
                                                                  )}
                                                                  value={editComment}
                                                                  onChangeText={setEditComment}
                                                              />
                                                          </div>

                                                          <div className="mt-2 d-flex justify-content-end">
                                                              <span className="mr-2">
                                                                  <Button
                                                                      onPress={() => {
                                                                          setCommentIdForEdit('');
                                                                          setEditComment('');
                                                                      }}
                                                                      variant="ghost-gray"
                                                                  >
                                                                      {t(
                                                                          'investigations:panel.tabs.comments.editComment.actions.cancel',
                                                                          'Cancel'
                                                                      )}
                                                                  </Button>
                                                              </span>

                                                              <Button
                                                                  variant="primary"
                                                                  onPress={() => {
                                                                      investigationService

                                                                          .editInvestigationComment({
                                                                              investigationId,
                                                                              investigationCommentId:
                                                                                  comment.investigationCommentId,
                                                                              body: editComment,
                                                                              signal: abortSignalRef.current,
                                                                          })
                                                                          .then(() => {
                                                                              fetchComments();
                                                                              setCommentIdForEdit('');
                                                                          });
                                                                  }}
                                                              >
                                                                  {t(
                                                                      'investigations:panel.tabs.comments.editComment.actions.save',
                                                                      'Save'
                                                                  )}
                                                              </Button>
                                                          </div>
                                                      </>
                                                  );
                                              }
                                            : undefined
                                    }
                                />
                            );
                        }}
                        ListEmptyComponent={
                            <EmptyList
                                iconName="chat-bubble"
                                title={t(
                                    'shared:investigationCommentList.emptyList.title',
                                    'No comments have been added'
                                )}
                                description={t(
                                    'shared:investigationCommentList.emptyList.description',
                                    'Comments will not be displayed to retailers'
                                )}
                            />
                        }
                        keyExtractor={(item) => item.investigationCommentId}
                    />
                )}
            </div>

            <div ref={addCommentRef} className={classes.addCommentContainer}>
                <div className="mt-3 bg-white">
                    <TextareaHelper
                        containerStyle={{ height: 80 }}
                        placeholder={t('investigations:panel.tabs.comments.newComment.placeholder', 'Add a comment...')}
                        value={newComment}
                        onChangeText={setNewComment}
                    />
                </div>

                <div className="mt-2 d-flex justify-content-end">
                    <span className="mr-2">
                        <Button onPress={() => setNewComment('')} variant="ghost-gray">
                            {t('investigations:panel.tabs.comments.newComment.actions.cancel', 'Cancel')}
                        </Button>
                    </span>

                    <Button variant="primary" isLoading={isSaving} onPress={addComment}>
                        {t('investigations:panel.tabs.comments.newComment.actions.addComment', 'Comment')}
                    </Button>
                </div>

                <div
                    ref={tooltipRef}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    className={classNames(classes.info, 'mt-4 d-flex align-items-center')}
                >
                    <span className="mr-2">
                        <Icon size={16} name="question-mark" color="blueOne" />
                    </span>
                    <Typography variant="caption" color="blueOne">
                        {t('investigations:panel.tabs.comments.whoCanSeeComment', 'Who can see my comments?')}
                    </Typography>
                </div>

                <Overlay container={addCommentRef.current} target={tooltipRef.current as any} show={showTooltip}>
                    {(overlayProps: any) => {
                        return (
                            <Badge ref={overlayProps.ref} style={overlayProps.style} variant="secondary">
                                <div className="py-2 px-3 text-left">
                                    <Typography color="white">
                                        {t(
                                            'investigations:panel.tabs.comments.commentMessage',
                                            'Comments are viewable by DSQMs and QA.\r\nRetailers cannot see comments.'
                                        )}
                                    </Typography>
                                </div>
                            </Badge>
                        );
                    }}
                </Overlay>
            </div>
        </Tab.Pane>
    );
};
