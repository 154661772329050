import React, { FC, useRef, useState, useEffect, useCallback } from 'react';
import { Button, Icon, StsIconName, Typography } from '@packages/ui/shared';
import { Platform } from 'react-native';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import useTranslationData from '../hooks/useTranslationData';
import { DateTime } from 'luxon';
import InputPickers from './InputPickers';
type Type = 'time' | 'date' | 'dateTime';

interface InputPickerHelperProps {
    type: Type;
    onChange?: (data: string) => void;
    iconName: StsIconName;
    data: string;
    readonly?: boolean;
    iconDisable?: boolean;
    errorMessage?: string;
}
export const InputPickerHelper: FC<InputPickerHelperProps> = ({
    type,
    iconName,
    onChange,
    data,
    readonly,
    iconDisable,
    errorMessage,
}) => {
    const classes = useStyleWrapper(errorMessage);
    const [inputValue, setInputValues] = useState(data);
    const [showValue, setShowValue] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const buttonRef = useRef<HTMLDivElement>(null);
    const { draggableElementsDataTranslate } = useTranslationData();
    const placeholder: Record<Type, string> = {
        time: draggableElementsDataTranslate.placeholder.time,
        date: draggableElementsDataTranslate.placeholder.date,
        dateTime: draggableElementsDataTranslate.placeholder.dateTime,
    };

    useEffect(() => {
        if (!data) {
            return;
        }
        if (type === 'time' || type === 'dateTime') {
            if (data.trim() !== '') {
                const arrayOfData = data.split(' ');
                const zone = arrayOfData[arrayOfData.length - 1];

                const convertedZone = DateTime.now()
                    .setLocale('en-US')
                    .setZone(zone)
                    .offsetNameLong?.split(' ')
                    .map((i) => i[0])
                    .join('');

                arrayOfData[arrayOfData.length - 1] = convertedZone!;
                const value = arrayOfData.join(' ');
                setShowValue(value);
            }
        } else {
            setShowValue(data);
        }
        setInputValues(data);
    }, [data, type]);

    const triggerInput = useCallback(() => {
        setShowPicker(!showPicker);
    }, [showPicker]);

    return (
        <>
            <div className="d-flex  justify-content-between">
                <div
                    className={`${classes.inputContainer} w-100 d-flex justify-content-between ${
                        readonly ? classes.disabledInput : ''
                    }`}
                >
                    <div className="w-100 d-flex align-contents-center overflow-hidden">
                        {readonly || !data ? (
                            <input
                                value={showValue}
                                readOnly
                                placeholder={placeholder[type]}
                                className={`border-0 ml-3 m-3 ${readonly && classes.disabledInput}`}
                            ></input>
                        ) : (
                            <Typography
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 10, marginRight: 10 }}
                            >
                                {showValue}
                            </Typography>
                        )}
                    </div>

                    <div className="d-flex align-contents-center h-100" style={{ position: 'relative' }}>
                        <InputPickers
                            type={type}
                            show={showPicker}
                            value={inputValue}
                            onChange={onChange}
                            setShowTimePicker={setShowPicker}
                            buttonRef={buttonRef}
                        />

                        <div ref={buttonRef}>
                            <Button
                                disabled={iconDisable || readonly}
                                variant="link-header-button"
                                onPress={triggerInput}
                                style={{ marginRight: 10, marginLeft: 50, height: '100%' }}
                            >
                                <Icon name={iconName} color="graySix" style={{ fontWeight: '100' }} />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {errorMessage && (
                <Typography color="redOne" style={{ textAlign: 'center' }}>
                    {errorMessage}
                </Typography>
            )}
        </>
    );
};

const useStyleWrapper = createUseStyles((error) => ({
    inputContainer: {
        height: Platform.select({ web: 40, default: 48 }),
        paddingHorizontal: 12,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 3,
        borderWidth: 1,
        borderColor: colors.grayFive,
        border: `1px solid ${error && error !== '' ? colors.redOne : colors.black}`,
    },
    disabledInput: {
        backgroundColor: colors.grayTwo,
        color: colors.grayFour,
        border: `1px solid ${colors.grayFour}`,
    },
    textInput: {
        flex: 1,
        fontSize: 17,
        maxWidth: '100%',
        color: colors.textDarkPrimary,
        textAlignVertical: 'top',
    },
}));
