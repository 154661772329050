import * as React from 'react';
import { action, on, props, reducer, union } from 'ts-action';

import {
    IHaveYouSeen,
    IQmrsDashboardAlertLink,
    IQmrsDashboardAlertLinkListPayload,
    IQmrsDashboardAlertLinkPayload,
    IQmrsDashboardHaveYouSeenListPayload,
    IQmrsDashboardHaveYouSeenPayload,
    ICounterMeasureOfTheMonth,
    ICounterMeasureOfTheMonthPayload,
    ICounterMeasureOfTheMonthListPayload,
    IHelpfulLink,
    IHelfulLinkPayload,
    IHelfulLinksListPayload,
} from '@packages/models/api';

export enum QmrsDashboardActionList {
    GET_QMRSDASHBOARDALERTLINKS = 'GET_QMRSDASHBOARDALERTLINKS',
    UPDATE_QMRSDASHBOARDALERTLINK = 'UPDATE_QMRSDASHBOARDALERTLINK',
    DELETE_QMRSDASHBOARDALERTLINK = 'DELETE_QMRSDASHBOARDALERTLINK',
    CREATE_QMRSDASHBOARDALERTLINK = 'CREATE_QMRSDASHBOARDALERTLINK',
    GET_HAVEYOUSEEN = 'GET_HAVEYOUSEEN',
    UPDATE_HAVEYOUSEEN = 'UPDATE_HAVEYOUSEEN',
    DELETE_HAVEYOUSEEN = 'DELETE_HAVEYOUSEEN',
    CREATE_HAVEYOUSEEN = 'CREATE_HAVEYOUSEEN',
    GET_COUNTERMEASUREOFMONTH = 'GET_COUNTERMEASUREOFMONTH',
    UPDATE_COUNTERMEASUREOFMONTH = 'UPDATE_COUNTERMEASUREOFMONTH',
    DELETE_COUNTERMEASUREOFMONTH = 'DELETE_COUNTERMEASUREOFMONTH',
    CREATE_COUNTERMEASUREOFMONTH = 'CREATE_COUNTERMEASUREOFMONTH',
    GET_HELPFULLINKS = 'GET_HELPFULLINKS',
    UPDATE_HELPFULLINKS = 'UPDATE_HELPFULLINKS',
    DELETE_HELPFULLINKS = 'DELETE_HELPFULLINKS',
    CREATE_HELPFULLINKS = 'CREATE_HELPFULLINKS',
}

export const qmrsDashboardActions = {
    getAlertLinks: action(
        QmrsDashboardActionList.GET_QMRSDASHBOARDALERTLINKS,
        props<IQmrsDashboardAlertLinkListPayload>()
    ),
    updateAlertLink: action(
        QmrsDashboardActionList.GET_QMRSDASHBOARDALERTLINKS,
        props<IQmrsDashboardAlertLinkListPayload>()
    ),
    createAlertLink: action(
        QmrsDashboardActionList.DELETE_QMRSDASHBOARDALERTLINK,
        props<IQmrsDashboardAlertLinkListPayload>()
    ),
    deleteAlertLink: action(
        QmrsDashboardActionList.CREATE_QMRSDASHBOARDALERTLINK,
        props<IQmrsDashboardAlertLinkPayload>()
    ),
    getAllHaveYouSeen: action(QmrsDashboardActionList.GET_HAVEYOUSEEN, props<IQmrsDashboardHaveYouSeenListPayload>()),
    updateHaveYouSeen: action(
        QmrsDashboardActionList.UPDATE_HAVEYOUSEEN,
        props<IQmrsDashboardHaveYouSeenListPayload>()
    ),
    deleteHaveYouSeen: action(QmrsDashboardActionList.DELETE_HAVEYOUSEEN, props<IQmrsDashboardHaveYouSeenPayload>()),
    createHaveYouSeen: action(
        QmrsDashboardActionList.CREATE_HAVEYOUSEEN,
        props<IQmrsDashboardHaveYouSeenListPayload>()
    ),
    getAllCounterMeasureOfMonth: action(
        QmrsDashboardActionList.GET_COUNTERMEASUREOFMONTH,
        props<ICounterMeasureOfTheMonthListPayload>()
    ),
    updateCounterMeasureOfMonth: action(
        QmrsDashboardActionList.UPDATE_COUNTERMEASUREOFMONTH,
        props<ICounterMeasureOfTheMonthListPayload>()
    ),
    createCounterMeasureOfMonth: action(
        QmrsDashboardActionList.CREATE_COUNTERMEASUREOFMONTH,
        props<ICounterMeasureOfTheMonthListPayload>()
    ),
    deleteCounterMeasureOfMonth: action(
        QmrsDashboardActionList.DELETE_COUNTERMEASUREOFMONTH,
        props<ICounterMeasureOfTheMonthPayload>()
    ),
    getAllHelpfullinks: action(QmrsDashboardActionList.GET_HELPFULLINKS, props<IHelfulLinksListPayload>()),
    updateHelpfullink: action(QmrsDashboardActionList.UPDATE_HELPFULLINKS, props<IHelfulLinksListPayload>()),
    deleteHelpfullink: action(QmrsDashboardActionList.DELETE_HELPFULLINKS, props<IHelfulLinkPayload>()),
    createHelpfullink: action(QmrsDashboardActionList.CREATE_HELPFULLINKS, props<IHelfulLinksListPayload>()),
};

export interface IQmrsDashboardProps {
    qmrsDashboardAlertLinks: IQmrsDashboardAlertLink[];
    haveYouSeens: IHaveYouSeen[];
    counterMeasuresOfTheMonth: ICounterMeasureOfTheMonth[];
    helpfullinks: IHelpfulLink[];
}

export interface IQmrsDashboardState {
    qmrsDashboardAlertLinks: IQmrsDashboardAlertLink[];
    haveYouSeens: IHaveYouSeen[];
    counterMeasuresOfTheMonth: ICounterMeasureOfTheMonth[];
    helpfullinks: IHelpfulLink[];
    loading?: boolean;
}

export const qmrsDashboardAlertLinksBase: IQmrsDashboardAlertLink[] = [];
export const haveYouSeensBase: IHaveYouSeen[] = [];
export const counterMeasuresOfTheMonthBase: ICounterMeasureOfTheMonth[] = [];
export const helpfulLinksBase: IHelpfulLink[] = [];
const initialState: IQmrsDashboardState = {
    qmrsDashboardAlertLinks: qmrsDashboardAlertLinksBase,
    haveYouSeens: haveYouSeensBase,
    counterMeasuresOfTheMonth: counterMeasuresOfTheMonthBase,
    helpfullinks: helpfulLinksBase,
    loading: false,
};

const actionsUnion = union(...Object.values(qmrsDashboardActions));
export type QmrsDashboardActions = typeof actionsUnion.actions;
export type QmrsDashboardActionDispatcher = React.Dispatch<QmrsDashboardActions>;

export const qmrsDashboardReducer: React.Reducer<IQmrsDashboardState, QmrsDashboardActions> = reducer(
    initialState,
    on(qmrsDashboardActions.getAlertLinks, (state, { qmrsDashboardAlertLinks }) => {
        return {
            ...state,
            qmrsDashboardAlertLinks: [...qmrsDashboardAlertLinks],
        };
    }),
    on(qmrsDashboardActions.updateAlertLink, (state, { qmrsDashboardAlertLinks }) => {
        return {
            ...state,
            qmrsDashboardAlertLinks: [...qmrsDashboardAlertLinks],
        };
    }),
    on(qmrsDashboardActions.createAlertLink, (state, { qmrsDashboardAlertLinks }) => {
        return {
            ...state,
            qmrsDashboardAlertLinks: [...qmrsDashboardAlertLinks],
        };
    }),
    on(qmrsDashboardActions.deleteAlertLink, (state, { qmrsDashboardAlertLink }) => {
        const { qmrDashAlertLinksId } = qmrsDashboardAlertLink;
        const existingValuesInState = state.qmrsDashboardAlertLinks;
        const filteredList = existingValuesInState.filter((ev) => ev.qmrDashAlertLinksId !== qmrDashAlertLinksId) || [];
        return {
            ...state,
            qmrsDashboardAlertLinks: [...filteredList],
        };
    }),
    on(qmrsDashboardActions.getAllHaveYouSeen, (state, { haveYouSeens }) => {
        return {
            ...state,
            haveYouSeens: [...haveYouSeens],
        };
    }),
    on(qmrsDashboardActions.createHaveYouSeen, (state, { haveYouSeens }) => {
        return {
            ...state,
            haveYouSeens: [...haveYouSeens],
        };
    }),
    on(qmrsDashboardActions.updateHaveYouSeen, (state, { haveYouSeens }) => {
        return {
            ...state,
            haveYouSeens: [...haveYouSeens],
        };
    }),
    on(qmrsDashboardActions.deleteHaveYouSeen, (state, { haveYouSeen }) => {
        const existingHaveYouSeen = state.haveYouSeens;
        const filteredList =
            existingHaveYouSeen.filter((hys) => hys.qmrDashHaveYouSeenId !== haveYouSeen.qmrDashHaveYouSeenId) || [];
        return {
            ...state,
            haveYouSeens: [...filteredList],
        };
    }),
    on(qmrsDashboardActions.getAllCounterMeasureOfMonth, (state, { counterMeasuresOfTheMonth }) => {
        return {
            ...state,
            counterMeasuresOfTheMonth: [...counterMeasuresOfTheMonth],
        };
    }),
    on(qmrsDashboardActions.createCounterMeasureOfMonth, (state, { counterMeasuresOfTheMonth }) => {
        return {
            ...state,
            counterMeasuresOfTheMonth: [...counterMeasuresOfTheMonth],
        };
    }),
    on(qmrsDashboardActions.updateCounterMeasureOfMonth, (state, { counterMeasuresOfTheMonth }) => {
        return {
            ...state,
            counterMeasuresOfTheMonth: [...counterMeasuresOfTheMonth],
        };
    }),
    on(qmrsDashboardActions.deleteCounterMeasureOfMonth, (state, { counterMeasureOfTheMonth }) => {
        const existingCounterMeasureOftheMonth = state.counterMeasuresOfTheMonth;
        const filteredList =
            existingCounterMeasureOftheMonth.filter(
                (cmotm) => cmotm.qmrDashCounterMeasureId !== counterMeasureOfTheMonth.qmrDashCounterMeasureId
            ) || [];
        return {
            ...state,
            counterMeasuresOfTheMonth: [...filteredList],
        };
    }),
    on(qmrsDashboardActions.getAllHelpfullinks, (state, { helpfulLinks }) => {
        return {
            ...state,
            helpfullinks: [...helpfulLinks],
        };
    }),
    on(qmrsDashboardActions.createHelpfullink, (state, { helpfulLinks }) => {
        return {
            ...state,
            helpfullinks: [...helpfulLinks],
        };
    }),
    on(qmrsDashboardActions.updateHelpfullink, (state, { helpfulLinks }) => {
        return {
            ...state,
            helpfullinks: [...helpfulLinks],
        };
    }),
    on(qmrsDashboardActions.deleteHelpfullink, (state, { helpfulLink }) => {
        const existingHelpfulLinks = state.helpfullinks;
        const filteredList =
            existingHelpfulLinks.filter((hpl) => hpl.qmrDashHelpfulLinksId !== helpfulLink?.qmrDashHelpfulLinksId) ||
            [];
        return {
            ...state,
            helpfullinks: [...filteredList],
        };
    })
);
