import React from 'react';
import { cloneDeep } from 'lodash';
import { FC, LegacyRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon, TextInputHelper, Typography, TextBadge } from '@packages/ui/shared';
import {
    ConditionalElement,
    DraggableFormElement,
    FormElementAction,
    WorksheetFormElementOption,
    draggableFormElements,
} from '@packages/models/api';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import uuid from 'uuid';
import { Platform } from 'react-native';
import QuantityMenu from './form-quantity-menu-option';
import { findLastIndex } from 'lodash';
import useTranslationData from './hooks/useTranslationData';

const useDropdownStyles = createUseStyles({
    input: {
        height: Platform.select({ web: 40, default: 48 }),
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        borderRadius: 3,
        borderWidth: 1,
        border: `1px solid ${colors.grayFive}`,
        position: 'relative',
    },
    dropdown: {
        position: 'absolute',
        top: Platform.select({ web: 42, default: 50 }),
        backgroundColor: colors.white,
        maxHeight: 280,
        minHeight: 100,
        minWidth: 325,
        borderRadius: 3,
        border: `1px solid ${colors.grayFive}`,
        overflowY: 'scroll',
        zIndex: 1000,
        boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
    },
    dropdownItem: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 10px',
        '&:hover': {
            backgroundColor: `${colors.grayOne} !important`,
            color: `${colors.black} !important`,
        },
    },
});

interface FormQuantityMenuItemListProps {
    onChange?({
        options,
        optionConditionsElements,
        formElementHasRule,
    }: {
        optionConditionsElements: ConditionalElement[];
        options: WorksheetFormElementOption[];
        formElementHasRule: boolean;
    }): void;
    optionId: string;
    formElement: DraggableFormElement;
}

const getCurrentDimension = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
    };
};

export interface SelectionElement extends DraggableFormElement {
    count: number;
}

export const FormQuantityMenuItemList: FC<FormQuantityMenuItemListProps> = ({
    formElement,
    optionId,
    onChange,
}: FormQuantityMenuItemListProps) => {
    const [open, setOpen] = useState(false);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [menuPosition, setMenuPosition] = useState({ top: Platform.select({ web: 40, default: 48 }), left: 0 });
    const classes = useDropdownStyles();

    //conditional formElement array with dummy data
    const conditionalOptionsArray: SelectionElement[] = cloneDeep(
        Object.values(draggableFormElements).map((option, index) => ({
            ...option,
            formElementOrder: index + 1,
            count: 0,
        }))
    );

    const [conditionalOptions, setConditionalOptions] = useState(conditionalOptionsArray);
    const [filteredConditionalOptions, setFilteredConditionalOptions] = useState(conditionalOptionsArray);
    const [searchSearchString, setSearchString] = useState<string>('');
    const { draggableElementsDataTranslate } = useTranslationData();
    const { optionConditionsElements } = formElement;
    const ref = useRef();
    const inputRef = useRef();
    const calculatePosition = useCallback(() => {
        const dropdownNode = ref.current as unknown as HTMLDivElement;
        const inputNode = inputRef.current as unknown as HTMLDivElement;
        if (dropdownNode) {
            const { left, right } = dropdownNode.getBoundingClientRect();
            const { top: iTop } = inputNode?.getBoundingClientRect();

            const { innerHeight, innerWidth } = window;

            // Calculate the space available on the left and right of the target element
            const spaceOnLeft = left;
            const spaceOnRight = innerWidth - right;

            // Determine if there is more space available on the top or bottom
            const showOnTop = innerHeight < iTop + inputNode?.offsetHeight + dropdownNode.offsetHeight;

            // Determine if there is more space available on the left or right
            const showOnLeft = spaceOnLeft > spaceOnRight;

            // Calculate the top and left position of the dropdown menu
            const topPosition = showOnTop ? -dropdownNode.offsetHeight : 42;
            const leftPosition = showOnLeft ? left - dropdownNode.offsetWidth : right - dropdownNode.offsetWidth;
            setMenuPosition({ top: topPosition, left: leftPosition });
        }
    }, [ref, inputRef]);

    //calculate positions
    useEffect(() => {
        calculatePosition();
    }, [screenSize]);

    //handle responsive popup
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
        };
        window.addEventListener('resize', updateDimension);

        return () => {
            window.removeEventListener('resize', updateDimension);
        };
    }, []);

    // The count of selected conditional types for show condition questions length

    const showCount = useMemo(() => {
        const conditionalOptionsClone = cloneDeep(conditionalOptions);
        return conditionalOptionsClone?.filter((option) => option.count);
    }, [conditionalOptions]);

    // Set count conditional questions by selected optionConditionsElements
    useEffect(() => {
        const filteredOptions = cloneDeep(conditionalOptions).map((item) => {
            const matchingElements = optionConditionsElements?.filter(
                (element) => element.formElementTypeId === item.formElementTypeId && element.targetOptionId === optionId
            );
            return {
                ...item,
                count: matchingElements?.length || 0,
            };
        });
        setConditionalOptions(filteredOptions);
        setFilteredConditionalOptions(filteredOptions);
    }, []);

    const handleQuantity = useCallback(
        ({ field, increase }: { field: SelectionElement; increase: boolean }) => {
            const selectedType = field.formElementTypeId;
            const optionsClone = cloneDeep(formElement.options);
            const conditionTypeOptions = cloneDeep(conditionalOptions);

            if (!optionsClone) {
                return;
            }
            const updateOption = conditionTypeOptions.map((item: SelectionElement) => {
                return item.formElementTypeId === selectedType
                    ? { ...item, count: increase ? item.count + 1 : item.count - 1 }
                    : item;
            });

            const option = optionsClone.find((opt) => opt.optionId === optionId);

            if (!option) {
                return;
            }
            const optionConditionsElementsClone = cloneDeep(formElement.optionConditionsElements || []);

            if (increase) {
                const newFormComponent: SelectionElement = Object.assign(field, { formElementId: uuid() });
                if (newFormComponent.options) {
                    newFormComponent.options = newFormComponent.options.map((option, index) => {
                        option.optionId = uuid();
                        option.optionValue = String(index);
                        return option;
                    });
                }
                newFormComponent.action = FormElementAction.Insert;
                optionConditionsElementsClone.push({
                    ...newFormComponent,
                    targetOptionId: optionId,
                });
                // add trgtElementId in option
                option.trgtElementIdList = [...(option.trgtElementIdList || []), newFormComponent.formElementId];
            } else {
                const optionConditionIndex = findLastIndex(
                    optionConditionsElementsClone,
                    (optCon) => optCon.formElementTypeId === selectedType && optCon.targetOptionId === optionId
                );
                // delete trgtElementId in option
                option.trgtElementIdList = option.trgtElementIdList?.filter(
                    (trg) => trg !== optionConditionsElementsClone[optionConditionIndex].formElementId
                );

                optionConditionsElementsClone.splice(optionConditionIndex, 1);
            }

            formElement.formElementHasRule = !!optionConditionsElementsClone.length;

            onChange?.({
                formElementHasRule: formElement.formElementHasRule,
                options: optionsClone,
                optionConditionsElements: optionConditionsElementsClone,
            });

            setConditionalOptions(Object.assign(cloneDeep(conditionalOptions), updateOption));
            onSearch(searchSearchString, updateOption);
        },
        [formElement, conditionalOptions, onChange, searchSearchString, optionId]
    );

    const onSearch = useCallback(
        (text: string, options?: SelectionElement[]) => {
            let _options = [...(options || conditionalOptions)];
            if (text !== '') {
                _options = _options?.filter(
                    (option) => option?.description?.toLocaleLowerCase()?.includes(text.toLowerCase())
                );
            }
            setSearchString(text);
            setFilteredConditionalOptions(_options);
        },
        [conditionalOptions]
    );

    return (
        <>
            {open ? (
                <div
                    style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 10,
                    }}
                    onClick={() => {
                        setOpen(false);
                    }}
                />
            ) : (
                <></>
            )}
            <div
                className={classes.input}
                ref={inputRef as unknown as LegacyRef<HTMLDivElement>}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                    calculatePosition();
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        padding: '0 10px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <Typography color={'grayFive'}>
                        {showCount?.length
                            ? draggableElementsDataTranslate.labels.continueWith
                            : draggableElementsDataTranslate.labels.nothingHappens}
                    </Typography>
                    {showCount?.length > 0 ? (
                        <TextBadge variant="blue" textStyle={{ color: colors.blueOne }}>
                            {showCount?.length}
                        </TextBadge>
                    ) : (
                        <></>
                    )}
                    <Icon name="chevron-down" size={16} />
                </div>
                {open ? (
                    <div
                        className={classes.dropdown}
                        ref={ref as unknown as LegacyRef<HTMLDivElement>}
                        style={{ top: menuPosition.top }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <>
                            <TextInputHelper
                                onChangeText={onSearch}
                                inputContainerStyle={{ borderWidth: 0 }}
                                placeholder={draggableElementsDataTranslate.placeholder.search}
                            />
                            <div>
                                {filteredConditionalOptions?.map((option) => {
                                    return (
                                        <QuantityMenu
                                            option={option}
                                            handleQuantity={handleQuantity}
                                            key={option.formElementTypeId}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
