import React, { FC, useCallback } from 'react';

import { TableActionHeader, TableCheckbox, TableHeader, TableRow } from '@web/components/table';
import { Typography, Button, Icon } from '@packages/ui/shared';
import { FailCode } from '@packages/models/api/fail-codes';

interface FailCodeTableHeadProps {
    selectAll?: boolean;
    selectedCount?: number;
    onSelectAllChange(event: React.ChangeEvent<HTMLInputElement>): void;
    onActionPress?(actionId: string): void;
    selectedItems?: any[] | null;
    selectedFailCodeSearch?: FailCode;
    handleModalClose: () => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FailCodeTableHead: FC<FailCodeTableHeadProps> = ({
    selectAll,
    onSelectAllChange,
    selectedCount,
    onActionPress,
    selectedItems,
    selectedFailCodeSearch,
    handleModalClose,
    setIsLoading,
}) => {
    const handleActionPress = useCallback(
        (actionId: string) => {
            if (!onActionPress) {
                return;
            }

            onActionPress(actionId);
        },
        [onActionPress]
    );

    return (
        <TableRow>
            <TableHeader fixed hideBorder={!selectAll}>
                <TableCheckbox
                    disabled={selectedFailCodeSearch ? true : false}
                    checked={selectAll}
                    onChange={onSelectAllChange}
                    iconName="minus"
                />
            </TableHeader>
            {selectAll ? (
                <>
                    <TableActionHeader colspan={10} selectedCount={selectedCount}>
                        <Button
                            variant="ghost-blue"
                            iconLeft={<Icon name="download" color="blueOne" size={20} />}
                            onPress={() => {
                                handleActionPress('DOWNLOAD');
                                setIsLoading(true);
                                setTimeout(() => {
                                    setIsLoading(false);
                                    handleModalClose();
                                }, 500);
                            }}
                        >
                            Export to CSV
                        </Button>
                    </TableActionHeader>
                </>
            ) : (
                <>
                    <TableHeader fixed hideBorder width={'calc(50vh)'}>
                        <Typography variant="label">Fail Code</Typography>
                    </TableHeader>
                    <TableHeader>
                        <Typography variant="label">Description</Typography>
                    </TableHeader>
                </>
            )}
        </TableRow>
    );
};
