import React, { FC, useCallback } from 'react';
import { StyleSheet, StyleProp, ViewStyle, View, ListRenderItemInfo, FlatList } from 'react-native';

import { QmrsService, useQmrPolling } from '@packages/contexts/qmrs';
import { Qmr, QmrActivity } from '@packages/models/api';
import colors from '@packages/core/styles/colors';
import { DynamicJSX } from '../shared';

const styles = StyleSheet.create({
    rootContainer: {
        paddingTop: 24,
    },
    activityLogItem: {
        flexDirection: 'row',
    },
    activityLineWrapper: {
        width: 48,
        alignItems: 'center',
    },
    activityLine: {
        width: 2,
        flex: 1,
        backgroundColor: colors.grayFive,
    },
    activityDotWrapper: {
        position: 'absolute',
        borderRadius: 50,
        width: 14,
        height: 14,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
    },
    activityDot: {
        borderRadius: 50,
        width: 10,
        height: 10,
        backgroundColor: colors.grayFive,
    },
});

interface QmrActivitySegmentProps {
    qmr: Qmr | null;
    style?: StyleProp<ViewStyle>;
    qmrsService: QmrsService;
}

export const QmrActivitySegment: FC<QmrActivitySegmentProps> = ({ qmr, qmrsService }) => {
    useQmrPolling({
        qmrsService,
        pollImmediately: true,
    });

    const activityItemRenderer = useCallback(
        ({ item, index }: ListRenderItemInfo<QmrActivity>) => {
            if (!qmr || !qmr.qmrActivities) {
                return null;
            }

            const isLastItem = index === qmr.qmrActivities.length - 1;

            return (
                <View style={styles.activityLogItem}>
                    <View style={styles.activityLineWrapper}>
                        {!isLastItem && <View style={styles.activityLine} />}
                        <View style={styles.activityDotWrapper}>
                            <View style={styles.activityDot} />
                        </View>
                    </View>

                    <DynamicJSX jsx={item.bodyHtml} />
                </View>
            );
        },
        [qmr]
    );

    if (!qmr || !qmr.capabilities.viewActivityLog) {
        return null;
    }

    return (
        <FlatList
            data={qmr.qmrActivities}
            keyboardDismissMode="interactive"
            renderItem={activityItemRenderer}
            contentContainerStyle={styles.rootContainer}
            keyExtractor={(item) => item.qmrActivityId}
        />
    );
};
