import React, { FC, useCallback, useState } from 'react';
import { Dropdown, FormCheck } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import colors from '@packages/core/styles/colors';
import { Collapse, Icon, Typography } from '@packages/ui/shared';
import { AccountRole } from '@packages/models/api';

const useStyles = createUseStyles({
    dropdownToggle: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        width: '100%',
        borderRadius: 3,
        cursor: 'pointer',
        padding: '0 12px',
        appearance: 'none',
        color: colors.black,
        textAlign: 'left',
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayFive}`,
        '&:after, &:before': {
            display: 'none !important',
        },
        '&:hover': {
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
        },
        '&:focus, &:active': {
            outline: 'none',
            border: `1px solid ${colors.blueOne}`,
        },
        '&:disabled': {
            cursor: 'default',
            color: colors.grayFive,
            backgroundColor: colors.grayThree,
            '&:hover': {
                boxShadow: 'none',
            },
            '&:active': {
                border: `1px solid ${colors.grayFive}`,
            },
        },
    },
    sessionMenu: {
        width: '100%',
        padding: '16px 0',
        borderRadius: `3px !important`,
    },
    sessionMenuScroller: {
        maxHeight: 256,
        overflowY: 'auto',
    },
    dropdownAccordionItem: {
        display: 'flex',
        padding: '8px 16px',
        alignItems: 'center',
        textDecoration: 'none',
        justifyContent: 'space-between',
        borderTop: `1px solid ${colors.grayThree}`,
    },
    dropdownItem: {
        '&:first-child': {
            paddingTop: 12,
        },
        '&:last-child': {
            paddingBottom: 8,
        },
    },
});

interface DropdownToggleProps {
    id: string;
    className?: string;
    onClick?(event: React.MouseEvent): void;
    children?: React.ReactNode;
}

const DrodownTogggle: FC<DropdownToggleProps> = React.forwardRef(({ children, className, id, onClick }, ref) => {
    function handleSessionToggleClick(event: React.MouseEvent) {
        event.preventDefault();

        if (onClick) {
            onClick(event);
        }
    }

    return (
        // @ts-ignore
        <button ref={ref} id={id} className={className} onClick={handleSessionToggleClick}>
            {children}
        </button>
    );
});

interface DropdownMenuProps {
    style?: any;
    className?: string;
    'aria-labelledby'?: string;
    children?: React.ReactNode;
}

const DropdownMenu: FC<DropdownMenuProps> = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        return (
            // @ts-ignore
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                <ul className="m-0 list-unstyled">{children}</ul>
            </div>
        );
    }
);

interface RoleDropdownProps {
    id: string;
    accountRoles: AccountRole[];
    selectedJobIds: string[];
    onSelect: (selectedJobId: string) => void;
}

export const RoleDropdown: FC<RoleDropdownProps> = ({ id, accountRoles, selectedJobIds, onSelect }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [expandedRoles, setExpandedRoles] = useState<Record<string, boolean>>({});

    /* -------------------------------------------------- */
    /* Hack to get dropdown to stay open */
    /* -------------------------------------------------- */
    function handleDropdownToggle(
        isOpen: boolean,
        _event: React.SyntheticEvent<Dropdown, Event>,
        metadata: {
            source: 'select' | 'click' | 'rootClose' | 'keydown';
        }
    ) {
        if (metadata.source === 'select') {
            setOpen(true);
            return;
        } else {
            // Auto close all sections with no selections on blur
            setExpandedRoles(
                accountRoles.reduce((newExpandedState, accountRole) => {
                    return {
                        ...newExpandedState,
                        [accountRole.roleId]: accountRole.jobTitles.some((jobTitle) =>
                            selectedJobIds.includes(jobTitle.jobTitleId)
                        ),
                    };
                }, {})
            );
        }

        setOpen(isOpen);
    }

    const selectionsForDisplay = useCallback(() => {
        let display: string[] = [];

        accountRoles.forEach((section) => {
            const selectedJobs = section.jobTitles.filter((jobTitle) => selectedJobIds.includes(jobTitle.jobTitleId));
            const selectedCount = selectedJobs.length;

            if (selectedCount > 0) {
                display = display.concat(`${section.roleDescription} (${selectedCount})`);
            }
        });

        return display.length > 0 ? display.join(', ') : 'Select Targets...';
    }, [accountRoles, selectedJobIds]);

    return (
        <Dropdown show={open} onToggle={handleDropdownToggle}>
            <Dropdown.Toggle as={DrodownTogggle} id={id} className={classes.dropdownToggle}>
                <Typography variant="lead" numberOfLines={1}>
                    {selectionsForDisplay()}
                </Typography>
            </Dropdown.Toggle>
            <Dropdown.Menu as={DropdownMenu} className={classes.sessionMenu}>
                <div className={classes.sessionMenuScroller}>
                    {accountRoles.map((accountRole) => {
                        const isExpanded = expandedRoles[accountRole.roleId];
                        const selectedJobs = accountRole.jobTitles.filter((jobTitle) =>
                            selectedJobIds.includes(jobTitle.jobTitleId)
                        );

                        return (
                            <div key={accountRole.roleId}>
                                <Dropdown.Item
                                    key={accountRole.roleId}
                                    className={classes.dropdownAccordionItem}
                                    onClick={() => {
                                        setExpandedRoles({
                                            ...expandedRoles,
                                            [accountRole.roleId]: !isExpanded,
                                        });
                                    }}
                                >
                                    <div className="d-flex align-items-center">
                                        <Typography variant="label">{accountRole.roleDescription}</Typography>
                                        <div className="ml-2">
                                            <Typography variant="caption" color="grayFive">
                                                {selectedJobs.length} selected
                                            </Typography>
                                        </div>
                                    </div>
                                    {isExpanded ? <Icon name="minus" /> : <Icon name="plus" />}
                                </Dropdown.Item>
                                <Collapse open={isExpanded}>
                                    {accountRole.jobTitles.map((jobTitle) => {
                                        const isChecked = selectedJobIds.includes(jobTitle.jobTitleId);

                                        return (
                                            <Dropdown.Item
                                                key={jobTitle.jobTitleId}
                                                className={classes.dropdownItem}
                                                onClick={() => {
                                                    onSelect(jobTitle.jobTitleId);
                                                }}
                                            >
                                                <FormCheck label={jobTitle.jobTitleDescription} checked={isChecked} />
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Collapse>
                            </div>
                        );
                    })}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
