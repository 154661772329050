import React, { FC } from 'react';
import InputPickerWrapper from './InputPickerWrapper';
import TimePicker from './TimePicker';
import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';

type Props = {
    value: string;
    onChange: ((data: string) => void) | undefined;
    show: boolean;
    setShowTimePicker: (x: boolean) => void;
    format?: string;
    type: 'date' | 'time' | 'dateTime';
    buttonRef: React.RefObject<HTMLDivElement>;
};

const InputPickers: FC<Props> = ({ value, onChange, show, setShowTimePicker, format, type, buttonRef }) => {
    return (
        <>
            <InputPickerWrapper show={show} setShow={setShowTimePicker} buttonRef={buttonRef}>
                {type === 'time' && <TimePicker value={value} onChange={onChange} format={format} show={show} />}
                {type === 'date' && <DatePicker value={value} onChange={onChange} format={format} show={show} />}
                {type === 'dateTime' && (
                    <DateTimePicker value={value} onChange={onChange} format={format} show={show} />
                )}
            </InputPickerWrapper>
        </>
    );
};

export default InputPickers;
