import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import RecentSearchTypeahead from '@web/components/recent-search-typeahead';
import { AlertBar, Button, Icon, TextBadge, Typography } from '@packages/ui/shared';
import {
    Table,
    TableBody,
    TableCell,
    TableCheckbox,
    TableHead,
    TableHeader,
    TablePagination,
    TableRow,
} from '@web/components/table';

const useStyles = createUseStyles({
    cursorPointer: {
        cursor: 'pointer',
    },
});

export const ManageWorksheets: FC = () => {
    const classes = useStyles();

    const [filters, setFilters] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    const selectedTabHeadStyles = {
        borderBottomColor: colors.blueOne,
        color: colors.blueOne,
        borderBottomWidth: 2,
        paddingBottom: 5,
    };

    const renderTopFilters = () => {
        return (
            <div className="d-flex flex-row mt-5">
                <div className={`${classes.cursorPointer} d-flex`}>
                    <Typography style={true ? selectedTabHeadStyles : {}} color="grayFive" variant="h6">
                        All
                    </Typography>
                </div>
                <div className={`${classes.cursorPointer} ml-5 d-flex`}>
                    <Typography color="grayFive" variant="h6">
                        Pending
                    </Typography>
                </div>
                <div className={`${classes.cursorPointer} ml-5 d-flex`}>
                    <Typography color="grayFive" variant="h6">
                        Pilot
                    </Typography>
                </div>
                <div className={`${classes.cursorPointer} ml-5 d-flex`}>
                    <Typography color="grayFive" variant="h6">
                        Published
                    </Typography>
                </div>
                <div className={`${classes.cursorPointer} ml-5 d-flex`}>
                    <Typography color="grayFive" variant="h6">
                        Archived
                    </Typography>
                </div>
            </div>
        );
    };

    const renderTopSearchAndCreateWorksheet = () => {
        return (
            <div className="d-flex flex-row justify-content-between align-items-center mt-5">
                <div style={{ flex: 5 }} className="d-flex flex-row align-items-center">
                    <div className="w-50">
                        <RecentSearchTypeahead
                            onChange={(query) => setSearchQuery(query)}
                            placeholder="Search for a Worksheet"
                            recentSearches={[]}
                            quickSearchQuery={searchQuery}
                        />
                    </div>
                    <div className="ml-5">
                        {0 ? (
                            <div className="d-flex flex-row">
                                <Button
                                    active={false}
                                    variant="info"
                                    iconLeft={<Icon color="blueOne" name="filter-list" />}
                                    onPress={() => {}}
                                >
                                    <Typography variant="h5" color="blueOne">
                                        Applied filters (0)
                                    </Typography>
                                </Button>
                                <div className="ml-5">
                                    <Button variant="ghost-blue" onPress={() => {}}>
                                        Clear all
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <Button
                                active={false}
                                variant="ghost-blue"
                                iconLeft={<Icon color="blueOne" name="filter-list" />}
                                onPress={() => {}}
                            >
                                Filters
                            </Button>
                        )}
                    </div>
                </div>
                <div className="d-flex justify-content-end" style={{ flex: 5 }}>
                    <Button
                        iconRight={
                            <Icon
                                size={16}
                                raised
                                name="plus"
                                raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                            />
                        }
                        onPress={() => {}}
                    >
                        Create a new worksheet
                    </Button>
                </div>
            </div>
        );
    };

    const renderTable = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>
                            <Typography variant="h5">Name</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Case type</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Status</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Last Update</Typography>
                        </TableHeader>
                        <TableHeader></TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography>
                                <a href="" target="_blank">
                                    Interior inspection checklist
                                </a>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Body</Typography>
                        </TableCell>
                        <TableCell className="d-flex">
                            <TextBadge variant="yellow">PILOT</TextBadge>
                        </TableCell>
                        <TableCell>
                            <Typography>May 2, 2023</Typography>
                        </TableCell>
                        <TableCell className="d-flex align-items-center justify-content-center">
                            <Typography>
                                <a href="" target="_blank">
                                    Add version
                                </a>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography>
                                <a href="" target="_blank">
                                    A/C performance testing
                                </a>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>HVAC</Typography>
                        </TableCell>
                        <TableCell className="d-flex">
                            <TextBadge variant="red">PENDING</TextBadge>
                        </TableCell>
                        <TableCell>
                            <Typography>May 2, 2023</Typography>
                        </TableCell>
                        <TableCell className="d-flex align-items-center justify-content-center">
                            <Typography>
                                <a href="/administration/worksheets/1234/edit" target="_blank">
                                    Edit
                                </a>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography>
                                <a href="" target="_blank">
                                    Wheel alignment measurement form
                                </a>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Steering and suspension</Typography>
                        </TableCell>
                        <TableCell className="d-flex">
                            <TextBadge variant="red">PENDING</TextBadge>
                            <TextBadge style={{ marginLeft: 5 }} variant="green">
                                PUBLISHED
                            </TextBadge>
                            <TextBadge style={{ marginLeft: 5 }} variant="default">
                                ARCHIVED
                            </TextBadge>
                        </TableCell>
                        <TableCell>
                            <Typography>May 2, 2023</Typography>
                        </TableCell>
                        <TableCell className="d-flex align-items-center justify-content-center">
                            <Typography>
                                <a href="/administration/worksheets/1234/edit" target="_blank">
                                    Edit
                                </a>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography>
                                <a href="" target="_blank">
                                    Wheel alignment measurement form
                                </a>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Steering and suspension</Typography>
                        </TableCell>
                        <TableCell className="d-flex">
                            <TextBadge variant="red">PENDING</TextBadge>
                            <TextBadge style={{ marginLeft: 5 }} variant="green">
                                PUBLISHED
                            </TextBadge>
                        </TableCell>
                        <TableCell>
                            <Typography>May 2, 2023</Typography>
                        </TableCell>
                        <TableCell className="d-flex align-items-center justify-content-center">
                            <Typography>
                                <div className={`${classes.cursorPointer}`}>
                                    <Icon name="chevron-down" color="black" />
                                </div>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    return (
        <div className="pt-7 pl-7 pr-7 d-flex h-100 w-100">
            <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row">
                    <Typography color="black" variant="h2">
                        Worksheets
                    </Typography>
                </div>
                {renderTopFilters()}
                {renderTopSearchAndCreateWorksheet()}
                <div className="d-flex mt-5 flex-column h-100">
                    <div className="d-flex flex-column mt-5 h-100">
                        {renderTable()}
                        <TablePagination page={0} size={10} total={20} onClick={() => {}} onSizeChange={() => {}} />
                    </div>
                </div>
            </div>
        </div>
    );
};
