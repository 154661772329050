import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Typography } from '../../shared';
import { Button } from '../../shared/button';
import { modalDefaultStyles, Popup, PopupProps } from '../../shared/popup';

interface Props extends PopupProps {
    onOkPress: () => void;
}

export const PartRequestsClosedPopup: FC<Props> = ({ onOkPress, ...props }) => {
    const { t } = useTranslation();

    return (
        <Popup {...props}>
            <View style={modalDefaultStyles.header}>
                <Typography variant="h4">{t('qmr:popups.partRequests.closed.title', 'Closed Requests')}</Typography>
            </View>

            <View style={modalDefaultStyles.content}>
                <Typography variant="lead">
                    {t('qmr:popups.partRequests.closed.message', '[TODO]: Get copy for this popup.')}
                </Typography>
            </View>

            <View style={modalDefaultStyles.footer}>
                <Button title={t('qmr:popups.partRequests.closed.ok', 'Got it')} onPress={onOkPress} />
            </View>
        </Popup>
    );
};
