import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import colors from '@packages/core/styles/colors';
import { Typography } from '@packages/ui/shared';

const useTableActionHeaderStyles = createUseStyles({
    tableHeader: {
        top: 0,
        zIndex: 1,
        padding: 0,
        position: 'sticky',
    },
    tableHeaderContent: {
        height: 58,
        display: 'flex',
        padding: '0 16px',
        overflow: 'hidden',
        alignItems: 'center',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
});

interface TableActionHeaderProps {
    colspan?: number;
    className?: string;
    selectedCount?: number;
    children?: React.ReactNode;
}

export const TableActionHeader: FC<TableActionHeaderProps> = React.memo(
    ({ selectedCount = 0, colspan, className, children }) => {
        const classes = useTableActionHeaderStyles();

        return (
            <th className={classes.tableHeader} colSpan={colspan}>
                <div className={classNames(classes.tableHeaderContent, className)}>
                    <Typography variant="label" color="blueOne">
                        {selectedCount} Selected
                    </Typography>
                    <div className="ml-4 d-flex">{children}</div>
                </div>
            </th>
        );
    }
);
