import React, { FC } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';

import { ReactComponent as HandleIcon } from '@web/assets/icon-handle.svg';

import { Button, Icon, StsIconName, TextBadge, Typography } from '@packages/ui/shared';
import { DraggableFormComponent, DraggableWorksheetFormComponent } from '@packages/models/api';
import { FeedbackFormElement } from '@web/feedback/components';
import { WorksheetFormElement } from '../views/worksheets/components/worksheet-form-element';

export interface FormType {
    Worksheet: 'Worksheet';
}
interface FormComponentProps {
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    item: DraggableFormComponent;
    editIconName?: StsIconName;
    deleteIconName?: StsIconName;
    copyIconName?: StsIconName;
    onEditClick?(): void;
    onDeleteClick?(): void;
    onCopyClick?(): void;
}

export const FormComponent: FC<FormComponentProps> = ({
    provided,
    snapshot,
    item,
    onEditClick,
    onDeleteClick,
    onCopyClick,
    copyIconName,
    editIconName,
    deleteIconName,
}) => {
    const classes = useFormComponentStyles();

    return (
        <div className={classes.formComponentOuter} ref={provided.innerRef} {...provided.draggableProps}>
            <div
                className={classNames({
                    [classes.formComponent]: true,
                    [classes.formComponentIsDragging]: snapshot.isDragging,
                })}
            >
                <div className={classes.toolbar}>
                    <div className="d-flex align-items-center">
                        <div className={classes.handle} {...provided.dragHandleProps}>
                            <HandleIcon />
                        </div>
                        <Typography variant="labelRegular">{item.description}</Typography>
                    </div>
                    <div className="d-flex align-items-center">
                        {onCopyClick && (
                            <div className="ml-2">
                                <Button variant="ghost-blue" onPress={onCopyClick}>
                                    {copyIconName ? <Icon name={copyIconName} /> : 'copy'}
                                </Button>
                            </div>
                        )}
                        <div>
                            <Button variant="ghost-blue" onPress={onEditClick}>
                                {editIconName ? <Icon name={editIconName} /> : 'Edit'}
                            </Button>
                        </div>
                        <div className="ml-2">
                            <Button variant="danger" onPress={onDeleteClick}>
                                {deleteIconName ? <Icon name={deleteIconName} color="white" /> : 'Delete'}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={classes.preview}>
                    <FeedbackFormElement item={item} />
                </div>
            </div>
        </div>
    );
};

const useFormComponentStyles = createUseStyles({
    formComponentOuter: {
        paddingBottom: 16,
    },
    formComponent: {
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
    },
    formComponentIsDragging: {
        border: `1px dashed ${colors.black}`,
    },
    toolbar: {
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    handle: {
        width: 44,
        height: 44,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    description: {
        ...fonts.xxs,
    },
    preview: {
        padding: '24px 20px 0 20px',
    },
});
