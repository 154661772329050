import React, { FC, useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';

import { Button, Label, TextareaHelper, Typography } from '@packages/ui/shared';
import { analyticsService, feedbackService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { FeedbackRecipient } from '@packages/models/api';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

interface RequestFeedbackModalProps extends ModalProps {
    onFeedbackRequested: () => void;
}

export const RequestFeedbackModal: FC<RequestFeedbackModalProps> = ({ show, onHide, onFeedbackRequested }) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();

    const [recipientOptions, setRecipientOptions] = useState<FeedbackRecipient[]>([]);
    const [showRequestMessageInput, setShowRequestMessageInput] = useState(false);
    const [requestMessage, setRequestMessage] = useState('');
    const [selectedRecipients, setSelectedRecipients] = useState<FeedbackRecipient[]>([]);
    const [isStartingSession, setIsStartingSession] = useState(false);

    const searchRecipients = useCallback(
        async (query = '', selected: FeedbackRecipient[] = []) => {
            try {
                const response = await feedbackService.searchFeedbackRecipients({
                    query,
                    selectedIds: selected.map((r) => r.id),
                    isRequestFeedback: true,
                    signal: abortSignalRef.current,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setRecipientOptions(response.data.recipients.availableRecipients);
            } catch (error) {
                alert(error.message);
            }
        },
        [abortSignalRef]
    );

    const startSession = useCallback(() => {
        setIsStartingSession(true);

        return feedbackService
            .startFeedbackSession({
                feedbackRecipientIds: selectedRecipients.map((r) => r.id),
                feedbackRequest: true,
                requestMessage,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setIsStartingSession(false);

                onFeedbackRequested();
            })
            .catch((e) => {
                setIsStartingSession(false);
                throw e;
            });
    }, [abortSignalRef, requestMessage, onFeedbackRequested, selectedRecipients]);

    useEffect(() => {
        if (!show) {
            setRecipientOptions([]);
            setSelectedRecipients([]);
            setShowRequestMessageInput(false);
            setRequestMessage('');
            return;
        }

        searchRecipients();
    }, [abortSignalRef, searchRecipients, show]);

    return (
        <Modal show={show} centered={true} onHide={onHide} size="lg">
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>{t('modals:requestFeedback.title', 'Request Feedback')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {showRequestMessageInput ? (
                    <>
                        <div className="mb-6">
                            <Typography variant="label">
                                {t('modals:requestFeedback.recipientsLabel', 'Recipient(s)')}
                            </Typography>

                            <div>
                                <Typography>{selectedRecipients.map((r) => r.displayName).join(', ')}</Typography>
                            </div>
                        </div>

                        <TextareaHelper
                            controlId="feedbackRequestDetail"
                            label={t('modals:requestFeedback.requestDetail.label', 'Compose your feedback request')}
                            value={requestMessage}
                            onChangeText={(newVal) => {
                                setRequestMessage(newVal);
                            }}
                            placeholder={t(
                                'modals:requestFeedback.requestDetail.placeholder',
                                'What is the one thing I do well?'
                            )}
                        />
                    </>
                ) : (
                    <>
                        <Label required>{t('modals:requestFeedback.recipientSearch.label', 'Enter name')}</Label>

                        <AsyncTypeahead
                            multiple
                            id="feedback-recipients"
                            // placeholder=""
                            selected={selectedRecipients}
                            labelKey="displayName"
                            isLoading={false}
                            useCache={false}
                            minLength={1}
                            onSearch={(query) => {
                                searchRecipients(query, selectedRecipients);
                            }}
                            options={recipientOptions}
                            onChange={(selected) => {
                                setSelectedRecipients(selected);
                            }}
                        />
                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                <div className="mr-2">
                    <Button variant="ghost-blue" onPress={onHide}>
                        {t('modals:requestFeedback.actions.cancel', 'Cancel')}
                    </Button>
                </div>

                <Button
                    variant="primary"
                    isLoading={isStartingSession}
                    onPress={async () => {
                        if (isStartingSession || selectedRecipients.length === 0) {
                            return;
                        }

                        try {
                            if (!showRequestMessageInput) {
                                setShowRequestMessageInput(true);
                                return;
                            }

                            await startSession();

                            analyticsService.logEvent(ANALYTICS_EVENTS.USER_REQUESTS_FEEDBACK);
                        } catch (error) {
                            window.alert(error.message);
                        }
                    }}
                >
                    {t('modals:requestFeedback.actions.continue', 'Continue')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
