import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import TermsAndConditionsModal from '@web/components/terms-and-conditions-modal';

import { Typography, Button, AccountLocation } from '@packages/ui/shared';
import { patchAuthUserProfile, useAuthDispatch, useAuthState } from '@packages/contexts/auth';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import Select from '@web/components/select';
import { useNavigate } from 'react-router-dom';
import { AccountStatusId } from '@packages/models/api';
import { authService, storageService } from '@web/services/singletons';
import { WebStorageKeys } from '@packages/core/storage';
import { useAbortController } from '@packages/core/http';

const useAccountSettingsModalStyles = createUseStyles({
    welcome: {
        marginTop: 40,
        marginBottom: 40,
        padding: 0,
    },
    card: {
        backgroundColor: colors.white,
        boxShadow: `0px 1px 4px 0px rgba(0, 0, 0, 0.16)`,
    },
    section: {
        padding: 32,
    },
    footer: {
        borderTop: `1px solid ${colors.grayThree}`,
    },
});

const UserOnboarding: FC = () => {
    const classes = useAccountSettingsModalStyles();
    const { abortSignalRef } = useAbortController();
    const { t } = useTranslation();
    const { account } = useAuthState();
    const authDispatch = useAuthDispatch();
    const navigate = useNavigate();

    const [defaultPhoneId, setDefaultPhoneId] = useState(account?.defaultPhoneNumberId ?? '');
    const [defaultEmailId, setDefaultEmailId] = useState(account?.defaultEmailAddressId ?? '');
    const [acceptedToS, setAcceptedToS] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [showToS, setShowToS] = useState(false);

    useEffect(() => {
        if (!account) {
            return;
        }

        if (account.accountStatusId !== AccountStatusId.New) {
            storageService.getItem(WebStorageKeys.AuthRedirectUrl).then((authRedirect) => {
                if (authRedirect !== null && typeof authRedirect === 'object') {
                    return navigate({
                        ...authRedirect,
                        replace: true,
                        state: {
                            recentlyOnboarded: !!isSaving,
                        },
                    });
                }

                navigate(authRedirect || '/', {
                    replace: true,
                    state: {
                        recentlyOnboarded: !!isSaving,
                    },
                });
            });
        }
    }, [account, navigate, isSaving]);

    return (
        <div className="h-100 pb-6 overflow-auto">
            <TermsAndConditionsModal
                show={showToS}
                onHide={() => {
                    setShowToS(false);
                }}
            />

            <Row>
                <Col xs={12} md={{ offset: 2, span: 8 }} className={classes.welcome}>
                    <Typography variant="h3">
                        {t(
                            'screens:reviewProfile.welcome',
                            'Welcome {{firstName}}. Please review the following\r\ninformation before continuing.',
                            { firstName: account?.firstName }
                        )}
                    </Typography>
                </Col>

                <Col xs={12} md={{ offset: 2, span: 8 }} className={classes.card}>
                    <Row className={classes.section}>
                        <Col xs={6} className="mb-4">
                            <div>
                                <Typography variant="label">
                                    {t('views:onboarding.profile.username', 'Subarunet Username')}
                                </Typography>
                            </div>

                            <Typography variant="lead">{account?.soaUsername || 'N/A'}</Typography>
                        </Col>

                        <Col xs={6} className="mb-4">
                            <div>
                                <Typography variant="label">
                                    {t('views:onboarding.profile.fullName', 'Full Name')}
                                </Typography>
                            </div>

                            <Typography variant="lead">{account?.name || 'N/A'}</Typography>
                        </Col>

                        <Col xs={6} className="mb-4">
                            <div>
                                <Typography variant="label">
                                    {t('views:onboarding.profile.phoneNumber', 'Phone')}
                                </Typography>
                            </div>

                            {account && account.phoneNumbers.length > 1 ? (
                                <Select
                                    value={defaultPhoneId}
                                    options={
                                        account?.phoneNumbers.map((pN) => ({
                                            title: pN.phoneNumberDescription,
                                            value: pN.phoneNumberId,
                                        })) ?? []
                                    }
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        setDefaultPhoneId(event.target.value);
                                    }}
                                />
                            ) : account?.phoneNumbers.length === 1 ? (
                                <Typography variant="lead">{account.phoneNumbers[0].phoneNumberDescription}</Typography>
                            ) : (
                                <Typography variant="lead">N/A</Typography>
                            )}
                        </Col>

                        <Col xs={6} className="mb-4">
                            <div>
                                <Typography variant="label">{t('views:onboarding.profile.email', 'Email')}</Typography>
                            </div>

                            {account && account.emailAddresses.length > 1 ? (
                                <Select
                                    value={defaultEmailId}
                                    options={account.emailAddresses.map((eA) => ({
                                        title: eA.emailAddress,
                                        value: eA.emailAddressId,
                                    }))}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        setDefaultEmailId(event.target.value);
                                    }}
                                />
                            ) : account?.emailAddresses.length === 1 ? (
                                <Typography variant="lead">{account?.emailAddresses[0].emailAddress}</Typography>
                            ) : (
                                <Typography variant="lead">N/A</Typography>
                            )}
                        </Col>

                        <Col xs={12}>
                            {account?.locations.map((locationProfile, idx) => {
                                return (
                                    <AccountLocation
                                        key={idx}
                                        index={idx}
                                        showCount={account.locations.length > 1}
                                        elements={locationProfile.elements}
                                    />
                                );
                            })}
                        </Col>
                    </Row>

                    <Row className={classNames(classes.section, classes.footer)}>
                        <Col xs={12}>
                            <div className="d-flex align-items-center justify-content-between">
                                {/* Remove this empty div when ToS are added back in */}
                                <div />
                                {/* <Form.Check
                                    className="mb-3"
                                    label={
                                        <Typography>
                                            {t('views:onboarding.tos.', 'I accept the')}{' '}
                                            <Typography
                                                accessibilityRole="link"
                                                onPress={(e) => {
                                                    e.preventDefault();
                                                    setShowToS(true);
                                                }}
                                                color="blueOne"
                                            >
                                                {t('views:onboarding.tos.', 'terms & conditions')}
                                            </Typography>
                                        </Typography>
                                    }
                                    id="tos"
                                    name="tos"
                                    checked={acceptedToS}
                                    onChange={() => {
                                        setAcceptedToS(!acceptedToS);
                                    }}
                                /> */}

                                <Button
                                    title={t('views:onboarding.continue', 'Continue')}
                                    isLoading={isSaving}
                                    // disabled={!acceptedToS}
                                    onPress={() => {
                                        // if (!acceptedToS || !account || isSaving) {
                                        //     return;
                                        // }
                                        if (!account || isSaving) {
                                            return;
                                        }

                                        setIsSaving(true);

                                        patchAuthUserProfile({
                                            authDispatch,
                                            authService,
                                            accountId: account.accountId,
                                            accountPatch: {
                                                accountStatusId: AccountStatusId.Active,
                                                defaultPhoneNumberId: defaultPhoneId || undefined,
                                                defaultEmailAddressId: defaultEmailId || undefined,
                                            },
                                            signal: abortSignalRef.current,
                                        }).catch((e) => {
                                            setIsSaving(false);
                                            alert(e.message);
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} md={{ offset: 2, span: 8 }}>
                    <Row>
                        <Col xs={12} md={{ offset: 3, span: 6 }} className="mt-4 text-center">
                            <Typography variant="caption">
                                {account?.isRetailerUser
                                    ? t(
                                          'views:onboarding.retailerContactInfo',
                                          'If anything looks incorrect, contact your DEM admin (Dealer Employee Maintenance Administrator).'
                                      )
                                    : t(
                                          'views:onboarding.contactInfo',
                                          'If anything looks incorrect, contact the Subaru Helpdesk\r\nat 1-800-SOA-STAR (1-800-762-7827).'
                                      )}
                            </Typography>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default UserOnboarding;
