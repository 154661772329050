import { HttpClient } from '@packages/core/http';
import { ITechlineResponse, ITechline, ITechlineRedux } from './techline.state';
import {
    CaseTypesResponse,
    RetrieveCaseResponse,
    SaveCaseTypeRequestBody,
    WorksheetCaseTypeStatus,
} from '@packages/models/api';
import { throttle } from 'lodash';

export class TechlineService {
    constructor(private http: HttpClient) {}

    public getTechline = async ({
        techlineId,
        ignoreCache = false,
        signal,
    }: {
        techlineId?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<ITechlineRedux>({
            method: 'GET',
            url: '/techline' + this.http.queryString({ techlineId }),
            ignoreCache,
            signal,
        });
    };

    public createTechline = async ({
        ignoreCache = false,
        techline,
        signal,
    }: {
        ignoreCache?: boolean;
        techline: Partial<ITechline>;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<ITechlineResponse>({
            method: 'POST',
            url: '/techline',
            ignoreCache,
            body: {
                techline,
            },
            signal,
        });
    };

    public updateTechline = async ({
        techlineId,
        techline,
        ignoreCache = false,
        signal,
    }: {
        techlineId: string;
        techline: Partial<ITechline>;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<ITechlineRedux>({
            method: 'PUT',
            url: `/techline/${techlineId}`,
            body: {
                techline,
            },
            ignoreCache,
            signal,
        });
    };

    public deleteTechline = async ({
        techlineId,
        ignoreCache = false,
        signal,
    }: {
        techlineId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<void>({
            method: 'DELETE',
            url: `/techline/${techlineId}`,
            ignoreCache,
            signal,
        });
    };

    public getInfotainmentPortalKey = async ({
        retailerId,
        ignoreCache = false,
        signal,
    }: {
        retailerId?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<{
            encryptedRetailerId: string;
            infotainmentUrl: string;
        }>({
            method: 'GET',
            url: `/infotainment/retailer/${retailerId}`,
            ignoreCache,
            signal,
        });
    };

    public getInfotainmentCasesPortalKey = async ({
        retailerId,
        ignoreCache = false,
        signal,
    }: {
        retailerId?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<{
            encryptedRetailerId: string;
            infotainmentUrl: string;
        }>({
            method: 'GET',
            url: `/infotainment/retailercases/${retailerId}`,
            ignoreCache,
            signal,
        });
    };

    public validateVin = async (vinNumber: string, ignoreCache = true, signal?: AbortSignal) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/validateVin/v1/${this.http.queryString({ vin: vinNumber })}`,
            ignoreCache,
            signal,
        });
    };

    public retrieveVinDetails = async (vinNumber: string, ignoreCache = true, signal?: AbortSignal) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/retrieveVinDetails/v1/${this.http.queryString({ vin: vinNumber })}`,
            ignoreCache,
            signal,
        });
    };

    public retrieveRetailer = async (ignoreCache = true, signal?: AbortSignal, query?: string) => {
        const queryString = this.http.queryString({ query });
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/retrieveRetailer/v1${queryString}`,
            ignoreCache,
            signal,
        });
    };

    public recentSearch = async (ignoreCache = true, signal?: AbortSignal) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/retailers/recent-search',
            ignoreCache,
            signal,
        });
    };

    patchRetailerProfile = (data: {
        casenumber: string;
        username: string;
        retailernumber: string;
        statuscode: number;
    }) => {
        return this.http.orchestrateRequest({
            method: 'PATCH',
            url: '/northstar/patchCaseRetailerProfile/v1',
            ignoreCache: true,
            body: data,
        });
    };

    public retrieveCases = async (
        retailerNumber: string,
        ignoreCache = true,
        signal?: AbortSignal,
        techlineCaseStatus?: string,
        isMobile?: boolean,
        page?: number,
        recordCount?: number,
        vinFilter?: string,
        caseTypeFilter?: string,
        submissionDateFilter?: string
    ) => {
        if (!retailerNumber) {
            return;
        }
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/retrieveCases/v1${this.http.queryString({
                retailerNumber,
                techlineCaseStatus,
                isMobile,
                page,
                recordCount,
                vinFilter,
                caseTypeFilter,
                submissionDateFilter,
            })}`,
            ignoreCache,
            signal,
        });
    };

    public searchCases = async (
        retailerNumber: string,
        searchText: string,
        ignoreCache = true,
        signal?: AbortSignal
    ) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/searchText${this.http.queryString({
                searchText,
                retailerNumber,
            })}`,
            ignoreCache,
            signal,
        });
    };

    public retrieveCaseTypes = async (ignoreCache = true, signal?: AbortSignal) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/northstar/retrieveCaseTypes/v1',
            ignoreCache,
            signal,
        });
    };

    public createDraftCase = async ({
        username,
        retailernumber,
        vin,
        signal,
        ignoreCache = true,
    }: {
        username: string;
        retailernumber: string;
        vin: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: '/northstar/draftCase/v1',
            body: {
                username,
                retailernumber,
                vin,
            },
            ignoreCache,
            signal,
        });
    };

    public createCase = async ({
        casenumber,
        dtccode,
        signal,
        ignoreCache = true,
    }: {
        casenumber: string;
        dtccode: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: '/northstar/createCase',
            body: {
                casenumber,
                dtccode,
            },
            ignoreCache,
            signal,
        });
    };

    public updateCase = async ({
        updateBody,
        signal,
        ignoreCache = true,
    }: {
        updateBody: any;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: '/northstar/draftCase/v1',
            body: updateBody,
            ignoreCache,
            signal,
        });
    };

    public getCaseDetails = async ({
        caseNumber,
        signal,
        ignoreCache = true,
    }: {
        caseNumber: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<RetrieveCaseResponse>({
            method: 'GET',
            url: `/northstar/retrieveCase/${caseNumber}/v1`,
            ignoreCache,
            signal,
        });
    };

    public getDtcCodes = async ({
        query,
        signal,
        ignoreCache = true,
    }: {
        query: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        const queryString = this.http.queryString({ query });
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/dtc-codes' + queryString,
            ignoreCache,
            signal,
        });
    };

    public updateCaseStatus = async ({
        updateBody,
        signal,
        ignoreCache = true,
    }: {
        updateBody: any;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/northstar/updateCaseStatus/v1',
            body: updateBody,
            ignoreCache,
            signal,
        });
    };

    public deleteCase = async ({
        caseNumber,
        ignoreCache = false,
        signal,
    }: {
        caseNumber: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'DELETE',
            url: `/northstar/deleteDraftCase/${caseNumber}/v1`,
            ignoreCache,
            signal,
        });
    };

    public addComment = async ({
        body,
        signal,
        ignoreCache = true,
    }: {
        body: any;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/northstar/createTechShareComment/v1',
            body: body,
            ignoreCache,
            signal,
        });
    };

    public getComments = async ({
        caseNumber,
        signal,
        ignoreCache = true,
    }: {
        caseNumber: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/retrieveTechShareComments/${caseNumber}/v1`,
            ignoreCache,
            signal,
        });
    };

    public updateDTCCode = async ({
        updateBody,
        signal,
        ignoreCache = true,
    }: {
        updateBody: any;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: '/northstar/patchDTCCode/v1',
            body: updateBody,
            ignoreCache,
            signal,
        });
    };

    public postAssets = async ({
        assetsBody,
        signal,
        ignoreCache = true,
    }: {
        assetsBody: any;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/northstar/postAssets/v1',
            body: assetsBody,
            ignoreCache,
            signal,
        });
    };

    public uploadAssets = async ({
        uploadUrl,
        headers,
        assetsBody,
        onProgress,
    }: {
        uploadUrl: string;
        headers: any;
        assetsBody: any;
        onProgress?: any;
    }) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadUrl, true);
        Object.entries(headers).forEach(([header, value]: any) => {
            xhr.setRequestHeader(header, value);
        });
        xhr.upload.addEventListener('progress', (evt) => {
            if (evt.lengthComputable) {
                const progress = Math.round((evt.loaded * 100) / evt.total);
                onProgress?.(progress);
            }
        });
        xhr.send(assetsBody);
        // return await fetch(uploadUrl, {
        //     method: 'PUT',
        //     headers,
        //     body: assetsBody,
        // });
    };

    public updateAssets = async ({
        assetsBody,
        signal,
        ignoreCache = true,
    }: {
        assetsBody: any;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/northstar/updateAssets',
            body: assetsBody,
            ignoreCache,
            signal,
        });
    };

    searchFailCodes({
        modelYear,
        modelCode,
        optionCode,
        query,
        ignoreCache,
        signal,
    }: {
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        query?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/techline/fail-codes/search' + this.http.queryString({ modelYear, modelCode, optionCode, query });

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchPossibleSections({
        modelYear,
        modelCode,
        optionCode,
        failCode,
        ignoreCache,
        signal,
    }: {
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        failCode?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = this.http.queryString({ modelYear, modelCode, optionCode, failCode });

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/techline/fail-codes/fail-code-sections' + queryString,
            ignoreCache,
            signal,
        });
    }

    fetchPossibleSubsections({
        modelYear,
        modelCode,
        optionCode,
        failCode,
        sectionNumber,
        sectionName,
        ignoreCache,
        signal,
    }: {
        failCode?: string;
        sectionNumber: number;
        sectionName: string;
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = this.http.queryString({
            modelYear,
            modelCode,
            optionCode,
            failCode,
            sectionNumber,
            sectionName,
        });

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/techline/fail-codes/fail-code-subsections' + queryString,
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeSections({
        modelYear,
        modelCode,
        optionCode,
        ignoreCache,
        signal,
    }: {
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/techline/fail-codes/sections' + this.http.queryString({ modelYear, modelCode, optionCode });

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    lookupFailCodeSubsections({
        modelYear,
        modelCode,
        optionCode,
        sectionNumber,
        sectionName,
        ignoreCache,
        signal,
    }: {
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        sectionNumber: number;
        sectionName: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url =
            '/techline/fail-codes/subsections' +
            this.http.queryString({ modelYear, modelCode, optionCode, sectionNumber, sectionName });

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    lookupFailCodeFigures({
        modelYear,
        modelCode,
        optionCode,
        sectionNumber,
        sectionName,
        subsectionNumber,
        subsectionName,
        ignoreCache,
        signal,
    }: {
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        sectionNumber: number;
        sectionName: string;
        subsectionNumber: number;
        subsectionName: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url =
            '/techline/fail-codes/figures' +
            this.http.queryString({
                modelYear,
                modelCode,
                optionCode,
                sectionNumber,
                sectionName,
                subsectionNumber,
                subsectionName,
            });

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    lookupFailCodes({
        modelYear,
        modelCode,
        optionCode,
        sectionNumber,
        subsectionNumber,
        figureCode,
        partNumber,
        ignoreCache,
        signal,
    }: {
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        sectionNumber?: number;
        subsectionNumber?: number;
        figureCode?: string;
        partNumber?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = partNumber
            ? this.http.queryString({ modelYear, modelCode, optionCode, partNumber })
            : this.http.queryString({ modelYear, modelCode, optionCode, sectionNumber, subsectionNumber, figureCode });

        const url = '/techline/fail-codes' + queryString;

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    searchPartNumbers({
        modelYear,
        modelCode,
        optionCode,
        query,
        ignoreCache,
        signal,
    }: {
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        query?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url =
            '/techline/fail-codes/part-number/search' +
            this.http.queryString({ modelYear, modelCode, optionCode, query });

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeWiringDiagramSubsections({
        modelYear,
        modelCode,
        optionCode,
        vin,
        ignoreCache,
        signal,
    }: {
        modelCode?: string;
        modelYear?: string;
        optionCode?: string;
        vin?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = this.http.queryString({ modelYear, modelCode, optionCode, vin });

        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/techline/fail-codes/electrical-wiring-diagrams/subsection-figures' + queryString,
            ignoreCache,
            signal,
        });
    }

    public retrieveCaseAssets = async ({
        caseNumber,
        signal,
        ignoreCache = true,
    }: {
        caseNumber: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/retrieveCaseAssets/${caseNumber}/v1`,
            ignoreCache,
            signal,
        });
    };

    retrieveAsset(assetId: string) {
        return this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/retrieveAsset/${assetId}/v1`,
            ignoreCache: true,
        });
    }

    public retrieveAssetUploadStatus = async ({
        assetId,
        signal,
        ignoreCache = true,
    }: {
        assetId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: `/northstar/retrieveAssetUploadStatus/${assetId}/v1`,
            ignoreCache,
            signal,
        });
    };

    fetchCaseType({ status, isPilotUser }: { status: WorksheetCaseTypeStatus; isPilotUser?: boolean }) {
        return this.http.orchestrateRequest<CaseTypesResponse>({
            method: 'GET',
            url: `/admin/casetypes?status=${status}${isPilotUser ? '&pilotUser=true' : '&pilotUser=false'}`,
        });
    }

    saveCaseType(body: SaveCaseTypeRequestBody) {
        return this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: '/northstar/caseTypeIntersect/v1',
            body,
        });
    }
}
