import React, { FC } from 'react';
import { StyleSheet, View, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';

import colors from '@packages/core/styles/colors';
import { Icon } from './icon';

interface AlertBarProps {
    show: boolean;
    style?: StyleProp<ViewStyle>;
    success?: boolean;
    error?: boolean;
    warning?: boolean;
    onClose(): void;
    children?: React.ReactNode;
}

export const AlertBar: FC<AlertBarProps> = ({ show, style, success, error, warning, onClose, children }) => {
    function handleCloseButtonPress() {
        onClose();
    }

    if (!show) {
        return null;
    }

    return (
        <View
            style={[
                styles.alert,
                success && { backgroundColor: colors.greenFour },
                error && { backgroundColor: colors.redFour },
                warning && { backgroundColor: colors.yellowFour },
                style,
            ]}
        >
            <View
                style={[
                    styles.iconWrapper,
                    success && { backgroundColor: colors.greenOne },
                    error && { backgroundColor: colors.redOne },
                ]}
            >
                {success && <Icon name="checkmark" color="white" size={16} />}
                {error && <Icon name="checkmark" color="white" size={16} />}
            </View>

            <View style={styles.childrenOuter}>{children}</View>

            <TouchableOpacity onPress={handleCloseButtonPress} style={styles.closeButton}>
                <Icon name="x-close" />
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    alert: {
        padding: 16,
        paddingRight: 50,
        position: 'relative',
        flexDirection: 'row',
        backgroundColor: colors.grayOne,
    },
    iconWrapper: {
        height: 24,
        width: 24,
        borderRadius: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    childrenOuter: {
        flex: 1,
        paddingTop: 2,
        paddingLeft: 16,
    },
    closeButton: {
        top: 6,
        right: 6,
        width: 44,
        height: 44,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const alertBarDefaultStyles = StyleSheet.create({
    text: {
        fontSize: 17,
        lineHeight: 20,
        fontWeight: '600',
    },
    link: {
        fontSize: 15,
        lineHeight: 20,
    },
});
