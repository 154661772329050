import colors from '@packages/core/styles/colors';
import { Icon, LoadingCircle, StsIconName, Typography } from '@packages/ui/shared';
import React, { useCallback, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { Table, TableHead, TableBody, TableRow, TableCell } from './';

const useStyles = createUseStyles({
    tableOuter: ({ isLoading }: { isLoading: boolean }) => ({
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? 'none' : 'auto',
        height: '100%',
        maxHeight: 'calc(100vh - 300px)', // 100px for breadcrumbs, 64px for pagination, 64px for detail view padding, 72px for page header
    }),
    loaderCircleOuter: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    noResultsOuter: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    iconOuter: {
        width: 192,
        height: 192,
        display: 'flex',
        marginBottom: 32,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.grayOne,
    },
});

type BaseTableRowDataModel<T extends {}> = T & {
    checked?: boolean;
    rowId: string;
};

interface TableRendererProps<T> {
    isLoading?: boolean;
    tableRowsData: T[];
    tableRowsDataSetter: React.Dispatch<React.SetStateAction<T[]>>;
    selectAll?: boolean;
    selectAllSetter?: React.Dispatch<React.SetStateAction<boolean>>;
    tableHeaderRowRenderer(selectAll: boolean | undefined, selectAllHandler: () => void): JSX.Element;
    tableBodyRowRenderer(data: T, rowSelectHandler: (event: React.ChangeEvent<HTMLInputElement>) => void): JSX.Element;
    onSelectionChange?(selectedRows: T[]): void;
    noResultsTitle?: string;
    noResultsIconName?: StsIconName;
}

export function TableRenderer<T>({
    isLoading,
    tableRowsData,
    tableRowsDataSetter,
    selectAll,
    selectAllSetter,
    tableHeaderRowRenderer,
    tableBodyRowRenderer,
    onSelectionChange,
    noResultsTitle,
    noResultsIconName,
}: TableRendererProps<BaseTableRowDataModel<T & {}>>) {
    const classes = useStyles({ isLoading });
    const tableHeadRef = useRef<HTMLTableSectionElement>(null);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    const selectAllHandler = useCallback(() => {
        if (selectAll) {
            tableRowsDataSetter((previousRowData) => {
                const previousQmrTableRowsClone = previousRowData.map((row) => {
                    row.checked = false;
                    return row;
                });

                const checkedRows = previousRowData.filter((row) => row.checked);
                if (onSelectionChange) {
                    onSelectionChange(checkedRows);
                }

                return [...previousQmrTableRowsClone];
            });

            if (selectAllSetter) {
                selectAllSetter(false);
            }
        } else {
            tableRowsDataSetter((previousRowData) => {
                const previousQmrTableRowsClone = previousRowData.map((row) => {
                    row.checked = true;
                    return row;
                });

                const checkedRows = previousRowData.filter((row) => row.checked);
                if (onSelectionChange) {
                    onSelectionChange(checkedRows);
                }

                return [...previousQmrTableRowsClone];
            });

            if (selectAllSetter) {
                selectAllSetter(true);
            }
        }
    }, [selectAll, tableRowsDataSetter, selectAllSetter, onSelectionChange]);

    const rowSelectHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.currentTarget;

            tableRowsDataSetter((previousRowData) => {
                const targetIndex = previousRowData.findIndex((row) => {
                    return row.rowId === value;
                });

                previousRowData[targetIndex].checked = !previousRowData[targetIndex].checked;

                if (previousRowData[targetIndex].checked) {
                    if (selectAllSetter) {
                        selectAllSetter(true);
                    }
                }

                const noneAreChecked = previousRowData.every((row) => {
                    return !row.checked;
                });

                if (noneAreChecked) {
                    if (selectAllSetter) {
                        selectAllSetter(false);
                    }
                }

                const checkedRows = previousRowData.filter((row) => row.checked);
                if (onSelectionChange) {
                    onSelectionChange(checkedRows);
                }

                return [...previousRowData];
            });
        },
        [tableRowsDataSetter, onSelectionChange, selectAllSetter]
    );

    return (
        <Table className={classes.tableOuter}>
            <TableHead ref={tableHeadRef}>{tableHeaderRowRenderer(selectAll, selectAllHandler)}</TableHead>

            <TableBody ref={tableBodyRef}>
                {isLoading ? (
                    <>
                        <TableRow>
                            <TableCell>
                                <div className={classes.loaderCircleOuter}>
                                    <LoadingCircle size={64} />
                                </div>
                            </TableCell>
                        </TableRow>
                    </>
                ) : (
                    <>
                        {tableRowsData.length === 0 ? (
                            <>
                                <TableRow>
                                    <TableCell>
                                        <div className={classes.noResultsOuter}>
                                            <div className={classes.iconOuter}>
                                                <Icon
                                                    name={noResultsIconName ? noResultsIconName : 'car-subaru'}
                                                    color="grayFive"
                                                    size={100}
                                                    style={{ display: 'flex' }}
                                                />
                                            </div>
                                            <div className="mb-4 text-center">
                                                <Typography variant="h3" color="graySix">
                                                    {noResultsTitle ? noResultsTitle : 'No Results'}
                                                </Typography>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : (
                            <>
                                {tableRowsData.map((tableRowData) => {
                                    return tableBodyRowRenderer(tableRowData, rowSelectHandler);
                                })}
                            </>
                        )}
                    </>
                )}
            </TableBody>
        </Table>
    );
}
