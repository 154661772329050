import {
    CreateAssetsResponseDto,
    Asset,
    CreateAssetsRequestDto,
    GetAssetResponseDto,
    AssetProcessingState,
} from '@packages/models/api/assets';
import { HttpClient } from '@packages/core/http';

import {
    AssetUploadInfo,
    AssetUploadTask,
    AssetUploadsState,
    AssetUploadEventHandlers,
    AssetUploadTaskStatus,
} from './asset-uploads.state';

export interface UploadTaskAsset {
    uploadId: string;
    uploadTask: AssetUploadTask;
    asset: Asset;
}

export abstract class AssetUploadsService {
    constructor(private http: HttpClient) {}

    protected abstract startUploads(
        files: AssetUploadInfo[],
        newAssets: CreateAssetsResponseDto
    ): Promise<string[]> | string[];

    protected abstract subscribeToEvents(
        uploadId: string,
        asset: Asset,
        eventHandlers?: AssetUploadEventHandlers
    ): void;

    createAssets(files: AssetUploadInfo[], signal?: AbortSignal) {
        const body: CreateAssetsRequestDto = {
            assets: files.map((f) => ({
                ...f.assetDisposition,
                assetId: f.assetId,
                assetTypeId: f.assetTypeId,
                hasOverlay: !!f.overlayUri && f.overlayUri !== f.assetUri,
                name: f.name,
                note: f.note,
                replacesAssetId: f.replacesAssetId,
                sizeInBytes: f.sizeInBytes,
            })),
        };
        return this.http.orchestrateRequest<CreateAssetsResponseDto>({
            method: 'POST',
            url: '/assets',
            body: body,
            signal,
        });
    }

    getAsset({ assetId, ignoreCache, signal }: { assetId: string; ignoreCache?: boolean; signal?: AbortSignal }) {
        return this.http.orchestrateRequest<GetAssetResponseDto>({
            method: 'GET',
            url: `/assets/${assetId}`,
            ignoreCache,
            signal,
        });
    }

    updateAssetProcessingStatus({
        assetId,
        assetProcessingStateId,
        error,
        signal,
    }: {
        assetId: string;
        assetProcessingStateId: AssetProcessingState;
        error?: string;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<GetAssetResponseDto>({
            method: 'PUT',
            url: `/assets/${assetId}/asset-processing-state`,
            body: {
                assetProcessingStateId,
                error,
            },
            signal,
        });
    }

    async queueUploadTasks(
        assetUploads: AssetUploadInfo[],
        signal?: AbortSignal
    ): Promise<AssetUploadTask[] | undefined> {
        let uploadIds: string[];

        if (signal && signal.aborted) {
            return;
        }

        const response = await this.createAssets(assetUploads, signal);

        if (!response.success) {
            return Promise.reject(response.data);
        }

        const createdAssets = response.data;

        try {
            uploadIds = await this.startUploads(assetUploads, createdAssets);
        } catch (error) {
            return Promise.reject(error);
        }

        return assetUploads.map<AssetUploadTask>((uploadInfo, idx) => {
            const { asset, presignedUpload } = createdAssets.assets[idx];
            const uploadId = uploadIds[idx];

            return {
                qmrId: uploadInfo.assetDisposition.qmrId,
                uploadId,
                asset,
                uploadInfo,
                presignedUpload,
                status: AssetUploadTaskStatus.Uploading,
                error: null,
            };
        });
    }

    getBackendStatuses(
        uploadIds: string[],
        uploads: AssetUploadsState,
        signal?: AbortSignal
    ): Promise<UploadTaskAsset[]> {
        return Promise.all(
            uploadIds.map(async (uploadId) => {
                const uploadTask = uploads[uploadId];
                const assetId = uploadTask.asset.assetId;

                const response = await this.getAsset({
                    assetId,
                    signal,
                    ignoreCache: true,
                });

                if (!response.success) {
                    return Promise.reject(response.data);
                }

                const asset = response.data.asset;

                return { uploadId, uploadTask, asset };
            })
        );
    }
}
