import { HttpClient } from '@packages/core/http';

import {
    NotificationsResponseDto,
    NotificationSummary,
    UpdateNotificationStatusesDto,
    NotificationPreferencesResponseDto,
    UpdateNotificationPreferencesResponseDto,
    UpdateNotificationPreferencesDto,
} from '@packages/models/api';

export class NotificationsService {
    constructor(private http: HttpClient) {}

    fetchNotifications(signal?: AbortSignal, ignoreCache = true) {
        return this.http.orchestrateRequest<NotificationsResponseDto>({
            method: 'GET',
            url: '/notifications',
            ignoreCache,
            signal,
        });
    }

    fetchNotificationsSummary(signal?: AbortSignal, ignoreCache = false) {
        return this.http.orchestrateRequest<NotificationSummary>({
            method: 'GET',
            url: '/notifications/overview',
            ignoreCache,
            signal,
        });
    }

    clearNewNotifications(signal?: AbortSignal, ignoreCache = false) {
        return this.http.orchestrateRequest({
            method: 'PUT',
            url: '/notifications/update-new-statuses',
            ignoreCache,
            signal,
        });
    }

    updateNotifications(body: UpdateNotificationStatusesDto, signal?: AbortSignal) {
        return this.http.orchestrateRequest<NotificationsResponseDto>({
            method: 'PUT',
            url: '/notifications/notification-status-id',
            body,
            signal,
        });
    }

    deleteNotifications(notifications: string[], signal?: AbortSignal) {
        return this.http.orchestrateRequest<void>({
            method: 'DELETE',
            url: '/notifications',
            body: { notificationIds: notifications },
            signal,
        });
    }

    // User Settings for Notification Preferences
    fetchNotificationPreferences(signal?: AbortSignal, ignoreCache = false) {
        return this.http.orchestrateRequest<NotificationPreferencesResponseDto>({
            method: 'GET',
            url: '/notifications/preferences',
            ignoreCache,
            signal,
        });
    }

    updateNotificationPreferences(body: UpdateNotificationPreferencesDto, signal?: AbortSignal) {
        return this.http.orchestrateRequest<UpdateNotificationPreferencesResponseDto>({
            method: 'PUT',
            url: '/notifications/preferences',
            body,
            signal,
        });
    }

    deleteNotificationPreferences(signal?: AbortSignal) {
        return this.http.orchestrateRequest<void>({
            method: 'DELETE',
            url: '/notifications/preferences',
            signal,
        });
    }
}
