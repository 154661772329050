import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useDetailViewStyles = createUseStyles({
    view: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});

export const DetailView: FC<{ children: React.ReactNode }> = ({ children }) => {
    const classes = useDetailViewStyles();

    return <div className={classes.view}>{children}</div>;
};
