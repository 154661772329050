import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Typography, Icon, Button } from '@packages/ui/shared';
import { Form } from 'react-bootstrap';
import { CreatedAndUpdateFilters, KeyOfFilterParams, WorkSheetStatuses } from '../utils';
import useWorksheetFormTranslation from '../hooks/use-worksheet-form-translation';
import { WorkSheetStatusEnum } from '@packages/models/api';
import Select, { SelectOption } from '@web/components/select';
import { useQuery } from '@web/core/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import useFilter from '../hooks/use-filter';

interface Props {
    onClose: () => void;
    show: boolean;
    tab: WorkSheetStatusEnum;
}

const SearchFilter: FC<Props> = ({ onClose, show, tab }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const currentQueryParams = useQuery();
    const { dataTranslate } = useWorksheetFormTranslation();
    const { filter } = dataTranslate;
    const { clearFilter, clearFilterURLSearchParams, hasFilterApplied } = useFilter();
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const [selectedLastUpdate, setSelectedLastUpdate] = useState<string>('');
    const [selectedCreated, setSelectedCreated] = useState<string>('');

    useEffect(() => {
        // Set values based on URL query parameters.
        if (show) {
            setSelectedStatus(currentQueryParams.getAll(KeyOfFilterParams.statuses) || []);
            setSelectedLastUpdate(currentQueryParams.get(KeyOfFilterParams.lastUpdatedDate) || '');
            setSelectedCreated(currentQueryParams.get(KeyOfFilterParams.createdDate) || '');
        }
    }, [show]);

    useEffect(() => {
        if (tab !== WorkSheetStatusEnum.All) {
            setSelectedStatus([]);
            const params = new URLSearchParams(search);
            params.delete(KeyOfFilterParams.statuses);
            navigateWithParams(params.toString());
        }
    }, [tab]);

    const navigateWithParams = useCallback(
        (params: string) => {
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname]
    );

    const handleStatus = useCallback(
        (id: WorkSheetStatusEnum) => {
            if (selectedStatus.includes(id)) {
                setSelectedStatus((prev) => prev.filter((s) => s !== id));
                return;
            }
            setSelectedStatus([...selectedStatus, id]);
        },
        [selectedStatus]
    );

    const createdAndUpdateFilters = useMemo((): SelectOption[] => {
        return CreatedAndUpdateFilters.map((opt) => {
            return {
                title: opt.label,
                value: opt.value.toString(),
            };
        });
    }, []);

    const handleApply = useCallback(() => {
        const params = clearFilterURLSearchParams();

        if (selectedStatus.length) {
            for (const status of selectedStatus) {
                params.append(KeyOfFilterParams.statuses, status);
            }
        }

        if (selectedLastUpdate) {
            params.set(KeyOfFilterParams.lastUpdatedDate, selectedLastUpdate);
        }
        if (selectedCreated) {
            params.set(KeyOfFilterParams.createdDate, selectedCreated);
        }
        navigateWithParams(params.toString());
        onClose();
    }, [clearFilterURLSearchParams, navigateWithParams, onClose, selectedCreated, selectedLastUpdate, selectedStatus]);

    const rightPanelDiv = document.getElementById('right-side-panel');
    if (!rightPanelDiv || !show) {
        return null;
    }

    const Divider = () => <hr className={classes.hr} />;

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <div className={classes.panelHeader}>
                <Typography>{filter.title}</Typography>

                <div className={classes.closeButton} onClick={onClose}>
                    <Icon name="x-close" />
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                {tab === WorkSheetStatusEnum.All && (
                    <>
                        <div className={classes.filterHeader}>
                            <Typography variant="caption2">{filter.status}</Typography>
                        </div>

                        <div className={classes.filterContent}>
                            {WorkSheetStatuses.map((option, index) => {
                                if (option.value === WorkSheetStatusEnum.All) {
                                    return null;
                                }
                                return (
                                    <Form.Check
                                        className="mb-3"
                                        label={
                                            dataTranslate.tabs[option.text as keyof typeof dataTranslate.tabs].tabLabel
                                        }
                                        type="checkbox"
                                        key={option.value}
                                        id={option.value}
                                        checked={selectedStatus.includes(option.value)}
                                        onChange={() => handleStatus(option.value)}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}

                <Divider />

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{filter.lastUpdate}</Typography>
                </div>

                <div className={classes.filterContent}>
                    <Select
                        value={selectedLastUpdate}
                        options={createdAndUpdateFilters}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedLastUpdate(event.target.value);
                        }}
                    />
                </div>
                <Divider />

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{filter.createdDate}</Typography>
                </div>

                <div className={classes.filterContent}>
                    <Select
                        value={selectedCreated}
                        options={createdAndUpdateFilters}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedCreated(event.target.value);
                        }}
                    />
                </div>

                <Divider />

                <div className={classes.filterContent}>
                    <div className="mt-4 d-flex justify-content-between">
                        <Button onPress={handleApply}>{filter.apply}</Button>

                        {hasFilterApplied && (
                            <Button
                                variant="ghost-blue"
                                onPress={() => {
                                    clearFilter();
                                    onClose();
                                }}
                            >
                                {filter.clearAll}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>,
        rightPanelDiv
    );
};

export default SearchFilter;

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    filterHeader: {
        textTransform: 'uppercase',
        margin: '16px 24px',
    },
    filterContent: {
        margin: '0 24px 16px',
    },
    hr: {
        margin: '0 24px',
    },
});
