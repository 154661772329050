import * as React from 'react';
import { ArticleActionDispatcher, initialState, articlesReducer, ArticlesState } from './articles.state';

export const defaultArticles: ArticlesState = initialState;

export const ArticlesContext = React.createContext(defaultArticles);
export const ArticlesDispatchContext = React.createContext<undefined | ArticleActionDispatcher>(undefined);

export const ArticlesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setArticles] = React.useReducer(articlesReducer, initialState);

    return (
        <ArticlesContext.Provider value={state}>
            <ArticlesDispatchContext.Provider value={setArticles}>{children}</ArticlesDispatchContext.Provider>
        </ArticlesContext.Provider>
    );
};
