import { useContext } from 'react';
import { IQmrsDashboardProps } from './qmrsdashboard.state';
import { QmrsDashboardContext, QmrsDashboardDispatchContext } from './qmrsdashboard.context';

export const useQmrsDashboardState = () => {
    const state = useContext<IQmrsDashboardProps>(QmrsDashboardContext);

    if (state === undefined) {
        throw new Error('useTechlineState must be used within a TechlineProvider');
    }

    return state;
};

export const useQmrsDashboardDispatch = () => {
    const state = useContext(QmrsDashboardDispatchContext);
    if (state === undefined) {
        throw new Error('useQmrsDashboardDispatch must be used within a DashboardProvider');
    }

    return state;
};
