import { Button, Icon } from '@packages/ui/shared';
import { httpClient } from '@web/services/singletons';
import React, { useRef } from 'react';

interface Props {
    onUploadSuccess: () => void;
}

export const BulkUserEnableButton = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const csvFile = e.target?.files && e.target?.files[0];

        console.log(csvFile);
        if (!csvFile) {
            return;
        }

        const body = new FormData();
        body.append('userAccounts', csvFile);

        httpClient
            .orchestrateRequest({
                method: 'POST',
                url: '/accounts/bulk-enable-list',
                body,
                headers: {
                    'content-type': 'multipart/form-data',
                },
            })
            .then((r) => {
                if (!r.success && r.aborted) {
                    return;
                } else if (!r.success) {
                    alert(r.data.message);
                    return;
                }

                props.onUploadSuccess();
            });
    };

    const handleInputClick = () => {
        inputRef.current?.click();
    };

    return (
        <>
            <input
                data-testid="file_input"
                type="file"
                accept=".csv"
                ref={inputRef}
                className="d-none"
                onChange={handleInputChange}
            />

            <Button iconLeft={<Icon name="upload" color="white" />} onPress={handleInputClick}>
                Bulk Enable
            </Button>
        </>
    );
};
