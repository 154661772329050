import { WorkSheetStatusEnum, FormElementTypeId } from '@packages/models/api';

export const WorkSheetStatuses = [
    { text: 'all', value: WorkSheetStatusEnum.All },
    { text: 'pending', value: WorkSheetStatusEnum.Pending },
    { text: 'pilot', value: WorkSheetStatusEnum.Pilot },
    { text: 'published', value: WorkSheetStatusEnum.Published },
    { text: 'archived', value: WorkSheetStatusEnum.Archived },
];

export enum RelativeDateEnum {
    ALL = 'All',
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    THIS_WEEK = 'THIS_WEEK',
    THIS_MONTH = 'THIS_MONTH',
}
export const CreatedAndUpdateFilters = [
    { label: 'All', value: '' },
    { label: 'Today', value: RelativeDateEnum.TODAY },
    { label: 'Yesterday', value: RelativeDateEnum.YESTERDAY },
    { label: 'This Week', value: RelativeDateEnum.THIS_WEEK },
    { label: 'This Month', value: RelativeDateEnum.THIS_MONTH },
];

export const WorksheetCharacterLengthValidation = {
    QuestionLength: 1000,
    AnswerLength: 2000,
    DescriptionLength: 2000,
    TooltipsLength: 2000,
};

export const KeyOfFilterParams = {
    statuses: 'statuses',
    lastUpdatedDate: 'lastUpdatedDate',
    createdDate: 'createdDate',
};

export const isConditionalElementType = (elementTypeId: FormElementTypeId) => {
    return [FormElementTypeId.Radio, FormElementTypeId.Checkbox].includes(elementTypeId);
};
