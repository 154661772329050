import { useCallback, useEffect, useRef } from 'react';

export function useAbortController() {
    const abortControllerRef = useRef(new AbortController());
    const abortSignalRef = useRef<AbortSignal>(abortControllerRef.current.signal);

    const signalAbort = useCallback(() => {
        const newCtrl = new AbortController();
        abortControllerRef.current.abort();
        abortControllerRef.current = newCtrl;
        abortSignalRef.current = newCtrl.signal;
    }, []);

    useEffect(() => {
        return () => {
            abortControllerRef.current.abort();
        };
    }, []);

    return { abortSignalRef, signalAbort };
}
