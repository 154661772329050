import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, Button, TextBadge, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { useNavigate } from 'react-router-dom';
import { caseStatusMap as statusMap, getBadgeVariantTechline } from '@web/utils';
import { TechlineStatusCode } from '@packages/models/api';
import useUserDetailsOrPermissions from './worksheet/hooks/useUserDetailsOrPermissions';

const useStyles = createUseStyles({
    container: {
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    primaryOutlineBtn: {
        color: colors.blueOne,
        borderColor: colors.blueOne,
        padding: 10,
        borderRadius: 4,
        '&:hover, &:active': {
            backgroundColor: 'transparent',
            borderColor: colors.blueOne,
        },
    },
});

interface Props {
    onSubmit?: () => void;
    onSaveAndExit?: () => void;
    onPrimaryBtnClick?: () => void;
    onDelete?: () => void;
    disablePrimaryBtn?: boolean;
    caseNumber?: string;
    caseDetails: any;
    disabledSubmit?: boolean;
    isSubmitLoading?: boolean;
    disabledSave?: boolean;
    disabledDelete?: boolean;
}

export const TechlineHeader = ({
    onSubmit,
    onPrimaryBtnClick,
    disablePrimaryBtn,
    onSaveAndExit,
    caseNumber,
    caseDetails,
    onDelete,
    disabledSubmit,
    isSubmitLoading,
    disabledSave,
    disabledDelete,
}: Props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {
        permissions: { canCloseCase },
    } = useUserDetailsOrPermissions();

    const renderRightContainer = () => {
        if (statusMap['DRAFT'].includes(caseDetails?.techlineStatusCode)) {
            return (
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <Typography variant="labelRegular" color="textDarkSecondary">
                        Saved on {caseDetails?.modifiedOnDescription}
                    </Typography>
                    <button
                        disabled={disabledSave}
                        onClick={onSaveAndExit}
                        type="button"
                        className={`${classes.primaryOutlineBtn} btn btn-outline-primary ml-4`}
                    >
                        <Typography color="blueOne">Save and exit</Typography>
                    </button>
                    <div className="ml-4">
                        <Button
                            disabled={disabledSubmit}
                            variant="primary"
                            isLoading={isSubmitLoading}
                            onPress={onSubmit}
                            title={'Submit'}
                        />
                    </div>
                    <div className="ml-4">
                        <Button disabled={disabledDelete} variant="ghost-gray" onPress={onDelete}>
                            <Icon color="blueOne" name="trash" />
                        </Button>
                    </div>
                </div>
            );
        } else if (
            (statusMap['OPEN'].includes(caseDetails?.techlineStatusCode) ||
                statusMap['PENDING_TECHLINE'].includes(caseDetails?.techlineStatusCode) ||
                statusMap['UPDATE_REQUESTED'].includes(caseDetails?.techlineStatusCode)) &&
            caseDetails?.techlineStatusCode !== TechlineStatusCode.OPEN_ESCALATED
        ) {
            return (
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <Typography variant="labelRegular" color="textDarkSecondary">
                        Saved on {caseDetails?.modifiedOnDescription}
                    </Typography>
                    {canCloseCase && (
                        <div className="ml-4">
                            <Button
                                variant="primary"
                                disabled={disablePrimaryBtn}
                                onPress={onPrimaryBtnClick}
                                title={'Close case'}
                            />
                        </div>
                    )}
                    {/* <Button variant="ghost-gray" onPress={() => {}}>
                        <Icon color="blueOne" name="more-dots-vertical" />
                    </Button> */}
                </div>
            );
        }
    };

    return (
        <div className={`${classes.container}`}>
            <div className="m-3 d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <Button
                        variant="link"
                        onPress={() =>
                            navigate(
                                `/techline/dashboard?retailerNumber=${
                                    caseDetails?.retailerProfiles?.find(
                                        (retailer: { isPrimary: any }) => retailer.isPrimary
                                    )?.retailerNumber
                                }`
                            )
                        }
                    >
                        <Icon name="arrow-back" />
                    </Button>
                    <div className="ml-4 mr-4">
                        <Typography variant="h5">{caseNumber}</Typography>
                    </div>
                    <TextBadge variant={getBadgeVariantTechline(caseDetails?.techlineStatusCode)}>
                        {caseDetails?.techlineStatusCode}
                    </TextBadge>
                </div>
                {renderRightContainer()}
            </div>
        </div>
    );
};
