import { HttpClient } from '@packages/core/http';

import { Qmr, Comment, CommentsResponseDto, CommentApiSupplement } from '@packages/models/api';

export class CommentsService {
    constructor(private http: HttpClient) {}

    fetchComments({
        qmrId,
        signal,
        ignoreCache = false,
    }: {
        qmrId: Qmr['qmrId'];
        signal?: AbortSignal;
        ignoreCache?: boolean;
    }) {
        const url =
            '/comments' +
            this.http.queryString({
                qmrId,
                supplement: CommentApiSupplement.Account,
            });

        return this.http.orchestrateRequest<CommentsResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    addComment({
        qmrId,
        body,
        signal,
        ignoreCache = false,
    }: {
        qmrId: Qmr['qmrId'];
        body: string;
        signal?: AbortSignal;
        ignoreCache?: boolean;
    }) {
        return this.http.orchestrateRequest<{
            comment: Omit<Comment, 'createdAccount'>;
        }>({
            method: 'POST',
            url: '/comments',
            body: { qmrId, body },
            ignoreCache,
            signal,
        });
    }

    deleteComment(commentId: Comment['commentId'], signal?: AbortSignal) {
        return this.http.orchestrateRequest<void>({
            method: 'DELETE',
            url: `/comments/${commentId}`,
            signal,
        });
    }
}
