import React from 'react';
import { View, StyleSheet } from 'react-native';

import colors from '@packages/core/styles/colors';

interface CircleBadgeProps {
    size?: number;
    color?: keyof typeof colors;
    children: any;
}

export function CircleBadge({ size = 5, color = 'grayTwo', children }: CircleBadgeProps) {
    const badgeSize = size * 10;

    const styles = StyleSheet.create({
        badge: {
            width: badgeSize,
            height: badgeSize,
            borderStyle: 'solid',
            borderColor: 'black',
            borderRadius: badgeSize,
            backgroundColor: colors[color],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

    return <View style={styles.badge}>{children}</View>;
}

export default CircleBadge;
