import React, { FC } from 'react';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { IQmrsDashboardAlertLink } from '@packages/models/api';
import { useTranslation } from 'react-i18next';

const useQmrDashboardStyle = createUseStyles({
    banner: {
        padding: 20,
        display: 'flex',
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.blueOne,
        marginLeft: 24,
        marginRight: 24,
    },
    bannerContentWrapper: {
        display: 'flex',
        width: '100%',
    },
    bannerContent: {
        flexGrow: 1,
        width: 'calc(100% - 180px)',
    },
    importedSiebelContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,
        marginBottom: 24,
    },
    importedSiebelIcon: {
        marginRight: 15,
    },
    importedSiebelTitle: {
        fontWeight: 'bold',
    },
    importedSiebelMessageContainer: {
        paddingTop: '0 20px',
    },
    importedSiebelMessage: {
        marginBottom: 15,
        marginTop: 5,
    },
    qmrsDashBoardUpdatesRow: {
        marginLeft: 15,
        marginRight: 15,
        display: 'flex',
        paddingTop: '24px',
    },
});
const QmrsDashboardBanner: FC<{ qmrsDashboardAlertLink: IQmrsDashboardAlertLink }> = ({ qmrsDashboardAlertLink }) => {
    const classes = useQmrDashboardStyle();
    const { t } = useTranslation();

    return (
        <>
            {!!qmrsDashboardAlertLink && !!qmrsDashboardAlertLink.description && (
                <div className={classes.banner}>
                    <div className={classes.bannerContentWrapper}>
                        <div className={classes.importedSiebelIcon}>
                            <Icon name="warning" color={'white'} />
                        </div>
                        <div className={classes.bannerContent}>
                            {qmrsDashboardAlertLink.title && (
                                <Typography variant="h3" color="white">
                                    {qmrsDashboardAlertLink.title}
                                </Typography>
                            )}
                            <div>
                                {qmrsDashboardAlertLink.description && (
                                    <Typography color="white">{qmrsDashboardAlertLink.description}</Typography>
                                )}
                            </div>
                        </div>

                        {qmrsDashboardAlertLink.link && (
                            <div className="d-flex align-items-center">
                                <Button
                                    variant="info"
                                    onPress={() => {
                                        window.open(qmrsDashboardAlertLink.link, '_blank');
                                    }}
                                    iconRight={<Icon size={16} name="chevron-right" color="blueOne" />}
                                >
                                    {t('Read More')}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default QmrsDashboardBanner;
