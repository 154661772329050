import React, { FC, useEffect, useRef } from 'react';
import { Animated, StyleSheet, TouchableOpacity, View } from 'react-native';

import { Typography } from './typography';

import colors from '@packages/core/styles/colors';

interface ToggleProps {
    uncheckedLabel?: string;
    checkedLabel?: string;
    checked: boolean;
    onChange(checked: boolean): void;
}

export const Toggle: FC<ToggleProps> = ({ uncheckedLabel, checkedLabel, checked, onChange }) => {
    const uncheckedLeft = -4;
    const checkedLeft = 18;
    const animationDuration = 200;
    const toggleAnimation = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(toggleAnimation, {
            toValue: checked ? checkedLeft : uncheckedLeft,
            duration: animationDuration,
        }).start();
    }, [checked, toggleAnimation, uncheckedLeft]);

    function handleTogglePress() {
        onChange(!checked);
    }

    return (
        <View style={styles.toggleOuter}>
            {uncheckedLabel && (
                <Typography variant="label" style={styles.uncheckedLabel}>
                    {uncheckedLabel}
                </Typography>
            )}
            <TouchableOpacity style={styles.toggle} onPress={handleTogglePress}>
                <Animated.View
                    style={[
                        styles.slide,
                        {
                            backgroundColor: toggleAnimation.interpolate({
                                inputRange: [uncheckedLeft, checkedLeft],
                                outputRange: [colors.grayThree, colors.blueThree],
                            }),
                        },
                    ]}
                >
                    <Animated.View
                        style={[
                            styles.handle,
                            {
                                left: toggleAnimation,
                                backgroundColor: toggleAnimation.interpolate({
                                    inputRange: [uncheckedLeft, checkedLeft],
                                    outputRange: [colors.graySix, colors.blueOne],
                                }),
                            },
                        ]}
                    />
                </Animated.View>
            </TouchableOpacity>
            {checkedLabel && (
                <Typography variant="label" style={styles.checkedLabel}>
                    {checkedLabel}
                </Typography>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    toggleOuter: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    uncheckedLabel: {
        marginRight: 16,
    },
    checkedLabel: {
        marginLeft: 16,
    },
    toggle: {
        width: 42,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    slide: {
        width: 34,
        height: 14,
        borderRadius: 20,
        position: 'relative',
        backgroundColor: colors.grayThree,
    },
    handle: {
        top: -3,
        left: 0,
        zIndex: 1,
        width: 20,
        height: 20,
        borderRadius: 10,
        position: 'absolute',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.21,
        shadowRadius: 3,
        elevation: 3,
    },
});
