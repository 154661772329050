import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '@packages/contexts/auth';
import constants from '@web/config/constants';

import QmrHeader from '@web/qmr/components/qmr-header';
import QmrReportSections from '@web/qmr/components/qmr-report-sections';
import QmrComments from '@web/qmr/components/qmr-comments';
import { QmrInfoRequests } from '@web/qmr/components/qmr-info-requests';

import colors from '@packages/core/styles/colors';
import { AlertBar, Icon, TextBadge, Typography, Button } from '@packages/ui/shared';
import { QmrDetailsSegment, QmrActivitySegment, QmrPartRequestsSegment } from '@packages/ui/qmr';
import { fetchQmr, useQmrDispatch, useQmrState } from '@packages/contexts/qmrs';
import Avatar from '@web/components/avatar';
import EditRetailerModal from '@web/components/edit-retailer-modal';
import { QmrStatusId } from '@packages/models/api';
import { useMatch } from 'react-router-dom';
import { useQuery as useQueryParams } from '@web/core/hooks';
import { accountsService, httpClient, qmrsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { DateTime } from 'luxon';

const ALLOW_EDIT_QMR_PERMISSION = 'QMRF002';

interface QmrLayoutStyleProps {
    hideQmrHeader: boolean;
    hideAsideContent: boolean;
    children?: React.ReactNode;
}

const useQmrLayoutStyles = createUseStyles({
    qmrHeader: {
        left: 0,
        right: 0,
        display: 'flex',
        padding: '0 24px',
        position: 'fixed',
        alignItems: 'center',
        top: constants.headerHeight,
        justifyContent: 'space-between',
        height: constants.qmrHeaderHeight,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    reportIdentifiers: {
        padding: '20px',
        backgroundColor: colors.white,
    },
    sectionsHeader: {
        padding: '14px 20px 10px',
    },
    reportSections: ({ hideQmrHeader }: QmrLayoutStyleProps) => ({
        left: 0,
        bottom: 0,
        width: 248,
        overflowY: 'auto',
        position: 'fixed',
        borderRight: `1px solid ${colors.grayThree}`,
        top: constants.headerHeight + (hideQmrHeader ? 0 : constants.qmrHeaderHeight),
        backgroundColor: colors.grayOne,
    }),
    details: ({ hideQmrHeader, hideAsideContent }: QmrLayoutStyleProps) => ({
        left: 248,
        bottom: 0,
        right: hideAsideContent ? 0 : 400,
        overflowY: 'auto',
        position: 'fixed',
        top: constants.headerHeight + (hideQmrHeader ? 0 : constants.qmrHeaderHeight),
        backgroundColor: colors.white,
    }),
    asideContent: ({ hideQmrHeader, hideAsideContent }: QmrLayoutStyleProps) => ({
        right: 0,
        bottom: 0,
        width: hideAsideContent ? 0 : 400,
        overflowY: hideAsideContent ? 'hidden' : 'auto',
        position: 'fixed',
        paddingTop: 16,
        borderLeft: `1px solid ${colors.grayThree}`,
        top: constants.headerHeight + (hideQmrHeader ? 0 : constants.qmrHeaderHeight),
        backgroundColor: colors.grayOne,
        transition: '0.2s width',
    }),
    tabContent: {
        padding: '0 10px',
    },
    '@media only screen and (max-width: 1100px)': {
        details: {
            left: '0px !important',
        },
        reportSections: {
            display: 'none',
        },
    },
    collapseButton: {
        width: 24,
        height: 24,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.blueFour,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
    },
    collapseButtonOuter: {
        position: 'fixed',
        bottom: 32,
        zIndex: 999999999,
    },
    collapseButtonOuterHiddenAside: {
        position: 'fixed',
        bottom: 32,
        right: 20,
    },
    editDisabledMessageContainer: {
        padding: 16,
        paddingRight: 50,
        paddingLeft: 32,
        position: 'relative',
        flexDirection: 'row',
        backgroundColor: colors.blueFour,
    },
});

interface QmrLayoutProps {
    checklistContainerId: string;
    checklistSectionIds: string[];
    children?: React.ReactNode;
}

type QmrTab = 'DETAILS' | 'ACTIVITY' | 'COMMENTS' | 'INFO' | 'PART';

function isQmrTab(tab: string): tab is QmrTab {
    return ['DETAILS', 'ACTIVITY', 'COMMENTS', 'INFO', 'PART'].includes(tab.toUpperCase());
}

function parseQmrTab(tab: string): QmrTab {
    const upperCasedTab = tab.toUpperCase();
    return isQmrTab(upperCasedTab) ? upperCasedTab : 'DETAILS';
}

const QmrLayout: FC<QmrLayoutProps> = ({ checklistContainerId, checklistSectionIds, ...props }) => {
    const { account } = useAuthState();
    const [permissions, setPermissions] = useState<any>({});
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const { abortSignalRef } = useAbortController();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');
    const [hideAsideContent, setHideAsideContent] = useState(false);
    const classes = useQmrLayoutStyles({ hideQmrHeader: false, hideAsideContent });
    const params = useQueryParams();
    const navigate = useNavigate();

    const [tab, setTab] = useState<QmrTab>(parseQmrTab(params.get('tab') || ''));

    const [showEditRetailerModal, setShowEditRetailerModal] = useState(false);
    const [qmrSuccessfullyAddedToInvestigation, setQmrSuccessfullyAddedToInvestigation] = useState('');
    const [showSubmitted, setShowSubmitted] = useState(false);
    const isDraft = qmr?.qmrStatus.qmrStatusId === QmrStatusId.Draft;

    // update selected tab if query params change
    useEffect(() => {
        setTab(parseQmrTab(params.get('tab') || ''));
    }, [params]);

    const handlePartCollectionRequestOpen = useCallback(async () => {
        try {
            if (!qmr) {
                throw new Error('qmr could not be found.');
            }

            const response = await qmrsService.openPartCollectionRequest(qmr.qmrId);

            if (response.success) {
                fetchQmr({
                    qmrId: qmr.qmrId,
                    qmrsService,
                    qmrDispatch,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                });
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }, [abortSignalRef, qmr, qmrDispatch]);

    const handlePartCollectionRequestClose = useCallback(async () => {
        try {
            if (!qmr?.openPartCollectionRequest) {
                throw new Error('qmr could not be found.');
            }

            const response = await qmrsService.closePartCollectionRequest(
                qmr.openPartCollectionRequest.partCollectionRequestId
            );

            if (response.success) {
                fetchQmr({
                    qmrId: qmr.qmrId,
                    qmrsService,
                    qmrDispatch,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                });
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }, [abortSignalRef, qmr?.openPartCollectionRequest, qmr?.qmrId, qmrDispatch]);

    const fetchPermissions = useCallback(() => {
        if (!account || !account.accountId) {
            return;
        }
        const config = {
            method: 'GET' as const,
            signal: abortSignalRef.current,
            ignoreCache: true,
        };
        httpClient
            .orchestrateRequest<any>({
                ...config,
                url: `/accounts/${account.accountId}/permissions-debug`,
            })
            .then((permissionsResponse) => {
                if (permissionsResponse.success) {
                    let allPermissions = [...(permissionsResponse.data?.globalPermissions || [])];
                    if (permissionsResponse.data?.retailerPermissions?.length) {
                        permissionsResponse.data.retailerPermissions.forEach((item: any) => {
                            if (item.permissions?.length) {
                                allPermissions = [...allPermissions, ...item.permissions];
                            }
                        });
                    }
                    setPermissions(allPermissions);
                }
            })
            .catch((e) => {
                alert('Error: ' + e + '. Please refresh & try again.');
                console.log('error::', e);
            });
    }, [abortSignalRef, account]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    const qmrsEditPermission = useMemo(() => {
        if (permissions.length) {
            return !!permissions.find(
                (permission: { permissionId: string }) => permission.permissionId === ALLOW_EDIT_QMR_PERMISSION
            );
        }
        return false;
    }, [permissions]);

    const getAutoDeleteRemainDays = useCallback(() => {
        const lastEditDate = DateTime.fromISO(qmr!.lastEditedTimestamp);
        const currentDate = DateTime.utc();
        const daysRemaining = 180 - currentDate.diff(lastEditDate, 'days').days;
        return Math.round(daysRemaining);
    }, [qmr]);

    return (
        <>
            {qmr?.capabilities.viewRetailerOnQmr && (
                <EditRetailerModal
                    show={showEditRetailerModal}
                    onHide={() => {
                        setShowEditRetailerModal(false);
                    }}
                />
            )}

            <div className={classes.qmrHeader}>
                <QmrHeader setShowSubmitted={setShowSubmitted} />
            </div>
            <div className={classes.reportSections}>
                {!editRouteMatch && qmr?.qmrStatus.qmrStatusId !== QmrStatusId.Draft && (
                    <>
                        <div className={classes.reportIdentifiers}>
                            <div>
                                <Typography variant="caption2">{qmr?.stsIdentifier || ' '}</Typography>
                            </div>

                            <div>
                                {qmr?.restricted && (
                                    <span className="mr-2">
                                        <Icon size={16} name="lock-closed" />
                                    </span>
                                )}
                                <Typography variant="label">{qmr?.siebelIdentifier || ' '}</Typography>
                            </div>

                            <div className="d-inline-flex mt-4">
                                <TextBadge
                                    variant="green"
                                    icon={<Icon name="check-circle-hollow" color="greenOne" size={20} />}
                                >
                                    {qmr?.qmrStatus.description}
                                </TextBadge>
                            </div>
                        </div>

                        <div className={classes.sectionsHeader}>
                            <Typography variant="caption2">
                                {t('views:qmrDetail.sections.label', 'QMR Sections')}
                            </Typography>
                        </div>
                    </>
                )}

                <QmrReportSections
                    checklistContainerId={checklistContainerId}
                    checklistSectionIds={checklistSectionIds}
                />
            </div>
            <div id={checklistContainerId} className={classes.details}>
                <AlertBar
                    success
                    show={showSubmitted}
                    onClose={() => {
                        setShowSubmitted(false);
                    }}
                >
                    <Typography variant="h5">
                        {t(
                            'alerts:qmrSubmitted.message',
                            'Thank you for submitting your QMR! Your QMR helps us find and catch potential quality concerns and will be reviewed as soon as possible.'
                        )}
                    </Typography>
                </AlertBar>

                <AlertBar
                    success
                    show={!!qmrSuccessfullyAddedToInvestigation}
                    onClose={() => {
                        setQmrSuccessfullyAddedToInvestigation('');
                    }}
                >
                    <Typography variant="h5">
                        {t('alerts:qmrAddedToInvestigation.message', 'This QMR was added to the investigation')} "
                        {qmrSuccessfullyAddedToInvestigation}"
                    </Typography>
                </AlertBar>

                {qmr?.capabilities.editQmr === false && qmrsEditPermission === true && (
                    <div className={classes.editDisabledMessageContainer}>
                        <Typography variant="default">
                            {t(
                                'views:qmrDetail.details.editNotAvailableMessage',
                                'This QMR is past the editing time period. Further changes or adjustments are no longer possible.'
                            )}
                        </Typography>
                    </div>
                )}

                {isDraft && (
                    <div className={classes.editDisabledMessageContainer}>
                        <Typography variant="default">
                            {t(
                                'views:qmrDetail.details.autoDeleteDraft',
                                `This DRAFT QMR will be automatically deleted if it is not submitted within 180 days of its last edit. Currently, this QMR is scheduled to be deleted in ${getAutoDeleteRemainDays} days.`,
                                { days: getAutoDeleteRemainDays() }
                            )}
                        </Typography>
                    </div>
                )}

                {props.children}
                {hideAsideContent && (
                    <div className={`${classes.collapseButtonOuter}  ${classes.collapseButtonOuterHiddenAside}`}>
                        <Button
                            variant="link"
                            onPress={() => {
                                setHideAsideContent(!hideAsideContent);
                            }}
                        >
                            <div className={classes.collapseButton}>
                                <Icon
                                    name={hideAsideContent ? 'chevron-left' : 'chevron-right'}
                                    color="blueOne"
                                    size={16}
                                />
                            </div>
                        </Button>
                    </div>
                )}
            </div>
            <div className={classes.asideContent}>
                <div className={classes.collapseButtonOuter}>
                    <Button
                        variant="link"
                        onPress={() => {
                            setHideAsideContent(!hideAsideContent);
                        }}
                    >
                        <div className={classes.collapseButton}>
                            <Icon
                                name={hideAsideContent ? 'chevron-left' : 'chevron-right'}
                                color="blueOne"
                                size={16}
                            />
                        </div>
                    </Button>
                </div>
                <Tabs
                    style={{ paddingLeft: 32, paddingRight: 32 }}
                    activeKey={tab}
                    onSelect={(t) => {
                        const newTab = parseQmrTab(t || '');
                        setTab(newTab);
                        navigate({ search: `?tab=${newTab}` });
                    }}
                    id="qmr-details-aside-tabs"
                    mountOnEnter
                    unmountOnExit
                >
                    <Tab
                        eventKey="DETAILS"
                        title={
                            <Typography variant="label" numberOfLines={1}>
                                {t('views:qmrDetail.details.tabLabel', 'Details')}
                            </Typography>
                        }
                    >
                        <div className={classes.tabContent}>
                            <QmrDetailsSegment
                                qmr={qmr}
                                onEditRetailer={() => {
                                    setShowEditRetailerModal(true);
                                }}
                                avatarComponent={({ firstName, lastName }) => (
                                    <Avatar size={32} {...{ firstName, lastName }} />
                                )}
                                onInvestigationLinkPress={() => {
                                    if (qmr?.investigationId) {
                                        navigate(`/investigations/${qmr?.investigationId}`);
                                    }
                                }}
                                onAddedToInvestigationSuccess={(investigation) => {
                                    setQmrSuccessfullyAddedToInvestigation(investigation.title);
                                }}
                                accountService={accountsService}
                            />
                        </div>
                    </Tab>

                    {qmr?.capabilities.viewActivityLog && (
                        <Tab
                            eventKey="ACTIVITY"
                            title={
                                <Typography variant="label" numberOfLines={1}>
                                    {t('views:qmrDetail.activity.tabLabel', 'Activity')}
                                </Typography>
                            }
                        >
                            <QmrActivitySegment qmr={qmr} qmrsService={qmrsService} />
                        </Tab>
                    )}

                    {qmr?.capabilities.canViewCommentsOnQmr && (
                        <Tab
                            eventKey="COMMENTS"
                            title={
                                <Typography variant="label" numberOfLines={1}>
                                    {t('views:qmrDetail.comments.tabLabel', 'Comments')}
                                </Typography>
                            }
                        >
                            <QmrComments />
                        </Tab>
                    )}

                    {qmr?.capabilities.viewMoreInfoRequest && (
                        <Tab
                            className="pt-0"
                            eventKey="INFO"
                            title={
                                <Typography variant="label" numberOfLines={1}>
                                    {t('views:qmrDetail.infoRequests.tabLabel', 'Info/Part Request')}
                                </Typography>
                            }
                        >
                            <QmrInfoRequests />
                        </Tab>
                    )}

                    {qmr?.capabilities.viewPartCollRequest && (
                        <Tab
                            className="pt-0"
                            eventKey="PART"
                            title={
                                <Typography variant="label" numberOfLines={1}>
                                    {t('views:qmrDetail.partCollectionRequest.tabLabel', 'Part Requests')}
                                </Typography>
                            }
                        >
                            <QmrPartRequestsSegment
                                qmr={qmr}
                                onOpenRequest={handlePartCollectionRequestOpen}
                                onCloseRequest={handlePartCollectionRequestClose}
                            />
                        </Tab>
                    )}
                </Tabs>
            </div>
        </>
    );
};

export default QmrLayout;
