import React from 'react';
import { SuccessFailBadge, TextBadge, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    container: {
        borderTop: `1px solid ${colors.grayFour}`,
        marginTop: 20,
    },
    flexOne: {
        flex: 1,
    },
    dtcCodeItem: {
        border: `1px solid ${colors.grayFour}`,
        marginTop: 5,
        padding: 15,
        borderRadius: 5,
    },
});

export const DtcsDetails = ({ caseDetails }: { caseDetails: any }) => {
    const classes = useStyles();

    const renderDtcCode = () => {
        if (!caseDetails?.diagnosticTroubleCodes?.length) return;
        return caseDetails.diagnosticTroubleCodes.map((code: { diagnosticTroubleCode: ''; isPrimary: any }) => (
            <div className={`d-flex flex-row justify-content-between ${classes.dtcCodeItem}`}>
                <div className="d-flex flex-column">
                    <Typography variant="h6" color="black">
                        {code.diagnosticTroubleCode}
                    </Typography>
                    <Typography color="black">{code.diagnosticTroubleCode}</Typography>
                </div>
                <div className={`d-flex flex-row`}>
                    <TextBadge style={{ margin: 10 }}>FFD Saved to SSM</TextBadge>
                    {code.isPrimary && <TextBadge style={{ margin: 10, marginLeft: 20 }}>PRIMARY</TextBadge>}
                </div>
            </div>
        ));
    };

    return (
        <div className={`${classes.container}`}>
            <div className="d-flex flex-row align-items-center mt-5">
                <Typography variant="h3">DTCs</Typography>
            </div>
            {renderDtcCode()}
        </div>
    );
};
