import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { Nav, Tab } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';
import { Icon } from '@packages/ui/shared';
import { useContentOverlayState } from '@web/core/hooks';
import { InvestigationModel } from '@packages/models/api';
import { InvestigationDetailsTab, InvestigationMeetingTab, InvestigationCommentsTab } from '.';

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.grayOne,
    },
    panelHeader: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        padding: '15px 15px 0',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    navLink: {
        ...fonts.xs,
        padding: '15px 15px',
        color: colors.textDarkPrimary,
        '&:hover': {
            color: colors.textDarkPrimary,
        },
        '&.active': {
            fontWeight: 'bold !important',
            borderBottom: `2px solid ${colors.blueOne}`,
        },
    },
    tabPane: {
        padding: 30,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    closeButton: {
        right: 14,
        bottom: 14,
        position: 'absolute',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    actionsPopover: {
        minWidth: 335,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        borderRadius: 4,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
    },
    popoverActionsSection: {
        padding: '4px 0',
    },
});

enum TAB_KEYS {
    DETAILS = 'DETAILS',
    COMMENTS = 'COMMENTS',
    MEETING_NOTES = 'MEETING_NOTES',
}

type Props = {
    investigation: InvestigationModel;
    onInvestigationChange(investigation: InvestigationModel): void;
    onClose: () => void;
};

const InvestigationDetailPanel = ({ investigation, onInvestigationChange, ...props }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { dismissAllOverlays } = useContentOverlayState();

    /* ----------------------------------------------------------------------------------------- */
    /* Hack to force bootstrap's Tab.Pane's sonEnter handler to fire when the side-panel appears */
    /* ----------------------------------------------------------------------------------------- */
    const [tabKey, setTabKey] = useState(TAB_KEYS.COMMENTS);
    useEffect(() => {
        setTabKey(TAB_KEYS.DETAILS);
    }, []);

    const rightPanelDiv = document.getElementById('right-side-panel');

    if (!rightPanelDiv) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <Tab.Container
                id="investigation-detial-panel-tabs"
                activeKey={tabKey}
                onSelect={(key) => setTabKey(key as TAB_KEYS)}
            >
                <div className={classes.panelHeader}>
                    <Nav>
                        <Nav.Item>
                            <Nav.Link eventKey={TAB_KEYS.DETAILS} className={classes.navLink}>
                                {t('investigations:panel.tabs.details.label', 'Details')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={TAB_KEYS.COMMENTS} className={classes.navLink}>
                                {t('investigations:panel.tabs.comments.label', 'Comments')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={TAB_KEYS.MEETING_NOTES} className={classes.navLink}>
                                {t('investigations:panel.tabs.meetingNotes.label', 'Meeting Notes')}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className={classes.closeButton} onClick={dismissAllOverlays}>
                        <Icon
                            name="x-close"
                            style={{
                                display: 'flex',
                            }}
                        />
                    </div>
                </div>
                <div className={classes.panelScrollContainer}>
                    <Tab.Content className="w-100">
                        <InvestigationDetailsTab
                            eventKey={TAB_KEYS.DETAILS}
                            investigation={investigation}
                            onInvestigationChange={onInvestigationChange}
                        />
                        <InvestigationCommentsTab eventKey={TAB_KEYS.COMMENTS} investigation={investigation} />
                        <InvestigationMeetingTab eventKey={TAB_KEYS.MEETING_NOTES} investigation={investigation} />
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>,
        rightPanelDiv
    );
};

export default InvestigationDetailPanel;
