import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QmrStatusId } from '@packages/models/api';
import { FailCodesContainerModal } from './fail-codes-container-modal';
import { SuccessFailBadge, Typography, Button } from '@packages/ui/shared';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@web/components/table';
import { patchQmr, useQmrDispatch, useQmrState } from '@packages/contexts/qmrs';
import { FeedbackRequestMessage } from '@web/feedback/components';
import { qmrsService } from '@web/services/singletons';
import { useAbortController } from '../../../../../packages/core/http';

interface FailCodeReportSectionProps {
    potentialFailCodeFound?: boolean;
}

const FailCodeReportSection: FC<FailCodeReportSectionProps> = ({ potentialFailCodeFound }) => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const { abortSignalRef } = useAbortController();

    const isSubmitted = qmr && qmr.qmrStatus.qmrStatusId !== QmrStatusId.Draft;
    const showContainer = !(qmr && qmr.failCode) || !isSubmitted;

    const [showFailCodeSearchModal, setShowFailCodeSearchModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <Typography variant="h4">
                    {t('qmr:sections.failCode.title', 'Fail Code')}
                    <Typography color="redOne"> *</Typography>
                </Typography>
                {(!isSubmitted || (isSubmitted && qmr?.capabilities.deleteFailCodeOnQmr)) && (
                    <>
                        <FailCodesContainerModal
                            handleSuccess={(message) => setSuccessMessage(message)}
                            show={showFailCodeSearchModal}
                            onHide={() => setShowFailCodeSearchModal(false)}
                        />
                        {(qmr?.capabilities.addFailCodeToQmr || qmr?.capabilities.editFailCodeOnQmr) && (
                            <Button
                                variant={qmr?.failCode ? 'tertiary' : 'primary'}
                                onPress={() => {
                                    setShowFailCodeSearchModal(true);
                                }}
                            >
                                {qmr?.failCode
                                    ? t('qmr:sections:failCode.changeFailCode', 'Change Fail Code')
                                    : t('qmr:sections:failCode.addFailCode')}
                            </Button>
                        )}
                    </>
                )}
            </div>

            {potentialFailCodeFound && (
                <div className="mt-6">
                    <FeedbackRequestMessage
                        className="pt-1 pb-0 align-items-center"
                        iconName="info"
                        title="A matching fail code has been found."
                        callToActionTitle="View Matching Fail Code"
                        callToActionOnPress={() => {
                            setShowFailCodeSearchModal(true);
                        }}
                    />
                </div>
            )}

            {qmr?.failCodeApproved === false && !qmr?.capabilities.addFailCodeToQmr && (
                <>
                    <Typography>
                        The Fail Code will be added by the Fail Code approver after the QMR is submitted.
                    </Typography>

                    {qmr?.capabilities.revokeFailCodeApproval && (
                        <div className="d-flex mt-2">
                            <Button
                                variant="ghost-blue"
                                title="Add the Fail Code myself"
                                onPress={() => {
                                    patchQmr({
                                        qmrsService,
                                        qmrDispatch,
                                        qmrPatch: {
                                            revokeFailCodeApproval: true,
                                        },
                                        qmrId: qmr.qmrId,
                                        signal: abortSignalRef.current,
                                    })
                                        .then((updatedQmr) => {
                                            // if (updatedQmr) {
                                            //     navigation.pop();
                                            //     return;
                                            // }
                                        })
                                        .catch((e) => {
                                            alert(e.message);
                                        });
                                }}
                            />
                        </div>
                    )}
                </>
            )}

            {qmr?.failCode && (
                <>
                    {Boolean(successMessage) && (
                        <div className="mt-3">
                            <SuccessFailBadge success>
                                <Typography>{successMessage}</Typography>
                            </SuccessFailBadge>
                        </div>
                    )}

                    <div className="mt-3 mb-6">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>
                                        <Typography variant="caption2">Fail Code & Description</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="caption2">Section & Subsection</Typography>
                                    </TableHeader>
                                    <TableHeader></TableHeader>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <div>
                                            <Typography>{qmr?.failCode}</Typography>
                                        </div>
                                        <div>
                                            <Typography color="textDarkSecondary">
                                                {qmr?.failCodeDescription}
                                            </Typography>
                                        </div>
                                        {qmr?.isValidFailCode === false && (
                                            <div>
                                                <Typography color="redOne">
                                                    This is not a recognized fail code. Please update it.
                                                </Typography>
                                            </div>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <div>
                                            <Typography>{qmr?.failCodeSectionName}</Typography>
                                        </div>
                                        <div>
                                            <Typography color="textDarkSecondary">
                                                {qmr?.failCodeSubsectionName}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </>
            )}
        </>
    );
};

export default FailCodeReportSection;
