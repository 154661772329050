import { HttpClient } from '@packages/core/http';
import { IHaveYouSeen, IQmrsDashboardAlertLink, ICounterMeasureOfTheMonth, IHelpfulLink } from '@packages/models/api';

export class QmrsDashboardService {
    constructor(private http: HttpClient) {}

    public fetchAllQmrsDashboardAlertLinks = async ({
        ignoreCache = false,
        signal,
    }: {
        ignoreCache?: boolean;
        id?: string;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/qmr/dashboard/alertLinks',
            ignoreCache,
            signal,
        });
    };

    public createQmrsDashboardAlertLink = async ({
        ignoreCache = false,
        qmrsDashboardAlertLink,
        signal,
    }: {
        ignoreCache?: boolean;
        qmrsDashboardAlertLink: Partial<IQmrsDashboardAlertLink>;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/qmr/dashboard/alertlinks',
            ignoreCache,
            body: {
                qmrDashboardAlertLinks: {
                    title: qmrsDashboardAlertLink.title,
                    description: qmrsDashboardAlertLink.description,
                    link: qmrsDashboardAlertLink.link,
                    displayOrder: qmrsDashboardAlertLink.displayOrder,
                },
            },
            signal,
        });
    };

    public updateQmrsDashboardAlertLink = async ({
        ignoreCache = false,
        qmrsDashboardAlertLink,
        signal,
    }: {
        ignoreCache?: boolean;
        qmrsDashboardAlertLink: Partial<IQmrsDashboardAlertLink>;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: `/qmr/dashboard/alertlinks/${qmrsDashboardAlertLink.qmrDashAlertLinksId}`,
            ignoreCache,
            body: {
                qmrDashboardAlertLinks: {
                    title: qmrsDashboardAlertLink.title,
                    description: qmrsDashboardAlertLink.description,
                    link: qmrsDashboardAlertLink.link,
                    displayOrder: qmrsDashboardAlertLink.displayOrder,
                },
            },
            signal,
        });
    };

    public deleteQmrsDashboardAlertLink = async ({
        id,
        ignoreCache = false,
        signal,
    }: {
        id: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'DELETE',
            url: `/qmr/dashboard/alertLinks/${id}`,
            ignoreCache,
            signal,
        });
    };

    public fetchAllHaveYouSeen = async ({
        ignoreCache = false,
        signal,
    }: {
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/qmr/dashboard/haveyouseen',
            ignoreCache,
            signal,
        });
    };

    public createHaveYouSeen = async ({
        haveYouSeen,
        ignoreCache = false,
        signal,
    }: {
        haveYouSeen: IHaveYouSeen;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/qmr/dashboard/haveyouseen',
            ignoreCache,
            body: {
                qmrDashboardHaveYouSeen: {
                    title: haveYouSeen.title,
                    description: haveYouSeen.description,
                    link: haveYouSeen.link,
                    displayOrder: haveYouSeen.displayOrder,
                },
            },
            signal,
        });
    };

    public updateHaveYouSeen = async ({
        haveYouSeen,
        ignoreCache = false,
        signal,
    }: {
        haveYouSeen: IHaveYouSeen;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        const qmrDashHaveYouSeenId = haveYouSeen.qmrDashHaveYouSeenId;
        return await this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: `/qmr/dashboard/haveyouseen/${qmrDashHaveYouSeenId}?move=Yes`,
            ignoreCache,
            body: {
                qmrDashboardHaveYouSeen: {
                    title: haveYouSeen.title,
                    description: haveYouSeen.description,
                    link: haveYouSeen.link,
                    displayOrder: haveYouSeen.displayOrder,
                },
            },
            signal,
        });
    };

    public deleteHaveYouSeen = async ({
        id,
        ignoreCache = false,
        signal,
    }: {
        id: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'DELETE',
            url: `/qmr/dashboard/haveyouseen/${id}`,
            ignoreCache,
            signal,
        });
    };

    public fetchAllCounterMeasureOfTheMonth = async ({
        ignoreCache = false,
        signal,
    }: {
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/qmr/dashboard/countermeasure/',
            ignoreCache,
            signal,
        });
    };

    public createCounterMeasureOfMonth = async ({
        counterMeasureOfTheMonth,
        ignoreCache = false,
        signal,
    }: {
        counterMeasureOfTheMonth: ICounterMeasureOfTheMonth;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/qmr/dashboard/countermeasure/',
            ignoreCache,
            body: {
                qmrDashboardCounterMeasure: {
                    title: counterMeasureOfTheMonth.title,
                    description: counterMeasureOfTheMonth.description,
                    link: counterMeasureOfTheMonth.link,
                    displayOrder: counterMeasureOfTheMonth.displayOrder,
                },
            },
            signal,
        });
    };

    public updateCounterMeasureOfTheMonth = async ({
        counterMeasureOfTheMonth,
        ignoreCache = false,
        signal,
    }: {
        counterMeasureOfTheMonth: ICounterMeasureOfTheMonth;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: `/qmr/dashboard/countermeasure/${counterMeasureOfTheMonth.qmrDashCounterMeasureId}?move=Yes`,
            ignoreCache,
            body: {
                qmrDashboardCounterMeasure: {
                    title: counterMeasureOfTheMonth.title,
                    description: counterMeasureOfTheMonth.description,
                    link: counterMeasureOfTheMonth.link,
                    displayOrder: counterMeasureOfTheMonth.displayOrder,
                },
            },
            signal,
        });
    };

    public deleteCounterMeasureOfMonth = async ({
        id,
        ignoreCache = false,
        signal,
    }: {
        id: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'DELETE',
            url: `/qmr/dashboard/countermeasure/${id}`,
            ignoreCache,
            signal,
        });
    };

    public fetchAllHelpfulLinks = async ({
        ignoreCache = false,
        signal,
    }: {
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'GET',
            url: '/qmr/dashboard/helpfullinks',
            ignoreCache,
            signal,
        });
    };

    public updateHelpfulLink = async ({
        helpFulLink,
        ignoreCache = false,
        signal,
    }: {
        helpFulLink: IHelpfulLink;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'PATCH',
            url: `/qmr/dashboard/helpfullinks/${helpFulLink.qmrDashHelpfulLinksId}`,
            ignoreCache,
            body: {
                qmrDashboardHelpfulLinks: {
                    title: helpFulLink.title,
                    description: helpFulLink.description,
                    link: helpFulLink.link,
                    displayOrder: helpFulLink.displayOrder,
                },
            },
            signal,
        });
    };

    public createHelpfulLink = async ({
        helpFulLink,
        ignoreCache = false,
        signal,
    }: {
        helpFulLink: IHelpfulLink;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/qmr/dashboard/helpfullinks',
            ignoreCache,
            body: {
                qmrDashboardHelpfulLinks: {
                    title: helpFulLink.title,
                    description: helpFulLink.description,
                    link: helpFulLink.link,
                    displayOrder: helpFulLink.displayOrder,
                },
            },
            signal,
        });
    };

    public deleteHelpfulLink = async ({
        id,
        ignoreCache = false,
        signal,
    }: {
        id: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) => {
        return await this.http.orchestrateRequest<any>({
            method: 'DELETE',
            url: `/qmr/dashboard/helpfulLinks/${id}`,
            ignoreCache,
            signal,
        });
    };
}
