import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Typography } from './typography';

interface Props<C> {
    comment: C;
    authorName: string;
    timestamp: string;
    body: string;
    hideAuthor?: boolean;
    authorTextVariant?: 'default' | 'bold';
    commentOptionsRenderer?: (comment: C) => JSX.Element | null;
    editCommentRenderer?: (comment: C) => JSX.Element;
}

const authorTextVariants = {
    default: {
        variant: 'default',
        color: 'blueOne',
    } as const,
    bold: {
        variant: 'h6',
        color: 'textDarkPrimary',
    } as const,
} as const;

export function BaseComment<C>({
    comment,
    authorName,
    timestamp,
    body,
    authorTextVariant = Platform.select({ web: 'default', default: 'bold' }),
    hideAuthor = false,
    commentOptionsRenderer,
    editCommentRenderer,
}: Props<C>) {
    return (
        <View>
            <View style={styles.commentHeader}>
                <View>
                    {!hideAuthor && <Typography {...authorTextVariants[authorTextVariant]}>{authorName}</Typography>}

                    <Typography variant="caption" color="graySix">
                        {timestamp}
                    </Typography>
                </View>
                {commentOptionsRenderer && <View>{commentOptionsRenderer(comment)}</View>}
            </View>
            {editCommentRenderer ? (
                editCommentRenderer(comment)
            ) : (
                <View style={styles.commentBody}>
                    <Typography>{body}</Typography>
                </View>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    commentHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
    },
    commentBody: {
        marginBottom: 24,
    },
});
