import React, { createContext, FC, useReducer } from 'react';

import {
    AssetUploadsActionDispatcher,
    assetUploadsReducer,
    AssetUploadsState,
    initialState,
    assetUploadsActions,
    AssetUploadTaskStatus,
} from './asset-uploads.state';
import { AssetUploadsService } from './asset-uploads.abstract-service';
import { Asset } from '@packages/models/api';

export const AssetUploadsStateContext = createContext<AssetUploadsState | undefined>(undefined);
export const AssetUploadsDispatchContext = createContext<AssetUploadsActionDispatcher | undefined>(undefined);

export const AssetUploadsProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(assetUploadsReducer, initialState);

    return (
        <AssetUploadsStateContext.Provider value={state}>
            <AssetUploadsDispatchContext.Provider value={dispatch}>{children}</AssetUploadsDispatchContext.Provider>
        </AssetUploadsStateContext.Provider>
    );
};

interface AssetUploadDataAccess {
    assetUploadsService: AssetUploadsService;
    assetUploadsDispatch: AssetUploadsActionDispatcher;
    ignoreCache?: boolean;
    signal?: AbortSignal;
}

export async function updateAssetProcessingStatus({
    uploadId,
    assetId,
    assetProcessingStateId,
    error,
    assetUploadsService,
    assetUploadsDispatch,
    signal,
}: {
    uploadId: string;
    assetId: Asset['assetId'];
    assetProcessingStateId: Asset['assetProcessingStateId'];
    error?: string;
} & AssetUploadDataAccess) {
    assetUploadsDispatch(
        assetUploadsActions.updateTask({
            uploadId,
            status: AssetUploadTaskStatus.Error,
            error,
        })
    );

    const response = await assetUploadsService.updateAssetProcessingStatus({
        assetId,
        assetProcessingStateId,
        error,
        signal,
    });

    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    return response.data.asset;
}
