import React, { FC } from 'react';
import { StyleSheet, View, StyleProp, ViewStyle, Image } from 'react-native';

import colors from '@packages/core/styles/colors';
import { AssetProcessingState } from '@packages/models/api';

import { Typography } from './typography';

interface AssetStatusPillProps {
    status: AssetProcessingState;
    style?: StyleProp<ViewStyle>;
}

export const AssetStatusPill: FC<AssetStatusPillProps> = ({ status, style }) => {
    switch (status) {
        case AssetProcessingState.Uploading: {
            return (
                <View style={[styles.container, styles.containerUploading, style]}>
                    <Image source={require('@packages/ui/assets/icons/uploading-circle.png')} />
                    <Typography style={styles.assetStatusPillText}>Uploading</Typography>
                </View>
            );
        }
        case AssetProcessingState.Processing: {
            return (
                <View style={[styles.container, styles.containerProcessing, style]}>
                    <Image source={require('@packages/ui/assets/icons/processing-circle.png')} />
                    <Typography style={styles.assetStatusPillText}>Upload Processing</Typography>
                </View>
            );
        }
        case AssetProcessingState.Complete: {
            return (
                <View style={[styles.container, styles.containerComplete, style]}>
                    <Image source={require('@packages/ui/assets/icons/check-circle-green.png')} />
                    <Typography style={styles.assetStatusPillText}>Uploaded</Typography>
                </View>
            );
        }
        case AssetProcessingState.Error: {
            return (
                <View style={[styles.container, styles.containerError, style]}>
                    <Image source={require('@packages/ui/assets/icons/error-circle-red.png')} />
                    <Typography style={styles.assetStatusPillText}>Upload Failed</Typography>
                </View>
            );
        }
        default:
            return null;
    }
};

const styles = StyleSheet.create({
    container: {
        paddingLeft: 2,
        paddingRight: 10,
        borderRadius: 50,
        paddingVertical: 2,
        alignItems: 'center',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        backgroundColor: colors.greenFour,
    },
    assetStatusPillText: {
        marginLeft: 2,
        lineHeight: 18,
        fontWeight: '600',
    },
    containerUploading: {
        backgroundColor: colors.grayTwo,
    },
    containerProcessing: {
        backgroundColor: colors.yellowFour,
    },
    containerComplete: {
        backgroundColor: colors.greenFour,
    },
    containerError: {
        backgroundColor: colors.redFour,
    },
});
