import * as React from 'react';
import { action, on, props, reducer, union } from 'ts-action';
import { FormElement, FormElementTypeId } from '@packages/models/api';
export const baseFormElement = {
    formElementId: '',
    formElementTypeId: FormElementTypeId.TextHeader,
    formElementLabel: 'Lorem ipsum dolor sit amet.',
    selectedOptions: [],
    options: [],
    title: 'Text: Header',
    description: 'Header',
    iconName: 'title',
    formElementTooltip: '',
    formElementFile: '',
    formElementDescription: '',
    optionConditionsElements: [],
    required: false,
    formElementHasRule: false,
};
export enum formElementModalActionsList {
    SET_ELEMENT = 'SET_ELEMENT',
    UPDATE_ELEMENT = 'UPDATE_ELEMENT',
}

export interface IFormElementState {
    formElement: FormElement;
}

export const formElementActions = {
    setFormElement: action(formElementModalActionsList.SET_ELEMENT, props<{ formElement: FormElement }>()),
    updateFormElement: action(formElementModalActionsList.UPDATE_ELEMENT, props<{ formElement: FormElement }>()),
};

const actionsUnion = union(...Object.values(formElementActions));

export type FormElementActions = typeof actionsUnion.actions;

export type FormElementActionDispatcher = React.Dispatch<FormElementActions>;

export const initialState: IFormElementState = {
    formElement: baseFormElement,
};

export const formElementReducer: React.Reducer<IFormElementState, FormElementActions> = reducer(
    initialState,
    on(formElementActions.setFormElement, (state, { formElement }) => {
        return {
            ...state,
            formElement,
        };
    }),
    on(formElementActions.updateFormElement, (state, { formElement }) => {
        return {
            ...state,
            formElement,
        };
    })
);
