import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Spinner, Badge, Overlay } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useAbortController } from '@packages/core/http';
import colors from '@packages/core/styles/colors';
import { Comment } from '@packages/models/api';

import { commentsService, qmrsService } from '@web/services/singletons';
import { useQmrDispatch, fetchQmr, useQmrState } from '@packages/contexts/qmrs';
import { Icon, Typography, Button, TextareaHelper } from '@packages/ui/shared';
import { QmrCommentsList } from '@packages/ui/qmr';
import { Popover } from 'react-tiny-popover';
import ConfirmDeleteCommentModal from './confirm-delete-comment-modal';
import { getPopoverContentLocation } from '../../utils/popover-content-location';

const useQmrCommentsStyles = createUseStyles({
    commentsContainer: {
        padding: '0 32px',
        minHeight: 250,
    },
    addCommentContainer: {
        padding: '0 32px',
        borderTop: `1px solid ${colors.grayThree}`,
    },
    info: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    actionsPopover: {
        minWidth: 150,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        borderRadius: 4,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
    },
    popoverActionsSection: {
        padding: '4px 0',
    },
});

const QmrComments: FC = () => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();

    const { abortSignalRef } = useAbortController();
    const classes = useQmrCommentsStyles();

    const tooltipRef = useRef<HTMLDivElement>(null);
    const [qmrComments, setQmrComments] = useState<Comment[]>([]);
    const [newComment, setNewComment] = useState('');
    const [editComment, setEditComment] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [expandedOptionsCommentId, setExpandedOptionsCommentId] = useState('');
    const [commentIdForDeletion, setCommentIdForDeletion] = useState('');
    const [commentIdForEdit, setCommentIdForEdit] = useState('');

    const qmrId = qmr ? qmr.qmrId : '';
    const fetchQmrComments = useCallback(() => {
        if (!qmrId) {
            return;
        }

        commentsService
            .fetchComments({
                qmrId,
                signal: abortSignalRef.current,
                ignoreCache: true,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    return Promise.reject(response.data);
                }

                setIsLoading(false);
                setQmrComments(response.data.comments);
            })
            .catch((e) => {
                setIsLoading(false);
                alert(e.message);
            });
    }, [abortSignalRef, qmrId]);

    useEffect(() => {
        fetchQmrComments();
    }, [fetchQmrComments]);

    const addComment = useCallback(() => {
        if (!qmr || isSaving) {
            return;
        }

        setIsSaving(true);
        commentsService
            .addComment({
                qmrId: qmr.qmrId,
                body: newComment,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setNewComment('');
                setIsSaving(false);
                fetchQmrComments();
                fetchQmr({
                    qmrId: qmr.qmrId,
                    qmrsService,
                    qmrDispatch,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                });
            })
            .catch((e) => {
                setIsSaving(false);
                alert(e.message);
            });
    }, [abortSignalRef, fetchQmrComments, isSaving, newComment, qmr, qmrDispatch]);

    return (
        <>
            <ConfirmDeleteCommentModal
                show={!!commentIdForDeletion}
                commentId={commentIdForDeletion}
                onHide={(didDelete) => {
                    if (didDelete) {
                        setQmrComments(qmrComments.filter((qC) => qC.commentId !== commentIdForDeletion));
                        fetchQmrComments();
                    }

                    setCommentIdForDeletion('');
                }}
            />
            <div className={classNames(classes.commentsContainer, 'mb-4')}>
                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <QmrCommentsList
                        comments={qmrComments}
                        commentOptionsRenderer={(comment) => {
                            const showOptionsButton =
                                comment.commentCapabilities.canEditComment ||
                                comment.commentCapabilities.canDeleteComment;

                            if (!showOptionsButton) {
                                return null;
                            }

                            const isOpen = expandedOptionsCommentId === comment.commentId;

                            return (
                                <Popover
                                    isOpen={isOpen}
                                    positions={['bottom', 'top']}
                                    onClickOutside={() => setExpandedOptionsCommentId('')}
                                    contentLocation={({ childRect, popoverRect, position }) => {
                                        return getPopoverContentLocation(childRect, popoverRect, position, 4);
                                    }}
                                    containerClassName={classes.actionsPopover}
                                    content={
                                        <div className={classes.popoverActionsSection}>
                                            {/* {comment.commentCapabilities.canEditComment && (
                                                <Button
                                                    alignTitle="left"
                                                    variant="ghost-gray"
                                                    onPress={() => {
                                                        setExpandedOptionsCommentId('');
                                                        setCommentIdForEdit(comment.commentId);
                                                        setEditComment(comment.body);
                                                    }}
                                                >
                                                    <Icon name="pencil" size={16} />
                                                    <Typography style={{ paddingLeft: 12 }} variant="labelRegular">
                                                        {t('qmr:sections.comments.actions.edit', 'Edit')}
                                                    </Typography>
                                                </Button>
                                            )} */}

                                            {comment.commentCapabilities.canDeleteComment && (
                                                <Button
                                                    alignTitle="left"
                                                    variant="ghost-gray"
                                                    onPress={() => {
                                                        setExpandedOptionsCommentId('');
                                                        setCommentIdForDeletion(comment.commentId);
                                                    }}
                                                >
                                                    <Icon name="trash" size={16} color="redOne" />
                                                    <Typography
                                                        style={{ paddingLeft: 12 }}
                                                        variant="labelRegular"
                                                        color="redOne"
                                                    >
                                                        {t('qmr:sections.comments.actions.delete', 'Delete')}
                                                    </Typography>
                                                </Button>
                                            )}
                                        </div>
                                    }
                                >
                                    <div>
                                        <Button
                                            active={isOpen}
                                            variant="ghost-gray"
                                            onPress={() => {
                                                setExpandedOptionsCommentId(isOpen ? '' : comment.commentId);
                                            }}
                                        >
                                            <Icon name="more-dots-vertical" />
                                        </Button>
                                    </div>
                                </Popover>
                            );
                        }}
                        editCommentId={commentIdForEdit}
                        editCommentRenderer={(comment) => {
                            return (
                                <>
                                    <div className="mt-3 bg-white">
                                        <TextareaHelper
                                            containerStyle={{ height: 80 }}
                                            placeholder={t(
                                                'qmr:sections.comments.editComment.placeholder',
                                                'Add a comment...'
                                            )}
                                            value={editComment}
                                            onChangeText={setEditComment}
                                        />
                                    </div>

                                    <div className="mt-2 d-flex justify-content-end">
                                        <span className="mr-2">
                                            <Button
                                                onPress={() => {
                                                    setCommentIdForEdit('');
                                                    setEditComment('');
                                                }}
                                                variant="ghost-gray"
                                            >
                                                {t('qmr:sections.comments.editComment.actions.cancel', 'Cancel')}
                                            </Button>
                                        </span>

                                        <Button
                                            variant="primary"
                                            onPress={() => {
                                                // TODO: Edit comment
                                                setCommentIdForEdit('');
                                            }}
                                        >
                                            {t('qmr:sections.comments.editComment.actions.save', 'Save')}
                                        </Button>
                                    </div>
                                </>
                            );
                        }}
                    />
                )}
            </div>

            {qmr?.capabilities.canAddCommentToQmr && (
                <div className={classes.addCommentContainer}>
                    <div className="mt-3 bg-white">
                        <TextareaHelper
                            containerStyle={{ height: 80 }}
                            placeholder={t('qmr:sections.comments.newComment.placeholder', 'Add a comment...')}
                            value={newComment}
                            onChangeText={setNewComment}
                        />
                    </div>

                    <div className="mt-2 d-flex justify-content-end">
                        <span className="mr-2">
                            <Button onPress={() => setNewComment('')} variant="ghost-gray">
                                {t('qmr:sections.comments.newComment.actions.cancel', 'Cancel')}
                            </Button>
                        </span>

                        <Button variant="primary" isLoading={isSaving} onPress={addComment}>
                            {t('qmr:sections.comments.newComment.actions.addComment', 'Comment')}
                        </Button>
                    </div>

                    <div
                        ref={tooltipRef}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        className={classNames(classes.info, 'mt-4 d-flex align-items-center')}
                    >
                        <span className="mr-2">
                            <Icon size={16} name="question-mark" color="blueOne" />
                        </span>
                        <Typography variant="caption" color="blueOne">
                            {t('qmr:sections.comments.whoCanSeeComment')}
                        </Typography>
                    </div>

                    <Overlay placement="top-start" target={tooltipRef.current as any} show={showTooltip}>
                        {(overlayProps: any) => {
                            return (
                                <Badge ref={overlayProps.ref} style={overlayProps.style} variant="secondary">
                                    <div className="py-2 px-3 text-left">
                                        <Typography color="white">
                                            {t('qmr:sections.comments.commentMessage')}
                                        </Typography>
                                    </div>
                                </Badge>
                            );
                        }}
                    </Overlay>
                </div>
            )}
        </>
    );
};

export default QmrComments;
