import * as React from 'react';
import { View } from 'react-native';

export const ContentSettingsMainWrapper = () => {
    const [contentSettingMain, setContentSettingsMain] = React.useState<JSX.Element | null>(null);
    React.useEffect(() => {
        const createMain = async () => {
            const { ContentSettingsMain } = await import('./content-settings-main');
            setContentSettingsMain(<ContentSettingsMain />);
        };
        if (process.env.NODE_ENV !== 'test') {
            createMain();
        }
    }, []);
    return <View>{contentSettingMain}</View>;
};
