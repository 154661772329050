import availableEnvs, { Environment, BuildConfig } from '@packages/core/config/build';

function envToConfig(env: NodeJS.ProcessEnv): Readonly<Environment> {
    return Object.entries(env)
        .filter(([envVar, value]) => {
            const isAppConfig = envVar.startsWith('REACT_APP_');
            const hasValue = typeof value !== 'undefined';

            return isAppConfig && hasValue;
        })
        .map<[keyof Environment, string]>(([envVar, value]) => [
            envVar.replace('REACT_APP_', '') as keyof Environment,
            value!,
        ])
        .reduce((local, [envVar, value]) => {
            local[envVar] = value;
            return local;
        }, {} as Environment);
}

export function getEnvConfig(): Readonly<BuildConfig> {
    const serverConfig = document.getElementById('react-app-env-config');
    if (__DEV__) {
        // This is a build-time flag.

        // `react-scripts` build script
        // hijacks `process.env.NODE_ENV` and sets it to "production"
        // regardless of what process is executing it.
        // Used by the build toolchain to differentiate between:
        //   - "bundling for the local development server" and,
        //   - "generating opitmized bundles".

        // `process.env` is controlled by `react-scripts`.
        // Expose build process env vars to app in the `.env`
        // file located at the root of the web app project.
        // The env var name must start with "REACT_APP_" for
        // the build script to let it through.

        const localConfig = envToConfig(process.env);

        if (Object.keys(localConfig).length === 0) {
            return availableEnvs;
        }

        return {
            buildEnv: 'local',
            debugEnv: serverConfig?.innerHTML ? JSON.parse(serverConfig.innerHTML).REACT_APP_DEBUG_ENV : 'local',
            availableEnvironments: { local: envToConfig(process.env) },
        };
    }

    if (!serverConfig) {
        console.warn('App is running without configuration.');
        // TODO: What should the default be if config is missing from non-development server?

        // Using "build-time" env vars as defaults
        return availableEnvs;
    }

    return {
        buildEnv: 'production',
        debugEnv: JSON.parse(serverConfig.innerHTML).REACT_APP_DEBUG_ENV,
        availableEnvironments: {
            production: envToConfig(JSON.parse(serverConfig.innerHTML)),
        },
    };
}

export function getReleaseVersion(): string {
    const serverConfig = document.getElementById('react-app-env-config');

    if (serverConfig?.innerHTML) {
        const parsedEnv = JSON.parse(serverConfig.innerHTML).REACT_APP_DEBUG_ENV;
        if (parsedEnv === 'production') {
            return JSON.parse(serverConfig.innerHTML).REACT_APP_APP_VERSION;
        }

        return JSON.parse(serverConfig.innerHTML).REACT_APP_APP_VERSION;
    }

    return 'local';
}

export function getEnvironment(): string {
    const serverConfig = document.getElementById('react-app-env-config');

    if (serverConfig?.innerHTML) {
        return JSON.parse(serverConfig.innerHTML).REACT_APP_DEBUG_ENV;
    }

    return 'local';
}
