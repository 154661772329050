import React, { useEffect, useRef, useState, useCallback, useMemo, FC } from 'react';
import ReactDOM from 'react-dom';
import { EditorApi, UIEvent, PhotoEditorSDKUI, CanvasAction, Tool, ExportFormat } from 'photoeditorsdk';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Viewer from 'react-viewer';
import colors from '@packages/core/styles/colors';
import { FormElementFile } from '@packages/models/api';
import { Icon, TextInputHelper, Typography } from '@packages/ui/shared';
import { Button } from 'react-bootstrap';

interface Props {
    formElementFile: FormElementFile;
    viewOnly: boolean;
    toggleEdit?: () => void;
    onClose: () => void;
    onSave: (file: File) => void;
}

export const AssetEditorModal: FC<Props> = ({ formElementFile, viewOnly, onClose, onSave }) => {
    const { t } = useTranslation();
    const assetEditorRootRef = useRef(document.getElementById('asset-editor-root'));
    const assetEditorElementRef = useRef(document.createElement('div'));
    const editorRef = useRef<EditorApi | null>(null);
    const originalBlobRef = useRef<Blob | null>(null);
    const viewerContainerRef = useRef(null);
    const [editorReady, setEditorReady] = useState(false);
    const [viewerData, setViewerData] = useState<any>([]);
    const [zoomScaleDisplay, setZoomScaleDisplay] = useState('100%');
    const classes = useCanvasStyles();

    const showEditorSDK = !viewOnly;

    const [assetNameInputValue, setAssetNameInputValue] = useState(formElementFile?.name ?? '');
    const [inValidMediaName, setInValidMediaName] = useState(false);
    const editName = useMemo(() => {
        return formElementFile.name! || t('modals:assetEditor.imageTitle');
    }, [formElementFile.name, t]);

    const handleCanvasClose = useCallback(() => {
        editorRef.current?.dispose();
        onClose();
    }, [onClose]);

    const downloadImage = async (imageSrc: string, fileName: string) => {
        const image = await fetch(imageSrc);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        setViewerData([
            {
                src: formElementFile.url,
                alt: formElementFile.name,
                iconName: 'file',
                downloadUrl: formElementFile.url,
            },
        ]);
    }, [formElementFile.name, formElementFile.url]);

    // Mount the modal as sibling to app
    // worth mentioning, this component/modal renders through a PORTAL
    useEffect(() => {
        const rootRef = assetEditorRootRef.current;
        const elRef = assetEditorElementRef.current;
        elRef.classList.toggle(classes.canvasOuter, true);
        rootRef?.appendChild(assetEditorElementRef.current);
        const originalUri = formElementFile.url;
        if (showEditorSDK && originalUri) {
            PhotoEditorSDKUI.init({
                container: '#editor',
                image: originalUri,
                license: JSON.stringify(require('@web/pesdk_html5_license.json')),
                mainCanvasActions: [CanvasAction.UNDO, CanvasAction.REDO],
                assetBaseUrl: '/photoeditorsdk/assets/',
                defaultTool: Tool.TRANSFORM,
                tools: [Tool.TRANSFORM, Tool.ADJUSTMENT, Tool.BRUSH],
                [Tool.TRANSFORM]: {
                    categories: [
                        {
                            identifier: 'imgly_transforms_common',
                            items: [
                                { identifier: 'imgly_transform_common_custom' },
                                { identifier: 'imgly_transform_common_square' },
                                { identifier: 'imgly_transform_common_4' },
                                { identifier: 'imgly_transform_common_16' },
                                { identifier: 'imgly_transform_common_3' },
                                { identifier: 'imgly_transform_common_9' },
                            ],
                        },
                    ],
                },
                order: 'reverse',
                export: {
                    image: {
                        exportType: ExportFormat.BLOB,
                        quality: 1,
                        enableDownload: false,
                    },
                },
            })
                .then((editor) => {
                    editorRef.current = editor;
                    setEditorReady(true);

                    editor
                        .export()
                        .then((data) => {
                            if (data instanceof Blob) {
                                originalBlobRef.current = data;
                            }
                        })
                        .catch((e) => {
                            alert('Error with export: ' + e + '. Please refresh & try again.');
                            console.log('Export Error', e);
                        });
                })
                .catch((e) => {
                    alert('Error with PhotoEditorSDK: ' + e + '. Please refresh & try again.');
                    console.log('PhotoEditorSDK Init failed', e);
                });
        }

        return () => {
            editorRef.current?.dispose();
            rootRef?.removeChild(elRef);
        };
    }, [classes.canvasOuter, showEditorSDK, formElementFile.url, editName]);

    // Setup mutation observer to watch Scale css changes
    // and update UI Zoom Text
    useEffect(() => {
        if (!viewOnly) {
            return;
        }

        const obs = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                //@ts-ignore
                const xform: string = mutation.target.style.transform;

                const matches = xform.match(/scaleX\(([\d\.]+)\)/);

                if (!matches || !matches[1]) {
                    setZoomScaleDisplay('100%');
                } else {
                    const scale = parseFloat(matches[1]) * 100;
                    setZoomScaleDisplay(Math.round(scale) + '%');
                }
            });
        });

        obs.observe(document.getElementById('viewer')!, {
            subtree: true,
            attributes: true,
        });

        return () => {
            obs.disconnect();
        };
    }, [viewOnly]);

    useEffect(() => {
        if (!editorReady) {
            return;
        }

        editorRef.current?.on(UIEvent.CLOSE, handleCanvasClose);

        return () => {
            editorRef.current?.off(UIEvent.CLOSE, handleCanvasClose);
        };
    }, [editorReady, handleCanvasClose]);

    const update = () => {
        if (!editorRef.current) {
            handleCanvasClose();
            return;
        }
        editorRef.current.export().then(async (strokeBlob) => {
            if (strokeBlob instanceof Blob) {
                originalBlobRef.current = strokeBlob;
                const file = new File([strokeBlob], editName, { type: strokeBlob.type });
                onSave(file);
                handleCanvasClose();
            }
        });
    };

    const activeViewerAsset = useMemo(() => {
        return {
            downloadUrl: formElementFile.url,
            iconName: 'image',
        };
    }, [formElementFile]);

    return ReactDOM.createPortal(
        <>
            <div className={classes.canvasDisplay}>
                {!showEditorSDK ? (
                    <>
                        <div id="viewer" ref={viewerContainerRef} className={classes.viewerWrapper}>
                            {activeViewerAsset && (
                                <div className={classes.viewerScale}>
                                    <Typography color="white">{zoomScaleDisplay}</Typography>
                                </div>
                            )}

                            <div className={classes.viewerClose}>
                                <Icon
                                    name="x-close"
                                    color="white"
                                    size={32}
                                    onPress={() => {
                                        onClose();
                                    }}
                                />
                            </div>
                        </div>

                        {activeViewerAsset && (
                            <>
                                <Viewer
                                    visible
                                    images={viewerData}
                                    container={viewerContainerRef.current as any}
                                    noNavbar
                                    noClose
                                    downloadable={true}
                                    disableMouseZoom
                                    disableKeyboardSupport={!viewOnly}
                                    zoomSpeed={0.1}
                                    attribute={false}
                                    customToolbar={() => {
                                        let actions: any = [
                                            {
                                                key: 'download',
                                                render: <Icon name="download" color="white" />,
                                                onClick: () => {
                                                    downloadImage(formElementFile.url!, formElementFile.name!);
                                                },
                                            },
                                            {
                                                key: 'zoomIn',
                                                actionType: 1,
                                                render: <Icon name="plus" color="white" />,
                                            },
                                            {
                                                key: 'zoomOut',
                                                actionType: 2,
                                                render: <Icon name="minus" color="white" />,
                                            },
                                        ];

                                        return actions;
                                    }}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <div id="editor" className={classes.canvasWrapper}></div>
                )}
            </div>

            <div className={classes.canvasControlsOuter}>
                <div className={classes.canvasControlsHeader}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className={classes.nameWrapper}>
                            <Typography variant="h4">{editName}</Typography>
                        </div>
                        {!viewOnly ? (
                            <Button variant="link" onClick={onClose}>
                                Close
                            </Button>
                        ) : null}
                    </div>

                    {viewOnly && (
                        <div style={{ paddingBottom: 100 }}>
                            <Typography color="textDarkSecondary">{t('modals:assetEditor.noDescription')}</Typography>
                        </div>
                    )}
                </div>

                <div className={classes.canvasControls}>
                    {!viewOnly && (
                        <div className={classes.canvasControlsFormField}>
                            <TextInputHelper
                                required
                                controlId="filename"
                                label={t('qmr:inputs.asset.filename.label', 'Media Name')}
                                value={assetNameInputValue}
                                onChangeText={(newVal) => {
                                    let originalFileExtn =
                                        formElementFile?.name &&
                                        formElementFile?.name.substring(
                                            formElementFile?.name.lastIndexOf('.'),
                                            formElementFile?.name.length
                                        );
                                    let newFileName = newVal.substring(0, newVal.lastIndexOf('.'));
                                    let newFileExtn = newVal.substring(newVal.lastIndexOf('.'), newVal.length);

                                    if (newFileName === '') {
                                        setInValidMediaName(true);
                                        alert('The file name should not be empty');
                                    } else if (originalFileExtn !== newFileExtn || newVal === newFileExtn) {
                                        setInValidMediaName(true);
                                        alert(
                                            'You cannot remove or change the file extension, ' + originalFileExtn + '.'
                                        );
                                        return;
                                    }
                                    setAssetNameInputValue(newVal);
                                    setInValidMediaName(false);
                                }}
                                placeholder={t('qmr:inputs.asset.filename.placeholder', 'Enter name')}
                            />
                        </div>
                    )}
                </div>

                <div className={classes.canvasControlsActions}>
                    {!viewOnly && (
                        <Button variant="primary" disabled={inValidMediaName} onClick={update}>
                            {t('modals:assetEditor.actions.save')}
                        </Button>
                    )}
                </div>
            </div>
        </>,
        assetEditorElementRef.current
    );
};

const useCanvasStyles = createUseStyles({
    canvasOuter: {
        zIndex: 1051,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        display: 'flex',
        '& canvas': {
            display: 'block',
            border: `1px solid ${colors.black}`,
        },
    },
    canvasDisplay: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    canvasWrapper: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    canvasControlsOuter: {
        width: 400,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    canvasControlsHeader: {
        display: 'flex',
        flexDirection: 'column',
        padding: '34px 24px',
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    nameWrapper: {
        flex: 1,
        minWidth: 0,
    },
    canvasControls: {
        padding: 24,
    },
    canvasControlsFormField: {
        paddingBottom: 24,
    },
    canvasControlsActions: {
        marginTop: 'auto',
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: `1px solid ${colors.grayThree}`,
    },
    viewerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        flex: 1,
        '& .react-viewer-footer': {
            top: 0,
            height: 64,
            '& .react-viewer-btn': {
                backgroundColor: 'transparent',
                position: 'absolute',
                height: 24,
                width: 24,
            },
            '& .react-viewer-btn[data-key=zoomIn], .react-viewer-btn[data-key=zoomOut]': {
                top: 30,
            },
            '& .react-viewer-btn[data-key=zoomOut]': {
                left: '45%',
            },
            '& .react-viewer-btn[data-key=zoomIn]': {
                right: '45%',
            },
            '& .react-viewer-btn[data-key=download]': {
                top: 28,
                right: 42,
            },
        },
    },
    viewerOverrideContainer: {
        zIndex: 1100,
        position: 'absolute',
        alignSelf: 'center',
        marginTop: -64,
    },
    viewerScale: {
        zIndex: 1100,
        position: 'absolute',
        top: 24,
        paddingTop: 5,
    },
    viewerClose: {
        zIndex: 1100,
        position: 'absolute',
        top: 24,
        left: 30,
    },
    fileIconContainer: {
        zIndex: 1100,
        position: 'absolute',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
});
