import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Icon, TextBadge, Typography } from '@packages/ui/shared';
import { TableCell, TableCheckbox, TableRow } from '@web/components/table';

import { FailCode } from '@packages/models/api';

interface FailCodeTableProps {
    rowId: string;
    failCode: FailCode;
    checked: boolean;
    onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): void;
    listQueryParams?: string;
}

export const FailCodeTableRow: FC<FailCodeTableProps> = React.memo(
    ({ rowId, failCode, checked, onCheckboxChange, listQueryParams }) => {
        return (
            <TableRow checked={checked}>
                <TableCell fixed classNameOuter="align-middle">
                    <TableCheckbox value={rowId} checked={checked} onChange={onCheckboxChange} />
                </TableCell>
                <TableCell>
                    <Typography numberOfLines={1}>{failCode.failCode}</Typography>
                </TableCell>
                <TableCell>
                    <Typography numberOfLines={1}>{failCode.description}</Typography>
                </TableCell>
            </TableRow>
        );
    }
);
