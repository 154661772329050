import * as React from 'react';
import { VinSessionContext, VinSessionDispatchContext } from './vin-sessions.context';

export const useVinSessionState = () => {
    const vinSessionState = React.useContext(VinSessionContext);

    if (vinSessionState === undefined) {
        throw new Error('VinSessionContext must be used within an VinSessionProvider');
    }

    return vinSessionState;
};

export const useVinSessionDispatch = () => {
    const vinSessionDispatch = React.useContext(VinSessionDispatchContext);

    if (vinSessionDispatch === undefined) {
        throw new Error('VinSessionDispatchContext must be used within an VinSessionProvider');
    }

    return vinSessionDispatch;
};
