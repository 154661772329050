import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useQmrState } from '@packages/contexts/qmrs';
import { SuccessFailBadge, Typography } from '@packages/ui/shared';

const ConcernSubmitted: FC = () => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();

    if (!qmr) {
        return null;
    }

    return (
        <>
            <Typography variant="h4">{t('qmr:sections.concern.title', 'Concern')}</Typography>

            <div className="my-4">
                <Typography>{qmr.complaint}</Typography>
            </div>

            <div className="d-inline-block">
                <SuccessFailBadge success={qmr.complaintDuplicated}>
                    {qmr.complaintDuplicated
                        ? t('qmr:report.concern.duplicated', 'Concern was duplicated')
                        : t('qmr:report.concern.notDuplicated', 'Concern was not duplicated')}
                </SuccessFailBadge>
            </div>
        </>
    );
};

export default ConcernSubmitted;
