import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';

import { scButton, scCard, scForm, scModal, scDropdown } from '@web/jss/theme';

const iterations: number = 40;

const m: Record<string, Record<string, string>> = {};
const ml: Record<string, Record<string, string>> = {};
const mr: Record<string, Record<string, string>> = {};
const mt: Record<string, Record<string, string>> = {};
const mb: Record<string, Record<string, string>> = {};
const mx: Record<string, Record<string, string>> = {};
const my: Record<string, Record<string, string>> = {};

const p: Record<string, Record<string, string>> = {};
const pl: Record<string, Record<string, string>> = {};
const pr: Record<string, Record<string, string>> = {};
const pt: Record<string, Record<string, string>> = {};
const pb: Record<string, Record<string, string>> = {};
const px: Record<string, Record<string, string>> = {};
const py: Record<string, Record<string, string>> = {};

for (let i: number = 0; i <= iterations; i++) {
    m[`.m-${i}`] = { margin: `${4 * i}px !important` };
    ml[`.ml-${i}`] = { marginLeft: `${4 * i}px !important` };
    mr[`.mr-${i}`] = { marginRight: `${4 * i}px !important` };
    mt[`.mt-${i}`] = { marginTop: `${4 * i}px !important` };
    mb[`.mb-${i}`] = { marginBottom: `${4 * i}px !important` };
    mx[`.mx-${i}`] = { marginLeft: `${4 * i}px !important`, marginRight: `${4 * i}px !important` };
    my[`.my-${i}`] = { marginTop: `${4 * i}px !important`, marginBottom: `${4 * i}px !important` };

    p[`.p-${i}`] = { padding: `${4 * i}px !important` };
    pl[`.pl-${i}`] = { paddingLeft: `${4 * i}px !important` };
    pr[`.pr-${i}`] = { paddingRight: `${4 * i}px !important` };
    pt[`.pt-${i}`] = { paddingTop: `${4 * i}px !important` };
    pb[`.pb-${i}`] = { paddingBottom: `${4 * i}px !important` };
    px[`.px-${i}`] = { paddingLeft: `${4 * i}px !important`, paddingRight: `${4 * i}px !important` };
    py[`.py-${i}`] = { paddingTop: `${4 * i}px !important`, paddingBottom: `${4 * i}px !important` };
}

const globalStyles = {
    '@global': {
        /* ----------------------------------------------------------- */
        /* Generics */
        /* ----------------------------------------------------------- */
        html: {
            height: '100%',
            fontSize: '10px',
        },
        body: {
            ...fonts.s,
            color: colors.black,
            minHeight: '100%',
            position: 'relative',
            overflow: 'hidden',
            ...fonts.robotoRegular,
        },
        hr: {
            margin: 0,
            borderTop: `1px solid ${colors.grayThree}`,
        },

        /* ----------------------------------------------------------- */
        /* Headings and text */
        /* ----------------------------------------------------------- */
        h1: {
            ...fonts.xxl,
            ...fonts.robotoRegular,
        },
        h2: {
            ...fonts.xl,
            ...fonts.robotoRegular,
        },
        h3: {
            ...fonts.l,
            ...fonts.robotoRegular,
        },
        h4: {
            ...fonts.m,
            ...fonts.robotoBold,
        },
        h5: {
            ...fonts.s,
            ...fonts.robotoMedium,
        },
        h6: {
            ...fonts.xs,
            ...fonts.robotoMedium,
        },
        p: {
            ...fonts.s,
            ...fonts.robotoRegular,
        },
        small: {
            ...fonts.xs,
            display: 'block',
            ...fonts.robotoRegular,
        },
        caption: {
            ...fonts.xxs,
            display: 'block',
            ...fonts.robotoRegular,
        },
        '.text-muted': {
            color: `${colors.graySix} !important`,
        },
        ul: {
            paddingLeft: 16,
        },
        '.regular': {
            ...fonts.robotoRegular,
        },
        '.medium': {
            ...fonts.robotoMedium,
        },
        '.bold': {
            ...fonts.robotoBold,
        },

        /* ----------------------------------------------------------- */
        /* Spacers */
        /* ----------------------------------------------------------- */
        ...m,
        ...ml,
        ...mr,
        ...mt,
        ...mb,
        ...p,
        ...pl,
        ...pr,
        ...pt,
        ...pb,

        /* ----------------------------------------------------------- */
        /* Theme spreads */
        /* ----------------------------------------------------------- */
        ...scButton,
        ...scCard,
        ...scForm,
        ...scModal,
        ...scDropdown,
        '.nav.nav-tabs': {
            overflowY: 'auto',
            flexWrap: 'nowrap',
            borderBottom: `2px solid ${colors.grayThree}`,
            '& .nav-item.nav-link': {
                border: 0,
                ...fonts.s,
                borderRadius: 0,
                padding: '8px 0',
                color: colors.black,
                margin: '0 8px -2px',
                '&.active': {
                    ...fonts.robotoBold,
                    backgroundColor: 'transparent',
                    borderBottom: `2px solid ${colors.blueOne}`,
                },
                '&:first-child': {
                    marginLeft: 0,
                },
                '&:last-child': {
                    marginRight: 0,
                },
            },
        },
        '.css-view-1dbjc4n': {
            zIndex: 'auto',
        },
        '[data-testid="show"]': {
            zIndex: '1 !important',
        },
        '.tab-content .tab-pane': {
            paddingTop: 16,
        },
        summary: { listStyle: 'none' },
        'summary::-webkit-details-marker': { display: 'none' },
    },
};

export default globalStyles;
