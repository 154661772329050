import React, { useCallback } from 'react';
import { CaseType, WorksheetAnswerStatus, WorksheetSubmissions } from '@packages/models/api';
import { Dictionary } from 'lodash';

const useWorksheetManage = () => {
    const groupOfCaseTypeIds = useCallback(
        ({
            worksheetsWiseCaseType,
            selectedCaseTypes,
        }: {
            worksheetsWiseCaseType: Dictionary<WorksheetSubmissions[]>;
            selectedCaseTypes: CaseType[];
        }) => {
            return selectedCaseTypes.reduce(
                (
                    _array: { [WorksheetAnswerStatus.Submitted]: string[]; [WorksheetAnswerStatus.Draft]: string[] },
                    type
                ) => {
                    if (worksheetsWiseCaseType[type.northstarId]?.length) {
                        const worksheets = worksheetsWiseCaseType[type.northstarId];
                        const hasSubmitted = worksheets.find(
                            (worksheet) => worksheet.status === WorksheetAnswerStatus.Submitted
                        );
                        const hasDraft = worksheets.find(
                            (worksheet) => worksheet.status === WorksheetAnswerStatus.Draft
                        );

                        if (hasSubmitted) {
                            _array[WorksheetAnswerStatus.Submitted].push(type.caseTypeId);
                        }
                        if (hasDraft) {
                            _array[WorksheetAnswerStatus.Draft].push(type.caseTypeId);
                        }
                    }
                    return _array;
                },
                { [WorksheetAnswerStatus.Submitted]: [], [WorksheetAnswerStatus.Draft]: [] }
            );
        },
        []
    );

    return {
        groupOfCaseTypeIds,
    };
};

export default useWorksheetManage;
