import React, { FC, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SearchProps } from '@web/techline/components/fail-codes-modal';
import { Label, Button, LoadingCircle } from '@packages/ui/shared';

interface FailCodeSearchModalProps {
    showMatchingFailCodes?: boolean;
    onHide(): void;
    handleSetContent: (props: SearchProps) => void;
    returnToHome: () => void;
    isLoading?: boolean;
}

export const FailCodeStartModalTechline: FC<FailCodeSearchModalProps> = (props) => {
    const { t } = useTranslation();

    const [viewMatching, setViewMatching] = useState<boolean>(Boolean(props.showMatchingFailCodes));
    const [searchFailCode, setSearchFailCode] = useState<boolean>(false);
    const [searchFailCodeByPart, setSearchFailCodeByPart] = useState<boolean>(false);
    const [lookBySection, setLookupBySection] = useState<boolean>(false);
    const [lookupByElectricalGridLocation, setLookupByElectricalGridLocation] = useState<boolean>(false);
    const [noTroubleFound, setNoTroubleFound] = useState<boolean>(false);

    const resetAll = () => {
        setViewMatching(false);
        setSearchFailCode(false);
        setSearchFailCodeByPart(false);
        setLookupBySection(false);
        setLookupByElectricalGridLocation(false);
        setNoTroubleFound(false);
    };

    const handleSearchFailCode = () => {
        resetAll();
        setSearchFailCode(true);
    };

    const handleSearchFailCodeByPart = () => {
        resetAll();
        setSearchFailCodeByPart(true);
    };

    const handleLookupBySection = () => {
        resetAll();
        setLookupBySection(true);
    };

    const handleLookupByElectricalGridLocation = () => {
        resetAll();
        setLookupByElectricalGridLocation(true);
    };

    const handleNoTroubleFound = () => {
        resetAll();
        setNoTroubleFound(true);
    };

    const handleViewMatching = () => {
        resetAll();
        setViewMatching(true);
    };

    const handleSetContent = () => {
        props.handleSetContent({
            searchFailCode,
            searchFailCodeByPart,
            lookBySection,
            lookupByElectricalGridLocation,
            noTroubleFound,
            viewMatching,
        });
    };

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>{t('qmr:sections:failCode.failCodeSearch')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group>
                    <div className="mb-6">
                        <Label>{t('qmr:sections:failCode.selectOptions')}.</Label>
                    </div>

                    {props.isLoading ? (
                        <LoadingCircle />
                    ) : (
                        <>
                            {props.showMatchingFailCodes && (
                                <Form.Check
                                    className="mb-3"
                                    label={t('qmr:sections:failCode.viewMatchingFailCodes')}
                                    type="radio"
                                    id="viewMatchingFailCodes"
                                    name="failCodeSearch"
                                    checked={viewMatching}
                                    onChange={handleViewMatching}
                                />
                            )}
                        </>
                    )}

                    <Form.Check
                        className="mb-3"
                        label={t('qmr:sections:failCode.searchFailCode')}
                        type="radio"
                        id="searchFailCode"
                        name="searchFailCode"
                        checked={searchFailCode}
                        onChange={handleSearchFailCode}
                    />

                    <Form.Check
                        className="mb-3"
                        label={t('qmr:sections:failCode.searchFailCodeByPart')}
                        type="radio"
                        id="searchFailCodeByPart"
                        name="searchFailCodeByPart"
                        checked={searchFailCodeByPart}
                        onChange={handleSearchFailCodeByPart}
                    />

                    <Form.Check
                        className="mb-3"
                        label={t('qmr:sections:failCode.lookupBySection')}
                        type="radio"
                        id="lookupBySection"
                        name="lookupBySection"
                        checked={lookBySection}
                        onChange={handleLookupBySection}
                    />

                    <Form.Check
                        className="mb-3"
                        label={'Lookup by electrical grid location'}
                        type="radio"
                        id="lookupByElectricalGridSection"
                        name="lookupByElectricalGridSection"
                        checked={lookupByElectricalGridLocation}
                        onChange={handleLookupByElectricalGridLocation}
                    />

                    {/* <Form.Check
                        className="mb-3"
                        label={t('qmr:sections:failCode.noTroubleFound')}
                        type="radio"
                        id="noTroubleFound"
                        name="noTroubleFound"
                        checked={noTroubleFound}
                        onChange={handleNoTroubleFound}
                    /> */}
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    disabled={
                        !searchFailCode &&
                        !searchFailCodeByPart &&
                        !lookBySection &&
                        !lookupByElectricalGridLocation &&
                        !noTroubleFound &&
                        !viewMatching
                    }
                    onPress={handleSetContent}
                    variant="primary"
                >
                    {t('qmr:sections:failCode.continue')}
                </Button>
            </Modal.Footer>
        </>
    );
};
