import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const en = {
    buttons: require('../../locales/en/buttons.json'),
    comment: require('../../locales/en/comment.json'),
    modals: require('../../locales/en/modals.json'),
    nav: require('../../locales/en/nav.json'),
    qmr: require('../../locales/en/qmr.json'),
    qmrActivity: require('../../locales/en/qmrActivity.json'),
    text: require('../../locales/en/text.json'),
    views: require('../../locales/en/views.json'),
    worksheet: require('../../locales/en/worksheet.json'),
};

const ja = {
    buttons: require('../../locales/ja/buttons.json'),
    comment: require('../../locales/ja/comment.json'),
    modals: require('../../locales/ja/modals.json'),
    nav: require('../../locales/ja/nav.json'),
    qmr: require('../../locales/ja/qmr.json'),
    qmrActivity: require('../../locales/ja/qmrActivity.json'),
    text: require('../../locales/ja/text.json'),
    views: require('../../locales/ja/views.json'),
    worksheet: require('../../locales/ja/worksheet.json'),
};

export function initI18n() {
    // passes i18n down to react-i18next -- for useTranslation consumers
    i18n.use(initReactI18next)
        // TODO: eventually drive from build-config
        // and/or dynamically load languages/xlations from backend
        .init({
            resources: {
                en,
                ja,
            },
            lng: 'en',
            fallbackLng: 'en',
            ns: ['buttons', 'comment', 'modals', 'nav', 'qmr', 'qmrActivity', 'text', 'views', 'worksheet'],
            interpolation: {
                escapeValue: false,
            },
        });
}
