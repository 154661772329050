import React from 'react';
import { SuccessFailBadge, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    container: {
        borderTop: `1px solid ${colors.grayFour}`,
    },
    flexOne: {
        flex: 1,
    },
});

export const CaseDetails = ({ caseDetails }: { caseDetails: any }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.container}`}>
            <div className="d-flex flex-column mt-5">
                <Typography variant="h3">Case details & Vehicle concern</Typography>
                <div className="mt-3 d-flex flex-row">
                    <div className={`d-flex flex-column ${classes.flexOne} mr-10`}>
                        <Typography variant="h6" color="black">
                            Repair order number
                        </Typography>
                        <div className="mt-1">
                            <Typography color="black">{caseDetails.repairOrderNumber || '--'}</Typography>
                        </div>
                    </div>
                    <div className={`d-flex flex-column ${classes.flexOne}`}>
                        <Typography variant="h6" color="black">
                            Repair Order Date
                        </Typography>
                        <div className="mt-1">
                            <Typography color="black">{caseDetails.repairOrderOpenDateDescription || '--'}</Typography>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column mt-3">
                    <Typography variant="h6" color="black">
                        Mileage
                    </Typography>
                    <div className="mt-1">
                        <Typography color="black">{caseDetails.mileage || '--'}</Typography>
                    </div>
                </div>
                <div className="mt-3">
                    <Typography variant="h6" color="black">
                        Customer's concern description
                    </Typography>
                    <div className="mt-1">
                        <Typography color="black">{caseDetails.customerConcern || '--'}</Typography>
                    </div>
                </div>
                <div className="mt-3">
                    <Typography variant="h6" color="black">
                        Technician's condition description
                    </Typography>
                    <div className="mt-1">
                        <Typography color="black">{caseDetails.technicianDescription || '--'}</Typography>
                    </div>
                </div>
                <div className="mt-3 d-flex">
                    <Typography color="black">
                        Have you been able to duplicate the concern?{' '}
                        {(
                            caseDetails.hasBeenAbleToDuplicate
                                ? JSON.parse(caseDetails.hasBeenAbleToDuplicate)
                                : caseDetails.hasBeenAbleToDuplicate
                        )
                            ? 'Yes'
                            : 'No'}
                    </Typography>
                </div>
                {(caseDetails.hasBeenAbleToDuplicate
                    ? JSON.parse(caseDetails.hasBeenAbleToDuplicate)
                    : caseDetails.hasBeenAbleToDuplicate) && (
                    <div className="mt-3">
                        <Typography variant="h6" color="black">
                            Steps completed to duplicate
                        </Typography>
                        <div className="mt-1">
                            <Typography color="black">
                                {caseDetails.technician5StepsBeforeFailureDuplication || '--'}
                            </Typography>
                        </div>
                    </div>
                )}
                <div className="mt-3 d-flex">
                    <Typography color="black">
                        Has the vehicle been in for this concern before?{' '}
                        {(
                            caseDetails.vehicleHasBeenInBefore
                                ? JSON.parse(caseDetails.vehicleHasBeenInBefore)
                                : caseDetails.vehicleHasBeenInBefore
                        )
                            ? 'Yes'
                            : 'No'}
                    </Typography>
                </div>
                {(caseDetails.vehicleHasBeenInBefore
                    ? JSON.parse(caseDetails.vehicleHasBeenInBefore)
                    : caseDetails.vehicleHasBeenInBefore) && (
                    <>
                        <div className="mt-3 d-flex flex-row">
                            <div className={`d-flex flex-column ${classes.flexOne}`}>
                                <Typography variant="h6" color="black">
                                    Previous repair order number
                                </Typography>
                                <div className="mt-1">
                                    <Typography color="black">{caseDetails.previousRONumber || '--'}</Typography>
                                </div>
                            </div>
                            <div className={`d-flex flex-column ${classes.flexOne}`}>
                                <Typography variant="h6" color="black">
                                    Previous mileage
                                </Typography>
                                <div className="mt-1">
                                    <Typography color="black">{caseDetails.previousMileage || '--'}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 d-flex">
                            {(
                                caseDetails.werePartsReplaced
                                    ? JSON.parse(caseDetails.werePartsReplaced)
                                    : caseDetails.werePartsReplaced
                            ) ? (
                                <SuccessFailBadge success={true}>Parts were replaced</SuccessFailBadge>
                            ) : (
                                <SuccessFailBadge success={false}>Parts were not replaced</SuccessFailBadge>
                            )}
                        </div>
                        {(caseDetails.werePartsReplaced
                            ? JSON.parse(caseDetails.werePartsReplaced)
                            : caseDetails.werePartsReplaced) && (
                            <>
                                <div className="mt-3">
                                    <Typography variant="h6" color="black">
                                        Parts replaced
                                    </Typography>
                                    <div className="mt-1">
                                        <Typography color="black">
                                            {caseDetails.whatPartsWereReplaced || '--'}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <Typography variant="h6" color="black">
                                        Previous Diagnosis and Repair
                                    </Typography>
                                    <div className="mt-1">
                                        <Typography color="black">
                                            {caseDetails.previousDiagnosisAndRepairs || '--'}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <Typography variant="h6" color="black">
                                        How did the condition improve or change
                                    </Typography>
                                    <div className="mt-1">
                                        <Typography color="black">
                                            {caseDetails.didTheConditionImprove || '--'}
                                        </Typography>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* <div className="mt-3 d-flex">
                            {(
                                caseDetails.rolloverFireAccidentInjuryDeathDamage
                                    ? JSON.parse(caseDetails.rolloverFireAccidentInjuryDeathDamage)
                                    : caseDetails.rolloverFireAccidentInjuryDeathDamage
                            ) ? (
                                <SuccessFailBadge success={true}>
                                    Customer report any of the following: Rollover, Fire, Accident, Injury, Death,
                                    Property Damage or airbag rupture.
                                </SuccessFailBadge>
                            ) : (
                                <SuccessFailBadge success={false}>
                                    Customer did not report any of the following: Rollover, Fire, Accident, Injury,
                                    Death, Property Damage or airbag rupture.
                                </SuccessFailBadge>
                            )}
                        </div> */}
                        {(caseDetails.rolloverFireAccidentInjuryDeathDamage
                            ? JSON.parse(caseDetails.rolloverFireAccidentInjuryDeathDamage)
                            : caseDetails.rolloverFireAccidentInjuryDeathDamage) && (
                            <div className="mt-3">
                                <Typography variant="h6" color="black">
                                    Which one?
                                </Typography>
                                <div className="mt-1">
                                    {(caseDetails.rolloverFlag
                                        ? JSON.parse(caseDetails.rolloverFlag)
                                        : caseDetails.rolloverFlag) && <Typography color="black">Rollover</Typography>}
                                    {(caseDetails.fireFlag
                                        ? JSON.parse(caseDetails.fireFlag)
                                        : caseDetails.fireFlag) && (
                                        <div className="mt-1">
                                            <Typography color="black">fire</Typography>
                                        </div>
                                    )}
                                    {(caseDetails.accidentFlag
                                        ? JSON.parse(caseDetails.accidentFlag)
                                        : caseDetails.accidentFlag) && (
                                        <div className="mt-1">
                                            <Typography color="black">accident</Typography>
                                        </div>
                                    )}
                                    {(caseDetails.injuryFlag
                                        ? JSON.parse(caseDetails.injuryFlag)
                                        : caseDetails.injuryFlag) && (
                                        <div className="mt-1">
                                            <Typography color="black">
                                                injury ({caseDetails.injuryNumberOfPeople || '--'})
                                            </Typography>
                                        </div>
                                    )}
                                    {(caseDetails.deathFlag
                                        ? JSON.parse(caseDetails.deathFlag)
                                        : caseDetails.deathFlag) && (
                                        <div className="mt-1">
                                            <Typography color="black">
                                                death ({caseDetails.deathNumberOfPeople || '--'})
                                            </Typography>
                                        </div>
                                    )}
                                    {(caseDetails.propertyDamage
                                        ? JSON.parse(caseDetails.propertyDamage)
                                        : caseDetails.propertyDamage) && (
                                        <div className="mt-1">
                                            <Typography color="black">property damage</Typography>
                                        </div>
                                    )}
                                    {(caseDetails.airbagRuptureFlag
                                        ? JSON.parse(caseDetails.airbagRuptureFlag)
                                        : caseDetails.airbagRuptureFlag) && (
                                        <div className="mt-1">
                                            <Typography color="black">Airbag Rupture</Typography>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
