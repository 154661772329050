import * as React from 'react';
import { IHaveYouSeen, IQmrsDashboardAlertLink, ICounterMeasureOfTheMonth, IHelpfulLink } from '@packages/models/api';
import { QmrsDashboardService } from './qmrsdashboard.service';
import {
    IQmrsDashboardProps,
    QmrsDashboardActionDispatcher,
    counterMeasuresOfTheMonthBase,
    haveYouSeensBase,
    qmrsDashboardActions,
    qmrsDashboardAlertLinksBase,
    qmrsDashboardReducer,
} from './qmrsdashboard.state';

export const QmrsDashboardContext = React.createContext({
    qmrsDashboardAlertLinks: qmrsDashboardAlertLinksBase,
    haveYouSeens: haveYouSeensBase,
    counterMeasuresOfTheMonth: counterMeasuresOfTheMonthBase,
} as IQmrsDashboardProps);

export const QmrsDashboardDispatchContext = React.createContext<QmrsDashboardActionDispatcher | undefined>(undefined);

export const QmrsDashboardProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const [state, dispatch] = React.useReducer(qmrsDashboardReducer, {} as any);
    return (
        <QmrsDashboardContext.Provider value={state}>
            <QmrsDashboardDispatchContext.Provider value={dispatch}>{children}</QmrsDashboardDispatchContext.Provider>
        </QmrsDashboardContext.Provider>
    );
};

export async function fetchQmrsDashboardAlertLink({
    id,
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    id?: string;
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    const response = await qmrsDashboardService.fetchAllQmrsDashboardAlertLinks({
        id,
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    const qmrsDashboardResponse = response.data; //to-do api support multiple alert links which one to display?
    const allAlertLinks = qmrsDashboardResponse.alertLinks;
    if (id) {
        const alertLinkToDisplay = allAlertLinks?.filter((al: any) => al.qmrDashAlertLinksId === id);
        if (alertLinkToDisplay && alertLinkToDisplay.length > 0) {
            qmrsDashboardDispatch(
                qmrsDashboardActions.getAlertLinks({
                    qmrsDashboardAlertLinks: [alertLinkToDisplay[0]],
                })
            );

            return alertLinkToDisplay[0];
        }
    } else {
        //TO-DO confirm this logic, display the last one in the list
        if (allAlertLinks && allAlertLinks.length > 0) {
            const alertLinkToDisplay = allAlertLinks[allAlertLinks.length - 1];

            qmrsDashboardDispatch(
                qmrsDashboardActions.getAlertLinks({
                    qmrsDashboardAlertLinks: [alertLinkToDisplay],
                })
            );
            return alertLinkToDisplay;
        }
    }
}

export async function createOrUpdateQmrsDashboadMessage({
    qmrsDashboardAlertLink,
    qmrsDashboardService,
    qmrsDashboardDispatch,
    signal,
}: {
    qmrsDashboardAlertLink: IQmrsDashboardAlertLink;
    qmrsDashboardService: QmrsDashboardService;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
    signal?: AbortSignal;
}) {
    const { qmrDashAlertLinksId } = qmrsDashboardAlertLink;
    if (qmrDashAlertLinksId) {
        //update the qmrsDashboardMessage
        const updateResponse = await qmrsDashboardService.updateQmrsDashboardAlertLink({
            qmrsDashboardAlertLink,
            signal,
        });

        if (!updateResponse.success && updateResponse.aborted) {
            return;
        } else if (!updateResponse.success) {
            return Promise.reject(updateResponse.data);
        }

        const alertLinkUpdateResponse: {
            alertLinks: {
                qmrDashboardAlertLinks: IQmrsDashboardAlertLink[];
            };
        } = updateResponse.data;
        qmrsDashboardDispatch(
            qmrsDashboardActions.updateAlertLink({
                qmrsDashboardAlertLinks: alertLinkUpdateResponse.alertLinks.qmrDashboardAlertLinks,
            })
        );
        //return response.data;
        return qmrsDashboardAlertLink;
    } else {
        const response = await qmrsDashboardService.createQmrsDashboardAlertLink({
            qmrsDashboardAlertLink,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }
        const alertLinkCreateResponse: {
            alertLinks: {
                qmrDashboardAlertLinks: IQmrsDashboardAlertLink[];
            };
        } = response.data;
        //take the latest one
        qmrsDashboardDispatch(
            qmrsDashboardActions.createAlertLink({
                qmrsDashboardAlertLinks: alertLinkCreateResponse.alertLinks.qmrDashboardAlertLinks,
            })
        );
        return alertLinkCreateResponse;
    }
}

export async function deleteDashboardAlertLink({
    id,
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    id: string;
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    const response = await qmrsDashboardService.deleteQmrsDashboardAlertLink({
        id,
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }
    qmrsDashboardDispatch(
        qmrsDashboardActions.deleteAlertLink({
            qmrsDashboardAlertLink: {
                qmrDashAlertLinksId: id,
                title: '',
                description: '',
                link: '',
                deleted: false,
            },
        })
    );
    return id;
}

export async function fetchAllHaveYouSeens({
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    const response = await qmrsDashboardService.fetchAllHaveYouSeen({
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }
    const qmrsHaveYouSeenResposne = response.data;
    const haveYouSeenList = qmrsHaveYouSeenResposne.qmrDashboard.qmrDashboardHaveYouSeen;
    qmrsDashboardDispatch(
        qmrsDashboardActions.getAllHaveYouSeen({
            haveYouSeens: haveYouSeenList,
        })
    );
    return haveYouSeenList;
}

export async function createOrUpdateHaveYouSeen({
    haveYouSeen,
    qmrsDashboardService,
    qmrsDashboardDispatch,
    signal,
}: {
    haveYouSeen: IHaveYouSeen;
    qmrsDashboardService: QmrsDashboardService;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
    signal?: AbortSignal;
}) {
    if (haveYouSeen.qmrDashHaveYouSeenId) {
        const response = await qmrsDashboardService.updateHaveYouSeen({
            haveYouSeen,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }
        const qmrsHaveYouSeenResposne = response.data;
        const haveYouSeenList = qmrsHaveYouSeenResposne.qmrDashboard.qmrDashboardHaveYouSeen;
        qmrsDashboardDispatch(
            qmrsDashboardActions.updateHaveYouSeen({
                haveYouSeens: haveYouSeenList,
            })
        );
        return haveYouSeenList;
    } else {
        const response = await qmrsDashboardService.createHaveYouSeen({
            haveYouSeen,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }
        const qmrsHaveYouSeenResposne = response.data;
        const haveYouSeenList = qmrsHaveYouSeenResposne.qmrDashboard.qmrDashboardHaveYouSeen;
        qmrsDashboardDispatch(
            qmrsDashboardActions.createHaveYouSeen({
                haveYouSeens: haveYouSeenList,
            })
        );
        return haveYouSeenList;
    }
}

export async function deleteHaveYouSeen({
    id,
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    id: string;
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    const response = await qmrsDashboardService.deleteHaveYouSeen({
        id,
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }
    qmrsDashboardDispatch(
        qmrsDashboardActions.deleteHaveYouSeen({
            haveYouSeen: {
                qmrDashHaveYouSeenId: id,
                description: '',
                link: '',
                title: '',
            },
        })
    );
    return id;
}

//countermeasureofthemonth
export async function fetchAllCounterMeasureOfTheMonth({
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    const response = await qmrsDashboardService.fetchAllCounterMeasureOfTheMonth({
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    const counterMesaureOfTheMonthResp = response.data;
    const counterMeasureOfTheList = counterMesaureOfTheMonthResp.qmrDashboard.qmrDashboardCounterMeasure;
    qmrsDashboardDispatch(
        qmrsDashboardActions.getAllCounterMeasureOfMonth({
            counterMeasuresOfTheMonth: counterMeasureOfTheList,
        })
    );
    return counterMeasureOfTheList;
}

export async function createOrUpdateCounterMeasureOfTheMonth({
    counterMeasureOfTheMonth,
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    counterMeasureOfTheMonth: ICounterMeasureOfTheMonth;
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    if (counterMeasureOfTheMonth.qmrDashCounterMeasureId) {
        const response = await qmrsDashboardService.updateCounterMeasureOfTheMonth({
            counterMeasureOfTheMonth,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }
        const counterMeasureOfTheMonthResp = response.data;
        const counterMesaureOfTheMonthList = counterMeasureOfTheMonthResp.qmrDashboard.qmrDashboardCounterMeasure;
        qmrsDashboardDispatch(
            qmrsDashboardActions.updateCounterMeasureOfMonth({
                counterMeasuresOfTheMonth: counterMesaureOfTheMonthList,
            })
        );
        return counterMesaureOfTheMonthList;
    } else {
        const response = await qmrsDashboardService.createCounterMeasureOfMonth({
            counterMeasureOfTheMonth,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }
        const counterMeasureOfTheMonthResp = response.data;
        const counterMesaureOfTheMonthList = counterMeasureOfTheMonthResp.qmrDashboard.qmrDashboardCounterMeasure;
        qmrsDashboardDispatch(
            qmrsDashboardActions.createCounterMeasureOfMonth({
                counterMeasuresOfTheMonth: counterMesaureOfTheMonthList,
            })
        );
        return counterMesaureOfTheMonthList;
    }
}

export async function deleteCounterMeasureOfMonth({
    id,
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    id: string;
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    const response = await qmrsDashboardService.deleteCounterMeasureOfMonth({
        id,
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }
    qmrsDashboardDispatch(
        qmrsDashboardActions.deleteCounterMeasureOfMonth({
            counterMeasureOfTheMonth: {
                qmrDashCounterMeasureId: id,
                description: '',
                link: '',
                title: '',
            },
        })
    );
    return id;
}

export async function fetchAllHelpfulLinks({
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    const response = await qmrsDashboardService.fetchAllHelpfulLinks({
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }
    const helpfulLinksResp = response.data;
    qmrsDashboardDispatch(
        qmrsDashboardActions.getAllHelpfullinks({
            helpfulLinks: helpfulLinksResp?.helpfulLinks,
        })
    );
    return helpfulLinksResp.helpfulLinks;
}

export async function createOrUpdateHelpfulLink({
    helpFulLink,
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    helpFulLink: IHelpfulLink;
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    if (helpFulLink.qmrDashHelpfulLinksId) {
        const response = await qmrsDashboardService.updateHelpfulLink({
            helpFulLink,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }
        const helpfulLinksResp = response.data;
        qmrsDashboardDispatch(
            qmrsDashboardActions.updateHelpfullink({
                helpfulLinks: helpfulLinksResp?.helpfulLinks?.qmrDashboardHelpfulLinks,
            })
        );
        return helpfulLinksResp?.helpfulLinks.qmrDashboardHelpfulLinks;
    } else {
        const response = await qmrsDashboardService.createHelpfulLink({
            helpFulLink,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }
        const helpfulLinksResp = response.data;
        qmrsDashboardDispatch(
            qmrsDashboardActions.createHelpfullink({
                helpfulLinks: helpfulLinksResp?.helpfulLinks?.qmrDashboardHelpfulLinks,
            })
        );
        return helpfulLinksResp?.helpfulLinks.qmrDashboardHelpfulLinks;
    }
}

export async function deleteHelpfulLink({
    id,
    qmrsDashboardDispatch,
    qmrsDashboardService,
    signal,
}: {
    id: string;
    qmrsDashboardService: QmrsDashboardService;
    signal?: AbortSignal;
    qmrsDashboardDispatch: QmrsDashboardActionDispatcher;
}) {
    const response = await qmrsDashboardService.deleteHelpfulLink({
        id,
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    qmrsDashboardDispatch(
        qmrsDashboardActions.deleteHelpfullink({
            helpfulLink: {
                qmrDashHelpfulLinksId: id,
                title: '',
                description: '',
                link: '',
            },
        })
    );
    return id;
}
