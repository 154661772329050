import React, { FC } from 'react';
import colors from '@packages/core/styles/colors';
import constants from '@web/config/constants';
import { BmisAsideContent } from '@web/bmis/components/bmis-aside-content';
import { BmisHeader } from '@web/bmis/components/bmis-header';
import { BmisReportSections } from '@web/bmis/components/bmis-report-sections';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { accountsService } from '@web/services/singletons';

interface BmisLayoutStyleProps {
    children?: React.ReactNode;
    hideAsideContent: boolean;
    hideBmisHeader: boolean;
    hideReportsSection: boolean;
}

const useBmisLayoutStyles = createUseStyles({
    bmisHeader: ({ hideBmisHeader }: BmisLayoutStyleProps) => ({
        top: constants.headerHeight,
        right: 0,
        left: 0,
        alignItems: 'center',
        borderBottom: `1px solid ${colors.grayThree}`,
        display: hideBmisHeader ? 'none' : 'flex',
        height: constants.bmisHeaderHeight,
        justifyContent: 'space-between',
        padding: '0 24px',
        position: 'fixed',
    }),
    asideContent: ({ hideAsideContent, hideBmisHeader }: BmisLayoutStyleProps) => ({
        top: constants.headerHeight + (hideBmisHeader ? 0 : constants.bmisHeaderHeight),
        right: 0,
        bottom: 0,
        backgroundColor: colors.grayOne,
        borderLeft: `1px solid ${colors.grayThree}`,
        overflowY: hideAsideContent ? 'hidden' : 'auto',
        paddingTop: 16,
        position: 'fixed',
        width: hideAsideContent ? 0 : 400,
    }),
    details: ({ hideAsideContent, hideBmisHeader }: BmisLayoutStyleProps) => ({
        top: constants.headerHeight + (hideBmisHeader ? 0 : constants.bmisHeaderHeight),
        right: hideAsideContent ? 0 : 400,
        left: 248,
        bottom: 0,
        backgroundColor: colors.white,
        overflowY: 'auto',
        position: 'fixed',
    }),
    reportSections: ({ hideBmisHeader, hideReportsSection }: BmisLayoutStyleProps) => ({
        top: constants.headerHeight + (hideBmisHeader ? 0 : constants.bmisHeaderHeight),
        bottom: 0,
        left: 0,
        backgroundColor: colors.grayOne,
        borderRight: `1px solid ${colors.grayThree}`,
        overflowY: 'auto',
        position: 'fixed',
        width: hideReportsSection ? 0 : 248,
    }),
    '@media only screen and (max-width: 1100px)': {
        details: {
            left: '0px !important',
        },
        reportSections: {
            display: 'none',
        },
    },
});

interface BmisLayoutProps {
    children?: React.ReactNode;
}

export const BmisLayout: FC<BmisLayoutProps> = ({ ...props }) => {
    const { rowGuid } = useParams<{ rowGuid?: string }>();
    const classes = useBmisLayoutStyles({
        hideBmisHeader: !rowGuid,
        hideAsideContent: !rowGuid,
        hideReportsSection: !rowGuid,
    });
    return (
        <>
            <div className={classes.bmisHeader}>
                <BmisHeader />
            </div>
            <div className={classes.reportSections}>
                <BmisReportSections />
            </div>
            <div className={classes.details}>{props.children}</div>
            <div className={classes.asideContent}>
                <BmisAsideContent accountService={accountsService} />
            </div>
        </>
    );
};
