import { cloneDeep } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from 'react-bootstrap';

import { InvestigationMeetingNoteModel, InvestigationMeetingNoteType, InvestigationModel } from '@packages/models/api';
import { Button, EmptyList, Icon, MeetingNotesPopup } from '@packages/ui/shared';
import { investigationService } from '@web/services/singletons';
import { MeetingNote, MeetingNoteForm, MeetingNoteFormOnSubmitArgument } from '.';

interface InvestigationMeetingTabProps {
    eventKey: string;
    investigation: InvestigationModel;
}

export const InvestigationMeetingTab = ({ eventKey, investigation }: InvestigationMeetingTabProps) => {
    const { t } = useTranslation();

    const [showPopup, setShowPopup] = useState(false);
    const [meetingNotes, setMeetingNotes] = useState<InvestigationMeetingNoteModel[]>([]);
    const [meetingNoteTypeOptions, setMeetingNoteTypeOptions] = useState<InvestigationMeetingNoteType[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleOnEnter = useCallback(async () => {
        try {
            const [meetingNoteTypesResponse, meetingNotesResponse] = await Promise.all([
                investigationService.getMeetingNoteTypes(),
                investigationService.getMeetingNotesForInvestigationId({
                    investigationId: investigation.investigationId,
                }),
            ]);

            if (meetingNoteTypesResponse.success) {
                setMeetingNoteTypeOptions(meetingNoteTypesResponse.data);
            } else {
                throw meetingNoteTypesResponse.data;
            }

            if (meetingNotesResponse.success) {
                setMeetingNotes(meetingNotesResponse.data.meetingNotes);
            } else {
                throw meetingNotesResponse.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }, [investigation.investigationId]);

    const handleAddNoteButtonPress = useCallback(
        async (meetingNoteValues: MeetingNoteFormOnSubmitArgument) => {
            try {
                setIsSubmitting(true);

                const response = await investigationService.postMeetingNote({
                    investigationId: investigation.investigationId,
                    body: {
                        meetingTypeId: meetingNoteValues.meetingNoteTypeValue
                            ? meetingNoteValues.meetingNoteTypeValue
                            : null,
                        meetingDate: meetingNoteValues.meetingNoteDateValue,
                        body: meetingNoteValues.meetingNoteDescriptionValue,
                    },
                });

                if (response.success) {
                    const meetingNotesClone = cloneDeep(meetingNotes);
                    meetingNotesClone.push(response.data.meetingNote);

                    setIsSubmitting(false);
                    setMeetingNotes(meetingNotesClone);
                } else {
                    throw response.data;
                }
            } catch (error) {
                setIsSubmitting(false);
                window.alert(error.message);
            }
        },
        [investigation.investigationId, meetingNotes]
    );

    return (
        <Tab.Pane eventKey={eventKey} onEnter={handleOnEnter}>
            <MeetingNotesPopup
                show={showPopup}
                onOkPress={() => setShowPopup(false)}
                onModalHide={() => setShowPopup(false)}
            />

            {meetingNotes.length === 0 ? (
                <EmptyList
                    iconName="chat-bubble"
                    title={t('investigations:panel.meetingNotes.noData', 'No meeting notes have been added')}
                >
                    <Button
                        variant="ghost-blue"
                        title={t('investigations:panel.meetingNotes.hint', 'What is a meeting note?')}
                        iconLeft={<Icon name="info" color="blueOne" />}
                        onPress={() => setShowPopup(true)}
                    />
                </EmptyList>
            ) : (
                <div className="pt-3 pl-6 pr-6">
                    {meetingNotes.map((meetingNote) => {
                        return (
                            <MeetingNote
                                key={meetingNote.meetingNoteId}
                                meetingNote={meetingNote}
                                meetingNoteTypeOptions={meetingNoteTypeOptions}
                                onMeetingNoteUpdate={(updatedMeetingNote: InvestigationMeetingNoteModel) => {
                                    const meetingNotesClone = cloneDeep(meetingNotes);
                                    const meetingNoteIndexToUpdate = meetingNotesClone.findIndex((mn) => {
                                        return mn.meetingNoteId === meetingNote.meetingNoteId;
                                    });

                                    meetingNotesClone[meetingNoteIndexToUpdate] = updatedMeetingNote;
                                    setMeetingNotes(meetingNotesClone);
                                }}
                            />
                        );
                    })}
                </div>
            )}

            <hr />

            <div className="p-6">
                <MeetingNoteForm
                    meetingNoteTypeOptions={meetingNoteTypeOptions}
                    submitButtonTitle={t(
                        'investigations:panel.meetingNotes.buttons.addMeetingNote.title',
                        'Add meeting note'
                    )}
                    isSubmitting={isSubmitting}
                    onSubmitButtonPress={handleAddNoteButtonPress}
                />
                <hr />
            </div>
        </Tab.Pane>
    );
};
