import React, { FC, useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, FormControl } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Icon } from '@packages/ui/shared';

const useConfirmDeathEwdModalStyles = createUseStyles({
    confirmDeathEwdModal: {
        width: 552,
        maxWidth: 'none',
    },
});

interface ConfirmDeathEwdModalProps {
    show: boolean;
    onHide(): void;
    onConfirmButtonClick(numberOfPeopleKilled: number): void;
    countInputValue: string;
}

const ConfirmDeathEwdModal: FC<ConfirmDeathEwdModalProps> = (props) => {
    const classes = useConfirmDeathEwdModalStyles();
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const [numberOfPeopleKilledInputValue, setNumberOfPeopleKilledInputValue] = useState<string>(props.countInputValue);

    function handleModalOnHide() {
        props.onHide();
    }

    function handleConfirmButtonClick() {
        props.onConfirmButtonClick(parseInt(numberOfPeopleKilledInputValue));
    }

    function handleNumberOfPeopleKilledInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        setNumberOfPeopleKilledInputValue(event.target.value);
    }

    useEffect(() => {
        if (!props.show) {
            return;
        }

        setNumberOfPeopleKilledInputValue(props.countInputValue);
    }, [props.show, props.countInputValue]);

    useEffect(() => {
        if (!props.show) {
            return;
        }

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [props.show]);

    return (
        <Modal show={props.show} dialogClassName={classes.confirmDeathEwdModal} onHide={handleModalOnHide} centered>
            <Modal.Header closeButton placeholder={''}>
                <h4 className="mb-0">{t('qmr:popups.confirmDeathEwd.title')}</h4>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center mb-4">
                    <div className="mr-4">
                        <Icon name="warning" />
                    </div>
                    <h4 className="mb-0">{t('qmr:popups.confirmDeathEwd.message')}</h4>
                </div>
                <p className="mb-6">{t('qmr:popups.confirmDeathEwd.description')}</p>
                <Form onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()}>
                    <Form.Group controlId="confirm-injury-ewd__number-of-people-injured">
                        <Form.Label>{t('qmr:inputs.deathCount.label')}</Form.Label>
                        <Form.Control
                            ref={inputRef}
                            type="number"
                            placeholder={t('qmr:inputs.deathCount.placeholder')}
                            value={numberOfPeopleKilledInputValue}
                            onChange={handleNumberOfPeopleKilledInputChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="mr-2" onClick={handleModalOnHide}>
                    {t('qmr:popups.confirmDeathEwd.cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleConfirmButtonClick}
                    disabled={!numberOfPeopleKilledInputValue || parseFloat(numberOfPeopleKilledInputValue) % 1 !== 0}
                >
                    {t('qmr:popups.confirmDeathEwd.confirms')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeathEwdModal;
