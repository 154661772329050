import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FeedbackForm } from '@packages/models/api';
import { Typography, Button, TextBadge } from '@packages/ui/shared';

import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';
import { feedbackAdminService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { useTranslation } from 'react-i18next';

interface FeedbackFormTableModel extends FeedbackForm {
    rowId: string;
}

export const ManageFeedback: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { abortSignalRef } = useAbortController();
    const [isLoadingForms, setIsLoadingForms] = useState(false);
    const [feedbackForms, setFeedbackForms] = useState<FeedbackFormTableModel[]>([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);

    const fetchFeedbackForms = useCallback(async () => {
        setIsLoadingForms(true);

        const response = await feedbackAdminService.fetchFeedbackForms({
            page,
            size,
            ignoreCache: true,
            signal: abortSignalRef.current,
        });

        setIsLoadingForms(false);

        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            alert(response.data.message);
            return;
        }

        setFeedbackForms(response.data.forms.map((f) => ({ ...f, rowId: f.feedbackFormId })));
        setTotal(response.data.totalCount);
    }, [abortSignalRef, page, size]);

    useEffect(() => {
        fetchFeedbackForms();
    }, [fetchFeedbackForms]);

    function handleNewFormButtonPress() {
        navigate('/administration/feedback/new');
    }

    return (
        <div className="pt-7 pl-7 pr-7 d-flex h-100">
            <div className="d-flex w-100 flex-column">
                <div className="mb-6 d-flex justify-content-between">
                    <Typography variant="h2">{t('feedback:adminFormList.title', 'All Feedback Forms')}</Typography>
                    <Button onPress={handleNewFormButtonPress}>
                        {t('feedback:adminFormList.actions.create', 'New Feedback Form')}
                    </Button>
                </div>

                <TableRenderer<FeedbackForm>
                    tableRowsData={feedbackForms}
                    tableRowsDataSetter={setFeedbackForms}
                    noResultsIconName="administration"
                    tableHeaderRowRenderer={() => {
                        return (
                            <TableRow>
                                <TableHeader sortable onSort={() => {}}>
                                    <Typography variant="label">
                                        {t('feedback:adminFormList.headers.name', 'Name')}
                                    </Typography>
                                </TableHeader>
                                <TableHeader sortable onSort={() => {}}>
                                    <Typography variant="label">
                                        {t('feedback:adminFormList.headers.type', 'Type')}
                                    </Typography>
                                </TableHeader>
                                <TableHeader sortable onSort={() => {}}>
                                    <Typography variant="label">
                                        {t('feedback:adminFormList.headers.createdAt', 'Created')}
                                    </Typography>
                                </TableHeader>
                                <TableHeader sortable onSort={() => {}} colspan={2}>
                                    <Typography variant="label">
                                        {t('feedback:adminFormList.headers.active', 'Active')}
                                    </Typography>
                                </TableHeader>
                            </TableRow>
                        );
                    }}
                    tableBodyRowRenderer={(rowData) => {
                        return (
                            <TableRow key={rowData.rowId}>
                                <TableCell>
                                    <Typography numberOfLines={1}>
                                        <Link to={`/administration/feedback/${rowData.feedbackFormId}`}>
                                            {rowData.formTitle}
                                        </Link>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography numberOfLines={1}>{rowData.formType}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography numberOfLines={1}>{rowData.createdTimestampForDisplay}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography numberOfLines={1}>
                                        {rowData.active ? (
                                            <TextBadge variant="green">{t('text:common.yes')}</TextBadge>
                                        ) : (
                                            <TextBadge variant="red">{t('text:common.no')}</TextBadge>
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/administration/feedback/${rowData.feedbackFormId}/edit`}>
                                        {t('feedback:adminFormList.actions.edit', 'Edit')}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        );
                    }}
                />

                <TablePagination {...{ page, size, total }} onClick={setPage} onSizeChange={setSize} />
            </div>
        </div>
    );
};
