import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { TextInputHelper, Typography } from '@packages/ui/shared';

import { DraggableFormComponent, FeedbackFormElementOption } from '@packages/models/api';
import Select from '@web/components/select';

enum RATING_FORMAT {
    ONE_TO_FIVE = 'ONE_TO_FIVE',
}

interface FormEditLikertLongTextProps extends DraggableFormComponent {
    onChange({
        formElementLabel,
        options,
    }: {
        formElementLabel?: string;
        options?: FeedbackFormElementOption[] | undefined;
    }): void;
}

export const FormEditLikertLongText: FC<FormEditLikertLongTextProps> = ({ formElementLabel, options, onChange }) => {
    const [ratingOptions, setRatingOptions] = useState<FeedbackFormElementOption[]>();
    const [textareaOption, setTextareaOption] = useState<FeedbackFormElementOption>();
    const [ratingFormatInputValue, seRatingFormatInputValue] = useState(RATING_FORMAT.ONE_TO_FIVE);

    useEffect(() => {
        if (!options) {
            return;
        }

        const optionsClone = cloneDeep(options);
        const textareaOption = optionsClone.pop();

        setRatingOptions(optionsClone);
        setTextareaOption(textareaOption);
    }, [options]);

    function handleLabelChangeText(text: string) {
        onChange({ formElementLabel: text });
    }

    function handleOptionLabelChange(index: number, text: string) {
        if (!ratingOptions) {
            return;
        }

        const ratingOptionsClone = cloneDeep(ratingOptions);
        ratingOptionsClone[index].optionLabel = text;

        if (textareaOption) {
            ratingOptionsClone.push(textareaOption);
        }

        onChange({ options: ratingOptionsClone });
    }

    function handleTextareaLabelChangeText(text: string) {
        if (!textareaOption) {
            return;
        }

        const textareaOptionClone = cloneDeep(textareaOption);
        textareaOptionClone.optionLabel = text;

        const optionsToSend = ratingOptions ? cloneDeep(ratingOptions) : [];
        optionsToSend.push(textareaOptionClone);

        onChange({ options: optionsToSend });
    }

    function handleTextareaPlaceholderChangeText(text: string) {
        if (!textareaOption) {
            return;
        }

        const textareaOptionClone = cloneDeep(textareaOption);
        textareaOptionClone.optionPlaceholder = text;

        const optionsToSend = ratingOptions ? cloneDeep(ratingOptions) : [];
        optionsToSend.push(textareaOptionClone);

        onChange({ options: optionsToSend });
    }

    return (
        <>
            <div className="mb-2">
                <Typography variant="label">Question Text</Typography>
            </div>
            <div className="mb-6">
                <TextInputHelper
                    value={formElementLabel}
                    onChangeText={handleLabelChangeText}
                    placeholder="Enter a label"
                />
            </div>
            <div className="mb-2">
                <Typography variant="label">Rating Format</Typography>
            </div>
            <div className="mb-6">
                <Select
                    value={ratingFormatInputValue}
                    options={[
                        {
                            title: '1 to 5',
                            value: RATING_FORMAT.ONE_TO_FIVE,
                        },
                    ]}
                    onChange={(event) => {
                        seRatingFormatInputValue(event.currentTarget.value as RATING_FORMAT);
                    }}
                />
            </div>
            <div className="mb-2">
                <Typography variant="label">Rating Values</Typography>
            </div>
            <div className="mb-6">
                {ratingFormatInputValue === RATING_FORMAT.ONE_TO_FIVE && (
                    <>
                        {ratingOptions?.map((option, index) => {
                            return (
                                <div key={`${option.optionId}-${index}`} className="d-flex mb-2 align-items-center">
                                    <div className="mr-2">
                                        <Typography>{index + 1}</Typography>
                                    </div>
                                    <TextInputHelper
                                        style={{ flex: 1 }}
                                        value={option.optionLabel}
                                        onChangeText={(text) => {
                                            handleOptionLabelChange(index, text);
                                        }}
                                        placeholder="Enter a label"
                                    />
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
            <div className="mb-2">
                <Typography variant="label">Input Settings</Typography>
            </div>
            <div className="mb-6">
                <TextInputHelper
                    value={textareaOption?.optionLabel}
                    onChangeText={handleTextareaLabelChangeText}
                    placeholder="Enter a label"
                />
            </div>
            <div>
                <TextInputHelper
                    value={textareaOption?.optionPlaceholder}
                    onChangeText={handleTextareaPlaceholderChangeText}
                    placeholder="Enter placeholder text (optional)"
                />
            </div>
        </>
    );
};
