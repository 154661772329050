import { ConditionalElement, DraggableWorksheetFormComponent } from '@packages/models/api';
import { flatMap, groupBy, cloneDeep } from 'lodash';
import { isConditionalElementType } from '../utils';

export const addQuestionNumber = (draggableWorksheetFormComponent: DraggableWorksheetFormComponent[]) => {
    draggableWorksheetFormComponent.forEach((component, index) => {
        component.formElementOrder = index + 1;
        if (component.optionConditionsElements && component.optionConditionsElements.length) {
            let groupConditionalByOptionId = groupBy(component.optionConditionsElements, 'targetOptionId');
            Object.keys(groupConditionalByOptionId).forEach((optionId) => {
                groupConditionalByOptionId[optionId].forEach((element, index) => {
                    element.formElementOrder = index;
                });
            });
            const flattArray = flatMap(groupConditionalByOptionId);
            component.optionConditionsElements = flattArray;
        }
    });

    return draggableWorksheetFormComponent;
};

export const updateDraggableFormComponent = (
    draggableWorksheetFormComponent: DraggableWorksheetFormComponent[],
    componentToUpdate: ConditionalElement | DraggableWorksheetFormComponent
) => {
    //  optionComponents component  or not
    if ('targetOptionId' in componentToUpdate) {
        return updateOptionalConditionComponent(draggableWorksheetFormComponent, componentToUpdate);
    }
    const draggableWorksheetFormComponentClone = cloneDeep(draggableWorksheetFormComponent);
    const itemIndex = draggableWorksheetFormComponentClone.findIndex(
        (component) => component.formElementId === componentToUpdate.formElementId
    );
    if (itemIndex > -1) {
        draggableWorksheetFormComponentClone[itemIndex] = componentToUpdate;
    }
    return draggableWorksheetFormComponentClone;
};

export const updateOptionalConditionComponent = (
    draggableWorksheetFormComponent: DraggableWorksheetFormComponent[],
    updatedComponent: ConditionalElement
) => {
    const draggableWorksheetFormComponentClone = cloneDeep(draggableWorksheetFormComponent);
    draggableWorksheetFormComponentClone.forEach((component) => {
        if (isConditionalElementType(component.formElementTypeId) && component.optionConditionsElements?.length) {
            const index = component.optionConditionsElements.findIndex(
                (element) => element.formElementId === updatedComponent.formElementId
            );
            if (index > -1) {
                component.optionConditionsElements[index] = updatedComponent;
            }
        }
    });
    return draggableWorksheetFormComponentClone;
};

export const deleteDraggableFormComponent = (
    draggableWorksheetFormComponent: DraggableWorksheetFormComponent[],
    componentToDelete: DraggableWorksheetFormComponent | ConditionalElement
) => {
    if ('targetOptionId' in componentToDelete) {
        return deleteOptionalConditionComponent(draggableWorksheetFormComponent, componentToDelete);
    }
    const draggableWorksheetFormComponentClone = cloneDeep(draggableWorksheetFormComponent);
    return draggableWorksheetFormComponentClone.filter(
        (component) => component.formElementId !== componentToDelete.formElementId
    );
};

export const deleteOptionalConditionComponent = (
    draggableWorksheetFormComponent: DraggableWorksheetFormComponent[],
    draggableWorksheetFormComponentToDelete: ConditionalElement
): DraggableWorksheetFormComponent[] => {
    const targetOptionId = draggableWorksheetFormComponentToDelete.targetOptionId;
    const draggableWorksheetFormComponentClone = cloneDeep(draggableWorksheetFormComponent);

    draggableWorksheetFormComponentClone.forEach((component) => {
        if (isConditionalElementType(component.formElementTypeId) && component.optionConditionsElements?.length) {
            const findOptionConIndex = component.optionConditionsElements.findIndex(
                (ele) => ele.formElementId === draggableWorksheetFormComponentToDelete.formElementId
            );

            if (findOptionConIndex > -1) {
                // Delete component in optionConditionsElements
                component.optionConditionsElements.splice(findOptionConIndex, 1);

                // Delete targetElementId in option trgtElementIdList
                const option = component.options.find(
                    (opt) => opt.optionId === targetOptionId && opt.trgtElementIdList?.length
                );
                if (option) {
                    option.trgtElementIdList = option.trgtElementIdList?.filter(
                        (trgtElementId) => trgtElementId !== draggableWorksheetFormComponentToDelete.formElementId
                    );
                }
            }
        }
    });
    return draggableWorksheetFormComponentClone;
};
