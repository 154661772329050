import { useWorksheetState } from '@packages/contexts/worksheets';
import { CaseType, WorksheetForm, WorksheetCaseTypeStatus } from '@packages/models/api/worksheets';
import { worksheetsService } from '@web/services/singletons';
import { useCallback, useEffect, useState } from 'react';
import useTranslationData from '@web/administration/components/worksheet/hooks/useTranslationData';
import useWorksheetPermission from './use-worksheet-permission';
interface State {
    nameErrorMessage: string;
    titleErrorMessage: string;
    caseTypes: CaseType[];
}
interface Props {
    setWorkSheetInformation: (nextState: Partial<WorksheetForm>) => void;
}

const useWorksheetForm = ({ setWorkSheetInformation }: Props) => {
    const [state, _setState] = useState<State>({
        nameErrorMessage: '',
        titleErrorMessage: '',
        caseTypes: [],
    });
    const { validationMessageDataTranslate } = useTranslationData();
    const [isCaseTypeLoading, setIsCaseTypeLoading] = useState(false);
    const worksheetState = useWorksheetState();
    const { worksheet } = worksheetState;
    const errors = {
        title: validationMessageDataTranslate.title,
        name: validationMessageDataTranslate.name,
    };
    const { isPilotUser } = useWorksheetPermission();
    const setState = useCallback((nextState: Partial<State>) => {
        _setState((prevState) => ({ ...prevState, ...nextState }));
    }, []);

    useEffect(() => {
        setIsCaseTypeLoading(true);
        fetchCaseType();
    }, [isPilotUser]);

    const fetchCaseType = useCallback(async () => {
        const caseTypeData = await worksheetsService.fetchCaseType({
            status: WorksheetCaseTypeStatus.Active,
        });
        if (!caseTypeData?.success) {
            setIsCaseTypeLoading(false);
            throw caseTypeData?.data;
        }
        setState({ caseTypes: caseTypeData.data.casetypes });
        if (!worksheet?.caseType) {
            if (caseTypeData) {
                setWorkSheetInformation({
                    caseTypeId: caseTypeData.data.casetypes[0].caseTypeId,
                    northstarId: caseTypeData.data.casetypes[0].northstarId,
                    caseType: caseTypeData.data.casetypes[0].caseType,
                });
            }
        }
        setIsCaseTypeLoading(false);
    }, [setState, setWorkSheetInformation, worksheet?.caseType]);

    const handleTitleChange = useCallback(
        (title: string) => {
            setWorkSheetInformation({ displayname: title });
            if (!title) {
                setState({ titleErrorMessage: errors.title });
                return;
            }
            setState({ titleErrorMessage: '' });
        },
        [errors.title, setState, setWorkSheetInformation]
    );

    const handleNameChange = useCallback(
        (name: string) => {
            setWorkSheetInformation({ worksheetGroupName: name });
            if (!name) {
                setState({ nameErrorMessage: errors.name });
                return;
            }
            setState({ nameErrorMessage: '' });
        },
        [errors.name, setState, setWorkSheetInformation]
    );

    const handleChangeCaseType = useCallback(
        (value: string) => {
            setWorkSheetInformation({ caseTypeId: value });
            const caseType = state.caseTypes.find((caseType) => caseType?.caseTypeId === value);
            if (caseType) {
                setWorkSheetInformation({ northstarId: caseType.northstarId, caseType: caseType.caseType });
            }
        },
        [setWorkSheetInformation, state.caseTypes]
    );

    return {
        state,
        errors,
        setState,
        actions: {
            handleChangeCaseType,
            handleNameChange,
            handleTitleChange,
            isCaseTypeLoading,
        },
    };
};

export type UseWorksheetFormReturnType = ReturnType<typeof useWorksheetForm>;

export default useWorksheetForm;
