import React, { useCallback, useMemo } from 'react';
import { KeyOfFilterParams } from '../utils';
import { useLocation, useNavigate } from 'react-router-dom';

const useFilter = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const hasFilterApplied = useMemo(() => {
        const params = new URLSearchParams(search);
        const isApplied = Object.values(KeyOfFilterParams).some((key) => params.get(key));
        return isApplied;
    }, [search]);

    const clearFilterURLSearchParams = useCallback(() => {
        const params = new URLSearchParams(search);
        for (const key of Object.values(KeyOfFilterParams)) {
            params.delete(key);
        }
        return params;
    }, [search]);

    const clearFilter = useCallback(() => {
        const params = clearFilterURLSearchParams();
        navigate(`${pathname}?${params.toString()}`);
    }, [clearFilterURLSearchParams, navigate, pathname]);

    return {
        clearFilter,
        clearFilterURLSearchParams,
        hasFilterApplied,
    };
};

export default useFilter;
