import * as React from 'react';
import { View, StyleSheet, DimensionValue } from 'react-native';

const techlineWrapperStyle = StyleSheet.create({
    mainViewStyle: {
        paddingLeft: '10px' as DimensionValue,
        paddingRight: '10px' as DimensionValue,
    },
});

export const ContentSettingsTechlineWrapper = () => {
    const [contentSettingMain, setContentSettingsMain] = React.useState<JSX.Element | null>(null);
    React.useEffect(() => {
        const createMain = async () => {
            const { ContentSettingsTechlineMain } = await import('./content-settings-main-techline');
            setContentSettingsMain(<ContentSettingsTechlineMain />);
        };
        if (process.env.NODE_ENV !== 'test') {
            createMain();
        }
    }, []);
    return <View style={techlineWrapperStyle.mainViewStyle}>{contentSettingMain}</View>;
};
