import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { EditorApi, UIEvent, PhotoEditorSDKUI, CanvasAction, Tool, ExportFormat } from 'photoeditorsdk';
import Viewer from 'react-viewer';

import colors from '@packages/core/styles/colors';

import { AssetType, AssetProcessingState, TechlineStatusCode } from '@packages/models/api';
import { Icon, StsIconName, TextInputHelper, Typography } from '@packages/ui/shared';
import LoaderButton from '@web/components/loader-button';
import { Button } from 'react-bootstrap';
import { analyticsService, techlineService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { throttle } from 'lodash';

const useCanvasStyles = createUseStyles({
    canvasOuter: {
        zIndex: 110,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        display: 'flex',
        '& canvas': {
            display: 'block',
            border: `1px solid ${colors.black}`,
        },
    },
    canvasDisplay: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    canvasWrapper: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    canvasControlsOuter: {
        width: 400,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    canvasControlsHeader: {
        display: 'flex',
        flexDirection: 'column',
        padding: '34px 24px',
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    nameWrapper: {
        flex: 1,
        minWidth: 0,
    },
    canvasControls: {
        padding: 24,
    },
    canvasControlsFormField: {
        paddingBottom: 24,
    },
    canvasControlsActions: {
        marginTop: 'auto',
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: `1px solid ${colors.grayThree}`,
    },
    viewerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        flex: 1,
        '& .react-viewer-footer': {
            top: 0,
            height: 64,
            '& .react-viewer-btn': {
                backgroundColor: 'transparent',
                position: 'absolute',
                height: 24,
                width: 24,
            },
            '& .react-viewer-btn[data-key=zoomIn], .react-viewer-btn[data-key=zoomOut]': {
                top: 30,
            },
            '& .react-viewer-btn[data-key=zoomOut]': {
                left: '45%',
            },
            '& .react-viewer-btn[data-key=zoomIn]': {
                right: '45%',
            },
            '& .react-viewer-btn[data-key=download]': {
                top: 28,
                right: 42,
            },
        },
    },
    viewerOverrideContainer: {
        zIndex: 1100,
        position: 'absolute',
        alignSelf: 'center',
        marginTop: -64,
    },
    viewerScale: {
        zIndex: 1100,
        position: 'absolute',
        top: 24,
        paddingTop: 5,
    },
    viewerClose: {
        zIndex: 1100,
        position: 'absolute',
        top: 24,
        left: 30,
    },
    fileIconContainer: {
        zIndex: 1100,
        position: 'absolute',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
});

interface AssetEditorModalProps {
    viewOnly: boolean;
    toggleEdit: () => void;
    onClose: () => void;
    caseDetails: any;
    caseNumber: string;
    fetchAssets: (caseNumber: string) => void;
    selectedAssetDetails?: any;
    setDeleteAsset: (selectedAssetDetails: any) => void;
    inProgressAssets: any;
    setInProgressAssets: any;
}

export const AssetEditorModal = React.memo(
    ({
        viewOnly,
        toggleEdit,
        onClose,
        selectedAssetDetails,
        caseNumber,
        caseDetails,
        fetchAssets,
        setDeleteAsset,
        inProgressAssets,
        setInProgressAssets,
    }: AssetEditorModalProps) => {
        const { t } = useTranslation();
        const assetEditorRootRef = useRef(document.getElementById('asset-editor-root'));
        const assetEditorElementRef = useRef(document.createElement('div'));
        const editorRef = useRef<EditorApi | null>(null);
        const originalBlobRef = useRef<Blob | null>(null);
        const viewerContainerRef = useRef(null);
        const [editorReady, setEditorReady] = useState(false);
        const [viewerData, setViewerData] = useState<any>([]);
        const [zoomScaleDisplay, setZoomScaleDisplay] = useState('100%');
        const classes = useCanvasStyles();

        const showEditorSDK = !viewOnly && selectedAssetDetails.assetTypeId === AssetType.Image;

        const [assetNameInputValue, setAssetNameInputValue] = useState(selectedAssetDetails?.name ?? '');
        const [inValidMediaName, setInValidMediaName] = useState(false);

        const [isSaving, setIsSaving] = useState(false);

        const handleCanvasClose = useCallback(() => {
            editorRef.current?.dispose();
            onClose();
        }, [onClose]);

        const downloadImage = async (imageSrc: string, fileName: string) => {
            const image = await fetch(imageSrc);
            const imageBlog = await image.blob();
            const imageURL = URL.createObjectURL(imageBlog);
            const link = document.createElement('a');
            link.href = imageURL;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        useEffect(() => {
            const downloadUri = selectedAssetDetails?.media?.find(
                (m: { assetImageTypeId: string }) => m.assetImageTypeId === 'ORIGINAL'
            )?.url;
            setViewerData([
                {
                    type: selectedAssetDetails?.assetTypeId,
                    assetProcessingStateId: selectedAssetDetails.assetProcessingStateId,
                    src:
                        selectedAssetDetails.assetTypeId !== AssetType.Image
                            ? ''
                            : downloadUri || selectedAssetDetails.media?.[0]?.url,
                    alt: selectedAssetDetails.name,
                    iconName: selectedAssetDetails.media.FILE && selectedAssetDetails.media.FILE.iconName,
                    // asset.media[0] is original file
                    // asset.media[1] is thumbnail file
                    downloadUrl: downloadUri,
                },
            ]);
        }, [selectedAssetDetails]);

        // Mount the modal as sibling to app
        // worth mentioning, this component/modal renders through a PORTAL
        useEffect(() => {
            const rootRef = assetEditorRootRef.current;
            const elRef = assetEditorElementRef.current;
            elRef.classList.toggle(classes.canvasOuter, true);
            rootRef?.appendChild(assetEditorElementRef.current);
            const originalUri = selectedAssetDetails?.media?.find(
                (m: { assetImageTypeId: string }) => m.assetImageTypeId === 'ORIGINAL'
            )?.url;
            if (showEditorSDK && originalUri) {
                PhotoEditorSDKUI.init({
                    container: '#editor',
                    image: originalUri,
                    license: JSON.stringify(require('@web/pesdk_html5_license.json')),
                    mainCanvasActions: [CanvasAction.UNDO, CanvasAction.REDO],
                    assetBaseUrl: '/photoeditorsdk/assets/',
                    defaultTool: Tool.TRANSFORM,
                    tools: [Tool.TRANSFORM, Tool.ADJUSTMENT, Tool.BRUSH],
                    [Tool.TRANSFORM]: {
                        categories: [
                            {
                                identifier: 'imgly_transforms_common',
                                items: [
                                    { identifier: 'imgly_transform_common_custom' },
                                    { identifier: 'imgly_transform_common_square' },
                                    { identifier: 'imgly_transform_common_4' },
                                    { identifier: 'imgly_transform_common_16' },
                                    { identifier: 'imgly_transform_common_3' },
                                    { identifier: 'imgly_transform_common_9' },
                                ],
                            },
                        ],
                    },
                    order: 'reverse',
                    export: {
                        image: {
                            exportType: ExportFormat.BLOB,
                            quality: 1,
                            enableDownload: false,
                        },
                    },
                })
                    .then((editor) => {
                        editorRef.current = editor;
                        setEditorReady(true);

                        editor
                            .export()
                            .then((data) => {
                                if (data instanceof Blob) {
                                    originalBlobRef.current = data;
                                }
                            })
                            .catch((e) => {
                                alert('Error with export: ' + e + '. Please refresh & try again.');
                                console.log('Export Error', e);
                            });
                    })
                    .catch((e) => {
                        alert('Error with PhotoEditorSDK: ' + e + '. Please refresh & try again.');
                        console.log('PhotoEditorSDK Init failed', e);
                    });
            }

            return () => {
                editorRef.current?.dispose();
                rootRef?.removeChild(elRef);
            };
        }, [selectedAssetDetails.media, classes.canvasOuter, showEditorSDK]);

        // Setup mutation observer to watch Scale css changes
        // and update UI Zoom Text
        useEffect(() => {
            if (!viewOnly) {
                return;
            }

            const obs = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    //@ts-ignore
                    const xform: string = mutation.target.style.transform;

                    const matches = xform.match(/scaleX\(([\d\.]+)\)/);

                    if (!matches || !matches[1]) {
                        setZoomScaleDisplay('100%');
                    } else {
                        const scale = parseFloat(matches[1]) * 100;
                        setZoomScaleDisplay(Math.round(scale) + '%');
                    }
                });
            });

            obs.observe(document.getElementById('viewer')!, {
                subtree: true,
                attributes: true,
            });

            return () => {
                obs.disconnect();
            };
        }, [viewOnly]);

        useEffect(() => {
            if (!editorReady) {
                return;
            }

            editorRef.current?.on(UIEvent.CLOSE, handleCanvasClose);

            return () => {
                editorRef.current?.off(UIEvent.CLOSE, handleCanvasClose);
            };
        }, [editorReady, handleCanvasClose]);

        const activeViewerAsset = {
            assetProcessingStateId: selectedAssetDetails.assetProcessingStateId,
            type: selectedAssetDetails.assetTypeId,
            downloadUrl:
                selectedAssetDetails?.media?.find((m: { assetImageTypeId: string; assetVideoTypeId: string }) =>
                    selectedAssetDetails.assetTypeId === AssetType.Video
                        ? m.assetVideoTypeId === 'ORIGINAL'
                        : m.assetImageTypeId === 'ORIGINAL'
                )?.url || selectedAssetDetails.media?.[0]?.url,
            iconName: selectedAssetDetails.media?.[0]?.contentType?.startsWith('audio')
                ? 'headset'
                : selectedAssetDetails.media?.[0]?.contentType?.startsWith('video')
                  ? 'videocam'
                  : selectedAssetDetails.media?.[0]?.contentType?.startsWith('image')
                    ? 'image'
                    : 'file',
            assetTypeId: selectedAssetDetails.assetTypeId,
        };

        const createNewAsset = async (acceptedFile: any) => {
            try {
                const assetsPreReqBody = {
                    assets: [
                        {
                            assetTypeId: acceptedFile.type.includes('video')
                                ? AssetType.Video
                                : acceptedFile.type.includes('image')
                                  ? AssetType.Image
                                  : AssetType.File,
                            assetDispositionId: 'TechShare',
                            caseNumber,
                            name: assetNameInputValue,
                            fileName: assetNameInputValue || selectedAssetDetails.name,
                            note: '',
                            hasOverlay: false,
                            replacesAssetId: selectedAssetDetails.assetId,
                        },
                    ],
                    techlinecasestatus:
                        caseDetails?.techlineStatusCode === TechlineStatusCode.OPEN_ESCALATED
                            ? TechlineStatusCode.OPEN_ESCALATED
                            : TechlineStatusCode.PENDING_TECHLINE,
                };
                const postAssetsRes = await techlineService.postAssets({ assetsBody: assetsPreReqBody });
                const assetUploadReqParams = postAssetsRes?.data?.results?.assets?.[0]?.presignedUpload;
                const assetsDetails = postAssetsRes?.data?.results?.assets?.[0]?.asset;
                return { assetUploadReqParams, assetsDetails };
            } catch (err) {
                setIsSaving(false);
                console.log(err);
            }
        };

        const updateAssetDetails = async () => {
            try {
                const image = await fetch(activeViewerAsset.downloadUrl);
                const imageBlog = await image.blob();
                let assetUploadReqParamsRoot: any = await createNewAsset(imageBlog);
                const assetUploadReqParams = assetUploadReqParamsRoot?.assetUploadReqParams;
                const assetsDetails = assetUploadReqParamsRoot?.assetsDetails;
                handleCanvasClose();
                setIsSaving(false);
                if (assetUploadReqParams) {
                    const onProgress = throttle((progress: any) => {
                        assetsDetails.percentUploaded = progress;
                        if (progress === 100) {
                            if (inProgressAssets[assetsDetails.assetId]) {
                                setInProgressAssets?.((preVal: any) => {
                                    delete preVal[assetsDetails.assetId];
                                    return preVal;
                                });
                            }
                            handleCanvasClose();
                            fetchAssets(caseNumber);
                            setIsSaving(false);
                        } else {
                            setInProgressAssets?.((preVal: any) => {
                                preVal[assetsDetails.assetId] = assetsDetails;
                                return { ...preVal };
                            });
                        }
                    }, 500);
                    const assetUploadReqest = await techlineService.uploadAssets({
                        uploadUrl: assetUploadReqParams.url,
                        headers: assetUploadReqParams.httpHeaders,
                        assetsBody: imageBlog,
                        onProgress,
                    });
                    analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_UPLOADS_ATTACHMENT);
                }
            } catch (err) {
                console.log(err);
                setIsSaving(false);
            }
        };

        const uploadEditedAsset = () => {
            try {
                setIsSaving(true);
                if (!editorRef.current) {
                    if (selectedAssetDetails.assetTypeId === 'VIDEO' || selectedAssetDetails.assetTypeId === 'FILE') {
                        updateAssetDetails();
                    } else {
                        setIsSaving(false);
                        handleCanvasClose();
                    }
                } else {
                    editorRef.current.export().then(async (strokeBlob) => {
                        let assetUploadReqParams: any = await createNewAsset(strokeBlob);
                        assetUploadReqParams = assetUploadReqParams?.assetUploadReqParams;
                        if (assetUploadReqParams) {
                            const assetUploadReqest = techlineService.uploadAssets({
                                uploadUrl: assetUploadReqParams.url,
                                headers: assetUploadReqParams.httpHeaders,
                                assetsBody: strokeBlob,
                            });
                            handleCanvasClose();
                            fetchAssets(caseNumber);
                        }
                    });
                }
            } catch (err) {
                setIsSaving(false);
                console.log(err);
            }
        };

        let editName = '';
        if (viewOnly) {
            editName = selectedAssetDetails.name;
        } else {
            editName =
                selectedAssetDetails.assetTypeId === AssetType.Image
                    ? t('modals:assetEditor.imageTitle')
                    : t('modals:assetEditor.videoTitle');
        }

        const isProcessing =
            activeViewerAsset && activeViewerAsset.assetProcessingStateId === AssetProcessingState.Processing;

        return ReactDOM.createPortal(
            <>
                <div className={classes.canvasDisplay}>
                    {!showEditorSDK ? (
                        <>
                            <div id="viewer" ref={viewerContainerRef} className={classes.viewerWrapper}>
                                {activeViewerAsset && activeViewerAsset.assetTypeId === AssetType.Image && (
                                    <div className={classes.viewerScale}>
                                        <Typography color="white">{zoomScaleDisplay}</Typography>
                                    </div>
                                )}

                                <div className={classes.viewerClose}>
                                    <Icon
                                        name="x-close"
                                        color="white"
                                        size={32}
                                        onPress={() => {
                                            onClose();
                                        }}
                                    />
                                </div>
                            </div>

                            {activeViewerAsset && (
                                <>
                                    <Viewer
                                        visible
                                        images={viewerData}
                                        container={viewerContainerRef.current as any}
                                        noNavbar
                                        noClose
                                        downloadable={true}
                                        disableMouseZoom
                                        disableKeyboardSupport={!viewOnly}
                                        zoomSpeed={0.1}
                                        attribute={false}
                                        customToolbar={() => {
                                            let actions: any = [
                                                {
                                                    key: 'download',
                                                    render: <Icon name="download" color="white" />,
                                                    onClick: () => {
                                                        const downloadUri = selectedAssetDetails?.media?.find(
                                                            (m: { assetImageTypeId: string }) =>
                                                                m.assetImageTypeId === 'ORIGINAL'
                                                        )?.url;
                                                        downloadImage(
                                                            downloadUri || selectedAssetDetails.media?.[0]?.url,
                                                            selectedAssetDetails.name
                                                        );
                                                    },
                                                },
                                            ];

                                            if (activeViewerAsset.assetTypeId === AssetType.Image) {
                                                actions = [
                                                    {
                                                        key: 'zoomIn',
                                                        actionType: 1,
                                                        render: <Icon name="plus" color="white" />,
                                                    },
                                                    {
                                                        key: 'zoomOut',
                                                        actionType: 2,
                                                        render: <Icon name="minus" color="white" />,
                                                    },
                                                    ...actions,
                                                ];
                                            }

                                            return actions;
                                        }}
                                    />

                                    {activeViewerAsset.assetTypeId === AssetType.Video && (
                                        <video
                                            key={activeViewerAsset.downloadUrl}
                                            className={classes.viewerOverrideContainer}
                                            width="800"
                                            height="600"
                                            controls
                                        >
                                            <source src={activeViewerAsset.downloadUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}

                                    {activeViewerAsset.assetTypeId === AssetType.File && (
                                        <div className={classes.fileIconContainer}>
                                            <Icon
                                                name={activeViewerAsset.iconName as StsIconName}
                                                size={280}
                                                color="white"
                                            />

                                            <Typography variant="lead" color="white">
                                                {t(
                                                    'modals:assetEditor.noPreview',
                                                    'Preview not available.\r\nDownload to view the file.'
                                                )}
                                            </Typography>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <div id="editor" className={classes.canvasWrapper}></div>
                    )}
                </div>

                <div className={classes.canvasControlsOuter}>
                    <div className={classes.canvasControlsHeader}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={classes.nameWrapper}>
                                <Typography variant="h4">{editName}</Typography>
                            </div>
                            {!viewOnly ? (
                                <Button variant="link" onClick={onClose}>
                                    Close
                                </Button>
                            ) : null}
                        </div>

                        {viewOnly && (
                            <div style={{ paddingBottom: 100 }}>
                                <Typography color="textDarkSecondary">
                                    {selectedAssetDetails.note || t('modals:assetEditor.noDescription')}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <div className={classes.canvasControls}>
                        {!viewOnly && (
                            <div className={classes.canvasControlsFormField}>
                                <TextInputHelper
                                    required
                                    controlId="filename"
                                    label={t('qmr:inputs.asset.filename.label', 'Media Name')}
                                    value={assetNameInputValue}
                                    onChangeText={(newVal) => {
                                        let originalFileExtn =
                                            selectedAssetDetails?.name &&
                                            selectedAssetDetails?.name.substring(
                                                selectedAssetDetails?.name.lastIndexOf('.'),
                                                selectedAssetDetails?.name.length
                                            );
                                        let newFileName = newVal.substring(0, newVal.lastIndexOf('.'));
                                        let newFileExtn = newVal.substring(newVal.lastIndexOf('.'), newVal.length);

                                        if (newFileName === '') {
                                            setInValidMediaName(true);
                                            alert('The file name should not be empty');
                                        } else if (originalFileExtn !== newFileExtn || newVal === newFileExtn) {
                                            setInValidMediaName(true);
                                            alert(
                                                'You cannot remove or change the file extension, ' +
                                                    originalFileExtn +
                                                    '.'
                                            );
                                            return;
                                        }
                                        setAssetNameInputValue(newVal);
                                        setInValidMediaName(false);
                                    }}
                                    placeholder={t('qmr:inputs.asset.filename.placeholder', 'Enter name')}
                                />
                            </div>
                        )}
                    </div>

                    <div className={classes.canvasControlsActions}>
                        <Button variant="danger" onClick={() => setDeleteAsset(selectedAssetDetails)}>
                            {selectedAssetDetails.assetTypeId === AssetType.File
                                ? t('modals:assetEditor.actions.deleteFile', 'Delete File')
                                : selectedAssetDetails.assetTypeId === AssetType.Video
                                  ? t('modals:assetEditor.actions.deleteVideo')
                                  : t('modals:assetEditor.actions.deleteImage')}
                        </Button>

                        {!viewOnly && (
                            <LoaderButton
                                isLoading={isSaving}
                                variant="primary"
                                disabled={inValidMediaName}
                                onClick={uploadEditedAsset}
                            >
                                {t('modals:assetEditor.actions.save')}
                            </LoaderButton>
                        )}
                    </div>
                </div>
            </>,
            assetEditorElementRef.current
        );
    }
);

export default AssetEditorModal;
