import React from 'react';
import uuid from 'uuid';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable, DropResult, DraggableLocation } from 'react-beautiful-dnd';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { FormComponent } from '@web/administration/components/worksheet';
import { DetailView, DetailViewBody, DetailViewContent } from '@web/components/detail-view';
import { Label, TextInputHelper } from '@packages/ui/shared';
import {
    DraggableWorksheetFormComponent,
    draggableFormElements,
    WorkSheetStatusEnum,
    ConditionalElement,
    FormElementAction,
    FormElementTypeId,
} from '@packages/models/api';
import AsyncPage from '@web/components/async-page';
import { useContentOverlayState } from '@web/core/hooks';
import { WorksheetFormComponentEditModal } from '../../../components/worksheet/worksheet-form-component-edit-modal';
import { FormElementModalProvider } from '@packages/contexts/form-element-modal';
import { WorksheetFormHeader } from '../components/worksheet-form-header';
import { addQuestionNumber, deleteDraggableFormComponent, updateDraggableFormComponent } from '../components/utils';
import useWorksheetForm from '../hooks/use-worksheet-form';
import WorksheetRightSideBar, { FormRightBarType } from '../components/worksheet-form-right-sidebar';
import WorksheetLeftSideBar from '../components/worksheet-form-left-sidebar';
import { WorksheetAction, WorksheetMode } from '../types';
import useWorksheetFormDetails from './use-worksheet-form-detail.hooks';
import { WorksheetModal } from '../components/worksheet-modal';
import Loader from '@web/components/loader';
import useWorksheetFormTranslation from '../hooks/use-worksheet-form-translation';
import useWorksheetPermission from '../hooks/use-worksheet-permission';

export const WorksheetsForm: FC = () => {
    const { modalDataTranslate, dataTranslate } = useWorksheetFormTranslation();
    const navigate = useNavigate();
    const { worksheetFormId } = useParams<{ worksheetFormId: string }>();
    const [searchParams] = useSearchParams();
    const worksheetStatus = (searchParams.get('status') as WorkSheetStatusEnum) || WorkSheetStatusEnum.Pending;
    const action = searchParams.get('action') as WorksheetAction;
    const maxVersion = parseInt(searchParams.get('maxVersion') || '1');
    const classes = useNewWorksheetFormStyles();
    const [showDeleteMessage, setShowDeleteMessage] = useState<boolean>(false);
    const [showCreateWorksheet, setShowCreateWorksheet] = useState<boolean>(false);
    const [bodyDeleteMessage, setDeleteBodyMessage] = useState<string>('');
    const [modalBodyMessage, setModalBodyMessage] = useState<string>('');
    const [deleteComponent, setDeleteComponent] = useState<DraggableWorksheetFormComponent | ConditionalElement>();
    const [createdWorksheet, setCreatedWorksheet] = useState<boolean>(false);
    const [showFormComponentEditModal, setShowFormComponentEditModal] = useState(false);
    const [formComponentToEdit, setFormComponentToEdit] = useState<DraggableWorksheetFormComponent>();
    const [exit, setExit] = useState(false);
    const [isLoading, setIsLoading] = useState({ saveAndExit: false, save: false });
    const { toggleDraggable } = useContentOverlayState();
    const { isEditOnlyPermission, isCreateOnlyPermission } = useWorksheetPermission();

    const {
        elements,
        fetching,
        setWorkSheetInfo,
        worksheetInfo,
        worksheetDisplayVersion,
        setElements,
        actions,
        worksheetSaveAndExit,
        saveState,
    } = useWorksheetFormDetails({
        worksheetFormId: worksheetFormId,
        worksheetAction: action,
        maxVersion,
    });

    const { state, actions: formActions } = useWorksheetForm({
        setWorkSheetInformation: setWorkSheetInfo,
    });

    const { handleNameChange, handleTitleChange } = formActions;

    useEffect(() => {
        toggleDraggable(true);
        return () => toggleDraggable(false);
    }, []);

    const handleSaveAndExit = useCallback(
        async (isExit: boolean) => {
            if (!worksheetInfo.displayname || !worksheetInfo.worksheetGroupName) {
                handleTitleChange(worksheetInfo.displayname || '');
                handleNameChange(worksheetInfo.worksheetGroupName || '');
                return;
            }
            setExit(isExit);
            await worksheetSaveAndExit({
                setModalBodyMessage,
                setCreatedWorksheet,
                setIsLoading,
                setShowCreateWorksheet,
                isExit,
                mode: WorksheetMode.Edit,
            });
        },
        [
            worksheetInfo.displayname,
            worksheetInfo.worksheetGroupName,
            worksheetSaveAndExit,
            handleTitleChange,
            handleNameChange,
        ]
    );

    const addNewFormComponent = useCallback(
        (source: DraggableLocation, destination: DraggableLocation) => {
            let formComponentsClone = cloneDeep(elements);
            const draggedFormElementClone = cloneDeep(Object.values(draggableFormElements)[source.index]);
            const newFormComponent = Object.assign(draggedFormElementClone, { formElementId: uuid() });
            if (newFormComponent.options) {
                newFormComponent.options = newFormComponent.options.map((option, index) => {
                    option.optionId = uuid();
                    option.optionValue = String(index);
                    return option;
                });
            } else {
                newFormComponent.options = [];
            }
            newFormComponent.action = FormElementAction.Insert;
            newFormComponent.formElementOrder = destination.index + 1;
            formComponentsClone.splice(destination.index, 0, newFormComponent);
            formComponentsClone = addQuestionNumber(formComponentsClone);
            setElements(formComponentsClone);
        },
        [elements, setElements]
    );

    const reorderFormComponents = useCallback(
        (source: DraggableLocation, destination: DraggableLocation) => {
            let formComponentsClone = cloneDeep(elements);
            const [draggedFormComponent] = formComponentsClone.splice(source.index, 1);
            formComponentsClone.splice(destination.index, 0, draggedFormComponent);
            formComponentsClone = addQuestionNumber(formComponentsClone);
            setElements(formComponentsClone);
        },
        [elements, setElements]
    );

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination } = result;
            if (!destination) {
                return;
            }
            switch (source.droppableId) {
                case 'FORM_ELEMENTS':
                    addNewFormComponent(source, destination);
                    break;
                case destination.droppableId:
                    reorderFormComponents(source, destination);
                    break;
                default:
                    return;
            }
        },
        [addNewFormComponent, reorderFormComponents]
    );

    const handleCopyFormComponents = useCallback(
        (item: ConditionalElement | DraggableWorksheetFormComponent) => {
            let formComponentsClone = cloneDeep(elements);
            const copyElementClone = cloneDeep(item);
            copyElementClone.formElementId = uuid();
            if (copyElementClone.optionConditionsElements?.length) {
                const elementJson = copyElementClone.optionConditionsElements.reduce(
                    (_json: { [x: string]: string }, element) => {
                        _json[element.formElementId] = uuid();
                        return _json;
                    },
                    {}
                );
                copyElementClone.options.forEach((opt) => {
                    elementJson[opt.optionId] = uuid();
                    opt.optionId = elementJson[opt.optionId];
                    if (opt.trgtElementIdList?.length) {
                        opt.trgtElementIdList = opt.trgtElementIdList.map((trgId) => elementJson[trgId]);
                    }
                });
                copyElementClone.optionConditionsElements.forEach((ele) => {
                    ele.formElementId = elementJson[ele.formElementId];
                    ele.targetOptionId = elementJson[ele.targetOptionId];
                    if (ele.options.length) {
                        ele.options = ele.options.map((opt) => {
                            opt.optionId = uuid();
                            return opt;
                        });
                    }
                });
            } else {
                copyElementClone.options.forEach((opt) => {
                    opt.optionId = uuid();
                });
            }
            copyElementClone.action = FormElementAction.Insert;
            copyElementClone.formElementOrder = item.formElementOrder || 0;

            if ('targetOptionId' in copyElementClone) {
                formComponentsClone.forEach((component) => {
                    if ([FormElementTypeId.Checkbox, FormElementTypeId.Radio].includes(component.formElementTypeId)) {
                        const targetOptionIndex = component.options.findIndex(
                            (option) => option.optionId === copyElementClone.targetOptionId
                        );
                        if (targetOptionIndex !== -1) {
                            component.options[targetOptionIndex].trgtElementIdList?.push(
                                copyElementClone.formElementId
                            );
                            component.optionConditionsElements?.splice(
                                copyElementClone.formElementOrder || component.optionConditionsElements.length,
                                0,
                                copyElementClone
                            );
                        }
                    }
                });
            } else {
                formComponentsClone.splice(copyElementClone.formElementOrder, 0, copyElementClone);
            }
            formComponentsClone = addQuestionNumber(formComponentsClone);
            setElements(formComponentsClone);
        },
        [elements, setElements]
    );

    const handleFormComponentEditClick = useCallback((item: DraggableWorksheetFormComponent) => {
        setFormComponentToEdit(item);
        setShowFormComponentEditModal(true);
    }, []);

    const handleFormComponentEditModalHide = useCallback(() => {
        setShowFormComponentEditModal(false);
        setFormComponentToEdit(undefined);
    }, []);

    const handleFormComponentEditModalSave = useCallback(
        (item: DraggableWorksheetFormComponent | ConditionalElement) => {
            let formComponentsClone = cloneDeep(elements);
            formComponentsClone = updateDraggableFormComponent(formComponentsClone, item);
            formComponentsClone = addQuestionNumber(formComponentsClone);
            setElements(formComponentsClone);
            handleFormComponentEditModalHide();
        },
        [elements, handleFormComponentEditModalHide, setElements]
    );

    const handleFormComponentDeleteClick = useCallback(
        (item: DraggableWorksheetFormComponent) => {
            setShowDeleteMessage(true);
            setDeleteBodyMessage(modalDataTranslate.deleteElement.bodyMessage(item.description));
            setDeleteComponent(item);
        },
        [modalDataTranslate.deleteElement]
    );

    const handleDeleteModal = useCallback(
        (ans: boolean) => {
            if (!deleteComponent) {
                return;
            }
            if (ans) {
                let formComponentsClone = cloneDeep(elements);
                formComponentsClone = deleteDraggableFormComponent(formComponentsClone, deleteComponent);
                formComponentsClone = addQuestionNumber(formComponentsClone);
                setElements(formComponentsClone);
            }
            setShowDeleteMessage(false);
        },
        [deleteComponent, elements, setElements]
    );

    const handlePreview = useCallback(() => {
        saveState(worksheetInfo, elements);
        worksheetFormId
            ? navigate(
                  `/administration/worksheet/${worksheetFormId}?action=${action}&status=${worksheetStatus}${
                      action === WorksheetAction.AddVersion ? `&maxVersion=${maxVersion}` : ''
                  }`
              )
            : navigate(
                  `/administration/worksheet/1?action=${WorksheetAction.New}&status=${
                      worksheetStatus || WorkSheetStatusEnum.Pending
                  }`
              );
    }, [action, elements, maxVersion, navigate, saveState, worksheetFormId, worksheetInfo, worksheetStatus]);

    if (!(isEditOnlyPermission || isCreateOnlyPermission)) {
        navigate(
            `/administration/worksheet/${worksheetFormId}?action=${action}&status=${worksheetStatus}${
                action === WorksheetAction.AddVersion ? `&maxVersion=${maxVersion}` : ''
            }`
        );
    }
    return (
        <AsyncPage fetchData={actions.fetchData}>
            {fetching ? (
                <Loader />
            ) : (
                <>
                    <WorksheetModal
                        show={showDeleteMessage}
                        onHideModal={() => setShowDeleteMessage(false)}
                        title={modalDataTranslate.deleteElement.title}
                        warningMessage={bodyDeleteMessage}
                        handleAction={handleDeleteModal}
                        cancelButton={modalDataTranslate.deleteElement.cancelButton}
                        confirmButton={modalDataTranslate.deleteElement.confirmButton}
                    />
                    <WorksheetModal
                        show={showCreateWorksheet}
                        title={
                            worksheetFormId
                                ? modalDataTranslate.createOrUpdateWorksheet.title.update
                                : modalDataTranslate.createOrUpdateWorksheet.title.create
                        }
                        onHideModal={() => {
                            if (exit) {
                                navigate('/administration/worksheets');
                            }
                            setShowCreateWorksheet(false);
                        }}
                        bodyMessage={modalBodyMessage}
                        confirmButton="Ok"
                        handleAction={() => {
                            if (createdWorksheet && exit) {
                                navigate('/administration/worksheets');
                            }
                            setShowCreateWorksheet(false);
                        }}
                        closeButton={false}
                    />
                    <FormElementModalProvider>
                        <WorksheetFormComponentEditModal
                            formComponent={formComponentToEdit}
                            show={showFormComponentEditModal}
                            onSave={handleFormComponentEditModalSave}
                            onHide={handleFormComponentEditModalHide}
                            key={formComponentToEdit?.formElementId}
                        />

                        <DetailView>
                            <WorksheetFormHeader
                                handlePreview={handlePreview}
                                handleSaveAndExit={handleSaveAndExit}
                                worksheetInfo={worksheetInfo}
                                worksheetDisplayVersion={worksheetDisplayVersion}
                                worksheetFormId={worksheetFormId}
                                isLoading={isLoading}
                                worksheetStatus={worksheetStatus}
                                isCaseTypeLoading={formActions.isCaseTypeLoading}
                                isPreview={false}
                            />
                            <DetailViewContent>
                                <DetailViewBody className="pt-3 pb-3 pr-2 overflow-hidden">
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <div className={classes.formRow}>
                                            <WorksheetLeftSideBar classes={classes} />
                                            <div className={classes.formComponentsCol} style={{ maxHeight: '77vh' }}>
                                                <div className="mb-2">
                                                    <Label required>
                                                        {dataTranslate.adminFormBuilder.inputs.formTitle.label}
                                                    </Label>
                                                </div>
                                                <div className="mb-6">
                                                    <TextInputHelper
                                                        required
                                                        errorMessage={state.titleErrorMessage}
                                                        value={worksheetInfo.displayname}
                                                        onChangeText={(title) => handleTitleChange(title)}
                                                        placeholder={
                                                            dataTranslate.adminFormBuilder.inputs.formTitle.placeholder
                                                        }
                                                    />
                                                </div>
                                                <Droppable droppableId="FORM_COMPONENTS">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            className={classNames({
                                                                [classes.formContainer]: true,
                                                                [classes.formContainerIsDraggingOver]:
                                                                    snapshot.isDraggingOver,
                                                            })}
                                                        >
                                                            {elements && elements?.length ? (
                                                                elements.map((element, index) => (
                                                                    <Draggable
                                                                        key={element.formElementId}
                                                                        draggableId={element.formElementId}
                                                                        index={index}
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <FormComponent
                                                                                provided={provided}
                                                                                snapshot={snapshot}
                                                                                item={element}
                                                                                parentItem={element}
                                                                                editIconName="pencil"
                                                                                deleteIconName="trash"
                                                                                copyIconName="copy"
                                                                                draggableIcon="draggable"
                                                                                onChildDrag={
                                                                                    handleFormComponentEditModalSave
                                                                                }
                                                                                onEditClick={
                                                                                    handleFormComponentEditClick
                                                                                }
                                                                                onDeleteClick={
                                                                                    handleFormComponentDeleteClick
                                                                                }
                                                                                onCopyClick={handleCopyFormComponents}
                                                                            />
                                                                        )}
                                                                    </Draggable>
                                                                ))
                                                            ) : (
                                                                <div className={classes.dropNotice}>
                                                                    <p className="mb-0">
                                                                        {dataTranslate.adminFormBuilder.dropArea}
                                                                    </p>
                                                                </div>
                                                            )}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </div>
                                        </div>
                                    </DragDropContext>
                                </DetailViewBody>

                                <WorksheetRightSideBar
                                    worksheetInfo={worksheetInfo}
                                    state={state}
                                    actions={formActions}
                                    type={FormRightBarType.Form}
                                />
                            </DetailViewContent>
                        </DetailView>
                    </FormElementModalProvider>
                </>
            )}
        </AsyncPage>
    );
};

const useNewWorksheetFormStyles = createUseStyles({
    formRow: {
        display: 'flex',
    },
    formElementsCol: {
        width: 192,
        flexShrink: 0,
        marginRight: 30,
        maxHeight: '77vh',
        overflowY: 'scroll',
    },
    formComponentsCol: {
        flex: 1,
    },
    formContainer: { height: '90%', overflowY: 'auto', paddingRight: '10px' },
    formContainerIsDraggingOver: {},
    dropNotice: {
        padding: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        top: '105px',
        left: '384px',
    },
});

export type UseNewWorksheetFormStylesReturn = ReturnType<typeof useNewWorksheetFormStyles>;
