import React, { FC } from 'react';
import { Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useBmisLayoutStyles = createUseStyles({
    reportIdentifiers: {
        padding: '20px',
        textTransform: 'uppercase',
    },
});

export const BmisReportSections: FC = () => {
    const classes = useBmisLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.reportIdentifiers}>
            <Typography variant="caption2">{t('Bmis Section')}</Typography>
        </div>
    );
};
