import React from 'react';
import { View, StyleSheet } from 'react-native';

interface MarginProps {
    m?: number | string;
    right?: number | string;
    left?: number | string;
    bottom?: number | string;
    top?: number | string;
    horizontal?: number | string;
    vertical?: number | string;
}

export function Margin({ m, right, left, bottom, top, horizontal, vertical }: MarginProps) {
    const styles = StyleSheet.create({
        theMargin: {
            margin: m,
            marginRight: right,
            marginLeft: left,
            marginBottom: bottom,
            marginTop: top,
            marginHorizontal: horizontal,
            marginVertical: vertical,
        },
    });

    return <View style={styles.theMargin} />;
}

export default Margin;
