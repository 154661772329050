import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { createUseStyles } from 'react-jss';

import { useAbortController } from '@packages/core/http';
import {
    FeedbackForm,
    FeedbackFormAnswer,
    FeedbackFormTypeId,
    FeedbackSession,
    FeedbackSessionConversationItem,
    FeedbackSessionStatusId,
} from '@packages/models/api';
import { analyticsService, feedbackService } from '@web/services/singletons';
import { Button, Typography, TextInputHelper } from '@packages/ui/shared';
import { useTranslation } from 'react-i18next';
import { FeedbackFormElement } from './feedback-form-element';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const useFeedbackDetailStyles = createUseStyles({
    commentsList: {},
});

interface Props {
    session: FeedbackSession;
    onSubmitRating: () => void;
}

export const FeedbackDetailSidePanel: FC<Props> = ({ session, onSubmitRating }) => {
    const classes = useFeedbackDetailStyles();
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();

    const [ratingForm, setRatingForm] = useState<FeedbackForm>();
    const [ratingFormChoices, setRatingFormChoices] = useState<Record<string, FeedbackFormAnswer>>({});

    const isReadOnly = !!session.feedbackRatingSession;

    const [sessionConversation, setSessionConversation] = useState<FeedbackSessionConversationItem[]>([]);
    const [newComment, setNewComment] = useState('');

    const hasAnswers = useMemo(() => {
        return Object.values(ratingFormChoices).length > 0;
    }, [ratingFormChoices]);

    const fetchRatingForm = useCallback(() => {
        if (session.feedbackRatingSession) {
            setRatingForm(session.feedbackRatingSession.feedbackForm);
            return;
        }

        return feedbackService
            .fetchFeedbackSessionRatingForm({
                feedbackSessionId: session.feedbackSessionId,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setRatingForm(response.data.feedbackForm);
            })
            .catch((error) => {
                alert(error.message);
            });
    }, [abortSignalRef, session.feedbackRatingSession, session.feedbackSessionId]);

    const submitRatingForm = useCallback(() => {
        const choices = Object.values(ratingFormChoices);

        if (!choices.length) {
            return;
        }

        feedbackService
            .rateFeedbackSession({
                feedbackSessionId: session.feedbackSessionId,
                data: {
                    choices,
                },
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                analyticsService.logEvent(ANALYTICS_EVENTS.USER_REPLIES_TO_FEEDBACK, {
                    feedbackType: session?.feedbackForm?.formType ? session?.feedbackForm?.formType : 'N/A',
                    ...(session?.feedbackForm?.formType === FeedbackFormTypeId.Entity
                        ? { entityName: session.receivingAccount.name }
                        : {}),
                    ...(session?.feedbackForm?.formType === FeedbackFormTypeId.Qmr
                        ? { tsId: session.qmr?.stsIdentifier }
                        : {}),
                });

                setRatingForm(response.data.session.feedbackForm);
                onSubmitRating();
            })
            .catch((error) => {
                alert(error.message);
            });
    }, [
        abortSignalRef,
        onSubmitRating,
        ratingFormChoices,
        session.feedbackForm.formType,
        session.feedbackSessionId,
        session.qmr,
        session.receivingAccount.name,
    ]);

    const fetchConversation = useCallback(() => {
        return feedbackService
            .fetchFeedbackSessionConversation({
                feedbackSessionId: session.feedbackSessionId,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setSessionConversation(response.data.conversation.conversationItems);
            });
    }, [abortSignalRef, session.feedbackSessionId]);

    const addComment = useCallback(() => {
        return feedbackService
            .addFeedbackSessionConversationComment({
                feedbackSessionId: session.feedbackSessionId,
                comment: newComment,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setSessionConversation(response.data.conversation.conversationItems);
                setNewComment('');
            })
            .catch((error) => {
                alert(error.message);
            });
    }, [abortSignalRef, newComment, session.feedbackSessionId]);

    useEffect(() => {
        fetchRatingForm();
        fetchConversation();
    }, [fetchConversation, fetchRatingForm]);

    const isLoopClosed = session.feedbackRatingSession?.feedbackSessionStatusId === FeedbackSessionStatusId.Rated;

    return (
        <>
            <div className="mb-3">
                <Typography variant="h4" color="blueOne">
                    {t('feedback:closeTheLoop.title', 'Close the Loop')}
                </Typography>
            </div>

            {ratingForm &&
                ratingForm.formElements.map((el, index) => {
                    return (
                        <FeedbackFormElement
                            key={el.formElementId}
                            item={el}
                            readOnly={isLoopClosed}
                            onAnswerChange={(newAnswer: FeedbackFormAnswer) => {
                                setRatingFormChoices({
                                    ...ratingFormChoices,
                                    [el.formElementId]: newAnswer,
                                });
                            }}
                        />
                    );
                })}

            {!isReadOnly && <Button disabled={!hasAnswers} onPress={submitRatingForm} title={t('buttons:submit')} />}

            <hr className="my-4" />

            {isLoopClosed && (
                <>
                    <div className="mb-6">
                        <Typography variant="h5">{t('feedback:closeTheLoop.comments.title', 'Comments')}</Typography>
                    </div>

                    <div className={classes.commentsList}>
                        {sessionConversation.map((item, index) => {
                            return (
                                <div key={index} className="mb-6 d-flex flex-column">
                                    <Typography variant="h6">{`${item.author.firstName} ${item.author.lastName}`}</Typography>

                                    <Typography
                                        variant="caption"
                                        color="textDarkSecondary"
                                        style={{ marginTop: 2, marginBottom: 8 }}
                                    >
                                        {item.createdTimestampDescription}
                                    </Typography>

                                    <Typography variant="default">{item.comment}</Typography>
                                </div>
                            );
                        })}
                    </div>

                    <div>
                        <TextInputHelper
                            placeholder={t('feedback:closeTheLoop.comments.newCommentPlaceholder', 'Add a comment...')}
                            value={newComment}
                            onChangeText={setNewComment}
                            onKeyPress={(e) => {
                                if (e.nativeEvent.key === 'Enter') {
                                    addComment();
                                }
                            }}
                        />

                        {/* SD-1246 -- removed */}
                        {/* <div className="d-flex">
                            <Button variant="ghost-blue" iconLeft={<Icon name="info" color="blueOne" size={16} />}>
                                <Typography variant="caption" color="blueOne">
                                    {t('feedback:closeTheLoop.comments.commentHint', 'Who can see my comments?')}
                                </Typography>
                            </Button>
                        </div> */}
                    </div>
                </>
            )}
        </>
    );
};
