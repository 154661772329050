import colors from '@packages/core/styles/colors';

export enum ChevronDirection {
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export interface ChevronProps {
    color?: string;
    direction?: ChevronDirection;
    topOffest?: string | number;
    leftOffset?: string | number;
    rightOffset?: string | number;
}

export const chevron = (props: ChevronProps = {}) => {
    const config = {
        color: colors.blueOne,
        direction: ChevronDirection.DOWN,
        topOffest: '50%',
        leftOffset: '50%',
        rightOffset: 'auto',
        ...props,
    };

    let beforeTransform;
    let afterTransform;

    switch (config.direction) {
        case ChevronDirection.UP:
            beforeTransform = 'translateX(calc(-50% - 3px)) rotate(-45deg)';
            afterTransform = 'translateX(calc(-50% + 3px)) rotate(45deg)';
            break;
        case ChevronDirection.DOWN:
            beforeTransform = 'translateX(calc(-50% - 3px)) rotate(45deg)';
            afterTransform = 'translateX(calc(-50% + 3px)) rotate(-45deg)';
            break;
        case ChevronDirection.LEFT:
            beforeTransform = 'translate(-50%, calc(-50% - 3px)) rotate(135deg)';
            afterTransform = 'translate(-50%, calc(-50% + 3px)) rotate(-135deg)';
            break;
        case ChevronDirection.RIGHT:
            beforeTransform = 'translate(-50%, calc(-50% - 3px)) rotate(45deg)';
            afterTransform = 'translate(-50%, calc(-50% + 3px)) rotate(-45deg)';
            break;
        default:
            beforeTransform = '';
            afterTransform = '';
            break;
    }

    return {
        '&:before, &:after': {
            border: 0,
            width: 10,
            height: 2,
            zIndex: 1,
            content: '""',
            borderRadius: 1,
            position: 'absolute',
            top: config.topOffest,
            left: config.leftOffset,
            right: config.rightOffset,
            transition: '0.2s transform',
            backgroundColor: config.color,
        },
        '&:before': {
            transform: beforeTransform,
        },
        '&:after': {
            transform: afterTransform,
        },
    };
};
