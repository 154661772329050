import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Label, TextInputHelper, Typography, Icon, Button } from '@packages/ui/shared';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { techlineService, analyticsService } from '@web/services/singletons';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { Form, Table } from 'react-bootstrap';
import fonts from '@web/jss/fonts';
import classNames from 'classnames';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const useStyles = createUseStyles({
    dtcTable: {
        '& thead': {
            height: 40,
            backgroundColor: colors.grayTwo,
        },

        '& tr': {
            border: 'none',
            borderRight: `1px solid ${colors.grayThree}`,
            borderLeft: `1px solid ${colors.grayThree}`,

            '& th': {
                '&:first-of-type': {
                    paddingLeft: 16,
                },
                '&:last-of-type': {
                    paddingRight: 24,
                },
                borderTop: `1px solid ${colors.grayThree}`,
                borderBottom: `1px solid ${colors.grayThree}`,
                fontSize: '1.1rem',
                fontWeight: 'normal',
                textTransform: 'uppercase',
                letterSpacing: 1,
                color: '#555555',
            },
            '& td': {
                borderTop: 'none',
                borderBottom: `1px solid ${colors.grayThree}`,
                padding: 16,
            },
        },
    },
    dtcCodeName: {
        margin: 0,
        marginLeft: 8,
        ...fonts.s,
    },
    dtcCodeDescription: {
        ...fonts.xxs,
        color: colors.textDarkTertiary,
    },
});

export const DtcsInputs = ({
    caseNumber,
    caseDetails,
    updateDTCCode,
}: {
    caseNumber: string;
    caseDetails: any;
    updateDTCCode: any;
}) => {
    const classes = useStyles();
    const [dtcCodesOptions, setDtcCodesOptions] = useState<any>([]);
    const [isDtcFetchLoading, setIsDtcFetchLoading] = useState(false);

    const fetchDTCCodes = async (query: string) => {
        try {
            setIsDtcFetchLoading(true);
            const dtcCodesRes = await techlineService.getDtcCodes({ query });
            if (dtcCodesRes?.data?.dtcCodes) {
                setDtcCodesOptions(
                    dtcCodesRes.data.dtcCodes.map((code: { dtcCode: string }) => ({
                        diagnosticTroubleCode: code.dtcCode,
                    }))
                );
            }
            setIsDtcFetchLoading(false);
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
            setIsDtcFetchLoading(false);
        }
    };

    const addDTCCode = async (dtcCodeObj: any) => {
        try {
            if (!dtcCodeObj.diagnosticTroubleCode) return;
            const updateBody = {
                Casenumber: caseNumber,
                Dtccode: dtcCodeObj.diagnosticTroubleCode,
            };
            const addDTCCodeRes = await techlineService.updateDTCCode({ updateBody });
            if (addDTCCodeRes.success) {
                const currentDtcCodes = caseDetails.diagnosticTroubleCodes || [];
                currentDtcCodes.push({
                    diagnosticTroubleCode: dtcCodeObj.diagnosticTroubleCode,
                    isPrimary: false,
                });
                updateDTCCode(currentDtcCodes);
                analyticsService.logEvent(ANALYTICS_EVENTS.USER_ADDS_DTC);
            }
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
        }
    };

    const updateCurrentDTCCode = async (dtcCodeObj: any) => {
        try {
            const updateBody = {
                Casenumber: caseNumber,
                ...dtcCodeObj,
            };
            const updateDTCCodeRes = await techlineService.updateDTCCode({ updateBody });
            if (updateDTCCodeRes.success) {
            }
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
        }
    };

    const dtcCodesOptionsToRender = useMemo(() => {
        if (dtcCodesOptions?.length && caseDetails?.diagnosticTroubleCodes?.length) {
            return dtcCodesOptions.filter(
                (code: { diagnosticTroubleCode: any }) =>
                    !caseDetails.diagnosticTroubleCodes.find(
                        (currentCode: { diagnosticTroubleCode: any }) =>
                            currentCode.diagnosticTroubleCode === code.diagnosticTroubleCode
                    )?.diagnosticTroubleCode
            );
        }
        return dtcCodesOptions || [];
    }, [caseDetails, dtcCodesOptions]);

    const renderDtcCodes = () => {
        if (!caseDetails?.diagnosticTroubleCodes?.length) return;
        return (
            <Table className={classNames('mb-4', classes.dtcTable)}>
                <thead>
                    <tr>
                        <th>
                            <div className="d-flex">DTC CODE</div>
                        </th>
                        <th>
                            <div className="d-flex justify-content-end">MARK FREEZE FRAME DATA IN SSM</div>
                        </th>
                        <th>
                            <div className="d-flex justify-content-end">MARK AS PRIMARY</div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {caseDetails?.diagnosticTroubleCodes.map(
                        (
                            code: { diagnosticTroubleCode: string; isPrimary: boolean; freezeFrameDataInSSM: boolean },
                            index: number
                        ) => (
                            <tr key={code.diagnosticTroubleCode}>
                                <td>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-row">
                                            <Button
                                                onPress={() => {
                                                    caseDetails.diagnosticTroubleCodes =
                                                        caseDetails.diagnosticTroubleCodes.filter(
                                                            (currentCode: any) =>
                                                                currentCode.diagnosticTroubleCode !==
                                                                code.diagnosticTroubleCode
                                                        );
                                                    updateDTCCode(caseDetails.diagnosticTroubleCodes);
                                                    updateCurrentDTCCode({
                                                        Statuscode: 2,
                                                        Dtccode: code.diagnosticTroubleCode,
                                                    });
                                                }}
                                                variant="ghost-gray"
                                            >
                                                <Icon name="trash" />
                                            </Button>

                                            <p className={classes.dtcCodeName}>
                                                {code.diagnosticTroubleCode}

                                                <small className={classes.dtcCodeDescription}>
                                                    {code.diagnosticTroubleCode}
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className="d-flex justify-content-end">
                                        <Form.Check
                                            className="ml-auto mr-6"
                                            inline
                                            label=" "
                                            type="checkbox"
                                            id={`${code.diagnosticTroubleCode}-ffd`}
                                            name="freezeFrameDataAvailable"
                                            checked={code.freezeFrameDataInSSM}
                                            onChange={() => {
                                                caseDetails.diagnosticTroubleCodes =
                                                    caseDetails.diagnosticTroubleCodes.map((currentCode: any) => ({
                                                        ...currentCode,
                                                        freezeFrameDataInSSM:
                                                            currentCode.diagnosticTroubleCode ===
                                                            code.diagnosticTroubleCode
                                                                ? !currentCode.freezeFrameDataInSSM
                                                                : code.freezeFrameDataInSSM === false
                                                                ? false
                                                                : currentCode.freezeFrameDataInSSM,
                                                    }));
                                                updateDTCCode(caseDetails.diagnosticTroubleCodes);
                                                updateCurrentDTCCode({
                                                    freezeFrameDataInSSM:
                                                        caseDetails.diagnosticTroubleCodes[index].freezeFrameDataInSSM,
                                                    Dtccode:
                                                        caseDetails.diagnosticTroubleCodes[index].diagnosticTroubleCode,
                                                });
                                            }}
                                        />
                                    </div>
                                </td>

                                <td>
                                    <div className="d-flex justify-content-end">
                                        <Form.Check
                                            className="mr-4"
                                            inline
                                            label={' '}
                                            id={`${code.diagnosticTroubleCode}-primary`}
                                            name="markedAsPrimary"
                                            checked={code.isPrimary}
                                            onChange={() => {
                                                caseDetails.diagnosticTroubleCodes =
                                                    caseDetails.diagnosticTroubleCodes.map((currentCode: any) => ({
                                                        ...currentCode,
                                                        isPrimary:
                                                            currentCode.diagnosticTroubleCode ===
                                                            code.diagnosticTroubleCode
                                                                ? !currentCode.isPrimary
                                                                : code.isPrimary === false
                                                                ? false
                                                                : currentCode.isPrimary,
                                                    }));
                                                updateDTCCode(caseDetails.diagnosticTroubleCodes);
                                                updateCurrentDTCCode({
                                                    IsPrimary: caseDetails.diagnosticTroubleCodes[index].isPrimary,
                                                    Dtccode:
                                                        caseDetails.diagnosticTroubleCodes[index].diagnosticTroubleCode,
                                                });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </Table>
        );
    };

    return (
        <div>
            <div className="d-flex flex-row align-items-center">
                <Typography variant="h3">DTCs</Typography>
                <div className="ml-2">
                    <Typography>(Optional)</Typography>
                </div>
            </div>
            <div className="mt-3">
                <div className="mb-3">
                    <Label>Enter any DTC Codes related to this repair</Label>
                </div>
                {renderDtcCodes()}
                <AsyncTypeahead
                    className="mt-3"
                    onChange={(selectedDtcCode: any) => addDTCCode(selectedDtcCode[0])}
                    labelKey={'diagnosticTroubleCode' as any}
                    options={dtcCodesOptionsToRender}
                    placeholder="Choose DTC Codes..."
                    isLoading={isDtcFetchLoading}
                    selected={[]}
                    onSearch={(query) => {
                        fetchDTCCodes(query);
                    }}
                />
            </div>
        </div>
    );
};
