import { HttpClient } from '@packages/core/http';
import {
    AddQmrsToExistingInvestigationResponseDto,
    CreateInvestigationResponseDto,
    GetInvestigationByIdResponseDto,
    GetInvestigationCategoriesResponseDto,
    GetInvestigationStatusesResponseDto,
    GetQmrsFromInvestigationByIdResponseDto,
    InvestigationMeetingNoteModel,
    InvestigationMeetingNoteType,
    InvestigationCommentModel,
    InvestigationModel,
    SearchInvestigationsResponseDto,
    UpdateInvestigationResponseDto,
    Account,
} from '@packages/models/api';

export class InvestigationService {
    constructor(private http: HttpClient) {}

    getInvestigationCategories(ignoreCache?: boolean, signal?: AbortSignal) {
        return this.http.orchestrateRequest<GetInvestigationCategoriesResponseDto>({
            method: 'GET',
            url: '/investigations/categories',
            ignoreCache,
            signal,
        });
    }

    createInvestigation({
        body,
        ignoreCache = false,
        signal,
    }: {
        body: {
            title: string;
            categoryId: string;
            description?: string;
            qmrIds: string[];
            force?: boolean;
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<CreateInvestigationResponseDto>({
            method: 'POST',
            url: '/investigations',
            body,
            ignoreCache,
            signal,
        });
    }

    getInvestigations({
        queryString,
        ignoreCache,
        signal,
    }: {
        queryString: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<InvestigationModel[]>({
            method: 'GET',
            url: `/investigations?${queryString}`,
            ignoreCache,
            signal,
        });
    }

    getInvestigationComments({
        investigationId,
        ignoreCache,
        signal,
    }: {
        investigationId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<{ investigationComments: InvestigationCommentModel[] }>({
            method: 'GET',
            url: `/investigations/${investigationId}/comments`,
            ignoreCache,
            signal,
        });
    }

    addInvestigationComment({
        investigationId,
        body,
        ignoreCache,
        signal,
    }: {
        investigationId: string;
        body: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<InvestigationCommentModel>({
            method: 'POST',
            url: `/investigations/${investigationId}/comments`,
            body: {
                investigationId,
                body,
            },
            ignoreCache,
            signal,
        });
    }

    editInvestigationComment({
        investigationId,
        investigationCommentId,
        body,
        ignoreCache,
        signal,
    }: {
        investigationId: string;
        investigationCommentId: string;
        body: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<InvestigationCommentModel>({
            method: 'PUT',
            url: `/investigations/${investigationId}/comments/${investigationCommentId}`,
            body: {
                investigationId,
                body,
            },
            ignoreCache,
            signal,
        });
    }

    deleteInvestigationComment({
        investigationId,
        investigationCommentId,
        ignoreCache,
        signal,
    }: {
        investigationId: string;
        investigationCommentId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<void>({
            method: 'DELETE',
            url: `/investigations/${investigationId}/comments/${investigationCommentId}`,
            ignoreCache,
            signal,
        });
    }

    searchInvestigations({
        searchString,
        isSearching = false,
        ignoreCache = false,
        signal,
    }: {
        searchString?: string;
        isSearching?: boolean;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = `/investigations?${searchString}`;
        return this.http.orchestrateRequest<SearchInvestigationsResponseDto>({
            method: 'GET',
            url: url,
            ignoreCache,
            signal,
        });
    }

    addQmrsToExistingInvestigation({
        investigationId,
        body,
        ignoreCache = false,
        signal,
    }: {
        investigationId: string;
        body: {
            qmrIds: string[];
            force?: boolean;
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<AddQmrsToExistingInvestigationResponseDto>({
            method: 'PUT',
            url: `/investigations/${investigationId}/qmr`,
            body,
            ignoreCache,
            signal,
        });
    }

    getInvestigationById(investigationId: string, ignoreCache?: boolean, signal?: AbortSignal) {
        return this.http.orchestrateRequest<GetInvestigationByIdResponseDto>({
            method: 'GET',
            url: `/investigations/${investigationId}`,
            ignoreCache,
            signal,
        });
    }

    getInvestigationStatuses(ignoreCache?: boolean, signal?: AbortSignal) {
        return this.http.orchestrateRequest<GetInvestigationStatusesResponseDto>({
            method: 'GET',
            url: '/investigations/statuses',
            ignoreCache,
            signal,
        });
    }

    updateInvestigation({
        investigationId,
        body,
        ignoreCache = false,
        signal,
    }: {
        investigationId: string;
        body: {
            title?: string;
            description?: string;
            categoryId?: string;
            statusId?: string;
            qmrIds?: string[];
            force?: boolean;
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<UpdateInvestigationResponseDto>({
            method: 'PUT',
            url: `/investigations/${investigationId}`,
            body,
            ignoreCache,
            signal,
        });
    }

    getMeetingNoteTypes(ignoreCache?: boolean, signal?: AbortSignal) {
        return this.http.orchestrateRequest<InvestigationMeetingNoteType[]>({
            method: 'GET',
            url: '/investigations/meetingNotes/meetingTypes',
            ignoreCache,
            signal,
        });
    }

    getMeetingNotesForInvestigationId({
        investigationId,
        ignoreCache = false,
        signal,
    }: {
        investigationId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<{ meetingNotes: InvestigationMeetingNoteModel[] }>({
            method: 'GET',
            url: `/investigations/${investigationId}/meetingNotes`,
            ignoreCache,
            signal,
        });
    }

    postMeetingNote({
        investigationId,
        body,
        ignoreCache = false,
        signal,
    }: {
        investigationId: string;
        body: {
            meetingTypeId: string | null;
            body: string;
            meetingDate: string;
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<{ meetingNote: InvestigationMeetingNoteModel }>({
            method: 'POST',
            url: `/investigations/${investigationId}/meetingNotes`,
            body,
            ignoreCache,
            signal,
        });
    }

    updateMeetingNote({
        investigationId,
        meetingNoteId,
        body,
        ignoreCache = false,
        signal,
    }: {
        investigationId: string;
        meetingNoteId: string;
        body: {
            meetingTypeId: string;
            body: string;
            meetingDate: string;
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<{ meetingNote: InvestigationMeetingNoteModel }>({
            method: 'PUT',
            url: `/investigations/${investigationId}/meetingNotes/${meetingNoteId}`,
            body,
            ignoreCache,
            signal,
        });
    }

    getQmrsFromInvestigationById({
        investigationId,
        searchParams,
        ignoreCache = false,
        signal,
    }: {
        investigationId: string;
        searchParams?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<GetQmrsFromInvestigationByIdResponseDto>({
            method: 'GET',
            url: `/investigations/${investigationId}/qmrs${searchParams ? `?${searchParams}` : ''}`,
            ignoreCache,
            signal,
        });
    }

    exportInvestigationAsCsv({
        body,
        ignoreCache = false,
        signal,
    }: {
        body: {
            investigationId: string;
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<any>({
            method: 'POST',
            url: '/qmrs/csv-export',
            body,
            ignoreCache,
            signal,
        });
    }

    getCreators(ignoreCache?: boolean, signal?: AbortSignal) {
        return this.http.orchestrateRequest<{ investigationCreators: Account[] }>({
            method: 'GET',
            url: '/investigations/creators',
            ignoreCache,
            signal,
        });
    }

    removeQmrsFromInvestigation({
        investigationId,
        body,
        ignoreCache = false,
        signal,
    }: {
        investigationId: string;
        body: {
            qmrIds?: string[];
        };
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<{ investigation: InvestigationModel }>({
            method: 'POST',
            url: `/investigations/${investigationId}/qmrs/remove`,
            body,
            ignoreCache,
            signal,
        });
    }
}
