import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';
import { chevron, ChevronDirection } from '../mixins';

export const scForm = {
    input: {
        outline: 'none',
    },
    textarea: {
        outline: 'none',
    },
    '::-webkit-inner-spin-button': {
        display: 'none',
    },
    '::-webkit-calendar-picker-indicator': {
        width: 20,
        height: 20,
        opacity: 1,
        marginRight: 12,
        cursor: 'pointer',
        color: colors.blueOne,
        backgroundColor: 'transparent',
    },
    '::-webkit-input-placeholder': {
        color: `${colors.grayFive} !important`,
    },
    ':-ms-input-placeholder': {
        color: `${colors.grayFive} !important`,
    },
    '::placeholder': {
        color: `${colors.grayFive} !important`,
    },
    '.subcomm-form': {
        '&__label': {
            ...fonts.xs,
            marginBottom: 8,
            color: colors.black,
        },
        '&__control': {
            flex: 1,
            padding: 0,
            ...fonts.s,
            width: '100%',
            borderRadius: 3,
            textIndent: 12.5,
            display: 'block',
            appearance: 'none',
            color: colors.black,
            lineHeight: 'normal',
            backgroundColor: colors.white,
            border: `1px solid ${colors.grayFive}`,
            '&:hover': {
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
            },
            '&:focus, &:active': {
                outline: 'none',
                border: `1px solid ${colors.blueOne}`,
            },
            '&:disabled': {
                backgroundColor: colors.grayThree,
                '&:hover': {
                    boxShadow: 'none',
                },
                '&:active': {
                    border: `1px solid ${colors.grayFive}`,
                },
            },
            '&[type=date], &[type=datetime-local], &[type=email], &[type=month], &[type=number], &[type=password], &[type=search], &[type=tel], &[type=text], &[type=time], &[type=url], &[type=week]': {
                height: 40,
            },
            '&[type=date]': {
                textIndent: 6,
            },
        },
        '&__control-file': {
            width: 0,
            height: 0,
            opacity: 0,
            zIndex: -1,
            overflow: 'hidden',
            position: 'absolute',
            '& + label': {
                margin: 0,
                width: 80,
                height: 80,
                display: 'flex',
                cursor: 'pointer',
                '&:after': {
                    width: '100%',
                    height: '100%',
                    content: '"+"',
                    display: 'flex',
                    borderRadius: 4,
                    fontSize: '3.2rem',
                    lineHeight: '3.2rem',
                    alignItems: 'center',
                    borderStyle: 'dashed',
                    justifyContent: 'center',
                    color: colors.blueOne,
                    border: `2px solid ${colors.grayThree}`,
                },
            },
        },
        '&__check': {
            '&-inline': {
                marginRight: 24,
                display: 'inline-flex',
            },
            '& input[type=checkbox], & input[type=radio]': {
                width: 0,
                height: 0,
                opacity: 0,
                '& + label': {
                    ...fonts.s,
                    paddingLeft: 24,
                    position: 'relative',
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        border: `1px solid ${colors.graySix}`,
                    },
                },
                '&:disabled + label:before': {
                    border: `1px solid ${colors.grayFive}`,
                },
                '&:not(:disabled):checked + label:before': {
                    border: `1px solid ${colors.blueOne}`,
                },
                '&:not(:disabled):focus + label:before': {
                    boxShadow: '0 0 0 0.2rem rgba(38, 143, 255, 0.5)',
                },
            },
            '& input[type=checkbox] + label:before': {
                top: 4,
                left: 0,
                width: 16,
                height: 16,
                borderRadius: 2,
            },
            '& input[type=checkbox]:checked + label:before': {
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: colors.blueOne,
                backgroundImage: `url(/static/icons/icon-checkmark-16x16.svg)`,
            },
            '& input[type=checkbox]:checked:disabled + label:before': {
                backgroundColor: colors.grayFive,
            },
            '& input[type=radio] + label:before': {
                top: 3,
                left: -1,
                width: 18,
                height: 18,
                borderRadius: '50%',
            },
            '& input[type=radio]:checked + label:after': {
                top: 7,
                left: 3,
                width: 10,
                height: 10,
                content: '""',
                borderRadius: '50%',
                position: 'absolute',
                backgroundColor: colors.blueOne,
            },
            '& input[type=radio]:disabled + label:after': {
                backgroundColor: colors.grayFive,
            },
        },
    },
    'textarea.subcomm-form__control': {
        height: 144,
        padding: 12.5,
        textIndent: 0,
        resize: 'none',
    },
    '.rbt': {
        position: 'relative',
        ...chevron({
            topOffest: 20,
            rightOffset: 12,
            leftOffset: 'auto',
            color: colors.grayFive,
            direction: ChevronDirection.DOWN,
        }),
        '& .rbt-input': {
            height: 'auto',
            borderRadius: 3,
            transition: 'all 0s',
            padding: '4px 4px 0 4px',
            backgroundColor: colors.white,
            border: `1px solid ${colors.grayFive}`,
            '&.rbt-input-main': {
                ...fonts.s,
                height: 40,
                padding: '0 12.5px',
                lineHeight: 'normal',
                '&:disabled': {
                    backgroundColor: colors.grayThree,
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&:active': {
                        border: `1px solid ${colors.grayFive}`,
                    },
                },
            },
            '& .rbt-input-wrapper': {
                alignItems: 'flex-start',
                display: 'flex',
                flexWrap: 'wrap',
            },
            '& input': {
                ...fonts.s,
                height: 30,
                textIndent: 8,
                minWidth: 200,
                marginBottom: 4,
                color: colors.black,
                lineHeight: 'normal',
            },
            '&.focus': {
                border: `1px solid ${colors.blueOne}`,
            },
            '&:hover': {
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
            },
        },
        '& .rbt-token': {
            ...fonts.xs,
            borderRadius: 500,
            color: colors.white,
            position: 'relative',
            display: 'inline-block',
            margin: '0px 4px 4px 0',
            padding: '6px 6px 6px 12px',
            backgroundColor: colors.blueOne,
            '&:focus': {
                outline: 'none',
                boxShadow: '0 0 0 0.2rem rgba(38, 143, 255, 0.5)',
            },
        },
        '& .rbt-token-remove-button': {
            width: 18,
            height: 18,
            opacity: 1,
            marginLeft: 8,
            borderRadius: '50%',
            backgroundColor: colors.white,
            '& > span': {
                color: colors.blueOne,
            },
        },
        '& .rbt-menu': {
            marginTop: 0,
            borderRadius: 3,
            border: `1px solid ${colors.grayThree}`,
            boxShadow: '0 8px 12px 0 rgba(0,0,0,0.16)',
        },
        '& .dropdown-item': {
            ...fonts.s,
            padding: '8px 16px',
            ...fonts.robotoRegular,
            '&.active': {
                backgroundColor: colors.blueTwo,
                '& .rbt-highlight-text': {
                    color: colors.white,
                },
            },
            '&:hover': {
                backgroundColor: colors.blueOne,
                color: colors.white,
                '& .rbt-highlight-text': {
                    color: colors.white,
                },
            },
        },
        '& .rbt-highlight-text': {
            padding: 0,
            ...fonts.robotoBold,
            backgroundColor: 'transparent',
        },
    },
};
