import * as React from 'react';
import { CategoriesProvider } from './categories.context';
import { ArticlesProvider } from './articles.context';

export const SharedCategoryContextProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <CategoriesProvider>
            <ArticlesProvider>{children}</ArticlesProvider>
        </CategoriesProvider>
    );
};
