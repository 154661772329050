import React, { FC, useCallback, useMemo } from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { Icon, Typography } from '..';
import colors from '@packages/core/styles/colors';
import { Account } from '@packages/models/api';
enum LocationInfoConstant {
    REGION = 'REGION',
    DISTRICT = 'DISTRICT',
    ZONE = 'ZONE',
}
enum RetailerInfoConstant {
    RETAILER = 'RETAILER',
    ID = 'ID',
}
interface Props {
    userInfo?: Account;
}

const Card: FC<Props> = ({ userInfo }) => {
    const info = useMemo(() => {
        let retailer: { [key in RetailerInfoConstant]: string } = {
            [RetailerInfoConstant.ID]: '',
            [RetailerInfoConstant.RETAILER]: '',
        };
        let locationInfo: { [key in LocationInfoConstant]: string } = {
            [LocationInfoConstant.REGION]: '',
            [LocationInfoConstant.DISTRICT]: '',
            [LocationInfoConstant.ZONE]: '',
        };

        if (userInfo) {
            userInfo.locations.forEach((loc) => {
                if (loc.roleTargetTypeId === 'RETAILER') {
                    loc.elements.forEach((ele) => {
                        if (retailer.hasOwnProperty(ele.accountLocationElementType)) {
                            retailer[ele.accountLocationElementType as RetailerInfoConstant] = ele.value;
                        }
                    });
                } else if (loc.roleTargetTypeId === LocationInfoConstant.DISTRICT) {
                    loc.elements.forEach((ele) => {
                        if (locationInfo.hasOwnProperty(ele.accountLocationElementType)) {
                            locationInfo[ele.accountLocationElementType as LocationInfoConstant] = ele.title;
                        }
                    });
                }
            });
        }
        return {
            name: userInfo?.name,
            phoneNumber: userInfo?.phoneNumbers[0]?.phoneNumberDescription,
            email: userInfo?.emailAddresses[0]?.emailAddress,
            retailer,
            ...locationInfo,
        };
    }, [userInfo]);

    const handleEmailClick = useCallback(() => {
        Linking.openURL(`mailto:"${info.email}"`);
    }, [info]);
    return (
        <>
            <View style={styles.marginVertical5}>
                {Boolean(info.retailer[RetailerInfoConstant.ID]) && (
                    <Typography color={'grayFive'} variant="caption" style={styles.marginVertical3}>
                        {info.retailer[RetailerInfoConstant.ID]} {info.retailer[RetailerInfoConstant.RETAILER]}
                    </Typography>
                )}
                <Typography style={[{ fontSize: 24, fontWeight: '700', lineHeight: 24 }, styles.marginVertical3]}>
                    {info.name}
                </Typography>
            </View>
            <View style={(styles.marginVertical5, styles.fullWidth)}>
                <Typography variant="caption" style={styles.marginVertical3}>
                    {userInfo?.jobTitleDescription}
                </Typography>
                {(info[LocationInfoConstant.ZONE] ||
                    info[LocationInfoConstant.REGION] ||
                    info[LocationInfoConstant.DISTRICT]) && (
                    <Typography variant="caption" style={styles.marginVertical3}>
                        {`${info[LocationInfoConstant.REGION] && `Region ${info[LocationInfoConstant.REGION]},`} ${
                            info[LocationInfoConstant.ZONE] && ` Zone ${info[LocationInfoConstant.ZONE]},`
                        }${info[LocationInfoConstant.DISTRICT] && ` District ${info[LocationInfoConstant.DISTRICT]}`}`}
                    </Typography>
                )}
            </View>
            <View style={{ height: 10 }} />
            <View style={{ borderColor: colors.grayFour, borderBottomWidth: 1 }} />
            <View style={{ height: 10 }} />
            {info.email && (
                <View style={[styles.iconTextContainer, styles.marginVertical3]}>
                    <Icon name="launch" style={{ marginRight: 10 }} />
                    <Typography
                        variant="caption"
                        style={[styles.marginVertical3, { color: colors.blueOne }]}
                        onPress={() => handleEmailClick()}
                    >
                        {info.email}
                    </Typography>
                </View>
            )}

            {info.phoneNumber && (
                <View style={[styles.iconTextContainer, styles.marginVertical3]}>
                    <Icon name="phone" style={{ marginRight: 10 }} />
                    <Typography style={styles.marginVertical3}>{info.phoneNumber}</Typography>
                </View>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    marginVertical3: {
        marginVertical: 3,
    },
    marginVertical5: {
        marginVertical: 5,
    },
    iconTextContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
});

export default Card;
