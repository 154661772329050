import React, { FC, createContext, useState, useCallback } from 'react';
import { InvestigationModel, Qmr } from '@packages/models/api';

export type InvestigationModalMode = 'add' | 'create';

interface InvestigationModalState {
    show: boolean;
    mode: InvestigationModalMode;
    selectedQmrs: Qmr[];
    onSuccess?: (investigation: InvestigationModel) => void;
    allowInvestigationReassignment: boolean;
}

type InvestigationModalStateChange = Partial<
    Pick<InvestigationModalState, 'mode' | 'selectedQmrs' | 'onSuccess' | 'allowInvestigationReassignment'>
>;

interface InvestigationModalContextConfig {
    show: boolean;
    mode: InvestigationModalMode;
    selectedQmrs: Qmr[];
    onSuccess?: (investigation: InvestigationModel) => void;
    allowInvestigationReassignment: boolean;
    showModal: (newState: InvestigationModalStateChange) => void;
    hideModal: () => void;
}

export const InvestigationModalContext = createContext({} as InvestigationModalContextConfig);

export const InvestigationModalProvider: FC = ({ children }) => {
    const [state, setState] = useState<InvestigationModalState>({
        show: false,
        mode: 'add',
        selectedQmrs: [],
        allowInvestigationReassignment: false,
    });

    const showModal = useCallback((newState: InvestigationModalStateChange) => {
        setState({
            show: true,
            mode: 'add',
            selectedQmrs: [],
            allowInvestigationReassignment: false,
            ...newState,
        });
    }, []);

    const hideModal = useCallback(() => {
        setState({
            show: false,
            mode: 'add',
            selectedQmrs: [],
            allowInvestigationReassignment: false,
        });
    }, []);

    return (
        <InvestigationModalContext.Provider
            value={{
                showModal,
                hideModal,
                ...state,
            }}
        >
            {children}
        </InvestigationModalContext.Provider>
    );
};
