import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { FailCodeStartModalTechline } from './fail-code-start-modal-techline';
import TechlineFailCodeLookupBySection from './techline-fail-code-lookup-by-section';
import { TechlineFailCodeSearch } from './techline-fail-code-search';
import { TechlineFailCodePartSearch } from './techline-fail-code-part-search';
import { TechlineFailCodeLookupElectricalGrid } from './techline-fail-code-lookup-electrical-grid';
import { TechlineFailCodeContainer, TechlineFailCodeNoTroubleFound } from './techline-fail-code-no-trouble-found';

interface Props {
    show: boolean;
    onHide(): void;
    updateCaseDetails: any;
    vehicleDetails: any;
}

export interface SearchProps {
    searchFailCode: boolean;
    searchFailCodeByPart: boolean;
    lookBySection: boolean;
    lookupByElectricalGridLocation: boolean;
    noTroubleFound: boolean;
    viewMatching: boolean;
}

export interface AnalyticalProp {
    enterTime: number;
}

export type ContentTypes =
    | 'start'
    | 'searchFailCode'
    | 'searchFailCodeByPart'
    | 'lookupBySection'
    | 'lookupByElectricalGridLocation'
    | 'noTroubleFound'
    | 'viewMatching';

const useFailCodeSearchModalStyles = createUseStyles({
    failCodeSearchModal: {
        width: 728,
        maxWidth: 'none',
    },
});

export const FailCodesModal = (props: Props) => {
    const classes = useFailCodeSearchModalStyles();

    const [isLoading, setIsloading] = useState(false);
    const [content, setContent] = useState<ContentTypes>('start');
    const [showMatchingFailCodes, setShowMatchingFailCodes] = useState(false);
    const [prefillPartNumber, setPrefillPartNumber] = useState(false);

    const handleEnter = useCallback(() => {}, []);

    const returnToHome = () => {
        setContent('start');
    };

    const onHide = () => {
        setContent('start');

        props.onHide();
    };

    const handleSetContent = (searchProps: SearchProps) => {
        if (searchProps.lookBySection) {
            setContent('lookupBySection');
        } else if (searchProps.lookupByElectricalGridLocation) {
            setContent('lookupByElectricalGridLocation');
        } else if (searchProps.searchFailCode) {
            setContent('searchFailCode');
        } else if (searchProps.searchFailCodeByPart) {
            setContent('searchFailCodeByPart');
        } else if (searchProps.noTroubleFound) {
            setContent('noTroubleFound');
        } else if (searchProps.viewMatching) {
            setContent('searchFailCodeByPart');
        }
    };

    const handleShowMatchingFailCodesTrue = (failCodes: Array<TechlineFailCodeContainer>) => {
        setShowMatchingFailCodes(true);
    };

    const handleShowMatchingFailCodesFalse = () => {
        setShowMatchingFailCodes(false);
    };

    return (
        <Modal
            centered
            dialogClassName={classes.failCodeSearchModal}
            onHide={onHide}
            show={props.show}
            onEnter={handleEnter}
        >
            {content === 'start' && (
                <FailCodeStartModalTechline
                    returnToHome={returnToHome}
                    onHide={onHide}
                    isLoading={isLoading}
                    showMatchingFailCodes={showMatchingFailCodes}
                    handleSetContent={handleSetContent}
                />
            )}
            {content === 'lookupBySection' && (
                <TechlineFailCodeLookupBySection
                    updateCaseDetails={props.updateCaseDetails}
                    vehicleDetails={props.vehicleDetails}
                    returnToHome={returnToHome}
                    onHide={onHide}
                    show={props.show}
                />
            )}
            {content === 'searchFailCode' && (
                <TechlineFailCodeSearch
                    updateCaseDetails={props.updateCaseDetails}
                    returnToHome={returnToHome}
                    onHide={onHide}
                    vehicleDetails={props.vehicleDetails}
                />
            )}
            {content === 'searchFailCodeByPart' && (
                <TechlineFailCodePartSearch
                    updateCaseDetails={props.updateCaseDetails}
                    vehicleDetails={props.vehicleDetails}
                    returnToHome={returnToHome}
                    onHide={onHide}
                    prefillPartNumber={prefillPartNumber}
                />
            )}
            {content === 'lookupByElectricalGridLocation' && (
                <TechlineFailCodeLookupElectricalGrid
                    updateCaseDetails={props.updateCaseDetails}
                    vehicleDetails={props.vehicleDetails}
                    onHide={props.onHide}
                    returnToHome={returnToHome}
                />
            )}
            {content === 'noTroubleFound' && (
                <TechlineFailCodeNoTroubleFound
                    updateCaseDetails={props.updateCaseDetails}
                    vehicleDetails={props.vehicleDetails}
                    onHide={onHide}
                    returnToHome={returnToHome}
                    handleShowMatchingFailCodesTrue={handleShowMatchingFailCodesTrue}
                    handleShowMatchingFailCodesFalse={handleShowMatchingFailCodesFalse}
                />
            )}
        </Modal>
    );
};
