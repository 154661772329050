import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextBadge, Typography } from '@packages/ui/shared';

import { useQmrState } from '@packages/contexts/qmrs';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
const useDtcCodeSubmittedStyles = createUseStyles({
    dtcCode: {
        display: 'flex',
        padding: '16px 16px 16px 24px',
        borderTop: `1px solid ${colors.grayThree}`,
        borderLeft: `1px solid ${colors.grayThree}`,
        borderRight: `1px solid ${colors.grayThree}`,
        '&:last-of-type': {
            borderBottom: `1px solid ${colors.grayThree}`,
        },
    },
    dtcCodeNum: {
        marginRight: 24,
    },
});

const DtcCodesSubmitted: FC = () => {
    const classes = useDtcCodeSubmittedStyles();
    const { t } = useTranslation();
    const { qmr } = useQmrState();

    if (!qmr) {
        return null;
    }

    return (
        <>
            <Typography variant="h4">{t('qmr:report.dtcCodes.title', 'DTC Codes')}</Typography>

            <div>
                {qmr.dtcCodes?.map((code, i) => {
                    return (
                        <div key={code.dtcCodeId} className={classes.dtcCode}>
                            <div className={classes.dtcCodeNum}>
                                <Typography color="textDarkSecondary">{i + 1}</Typography>
                            </div>

                            <Typography style={{ flex: 1 }}>
                                <Typography>{code.dtcCode}</Typography>
                                <div className="my-2">
                                    <Typography color="textDarkSecondary">{code.description}</Typography>
                                </div>
                            </Typography>

                            {code.freezeFrameDataAvailable && (
                                <div className="d-inline-block">
                                    <TextBadge>{t('qmr:report.dtcCodes.ffdSaved', 'FFD Saved to SSM')}</TextBadge>
                                </div>
                            )}

                            {code.markedAsPrimary && (
                                <div className="d-inline-block">
                                    <TextBadge style={code.freezeFrameDataAvailable && { marginLeft: 8 }}>
                                        {t('qmr:report.dtcCodes.primary', 'Primary')}
                                    </TextBadge>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default DtcCodesSubmitted;
