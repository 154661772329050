import { useCallback, useState } from 'react';
import { CaseType, SaveCaseTypeRequestBody, WorksheetCaseTypeStatus } from '@packages/models/api';
import { worksheetsService } from '@web/services/singletons';
import useUserDetailsForWorksheet from './useUserDetailsOrPermissions';

interface Props {
    setShowToaster?: ({ show, message }: { show: boolean; message: string }) => void;
    setCaseTypes?: (x: CaseType[]) => void;
    handleIsCaseTypeSave?: (x: boolean) => void;
}

export const useCaseType = ({ setShowToaster, setCaseTypes, handleIsCaseTypeSave }: Props) => {
    const [isCaseTypeLoading, setIsCaseTypeLoading] = useState(false);

    const { isPilotUser } = useUserDetailsForWorksheet();

    const fetchCaseType = useCallback(async () => {
        const caseTypeData = await worksheetsService.fetchCaseType({
            status: WorksheetCaseTypeStatus.Active,
            isPilotUser,
        });
        if (!caseTypeData?.success) {
            console.log('caseType error', caseTypeData.data.message);
        } else {
            setCaseTypes?.(caseTypeData.data.casetypes);
        }
        setIsCaseTypeLoading(false);
    }, [isPilotUser]);

    const saveCaseTypes = useCallback(
        async ({ caseTypes, status, caseNumber }: { caseTypes: CaseType[]; status: 1 | 2; caseNumber: string }) => {
            handleIsCaseTypeSave?.(false);
            if (!caseTypes.length) {
                setShowToaster?.({ show: true, message: 'Please select casetype.' });
            } else {
                const body: SaveCaseTypeRequestBody = {
                    casenumber: caseNumber || '',
                    caseTypeIntersects: caseTypes.map((caseType) => ({
                        casetypesourceid: caseType.northstarId,
                        isprimary: !(status === 2),
                        statuscode: status,
                    })),
                };
                const response = await worksheetsService.saveCaseType(body);
                if (!response.success) {
                    setShowToaster?.({ show: true, message: response.data.message || 'something went to wrong' });
                }
                handleIsCaseTypeSave?.(true);
                return response;
            }
            handleIsCaseTypeSave?.(true);
        },
        [handleIsCaseTypeSave, setShowToaster]
    );

    const defaultSelectedDto = useCallback(
        ({
            caseTypesDefault,
            caseTypes,
        }: {
            caseTypesDefault:
                | {
                      caseType: string;
                      caseTypeSourceId: string;
                      isPrimary: string;
                  }[]
                | undefined;

            caseTypes: CaseType[];
        }) => {
            const selected = caseTypesDefault
                ?.map((caseType) => {
                    const selected = caseTypes.find((type) => type.caseType === caseType.caseType);
                    if (selected) {
                        return {
                            caseTypeId: selected.caseTypeId,
                            northstarId: caseType.caseTypeSourceId,
                            caseType: caseType.caseType,
                        };
                    }
                    return undefined;
                })
                .filter((item): item is CaseType => item !== undefined);
            return selected;
        },
        []
    );

    return {
        fetchCaseType,
        saveCaseTypes,
        isCaseTypeLoading,
        defaultSelectedDto,
    };
};
