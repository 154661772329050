import uuid from 'uuid';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { constructUrl } from '@web/utils/url-utils';

import { analyticsService, configService, qmrsService } from '@web/services/singletons';
import { AdvancedSearchCombinator, AdvancedSearchField } from '@packages/models/api';

import { Button, Typography } from '@packages/ui/shared';
import AsyncPage from '@web/components/async-page';
import { DetailView, DetailViewBody, DetailViewContent, DetailViewHeader } from '@web/components/detail-view';
import { GroupConditionModel, QueryBuilder } from '@web/qmr/components/query-builder';
import AdvancedSearchModal from '@web/qmr/components/advanced-search-modal';
import { useAdvancedSearchContext } from '@packages/contexts/qmrs';
import RenderJson from '@web/components/render-json';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const firstQueryUuid = uuid();

const AdvancedSearch: FC = () => {
    const navigate = useNavigate();

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [combinators, setCombinators] = useState<AdvancedSearchCombinator[]>();
    const [fields, setFields] = useState<AdvancedSearchField[]>();
    const [query, setQuery] = useState<GroupConditionModel>();
    const { setRecentSavedSearches } = useAdvancedSearchContext();

    const fetchData = useCallback(async () => {
        const response = await qmrsService.getAdvancedSearchParameters();

        if (response.success) {
            setCombinators(response.data.combinators);
            setFields(response.data.fields);
            setQuery({
                id: firstQueryUuid,
                combinatorId: response.data.combinators[0].combinatorId,
                rules: [],
            });
        } else {
            throw response.data;
        }
    }, []);

    async function handleSearchButtonClick() {
        try {
            const response = await qmrsService.postAdvancedSearchQuery(query);
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_STARTS_ADVANCED_SEARCH);
            if (response.success) {
                navigate(constructUrl(`/qmrs/advanced-search/${response.data.searchId}`, {}));
                analyticsService.logEvent(ANALYTICS_EVENTS.ADVANCED_SEARCH_LOADED);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }

    async function handleSave(name: string, publicSearch: boolean, rolesToShareSelected: string[]) {
        try {
            const response = await qmrsService.postAdvancedSearchQuery(query, name, publicSearch, rolesToShareSelected);
            const recentSearchsResponse = await qmrsService.getAdvancedSearchRecentSavedSearches();

            if (recentSearchsResponse.success) {
                setRecentSavedSearches(recentSearchsResponse.data.savedSearches);
            }

            if (response.success) {
                analyticsService.logEvent(ANALYTICS_EVENTS.USER_SAVES_ADVANCED_SEARCH);
                navigate(constructUrl(`/qmrs/advanced-search/${response.data.searchId}`, {}));
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }

    return (
        <>
            <AdvancedSearchModal
                show={showSaveModal}
                onSave={handleSave}
                onHide={() => {
                    setShowSaveModal(false);
                }}
            />

            <AsyncPage fetchData={fetchData}>
                <DetailView>
                    <DetailViewHeader
                        breadcrumbs={[
                            {
                                to: '/qmrs',
                                title: 'QMRs',
                            },
                            {
                                to: '/#',
                                title: 'Advanced Search',
                            },
                        ]}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <Typography variant="h2">Advanced Search</Typography>
                        </div>
                    </DetailViewHeader>
                    <DetailViewContent topOffset="breadcrumb">
                        <DetailViewBody>
                            <Row>
                                <Col>
                                    {combinators && fields && query && (
                                        <QueryBuilder
                                            combinators={combinators}
                                            fields={fields}
                                            query={query}
                                            onChange={setQuery}
                                        />
                                    )}
                                    <hr className="mb-6" />
                                    {query && (
                                        <div className="d-flex">
                                            <Button
                                                disabled={query.rules.length === 0}
                                                style={{ marginRight: 12 }}
                                                onPress={handleSearchButtonClick}
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                disabled={query.rules.length === 0}
                                                onPress={() => {
                                                    setShowSaveModal(true);
                                                }}
                                            >
                                                Save this search
                                            </Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            {configService.debugEnvName !== 'production' && (
                                <Row>
                                    <Col>
                                        <RenderJson json={query} />
                                        <p className="mb-0">
                                            This debug output is available in our staging and dev environments and will
                                            not be live in production.
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </DetailViewBody>
                    </DetailViewContent>
                </DetailView>
            </AsyncPage>
        </>
    );
};

export default AdvancedSearch;
