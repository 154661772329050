import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useDetailViewContentStyles = createUseStyles({
    content: ({ topOffsetPixels }: { topOffsetPixels: number }) => ({
        flex: 1,
        display: 'flex',
        height: `calc(100% - ${topOffsetPixels}px)`,
    }),
});

interface DetailViewContentProps {
    topOffset?: 'none' | 'header' | 'breadcrumb';
    children?: React.ReactNode;
}

export const DetailViewContent: FC<DetailViewContentProps> = ({ topOffset = 'none', children }) => {
    const [topOffsetPixels, setTopOffsetPixels] = useState(0);

    useEffect(() => {
        if (topOffset === 'none') {
            setTopOffsetPixels(0);
        } else if (topOffset === 'header') {
            setTopOffsetPixels(80);
        } else if (topOffset === 'breadcrumb') {
            setTopOffsetPixels(100);
        }
    }, [topOffset]);

    const classes = useDetailViewContentStyles({
        topOffsetPixels,
    });

    return <div className={classes.content}>{children}</div>;
};
