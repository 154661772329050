import { vinSessionService } from '@web/services/singletons';

export function handleVinSessionError(message: string, error?: Error) {
    console.error('[VinSession Error]:', message, error);
}

export async function openVinSession(
    vin: string,
    accountId: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
    setIsLoading(true);
    try {
        await vinSessionService.openVinSession({
            body: {
                vin: vin,
                createdAccountId: accountId,
            },
            ignoreCache: true,
        });
    } catch (error) {
        handleVinSessionError('Open VIN Session Error', error);
    }
    setIsLoading(false);
}

export async function closeVinSession(
    vin: any,
    accountId: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
    setIsLoading(true);
    try {
        await vinSessionService.closeVinSession({
            body: {
                vin: vin,
                createdAccountId: accountId,
            },
            ignoreCache: true,
        });
    } catch (error) {
        handleVinSessionError('Close VIN Session Error', error);
    }
    setIsLoading(false);
}
