import { AssetMedia, AssetImageMedia, AssetVideoMedia, AssetMediaType } from '@packages/models/api';

export interface AssetMediaViewModel {
    assetId: string;
    uri: string;
    iconName?: string;
    rawSize?: {
        height: number;
        width: number;
    };
    type?: string;
    name?: string;
    id?: string;
}

export type AssetMediaUrlsByType = {
    [imgType in AssetMediaType]: AssetMediaViewModel;
};

const isVideo = <(media: AssetMedia) => media is AssetVideoMedia>((media) => !!(media as AssetVideoMedia).assetVideoId);
const isPhoto = <(media: AssetMedia) => media is AssetImageMedia>((media) => !!(media as AssetImageMedia).assetImageId);

export const getAssetMediaUrlsByType = (allMedia: AssetMedia[], assetId: string, assetName?: string) => {
    // reduces media array to map of
    // AssetMediaType => { uri, size }
    return allMedia.reduce((imgTypes, assetMedia) => {
        if (isVideo(assetMedia)) {
            imgTypes[assetMedia.assetVideoTypeId] = {
                assetId,
                uri: assetMedia.url,
                rawSize: {
                    height: assetMedia.height,
                    width: assetMedia.width,
                },
                type: assetMedia.contentType,
                name: assetName || assetMedia.assetVideoId,
                id: assetMedia.assetVideoId,
            };
        } else if (isPhoto(assetMedia)) {
            imgTypes[assetMedia.assetImageTypeId] = {
                assetId,
                uri: assetMedia.url,
                rawSize: {
                    height: assetMedia.height,
                    width: assetMedia.width,
                },
                type: assetMedia.contentType,
                name: assetName || assetMedia.assetImageId,
                id: assetMedia.assetImageId,
            };
        } else {
            imgTypes[AssetMediaType.File] = {
                assetId,
                uri: assetMedia.url,
                iconName: assetMedia.contentType.startsWith('audio')
                    ? 'headset'
                    : assetMedia.contentType.startsWith('video')
                    ? 'videocam'
                    : assetMedia.contentType.startsWith('image')
                    ? 'image'
                    : 'file',
                type: assetMedia.contentType,
                name: assetName || assetMedia.assetFileId,
                id: assetMedia.assetFileId,
            };
        }

        return imgTypes;
    }, {} as AssetMediaUrlsByType);
};

export function getMediaUrl(media: ReturnType<typeof getAssetMediaUrlsByType>) {
    return media.ORIGINAL_OVERLAID
        ? media.ORIGINAL_OVERLAID.uri
        : media.ORIGINAL
        ? media.ORIGINAL.uri
        : media.FILE
        ? media.FILE.uri
        : '';
}
