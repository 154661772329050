import { HttpClient } from '@packages/core/http';

export interface QmrsReviewPendingDTO {
    qmrId: string;
    stsIdentifier: string;
    vin: string;
    name: string;
    qmrLink: string;
    districtId: string;
}

export interface PendingReviewQmrsResponse {
    pendingReviewQmrs: QmrsReviewPendingDTO[];
}

export class PendingReviewQmrsService {
    constructor(private http: HttpClient) {}

    fetchPendingReviewQmrs({ ignoreCache = false, signal }: { ignoreCache?: boolean; signal?: AbortSignal }) {
        const url = '/qmr/dashboard/pendingreviewqmrs';

        return this.http.orchestrateRequest<PendingReviewQmrsResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }
}
