import uuid from 'uuid';
import { cloneDeep } from 'lodash';
import React, { FC } from 'react';

import { Button, Icon, TextInputHelper, Typography } from '@packages/ui/shared';

import { DraggableFormComponent, FeedbackFormElementOption } from '@packages/models/api';

interface FormEditSelectListProps extends DraggableFormComponent {
    onChange({
        formElementLabel,
        options,
    }: {
        formElementLabel?: string;
        options?: FeedbackFormElementOption[] | undefined;
    }): void;
}

export const FormEditSelectList: FC<FormEditSelectListProps> = ({ formElementLabel, options, onChange }) => {
    function handleLabelChangeText(text: string) {
        onChange({ formElementLabel: text });
    }

    function handleOptionLabelChange(index: number, text: string) {
        if (!options) {
            return;
        }

        const optionsClone = cloneDeep(options);
        optionsClone[index].optionLabel = text;

        onChange({ options: optionsClone });
    }

    function handleRemoveOptionPress(index: number) {
        if (!options) {
            return;
        }

        const optionsClone = cloneDeep(options);
        optionsClone.splice(index, 1);

        onChange({ options: optionsClone });
    }

    function handleAddOptionPress() {
        let optionsClone: FeedbackFormElementOption[] = [];

        if (!options) {
            optionsClone = [];
        } else {
            optionsClone = cloneDeep(options);
        }

        const idAndValue = uuid();

        optionsClone.push({
            optionId: idAndValue,
            optionValue: idAndValue,
            optionLabel: '',
            optionPlaceholder: '',
        });

        onChange({ options: optionsClone });
    }

    return (
        <>
            <div className="mb-2">
                <Typography variant="label">Question Text</Typography>
            </div>
            <div className="mb-6">
                <TextInputHelper
                    value={formElementLabel}
                    onChangeText={handleLabelChangeText}
                    placeholder="Enter a label"
                />
            </div>
            <div className="mb-2">
                <Typography variant="label">Options</Typography>
            </div>
            <div>
                {options &&
                    options.map((option, index) => {
                        return (
                            <div key={option.optionId} className="d-flex mb-2 align-items-center">
                                <div className="mr-2">
                                    <Typography>{index + 1}</Typography>
                                </div>
                                <TextInputHelper
                                    style={{ flex: 1 }}
                                    value={option.optionLabel}
                                    onChangeText={(text) => {
                                        handleOptionLabelChange(index, text);
                                    }}
                                    placeholder="Enter a label"
                                />
                                <div className="ml-2">
                                    <Button
                                        variant="link"
                                        onPress={() => {
                                            handleRemoveOptionPress(index);
                                        }}
                                    >
                                        <Icon name="x-close" />
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="d-flex">
                <Button variant="ghost-blue" onPress={handleAddOptionPress}>
                    Add Another Option
                </Button>
            </div>
        </>
    );
};
