import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import { useQmrState } from '@packages/contexts/qmrs';
import { Button, Icon, Typography, TextBadge } from '@packages/ui/shared';
import { Collapse, Alert } from 'react-bootstrap';

const useVehicleInformationSubmittedStyles = createUseStyles({
    section: {
        display: 'flex',
        paddingBottom: 8,
    },
    sectionDivider: {
        marginBottom: 24,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    info: {
        paddingTop: 8,
        paddingBottom: 16,
    },
    importedSiebelContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,
        marginBottom: 24,
    },
    importedSiebelIcon: {
        marginRight: 15,
    },
    importedSiebelTitle: {
        fontWeight: 'bold',
    },
    importedSiebelMessageContainer: {
        paddingTop: '0 20px',
    },
    importedSiebelMessage: {
        marginBottom: 15,
        marginTop: 5,
    },
});

const InfoGroup = ({ title, description }: { title: string; description: string }) => {
    const { t } = useTranslation();
    const classes = useVehicleInformationSubmittedStyles();

    return (
        <>
            <Typography variant="h6">{title}</Typography>

            <div className={classes.info}>
                <Typography>{description || t('text:notAvailable')}</Typography>
            </div>
        </>
    );
};

const VehicleInformationSubmitted: FC = () => {
    const classes = useVehicleInformationSubmittedStyles();
    const { t } = useTranslation();
    const { qmr } = useQmrState();

    const [isViewingMore, setIsViewingMore] = useState(false);

    if (!qmr) {
        return null;
    }

    const divider = <hr className={classes.sectionDivider} />;

    return (
        <>
            {qmr?.importedFromSiebel && (
                <Alert className={classes.importedSiebelContainer} variant="warning">
                    <div className={classes.importedSiebelIcon}>
                        <Icon name="warning" color={'yellowOne'} />
                    </div>
                    <div className={classes.importedSiebelMessageContainer}>
                        <div className={classes.importedSiebelTitle}>
                            {t('qmr:sections.vehicleInfo.importedSiebelTitle')}
                        </div>

                        <div className={classes.importedSiebelMessage}>
                            {t('qmr:sections.vehicleInfo.importedSiebelMessage')}
                        </div>
                    </div>
                </Alert>
            )}
            <div className="d-flex justify-content-between mb-4">
                <Typography variant="h4">{t('qmr:sections.vehicleInfo.title')}</Typography>

                <div className="d-inline-block">
                    <Button
                        variant="ghost-gray"
                        onPress={() => {
                            setIsViewingMore(!isViewingMore);
                        }}
                        iconRight={<Icon name={isViewingMore ? 'minus' : 'plus'} />}
                    >
                        {isViewingMore
                            ? t('qmr:sections.vehicleInfo.viewLess', 'View Less')
                            : t('qmr:sections.vehicleInfo.viewMore', 'View More')}
                    </Button>
                </div>
            </div>

            <div className={classes.section}>
                <div className={classes.column}>
                    <InfoGroup
                        title={t('qmr:report.vehicleInfo.modelYear', 'Model Year')}
                        description={'' + qmr.modelYear}
                    />
                    {qmr.capabilities.viewVinOnQmr && (
                        <InfoGroup title={t('qmr:report.vehicleInfo.vin', 'VIN')} description={qmr.vin} />
                    )}

                    <Collapse in={isViewingMore}>
                        <div>
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.production', 'Production Date')}
                                description={qmr.productionDateDescription}
                            />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.model', 'Model Code')}
                                description={qmr.modelCode}
                            />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.modelOption', 'Option Code')}
                                description={qmr.optionCode}
                            />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.engineSize', 'Engine Size')}
                                description={qmr.engineSize}
                            />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.engineNumber', 'Engine Number')}
                                description={qmr.engineNumber}
                            />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.emissionsSpec', 'Emissions Spec')}
                                description={qmr.emissionSpec}
                            />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.warrantyStart', 'Warranty Start Date')}
                                description={qmr.warrantyStartDateDescription}
                            />
                        </div>
                    </Collapse>
                </div>

                <div className={classes.column}>
                    <InfoGroup title={t('qmr:report.vehicleInfo.carline', 'Carline')} description={qmr.carlineName} />
                    <InfoGroup title={t('qmr:report.vehicleInfo.mileage', 'Mileage')} description={'' + qmr.mileage} />

                    <Collapse in={isViewingMore}>
                        <div>
                            <InfoGroup title={t('qmr:report.vehicleInfo.color', 'Color')} description={qmr.colorCode} />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.transmissionType', 'Transmission Type')}
                                description={qmr.transmissionType}
                            />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.transmissionNumber', 'Transmission Number')}
                                description={qmr.transmissionNumber}
                            />
                            <InfoGroup
                                title={t('qmr:report.vehicleInfo.turbo', 'Turbo Indicator')}
                                description={
                                    typeof qmr.turboIndicator === 'boolean'
                                        ? qmr.turboIndicator
                                            ? t('text:common.yes')
                                            : t('text:common.no')
                                        : ''
                                }
                            />
                        </div>
                    </Collapse>
                </div>
            </div>

            {divider}

            <div className={classes.section}>
                <div className={classes.column}>
                    <InfoGroup
                        title={t('qmr:report.repairOrder.partNumber', 'Part Number')}
                        description={qmr.partNumber ? `${qmr.partNumber}` : ''}
                    />
                    {qmr.capabilities.viewRepairOrderOnQmr && (
                        <InfoGroup
                            title={t('qmr:report.repairOrder.repairOrderNumber', 'Repair Order Number')}
                            description={qmr.repairOrderNumber}
                        />
                    )}
                </div>

                <div className={classes.column}>
                    <InfoGroup
                        title={t('qmr:report.repairOrder.failureDate', 'Failure Date')}
                        description={qmr.failureDateDescription}
                    />
                    {!qmr.importedFromSiebel && (
                        <InfoGroup
                            title={t('qmr:report.repairOrder.repairDate', 'Repair or Inspection Date')}
                            description={qmr.repairDateDescription}
                        />
                    )}
                </div>
            </div>

            {qmr.capabilities.viewEwdOnQmr && (
                <>
                    {divider}

                    <Typography variant="h6">
                        {t('qmr:report.ewd.title', 'Were there any EWD indicated by the customer?')}
                    </Typography>

                    {!qmr.ewdSignsExist ? (
                        <div className="my-2">
                            <Typography>{t('qmr:report.ewd.none', 'No')}</Typography>
                        </div>
                    ) : (
                        <>
                            {qmr.ewdRollover && (
                                <div className="my-2">
                                    <div className="d-flex">
                                        <TextBadge variant="red">{t('qmr:report.ewd.rollover', 'Rollover')}</TextBadge>
                                    </div>
                                </div>
                            )}

                            {qmr.ewdFire && (
                                <div className="my-2">
                                    <div className="d-flex">
                                        <TextBadge variant="red">{t('qmr:report.ewd.fire', 'Fire')}</TextBadge>
                                    </div>
                                </div>
                            )}

                            {qmr.ewdAccident && (
                                <div className="my-2">
                                    <div className="d-flex">
                                        <TextBadge variant="red">{t('qmr:report.ewd.accident', 'Accident')}</TextBadge>
                                    </div>
                                </div>
                            )}

                            {qmr.ewdInjury && (
                                <div className="my-2">
                                    <div className="d-flex">
                                        <TextBadge variant="red">
                                            {t('qmr:report.ewd.injury', 'Injury')}
                                            {t('qmr:report.ewd.peopleCount', ' ({{count}} person)', {
                                                count: qmr.ewdInjuryCount,
                                            })}
                                        </TextBadge>
                                    </div>
                                </div>
                            )}

                            {qmr.ewdDeath && (
                                <div className="my-2">
                                    <div className="d-flex">
                                        <TextBadge variant="red">
                                            {t('qmr:report.ewd.death', 'Death')}
                                            {t('qmr:report.ewd.peopleCount', '({{count}} person)', {
                                                count: qmr.ewdDeathCount,
                                            })}
                                        </TextBadge>
                                    </div>
                                </div>
                            )}

                            {qmr.ewdPropertyDamage && (
                                <div className="my-2">
                                    <div className="d-flex">
                                        <TextBadge variant="red">
                                            {t('qmr:report.ewd.propertyDamage', 'Property Damage')}
                                        </TextBadge>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default VehicleInformationSubmitted;
