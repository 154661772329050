import React, { FC } from 'react';
import { Typography } from '@packages/ui/shared';
import { DetailViewHeader } from '@web/components/detail-view';

const Reports: FC = () => {
    return (
        <>
            <DetailViewHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <Typography variant="h2">Reports</Typography>
                </div>
            </DetailViewHeader>
        </>
    );
};

export default Reports;
