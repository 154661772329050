import yn from 'yn';

import { StorageKeys, StorageService } from '../storage';
import { BuildConfig, Environment } from './build';
import { Constants } from './constants';

export class ConfigService {
    private _currentEnvName: string;
    private _debugEnvName: string;
    private _ready: Promise<void>;

    ready() {
        return this._ready;
    }

    get currentEnvName(): string {
        return this._currentEnvName;
    }

    get debugEnvName(): string {
        return this._debugEnvName;
    }

    get buildDebugEnabled() {
        return yn(this.buildConfig.availableEnvironments[this.buildConfig.buildEnv][Constants.Env.EnableDebugger], {
            default: false,
        });
    }

    get env(): Environment {
        return this.buildConfig.availableEnvironments[this._currentEnvName];
    }

    constructor(
        private storageService: StorageService,
        private buildConfig: BuildConfig
    ) {
        this._currentEnvName = buildConfig.buildEnv || 'local';
        this._debugEnvName = buildConfig.debugEnv || 'local';

        if (buildConfig.buildEnv === 'production') {
            this._ready = storageService.setItem(StorageKeys.CurrentEnv, buildConfig.buildEnv);
        } else {
            this._ready = storageService.getItem(StorageKeys.CurrentEnv).then((currentEnv) => {
                if (currentEnv && buildConfig.availableEnvironments[currentEnv]) {
                    this._currentEnvName = currentEnv;
                } else {
                    return this.storageService.setItem(StorageKeys.CurrentEnv, this._currentEnvName);
                }
            });
        }
    }

    get(key: keyof Environment, defaultValue?: string) {
        return this.env[key] || defaultValue;
    }

    // Environment variables are strings
    // this parses an env var intended as boolean
    getBoolean(key: keyof Environment, defaultValue = false) {
        // The following case-insensitive values are recognized:
        // 'on', 'y', 'yes', 'true', '1' --> true
        // 'off', 'n', 'no', 'false', '0' --> false
        return yn(this.env[key], { default: defaultValue });
    }

    changeEnv(env: string) {
        if (!Object.keys(this.buildConfig.availableEnvironments).includes(env)) {
            throw new Error('Unrecognized environment name');
        }

        this._currentEnvName = env;
        this._debugEnvName = env;

        return this.storageService.setItem(StorageKeys.CurrentEnv, env);
    }
}
