import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import { notificationsService } from '@web/services/singletons';

import colors from '@packages/core/styles/colors';
import { NotificationListItem, Typography, LoadingCircle, Icon, Button } from '@packages/ui/shared';
import { Notification, NotificationStatusId } from '@packages/models/api';
import { Dropdown } from 'react-bootstrap';
import ActionDropdown from './action-dropdown';
import { useContentOverlayState } from '@web/core/hooks';

const useStyles = createUseStyles({
    notificationsPanel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    notificationsPanelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    notificationsPanelCenterContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    notificationsPanelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    notificationGroupHeader: {
        top: 0,
        zIndex: 1,
        position: 'sticky',
        padding: '16px 24px',
        backgroundColor: colors.white,
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    footer: {
        padding: '12px 12px',
        borderTop: `1px solid ${colors.grayThree}`,
        textAlign: 'center',
    },
});

type Props = {
    /**
     * @desc Note: This controls close panel functionality
     */
    handleNotificationsPress: () => void;

    refetchNotifications: () => void;

    // Handles onClick onto notification settings tab
    openNotificationsSettingsModal: () => void;
};

const NotificationsPanel = (props: Props) => {
    const { refetchNotifications } = props;

    const classes = useStyles();
    const navigate = useNavigate();
    const { toggleOverlay } = useContentOverlayState();

    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const notificationGroups = useMemo(() => {
        return notifications.reduce((groupsArray: string[], notification) => {
            if (!groupsArray.includes(notification.notificationGroupingIdDescription)) {
                groupsArray.push(notification.notificationGroupingIdDescription);
            }

            return groupsArray;
        }, []);
    }, [notifications]);

    const getNotifications = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await notificationsService.fetchNotifications(undefined, true);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setNotifications(response.data.notifications);
            refetchNotifications();
        } catch (error) {
            window.alert(error.message);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        getNotifications();
    }, [getNotifications]);

    const markNotificationsAsRead = useCallback(
        async (notificationIds: string[]) => {
            await notificationsService
                .updateNotifications({
                    notifications: notificationIds.map((notificationId) => ({
                        notificationId: notificationId,
                        notificationStatusId: NotificationStatusId.Read,
                    })),
                })
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }

                    setNotifications(
                        notifications.map((n) => {
                            const updated = response.data.notifications.find(
                                (x) => x.notificationId === n.notificationId
                            );

                            if (updated) {
                                return updated;
                            }

                            return n;
                        })
                    );

                    refetchNotifications();
                });
        },
        [notifications, refetchNotifications]
    );

    const handleMarkAllAsReadButtonPress = useCallback(async () => {
        try {
            await markNotificationsAsRead(
                notifications
                    .filter(
                        (notification) =>
                            notification.notificationId &&
                            notification.notificationStatusId !== NotificationStatusId.Read
                    )
                    .map((notification) => notification.notificationId)
            );
            await getNotifications();
        } catch (error) {
            window.alert(error.message);
        }
    }, [getNotifications, markNotificationsAsRead, notifications]);

    const handleClearAllButtonPress = useCallback(async () => {
        try {
            await notificationsService.deleteNotifications(
                notifications.map((notification) => notification.notificationId)
            );

            await getNotifications();
        } catch (error) {
            window.alert(error.message);
        }
    }, [getNotifications, notifications]);

    const handleNotificationPress = useCallback(
        async (notification: Notification) => {
            let destination = '';

            if (notification.link) {
                destination = notification.link.replace(window.location.origin, '');
                toggleOverlay('notifications');
            } else {
                window.alert('Notification has no link');
            }

            try {
                await markNotificationsAsRead([notification.notificationId]);
                refetchNotifications();
            } catch (error) {
                window.alert(error.message);
            }

            if (destination) {
                const win = window.open(destination, '_blank');
                win?.focus();
            }
        },
        [navigate, markNotificationsAsRead, refetchNotifications, toggleOverlay]
    );

    const rightPanelDiv = document.getElementById('right-side-panel');

    if (!rightPanelDiv) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={classes.notificationsPanel}>
            <div className={classes.notificationsPanelHeader}>
                <Typography>Notifications</Typography>
                <ActionDropdown drop="down" alignRight>
                    <Dropdown.Item onClick={props.openNotificationsSettingsModal}>
                        <Typography variant="default">View Settings</Typography>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleMarkAllAsReadButtonPress}>
                        <Typography variant="default">Mark all as read</Typography>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleClearAllButtonPress}>
                        <Typography variant="default">Clear all</Typography>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={refetchNotifications}>
                        <Typography variant="default">Refetch</Typography>
                    </Dropdown.Item>
                </ActionDropdown>
                <div className={classes.closeButton} onClick={props.handleNotificationsPress}>
                    <Icon name="x-close" />
                </div>
            </div>
            <div className={classes.notificationsPanelScrollContainer}>
                {isLoading && (
                    <div className={classes.notificationsPanelCenterContainer}>
                        <LoadingCircle size={64} borderWidth={8} borderColor={colors.grayOne} />
                    </div>
                )}

                {!isLoading &&
                    notificationGroups.length > 0 &&
                    notificationGroups.map((group, index) => (
                        <div key={`${group}-${index}`}>
                            <div className={classes.notificationGroupHeader}>
                                <Typography variant="h6">{group}</Typography>
                            </div>
                            {notifications
                                .filter((item) => item.notificationGroupingIdDescription === group)
                                .map((item) => (
                                    <NotificationListItem
                                        key={item.notificationId}
                                        item={item}
                                        onPress={handleNotificationPress}
                                    />
                                ))}
                        </div>
                    ))}

                {!isLoading && notificationGroups.length <= 0 && (
                    <div className={classes.notificationsPanelCenterContainer}>
                        <div style={{ marginBottom: 32 }}>
                            <Icon hero name="bell" />
                        </div>
                        <Typography variant="h3" color="textDarkTertiary">
                            No Notifications
                        </Typography>
                    </div>
                )}

                <div className={classes.footer}>
                    <Typography variant="default" color="blueOne">
                        Not seeing notifications you're expecting?
                        <br />
                        <Button variant="info" onPress={props.openNotificationsSettingsModal}>
                            {'Check Your Notification Settings'}
                        </Button>
                    </Typography>
                </div>
            </div>
        </div>,
        rightPanelDiv
    );
};

export default NotificationsPanel;
