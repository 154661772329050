import { BadgeVariants } from '@packages/ui/shared';

export const getBadgeVariant = (status: string): BadgeVariants => {
    switch (status) {
        // https://subaruofamerica.atlassian.net/browse/TECHSHARE-1769
        case 'Pending':
            return 'yellow';
        case 'NOT_ESCALATED':
            return 'gray';
        case 'ESCALATION_FAILED':
            return 'red';
        case 'TRANSFERRING':
            return 'yellow';
        case 'SENT_TO_TR':
            return 'gray';
        case 'TR_DRAFT':
            return 'gray';
        case 'TR_ISSUED':
            return 'yellow';
        case 'TEMPORARY_REPLY_RECEIVED':
            return 'yellow';
        case 'FINAL_REPLY_RECEIVED':
            return 'green';
        case 'FINAL_REPLY_APPROVED':
            return 'green';
        case 'FINAL_REPLY_REFUSED':
            return 'red';
        default:
            return 'yellow';
    }
};

export const getBadgeVariantTRSharedReply = (status: string): BadgeVariants => {
    switch (status) {
        case 'SHARED':
            return 'green';
        case 'PENDING':
            return 'gray';
        case 'CLOSED':
            return 'green';
        case 'NO_REPLY':
            return 'yellow';
        default:
            return 'yellow';
    }
};
