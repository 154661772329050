export enum Env {
    ApiBaseUrl = 'API_BASE_URL',
    VinBaseUrl = 'VIN_BASE_URL',
    SamlLoginUrl = 'SAML_LOGIN_URL',
    FirebaseApiKey = 'FIREBASE_API_KEY',
    FirebaseProjectId = 'FIREBASE_PROJECT_ID',
    FirebaseMessagingSenderId = 'FIREBASE_MESSAGING_SENDER_ID',
    FirebaseAppId = 'FIREBASE_APP_ID',
    FirebaseVapidKey = 'FIREBASE_VAPID_KEY',
    EnableDebugger = 'ENABLE_DEBUGGER',
    DebugApiUtils = 'DEBUG_API_UTILS',
    DebugAvailableEnvironments = 'DEBUG_AVAILABLE_ENVIRONMENTS',
    DebugUserSwitcher = 'DEBUG_USER_SWITCHER',
    amplitudeApiKey = 'AMPLITUDE_API_KEY',
}

export const Constants = {
    AccessTokenHeaderName: 'X-Subcomm-Access-Token',
    RandomErrorProbabilityHeaderName: 'X-Random-Error-Probability',
    SimulateDelayHeaderName: 'X-Simulate-Delay',
    Env,
    TechlineSupportPhone: '1-866-782-2782',
    TimeoutPopupDurationInSeconds: 2 * 60,
    RefreshTokenWindowInSeconds: 5 * 60,
};
