import React, { FC } from 'react';
import { DraggableWorksheetFormComponent, FormElementFile, WorksheetFormElementOption } from '@packages/models/api';
import { AddFieldType } from './worksheet-form-component-edit-modal';
import { FileField } from './file-fields';
import useTranslationData from './hooks/useTranslationData';

interface FormWorksheetEditFileProps extends DraggableWorksheetFormComponent {
    onChange({
        formElementFile,
        options,
        worksheetElementTooltip,
    }: {
        formElementFile?: FormElementFile;
        options?: WorksheetFormElementOption[];
        worksheetElementTooltip?: string;
    }): void;
    addField?: AddFieldType;
}

export const FormEditFile: FC<FormWorksheetEditFileProps> = ({ formElementFile, onChange }) => {
    const { draggableElementsDataTranslate } = useTranslationData();
    return (
        <FileField
            onChange={onChange}
            formElementFile={formElementFile}
            title={draggableElementsDataTranslate.title.files}
            accept={'*'}
        />
    );
};
