import React, { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { Button, TextBadge, Typography } from '@packages/ui/shared';
import {
    DetailView,
    DetailViewAside,
    DetailViewBody,
    DetailViewContent,
    DetailViewHeader,
} from '@web/components/detail-view';
import { Chip } from '@web/administration/components';

import { entitiesService } from '@web/services/singletons';
import { EntityModel } from '@packages/models/api';
import AsyncPage from '@web/components/async-page';

export const EntityDetail: FC = () => {
    const navigate = useNavigate();
    const { entityId } = useParams<{ entityId: string }>() as { entityId: string };
    const [entity, setEntity] = useState<EntityModel | undefined>();

    const fetchData = useCallback(async () => {
        const response = await entitiesService.getEntity(entityId);

        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            throw response.data;
        }

        setEntity(response.data.entity);
    }, [entityId]);

    function handleEditButtonPress() {
        if (!entity) {
            return;
        }

        navigate(`/administration/entities/${entity.entityId}/edit`);
    }

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <DetailViewHeader
                    breadcrumbs={[
                        { to: '/administration/entities', title: 'Manage Entities' },
                        { to: `/administration/entities/${entityId}`, title: 'Entity' },
                    ]}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <Typography variant="h3">{entity?.name}</Typography>
                        <Button onPress={handleEditButtonPress}>Edit Entity</Button>
                    </div>
                </DetailViewHeader>
                <DetailViewContent topOffset="breadcrumb">
                    <DetailViewBody>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4">Entity Details</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={6}>
                                <div className="mb-2">
                                    <Typography variant="label">Name</Typography>
                                </div>
                                <Typography>{entity?.name}</Typography>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-2">
                                    <Typography variant="label">Description</Typography>
                                </div>
                                <Typography>{entity?.description}</Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <div className="mb-2">
                                    <Typography variant="label">Managers</Typography>
                                </div>
                                <ul className="list-unstyled">
                                    {entity?.members && entity.members.length > 0 ? (
                                        <>
                                            {entity?.members.map((member) => {
                                                return (
                                                    <li key={member.accountId} className="mb-1">
                                                        <Chip title={member.name} />
                                                    </li>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <Typography>No managers</Typography>
                                    )}
                                </ul>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-2">
                                    <Typography variant="label">Parent Entity</Typography>
                                </div>
                                {entity?.parentEntity ? (
                                    <ul className="list-unstyled">
                                        <li className="mb-1">
                                            <Chip title={entity.parentEntity.name} />
                                        </li>
                                    </ul>
                                ) : (
                                    <Typography>No parent entity</Typography>
                                )}
                            </Col>
                        </Row>
                    </DetailViewBody>
                    <DetailViewAside>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    Entity Settings
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-6">
                            <Col>
                                <div className="mb-2">
                                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                                        Visibility
                                    </Typography>
                                </div>
                                <div className="d-inline-flex">
                                    {entity?.visible ? (
                                        <TextBadge variant="green">Visible</TextBadge>
                                    ) : (
                                        <TextBadge variant="red">Hidden</TextBadge>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-6">
                            <Col>
                                <div className="mb-2">
                                    <Typography variant="h6">Status</Typography>
                                </div>
                                <div className="d-inline-flex">
                                    {entity?.active ? (
                                        <TextBadge variant="green">Enabled</TextBadge>
                                    ) : (
                                        <TextBadge variant="default">Disabled</TextBadge>
                                    )}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {entity?.feedbackCreationUrl && (
                                <Col>
                                    <div className="mb-2">
                                        <Typography variant="h6">Feedback Form URL</Typography>
                                    </div>
                                    <div className="d-inline-flex">
                                        <Typography>
                                            <a
                                                href={entity?.feedbackCreationUrl}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {entity?.feedbackCreationUrl}
                                            </a>
                                        </Typography>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </DetailViewAside>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};
