import { HttpClient } from '@packages/core/http';

import {
    AccountRolesResponseDto,
    FeedbackForm,
    FeedbackFormAdminDto,
    FeedbackFormElement,
    FeedbackFormsAdminDto,
} from '@packages/models/api';

interface CreateFeedbackFormRequestBody {
    title: string;
    feedbackFormTypeId: string;
    elements: FeedbackFormElement[];
    active: boolean;
    confirm: boolean;
    feedbackTargetIds?: string[];
}

interface UpdateFeedbackFormRequestBody extends CreateFeedbackFormRequestBody {}

export class FeedbackAdminService {
    constructor(private http: HttpClient) {}

    fetchFeedbackForms({
        page,
        size,
        ignoreCache = false,
        signal,
    }: {
        page: number;
        size: number;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const params = new URLSearchParams({
            page: '' + page,
            size: '' + size,
        });

        return this.http.orchestrateRequest<FeedbackFormsAdminDto>({
            method: 'GET',
            url: `/admin/feedback?${params.toString()}`,
            ignoreCache,
            signal,
        });
    }

    fetchFeedbackFormById({
        feedbackFormId,
        ignoreCache = false,
        signal,
    }: {
        feedbackFormId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<{ form: FeedbackForm }>({
            method: 'GET',
            url: `/admin/feedback/form/${feedbackFormId}`,
            ignoreCache,
            signal,
        });
    }

    createFeedbackForm(body: CreateFeedbackFormRequestBody, signal?: AbortSignal) {
        return this.http.orchestrateRequest<FeedbackFormAdminDto>({
            method: 'POST',
            url: '/admin/feedback/form',
            body,
            signal,
        });
    }

    updateFeedbackForm(feedbackFormId: string, body: UpdateFeedbackFormRequestBody, signal?: AbortSignal) {
        return this.http.orchestrateRequest<FeedbackFormAdminDto>({
            method: 'PUT',
            url: `/admin/feedback/form/${feedbackFormId}`,
            body,
            signal,
        });
    }

    fetchAccountRoles(signal?: AbortSignal) {
        return this.http.orchestrateRequest<AccountRolesResponseDto>({
            method: 'GET',
            url: '/accounts/roles',
            signal,
        });
    }
}
