import React, { FC, useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, FormControl } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Icon } from '@packages/ui/shared';

const useConfirmInjusyEwdModalStyles = createUseStyles({
    confirmInjuryEwdModal: {
        width: 552,
        maxWidth: 'none',
    },
});

interface ConfirmInjuryEwdModalProps {
    show: boolean;
    onHide(): void;
    onConfirmButtonClick(numberOfPeopleInjured: number): void;
    countInputValue: string;
}

const ConfirmInjuryEwdModal: FC<ConfirmInjuryEwdModalProps> = (props) => {
    const { t } = useTranslation();
    const classes = useConfirmInjusyEwdModalStyles();
    const inputRef = useRef<HTMLInputElement>(null);

    const [numberOfPeopleInjuredInputValue, setNumberOfPeopleInjuredInputValue] = useState<string>(
        props.countInputValue
    );

    function handleModalOnHide() {
        props.onHide();
    }

    function handleConfirmButtonClick() {
        props.onConfirmButtonClick(parseInt(numberOfPeopleInjuredInputValue));
    }

    function handleNumberOfPeopleInjuredInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        setNumberOfPeopleInjuredInputValue(event.target.value);
    }

    useEffect(() => {
        if (!props.show) {
            return;
        }

        setNumberOfPeopleInjuredInputValue(props.countInputValue);
    }, [props.show, props.countInputValue]);

    useEffect(() => {
        if (!props.show) {
            return;
        }

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [props.show]);

    return (
        <Modal show={props.show} dialogClassName={classes.confirmInjuryEwdModal} onHide={handleModalOnHide} centered>
            <Modal.Header closeButton placeholder={''}>
                <h4 className="mb-0">{t('qmr:popups.confirmInjuryEwd.title')}</h4>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center mb-4">
                    <div className="mr-4">
                        <Icon name="warning" />
                    </div>
                    <h4 className="mb-0">{t('qmr:popups.confirmInjuryEwd.message')}</h4>
                </div>
                <p className="mb-6">{t('qmr:popups.confirmInjuryEwd.description')}</p>
                <Form onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()}>
                    <Form.Group controlId="confirm-injury-ewd__number-of-people-injured">
                        <Form.Label>{t('qmr:inputs.injuryCount.label')}</Form.Label>
                        <Form.Control
                            ref={inputRef}
                            type="number"
                            placeholder={t('qmr:inputs.injuryCount.placeholder')}
                            value={numberOfPeopleInjuredInputValue}
                            onChange={handleNumberOfPeopleInjuredInputChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="mr-2" onClick={handleModalOnHide}>
                    {t('qmr:popups.confirmInjuryEwd.cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleConfirmButtonClick}
                    disabled={!numberOfPeopleInjuredInputValue || parseFloat(numberOfPeopleInjuredInputValue) % 1 !== 0}
                >
                    {t('qmr:popups.confirmInjuryEwd.confirm')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmInjuryEwdModal;
