import { WorkSheetStatusEnum, WorksheetForm } from '@packages/models/api';
import { Button, Icon, Typography } from '@packages/ui/shared';
import RecentSearchTypeahead from '@web/components/recent-search-typeahead';
import useQuery from '@web/core/hooks/use-query';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { WorkSheetsTable } from './components/worksheets-table';
import { WorkSheetStatuses } from './utils';
import { createUseStyles } from 'react-jss';
import { saveWorksheet, useWorksheetDispatch } from '@packages/contexts/worksheets';
import { storageService } from '@web/services/singletons';
import colors from '@packages/core/styles/colors';
import { WorksheetAction } from './types';
import useWorksheetFormTranslation from './hooks/use-worksheet-form-translation';
import SearchFilter from './components/SearchFilter';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import useFilter from './hooks/use-filter';
import useWorksheetPermission from './hooks/use-worksheet-permission';

const useWorkSheetTabStyle = createUseStyles({
    tabContainer: {
        '& > :first-child': {
            marginRight: '0',
        },
        '& > :last-child': {
            marginLeft: '0',
        },
    },
    activeTab: {
        borderBottom: `2px solid ${colors.blueOne}`,
    },
    tab: {
        textDecoration: 'none',
        padding: '10px 0',
        margin: '0 10px',
        '&>:first-child': {
            marginRight: '0',
        },
        '&>:last-child': {
            marginLeft: '0',
        },
    },
});

export const ManageWorksheets: FC = () => {
    const { dataTranslate } = useWorksheetFormTranslation();
    const navigate = useNavigate();
    const { hasFilterApplied, clearFilter } = useFilter();
    const { toggleOverlay, toggledOverlays } = useContentOverlayState();
    const queryParams = useQuery();
    const searchInputRef = useRef<any>(null);
    const classes = useWorkSheetTabStyle();
    const { pathname, search } = useLocation();
    const worksheetDispatch = useWorksheetDispatch();

    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const quickSearchQuery = queryParams.get('query') || '';
    const [tab, setTab] = useState<WorkSheetStatusEnum>(WorkSheetStatusEnum.All);
    const { isCreateOnlyPermission } = useWorksheetPermission();

    useEffect(() => {
        saveWorksheet({
            worksheet: undefined,
            storageService,
            worksheetDispatch,
        });
    }, []);

    const toggleFilter = useCallback(() => {
        searchInputRef?.current?.hideMenu?.();
        toggleOverlay('worksheetFilter');
    }, [toggleOverlay]);

    const RecentSearchTypeaheadChange = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);
            if (text) {
                params.set('query', text);

                if (!recentSearches.includes(text)) {
                    setRecentSearches([text, ...recentSearches]);
                }
            } else {
                params.delete('query');
            }

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, recentSearches, search]
    );

    return (
        <>
            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <div className="mb-6 d-flex justify-content-between">
                        <Typography variant="h2">Worksheets</Typography>
                    </div>
                    <div className={`${classes.tabContainer} mb-7`}>
                        {WorkSheetStatuses.map((worksheetStatus) => (
                            <span
                                className={`pt-2 pb-2 ml-4 mr-4 ${tab === worksheetStatus.value && classes.activeTab} ${
                                    classes.tab
                                }`}
                                key={worksheetStatus.value}
                                onClick={() => {
                                    setTab(worksheetStatus.value);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <Typography
                                    color={tab === worksheetStatus.value ? 'brandPrimaryDark' : 'grayFive'}
                                    style={{ textTransform: 'capitalize' }}
                                    variant="label"
                                    numberOfLines={1}
                                >
                                    {
                                        dataTranslate.tabs[
                                            worksheetStatus.text.toLowerCase() as keyof typeof dataTranslate.tabs
                                        ].tabLabel
                                    }
                                </Typography>
                            </span>
                        ))}
                    </div>
                    <div className="mb-5 d-flex justify-content-between">
                        <div className="d-flex w-100">
                            <div className="" style={{ width: '31%' }}>
                                <RecentSearchTypeahead
                                    ref={searchInputRef}
                                    onChange={RecentSearchTypeaheadChange}
                                    placeholder={'Enter name'}
                                    defaultInputValue={quickSearchQuery ? quickSearchQuery : ''}
                                    recentSearches={recentSearches}
                                    quickSearchQuery={quickSearchQuery}
                                />
                            </div>
                            <div className="ml-4 d-flex">
                                <Button
                                    active={hasFilterApplied}
                                    variant="ghost-blue"
                                    iconLeft={<Icon color="blueOne" name="filter-list" />}
                                    onPress={toggleFilter}
                                >
                                    {hasFilterApplied
                                        ? dataTranslate.view.actions.filterToggle.active
                                        : dataTranslate.view.actions.filterToggle.inactive}
                                </Button>
                                {hasFilterApplied && (
                                    <div className="ml-1">
                                        <Button variant="ghost-blue" onPress={clearFilter}>
                                            <Icon color="blueOne" name="x-close" />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        {isCreateOnlyPermission && (
                            <div className="ml-auto d-flex">
                                <Button
                                    iconRight={
                                        <Icon
                                            size={16}
                                            raised
                                            name="plus"
                                            raisedContainerStyle={{ width: 22, height: 22 }}
                                        />
                                    }
                                    style={{ marginRight: 40 }}
                                >
                                    <Link
                                        to={`/administration/worksheet/new?action=${WorksheetAction.New}`}
                                        style={{ textDecoration: 'none', color: 'white' }}
                                    >
                                        Create a new worksheet
                                    </Link>
                                </Button>
                            </div>
                        )}
                    </div>
                    <WorkSheetsTable tab={tab} />
                </div>
            </div>
            <SearchFilter onClose={toggleFilter} show={toggledOverlays.worksheetFilter} tab={tab} />
        </>
    );
};
