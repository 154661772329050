import { Button, Typography } from '@packages/ui/shared';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import Select from '@web/components/select';
import colors from '@packages/core/styles/colors';

import { ConditionModel, GroupConditionModel } from '../models';
import { QueryCondition, RemoveButton } from '.';
import { isCondition, isGroupCondition } from '../utils';
import { AdvancedSearchCombinator, AdvancedSearchField } from '@packages/models/api';

const useStyles = createUseStyles({
    removeButton: {
        marginTop: 10,
    },
    queryConditionGroup: {
        display: 'flex',
        marginBottom: 24,
    },
    conditionsOuter: {
        borderRadius: 4,
        marginBottom: 24,
        padding: '24px 24px 0 24px',
        border: `1px dashed ${colors.grayThree}`,
    },
});

interface QueryConditionGroupProps {
    combinators: AdvancedSearchCombinator[];
    fields: AdvancedSearchField[];
    conditionGroup: GroupConditionModel;
    onRemove(id: string): void;
    onCombinatorChange(id: string, value: string): void;
    onAddCondition(id: string): void;
    onAddGroupedCondition(id: string): void;
    onChange(id: string, values: Partial<ConditionModel>): void;
    isFirst?: boolean;
}

export const QueryConditionGroup: FC<QueryConditionGroupProps> = ({
    combinators,
    fields,
    conditionGroup,
    onRemove,
    onCombinatorChange,
    onAddCondition,
    onAddGroupedCondition,
    onChange,
    isFirst,
}) => {
    const classes = useStyles({ isFirst });

    return (
        <div className={classes.queryConditionGroup}>
            {!isFirst && (
                <RemoveButton
                    className={classes.removeButton}
                    onClick={() => {
                        onRemove(conditionGroup.id);
                    }}
                />
            )}

            <div className={isFirst ? '' : 'ml-3'}>
                <div className="mb-6 d-flex align-items-center">
                    <Select
                        value={conditionGroup.combinatorId}
                        onChange={(event) => {
                            onCombinatorChange(conditionGroup.id, event.currentTarget.value);
                        }}
                        options={combinators.map((combinator) => {
                            return { value: combinator.combinatorId, title: combinator.description };
                        })}
                    />
                    <div className="ml-3">
                        <Typography variant="labelRegular">of the following</Typography>
                    </div>
                </div>

                <div className={isFirst ? '' : classes.conditionsOuter}>
                    {conditionGroup.rules.map((q, index) => {
                        const isNotLast = index !== conditionGroup.rules.length - 1;

                        return (
                            <div key={q.id}>
                                {isCondition(q) && (
                                    <QueryCondition
                                        fields={fields}
                                        condition={q}
                                        onRemove={onRemove}
                                        onChange={onChange}
                                    />
                                )}
                                {isGroupCondition(q) && (
                                    <QueryConditionGroup
                                        combinators={combinators}
                                        fields={fields}
                                        conditionGroup={q}
                                        onRemove={onRemove}
                                        onCombinatorChange={onCombinatorChange}
                                        onAddCondition={onAddCondition}
                                        onAddGroupedCondition={onAddGroupedCondition}
                                        onChange={onChange}
                                    />
                                )}
                                {isNotLast && (
                                    <div className="overflow-hidden mb-6">
                                        <Typography variant="label">{conditionGroup.combinatorId}</Typography>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className="d-flex">
                    <Button
                        variant="info"
                        onPress={() => {
                            onAddCondition(conditionGroup.id);
                        }}
                    >
                        Add condition
                    </Button>
                    <Button
                        style={{ marginLeft: 12 }}
                        variant="info"
                        onPress={() => {
                            console.log('Add GroupedCondition to ID', conditionGroup.id);
                            onAddGroupedCondition(conditionGroup.id);
                        }}
                    >
                        Add grouped condition
                    </Button>
                </div>
            </div>
        </div>
    );
};
