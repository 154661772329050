import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';
import { Button, TextBadge, Typography } from '@packages/ui/shared';
import { entitiesService } from '@web/services/singletons';
import { EntityModel } from '@packages/models/api';

type EntityTableRowModal = {
    rowId: string;
    checked: boolean;
} & EntityModel;

export const ManageEntities: FC = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [entities, setEntities] = useState<EntityTableRowModal[]>([]);
    const [totalEntities, setTotalEntities] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            try {
                const response = await entitiesService.getEntities({
                    page: pageIndex,
                    size: pageSize,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const entityTableRows = response.data.entities.map((entity) => {
                    return Object.assign(
                        {
                            rowId: entity.entityId,
                            checked: false,
                        },
                        entity
                    );
                });

                setEntities(entityTableRows);
                setTotalEntities(response.data.totalCount);
            } catch (error) {
                window.alert(error.message);
            }

            setIsLoading(false);
        }

        fetchData();
    }, [pageIndex, pageSize]);

    function handleNewEntityButtonClick() {
        navigate('/administration/entities/new');
    }

    const handleSort = useCallback((sortId: string) => {
        console.log(`TODO: sort table by ${sortId}`);
    }, []);

    const handlePaginationClick = useCallback((index: number) => {
        setPageIndex(index);
    }, []);

    const handlePaginationSizeChange = useCallback((size: number) => {
        setPageSize(size);
    }, []);

    return (
        <div className="pt-7 pl-7 pr-7 d-flex h-100">
            <div className="d-flex w-100 flex-column">
                <div className="mb-6 d-flex justify-content-between">
                    <Typography variant="h2">All Entities</Typography>
                    <Button onPress={handleNewEntityButtonClick}>New Entity</Button>
                </div>

                <TableRenderer<EntityTableRowModal>
                    tableRowsData={entities}
                    tableRowsDataSetter={setEntities}
                    noResultsIconName="administration"
                    tableHeaderRowRenderer={() => {
                        return (
                            <TableRow>
                                <TableHeader
                                    sortable
                                    onSort={(_event, _sortDirection) => {
                                        handleSort('NAME');
                                    }}
                                >
                                    <Typography variant="label">Name</Typography>
                                </TableHeader>
                                <TableHeader
                                    sortable
                                    onSort={(_event, _sortDirection) => {
                                        handleSort('DESCRIPTION');
                                    }}
                                >
                                    <Typography variant="label">Description</Typography>
                                </TableHeader>
                                <TableHeader
                                    sortable
                                    onSort={(_event, _sortDirection) => {
                                        handleSort('VISIBILITY');
                                    }}
                                >
                                    <Typography variant="label">Visibility</Typography>
                                </TableHeader>
                                <TableHeader
                                    sortable
                                    onSort={(_event, _sortDirection) => {
                                        handleSort('ENABLED');
                                    }}
                                >
                                    <Typography variant="label">Enabled</Typography>
                                </TableHeader>
                            </TableRow>
                        );
                    }}
                    tableBodyRowRenderer={(rowData) => {
                        return (
                            <TableRow key={rowData.rowId}>
                                <TableCell>
                                    <Typography numberOfLines={1}>
                                        <Link to={`/administration/entities/${rowData.entityId}`}>{rowData.name}</Link>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{rowData.description}</Typography>
                                </TableCell>
                                <TableCell>
                                    <div className="d-inline-flex">
                                        {rowData.visible ? (
                                            <TextBadge variant="green">Visible</TextBadge>
                                        ) : (
                                            <TextBadge variant="red">Hidden</TextBadge>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="d-inline-flex">
                                        {rowData.active ? (
                                            <TextBadge variant="green">Enabled</TextBadge>
                                        ) : (
                                            <TextBadge variant="default">Disabled</TextBadge>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    }}
                />

                <TablePagination
                    page={pageIndex}
                    size={pageSize}
                    total={totalEntities}
                    onClick={handlePaginationClick}
                    onSizeChange={handlePaginationSizeChange}
                />
            </div>
        </div>
    );
};
