import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Icon, StsIconName, Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { Platform } from 'react-native';
import colors from '@packages/core/styles/colors';
import useTranslationData from '../hooks/useTranslationData';
import { FormElementFile } from '@packages/models/api/form';
import { useSearchParams } from 'react-router-dom';
import { AssetProcessingStateId, WorkSheetStatusEnum } from '@packages/models/api';
import { AssetEditorModal } from '@packages/ui/worksheet';
interface Props {
    onChange?: (data: File | undefined, isOverlay?: boolean) => void;
    iconName: StsIconName;
    formElementFile?: FormElementFile;
    handleClose?: () => void;
    iconDisable?: boolean;
    errorMessage?: string;
    accept?: string;
    fileSizeLimit?: number; // In MB
}
const FilePicker: FC<Props> = ({
    iconName,
    onChange,
    formElementFile,
    handleClose,
    iconDisable,
    errorMessage,
    accept = 'image/*',
    fileSizeLimit,
}) => {
    const classes = useStyleWrapper(errorMessage);
    const inputRef = useRef<HTMLInputElement>(null);

    const [inputValue, setInputValues] = useState<string | undefined>(formElementFile?.name);
    const [showValue, setShowValue] = useState<string | undefined>('');
    const [showEditor, setShowEditor] = useState(false);

    const [searchParams] = useSearchParams();
    const worksheetStatus = (searchParams.get('status') as WorkSheetStatusEnum) || WorkSheetStatusEnum.Pending;
    const { draggableElementsDataTranslate } = useTranslationData();

    const isShowChangeImage = useMemo(() => {
        return inputValue && ![WorkSheetStatusEnum.Published, WorkSheetStatusEnum.Pilot].includes(worksheetStatus);
    }, [inputValue, worksheetStatus]);

    const isUploading = useMemo(() => {
        return formElementFile?.status === AssetProcessingStateId.UPLOADING;
    }, [formElementFile]);

    useEffect(() => {
        if (!formElementFile) {
            return;
        }
        setShowValue(formElementFile.name);
        setInputValues(formElementFile.name);
    }, [formElementFile]);

    const triggerInput = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.showPicker();
        }
    }, []);

    const changeValues = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.currentTarget.files?.[0];
            if (file) {
                if (fileSizeLimit && file.size > fileSizeLimit * 1024 * 1024) {
                    return;
                }
                onChange?.(file);
            }
        },
        [fileSizeLimit, onChange]
    );

    const onEditImage = useCallback(
        (file: File) => {
            onChange?.(file, true);
        },
        [onChange]
    );

    const handleRemoveFile = useCallback(() => {
        handleClose?.();
        setInputValues('');
        onChange?.(undefined);
    }, [handleClose, onChange]);

    return (
        <>
            <div className="d-flex  justify-content-between">
                <div className={`${classes.inputContainer} w-100 d-flex justify-content-between`}>
                    <div className="w-100 d-flex align-contents-center overflow-hidden">
                        {!formElementFile ? (
                            <input
                                value={showValue}
                                readOnly
                                placeholder={draggableElementsDataTranslate.placeholder.files}
                                className={`border-0 ml-3 m-3 ${classes.disabledInput}`}
                            ></input>
                        ) : (
                            <Typography
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxWidth: '50%',
                                    marginLeft: 10,
                                    marginRight: 10,
                                }}
                            >
                                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    {showValue}
                                </span>
                            </Typography>
                        )}
                        {isShowChangeImage && (
                            <Button
                                variant="link"
                                onPress={triggerInput}
                                style={{ height: '100%' }}
                                disabled={isUploading}
                            >
                                <Typography color="blueOne">
                                    - {draggableElementsDataTranslate.buttons.changeImage}
                                </Typography>
                            </Button>
                        )}
                    </div>

                    <div className="d-flex align-contents-center h-100" style={{ position: 'relative' }}>
                        <input
                            type={'file'}
                            ref={inputRef}
                            onChange={changeValues}
                            className="invisible"
                            style={{ width: '10px' }}
                            accept={accept}
                        />

                        {!formElementFile?.url && (
                            <Button
                                disabled={iconDisable}
                                variant="link-header-button"
                                onPress={triggerInput}
                                style={{ marginRight: 10, marginLeft: 50, height: '100%' }}
                            >
                                <Icon name={iconName} color="graySix" style={{ fontWeight: '100' }} />
                            </Button>
                        )}
                    </div>
                </div>

                <div className="ml-3 d-flex">
                    <Button variant="link" onPress={handleRemoveFile} disabled={isUploading}>
                        <Icon name="x-close" color="blueOne" />
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        variant="link"
                        onPress={() => {
                            setShowEditor(true);
                        }}
                        disabled={!formElementFile?.url || isUploading}
                    >
                        <Icon name="pencil" color="blueOne" />
                    </Button>
                </div>
            </div>

            {fileSizeLimit && <Typography color="grayFive">Upload your file (Max size: {fileSizeLimit}MB)</Typography>}
            {errorMessage && (
                <Typography color="redOne" style={{ textAlign: 'center' }}>
                    {errorMessage}
                </Typography>
            )}
            {showEditor && (
                <AssetEditorModal
                    formElementFile={formElementFile!}
                    viewOnly={false}
                    onClose={() => setShowEditor(false)}
                    onSave={onEditImage}
                />
            )}
        </>
    );
};

const useStyleWrapper = createUseStyles((error) => ({
    inputContainer: {
        height: Platform.select({ web: 40, default: 48 }),
        paddingHorizontal: 12,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 3,
        borderWidth: 1,
        borderColor: colors.grayFive,
        border: `1px solid ${error && error !== '' ? colors.redOne : colors.black}`,
    },
    disabledInput: {
        backgroundColor: colors.grayTwo,
        color: colors.grayFour,
        border: `1px solid ${colors.grayFour}`,
    },
    textInput: {
        flex: 1,
        fontSize: 17,
        maxWidth: '100%',
        color: colors.textDarkPrimary,
        textAlignVertical: 'top',
    },
}));

export default FilePicker;
