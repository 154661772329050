import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { find } from 'lodash';
import { useContentOverlayState, useQuery } from '@web/core/hooks';

import { AlertBar, Button, Icon, TextBadge, Typography, useAlert } from '@packages/ui/shared';
import { DetailView, DetailViewBody, DetailViewContent, DetailViewHeader } from '@web/components/detail-view';
import InvestigationDetailPanel from '@web/investigations/components/investigation-detail-panel';
import { investigationService, qmrsService } from '@web/services/singletons';
import AsyncPage from '@web/components/async-page';
import { InvestigationModel, Qmr } from '@packages/models/api';
import {
    SORT_DIRECTION,
    TableActionHeader,
    TableCell,
    TableCheckbox,
    TableHeader,
    TablePagination,
    TableRenderer,
    TableRow,
} from '@web/components/table';
import { useEscalationModal } from '@packages/contexts/escalations';
import { useQmrTableActions } from '@packages/contexts/qmrs';

interface QmrTableRowModel extends Qmr {
    rowId: string;
    checked: boolean;
}

const InvestigationDetails: FC = () => {
    const { t } = useTranslation();
    const { investigationId } = useParams<{ investigationId: string }>();
    const { toggleOverlay, toggledOverlays } = useContentOverlayState();
    const [investigation, setInvestigation] = useState<InvestigationModel>();
    const { showModal: showEscalationModal } = useEscalationModal();

    /* Variables for QMR table */
    const [selectAll, setSelectAll] = useState(false);
    const queryParams = useQuery();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const pageFromQuery = queryParams.get('page');
    const sizeFromQuery = queryParams.get('size');
    const parsedPage = pageFromQuery ? parseInt(pageFromQuery, 10) : 0;
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 10) : 10;
    const parsedSortOrder = queryParams.get('sortOrder');
    const parsedSortDirection = queryParams.get('sortDir');
    const [qmrsLoading, setQmrsLoading] = useState(false);
    const [qmrTableRows, setQmrTableRows] = useState<QmrTableRowModel[]>([]);
    const [selectedQmrs, setSelectedQmrs] = useState<QmrTableRowModel[]>([]);
    const [totalEntries, setTotalEntries] = useState(0);
    const [pageStartCount, setPageStartCount] = useState('0');
    const [pageEndCount, setPageEndCount] = useState('0');
    const [pageTotalCount, setPageTotalCount] = useState('0');

    const [csvExportStarted, setCsvExportStarted] = useState(false);
    const [canEscalateQmr, setCanEscalateQmr] = useState(false);

    const alert = useAlert();

    const { handleActionPress } = useQmrTableActions({
        qmrs: selectedQmrs.length ? selectedQmrs : null,
        alert,
        qmrsService,
    });

    const fetchData = useCallback(async () => {
        if (investigationId) {
            const response = await investigationService.getInvestigationById(investigationId);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setInvestigation(response.data.investigation);
        }
    }, [investigationId]);

    const fetchQmrs = useCallback(async () => {
        setQmrsLoading(true);

        const params = new URLSearchParams(search);

        params.set('page', String(parsedPage));
        params.set('size', String(parsedSize));

        try {
            if (investigationId) {
                const response = await investigationService.getQmrsFromInvestigationById({
                    investigationId: investigationId,
                    searchParams: params.toString(),
                    ignoreCache: true,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const qmrsFormattedAsTableRows = response.data.qmrs.map((qmr) => {
                    return Object.assign(qmr, { rowId: qmr.qmrId, checked: false });
                });

                setQmrTableRows(qmrsFormattedAsTableRows);
                setTotalEntries(response.data.totalCount || 0);
                setPageStartCount(response.data.displayStartCountDescription);
                setPageEndCount(response.data.displayEndCountDescription);
                setPageTotalCount(response.data.totalCountDescription);
            }
        } catch (error) {
            window.alert(error.message);
        }

        setQmrsLoading(false);
    }, [investigationId, parsedPage, parsedSize, search]);

    useEffect(() => {
        fetchQmrs();
    }, [fetchQmrs]);

    useEffect(() => {
        if (selectAll) {
            const _canEscalateQmr = find(selectedQmrs, { 'capabilities.canEscalateQmr': false });
            setCanEscalateQmr(_canEscalateQmr === undefined ? true : _canEscalateQmr ? false : true);
        }
    }, [selectAll, selectedQmrs]);

    const handleExportButtonPress = useCallback(async () => {
        try {
            if (investigationId) {
                await investigationService.exportInvestigationAsCsv({ body: { investigationId } });
                setCsvExportStarted(true);
            }
        } catch (error) {
            window.alert(error.message);
        }
    }, [investigationId]);

    const handleSortChange = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            const params = new URLSearchParams(search);
            params.set('page', String(0));
            params.set('sortOrder', sortBy);
            params.set('sortDir', sortDirection);
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationClick = useCallback(
        (index: number) => {
            setSelectAll(false);
            const params = new URLSearchParams(search);
            params.set('page', String(index));
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            setSelectAll(false);
            const params = new URLSearchParams(search);
            params.set('page', String(0));
            params.set('size', String(size));
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handleRemoveFromInvestigationPress = useCallback(async () => {
        if (
            window.confirm(
                `Are you sure you want to remove ${selectedQmrs.length} QMR(s) from the investigation "${investigation?.title}"?`
            )
        ) {
            setQmrsLoading(true);

            try {
                if (investigationId) {
                    await investigationService.removeQmrsFromInvestigation({
                        investigationId: investigationId,
                        body: {
                            qmrIds: selectedQmrs.map((qmr) => qmr.qmrId),
                        },
                    });

                    window.location.reload();
                }
            } catch (error) {
                setQmrsLoading(false);
                window.alert(error.message);
            }
        }
    }, [investigation?.title, investigationId, selectedQmrs]);

    const handleEscalationPress = useCallback(() => {
        showEscalationModal({
            selectedQmrs: selectedQmrs ? selectedQmrs : [],
        });
    }, [selectedQmrs, showEscalationModal]);

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <AlertBar
                    success
                    show={csvExportStarted}
                    onClose={() => {
                        setCsvExportStarted(false);
                    }}
                >
                    <Typography variant="h5">
                        {t(
                            'alerts:downloadRequest.message',
                            'Request to download QMRs as CSV is in progress. It may take a few minutes. You will receive a notification when it is ready to view.'
                        )}
                    </Typography>
                </AlertBar>

                {toggledOverlays.investigationDetails && investigation && (
                    <InvestigationDetailPanel
                        investigation={investigation}
                        onInvestigationChange={setInvestigation}
                        onClose={() => {
                            toggleOverlay('investigationDetails');
                        }}
                    />
                )}

                <DetailViewHeader
                    breadcrumbs={[
                        {
                            to: '/investigations',
                            title: t('investigations:breadcrumbs.investigations', 'Investigations'),
                        },
                        {
                            to: '/#',
                            title: t('investigations:breadcrumbs.investigationDetails', investigation?.title || ''),
                        },
                    ]}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <TextBadge variant="green" style={{ marginRight: 8 }}>
                                {investigation?.status}
                            </TextBadge>
                            <Typography variant="caption" color="graySix">
                                ID {investigation?.investigationStsId} — Created By {investigation?.createdAccount.name}{' '}
                                on {investigation?.createdTimestampDescription}
                            </Typography>
                        </div>
                        <div className="d-flex align-items-center">
                            {/* <Button
                                variant="ghost-blue"
                                iconLeft={<Icon size={16} name="paperclip" color="blueOne" />}
                                onPress={() => {
                                    return;
                                }}
                                title={t('investigations:header.actions.share', 'Share')}
                            /> */}
                            <Button
                                style={{ marginLeft: 8 }}
                                variant="ghost-blue"
                                iconLeft={<Icon size={16} name="download" color="blueOne" />}
                                onPress={handleExportButtonPress}
                                title={t('investigations:header.actions.export', 'Export')}
                            />
                            <Button
                                style={{ marginLeft: 8 }}
                                variant="success"
                                iconLeft={<Icon size={16} name="menu" color="white" />}
                                onPress={() => {
                                    toggleOverlay('investigationDetails');
                                }}
                                title={t('investigations:header.actions.details', 'Details')}
                            />
                        </div>
                    </div>
                </DetailViewHeader>
                <DetailViewContent topOffset="breadcrumb">
                    <DetailViewBody>
                        <TableRenderer
                            isLoading={qmrsLoading}
                            selectAll={selectAll}
                            selectAllSetter={setSelectAll}
                            onSelectionChange={setSelectedQmrs}
                            tableRowsData={qmrTableRows}
                            tableRowsDataSetter={setQmrTableRows}
                            noResultsTitle={t('views:investigations.noResults', 'No Investigations found')}
                            tableHeaderRowRenderer={(selectAll, onSelectAllChange) => {
                                return (
                                    <TableRow>
                                        <TableHeader fixed hideBorder={!selectAll}>
                                            <TableCheckbox
                                                checked={selectAll}
                                                onChange={onSelectAllChange}
                                                iconName="minus"
                                            />
                                        </TableHeader>
                                        {selectAll ? (
                                            <TableActionHeader colspan={10} selectedCount={selectedQmrs.length}>
                                                <Button
                                                    variant="ghost-blue"
                                                    iconLeft={<Icon name="eye" color="blueOne" size={20} />}
                                                    onPress={handleRemoveFromInvestigationPress}
                                                >
                                                    Remove from Investigation
                                                </Button>
                                                {canEscalateQmr && (
                                                    <Button
                                                        variant="ghost-blue"
                                                        iconLeft={<Icon name="escalation" color="blueOne" size={20} />}
                                                        onPress={() => {
                                                            handleActionPress('ESCALATE');
                                                        }}
                                                    >
                                                        Escalate
                                                    </Button>
                                                )}
                                            </TableActionHeader>
                                        ) : (
                                            <>
                                                <TableHeader
                                                    sortable
                                                    sortDirection={
                                                        parsedSortOrder === 'DATE_CREATED'
                                                            ? (parsedSortDirection as SORT_DIRECTION)
                                                            : undefined
                                                    }
                                                    onSort={(_event, sortDirection) => {
                                                        handleSortChange('DATE_CREATED', sortDirection);
                                                    }}
                                                >
                                                    <Typography variant="label">Date Created</Typography>
                                                </TableHeader>
                                                <TableHeader>
                                                    <Typography variant="label">QMR ID</Typography>
                                                </TableHeader>
                                                <TableHeader>
                                                    <Typography variant="label">VIN</Typography>
                                                </TableHeader>
                                                <TableHeader
                                                    sortable
                                                    sortDirection={
                                                        parsedSortOrder === 'CARLINE'
                                                            ? (parsedSortDirection as SORT_DIRECTION)
                                                            : undefined
                                                    }
                                                    onSort={(_event, sortDirection) => {
                                                        handleSortChange('CARLINE', sortDirection);
                                                    }}
                                                >
                                                    <Typography variant="label">Carline</Typography>
                                                </TableHeader>
                                                <TableHeader
                                                    sortable
                                                    sortDirection={
                                                        parsedSortOrder === 'YEAR'
                                                            ? (parsedSortDirection as SORT_DIRECTION)
                                                            : undefined
                                                    }
                                                    onSort={(_event, sortDirection) => {
                                                        handleSortChange('YEAR', sortDirection);
                                                    }}
                                                >
                                                    <Typography variant="label">Year</Typography>
                                                </TableHeader>
                                                <TableHeader
                                                    sortable
                                                    sortDirection={
                                                        parsedSortOrder === 'FAIL_CODE'
                                                            ? (parsedSortDirection as SORT_DIRECTION)
                                                            : undefined
                                                    }
                                                    onSort={(_event, sortDirection) => {
                                                        handleSortChange('FAIL_CODE', sortDirection);
                                                    }}
                                                >
                                                    <Typography variant="label">Fail Code</Typography>
                                                </TableHeader>
                                                <TableHeader
                                                    sortable
                                                    sortDirection={
                                                        parsedSortOrder === 'SECTION'
                                                            ? (parsedSortDirection as SORT_DIRECTION)
                                                            : undefined
                                                    }
                                                    onSort={(_event, sortDirection) => {
                                                        handleSortChange('SECTION', sortDirection);
                                                    }}
                                                >
                                                    <Typography variant="label">Section</Typography>
                                                </TableHeader>
                                                <TableHeader
                                                    sortable
                                                    sortDirection={
                                                        parsedSortOrder === 'RETAILER'
                                                            ? (parsedSortDirection as SORT_DIRECTION)
                                                            : undefined
                                                    }
                                                    onSort={(_event, sortDirection) => {
                                                        handleSortChange('RETAILER', sortDirection);
                                                    }}
                                                >
                                                    <Typography variant="label">Retailer</Typography>
                                                </TableHeader>
                                            </>
                                        )}
                                    </TableRow>
                                );
                            }}
                            tableBodyRowRenderer={(qmr, rowSelectHandler) => {
                                return (
                                    <TableRow key={qmr.rowId}>
                                        <TableCell fixed classNameOuter="align-middle">
                                            <TableCheckbox
                                                value={qmr.qmrId}
                                                checked={qmr.checked}
                                                onChange={rowSelectHandler}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography numberOfLines={1}>{qmr.createdTimestampDescription}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography numberOfLines={1}>
                                                <a
                                                    href={`/qmrs/${qmr.displayIdentifier}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {qmr.displayIdentifier}
                                                </a>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography numberOfLines={1}>{qmr.vin}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography numberOfLines={1}>{qmr.carlineName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography numberOfLines={1}>{qmr.modelYear}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography numberOfLines={1}>{qmr.failCode}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {qmr.failCodeSectionName && (
                                                <div className={qmr.failCodeSubsectionName ? 'mb-1' : 'mb-0'}>
                                                    <Typography numberOfLines={1} variant="caption" color="graySix">
                                                        {qmr.failCodeSectionName}
                                                    </Typography>
                                                </div>
                                            )}
                                            {qmr.failCodeSubsectionName && (
                                                <div>
                                                    <Typography numberOfLines={1} variant="caption" color="graySix">
                                                        {qmr.failCodeSubsectionName}
                                                    </Typography>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Typography numberOfLines={1}>{qmr.retailer?.name}</Typography>
                                            <div>
                                                <Typography variant="caption" color="textDarkSecondary">
                                                    &#35;{qmr.retailer?.code}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            }}
                        />
                        <TablePagination
                            page={parsedPage}
                            size={parsedSize}
                            total={totalEntries}
                            onClick={handlePaginationClick}
                            onSizeChange={handlePaginationSizeChange}
                            displayCount={`${pageStartCount}-${pageEndCount} of ${pageTotalCount}`}
                        />
                    </DetailViewBody>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};

export default InvestigationDetails;
