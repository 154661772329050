import * as React from 'react';
import { action, on, props, reducer, union } from 'ts-action';
import { baseWorksheet } from './worksheets.context';
import { WorksheetForm } from '@packages/models/api';

export enum worksheetsActionsList {
    GET_WORKSHEETS = 'GET_WORKSHEETS',
    UPDATE_WORKSHEETS = 'UPDATE_WORKSHEETS',
    DELETE_WORKSHEETS = 'DELETE_WORKSHEETS',
    CREATE_WORKSHEETS = 'CREATE_WORKSHEETS',
    GET_WORKSHEETS_UPDATE = 'GET_WORKSHEETS_UPDATE',
    UPDATE_WORKSHEETS_UPDATE = 'UPDATE_WORKSHEETS_UPDATE',
    DELETE_WORKSHEETS_UPDATE = 'DELETE_WORKSHEETS_UPDATE',
    CREATE_WORKSHEETS_UPDATE = 'CREATE_WORKSHEETS_UPDATE',
    SET_WORKSHEET = 'SET_WORKSHEET',
    HAS_FETCHED = 'HAS_FETCHED',
    IS_LOADING = 'IS_LOADING',
    HAS_LOADED = 'HAS_LOADED',
    HAS_ERRORS = 'HAS_ERRORS',
}

export interface IWorksheetsUpdates {
    worksheetsUpdateId: string;
    heading: string;
    description: string;
    link: string;
    deleted: boolean;
    type: string;
}

export interface IWorksheetsBanner {
    worksheetsId: string;
    deleted: boolean;
    bannerHeader: string;
    bannerMessage: string;
    bannerLink: string;
}

export interface IWorksheetsProps {
    worksheets: WorksheetForm;
}

export interface IWorksheetsUpdateProps {
    worksheetsUpdates: IWorksheetsUpdates[];
}
export interface IWorksheetsResponse {
    worksheets: {
        worksheets: WorksheetForm;
        worksheetsUpdates: IWorksheetsUpdates[];
    };
}

export interface IWorksheetsRequest {
    worksheets: Omit<WorksheetForm, 'worksheetsId'>;
    worksheetsUpdates: Omit<IWorksheetsUpdates, 'worksheetsUpdateId'>[];
}

export interface IWorksheetsUpdateRequest {
    worksheets: Partial<WorksheetForm>;
}

export interface IWorksheetsCreateRequest extends IWorksheetsUpdateRequest {}

export interface IWorksheetsState {
    worksheet: WorksheetForm | undefined;
    loading: boolean;
    errors: string[];
    hasFetched: boolean;
    worksheetsUpdates: IWorksheetsUpdates[];
}

export const worksheetsActions = {
    hasFetched: action(worksheetsActionsList.HAS_FETCHED),
    isLoading: action(worksheetsActionsList.IS_LOADING),
    hasLoaded: action(worksheetsActionsList.HAS_LOADED),
    hasErrors: action(worksheetsActionsList.HAS_ERRORS, props<{ errors: string[] }>()),
    setWorksheet: action(worksheetsActionsList.SET_WORKSHEET, props<{ worksheet: IWorksheetsState['worksheet'] }>()),
    getWorksheets: action(worksheetsActionsList.GET_WORKSHEETS, props<IWorksheetsProps>()),
};

const actionsUnion = union(...Object.values(worksheetsActions));
export type WorksheetsActions = typeof actionsUnion.actions;

export type WorksheetsActionDispatcher = React.Dispatch<WorksheetsActions>;

export const initialState: IWorksheetsState = {
    worksheet: undefined,
    loading: false,
    hasFetched: false,
    errors: [],
    worksheetsUpdates: [],
};

export const worksheetsReducer: React.Reducer<IWorksheetsState, WorksheetsActions> = reducer(
    initialState,
    on(worksheetsActions.hasFetched, (state) => {
        return {
            ...state,
            hasFetched: true,
        };
    }),
    on(worksheetsActions.isLoading, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(worksheetsActions.hasLoaded, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(worksheetsActions.hasErrors, (state, { errors }) => {
        return {
            ...state,
            errors,
        };
    }),
    on(worksheetsActions.setWorksheet, (state, { worksheet }) => {
        return {
            ...state,
            worksheet,
        };
    }),
    on(worksheetsActions.getWorksheets, (state, { worksheets }) => {
        return {
            ...state,
            worksheets,
            errors: [],
        };
    })
);
