import colors from '@packages/core/styles/colors';
import { Button, ButtonVariant, Icon, Typography } from '@packages/ui/shared';
import React, { FC, useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Popover } from 'react-tiny-popover';
import { getPopoverContentLocation } from '../../utils/popover-content-location';

interface CustomComponentProps extends React.ComponentPropsWithoutRef<'div'> {
    onClick(): void;
    variant: ButtonVariant;
    label: string;
    isLoading: boolean;
}

const DropdownCustomButton = React.forwardRef<HTMLDivElement, CustomComponentProps>((props, ref) => (
    <div ref={ref}>
        <Button
            variant={props.variant}
            iconRight={<Icon color="white" name="arrow-small-down" />}
            onPress={props.onClick}
            isLoading={props.isLoading}
        >
            {props.label}
        </Button>
    </div>
));

const useStyles = createUseStyles({
    actionsPopover: {
        minWidth: 200,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        borderRadius: 4,
        boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.16)`,
    },
    popoverActionsSection: {
        padding: '4px 0',
    },
    dropdownButton: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        width: '100%',
        borderRadius: 3,
        cursor: 'pointer',
        padding: '0 12px',
        appearance: 'none',
        color: colors.blueOne,
        textAlign: 'left',
        backgroundColor: colors.white,
        border: `1px solid ${colors.blueOne}`,
    },
    dropdownButtonIcon: {
        marginLeft: '5px',
        marginTop: '6px',
    },
});

export enum CustomDropdownButtonType {
    primary,
}

interface CustomDropdownButton {
    type: CustomDropdownButtonType;
    variant: ButtonVariant;
}

interface DropdownOption {
    id: string;
    label: string;
    disabled?: boolean;
}

interface DropdownProps {
    label: string;
    options: DropdownOption[];
    onPress: (key: string) => void;
    customDropdownButton?: CustomDropdownButton;
    isLoading: boolean;
}

interface DropdownItemProps {
    onPress: Function;
    label: string;
    disabled?: boolean;
}

const DropdownItem: FC<DropdownItemProps> = ({ onPress, label, disabled = false }) => {
    return (
        <Button alignTitle="left" variant="ghost-gray" onPress={() => onPress()} disabled={disabled}>
            <Typography variant="labelRegular">{label}</Typography>
        </Button>
    );
};

export const Dropdown: FC<DropdownProps> = ({ label, options, onPress, customDropdownButton, isLoading = false }) => {
    const classes = useStyles();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const renderContent = useCallback(() => {
        return (
            <div className={classes.popoverActionsSection}>
                {options.map((option) => (
                    <DropdownItem
                        key={option.id}
                        onPress={() => {
                            setIsPopoverOpen(false);
                            onPress(option.id);
                        }}
                        label={option.label}
                        disabled={option.disabled}
                    />
                ))}
            </div>
        );
    }, [classes.popoverActionsSection, options, onPress]);

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={['bottom', 'top']}
            content={renderContent()}
            containerClassName={classes.actionsPopover}
            onClickOutside={() => setIsPopoverOpen(false)}
            contentLocation={({ childRect, popoverRect, position }) => {
                return getPopoverContentLocation(childRect, popoverRect, position, 4);
            }}
        >
            {customDropdownButton?.type === CustomDropdownButtonType.primary ? (
                <DropdownCustomButton
                    variant={customDropdownButton.variant}
                    label={label}
                    onClick={() => togglePopover()}
                    isLoading={isLoading}
                />
            ) : (
                <button className={classes.dropdownButton} onClick={togglePopover}>
                    {label}
                    <span className={classes.dropdownButtonIcon}>
                        <Icon name="arrow-small-down" color="blueOne" />
                    </span>
                </button>
            )}
        </Popover>
    );
};
