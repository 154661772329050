// adapted from
// https://github.com/rafrex/react-router-hash-link/blob/master/src/index.js

let elementId: string | null = null;
let observer: MutationObserver | null = null;
let asyncTimerId: number | null = null;

function reset() {
    if (observer !== null) {
        observer.disconnect();
        observer = null;
    }

    if (asyncTimerId !== null) {
        window.clearTimeout(asyncTimerId);
        asyncTimerId = null;
    }
}

function getElAndScroll() {
    if (!elementId) {
        return false;
    }

    const element = document.getElementById(elementId);

    if (element !== null) {
        element.scrollIntoView({ behavior: 'smooth' });
        reset();
        return true;
    }

    return false;
}

export const scrollIntoView = (id: string) => {
    window.setTimeout(() => {
        elementId = id;
        if (!getElAndScroll()) {
            if (observer === null) {
                observer = new MutationObserver(getElAndScroll);
            }

            observer.observe(document, {
                attributes: true,
                childList: true,
                subtree: true,
            });

            asyncTimerId = window.setTimeout(() => {
                reset();
            }, 10000);
        }
    }, 150);
};
