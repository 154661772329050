import { useAbortController } from '@packages/core/http';
import colors from '@packages/core/styles/colors';
import { Account, InvestigationStatus } from '@packages/models/api';
import { Button, Icon, Typography } from '@packages/ui/shared';
import useQuery from '@web/core/hooks/use-query';
import { investigationService } from '@web/services/singletons';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    filterHeader: {
        textTransform: 'uppercase',
        margin: '16px 24px',
    },
    filterContent: {
        margin: '0 24px 16px',
    },
    hr: {
        margin: '0 24px',
    },
});

type Props = {
    hasActiveFilters: boolean;
    onClose: () => void;
    onClear: () => void;
};

const InvestigationsQuickFiltersPanel = (props: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const currentQueryParams = useQuery();
    const navigate = useNavigate();
    const { abortSignalRef } = useAbortController();

    const [selectedStatuses, setSelectedStatuses] = useState<InvestigationStatus[]>([]);
    const [selectedCreators, setSelectedCreators] = useState<Account[]>([]);

    const [statuses, setStatuses] = useState<InvestigationStatus[]>([]);
    const [creators, setCreators] = useState<Account[]>([]);

    useEffect(() => {
        investigationService.getInvestigationStatuses(false, abortSignalRef.current).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setStatuses(response.data.statuses);
            const statusesFromParams = currentQueryParams.getAll('statuses').map((s) => parseInt(s));
            setSelectedStatuses(
                response.data.statuses.filter((s) => statusesFromParams.includes(s.investigationStatusId as any))
            );
        });

        investigationService.getCreators(false, abortSignalRef.current).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setCreators(response.data.investigationCreators);
            const creatorsFromParams = currentQueryParams.getAll('creators');
            setSelectedCreators(
                response.data.investigationCreators.filter((a) => creatorsFromParams.includes(a.accountId))
            );
        });
    }, [abortSignalRef, currentQueryParams, t]);

    const rightPanelDiv = document.getElementById('right-side-panel');

    if (!rightPanelDiv) {
        return null;
    }

    const divider = <hr className={classes.hr} />;

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <div className={classes.panelHeader}>
                <Typography>{t('investigation:quickFilters.title', 'Filters')}</Typography>

                <div className={classes.closeButton} onClick={props.onClose}>
                    <Icon name="x-close" />
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                <div className={classes.filterHeader}>
                    <Typography variant="caption2">
                        {t('investigation:quickFilters.statuses.label', 'Status')}
                    </Typography>
                </div>

                <div className={classes.filterContent}>
                    <Typeahead
                        id="status-async-typeahead"
                        placeholder="Select Status(es)..."
                        labelKey="name"
                        multiple
                        selected={selectedStatuses}
                        onChange={(selections) => {
                            setSelectedStatuses(selections);
                        }}
                        options={statuses}
                    />
                </div>

                {divider}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{t('investigation:quickFilters.creators', 'Creator')}</Typography>
                </div>

                <div className={classes.filterContent}>
                    <Typeahead
                        id="creator-async-typeahead"
                        placeholder="Select creator(s)..."
                        labelKey="name"
                        multiple
                        selected={selectedCreators}
                        onChange={(selections) => {
                            setSelectedCreators(selections);
                        }}
                        options={creators}
                    />
                </div>

                {divider}

                <div className={classes.filterContent}>
                    <div className="mt-4 d-flex justify-content-between">
                        <Button
                            onPress={() => {
                                const newQueryParams = new URLSearchParams();
                                const currentSize = currentQueryParams.get('size');
                                const currentQuery = currentQueryParams.get('query');

                                if (currentSize) {
                                    newQueryParams.set('size', currentSize);
                                }

                                if (currentQuery) {
                                    newQueryParams.set('query', currentQuery);
                                }

                                for (const status of selectedStatuses) {
                                    newQueryParams.append('statuses', status.investigationStatusId);
                                }

                                for (const creator of selectedCreators) {
                                    newQueryParams.append('creators', creator.accountId);
                                }

                                navigate(`/investigations?${newQueryParams.toString()}`);
                                props.onClose();
                            }}
                        >
                            {t('investigation:quickFilters.actions.apply', 'Apply')}
                        </Button>

                        {props.hasActiveFilters && (
                            <Button
                                variant="ghost-blue"
                                onPress={() => {
                                    props.onClear();
                                    props.onClose();
                                }}
                            >
                                {t('investigation:quickFilters.actions.clear', 'Clear All')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>,
        rightPanelDiv
    );
};

export default InvestigationsQuickFiltersPanel;
