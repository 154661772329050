import React, { FC, useCallback, useEffect, useState } from 'react';
import { Icon, TextInputHelper, Typography } from '@packages/ui/shared';
import useTranslationData from './hooks/useTranslationData';
import FilePicker from './inputPickers/FilePicker';
import { FormElementFile } from '@packages/models/api/form';

interface FormWorksheetEditFileFieldsProps {
    onChange({ formElementFile }: { formElementFile?: FormElementFile }): void;
    formElementFile?: FormElementFile;
    title?: string;
    handleClose?: () => void;
    accept?: string;
    fileSizeLimit?: number; // In MB
}
export const FileField: FC<FormWorksheetEditFileFieldsProps> = ({
    formElementFile,
    accept,
    fileSizeLimit,
    onChange,
    handleClose,
}) => {
    const [eye, setEye] = useState(true);
    const { draggableElementsDataTranslate } = useTranslationData();

    useEffect(() => {
        if (formElementFile?.caption === '') {
            setEye(false);
        }
    }, []);

    const handleLabelChangeText = useCallback(
        (caption: string) => {
            caption === '' ? setEye(false) : setEye(true);
            onChange({ formElementFile: { ...formElementFile, caption } });
        },
        [formElementFile, onChange]
    );

    const handleChangeFile = useCallback(
        (file: File | undefined, isOverlay: boolean = false) => {
            if (file) {
                const fileUrl = URL.createObjectURL(file);
                onChange({
                    formElementFile: {
                        ...formElementFile,
                        ...(isOverlay
                            ? { overlay: { file, url: fileUrl, name: file.name } }
                            : { file, url: fileUrl, name: file.name }),
                    },
                });
            } else {
                onChange({ formElementFile: { ...formElementFile, file, url: undefined, name: '' } });
            }
        },
        [formElementFile, onChange]
    );

    return (
        <>
            <div className="mb-2">
                <Typography variant="label">{draggableElementsDataTranslate.labels.image}</Typography>
            </div>
            <div className="d-flex">
                <div className="w-100">
                    <FilePicker
                        onChange={handleChangeFile}
                        iconName={'plus'}
                        formElementFile={formElementFile}
                        handleClose={handleClose}
                        accept={accept}
                        fileSizeLimit={fileSizeLimit}
                    />
                </div>
            </div>
            <div className="mt-2 mb-3">
                <div>
                    <TextInputHelper
                        value={formElementFile?.caption || ''}
                        onChangeText={(caption) => handleLabelChangeText(caption)}
                        placeholder={draggableElementsDataTranslate.placeholder.imageCaption}
                        rightIcon={<Icon name={eye ? 'eye' : 'eye-off'} color={eye ? 'black' : 'grayFour'} />}
                    />
                </div>
            </div>
        </>
    );
};
