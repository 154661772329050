export function paramsToObject(search: string) {
    const searchParams = new URLSearchParams(search);
    const result: Record<string, string> = {};

    for (const [key, value] of searchParams) {
        result[key] = value;
    }

    return result;
}

export function objectToParams(searchObject: Record<string, string>) {
    const result = Object.keys(searchObject)
        .map((key) => `${key}=${searchObject[key]}`)
        .join('&');

    return result;
}

export function constructUrl(baseUrl: string, searchObject: Record<string, string>) {
    const searchString = objectToParams(searchObject);
    return `${baseUrl}?${searchString}`;
}
