import { Comment } from '@packages/models/api';
import React, { FC } from 'react';
import { BaseComment } from '../shared';

interface Props {
    comment: Comment;
    hideAuthor?: boolean;
    authorTextVariant?: 'default' | 'bold';
    commentOptionsRenderer?: (comment: Comment) => JSX.Element | null;
    editCommentRenderer?: (comment: Comment) => JSX.Element;
}

export const QmrComment: FC<Props> = (props) => {
    return (
        <BaseComment
            {...props}
            authorName={props.comment.createdAccount?.name}
            timestamp={props.comment.createdTimestampDescription}
            body={props.comment.body}
        />
    );
};
