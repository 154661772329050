import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { format, parse, isAfter, isBefore, isValid } from 'date-fns';

import { useQmrDispatch, useQmrState, patchQmr } from '@packages/contexts/qmrs';
import { useAbortController } from '@packages/core/http';
import { TextareaHelper, TextInputHelper, Button, Label, Icon } from '@packages/ui/shared';
import { CorrectionInfoPopup } from '@packages/ui/qmr/popups';
import { httpClient, qmrsService } from '@web/services/singletons';
import { PatchQmrDto } from '@packages/models/api';

const CorrectionDraft: FC = () => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');

    const [didInit, setDidInit] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [correction, setCorrection] = useState(qmr?.correction ?? '');
    const [correctionError, setCorrectionError] = useState('');
    const [isResolved, setIsResolved] = useState<boolean | null>(null);
    const [repairDate, setRepairDate] = useState(qmr?.createdTimestamp ?? '');

    const parsedFailureDate = qmr?.failureDate ? parse(qmr?.failureDate, 'yyyy-MM-dd', new Date()) : undefined;
    const minRepairDate = parsedFailureDate && format(parsedFailureDate, 'yyyy-MM-dd');
    const minRepairDateDisplay = parsedFailureDate && format(parsedFailureDate, 'MM/dd/yyyy');
    const maxRepairDate = format(new Date(), 'yyyy-MM-dd');
    const maxRepairDateDisplay = format(new Date(), 'MM/dd/yyyy');
    const [dateError, setDateError] = useState('');

    const saveChanges = useCallback(
        (qmrPatch: PatchQmrDto) => {
            if (!qmr) {
                return;
            }

            patchQmr({
                qmrId: qmr.qmrId,
                qmrsService,
                qmrDispatch,
                signal: abortSignalRef.current,
                qmrPatch,
                isLocalPatch: !!editRouteMatch,
            }).catch((e) => {
                alert(e.message);
            });
        },
        [abortSignalRef, editRouteMatch, qmr, qmrDispatch]
    );

    const timeoutRefs = useRef<{ [k: string]: NodeJS.Timeout }>({});
    useEffect(() => {
        if (timeoutRefs.current.correction) {
            clearTimeout(timeoutRefs.current.correction);
        }

        if ((!qmr?.correction && !correction) || qmr?.correction === correction) {
            return;
        }

        timeoutRefs.current.correction = global.setTimeout(() => {
            saveChanges({ correction });
        }, 60000);
    }, [saveChanges, correction, qmr?.correction]);

    useEffect(() => {
        if (timeoutRefs.current.repairDate) {
            clearTimeout(timeoutRefs.current.repairDate);
        }

        if ((!qmr?.repairDate && !repairDate) || qmr?.repairDate === repairDate) {
            return;
        }

        timeoutRefs.current.repairDate = global.setTimeout(() => {
            if (!isValid(parse(repairDate, 'yyyy-MM-dd', new Date()))) {
                saveChanges({ repairDate: null });
                return;
            }

            saveChanges({ repairDate });
        }, 60000);
    }, [saveChanges, repairDate, qmr?.repairDate]);

    useEffect(() => {
        if (!qmr || didInit) {
            return;
        }

        setDidInit(true);

        setCorrection(qmr.correction || '');
        setIsResolved(typeof qmr.issueResolved === 'boolean' ? qmr.issueResolved : null);
        setRepairDate(qmr.repairDate || '');
    }, [didInit, qmr]);

    useEffect(() => {
        const parsed = parse(repairDate, 'yyyy-MM-dd', new Date());

        if (isValid(parsed)) {
            if (parsedFailureDate && isBefore(parsed, parsedFailureDate)) {
                setDateError(`Must be on or after ${minRepairDateDisplay}`);
                return;
            }

            if (isAfter(parsed, new Date())) {
                setDateError(`Must be on or before ${maxRepairDateDisplay}`);
                return;
            }

            setDateError('');
        }
    }, [maxRepairDateDisplay, minRepairDateDisplay, parsedFailureDate, repairDate]);

    return (
        <>
            <CorrectionInfoPopup
                show={showInfoPopup}
                onOkPress={() => setShowInfoPopup(false)}
                onModalHide={() => setShowInfoPopup(false)}
            />

            <h4 className="mb-4">{t('qmr:sections.correction.title')}</h4>

            {!qmr?.importedFromSiebel && (
                <>
                    <Form.Group className="mb-6">
                        <div className="mb-3">
                            <Label required>{t('qmr:inputs.issueResolved.label')}</Label>
                        </div>

                        <Form.Check
                            disabled={!!editRouteMatch}
                            className="mb-3"
                            label={t('text:common.yes')}
                            type="radio"
                            id="issue-resolved-yes"
                            name="issue-resolved"
                            checked={!!isResolved}
                            onChange={() => {
                                setIsResolved(true);
                                saveChanges({
                                    issueResolved: true,
                                });
                            }}
                        />

                        <Form.Check
                            disabled={!!editRouteMatch}
                            label={t('text:common.no')}
                            type="radio"
                            id="issue-resolved-no"
                            name="issue-resolved"
                            checked={isResolved === false}
                            onChange={() => {
                                setIsResolved(false);
                                saveChanges({
                                    issueResolved: false,
                                });
                            }}
                        />
                    </Form.Group>

                    <Row className="mb-6">
                        <Col xs={6}>
                            <TextInputHelper
                                controlId="qmr-repairDate"
                                required
                                type="date"
                                label={t('qmr:inputs.repairDate.label')}
                                value={repairDate}
                                minDate={minRepairDate}
                                maxDate={maxRepairDate}
                                onChangeText={(v) => {
                                    setRepairDate(v);
                                }}
                                onFocus={() => {
                                    const parsed = parse(repairDate, 'yyyy-MM-dd', new Date());
                                    if (!isValid(parsed))
                                        setDateError('Please use the date picker or use this date format: MM/DD/YYYY');
                                }}
                                onBlur={() => {
                                    if (!qmr) {
                                        return;
                                    }

                                    if (qmr.repairDate === repairDate) {
                                        return;
                                    }

                                    const parsed = parse(repairDate, 'yyyy-MM-dd', new Date());
                                    if (isValid(parsed)) {
                                        const qmrPatch = { repairDate };
                                        patchQmr({
                                            qmrId: qmr.qmrId,
                                            qmrsService,
                                            qmrDispatch,
                                            isLocalPatch: true,
                                            qmrPatch,
                                        });
                                        saveChanges(qmrPatch);
                                    } else if (!isValid(parsed)) {
                                        setDateError('');
                                    }
                                }}
                                errorMessage={dateError}
                            />
                        </Col>
                    </Row>
                </>
            )}

            <div className="mb-6">
                <TextareaHelper
                    editable={!!editRouteMatch ? qmr?.capabilities.editCccOnQmr : true}
                    controlId="qmr-correction"
                    value={correction}
                    label={t('qmr:inputs.correction.label')}
                    labelRightElement={
                        <Button
                            variant="ghost-blue"
                            title={t('qmr:inputs.correction.hint')}
                            iconLeft={<Icon name="info" color="blueOne" />}
                            onPress={() => setShowInfoPopup(true)}
                        />
                    }
                    required
                    placeholder={t('qmr:inputs.correction.placeholder')}
                    errorMessage={correctionError}
                    onChangeText={(value) => {
                        httpClient.refreshToken();
                        setCorrection(value);
                        setCorrectionError(value.trim().length ? '' : t('qmr:inputs.correction.errorMessage'));

                        // Make sure when it is empty, we mark it definitively as empty and strictly do not allow empty submissions.
                        if (!value.trim().length) {
                            if (!qmr) {
                                return;
                            }

                            const qmrPatch = { correction: value };
                            patchQmr({
                                qmrId: qmr.qmrId,
                                qmrsService,
                                qmrDispatch,
                                isLocalPatch: true,
                                qmrPatch,
                            });
                            saveChanges(qmrPatch);
                        }
                    }}
                    onBlur={() => {
                        if (!qmr) {
                            return;
                        }

                        if (qmr.correction !== correction) {
                            const qmrPatch = { correction };
                            patchQmr({
                                qmrId: qmr.qmrId,
                                qmrsService,
                                qmrDispatch,
                                isLocalPatch: true,
                                qmrPatch,
                            });
                            saveChanges(qmrPatch);
                        }
                    }}
                />
            </div>
        </>
    );
};

export default CorrectionDraft;
