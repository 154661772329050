import { cloneDeep } from 'lodash';
import React, { FC, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { Button, Label, TextInputHelper, Toggle, Typography } from '@packages/ui/shared';
import {
    DetailView,
    DetailViewAside,
    DetailViewBody,
    DetailViewContent,
    DetailViewHeader,
} from '@web/components/detail-view';
import { Chip } from '@web/administration/components';

import { accountsService, entitiesService } from '@web/services/singletons';
import { Account, EntityModel } from '@packages/models/api';
import AsyncPage from '@web/components/async-page';

export const EntityForm: FC = () => {
    const navigate = useNavigate();

    const { entityId } = useParams<{ entityId?: string }>();
    const [displayNameValue, setDisplayNameValue] = useState('New Entity');
    const [nameInputValue, setNameInputValue] = useState('');
    const [descriptionInputValue, setDescriptionInputValue] = useState('');
    const [parentEntity, setParentEntity] = useState<EntityModel>();
    const [managers, setManagers] = useState<Account[]>([]);
    const [visibleToggleValue, setVisibleToggleValue] = useState(false);
    const [enabledToggleValue, setEnabledToggleValue] = useState(false);

    const fetchData = useCallback(async () => {
        if (!entityId) {
            return;
        }

        const response = await entitiesService.getEntity(entityId);

        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            throw response.data;
        }

        setDisplayNameValue(response.data.entity.name);
        setNameInputValue(response.data.entity.name);
        setDescriptionInputValue(response.data.entity.description);
        setManagers(response.data.entity.members);
        if (response.data.entity.parentEntity) {
            setParentEntity(response.data.entity.parentEntity as EntityModel);
        }
        setVisibleToggleValue(response.data.entity.visible);
        setEnabledToggleValue(response.data.entity.active);
    }, [entityId]);

    function handleCancelButtonPress() {
        navigate(-1);
    }

    async function handleSaveButtonPress() {
        try {
            const requestBody = {
                name: nameInputValue,
                description: descriptionInputValue,
                members: managers.map((manager) => manager.accountId),
                visible: visibleToggleValue,
                active: enabledToggleValue,
                ...(parentEntity ? { parentEntityId: parentEntity.entityId } : {}),
            };

            const response = entityId
                ? await entitiesService.updateEntity(entityId, requestBody)
                : await entitiesService.createEntity(requestBody);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            window.alert(entityId ? 'Entity was updated.' : 'Entity was created.');
            navigate(-1);
        } catch (error) {
            window.alert(error.message);
        }
    }

    /* ------------------------------------------------- */
    /* Managers Typeahead */
    /* ------------------------------------------------- */
    const managersTypeaheadRef = useRef<AsyncTypeahead<Account> | null>(null);
    const [managersTypeaheadIsLoading, setManagersTypeaheadIsLoading] = useState(false);
    const [managersTypeheadOptions, setManagersTypeaheadOptions] = useState<Account[]>([]);

    const handleManagersTypeaheadSearch = useCallback(
        async (query: string) => {
            setManagersTypeaheadIsLoading(true);

            try {
                const response = await accountsService.getAccounts({
                    query,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const managersToShow = response.data.accounts.filter((account) => {
                    const matchFound = !!managers.find((manager) => manager.accountId === account.accountId);

                    if (matchFound) {
                        return false;
                    } else {
                        return true;
                    }
                });

                setManagersTypeaheadOptions(managersToShow);
            } catch (error) {
                setManagersTypeaheadOptions([]);
                window.alert(error.message);
            }

            setManagersTypeaheadIsLoading(false);
        },
        [managers]
    );

    const handleManagersTypeaheadChange = useCallback(
        ([account]: Account[]) => {
            if (!account) {
                return;
            }

            const managersClone = cloneDeep(managers);

            if (managersTypeaheadRef.current) {
                // @ts-ignore
                managersTypeaheadRef.current.clear();
            }

            managersClone.push(account);
            setManagers(managersClone);
        },
        [managers]
    );

    const handleManagerChipRemove = useCallback(
        (account: Account) => {
            const managerIndexToRemove = managers.findIndex((manager) => manager.accountId === account.accountId);
            const managersClone = cloneDeep(managers);

            managersClone.splice(managerIndexToRemove, 1);
            setManagers(managersClone);
        },
        [managers]
    );

    /* ------------------------------------------------- */
    /* Parent Entites Typeahead */
    /* ------------------------------------------------- */
    const parentEntityTypeaheadRef = useRef<AsyncTypeahead<EntityModel> | null>(null);
    const [parentEntityTypeaheadIsLoading, setParentEntityTypeaheadIsLoading] = useState(false);
    const [parentEntityTypeaheadOptions, setParentEntityTypeaheadOptions] = useState<EntityModel[]>([]);

    const handleParentEntityTypeaheadSearch = useCallback(
        async (query: string) => {
            setParentEntityTypeaheadIsLoading(true);

            try {
                try {
                    const response = await entitiesService.getEntities({
                        query,
                    });

                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }

                    const entitiesToShow = response.data.entities.filter((entity) => {
                        return entityId !== entity.entityId;
                    });

                    setParentEntityTypeaheadOptions(entitiesToShow);
                } catch (error) {
                    setParentEntityTypeaheadOptions([]);
                    window.alert(error.message);
                }
            } catch (error) {
                window.alert(error.message);
            }

            setParentEntityTypeaheadIsLoading(false);
        },
        [entityId]
    );

    const handleParentEntityTypeaheadChange = useCallback(([SelectedParentEntity]: EntityModel[]) => {
        if (!SelectedParentEntity) {
            return;
        }

        if (parentEntityTypeaheadRef.current) {
            // @ts-ignore
            parentEntityTypeaheadRef.current.clear();
        }

        setParentEntity(SelectedParentEntity);
    }, []);

    const handleParentEntityChipRemove = useCallback(() => {
        setParentEntity(undefined);
    }, []);

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <DetailViewHeader
                    breadcrumbs={
                        entityId
                            ? [
                                  { to: '/administration/entities', title: 'Manage Entities' },
                                  {
                                      to: `/administration/entities/${entityId}`,
                                      title: 'Entity',
                                  },
                                  {
                                      to: `/administration/entities/${entityId}/edit`,
                                      title: 'Edit',
                                  },
                              ]
                            : undefined
                    }
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <Typography variant="h3">{displayNameValue}</Typography>
                        <div className="d-flex">
                            <Button variant="ghost-blue" onPress={handleCancelButtonPress}>
                                Cancel
                            </Button>
                            <Button style={{ marginLeft: 8 }} onPress={handleSaveButtonPress}>
                                Save Entity
                            </Button>
                        </div>
                    </div>
                </DetailViewHeader>
                <DetailViewContent topOffset={entityId ? 'breadcrumb' : 'header'}>
                    <DetailViewBody>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4">Entity Details</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={6}>
                                <Form.Group>
                                    <TextInputHelper
                                        required
                                        label="Entity Name"
                                        placeholder="Name this entity"
                                        value={nameInputValue}
                                        onChange={(event) => {
                                            setNameInputValue(event.nativeEvent.text);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group>
                                    <TextInputHelper
                                        required
                                        label="Description"
                                        placeholder="Describe this entity"
                                        value={descriptionInputValue}
                                        onChange={(event) => {
                                            setDescriptionInputValue(event.nativeEvent.text);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Label>Managers</Label>
                                    <AsyncTypeahead
                                        ref={managersTypeaheadRef}
                                        id="managers-async-typeahead"
                                        minLength={1}
                                        placeholder="Users who manage this entity"
                                        labelKey="name"
                                        isLoading={managersTypeaheadIsLoading}
                                        onSearch={handleManagersTypeaheadSearch}
                                        options={managersTypeheadOptions}
                                        onChange={handleManagersTypeaheadChange}
                                        useCache={false}
                                    />
                                </Form.Group>
                                <ul className="list-unstyled">
                                    {managers.map((manager) => {
                                        return (
                                            <li key={manager.accountId} className="mb-1">
                                                <Chip
                                                    title={manager.name}
                                                    removable
                                                    onRemove={() => {
                                                        handleManagerChipRemove(manager);
                                                    }}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Col>
                            <Col xs={6}>
                                <Form.Group>
                                    <Label>Parent Entity</Label>
                                    <AsyncTypeahead
                                        ref={parentEntityTypeaheadRef}
                                        id="parent-entities-async-typeahead"
                                        minLength={1}
                                        placeholder="Enter parent entity"
                                        labelKey="name"
                                        isLoading={parentEntityTypeaheadIsLoading}
                                        onSearch={handleParentEntityTypeaheadSearch}
                                        options={parentEntityTypeaheadOptions}
                                        onChange={handleParentEntityTypeaheadChange}
                                        useCache={false}
                                    />
                                </Form.Group>
                                {parentEntity && (
                                    <Chip title={parentEntity.name} removable onRemove={handleParentEntityChipRemove} />
                                )}
                            </Col>
                        </Row>
                    </DetailViewBody>
                    <DetailViewAside>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    Entity Settings
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-6">
                            <Col>
                                <div className="mb-2">
                                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                                        Visible to all STS users?
                                    </Typography>
                                </div>
                                <Toggle
                                    uncheckedLabel="Hidden"
                                    checkedLabel="Visible"
                                    checked={visibleToggleValue}
                                    onChange={setVisibleToggleValue}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mb-2">
                                    <Typography variant="h6">Entity Status</Typography>
                                </div>
                                <Toggle
                                    uncheckedLabel="Disabled"
                                    checkedLabel="Enabled"
                                    checked={enabledToggleValue}
                                    onChange={setEnabledToggleValue}
                                />
                            </Col>
                        </Row>
                    </DetailViewAside>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};
