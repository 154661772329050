import { useAbortController } from '@packages/core/http';
import colors from '@packages/core/styles/colors';
import {
    CarlineQuickFilterOption,
    QuickFilterFinalResultStatus,
    QuickFilterSbrStatus,
    TrReplySharedStatus,
    TrReplyStatus,
} from '@packages/models/api';
import { Button, Icon, Typography } from '@packages/ui/shared';
import Select, { SelectOption } from '@web/components/select';
import useQuery from '@web/core/hooks/use-query';
import { escalationsService } from '@web/services/singletons';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    filterHeader: {
        textTransform: 'uppercase',
        margin: '16px 24px',
    },
    filterContent: {
        margin: '0 24px 16px',
    },
    hr: {
        margin: '0 24px',
    },
});

type Props = {
    hasActiveFilters: boolean;
    onClose: () => void;
    onClear: () => void;
};

const EscalationsQuickFiltersPanel = (props: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const currentQueryParams = useQuery();
    const navigate = useNavigate();
    const { abortSignalRef } = useAbortController();

    const [selectedModels, setSelectedModels] = useState<CarlineQuickFilterOption[]>([]);
    const [selectedYearFrom, setSelectedYearFrom] = useState(currentQueryParams.get('modelYearFrom') || '');
    const [selectedYearTo, setSelectedYearTo] = useState(currentQueryParams.get('modelYearTo') || '');
    const [selectedTrReplies, setSelectedTrReplies] = useState<TrReplyStatus[]>([]);
    const [selectedTrReplyShared, setSelectedTrReplyShared] = useState<TrReplySharedStatus[]>([]);
    const [selectedFinalResultStatuses, setSelectedFinalResultStatuses] = useState<QuickFilterFinalResultStatus[]>([]);
    const [selectedSbrStatuses, setSelectedSbrStatuses] = useState<QuickFilterSbrStatus[]>([]);

    const [models, setModels] = useState<CarlineQuickFilterOption[]>([]);
    const [modelYearOptions, setModelYearOptions] = useState<SelectOption[]>([]);
    const [trReplyStatus, setTrReplyStatus] = useState<TrReplyStatus[]>([]);
    const [trReplySharedStatus, setTrReplySharedStatus] = useState<TrReplySharedStatus[]>([]);
    const [finalResultStatus, setFinalResultStatus] = useState<QuickFilterFinalResultStatus[]>([]);
    const [sbrStatus, setSbrStatus] = useState<QuickFilterSbrStatus[]>([]);

    useEffect(() => {
        escalationsService.fetchEscalationsQuickFilters().then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setModels(response.data.model);
            const modelsFromParams = currentQueryParams.getAll('model');
            setSelectedModels(response.data.model.filter((a) => modelsFromParams.includes(a.carlineId)));

            setModelYearOptions([
                {
                    title: '—',
                    value: '',
                },
                ...response.data.yearRange.map((option) => ({
                    title: option.description,
                    value: option.id,
                })),
            ]);

            setTrReplyStatus(response.data.trReplyStatus);
            const repliesFromParams = currentQueryParams.getAll('trReplyStatus');
            setSelectedTrReplies(
                response.data.trReplyStatus.filter((a) => repliesFromParams.includes(a.trReplyStatusId))
            );

            setTrReplySharedStatus(response.data.trReplySharedStatus);
            const currentTrReplySharedFromParams = currentQueryParams.getAll('trReplySharedStatus');
            try {
                setSelectedTrReplyShared(
                    response.data.trReplySharedStatus.filter((a) =>
                        currentTrReplySharedFromParams.includes(a.trReplySharedStatusId)
                    )
                );
            } catch (e) {
                setSelectedTrReplyShared([]);
                alert('API Response missing trReplySharedStatus information ' + e);
                console.error(e);
            }

            setFinalResultStatus(response.data.finalResultStatus);
            const finalFromParams = currentQueryParams.getAll('finalResult');
            setSelectedFinalResultStatuses(
                response.data.finalResultStatus.filter((a) => finalFromParams.includes(a.trFinalResultId))
            );

            setSbrStatus(response.data.sbrStatus);
            const sbrFromParams = currentQueryParams.getAll('sbrStatus');
            setSelectedSbrStatuses(response.data.sbrStatus.filter((a) => sbrFromParams.includes(a.trSbrStatusId)));
        });
    }, [abortSignalRef, currentQueryParams, t]);

    const rightPanelDiv = document.getElementById('right-side-panel');

    if (!rightPanelDiv) {
        return null;
    }

    const divider = <hr className={classes.hr} />;

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <div className={classes.panelHeader}>
                <Typography>{t('escalation:quickFilters.title', 'Filters')}</Typography>

                <div className={classes.closeButton} onClick={props.onClose}>
                    <Icon name="x-close" />
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{t('escalation:quickFilters.models.label', 'Model')}</Typography>
                </div>

                <div className={classes.filterContent}>
                    <Typeahead
                        id="model-async-typeahead"
                        placeholder="Select Model(s)..."
                        labelKey="name"
                        multiple
                        selected={selectedModels}
                        onChange={(selections) => {
                            setSelectedModels(selections);
                        }}
                        options={models}
                    />
                </div>

                {divider}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{t('qmr:quickFilters.modelYear.label', 'Model Year')}</Typography>
                </div>

                <div className={classes.filterContent}>
                    <div className="d-flex">
                        <div className="w-100 mr-2">
                            <Typography variant="label">{t('qmr:quickFilters.modelYear.from', 'From')}</Typography>
                            <Select
                                value={selectedYearFrom}
                                options={modelYearOptions}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    setSelectedYearFrom(event.target.value);

                                    if (event.target.value > selectedYearTo) {
                                        setSelectedYearTo('');
                                    }
                                }}
                            />
                        </div>
                        <div className="ml-2 w-100">
                            <Typography variant="label">{t('qmr:quickFilters.modelYear.to', 'To')}</Typography>
                            <Select
                                value={selectedYearTo}
                                options={modelYearOptions}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    setSelectedYearTo(event.target.value);

                                    if (event.target.value < selectedYearFrom) {
                                        setSelectedYearFrom('');
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                {divider}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">
                        {t('escalation:quickFilters.models.label', 'TR Reply Status')}
                    </Typography>
                </div>

                <div className={classes.filterContent}>
                    <Typeahead
                        id="tr-async-typeahead"
                        placeholder="Select TR Reply Status..."
                        labelKey="description"
                        multiple
                        selected={selectedTrReplies}
                        onChange={(selections) => {
                            setSelectedTrReplies(selections);
                        }}
                        options={trReplyStatus}
                    />
                </div>

                {divider}

                {/* TR Reply Shared Status Filters */}
                <div className={classes.filterHeader}>
                    <Typography variant="caption2">
                        {t('escalation:quickFilters.models.label', 'TR Reply Shared Status')}
                    </Typography>
                </div>

                <div className={classes.filterContent}>
                    <Typeahead
                        id="tr-async-typeahead"
                        placeholder="Select TR Reply Shared Status..."
                        labelKey="description"
                        multiple
                        selected={selectedTrReplyShared}
                        onChange={(selections) => {
                            setSelectedTrReplyShared(selections);
                        }}
                        options={trReplySharedStatus}
                    />
                </div>

                {divider}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">
                        {t('escalation:quickFilters.finalResult.label', 'Final Result')}
                    </Typography>
                </div>

                <div className={classes.filterContent}>
                    <Typeahead
                        id="final-async-typeahead"
                        placeholder="Select Final Result..."
                        labelKey="description"
                        multiple
                        selected={selectedFinalResultStatuses}
                        onChange={(selections) => {
                            setSelectedFinalResultStatuses(selections);
                        }}
                        options={finalResultStatus}
                    />
                </div>

                {divider}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">
                        {t('escalation:quickFilters.sbsStatus.label', 'SBR Status')}
                    </Typography>
                </div>

                <div className={classes.filterContent}>
                    <Typeahead
                        id="sbr-async-typeahead"
                        placeholder="Select SBR Status..."
                        labelKey="description"
                        multiple
                        selected={selectedSbrStatuses}
                        onChange={(selections) => {
                            setSelectedSbrStatuses(selections);
                        }}
                        options={sbrStatus}
                    />
                </div>

                {divider}

                <div className={classes.filterContent}>
                    <div className="mt-4 d-flex justify-content-between">
                        <Button
                            onPress={() => {
                                const newQueryParams = new URLSearchParams();
                                const currentSize = currentQueryParams.get('size');
                                const currentQuery = currentQueryParams.get('query');

                                if (currentSize) {
                                    newQueryParams.set('size', currentSize);
                                }

                                if (currentQuery) {
                                    newQueryParams.set('query', currentQuery);
                                }

                                for (const model of selectedModels) {
                                    newQueryParams.append('model', model.carlineId);
                                }

                                if (selectedYearFrom) {
                                    newQueryParams.append('modelYearFrom', selectedYearFrom);
                                }

                                if (selectedYearTo) {
                                    newQueryParams.set('modelYearTo', selectedYearTo);
                                }

                                for (const trReply of selectedTrReplies) {
                                    newQueryParams.append('trReplyStatus', trReply.trReplyStatusId);
                                }

                                for (const trReplySharedStatus of selectedTrReplyShared) {
                                    newQueryParams.append(
                                        'trReplySharedStatus',
                                        trReplySharedStatus.trReplySharedStatusId
                                    );
                                }

                                for (const finalResult of selectedFinalResultStatuses) {
                                    newQueryParams.append('finalResult', finalResult.trFinalResultId);
                                }

                                for (const sbrStatus of selectedSbrStatuses) {
                                    newQueryParams.append('sbrStatus', sbrStatus.trSbrStatusId);
                                }

                                navigate(`/escalations?${newQueryParams.toString()}`);
                                props.onClose();
                            }}
                        >
                            {t('escalation:quickFilters.actions.apply', 'Apply')}
                        </Button>

                        {props.hasActiveFilters && (
                            <Button
                                variant="ghost-blue"
                                onPress={() => {
                                    props.onClear();
                                    props.onClose();
                                }}
                            >
                                {t('escalation:quickFilters.actions.clear', 'Clear All')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>,
        rightPanelDiv
    );
};

export default EscalationsQuickFiltersPanel;
