import { Account, Retailer } from '@packages/models/api';
import { Button, Typography } from '@packages/ui/shared';
import { Chip } from '@web/administration/components';
import AsyncPage from '@web/components/async-page';
import { DetailView, DetailViewBody, DetailViewContent, DetailViewHeader } from '@web/components/detail-view';
import { qmrsService } from '@web/services/singletons';
import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

export const RetailerDetail: FC = () => {
    const navigate = useNavigate();
    const { retailerId } = useParams<{ retailerId: string }>() as { retailerId: string };
    const [retailer, setRetailer] = useState<Retailer>();
    const [approvers, setApprovers] = useState<Account[]>([]);

    const fetchData = useCallback(async () => {
        qmrsService.fetchRetailerbyId({ retailerId }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setRetailer(response.data.retailer);
        });
        qmrsService.fetchRetailerFailCodeApprovers({ retailerId }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setApprovers(response.data.accounts);
        });
    }, [retailerId]);

    function handleEditButtonPress() {
        if (!retailer) {
            return;
        }

        navigate(`/administration/retailers/${retailer.retailerId}/edit`);
    }

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <div className="mt-6">
                    <DetailViewHeader
                        breadcrumbs={[
                            { to: '/administration/retailers', title: 'Manage Retailers' },
                            { to: `/administration/retailers/${retailerId}`, title: 'Retailer' },
                        ]}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <Typography variant="h3">
                                {retailer?.name} (#{retailer?.code})
                            </Typography>
                            {/* {retailer?.capabilities.manageFailCodeApprover && ( */}
                            <Button onPress={handleEditButtonPress}>Edit Retailer</Button>
                            {/* )} */}
                        </div>
                        <div className="mb-2">
                            <Typography variant="h6" color="graySix">
                                Region-Zone-District: {retailer?.regionCode}-{retailer?.zoneCode}-
                                {retailer?.districtCode}
                            </Typography>
                        </div>
                    </DetailViewHeader>
                </div>
                <DetailViewContent topOffset="breadcrumb">
                    <DetailViewBody>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4">Fail Code Settings</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <div className="mb-2">
                                    <Typography variant="label">Fail Code Approval Setting</Typography>
                                </div>
                                <Typography>{retailer?.failCodeApproverSettingDescription}</Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <Typography variant="label">Fail Code Approvers</Typography>
                                </div>
                                <ul className="list-unstyled">
                                    {approvers.length > 0 ? (
                                        approvers.map((approver) => {
                                            return (
                                                <li key={approver.accountId} className="mb-1">
                                                    <Chip title={approver.name} />
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <Typography>No Approvers</Typography>
                                    )}
                                </ul>
                            </Col>
                        </Row>
                    </DetailViewBody>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};
