import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '@web/components/loader';
import ErrorDisplay from '@web/components/error-display';

enum DISPLAY_STATES {
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

interface AsyncPageProps {
    fetchData(): void;
    abortFetch?(): void;
    authRedirectPath?: string;
    children?: React.ReactNode;
}

const AsyncPage: FC<AsyncPageProps> = ({ children, fetchData, abortFetch, authRedirectPath }) => {
    const navigate = useNavigate();
    const [fetchPageDataError, setFetchPageDataError] = useState(undefined);
    const [displayState, setDisplayState] = useState(DISPLAY_STATES.LOADING);

    const fetchPageData = useCallback(async () => {
        setDisplayState(DISPLAY_STATES.LOADING);
        setFetchPageDataError(undefined);

        try {
            await fetchData();
            setDisplayState(DISPLAY_STATES.SUCCESS);
        } catch (error) {
            if (error.code === 'REQUEST_ABORTED') {
                return;
            }

            if (error.code === 'AUTHENTICATION_REQUIRED') {
                if (authRedirectPath) {
                    return navigate(authRedirectPath, { replace: true });
                }

                return navigate('/login', { replace: true });
            }

            setFetchPageDataError(error);
            setDisplayState(DISPLAY_STATES.ERROR);
        }
    }, [authRedirectPath, fetchData, navigate]);

    useEffect(() => {
        fetchPageData();

        return () => {
            typeof abortFetch === 'function' && abortFetch();
        };
    }, [fetchPageData, abortFetch]);

    function getDisplayState() {
        switch (displayState) {
            case DISPLAY_STATES.LOADING:
                return <Loader />;
            case DISPLAY_STATES.SUCCESS:
                return children;
            case DISPLAY_STATES.ERROR:
                return (
                    <ErrorDisplay
                        error={fetchPageDataError}
                        showBackButton={true}
                        showRetryButton={true}
                        onRetryButtonClick={fetchPageData}
                    />
                );
            default:
                return <></>;
        }
    }

    return <>{getDisplayState()}</>;
};

export default AsyncPage;
