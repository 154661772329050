import React from 'react';
import { FC, useCallback, useRef } from 'react';
import { Icon, Typography, Button } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { SelectionElement } from './form-quantity-menu-item-list';

const useDropdownStyles = createUseStyles({
    dropdownItem: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 10px',
        '&:hover': {
            backgroundColor: `${colors.grayOne} !important`,
            color: `${colors.black} !important`,
        },
    },
});

interface Props {
    option: SelectionElement;
    handleQuantity: ({ field, increase }: { field: SelectionElement; increase: boolean }) => void;
}
const QuantityMenu: FC<Props> = ({ option, handleQuantity }) => {
    const classes = useDropdownStyles();
    const inputNumberRef = useRef<HTMLInputElement | null>(null);

    const handleIncrementDecrement = useCallback(
        ({ increase }: { increase: boolean }) => {
            handleQuantity({ field: option, increase });
        },
        [handleQuantity, option]
    );

    const ActionButton: FC<{ increase: boolean; iconName: 'minus' | 'plus'; disabled?: boolean }> = ({
        increase,
        iconName,
        disabled,
    }) => {
        return (
            <>
                <Button
                    variant="ghost-gray"
                    style={{ width: 30, height: 30 }}
                    disabled={disabled}
                    onPress={() => {
                        handleIncrementDecrement({
                            increase: increase,
                        });
                    }}
                >
                    <Icon name={iconName} style={{ fontSize: 15 }} />
                </Button>
            </>
        );
    };

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div
                className={classes.dropdownItem}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="d-flex">
                    <div className="mr-2 d-flex" style={{ cursor: 'pointer' }}>
                        <ActionButton increase={false} iconName="minus" disabled={option.count === 0} />
                        <input
                            type="number"
                            style={{ width: 30, height: 30, textAlign: 'center', fontSize: 15 }}
                            value={option.count}
                            min={0}
                            ref={inputNumberRef}
                            readOnly
                        />
                        <ActionButton increase={true} iconName="plus" />
                    </div>
                    <div>
                        {' '}
                        <Typography>{option?.formElementOrder}</Typography>
                        {'- '}
                        <Typography style={{ width: '100%' }}>{option?.description}</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuantityMenu;
