import * as React from 'react';
import { FormElementModalContext, FormElementModalDispatchContext } from './form-element-modal.context';

export const useFormElementModalState = () => {
    const state = React.useContext(FormElementModalContext);

    if (state === undefined) {
        throw new Error('useFormElementModalState must be used within a FormElementModalProvider');
    }

    return state;
};

export const useFormElementModalDispatch = () => {
    const state = React.useContext(FormElementModalDispatchContext);
    if (state === undefined) {
        throw new Error('useFormElementModalDispatch must be used within a FormElementModalProvider');
    }

    return state;
};
