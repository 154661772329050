import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useQmrDispatch, useQmrState, patchQmr } from '@packages/contexts/qmrs';
import { useAbortController } from '@packages/core/http';
import { TextareaHelper, Button, Label, Icon } from '@packages/ui/shared';
import { ConcernInfoPopup } from '@packages/ui/qmr/popups';
import { httpClient, qmrsService } from '@web/services/singletons';

import EarlyWarningDetectionDraft from './early-warning-detection-draft';
import RepairOrderDetailsDraft from './repair-order-details-draft';
import { PatchQmrDto } from '@packages/models/api';

const ConcernDraft: FC = () => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');

    const [didInit, setDidInit] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [concern, setConcern] = useState('');
    const [concernError, setConcernError] = useState('');
    const [didDuplicate, setDidDuplicate] = useState<boolean | null>(
        qmr && typeof qmr.complaintDuplicated === 'boolean' ? qmr.complaintDuplicated : null
    );

    const saveChanges = useCallback(
        (qmrPatch: PatchQmrDto) => {
            if (!qmr) {
                return;
            }

            patchQmr({
                qmrId: qmr.qmrId,
                qmrsService,
                qmrDispatch,
                signal: abortSignalRef.current,
                qmrPatch,
                isLocalPatch: !!editRouteMatch,
            });
        },
        [abortSignalRef, editRouteMatch, qmr, qmrDispatch]
    );

    const timeoutRefs = useRef<{ [k: string]: NodeJS.Timeout }>({});
    useEffect(() => {
        if (timeoutRefs.current.concern) {
            clearTimeout(timeoutRefs.current.concern);
        }

        if ((!qmr?.complaint && !concern) || qmr?.complaint === concern) {
            return;
        }

        timeoutRefs.current.concern = global.setTimeout(() => {
            saveChanges({ complaint: concern });
        }, 60000);
    }, [saveChanges, concern, qmr?.complaint]);

    useEffect(() => {
        if (!qmr || didInit) {
            return;
        }

        setDidInit(true);

        setConcern(qmr.complaint);
        setDidDuplicate(qmr.complaintDuplicated);
    }, [qmr, didInit]);

    return (
        <>
            <ConcernInfoPopup
                show={showInfoPopup}
                onOkPress={() => setShowInfoPopup(false)}
                onModalHide={() => setShowInfoPopup(false)}
            />

            <Row>
                <Col>
                    <h4 className="mb-4">{t('qmr:sections.concern.title')}</h4>

                    <Form.Group className="mb-6">
                        <div className="mb-3">
                            <Label required>{t('qmr:inputs.concernDuplicated.label')}</Label>
                        </div>

                        <Form.Check
                            className="mb-3"
                            disabled={!!editRouteMatch}
                            label={t('text:common.yes')}
                            type="radio"
                            id="duplicate-condition-yes"
                            name="duplicate-condition"
                            checked={!!didDuplicate}
                            onChange={() => {
                                setDidDuplicate(true);
                                saveChanges({ complaintDuplicated: true });
                            }}
                        />

                        <Form.Check
                            disabled={!!editRouteMatch}
                            label={t('text:common.no')}
                            type="radio"
                            id="duplicate-condition-no"
                            name="duplicate-condition"
                            checked={didDuplicate === false}
                            onChange={() => {
                                setDidDuplicate(false);
                                saveChanges({ complaintDuplicated: false });
                            }}
                        />
                    </Form.Group>

                    <div className="mb-6">
                        <EarlyWarningDetectionDraft />
                    </div>

                    <RepairOrderDetailsDraft
                        concrenInput={
                            <TextareaHelper
                                editable={!!editRouteMatch ? qmr?.capabilities.editCccOnQmr : true}
                                controlId="qmr-concern"
                                value={concern}
                                label={t('qmr:inputs.concern.label')}
                                labelRightElement={
                                    <Button
                                        variant="ghost-blue"
                                        title={t('qmr:inputs.concern.hint')}
                                        iconLeft={<Icon name="info" color="blueOne" />}
                                        onPress={() => setShowInfoPopup(true)}
                                    />
                                }
                                required
                                errorMessage={concernError}
                                placeholder={t('qmr:inputs.concern.placeholder')}
                                onChangeText={(value) => {
                                    httpClient.refreshToken();
                                    setConcern(value);
                                    setConcernError(value.trim().length ? '' : t('qmr:inputs.concern.errorMessage'));

                                    // Make sure when it is empty, we mark it definitively as empty and strictly do not allow empty submissions.
                                    if (!value.trim().length) {
                                        if (!qmr) {
                                            return;
                                        }

                                        const qmrPatch = { complaint: value };
                                        patchQmr({
                                            qmrId: qmr.qmrId,
                                            qmrsService,
                                            qmrDispatch,
                                            isLocalPatch: true,
                                            qmrPatch,
                                        });
                                        saveChanges(qmrPatch);
                                    }
                                }}
                                onBlur={() => {
                                    if (!qmr) {
                                        return;
                                    }

                                    if (qmr.complaint !== concern) {
                                        const qmrPatch = { complaint: concern };
                                        patchQmr({
                                            qmrId: qmr.qmrId,
                                            qmrsService,
                                            qmrDispatch,
                                            isLocalPatch: true,
                                            qmrPatch,
                                        });

                                        saveChanges(qmrPatch);
                                    }
                                }}
                            />
                        }
                    />
                </Col>
            </Row>
        </>
    );
};

export default ConcernDraft;
