import { useTranslation } from 'react-i18next';
import React, { Fragment, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { BaseComment, Button, Icon, Typography } from '../shared';
import colors from '@packages/core/styles/colors';
import {
    PartRequestsClosedPopup,
    PartRequestsConfirmClosePopup,
    PartRequestsConfirmOpenPopup,
    PartRequestsOpenPopup,
} from './popups';

import { Qmr } from '@packages/models/api';

interface QmrPartRequestsSegmentProps {
    qmr: Qmr | null;
    onOpenRequest(): void;
    onCloseRequest(): void;
}

export const QmrPartRequestsSegment = ({ qmr, onOpenRequest, onCloseRequest }: QmrPartRequestsSegmentProps) => {
    const { t } = useTranslation();

    const [showOpenRequestPopup, setShowOpenRequestPopup] = useState(false);
    const [showClosedRequestPopup, setShowClosedRequestPopup] = useState(false);
    const [showConfirmOpenRequestPopup, setShowConfirmOpenRequestPopup] = useState(false);
    const [showConfirmCloseRequestPopup, setShowConfirmCloseRequestPopup] = useState(false);

    if (!qmr) {
        return null;
    }

    return (
        <>
            <PartRequestsOpenPopup
                show={showOpenRequestPopup}
                onOkPress={() => setShowOpenRequestPopup(false)}
                onModalHide={() => setShowOpenRequestPopup(false)}
            />

            <PartRequestsClosedPopup
                show={showClosedRequestPopup}
                onOkPress={() => setShowClosedRequestPopup(false)}
                onModalHide={() => setShowClosedRequestPopup(false)}
            />

            <PartRequestsConfirmOpenPopup
                show={showConfirmOpenRequestPopup}
                onOpenPress={() => {
                    onOpenRequest();
                    setShowConfirmOpenRequestPopup(false);
                }}
                onModalHide={() => setShowConfirmOpenRequestPopup(false)}
            />

            <PartRequestsConfirmClosePopup
                show={showConfirmCloseRequestPopup}
                onClosePress={() => {
                    onCloseRequest();
                    setShowConfirmCloseRequestPopup(false);
                }}
                onModalHide={() => setShowConfirmCloseRequestPopup(false)}
            />

            <ScrollView>
                <View style={styles.headerOuter}>
                    <Button
                        variant="ghost-gray"
                        size="small"
                        iconRight={<Icon name="info" color="blueOne" size={16} style={styles.infoButtonIcon} />}
                        onPress={() => setShowOpenRequestPopup(true)}
                    >
                        <Typography variant="caption2" color="black">
                            {t('qmr:partRequests.openInfoButton.title', 'Open Request')}
                        </Typography>
                    </Button>
                    {qmr.openPartCollectionRequest && qmr.capabilities.openClosePartCollRequest && (
                        <Button variant="danger" size="small" onPress={() => setShowConfirmCloseRequestPopup(true)}>
                            {t('qmr:partRequests.closeButton.title', 'Close')}
                        </Button>
                    )}
                </View>

                {!qmr.openPartCollectionRequest && (
                    <View style={styles.noRequestsOuter}>
                        <Typography variant="lead" color="graySix" style={styles.noRequestsTitle}>
                            {t('qmr:partRequests.noOpenRequests.message', 'There are no open part collection requests')}
                        </Typography>
                        {qmr.capabilities.openClosePartCollRequest && (
                            <View style={styles.noRequestsButtonOuter}>
                                <Button
                                    title={t('qmr:partRequests.openButton.title', 'Open a new part collection request')}
                                    iconRight={
                                        <Icon
                                            size={16}
                                            raised
                                            name="plus"
                                            raisedContainerStyle={{ marginLeft: 20, width: 21, height: 21 }}
                                        />
                                    }
                                    onPress={() => setShowConfirmOpenRequestPopup(true)}
                                />
                            </View>
                        )}
                    </View>
                )}

                {qmr.openPartCollectionRequest && (
                    <View style={styles.requestOuter}>
                        <BaseComment
                            comment={null}
                            authorName={qmr.openPartCollectionRequest.openedAccount.name}
                            timestamp={qmr.openPartCollectionRequest.openedTimestampDescription}
                            body=""
                            editCommentRenderer={() => <></>}
                        />
                    </View>
                )}

                <View style={styles.footerOuter}>
                    <Button
                        variant="ghost-gray"
                        size="small"
                        iconRight={<Icon name="info" color="blueOne" size={16} style={styles.infoButtonIcon} />}
                        onPress={() => setShowClosedRequestPopup(true)}
                    >
                        <Typography variant="caption2" color="black">
                            {t('qmr:partRequests.closeInfoButton.title', 'Closed Requests')}
                        </Typography>
                    </Button>
                    <Typography variant="caption2" color="graySix">
                        {qmr.closedPartCollectionRequests?.length || 0}{' '}
                        {t('qmr:partRequests.closedLength.title', 'closed')}
                    </Typography>
                </View>

                {qmr.closedPartCollectionRequests?.map((closedRequest) => {
                    return (
                        <Fragment key={closedRequest.partCollectionRequestId}>
                            <View style={styles.closedRequestOuter}>
                                <BaseComment
                                    comment={null}
                                    authorName={closedRequest.openedAccount.name}
                                    timestamp={closedRequest.openedTimestampDescription}
                                    body=""
                                    editCommentRenderer={() => <></>}
                                />
                            </View>
                            <View style={styles.requestCloserOuter}>
                                <View style={styles.requestCloser}>
                                    <Typography variant="labelRegular">
                                        <Typography variant="h6">{closedRequest.closedAccount?.name}</Typography>{' '}
                                        {t('qmr:partRequests.closedRequest.message', 'closed this request')}
                                    </Typography>
                                    <Typography variant="caption" color="graySix">
                                        {closedRequest.closedTimestampDescription}
                                    </Typography>
                                </View>
                            </View>
                        </Fragment>
                    );
                })}
            </ScrollView>
        </>
    );
};

const styles = StyleSheet.create({
    headerOuter: {
        paddingLeft: 22,
        paddingRight: 30,
        paddingVertical: 12,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: colors.grayThree,
    },
    noRequestsOuter: {
        paddingTop: 34,
        paddingBottom: 56,
        paddingHorizontal: 30,
        backgroundColor: colors.white,
        borderBottomWidth: 1,
        borderBottomColor: colors.grayThree,
    },
    noRequestsButtonOuter: {
        alignItems: 'center',
    },
    noRequestsTitle: {
        marginBottom: 23,
        textAlign: 'center',
    },
    requestOuter: {
        paddingTop: 16,
        paddingBottom: 8,
        paddingHorizontal: 30,
        backgroundColor: colors.white,
        borderBottomWidth: 1,
        borderBottomColor: colors.grayThree,
    },
    closedRequestOuter: {
        paddingTop: 16,
        paddingBottom: 8,
        paddingHorizontal: 30,
    },
    requestCloserOuter: {
        paddingBottom: 16,
        borderBottomWidth: 1,
        paddingHorizontal: 16,
        borderBottomColor: colors.grayThree,
    },
    requestCloser: {
        paddingVertical: 20,
        paddingHorizontal: 14,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: colors.grayThree,
        backgroundColor: colors.grayTwo,
    },
    footerOuter: {
        paddingLeft: 22,
        paddingRight: 30,
        paddingVertical: 12,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: colors.grayThree,
    },
    infoButton: {
        paddingVertical: 8,
        paddingHorizontal: 8,
        height: 'auto',
    },
    infoButtonIcon: {
        marginTop: -2,
    },
});
