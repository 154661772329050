import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Button, modalDefaultStyles, Popup, PopupProps, Typography, Icon } from '../../shared';

interface UrfcaInfoPopupProps extends PopupProps {
    onOkPress: () => void;
}

export const UrfcaInfoPopup: FC<UrfcaInfoPopupProps> = ({ onOkPress, ...props }) => {
    const { t } = useTranslation();

    return (
        <Popup {...props}>
            <View style={[modalDefaultStyles.header, modalDefaultStyles.headerRow]}>
                <Icon name="info" size={40} style={modalDefaultStyles.headerIcon} />

                <Typography variant="h4">{t('qmr:popups.urfcaInfo.title', 'URFCA Required')}</Typography>
            </View>

            <View style={modalDefaultStyles.content}>
                <Typography variant="lead">
                    {t(
                        'qmr:popups.urfcaInfo.message',
                        'If you submit a QMR with any of these issues marked, you MUST open a case with Subaru Customer Retailer Services (CRS) and submit an Urgent Request for Customer Assistance (URFCA) at the same time. This ensures official notification of SOA has occured. The URFCA form may be entered online or printed and faxed. It is found in the Customer Retailer Services area of Subarunet.'
                    )}
                </Typography>
            </View>

            <View style={modalDefaultStyles.footer}>
                <Button title={t('qmr:popups.urfcaInfo.ok', 'Got it')} onPress={onOkPress} />
            </View>
        </Popup>
    );
};
