import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { Icon, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

const useChipStyles = createUseStyles({
    chip: ({ removable }: { removable: boolean }) => ({
        borderRadius: 500,
        alignItems: 'center',
        display: 'inline-flex',
        backgroundColor: colors.grayOne,
        border: `1px solid ${colors.grayThree}`,
        padding: removable ? '4px 4px 4px 12px' : '6px 12px',
    }),
    removeButton: {
        width: 24,
        height: 24,
        border: 0,
        margin: 0,
        padding: 0,
        marginLeft: 8,
        appearance: 'none',
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            width: 18,
            height: 18,
            borderRadius: 9,
            alignItems: 'center',
            display: 'inline-flex',
            justifyContent: 'center',
            backgroundColor: colors.grayFive,
        },
    },
});

interface ChipProps {
    title: string;
    removable?: boolean;
    onRemove?(): void;
}

export const Chip: FC<ChipProps> = ({ title, removable = false, onRemove }) => {
    const classes = useChipStyles({
        removable,
    });

    return (
        <div className={classes.chip}>
            <Typography variant="labelRegular">{title}</Typography>
            {removable && (
                <button className={classes.removeButton} onClick={onRemove}>
                    <span>
                        <Icon name="x-close" size={16} color="white" />
                    </span>
                </button>
            )}
        </div>
    );
};
