import { ApiTarget, HttpClient } from '@packages/core/http';
import { VIN, FetchVinResponseParams, OpenVinSessionParamsBody, RecentlyViewed } from '../types';

export class VinSessionService {
    constructor(private http: HttpClient) {}

    getVinData({
        fetchVinParams,
        ignoreCache,
        signal,
    }: {
        fetchVinParams: FetchVinResponseParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/vehicle-details' + this.http.queryString(fetchVinParams);

        return this.http.orchestrateRequest<VIN>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
            apiTarget: ApiTarget.VinService,
        });
    }

    openVinSession({
        body,
        ignoreCache,
        signal,
    }: {
        body: OpenVinSessionParamsBody;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/open-vin-session';

        return this.http.orchestrateRequest<any>({
            method: 'POST',
            url,
            body,
            ignoreCache,
            signal,
            apiTarget: ApiTarget.VinService,
        });
    }

    closeVinSession({
        body,
        ignoreCache,
        signal,
    }: {
        body: OpenVinSessionParamsBody;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/close-vin-session';

        return this.http.orchestrateRequest<any>({
            method: 'PUT',
            url,
            body,
            ignoreCache,
            signal,
            apiTarget: ApiTarget.VinService,
        });
    }

    getRecentlyViewedVinData({
        ignoreCache,
        signal,
        accountId,
    }: {
        ignoreCache?: boolean;
        signal?: AbortSignal;
        accountId: string | any;
    }) {
        const url = `/recent-vin-sessions?accountId=${accountId}`;

        return this.http.orchestrateRequest<RecentlyViewed[]>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
            apiTarget: ApiTarget.VinService,
        });
    }

    getActiveVinSessions({
        ignoreCache,
        signal,
        accountId,
    }: {
        ignoreCache?: boolean;
        signal?: AbortSignal;
        accountId: string | any;
    }) {
        const url = `/active-vin-sessions?accountId=${accountId}`;

        return this.http.orchestrateRequest<RecentlyViewed[]>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
            apiTarget: ApiTarget.VinService,
        });
    }
}
