import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import colors from '@packages/core/styles/colors';

const useDetailViewAsideStyles = createUseStyles({
    aside: {
        display: 'flex',
        flexDirection: 'column',
        width: 390,
        flexShrink: 0,
        overflowY: 'auto',
        padding: '32px 30px',
        backgroundColor: colors.grayOne,
        borderLeft: `1px solid ${colors.grayThree}`,
    },
});

export const DetailViewAside: FC<{ children: React.ReactNode }> = ({ children }) => {
    const classes = useDetailViewAsideStyles();

    return <div className={classes.aside}>{children}</div>;
};
