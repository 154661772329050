import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useQmrState } from '@packages/contexts/qmrs';
import { Typography } from '@packages/ui/shared';

const CauseSubmitted: FC = () => {
    const { t } = useTranslation();
    const { qmr } = useQmrState();

    if (!qmr) {
        return null;
    }

    return (
        <>
            <Typography variant="h4">{t('qmr:sections.cause.title', 'Cause')}</Typography>

            <div className="my-4">
                <Typography>{qmr.cause}</Typography>
            </div>
        </>
    );
};

export default CauseSubmitted;
