import React, { FC, useCallback } from 'react';
import { DropResult, DraggableProvided, DraggableStateSnapshot, DraggableLocation } from 'react-beautiful-dnd';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import colors from '@packages/core/styles/colors';
import fonts from '@web/jss/fonts';
import { cloneDeep } from 'lodash';
import { ReactComponent as HandleIcon } from '@web/assets/icon-handle.svg';
import { Button, Icon, StsIconName, TextBadge, Typography } from '@packages/ui/shared';
import { ConditionalElement, DraggableWorksheetFormComponent } from '@packages/models/api';
import { WorksheetFormElement } from '../../views/worksheets/components/worksheet-form-element';

import {
    updateFormElement,
    useFormElementModalDispatch,
    useFormElementModalState,
} from '@packages/contexts/form-element-modal';
import useTranslationData from './hooks/useTranslationData';

export interface FormType {
    Worksheet: 'Worksheet';
}

interface FormComponentProps {
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    item: DraggableWorksheetFormComponent;
    parentItem: DraggableWorksheetFormComponent;
    editIconName?: StsIconName;
    deleteIconName?: StsIconName;
    copyIconName?: StsIconName;
    draggableIcon?: StsIconName;
    onEditClick?(item: DraggableWorksheetFormComponent): void;
    onDeleteClick?(item: DraggableWorksheetFormComponent): void;
    onCopyClick?(item: DraggableWorksheetFormComponent): void;
    onChildDrag?(item: DraggableWorksheetFormComponent): void;
}

export const FormComponent: FC<FormComponentProps> = ({
    provided,
    snapshot,
    item,
    parentItem,
    draggableIcon,
    onEditClick,
    onDeleteClick,
    onCopyClick,
    onChildDrag,
    copyIconName,
    editIconName,
    deleteIconName,
}) => {
    const classes = useFormComponentStyles();
    const formElementDispatch = useFormElementModalDispatch();
    const formElementState = useFormElementModalState();
    const { draggableElementsDataTranslate } = useTranslationData();
    const updateItemFromNestedArray = useCallback(
        (
            conditionalElements: ConditionalElement[],
            itemId: string,
            source: DraggableLocation,
            destination: DraggableLocation
        ): ConditionalElement[] => {
            if (!conditionalElements.length) {
                return conditionalElements;
            }
            let conditionalElementsClone = [...conditionalElements];
            const itemIndex = conditionalElementsClone.findIndex((element) => element.formElementId === itemId);
            if (itemIndex > -1) {
                const option = conditionalElements[source.index];

                conditionalElementsClone.splice(source.index, 1);
                conditionalElementsClone.splice(destination.index, 0, option);
            } else {
                conditionalElementsClone = conditionalElementsClone.map((element) => {
                    if (element.optionConditionsElements && element.optionConditionsElements.length) {
                        const nestedArray: ConditionalElement[] = element.optionConditionsElements;
                        element.optionConditionsElements = updateItemFromNestedArray(
                            nestedArray,
                            itemId,
                            source,
                            destination
                        );
                    }
                    return element;
                });
            }
            return conditionalElementsClone;
        },
        []
    );

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination } = result;

            if (!destination || !parentItem.optionConditionsElements) {
                return;
            }
            let optionsClone = cloneDeep(parentItem.optionConditionsElements);
            optionsClone = updateItemFromNestedArray(optionsClone, result.draggableId, source, destination);
            updateFormElement({
                formElement: { ...formElementState.formElement, optionConditionsElements: optionsClone },
                formElementDispatch,
            });
            onChildDrag?.({ ...parentItem, optionConditionsElements: optionsClone });
        },
        [formElementDispatch, formElementState.formElement, onChildDrag, parentItem, updateItemFromNestedArray]
    );

    return (
        <div className={classes.formComponentOuter} ref={provided.innerRef} {...provided.draggableProps}>
            <div
                className={classNames({
                    [classes.formComponent]: true,
                    [classes.formComponentIsDragging]: snapshot.isDragging,
                })}
            >
                <div className={classes.toolbar}>
                    <div className="d-flex align-items-center">
                        <div className={classes.handle} {...provided.dragHandleProps}>
                            {draggableIcon ? (
                                <Icon name={draggableIcon} size={16} color={'grayFive'} />
                            ) : (
                                <HandleIcon />
                            )}
                        </div>
                        <Typography variant="labelRegular">
                            {`${item.formElementOrder} - ${item.description || item.formElementTypeId}`}
                        </Typography>
                        {item.required && (
                            <TextBadge
                                variant="blue"
                                style={{ borderRadius: 15, marginLeft: 15, paddingHorizontal: 13 }}
                            >
                                <Typography style={{ textTransform: 'capitalize' }} color="blueOne" variant="label">
                                    {draggableElementsDataTranslate.labels.required}
                                </Typography>
                            </TextBadge>
                        )}
                    </div>
                    <div className="d-flex align-items-center">
                        {onCopyClick && (
                            <div className="ml-2">
                                <Button variant="ghost-blue" onPress={() => onCopyClick?.(item)}>
                                    {copyIconName ? <Icon name={copyIconName} /> : 'copy'}
                                </Button>
                            </div>
                        )}
                        <div>
                            <Button variant="ghost-blue" onPress={() => onEditClick?.(item)}>
                                {editIconName ? <Icon name={editIconName} /> : 'Edit'}
                            </Button>
                        </div>
                        <div className="ml-2">
                            <Button variant="danger" onPress={() => onDeleteClick?.(item)}>
                                {deleteIconName ? <Icon name={deleteIconName} color="white" /> : 'Delete'}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={classes.preview}>
                    <WorksheetFormElement
                        item={item}
                        handleDragEnd={handleDragEnd}
                        onChildDrag={onChildDrag}
                        onCopyClick={onCopyClick}
                        onDeleteClick={onDeleteClick}
                        onEditClick={onEditClick}
                        readOnly={true}
                    />
                </div>
            </div>
        </div>
    );
};

const useFormComponentStyles = createUseStyles({
    formComponentOuter: {
        paddingBottom: 16,
    },
    formComponent: {
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
    },
    formComponentIsDragging: {
        border: `1px dashed ${colors.black}`,
    },
    toolbar: {
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    handle: {
        width: 44,
        height: 44,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    description: {
        ...fonts.xxs,
    },
    preview: {
        padding: '24px 20px 0 20px',
    },
});
