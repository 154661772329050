import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { get } from 'lodash';
import { Icon, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { TrReplyMessage } from '@packages/models/api';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles({
    trReplyOuter: {
        padding: 30,
        borderRadius: 8,
        backgroundColor: colors.grayOne,
        border: `1px solid ${colors.grayThree}`,
    },
    shareWithOuter: {
        display: 'flex',
        borderRadius: 4,
        padding: '12px 16px',
        alignItems: 'center',
        backgroundColor: colors.white,
        justifyContent: 'space-between',
        border: `1px solid ${colors.grayThree}`,
    },
});

interface TrReply {
    sqip: TrReplyMessage;
    investigation: TrReplyMessage;
    qmr: TrReplyMessage;
    sqipControlGroup: string;
    qmrId: string;
    investigationId?: string;
    className?: string;
}

export const TrReply = ({ sqip, investigation, qmr, sqipControlGroup, qmrId, investigationId, className }: TrReply) => {
    const classes = useStyles();

    return (
        <div>
            {sqip && (
                <div className={classNames(classes.trReplyOuter, className)}>
                    <div className={classNames('mb-6', classes.shareWithOuter)}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2 d-flex">
                                <Icon name="checkmark" color="greenOne" />
                            </div>
                            <Typography variant="labelRegular">QMRs with same SQIP Control Group</Typography>
                        </div>
                        <Link to={`/escalations?query=${sqipControlGroup}`} target="_blank">
                            <Icon name="launch" />
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            Retailer/Technician/Corporate:
                        </Typography>
                    </div>
                    <div className="mb-10">
                        <Typography variant="lead">
                            {get(
                                sqip,
                                'retailerTechnicianCorporateMessage',
                                get(sqip, 'retailerTechnicianCorporateMessage')
                            )}
                        </Typography>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            SOA DSQM/NASI:
                        </Typography>
                    </div>
                    <div className="mb-10">
                        <Typography variant="lead">
                            {get(sqip, 'soaDsqmNasiMessage', get(sqip, 'soaDsqmNasiMessage'))}
                        </Typography>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            SNE/SDC DSQMs:
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="lead">
                            {get(sqip, 'sneSdcDsqmMessage', get(sqip, 'sneSdcDsqmMessage'))}
                        </Typography>
                    </div>
                </div>
            )}
            {investigation && (
                <div className={classNames(classes.trReplyOuter, className)}>
                    <div className={classNames('mb-6', classes.shareWithOuter)}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2 d-flex">
                                <Icon name="checkmark" color="greenOne" />
                            </div>
                            <Typography variant="labelRegular">
                                QMRs in the same investigation (that were or were not escalated)
                            </Typography>
                        </div>
                        <Link to={`/investigations/${investigationId}`} target="_blank">
                            <Icon name="launch" />
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            Retailer/Technician/Corporate:
                        </Typography>
                    </div>
                    <div className="mb-10">
                        <Typography variant="lead">
                            {get(
                                investigation,
                                'retailerTechnicianCorporateMessage',
                                get(investigation, 'retailerTechnicianCorporateMessage')
                            )}
                        </Typography>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            SOA DSQM/NASI:
                        </Typography>
                    </div>
                    <div className="mb-10">
                        <Typography variant="lead">
                            {get(investigation, 'soaDsqmNasiMessage', get(investigation, 'soaDsqmNasiMessage'))}
                        </Typography>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            SNE/SDC DSQMs:
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="lead">
                            {get(investigation, 'sneSdcDsqmMessage', get(investigation, 'sneSdcDsqmMessage'))}
                        </Typography>
                    </div>
                </div>
            )}
            {qmr && (
                <div className={classNames(classes.trReplyOuter, className)}>
                    <div className={classNames('mb-6', classes.shareWithOuter)}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2 d-flex">
                                <Icon name="checkmark" color="greenOne" />
                            </div>
                            <Typography variant="labelRegular">QMR only</Typography>
                        </div>
                        <Link to={`/qmrs/${qmrId}`} target="_blank">
                            <Icon name="launch" />
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            Retailer/Technician/Corporate:
                        </Typography>
                    </div>
                    <div className="mb-10">
                        <Typography variant="lead">
                            {get(
                                qmr,
                                'retailerTechnicianCorporateMessage',
                                get(qmr, 'retailerTechnicianCorporateMessage')
                            )}
                        </Typography>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            SOA DSQM/NASI:
                        </Typography>
                    </div>
                    <div className="mb-10">
                        <Typography variant="lead">
                            {get(qmr, 'soaDsqmNasiMessage', get(qmr, 'soaDsqmNasiMessage'))}
                        </Typography>
                    </div>
                    <div className="mb-4">
                        <Typography variant="h4" color="blueOne">
                            SNE/SDC DSQMs:
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="lead">
                            {get(qmr, 'sneSdcDsqmMessage', get(qmr, 'sneSdcDsqmMessage'))}
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
};
