import React, { FC } from 'react';
import { View, StyleSheet, ViewProps, TextProps } from 'react-native';

import colors from '@packages/core/styles/colors';

import { Typography } from './typography';

export type BadgeVariants =
    | 'default'
    | 'blue'
    | 'red'
    | 'green'
    | 'yellow'
    | 'gray'
    // Techline Statuses
    | 'DRAFT'
    | 'OPEN'
    | 'CLOSED'
    | 'PENDING'
    | 'UPDATE';

interface TextBadgeProps extends ViewProps {
    variant?: BadgeVariants;
    icon?: JSX.Element | null;
    textStyle?: TextProps['style'];
    children?: React.ReactNode;
}

export const TextBadge: FC<TextBadgeProps> = ({
    icon = null,
    variant = 'default',
    style,
    children,
    textStyle,
    ...props
}) => {
    return (
        <View style={[styles.container, styles[variant], style]} {...props}>
            {icon}

            <Typography variant="h6" style={[styles.text, !!icon && styles.textAfterIcon, textStyle]}>
                {children}
            </Typography>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 2,
        paddingHorizontal: 4,
        borderRadius: 3,
    },
    text: {
        fontSize: 12,
        textTransform: 'uppercase',
    },
    textAfterIcon: {
        marginLeft: 4,
    },

    // Variant backgrounds
    default: {
        backgroundColor: colors.grayThree,
    },
    blue: {
        backgroundColor: colors.blueFour,
    },
    red: {
        backgroundColor: colors.redFour,
    },
    green: {
        backgroundColor: colors.greenFour,
    },
    yellow: {
        backgroundColor: colors.yellowFour,
    },
    gray: {
        backgroundColor: colors.grayThree,
    },

    // Techline Case Statuses
    // Check { caseStatusMap } from '@web/utils

    DRAFT: {
        backgroundColor: colors.grayThree,
    },

    OPEN: {
        backgroundColor: colors.greenThree,
    },

    CLOSED: {
        backgroundColor: colors.grayThree,
    },

    UPDATE: {
        backgroundColor: colors.redThree,
    },

    PENDING: {
        backgroundColor: colors.yellowThree,
    },
});
