import React, { FC, useState, useEffect } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { Typeahead } from 'react-bootstrap-typeahead';
import { analyticsService, failCodesService, qmrsService } from '@web/services/singletons';
import { useTranslation } from 'react-i18next';
import { useQmrState, patchQmr, useQmrDispatch } from '@packages/contexts/qmrs';
import { Label, Button, Icon } from '@packages/ui/shared';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const useFailCodeSearchModalStyles = createUseStyles({
    lookupBySection: {},
    failCodeSearchModal: {
        width: 728,
        maxWidth: 'none',
    },
});

interface FailCodeSearchModalProps {
    show: boolean;
    onHide(): void;
    returnToHome(): void;
    handleSuccess(value: string): void;
}

interface Option {
    label: string;
    value: string;
}

const FailCodeLookupBySection: FC<FailCodeSearchModalProps> = (props) => {
    const classes = useFailCodeSearchModalStyles();
    const { t } = useTranslation();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const qmrId = qmr?.qmrId ?? '';

    const [isSaving, setIsSaving] = useState(false);

    const [sectionInputValue, setSectionInputValue] = useState<Option[]>([]);
    const [subsectionInputValue, setSubsectionInputValue] = useState<Option[]>([]);
    const [repairTypeInputValue, setRepairTypeInputValue] = useState<Option[]>([]);
    const [failCodeInputValue, setFailCodeInputValue] = useState<Option[]>([]);

    const [sectionOptions, setSectionOptions] = useState<Option[]>([]);
    const [subsectionOptions, setSubsectionOptions] = useState<Option[]>([]);
    const [repairCodeOptions, setRepairCodeOptions] = useState<Option[]>([]);
    const [failCodeOptions, setFailCodeOptions] = useState<Option[]>([]);

    useEffect(() => {
        const getSections = async () => {
            const resp = await failCodesService.fetchFailCodeSections({
                qmrId,
            });

            if (!resp.success && resp.aborted) {
                return;
            } else if (!resp.success) {
                throw new Error(resp.data.message);
            }

            const labelSections = resp.data.sections.map((s): Option => {
                return { label: s.sectionName, value: s.sectionNumber.toString() };
            });
            setSectionOptions(labelSections);
        };
        getSections();
    }, [qmrId, setSectionOptions]);

    const handleSectionTypeaheadChange = async (selectedSection: Option[]) => {
        setSectionInputValue(selectedSection);
        setSubsectionInputValue([]);
        setRepairTypeInputValue([]);
        setFailCodeInputValue([]);

        const section = selectedSection[0]?.value;

        if (section) {
            const resp = await failCodesService.lookupFailCodeSubsections({
                qmrId,
                sectionNumber: Number(section),
                sectionName: selectedSection[0]?.label,
            });

            if (!resp.success && resp.aborted) {
                return;
            } else if (!resp.success) {
                throw new Error(resp.data.message);
            }

            const labeledSubsections = resp.data.subsections.map((s) => {
                return { label: s.subsectionName, value: s.subsectionNumber.toString() };
            });

            setSubsectionOptions(labeledSubsections);
        }
    };

    const handleSubsectionTypeaheadChange = (subsectionOption: Option[]) => {
        setSubsectionInputValue(subsectionOption);
        setRepairTypeInputValue([]);
        setFailCodeInputValue([]);

        const section = sectionInputValue[0]?.value;
        const subsection = subsectionOption[0]?.value;

        if (subsection && section) {
            const getFigureFailCodes = async () => {
                const resp = await failCodesService.lookupFailCodeFigures({
                    qmrId,
                    sectionNumber: Number(section),
                    sectionName: sectionInputValue[0]?.label,
                    subsectionNumber: Number(subsection),
                    subsectionName: subsectionOption[0]?.label,
                });
                if (!resp.success && resp.aborted) {
                    return;
                } else if (!resp.success) {
                    throw new Error(resp.data.message);
                }

                const figures = resp.data.figures.map((f): Option => {
                    return { label: `${f.figureCode} - ${f.figureDescription}`, value: f.figureCode };
                });
                setRepairCodeOptions(figures);
            };
            getFigureFailCodes();
        }
    };

    const handleRepairTypeTypeaheadChange = async (repairTypeOption: Option[]) => {
        setRepairTypeInputValue(repairTypeOption);
        setFailCodeInputValue([]);

        const section = sectionInputValue[0]?.value;
        const subsection = subsectionInputValue[0]?.value;
        const figureCode = repairTypeOption[0]?.value;

        if (section && subsection && figureCode) {
            const resp = await failCodesService.lookupFailCodes({
                qmrId,
                sectionNumber: Number(section),
                subsectionNumber: Number(subsection),
                figureCode: figureCode,
            });
            if (!resp.success && resp.aborted) {
                return;
            } else if (!resp.success) {
                throw new Error(resp.data.message);
            }

            const labeledFailCodes = resp.data.failCodes.map((f) => {
                return { label: `${f.failCode} - ${f.description}`, value: f.failCode };
            });
            setFailCodeOptions(labeledFailCodes);
        }
    };

    const handleFailedPartTypeaheadChange = (selected: any) => {
        setFailCodeInputValue(selected);
    };

    const handleSubmit = async () => {
        if (isSaving) {
            return;
        }

        const failCode = failCodeInputValue[0]?.value;
        const failCodeSectionName = sectionInputValue[0]?.label;
        const failCodeSubsectionName = subsectionInputValue[0]?.label;
        const failCodeFigureCode = repairTypeInputValue[0]?.value;

        if (failCode) {
            setIsSaving(true);

            try {
                const updatedQmr = await patchQmr({
                    qmrId,
                    qmrPatch: {
                        failCode,
                        failCodeSectionName,
                        failCodeSubsectionName,
                        failCodeFigureCode,
                    },
                    qmrsService,
                    qmrDispatch,
                });

                if (!updatedQmr) {
                    return;
                }
                setIsSaving(false);
                analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_ADDS_FAILCODE);
                // props.handleSuccess(t('qmr:sections:failCode.successPart'));
                props.onHide();
            } catch (e) {
                setIsSaving(false);
                alert(e.message);
            }
        }
    };

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>{t('qmr:sections:failCode.lookupBySection')}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={classes.lookupBySection}>
                <Form>
                    <Row>
                        <Col xs={12} className="mb-4">
                            <Label>{t('qmr:sections:failCode.section')}</Label>

                            <Typeahead
                                id="fail-code-search-modal__section"
                                onChange={handleSectionTypeaheadChange}
                                options={sectionOptions}
                                placeholder={t('qmr:sections:failCode.chooseSection')}
                                selected={sectionInputValue}
                            />
                        </Col>

                        <Col xs={12} className="mb-4">
                            <Label>{t('qmr:sections:failCode.subsection')}</Label>

                            <Typeahead
                                disabled={!sectionInputValue.length}
                                id="fail-code-search-modal__subsection"
                                onChange={handleSubsectionTypeaheadChange}
                                options={subsectionOptions}
                                placeholder={t('qmr:sections:failCode.chooseSubsection')}
                                selected={subsectionInputValue}
                            />
                        </Col>

                        <Col xs={12} className="mb-4">
                            <Label>{t('qmr:sections:failCode.repairType')}</Label>

                            <Typeahead
                                disabled={!subsectionInputValue.length}
                                id="fail-code-search-modal__repair-type"
                                onChange={handleRepairTypeTypeaheadChange}
                                options={repairCodeOptions}
                                placeholder={t('qmr:sections:failCode.chooseFigure')}
                                selected={repairTypeInputValue}
                            />
                        </Col>

                        <Col xs={12} className="mb-4">
                            <Label>{t('qmr:sections:failCode.failedPart')}</Label>

                            <Typeahead
                                disabled={!repairTypeInputValue.length}
                                id="fail-code-search-modal__failed-part"
                                onChange={handleFailedPartTypeaheadChange}
                                options={failCodeOptions}
                                placeholder={t('qmr:sections:failCode.chooseFailCode')}
                                selected={failCodeInputValue}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer className="justify-content-between">
                <Button
                    onPress={props.returnToHome}
                    variant="ghost-blue"
                    iconLeft={<Icon name="chevron-left" color="blueOne" />}
                >
                    Back
                </Button>

                <Button
                    disabled={
                        !sectionInputValue.length ||
                        !subsectionInputValue.length ||
                        !repairTypeInputValue.length ||
                        !failCodeInputValue.length
                    }
                    onPress={handleSubmit}
                    variant="primary"
                    isLoading={isSaving}
                >
                    {t('qmr:sections:failCode.addFailCode')}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default FailCodeLookupBySection;
