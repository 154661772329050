import React, { FC } from 'react';
import colors from '@packages/core/styles/colors';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { DimensionValue, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import src from 'react-select/dist/declarations/src';
import { analyticsService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

type HelpfulLink = {
    linkText: string;
    targetUri: string;
};
type QmrsDashboardHelpfulLinksProps = {
    title: string;
    links: HelpfulLink[];
};

const styles = StyleSheet.create({
    section: {
        paddingVertical: 12,
        //paddingHorizontal: 20,
    },
    buttonOuter: {
        alignItems: 'flex-start',
    },
    buttonRowText: {
        width: 'calc(100% - 40px)' as DimensionValue,
    },
    button: {
        textDecorationLine: 'none',
        height: 40,
        width: 40,
        borderRadius: 4,
        padding: 8,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '32px' as DimensionValue,
        paddingRight: '32px' as DimensionValue,
        paddingTop: '8px' as DimensionValue,
        paddingBottom: '8px' as DimensionValue,
        gap: 4,
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: colors.grayFour,
    },
});

const QmrsDashboardHelpfulLinks: FC<{ qmrsDashboardHelpfullinksProps: QmrsDashboardHelpfulLinksProps }> = ({
    qmrsDashboardHelpfullinksProps,
}) => {
    const { title, links } = qmrsDashboardHelpfullinksProps;
    const { t } = useTranslation();
    return (
        <>
            <View>
                <View style={styles.section}>
                    <View style={styles.row}>
                        <Typography variant="h6">{t(title || 'Helpful links')}</Typography>
                    </View>
                    {links.map((link) => (
                        <TouchableOpacity
                            onPress={() => {
                                window.open(link.targetUri, '_blank');
                                analyticsService.logEvent(ANALYTICS_EVENTS.QMRDASH_USER_CLICKED_HELPFUL_LINK, {
                                    linkText: link.linkText,
                                    url: link.targetUri,
                                });
                            }}
                        >
                            <View style={styles.row}>
                                <View style={styles.buttonRowText}>
                                    <Typography variant="caption">{link.linkText}</Typography>
                                </View>
                                <View style={styles.buttonOuter}>
                                    <Button
                                        variant="link"
                                        onPress={() => {
                                            window.open(link.targetUri, '_blank');
                                        }}
                                        style={styles.button}
                                    >
                                        <Icon size={16} name="chevron-right" />
                                    </Button>
                                </View>
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
            </View>
        </>
    );
};

export default QmrsDashboardHelpfulLinks;
