import React, { FC, useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { Button, Label } from '@packages/ui/shared';

import { FeedbackRecipient } from '@packages/models/api';
import { feedbackService } from '@web/services/singletons';
import { useTranslation } from 'react-i18next';
import { useAbortController } from '@packages/core/http';

interface SendFeedbackModalProps extends ModalProps {
    onFeedbackSent: (feedbackSessionId: string) => void;
}

export const SendFeedbackModal: FC<SendFeedbackModalProps> = ({ show, onHide, onFeedbackSent }) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();

    const [recipientOptions, setRecipientOptions] = useState<FeedbackRecipient[]>([]);
    const [selectedRecipients, setSelectedRecipients] = useState<FeedbackRecipient[]>([]);

    const searchRecipients = useCallback(
        async (query = '', selected: FeedbackRecipient[] = []) => {
            try {
                const response = await feedbackService.searchFeedbackRecipients({
                    query,
                    selectedIds: selected.map((r) => r.id),
                    isRequestFeedback: false,
                    signal: abortSignalRef.current,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setRecipientOptions(response.data.recipients.availableRecipients);
            } catch (error) {
                alert(error.message);
            }
        },
        [abortSignalRef]
    );

    const startSession = useCallback(() => {
        return feedbackService
            .startFeedbackSession({
                feedbackRecipientIds: selectedRecipients.map((r) => r.id),
                feedbackRequest: false,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }
                onFeedbackSent(response.data.session.feedbackSessionId);
            })
            .catch((error) => {
                alert(error.message);
            });
    }, [abortSignalRef, onFeedbackSent, selectedRecipients]);

    useEffect(() => {
        if (!show) {
            setRecipientOptions([]);
            setSelectedRecipients([]);
            return;
        }

        searchRecipients();
    }, [abortSignalRef, searchRecipients, show]);

    return (
        <Modal show={show} centered={true} onHide={onHide} size="lg">
            <Modal.Header closeButton placeholder={''}>
                <Modal.Title>Send Feedback</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Label
                    required
                    hint={t(
                        'modals:sendFeedback.recipientSearch.hint',
                        'Note: You can only send feedback to one person or entity at a time'
                    )}
                >
                    {t('modals:sendFeedback.recipientSearch.label', 'Enter name, entity, or keyword')}
                </Label>

                <AsyncTypeahead
                    multiple
                    id="feedback-recipients"
                    selected={selectedRecipients}
                    labelKey="displayName"
                    isLoading={false}
                    useCache={false}
                    minLength={1}
                    onSearch={(query) => {
                        searchRecipients(query, selectedRecipients);
                    }}
                    options={recipientOptions}
                    onChange={(selectedArr) => {
                        // force only one selection
                        const selectedOne = selectedArr.pop();
                        setSelectedRecipients(selectedOne ? [selectedOne] : []);
                    }}
                />
            </Modal.Body>

            <Modal.Footer>
                <div className="mr-2">
                    <Button variant="ghost-blue" onPress={onHide}>
                        {t('modals:sendFeedback.actions.cancel', 'Cancel')}
                    </Button>
                </div>

                <Button variant="primary" onPress={startSession}>
                    {t('modals:sendFeedback.actions.continue', 'Continue')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
