import React, { FC, createContext, useState } from 'react';
import { SavedSearch } from '@packages/models/api';

interface AdvancedSearchContextConfig {
    recentSavedSearches: SavedSearch[];
    setRecentSavedSearches: React.Dispatch<React.SetStateAction<SavedSearch[]>>;
}

export const AdvancedSearchContext = createContext({} as AdvancedSearchContextConfig);

export const AdvancedSearchProvider: FC = ({ children }) => {
    const [recentSavedSearches, setRecentSavedSearches] = useState<SavedSearch[]>([]);
    const value = {
        recentSavedSearches,
        setRecentSavedSearches,
    };

    return <AdvancedSearchContext.Provider value={value}>{children}</AdvancedSearchContext.Provider>;
};
