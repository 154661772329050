import {
    DraggableWorksheetFormComponent,
    FormElementOption,
    WorksheetFormElement,
    WorksheetForm,
    WorksheetGroupStatus,
} from '@packages/models/api';

import { cloneDeep } from 'lodash';
function flattenNestedArray(draggableFormComponents: DraggableWorksheetFormComponent[]) {
    let flattenedArray: WorksheetFormElement[] = [];
    draggableFormComponents.forEach((element) => {
        let _element: WorksheetFormElement = {
            worksheetElementId: element.formElementId,
            worksheetElementTypeId: element.formElementTypeId,
            worksheetElementLabel: element.formElementLabel,
            worksheetElementTooltip: element.formElementTooltip,
            worksheetElementDescription: element.formElementDescription,
            worksheetElementOrder: element.formElementOrder!,
            worksheetElementDefaultValue: false,
            worksheetElementRequired: element?.required || false,
            worksheetElementAllowMultiple: false,
            worksheetElementHasRule: element?.formElementHasRule,
            options: element.options?.map((option: FormElementOption, index: number) => {
                const opt = {
                    ...option,
                    optionDisplayOrder: index,
                    trgtWorksheetElementIdList: option.trgtElementIdList || [],
                    assets: option.assets,
                };
                delete opt.trgtElementIdList;
                return opt;
            }),
            formElementFile: element.formElementFile,
            assets: element.assets,
        };

        // When add new element it had action INSERT.
        if (element.action) {
            _element.action = element.action;
        }

        flattenedArray.push(_element);

        if (element.optionConditionsElements && element.optionConditionsElements.length) {
            const nestedFlattenedArray = flattenNestedArray(element.optionConditionsElements);
            flattenedArray = flattenedArray.concat(nestedFlattenedArray);
        }

        delete element.optionConditionsElements;
    });

    return flattenedArray;
}

export const prepareElements = (elements: DraggableWorksheetFormComponent[]) => {
    return flattenNestedArray(elements);
};

export const createWorksheet = ({
    worksheetSettings,
    fromComponents,
}: {
    worksheetSettings: WorksheetForm;
    fromComponents: DraggableWorksheetFormComponent[];
}) => {
    const elementClone = cloneDeep(fromComponents);
    return {
        caseTypeId: worksheetSettings?.caseTypeId,
        northstarId: worksheetSettings?.northstarId,
        worksheetGroupName: worksheetSettings?.worksheetGroupName,
        groupStatus: worksheetSettings?.groupStatus,
        displayname: worksheetSettings?.displayname,
        elements: prepareElements(elementClone),
        worksheetStatus: WorksheetGroupStatus.Pending,
        version: worksheetSettings?.version || 1,
        // Add for description field.
        // description: worksheetSettings.description || 'asd',
    };
};
