import React, { useState } from 'react';

interface Props {
    handleIsValid?: (valid: boolean) => void;
    required: boolean;
}
export const useValidation = ({ handleIsValid, required }: Props) => {
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleValidation = ({ currentValue, prevValue }: { currentValue: string; prevValue: string }) => {
        if (!required || !handleIsValid || currentValue.trim().length > 2000) {
            return;
        }

        currentValue = currentValue.trim();
        prevValue = prevValue.trim();
        if (prevValue !== '' && currentValue === '') {
            handleIsValid(false);
            setErrorMessage('This is a required field');
        } else if (prevValue === '' && currentValue !== '') {
            handleIsValid(true);
            setErrorMessage('');
        }
    };

    return {
        handleValidation,
        errorMessage,
    };
};
