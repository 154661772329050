import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '@packages/ui/shared';

const notFoundStyles = createUseStyles({
    qmrContainer: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    qmrBody: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        height: '80vh',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    titleContainer: {
        marginTop: 30,
        marginBottom: 30,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 22,
    },
});

export const QmrNotFound = () => {
    const { t } = useTranslation();
    const styles = notFoundStyles();
    return (
        <div className={`qmr-not-found ${styles.qmrContainer}`}>
            <div className={`qmr-not-found-body ${styles.qmrBody}`}>
                <div className="qmr-not-found-icon">
                    <Icon name="eye-off" hero={true} />
                </div>
                <div className={styles.titleContainer}>
                    <Typography
                        style={{
                            fontWeight: 'bold',
                            fontSize: 22,
                        }}
                    >
                        {t('qmr:sections:notFound.title', 'QMR Not Found')}
                    </Typography>
                </div>
                <div>
                    <Typography>{t('qmr:sections:notFound.bodyOne', 'The QMR record could not be found.')}</Typography>
                </div>
                <div>
                    <Typography>
                        {t('qmr:sections:notFound.bodyTwo', 'Please contact your DSQM or the Support Desk.')}
                    </Typography>
                </div>
            </div>
        </div>
    );
};
