import React, { FC } from 'react';
import NoMatch from '@web/views/no-match';
import { BmisDetail } from '@web/bmis/views/bmis-detail';
import { BmisList } from '@web/bmis/views/bmis-list';
import { BmisProvider } from '@packages/contexts/bmis';
import { Route, Routes } from 'react-router-dom';

export const BmisRouter: FC = () => {
    return (
        <BmisProvider>
            <Routes>
                <Route path="/" element={<BmisList />} />
                <Route path="/:rowGuid" element={<BmisDetail />} />
                <Route
                    path="*"
                    element={
                        <>
                            <NoMatch />
                        </>
                    }
                />
            </Routes>
        </BmisProvider>
    );
};
