import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { failCodesService } from '@web/services/singletons';
import { FailCode, FailCodeSubsection, FailCodeSection } from '@packages/models/api';
import { useTranslation } from 'react-i18next';

import { Label, Button, Icon } from '@packages/ui/shared';
import { useAbortController } from '@packages/core/http';

interface TechlineNoTroubleFoundProps {
    onHide(): void;
    returnToHome(): void;
    handleShowMatchingFailCodesTrue(failCodes: Array<TechlineFailCodeContainer>): void;
    handleShowMatchingFailCodesFalse(): void;
    updateCaseDetails: any;
    vehicleDetails: any;
}

export type TechlineFailCodeContainer = FailCode & FailCodeSection & FailCodeSubsection;

interface Option {
    label: string;
    value: string;
}

export const TechlineFailCodeNoTroubleFound = (props: TechlineNoTroubleFoundProps) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState('');

    const [specificVehicleLocationOptions, setSpecificVehicleLocationOptions] = useState<Option[]>([]);
    const [howOftenConditionOccursOptions, setHowOftenConditionOccursOptions] = useState<Option[]>([]);
    const [symptomConditionConcernOptions, setSymptomConditionConcernOptions] = useState<Option[]>([]);

    const [specificVehicleLocationValue, setSpecificVehicleLocationValue] = useState<Option[]>([]);
    const [howOftenConditionOccursValue, setHowOftenConditionOccursValue] = useState<Option[]>([]);
    const [symptomConditionConcernValue, setSymptomConditionConcernValue] = useState<Option[]>([]);

    const getLocations = useCallback(async () => {
        const resp = await failCodesService.fetchFailCodeLocations({});

        if (!resp.success && resp.aborted) {
            return;
        } else if (!resp.success) {
            setIsLoading(false);
            throw new Error(resp.data.message);
        }

        const locationOptions = resp.data.locations.map((l) => {
            return { label: `${l.code} - ${l.description}`, value: l.code };
        });
        setSpecificVehicleLocationOptions(locationOptions);
    }, []);

    const getHowOften = useCallback(
        async (locationCode: any) => {
            const resp = await failCodesService.fetchFailCodeFrequencies({
                locationCode,
                signal: abortSignalRef.current,
            });
            if (!resp.success && resp.aborted) {
                return;
            } else if (!resp.success) {
                setIsLoading(false);
                throw new Error(resp.data.message);
            }

            const labelOptions = resp.data.frequencies.map((f) => {
                return { label: `${f.code} - ${f.description}`, value: f.code };
            });
            setHowOftenConditionOccursOptions(labelOptions);
        },
        [abortSignalRef]
    );

    const getConcerns = useCallback(
        async (locationCode: any, frequencyCode: any) => {
            const resp = await failCodesService.fetchFailCodeConcerns({
                locationCode,
                frequencyCode,
                signal: abortSignalRef.current,
            });
            if (!resp.success && resp.aborted) {
                return;
            } else if (!resp.success) {
                setIsLoading(false);
                throw new Error(resp.data.message);
            }

            const labelOptions = resp.data.concerns.map((c) => {
                return { label: `${c.code} - ${c.description}`, value: c.code };
            });
            setSymptomConditionConcernOptions(labelOptions);
        },
        [abortSignalRef]
    );

    useEffect(() => {
        getLocations();
    }, [getLocations]);

    const handleLocationTypeaheadChange = async (selected: Option[]) => {
        setError('');
        setSpecificVehicleLocationValue(selected);
        setSymptomConditionConcernValue([]);
        setHowOftenConditionOccursValue([]);

        const locationCode = selected[0]?.value;
        if (locationCode) {
            getHowOften(locationCode);
        }
    };

    const handleHowOftenConditionOccursTypeaheadChange = async (selected: Option[]) => {
        setError('');
        setHowOftenConditionOccursValue(selected);
        setSymptomConditionConcernValue([]);

        const locationCode = specificVehicleLocationValue[0]?.value;
        const frequencyCode = selected[0]?.value;
        if (locationCode && frequencyCode) {
            getConcerns(locationCode, frequencyCode);
        }
    };

    const handleSymptomConditionTypeaheadChange = async (selected: any) => {
        setError('');
        setSymptomConditionConcernValue(selected);
    };

    const handleReturnToHome = async () => {
        props.handleShowMatchingFailCodesFalse();
        props.returnToHome();
    };

    const handleSubmit = async () => {
        props.updateCaseDetails({
            repairCode: specificVehicleLocationValue[0].value,
            failCode: specificVehicleLocationValue[0].value,
            section: howOftenConditionOccursValue[0].value,
            subSection: symptomConditionConcernValue[0].value,
        });
        props.onHide();
    };

    return (
        <>
            <Modal.Header closeButton placeholder={''}>
                <h4 className="mb-0">{t('qmr:sections:failCode.noTroubleFound')}</h4>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger" show>
                        {error}
                    </Alert>
                )}

                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <Row>
                        <Col xs={12} className="mb-4">
                            <Label>Specific Vehicle Location</Label>

                            <Typeahead
                                id="fail-code-no-trouble-modal-specific-vehicle__section"
                                onChange={handleLocationTypeaheadChange}
                                options={specificVehicleLocationOptions}
                                placeholder={t('qmr:sections:failCode.enterLetterCode')}
                                selected={specificVehicleLocationValue}
                            />
                        </Col>

                        <Col xs={12} className="mb-4">
                            <Label>{t('qmr:sections:failCode.howOftenConditionOccurs')}</Label>

                            <Typeahead
                                disabled={!specificVehicleLocationValue.length}
                                id="fail-code-search-modal__specific_vehicle_location"
                                onChange={handleHowOftenConditionOccursTypeaheadChange}
                                options={howOftenConditionOccursOptions}
                                placeholder={t('qmr:sections:failCode.enterLetterCode')}
                                selected={howOftenConditionOccursValue}
                            />
                        </Col>

                        <Col xs={12} className="mb-4">
                            <Label>{t('qmr:sections:failCode.symptomCondition')}</Label>

                            <Typeahead
                                disabled={!howOftenConditionOccursValue.length}
                                id="fail-code-search-modal__repair-type"
                                onChange={handleSymptomConditionTypeaheadChange}
                                options={symptomConditionConcernOptions}
                                placeholder={t('qmr:sections:failCode.enterLetterCode')}
                                selected={symptomConditionConcernValue}
                            />
                        </Col>
                    </Row>
                )}
            </Modal.Body>

            <Modal.Footer className="justify-content-between">
                <Button
                    onPress={handleReturnToHome}
                    variant="ghost-blue"
                    iconLeft={<Icon name="chevron-left" color="blueOne" />}
                >
                    {t('qmr:sections:failCode.back')}
                </Button>

                <Button
                    disabled={
                        !specificVehicleLocationValue.length ||
                        !howOftenConditionOccursValue.length ||
                        !symptomConditionConcernValue.length
                    }
                    isLoading={isSaving}
                    onPress={handleSubmit}
                >
                    {t('qmr:sections:failCode.addFailCode')}
                </Button>
            </Modal.Footer>
        </>
    );
};
