import { WorksheetAnswerElement, WorksheetSubmissions } from './worksheets';

export interface ITechline {
    techlineId: string;
    backgroundImage: string;
    message: string;
    header: string;
    showButton: boolean;
    buttonText: string;
    buttonLink: string;
    linkOne: string;
    linkTwo: string;
    linkThree: string;
}
export enum TechlineStatusCode {
    DRAFT = 'DRAFT',
    OPEN = 'OPEN',
    OPEN_ESCALATED = 'OPEN-ESCALATED',
    UPDATE_REQUESTED = 'UPDATE_REQUESTED',
    PENDING_TECHLINE = 'PENDING TECHLINE',
    CLOSED = 'CLOSED',
    CLOSED_RESOLVED = 'CLOSED-RESOLVED',
}

export interface Retailer {
    accidentFlag: boolean;
    airbagRuptureFlag: boolean;
    asset: { assetId: string; enginId: string; transmissionNumber: string };
    caseId: string;
    caseTypes: { caseType: string; caseTypeSourceId: string; isPrimary: string }[];
    customerConcern: string;
    deathFlag: boolean;
    diagnosticTroubleCodes: [];
    didTheConditionImprove: string;
    fireFlag: boolean;
    hasBeenAbleToDuplicate: boolean;
    injuryFlag: boolean;
    mileage: number;
    modifiedOn: string;
    modifiedOnDescription: string;
    previousDiagnosisAndRepairs: string;
    previousMileage: number;
    previousRONumber: string;
    product: {
        colorCategory: string;
        model: string;
        modelNumber: string;
        modelYear: string;
        optionCode: string;
        productId: string;
    };
    propertyDamage: string;
    repairCode: string;
    repairOrderNumber: string;
    repairOrderOpenDate: string;
    retailerProfiles: {
        city: string;
        districtCode: string;
        districtId: string;
        isPrimary: boolean;
        name: string;
        postalCode: string;
        regionCode: string;
        regionId: string;
        retailerNumber: string;
        retailerProfileId: string;
        state: string;
        userName: string;
        zoneCode: string;
        zoneId: string;
        soaUserId: string;
    }[];
    retailerUniqueProfiles?: {
        city: string;
        districtCode: string;
        districtId: string;
        isPrimary: boolean;
        name: string;
        postalCode: string;
        regionCode: string;
        regionId: string;
        retailerNumber: string;
        retailerProfileId: string;
        state: string;
        userName: string;
        zoneCode: string;
        zoneId: string;
    }[];
    rolloverFlag: boolean;
    rolloverFireAccidentInjuryDeathDamage: string;
    section: string;
    stateCode: number;
    statusCode: number;
    subSection: string;
    symptoms: [];
    techShareCaseClosureDescription: string;
    techlineStatusCode: TechlineStatusCode;
    technician5StepsBeforeFailureDuplication: string;
    technicianDescription: string;
    vehicleHasBeenInBefore: boolean;
    wasTheVehicleRepaired: boolean;
    werePartsReplaced: boolean;
    whatPartsWereReplaced: string;
    worksheetSubmissions: WorksheetSubmissions[];
}

export interface RetrieveCaseResponse {
    caseDetails: {
        message: string;
        results: Retailer;
        status: string;
        statusCode: number;
    };
    vinDetails: any;
    caseAssetsDetails: any;
    caseTypeDetails?: any;
}

export interface WorksheetOnCaseSubmit {
    submissions: { submissionId: string; worksheetId: string; answers: WorksheetAnswerElement[] }[];
}
