import { Account, FailCodeApproverSetting, Retailer } from '@packages/models/api';
import { Button, Label, Typography } from '@packages/ui/shared';
import { Chip } from '@web/administration/components';
import AsyncPage from '@web/components/async-page';
import { DetailView, DetailViewBody, DetailViewContent, DetailViewHeader } from '@web/components/detail-view';
import Select from '@web/components/select';
import { accountsService, qmrsService } from '@web/services/singletons';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate, useParams } from 'react-router-dom';

export const RetailerForm: FC = () => {
    const navigate = useNavigate();
    const { retailerId } = useParams<{ retailerId?: string }>();

    const [retailer, setRetailer] = useState<Retailer>();
    const [approverSettings, setApproverSettings] = useState<FailCodeApproverSetting[]>([]);
    const [failCodeApprovers, setFailCodeApprovers] = useState<Account[]>([]);
    const [selectedApproverSetting, setSelectedApproverSetting] = useState('');

    const fetchData = useCallback(async () => {
        if (!retailerId) {
            return;
        }

        qmrsService.fetchFailCodeApproverSettings({}).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            console.log(response.data);
            setApproverSettings(response.data.failCodeApproverSettings);
        });

        qmrsService.fetchRetailerbyId({ retailerId }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setRetailer(response.data.retailer);
            setSelectedApproverSetting(response.data.retailer.failCodeApproverSettingId);
        });

        qmrsService.fetchRetailerFailCodeApprovers({ retailerId }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setFailCodeApprovers(response.data.accounts);
        });
    }, [retailerId]);

    function handleCancelButtonPress() {
        navigate(-1);
    }

    async function handleSaveButtonPress() {
        if (!retailerId) {
            return;
        }

        try {
            await qmrsService
                .updateRetailerFailCodeApprovers({
                    retailerId,
                    accountIds: failCodeApprovers.map((fCA) => fCA.accountId),
                })
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }
                });

            await qmrsService
                .updateRetailerApproverSetting({
                    retailerId,
                    approverSettingId: selectedApproverSetting,
                })
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }
                });

            navigate(-1);
        } catch (e) {
            window.alert(e.message);
        }
    }

    /* ------------------------------------------------- */
    /* Approvers Typeahead */
    /* ------------------------------------------------- */
    const approversTypeaheadRef = useRef<AsyncTypeahead<Account> | null>(null);
    const [approversTypeaheadIsLoading, setManagersTypeaheadIsLoading] = useState(false);
    const [approversTypeheadOptions, setManagersTypeaheadOptions] = useState<Account[]>([]);

    const handleManagersTypeaheadSearch = useCallback(
        async (query: string) => {
            setManagersTypeaheadIsLoading(true);

            try {
                const response = await accountsService.getAccounts({
                    query,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const managersToShow = response.data.accounts.filter((account) => {
                    const matchFound = !!failCodeApprovers.find((manager) => manager.accountId === account.accountId);

                    if (matchFound) {
                        return false;
                    } else {
                        return true;
                    }
                });

                setManagersTypeaheadOptions(managersToShow);
            } catch (error) {
                setManagersTypeaheadOptions([]);
                window.alert(error.message);
            }

            setManagersTypeaheadIsLoading(false);
        },
        [failCodeApprovers]
    );

    const handleApproversTypeaheadChange = useCallback(
        ([account]: Account[]) => {
            if (!account) {
                return;
            }

            const managersClone = cloneDeep(failCodeApprovers);

            if (approversTypeaheadRef.current) {
                // @ts-ignore
                approversTypeaheadRef.current.clear();
            }

            managersClone.push(account);
            setFailCodeApprovers(managersClone);
        },
        [failCodeApprovers]
    );

    const handleApproverChipRemove = useCallback(
        (account: Account) => {
            const managerIndexToRemove = failCodeApprovers.findIndex(
                (manager) => manager.accountId === account.accountId
            );
            const managersClone = cloneDeep(failCodeApprovers);

            managersClone.splice(managerIndexToRemove, 1);
            setFailCodeApprovers(managersClone);
        },
        [failCodeApprovers]
    );

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <DetailViewHeader
                    breadcrumbs={
                        retailerId
                            ? [
                                  { to: '/administration/retailers', title: 'Manage Retailer' },
                                  {
                                      to: `/administration/retailers/${retailerId}`,
                                      title: 'Retailer',
                                  },
                                  {
                                      to: `/administration/retailers/${retailerId}/edit`,
                                      title: 'Edit',
                                  },
                              ]
                            : undefined
                    }
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <Typography variant="h3">{retailer && `${retailer?.name} #(${retailer?.code})`}</Typography>
                        <div className="d-flex">
                            <Button variant="ghost-blue" onPress={handleCancelButtonPress}>
                                Cancel
                            </Button>
                            <Button style={{ marginLeft: 8 }} onPress={handleSaveButtonPress}>
                                Save Retailer
                            </Button>
                        </div>
                    </div>
                </DetailViewHeader>
                <DetailViewContent topOffset={retailerId ? 'breadcrumb' : 'header'}>
                    <DetailViewBody>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4">Fail Code Settings</Typography>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Label>Fail Code Approval Setting</Label>
                                    <Select
                                        value={selectedApproverSetting}
                                        options={approverSettings.map((aS) => {
                                            return {
                                                value: aS.failCodeApproverSettingId,
                                                title: aS.description,
                                            };
                                        })}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                            setSelectedApproverSetting(event.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col xs={6}>
                                <Form.Group>
                                    <Label>Authorized Approvers</Label>
                                    <AsyncTypeahead
                                        ref={approversTypeaheadRef}
                                        id="approvers-async-typeahead"
                                        minLength={1}
                                        placeholder="Users who can approve fail codes"
                                        labelKey={(acct) =>
                                            `${acct.name} ${acct.soaUsername ? `(${acct.soaUsername})` : ''}`
                                        }
                                        isLoading={approversTypeaheadIsLoading}
                                        onSearch={handleManagersTypeaheadSearch}
                                        options={approversTypeheadOptions}
                                        onChange={handleApproversTypeaheadChange}
                                        useCache={false}
                                    />
                                </Form.Group>
                                <ul className="list-unstyled">
                                    {failCodeApprovers.map((approver) => {
                                        return (
                                            <li key={approver.accountId} className="mb-1">
                                                <Chip
                                                    title={approver.name}
                                                    removable
                                                    onRemove={() => {
                                                        handleApproverChipRemove(approver);
                                                    }}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    </DetailViewBody>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};
