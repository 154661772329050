import * as React from 'react';
import { SharedCategoryContextProviders } from './context/shared-category-context-providers';
import { createUseStyles } from 'react-jss';

const userArticleStyles = createUseStyles({
    userArticles: {
        overflowY: 'auto',
        height: '100%',
    },
});

export const UserArticles = (): JSX.Element => {
    const classes = userArticleStyles();
    const [editArticlePlaceholder, setEditArticle] = React.useState<JSX.Element | null>(null);

    React.useEffect(() => {
        const createEditArticle = async () => {
            const { ArticleContainer } = await import('./components/articles/article-container');
            setEditArticle(<ArticleContainer />);
        };

        if (process.env.NODE_ENV !== 'test') {
            createEditArticle();
        }
    }, []);

    return (
        <SharedCategoryContextProviders>
            <section className={classes.userArticles}>{editArticlePlaceholder}</section>
        </SharedCategoryContextProviders>
    );
};
