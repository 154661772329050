import { isFunction } from 'lodash';
import * as Sentry from '@sentry/react';

import {
    AssetUploadInfo,
    AssetUploadsService as AbstractAssetUploadsService,
    AssetUploadEventHandlers,
} from '@packages/contexts/asset-uploads';
import { CreateAssetsResponseDto, Asset } from '@packages/models/api/assets';

export class AssetUploadsService extends AbstractAssetUploadsService {
    private xhrs = new Map<string, XMLHttpRequest>();
    private lastUploadId = 0;

    protected startUploads(assetUploads: AssetUploadInfo[], newAssets: CreateAssetsResponseDto): Promise<string[]> {
        return Promise.all(
            assetUploads.map(async (assetUpload, idx) => {
                const createdAsset = newAssets.assets[idx];
                const { presignedUpload, overlayPresignedUpload } = createdAsset;

                if (overlayPresignedUpload) {
                    await new Promise((resolve, reject) => {
                        const { httpMethod, url, httpHeaders } = overlayPresignedUpload;
                        const xhr = new XMLHttpRequest();
                        xhr.open(httpMethod, url, true);

                        Object.entries(httpHeaders).forEach(([header, value]) => {
                            xhr.setRequestHeader(header, value);
                        });

                        xhr.send(assetUpload.overlayUri);

                        xhr.addEventListener('error', reject);
                        xhr.addEventListener('load', resolve);
                    });
                }

                const uploadId = `${++this.lastUploadId}`;
                const xhr = new XMLHttpRequest();

                this.xhrs.set(uploadId, xhr);
                this.subscribeToEvents(uploadId, createdAsset.asset, assetUpload.eventHandlers);

                xhr.open(presignedUpload.httpMethod, presignedUpload.url, true);

                Object.entries(presignedUpload.httpHeaders).forEach(([header, value]) => {
                    xhr.setRequestHeader(header, value);
                });

                // Setting content-type only for heic file format, else it should be default content type
                if (assetUpload.contentType === 'image/heic') {
                    xhr.setRequestHeader('Content-Type', 'image/heif');
                }
                xhr.send(assetUpload.assetUri);

                return uploadId;
            })
        );
    }

    subscribeToEvents(uploadId: string, asset: Asset, handlers?: AssetUploadEventHandlers) {
        if (!handlers) {
            return;
        }

        const xhr = this.xhrs.get(uploadId);

        if (!xhr) {
            return;
        }

        xhr.upload.addEventListener('progress', (evt) => {
            if (evt.lengthComputable) {
                const progress = Math.round((evt.loaded * 100) / evt.total);

                isFunction(handlers.onProgress) && handlers.onProgress(uploadId, asset, progress);
            }
        });

        xhr.addEventListener('error', (evt) => {
            const message: string = typeof evt === 'string' ? evt : 'Upload failed. please try again!';

            Sentry.captureException(evt);

            isFunction(handlers.onError) && handlers.onError(uploadId, asset, message);
            this.xhrs.delete(uploadId);
        });

        xhr.addEventListener('load', () => {
            isFunction(handlers.onCompleted) && handlers.onCompleted(uploadId, asset);
            this.xhrs.delete(uploadId);
        });
    }
}
