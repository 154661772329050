import React, { useMemo } from 'react';
import { WorksheetFormElement } from '@web/administration/views/worksheets/components/worksheet-form-element';
import { prepareFormElementsWithOrder } from '@web/administration/views/worksheets/utils/worksheet-form';
import { Divider, TextBadge, Typography } from '@packages/ui/shared';
import AsyncPage from '@web/components/async-page';
import colors from '@packages/core/styles/colors';
import { useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Loader from '@web/components/loader';
import { DropResult } from 'react-beautiful-dnd';

import useWorksheetData from '../hooks/use-worksheet-data';
import { statusVariants } from '../components/worksheets-table';

const WorksheetPreview = () => {
    const classes = useNewWorksheetHeaderStyles();
    const { worksheetId } = useParams<{ worksheetId: string }>();
    const { fetching, actions, worksheetData } = useWorksheetData({ worksheetFormId: worksheetId });

    //worksheet dto to FormElement
    const elements = useMemo(() => {
        return prepareFormElementsWithOrder(worksheetData?.elements || []);
    }, [worksheetData]);

    return (
        <>
            <AsyncPage fetchData={actions.fetchData}>
                {fetching ? (
                    <Loader />
                ) : (
                    worksheetData && (
                        <>
                            <div className={classes.view} style={{ backgroundColor: colors.grayTwo }}>
                                <div
                                    style={{ backgroundColor: colors.white }}
                                    className={`d-flex flex-row align-items-center justify-content-between p-4`}
                                >
                                    <div className="ml-5 d-flex flex-row align-items-center">
                                        <Typography color="black" variant="h4">
                                            {worksheetData?.displayname}
                                        </Typography>
                                        <div className="ml-1">
                                            <Typography variant="h3">{`(v${worksheetData.version})`}</Typography>
                                        </div>
                                        <div className="ml-1">
                                            <TextBadge variant={statusVariants[worksheetData.worksheetStatus]}>
                                                {worksheetData?.groupStatus}
                                            </TextBadge>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="w-50 p-5 mb-5 mt-5 text-center m-auto"
                                    style={{ backgroundColor: colors.white }}
                                >
                                    {elements.map((elements) => {
                                        return (
                                            <WorksheetFormElement
                                                key={elements.formElementId}
                                                item={elements}
                                                handleDragEnd={(result: DropResult) => {}}
                                                preview
                                            />
                                        );
                                    })}
                                    <Divider />
                                </div>
                            </div>
                        </>
                    )
                )}
            </AsyncPage>
        </>
    );
};

const useNewWorksheetHeaderStyles = createUseStyles({
    view: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
    },
    header: {
        flexShrink: 0,
        display: 'flex',
        padding: '0 30px',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: colors.white,
        height: 80,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
});

export default WorksheetPreview;
