import React, { FC, useEffect, useState } from 'react';

import { BaseFeedbackFormElement, FeedbackFormElementRenderProps } from '@packages/ui/feedback';

import Select from '@web/components/select';
import { SuccessFailBadge, Typography } from '@packages/ui/shared';

const DropdownElement: FC<FeedbackFormElementRenderProps> = ({ item, onAnswerChange, readOnly }) => {
    const [value, setValue] = useState(
        item.selectedOptions && item.selectedOptions[0] ? item.selectedOptions[0].optionId : ''
    );

    const label = <Typography variant="label">{item.formElementLabel}</Typography>;

    useEffect(() => {
        if (value) {
            return;
        }

        if (onAnswerChange) {
            const firstOptionId = item.options && item.options[0] ? item.options[0].optionId : '';

            setValue(firstOptionId);
            onAnswerChange({
                elementId: item.formElementId,
                options: [{ optionId: firstOptionId }],
            });
        }
    }, [item.formElementId, item.options, onAnswerChange, value]);

    if (readOnly) {
        const selectedOption = item.options?.find((o) => o.optionId === value);

        return (
            <>
                {label}
                <div className="d-flex mt-2 mb-8">
                    <SuccessFailBadge success>{selectedOption?.optionLabel}</SuccessFailBadge>
                </div>
            </>
        );
    }

    return (
        <>
            {label}

            <div className="mt-2 mb-8">
                <Select
                    required
                    value={value}
                    options={
                        item.options
                            ? [
                                  { title: 'Select...', value: '', disabled: true },
                                  ...item.options.map((o) => ({ title: o.optionLabel, value: o.optionId })),
                              ]
                            : []
                    }
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        const newVal = event.target.value;

                        setValue(newVal);

                        if (onAnswerChange && item.options) {
                            const selectedOption = item.options.find((o) => o.optionId === newVal);

                            if (!selectedOption) {
                                return;
                            }

                            onAnswerChange({
                                elementId: item.formElementId,
                                options: [{ optionId: selectedOption.optionId }],
                            });
                        }
                    }}
                />
            </div>
        </>
    );
};

export const FeedbackFormElement: FC<FeedbackFormElementRenderProps> = ({ ...props }) => {
    return (
        <BaseFeedbackFormElement
            dropdownRenderer={(dropDownProps) => {
                return <DropdownElement {...dropDownProps} />;
            }}
            {...props}
        />
    );
};
