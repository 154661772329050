import { HttpClient } from '@packages/core/http';

import {
    FeedbackSessionsDto,
    FeedbackSessionDto,
    FeedbackSessionAnswersDto,
    FeedbackSessionConversationDto,
    FeedbackSessionRatingFormDto,
    FeedbackRecipientsDto,
    FeedbackSummaryDto,
    FeedbackGroupType,
    QmrFeedbackFormDto,
} from '@packages/models/api';

export class FeedbackService {
    constructor(private http: HttpClient) {}

    fetchFeedbackSummary({ ignoreCache = false, signal }: { ignoreCache?: boolean; signal: AbortSignal }) {
        return this.http.orchestrateRequest<FeedbackSummaryDto>({
            method: 'GET',
            url: '/feedback/summary',
            ignoreCache,
            signal,
        });
    }

    fetchFeedbackSessions({
        groupType,
        entityId,
        page,
        size,
        ignoreCache = false,
        signal,
    }: {
        groupType: FeedbackGroupType;
        entityId?: string;
        page: number;
        size: number;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const params = new URLSearchParams({
            page: '' + page,
            size: '' + size,
        });

        if (entityId) {
            params.set('entityId', entityId);
        }

        return this.http.orchestrateRequest<FeedbackSessionsDto>({
            method: 'GET',
            url: `/feedback/group/${groupType}?${params.toString()}`,
            ignoreCache,
            signal,
        });
    }

    fetchFeedbackSessionById({
        feedbackSessionId,
        ignoreCache = false,
        signal,
    }: {
        feedbackSessionId?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<FeedbackSessionDto>({
            method: 'GET',
            url: `/feedback/${feedbackSessionId}`,
            ignoreCache,
            signal,
        });
    }

    completeFeedbackSession({
        feedbackSessionId,
        data,
        signal,
    }: {
        feedbackSessionId: string;
        data: FeedbackSessionAnswersDto;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<FeedbackSessionDto>({
            method: 'POST',
            url: `/feedback/${feedbackSessionId}/answers`,
            signal,
            body: data,
        });
    }

    fetchFeedbackSessionConversation({
        feedbackSessionId,
        ignoreCache = false,
        signal,
    }: {
        feedbackSessionId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<FeedbackSessionConversationDto>({
            method: 'GET',
            url: `/feedback/${feedbackSessionId}/conversation`,
            ignoreCache,
            signal,
        });
    }

    addFeedbackSessionConversationComment({
        feedbackSessionId,
        comment,
        signal,
    }: {
        feedbackSessionId: string;
        comment: string;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<FeedbackSessionConversationDto>({
            method: 'PUT',
            url: `/feedback/${feedbackSessionId}/conversation`,
            signal,
            body: { comment },
        });
    }

    fetchFeedbackSessionRatingForm({
        feedbackSessionId,
        ignoreCache = false,
        signal,
    }: {
        feedbackSessionId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<FeedbackSessionRatingFormDto>({
            method: 'GET',
            url: `/feedback/${feedbackSessionId}/rate`,
            ignoreCache,
            signal,
        });
    }

    rateFeedbackSession({
        feedbackSessionId,
        data,
        signal,
    }: {
        feedbackSessionId: string;
        data: FeedbackSessionAnswersDto;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<FeedbackSessionDto>({
            method: 'PUT',
            url: `/feedback/${feedbackSessionId}/rate`,
            signal,
            body: data,
        });
    }

    searchFeedbackRecipients({
        query,
        selectedIds,
        isRequestFeedback,
        signal,
    }: {
        query: string;
        selectedIds: string[];
        isRequestFeedback: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<FeedbackRecipientsDto>({
            method: 'POST',
            url: '/feedback/recipients',
            signal,
            body: { query, selectedIds, isRequestFeedback },
        });
    }

    startFeedbackSession({
        feedbackRecipientIds,
        feedbackRequest,
        requestMessage,
        feedbackCreationUrlPath,
        signal,
    }: {
        feedbackRecipientIds: string[];
        feedbackRequest: boolean;
        requestMessage?: string;
        feedbackCreationUrlPath?: string;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<FeedbackSessionDto>({
            method: 'POST',
            url: '/feedback',
            signal,
            body: {
                feedbackRecipientIds,
                feedbackRequest,
                requestMessage,
                feedbackCreationUrlPath,
            },
        });
    }

    fetchQmrFeedbackForm({ ignoreCache, signal }: { ignoreCache?: boolean; signal?: AbortSignal }) {
        return this.http.orchestrateRequest<QmrFeedbackFormDto>({
            method: 'GET',
            url: '/feedback/qmr-form',
            signal,
            ignoreCache,
        });
    }

    submitQmrFeedback({
        qmrId,
        feedbackFormId,
        signal,
        answers,
    }: {
        qmrId: string;
        feedbackFormId: string;
        signal: AbortSignal;
        answers: FeedbackSessionAnswersDto;
    }) {
        return this.http.orchestrateRequest<FeedbackSessionDto>({
            method: 'POST',
            url: '/feedback/qmr',
            signal,
            body: {
                qmrId,
                feedbackFormId,
                answers,
            },
        });
    }
}
