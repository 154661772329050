import * as React from 'react';
import { ITechline } from '@packages/models/api';
import {
    ITechlineProps,
    TechlineActionDispatcher,
    techlineReducer,
    techlineActions,
    baseTechline,
} from './techline.state';
import { TechlineService } from './techline.service';

export const TechlineContext = React.createContext({
    techline: baseTechline,
} as ITechlineProps);

export const TechlineDispatchContext = React.createContext<TechlineActionDispatcher | undefined>(undefined);

export const TechlineProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const [state, dispatch] = React.useReducer(techlineReducer, {} as any);
    return (
        <TechlineContext.Provider value={state}>
            <TechlineDispatchContext.Provider value={dispatch}>{children}</TechlineDispatchContext.Provider>
        </TechlineContext.Provider>
    );
};

export async function fetchTechline({
    techlineId,
    techlineService,
    techlineDispatch,
    signal,
}: {
    techlineId?: string;
    techlineService: TechlineService;
    signal?: AbortSignal;
    techlineDispatch: TechlineActionDispatcher;
}) {
    const response = await techlineService.getTechline({
        techlineId,
        signal,
    });
    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }
    const techline = response.data.techline;
    techlineDispatch(techlineActions.getTechline({ techline }));

    return response.data;
}

export async function createOrUpdateTechline({
    techline,
    techlineService,
    techlineDispatch,
    signal,
}: {
    techline: Partial<ITechline>;
    techlineService: TechlineService;
    signal?: AbortSignal;
    techlineDispatch: TechlineActionDispatcher;
}) {
    const { techlineId } = techline;
    if (techlineId) {
        const response = await techlineService.updateTechline({
            techline,
            techlineId,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }
        const newTechline = response.data.techline;

        techlineDispatch(techlineActions.getTechline({ techline: newTechline }));

        return response.data;
    } else {
        delete techline.techlineId;
        const response = await techlineService.createTechline({
            techline,
            signal,
        });
        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            return Promise.reject(response.data);
        }

        const newTechline = response.data.techline.techline;

        techlineDispatch(techlineActions.getTechline({ techline: newTechline }));

        return response.data;
    }
}
