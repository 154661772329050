/* eslint-disable react-native/no-inline-styles */
import { cloneDeep } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

import colors from '@packages/core/styles/colors';
import { FeedbackFormElement, FormElementTypeId } from '@packages/models/api';

import {
    TextInputHelper,
    TextareaHelper,
    Typography,
    Icon,
    Range,
    Collapse,
    SuccessFailBadge,
    Label,
    StsIconName,
} from '../shared';

export type FeedbackFormElementComponent = FC<
    FeedbackFormElement & {
        readOnly?: boolean;
        onAnswerChange?: (answer: any) => void;
    }
>;

const FeedbackFormTextHeader: FeedbackFormElementComponent = ({ formElementLabel }) => {
    return (
        <View style={styles.formSection}>
            <Typography variant="lead">{formElementLabel}</Typography>
        </View>
    );
};

const FeedbackFormTextParagraph: FeedbackFormElementComponent = ({ formElementLabel }) => {
    return (
        <View style={styles.formSection}>
            <Typography variant="lead">{formElementLabel}</Typography>
        </View>
    );
};

const FeedbackFormInputText: FeedbackFormElementComponent = ({
    formElementId,
    formElementLabel,
    options,
    textRequired,
    selectedOptions,
    readOnly,
    onAnswerChange,
}) => {
    if (!Array.isArray(options)) {
        throw new Error('Unable to render InputText. Missing options');
    }

    const [value, setValue] = useState(selectedOptions && selectedOptions[0] ? selectedOptions[0].answerText : '');

    return (
        <View style={styles.formSection}>
            {options.map((option) => {
                if (readOnly) {
                    const optionAnswer = selectedOptions?.find((sO) => sO.optionId === option.optionId);

                    return (
                        <React.Fragment key={option.optionId}>
                            <Label>{formElementLabel}</Label>
                            <Typography>{optionAnswer?.answerText}</Typography>
                        </React.Fragment>
                    );
                }

                return (
                    <TextInputHelper
                        key={option.optionId}
                        label={formElementLabel}
                        required={textRequired}
                        value={value}
                        onChangeText={(text) => {
                            setValue(text);

                            if (option && onAnswerChange) {
                                onAnswerChange({
                                    elementId: formElementId,
                                    options: [{ optionId: option.optionId, answerText: text }],
                                });
                            }
                        }}
                        placeholder={option.optionPlaceholder}
                    />
                );
            })}
        </View>
    );
};

const FeedbackFormInputTextarea: FeedbackFormElementComponent = ({
    formElementId,
    formElementLabel,
    options,
    textRequired,
    selectedOptions,
    readOnly,
    onAnswerChange,
}) => {
    if (!Array.isArray(options)) {
        throw new Error('Unable to render InputTextarea. Missing options');
    }

    const [value, setValue] = useState(selectedOptions && selectedOptions[0] ? selectedOptions[0].answerText : '');

    return (
        <View style={styles.formSection}>
            {options.map((option) => {
                if (readOnly) {
                    const optionAnswer = selectedOptions?.find((sO) => sO.optionId === option.optionId);

                    return (
                        <React.Fragment key={option.optionId}>
                            <Label>{formElementLabel}</Label>
                            <Typography>{optionAnswer?.answerText}</Typography>
                        </React.Fragment>
                    );
                }

                return (
                    <TextareaHelper
                        key={option.optionId}
                        label={formElementLabel}
                        value={value}
                        required={textRequired}
                        onChangeText={(text) => {
                            setValue(text);

                            if (option && onAnswerChange) {
                                onAnswerChange({
                                    elementId: formElementId,
                                    options: [{ optionId: option.optionId, answerText: text }],
                                });
                            }
                        }}
                        placeholder={option.optionPlaceholder}
                    />
                );
            })}
        </View>
    );
};

const FeedbackFormRating: FeedbackFormElementComponent = ({
    formElementId,
    formElementLabel,
    selectedOptions,
    options,
    readOnly,
    onAnswerChange,
}) => {
    if (!Array.isArray(options)) {
        throw new Error('Unable to render Rating. Missing options');
    }

    const [ratingOptions, selectedOption] = useMemo(() => {
        const selectedOptionId = selectedOptions && selectedOptions[0] && selectedOptions[0].optionId;

        return [
            options.map((option) => ({
                id: option.optionId,
                value: parseInt(option.optionValue, 10),
                message: option.optionLabel,
            })),
            options.find((o) => o.optionId === selectedOptionId),
        ];
    }, [options, selectedOptions]);

    const [value, setValue] = useState((selectedOption && selectedOption.optionValue) || '');

    return (
        <View style={styles.formSection}>
            <Typography variant="label">{formElementLabel}</Typography>

            <Range
                disabled={readOnly}
                value={parseInt(value, 10)}
                values={ratingOptions}
                onChange={(newValue) => {
                    const valueAsString = String(newValue);
                    setValue(valueAsString);

                    const option = options.find((o) => o.optionValue === valueAsString);

                    if (option && onAnswerChange) {
                        onAnswerChange({
                            elementId: formElementId,
                            options: [{ optionId: option.optionId }],
                        });
                    }
                }}
            />
        </View>
    );
};

const FeedbackFormRatingWithText: FeedbackFormElementComponent = ({
    formElementId,
    formElementLabel,
    selectedOptions = [],
    options,
    textRequired,
    readOnly,
    onAnswerChange,
}) => {
    if (!Array.isArray(options)) {
        throw new Error('Unable to render RatingWithText. Missing options');
    }

    const [selectedRating, selectedRatingText] = selectedOptions;

    const [ratingOptions, selectedRatingOption, textareaOption] = useMemo(() => {
        const clone = cloneDeep(options);
        const lastOption = clone.pop();

        return [
            clone.map((option) => ({
                id: option.optionId,
                value: parseInt(option.optionValue, 10),
                message: option.optionLabel,
            })),
            selectedRating && options.find((o) => o.optionId === selectedRating.optionId),
            lastOption,
        ];
    }, [options, selectedRating]);

    const [ratingOption, setRatingOption] = useState(selectedRatingOption);
    const [rangeValue, setRangeValue] = useState((ratingOption && ratingOption.optionValue) || '');
    const [textValue, setTextValue] = useState((selectedRatingText && selectedRatingText.answerText) || '');
    const [isExpanded, setIsExpanded] = useState(false);

    if (!ratingOptions || !textareaOption) {
        return null;
    }

    return (
        <View style={styles.formSection}>
            <Typography variant="label">{formElementLabel}</Typography>

            <Range
                disabled={readOnly}
                value={parseInt(rangeValue, 10)}
                values={ratingOptions}
                onChange={(newValue) => {
                    const valueAsString = String(newValue);
                    setRangeValue(valueAsString);

                    const option = options.find((o) => o.optionValue === valueAsString);

                    if (textareaOption && option && onAnswerChange) {
                        setRatingOption(option);
                        onAnswerChange({
                            elementId: formElementId,
                            options: [
                                { optionId: option.optionId },
                                {
                                    optionId: textareaOption.optionId,
                                    answerText: textValue,
                                },
                            ],
                        });
                    }
                }}
            />

            {readOnly ? (
                <>
                    <Typography style={{ marginTop: 8 }} variant="label">
                        {textareaOption ? textareaOption.optionLabel : ''}
                    </Typography>

                    <Typography>{selectedRatingText?.answerText}</Typography>
                </>
            ) : (
                <>
                    <TouchableOpacity style={styles.optionalSectionToggle} onPress={() => setIsExpanded(!isExpanded)}>
                        <Label required={!!textareaOption && textRequired}>
                            {textareaOption ? textareaOption.optionLabel : ''}
                        </Label>
                        <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} color="blueOne" />
                    </TouchableOpacity>

                    <Collapse open={isExpanded}>
                        <TextareaHelper
                            value={textValue}
                            onChangeText={(newValue) => {
                                setTextValue(newValue);

                                if (textareaOption && ratingOption && onAnswerChange) {
                                    onAnswerChange({
                                        elementId: formElementId,
                                        options: [
                                            { optionId: ratingOption.optionId },
                                            {
                                                optionId: textareaOption.optionId,
                                                answerText: newValue,
                                            },
                                        ],
                                    });
                                }
                            }}
                            placeholder={textareaOption ? textareaOption.optionPlaceholder : ''}
                        />
                    </Collapse>
                </>
            )}
        </View>
    );
};

const FeedbackFormCheckbox: FeedbackFormElementComponent = ({
    formElementId,
    formElementLabel,
    selectedOptions,
    options,
    readOnly,
    onAnswerChange,
}) => {
    if (!Array.isArray(options)) {
        throw new Error('Unable to render Checkbox. Missing options.');
    }

    const answeredOptions = useMemo(() => {
        return selectedOptions?.map((o) => options.find((oo) => oo.optionId === o.optionId));
    }, [options, selectedOptions]);

    const iconName = 'checkbox';
    const [selectedValues, setSelectedValues] = useState(answeredOptions ? answeredOptions : []);

    return (
        <View style={styles.formSection}>
            <Typography variant="label" style={styles.labelText}>
                {formElementLabel}
            </Typography>

            {options.map((option) => {
                const isOptionSelected = !!selectedValues.find(
                    (selectedOption) => selectedOption?.optionId === option.optionId
                );

                if (readOnly) {
                    return isOptionSelected ? (
                        <View style={{ marginBottom: 4 }}>
                            <SuccessFailBadge key={option.optionId} success>
                                {option.optionLabel}
                            </SuccessFailBadge>
                        </View>
                    ) : null;
                }

                return (
                    <TouchableOpacity
                        style={styles.selectOptionWrapper}
                        key={option.optionId}
                        onPress={() => {
                            const selectedValuesClone = cloneDeep(selectedValues);

                            if (isOptionSelected) {
                                const indexToRemove = selectedValuesClone.findIndex(
                                    (selectedOption) => selectedOption?.optionId === option.optionId
                                );
                                selectedValuesClone.splice(indexToRemove, 1);
                            } else {
                                selectedValuesClone.push(option);
                            }

                            setSelectedValues(selectedValuesClone);

                            if (onAnswerChange) {
                                onAnswerChange({
                                    elementId: formElementId,
                                    options: selectedValuesClone.map((selectedOption) => {
                                        return { optionId: selectedOption?.optionId };
                                    }),
                                });
                            }
                        }}
                    >
                        <Icon
                            size={32}
                            name={(isOptionSelected ? `${iconName}-marked` : `${iconName}-unmarked`) as StsIconName}
                            color={isOptionSelected ? 'blueOne' : 'grayFive'}
                        />

                        <Typography variant="lead" style={{}}>
                            {option.optionLabel}
                        </Typography>
                    </TouchableOpacity>
                );
            })}
        </View>
    );
};

const FeedbackFormRadio: FeedbackFormElementComponent = ({
    formElementId,
    formElementLabel,
    options,
    selectedOptions,
    readOnly,
    onAnswerChange,
}) => {
    if (!Array.isArray(options)) {
        throw new Error('Unable to render Radio. Missing options.');
    }

    const answeredOptions = useMemo(() => {
        return selectedOptions?.map((o) => options.find((oo) => oo.optionId === o.optionId));
    }, [options, selectedOptions]);

    const iconName = 'radio';
    const [selectedValues, setSelectedValues] = useState(answeredOptions ? answeredOptions : []);

    return (
        <View style={styles.formSection}>
            <Typography variant="label" style={styles.labelText}>
                {formElementLabel}
            </Typography>

            {options.map((option, idx) => {
                const isOptionSelected = !!selectedValues.find(
                    (selectedOption) => selectedOption?.optionId === option.optionId
                );

                if (readOnly) {
                    return isOptionSelected ? (
                        <View style={{ marginBottom: 4 }}>
                            <SuccessFailBadge key={option.optionId} success>
                                {option.optionLabel}
                            </SuccessFailBadge>
                        </View>
                    ) : null;
                }

                return (
                    <TouchableOpacity
                        style={styles.selectOptionWrapper}
                        key={idx}
                        onPress={() => {
                            setSelectedValues([option]);

                            if (onAnswerChange) {
                                onAnswerChange({
                                    elementId: formElementId,
                                    options: [{ optionId: option.optionId }],
                                });
                            }
                        }}
                    >
                        <Icon
                            size={32}
                            name={(isOptionSelected ? `${iconName}-marked` : `${iconName}-unmarked`) as StsIconName}
                            color={isOptionSelected ? 'blueOne' : 'grayFive'}
                        />

                        <Typography variant="lead" style={{}}>
                            {option.optionLabel}
                        </Typography>
                    </TouchableOpacity>
                );
            })}
        </View>
    );
};

const FeedbackFormDropdown: FeedbackFormElementComponent = ({}) => {
    throw new Error(
        FormElementTypeId.Dropdown +
            ' is platform dependent, render this element through paltform-specific FeedbackFormElement components'
    );
};

export const FeedbackFormElements = {
    [FormElementTypeId.TextHeader]: FeedbackFormTextHeader,
    [FormElementTypeId.TextParagraph]: FeedbackFormTextParagraph,
    [FormElementTypeId.InputText]: FeedbackFormInputText,
    [FormElementTypeId.InputTextarea]: FeedbackFormInputTextarea,
    [FormElementTypeId.Rating]: FeedbackFormRating,
    [FormElementTypeId.RatingWithText]: FeedbackFormRatingWithText,
    [FormElementTypeId.Checkbox]: FeedbackFormCheckbox,
    [FormElementTypeId.Radio]: FeedbackFormRadio,
    [FormElementTypeId.Dropdown]: FeedbackFormDropdown,
};

const styles = StyleSheet.create({
    formSection: {
        paddingBottom: 24,
    },
    sectionBorder: {
        borderBottomColor: colors.grayThree,
        borderBottomWidth: 1,
    },
    labelText: {
        marginBottom: 8,
    },
    optionalSectionToggle: {
        marginTop: 8,
        minHeight: 48,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selectOptionWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
