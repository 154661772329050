import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

import { Button, Typography } from '@packages/ui/shared';
import Select from '@web/components/select';

import { DraggableFormComponent, draggableFeedbackFormElements, FormElementTypeId } from '@packages/models/api';
import {
    FormEditLikert,
    FormEditTextInput,
    FormEditLikertLongText,
    FormEditTextStatic,
} from '@web/administration/components';
import { FormEditSelectList } from './form-edit-select-list';

interface FormComponentEditModalPorps extends ModalProps {
    formComponent: DraggableFormComponent | undefined;
    onSave(formComponent: DraggableFormComponent): void;
}

export const FormComponentEditModal: FC<FormComponentEditModalPorps> = ({ formComponent, onSave, show, onHide }) => {
    const [formComponentClone, setFromComponentClone] = useState<DraggableFormComponent>();
    const [questionTypeSelectValue, setQuestionTypeSelectValue] = useState('');

    useEffect(() => {
        if (formComponent) {
            setFromComponentClone(cloneDeep(formComponent));
            setQuestionTypeSelectValue(formComponent.formElementTypeId);
        } else {
            setFromComponentClone(undefined);
            setQuestionTypeSelectValue('');
        }
    }, [formComponent]);

    function handleSaveButtonClick() {
        if (!formComponentClone) {
            return;
        }

        onSave(formComponentClone);
    }

    function handleQuestionTypeSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        if (!formComponentClone) {
            return;
        }

        const updatedFormComponent = Object.assign(cloneDeep(formComponentClone), {
            formElementTypeId: event.currentTarget.value as FormElementTypeId,
        });

        setQuestionTypeSelectValue(event.currentTarget.value);
        setFromComponentClone(updatedFormComponent);
    }

    function handleFormComponentChange(newProps: Partial<DraggableFormComponent>) {
        if (!formComponentClone) {
            return;
        }

        const updatedFormComponent = Object.assign(cloneDeep(formComponentClone), newProps);

        setFromComponentClone(updatedFormComponent);
    }

    return (
        <Modal show={show} centered={true} onHide={onHide} size="lg">
            <Modal.Header placeholder={''}>
                <Modal.Title>Edit Question</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-2">
                    <Typography variant="label">Question Type</Typography>
                </div>
                <Select
                    value={questionTypeSelectValue}
                    options={Object.values(draggableFeedbackFormElements).map((formElement) => {
                        return {
                            title: formElement.title,
                            value: formElement.formElementTypeId,
                        };
                    })}
                    onChange={handleQuestionTypeSelectChange}
                />
            </Modal.Body>

            <hr />

            <Modal.Body>
                {formComponentClone?.formElementTypeId === FormElementTypeId.TextHeader && (
                    <FormEditTextStatic onChange={handleFormComponentChange} {...formComponentClone} />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.TextParagraph && (
                    <FormEditTextStatic onChange={handleFormComponentChange} {...formComponentClone} />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.InputText && (
                    <FormEditTextInput onChange={handleFormComponentChange} {...formComponentClone} />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.InputTextarea && (
                    <FormEditTextInput onChange={handleFormComponentChange} {...formComponentClone} />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Rating && (
                    <FormEditLikert onChange={handleFormComponentChange} {...formComponentClone} />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.RatingWithText && (
                    <FormEditLikertLongText onChange={handleFormComponentChange} {...formComponentClone} />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Checkbox && (
                    <FormEditSelectList onChange={handleFormComponentChange} {...formComponentClone} />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Radio && (
                    <FormEditSelectList onChange={handleFormComponentChange} {...formComponentClone} />
                )}
                {formComponentClone?.formElementTypeId === FormElementTypeId.Dropdown && (
                    <FormEditSelectList onChange={handleFormComponentChange} {...formComponentClone} />
                )}
            </Modal.Body>

            <Modal.Footer>
                <div className="mr-2">
                    <Button variant="ghost-blue" onPress={onHide}>
                        Cancel
                    </Button>
                </div>

                <Button variant="primary" onPress={handleSaveButtonClick}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
