import React, { forwardRef } from 'react';
import { createUseStyles } from 'react-jss';

const useTableHeadStyles = createUseStyles({
    tableHead: {},
});

export const TableHead = React.memo(
    forwardRef<HTMLTableSectionElement, any>((props, ref) => {
        const classes = useTableHeadStyles();

        return (
            <thead ref={ref} className={classes.tableHead}>
                {props.children}
            </thead>
        );
    })
);

TableHead.displayName = 'TableHead';
