import colors from '@packages/core/styles/colors';
import React from 'react';
import { View, StyleSheet } from 'react-native';

export const Divider = () => {
    return <View style={styles.divider} />;
};

const styles = StyleSheet.create({
    divider: {
        width: '100%',
        borderColor: colors.grayTwo,
        borderBottomWidth: 2,
    },
});
