import uuid from 'uuid';
import { isFunction, throttle } from 'lodash';

import { ApiError, ApiResponse } from '@packages/models/api';

import { StorageService, StorageKeys } from '../storage';
import { ConfigService, Constants } from '../config';
import jwt_decode from 'jwt-decode';

interface CustomHeaders {
    'X-Simulate-Delay'?: string;
    'X-Random-Error-Probability'?: string;
}

export enum ApiTarget {
    StsBackend = 'STS_BACKEND',
    VinService = 'VIN_SERVICE',
}

export interface HttpHeaders {
    [headerName: string]: string;
}

export type TokenGetter = () => Promise<string | null>;

export interface HttpClientOptions {
    defaultHeaders: HttpHeaders;
    tokenGetter: TokenGetter;
}

export interface AuthTokenPayload {
    sub: string;
    exp: number;
}
export interface RequestConfig<T = {}> {
    requestId?: string;
    method: 'PUT' | 'POST' | 'GET' | 'DELETE' | 'PATCH';
    url: string;
    headers?: HttpHeaders & CustomHeaders;
    body?: T;
    signal?: AbortSignal;
    ignoreCache?: boolean;
    apiTarget?: ApiTarget;
}

export class HttpClient {
    private cache = new Map<string, any>();
    private defaultHeaders: HttpHeaders;
    private enableLogging = false;
    private enableDegbugApiUtils = false;
    private tokenGetter: TokenGetter;
    private freshTokenResolver: null | Promise<string> = null;
    private sessionTimeoutHandler: null | ((exp: number) => void) = null;
    private notAuthenticatedHandler: null | (() => void) = null;
    private tokenUpdateHandler: null | ((newToken: string) => void) = null;
    private timer?: number;

    private apiBaseUrls: Record<ApiTarget, string | null> = {
        [ApiTarget.StsBackend]: this.configService.env.API_BASE_URL || null,
        [ApiTarget.VinService]: this.configService.env.VIN_BASE_URL || null,
    };

    constructor(
        private storageService: StorageService,
        private configService: ConfigService,
        options: HttpClientOptions
    ) {
        this.tokenGetter = options.tokenGetter;
        this.defaultHeaders = options.defaultHeaders;

        this.refreshToken = throttle(this.refreshToken.bind(this), 30000) as unknown as () => Promise<
            string | undefined
        >;

        this.enableLogging = configService.getBoolean(Constants.Env.EnableDebugger);
        this.enableDegbugApiUtils = configService.getBoolean(Constants.Env.DebugApiUtils);
    }

    private verifyTokenFreshness(accessToken: string) {
        // httpClient is already "busy" refreshing the token.
        // let's wait for that to resolve and use the fresh token
        // before firing whatever request we're attempting to fire
        if (this.freshTokenResolver) {
            return this.freshTokenResolver;
        }

        let payload: AuthTokenPayload;
        try {
            payload = jwt_decode(accessToken);
        } catch (e) {
            // couldn't decode
            return accessToken;
        }

        const expiresAfterInSeconds = payload.exp - Date.now() / 1000;

        this.updateTimeoutTimer(expiresAfterInSeconds);

        if (expiresAfterInSeconds >= Constants.RefreshTokenWindowInSeconds) {
            // token still has more than 5 mins left -- let's use it!
            return accessToken;
        }

        // token about to expire in 5 minutes.
        // let's refresh it now
        this.freshTokenResolver = this.orchestrateRequest<{ accessToken: string }>({
            method: 'POST',
            url: '/accounts/refresh-access-token',
            body: { accessToken },
        }).then((response) => {
            if (!response.success) {
                // do nothing - use old token
                return accessToken;
            }

            const newToken = response.data.accessToken;

            const newPayload: AuthTokenPayload = jwt_decode(newToken);
            const newExpiryInSeconds = newPayload.exp - Date.now() / 1000;

            this.updateTimeoutTimer(newExpiryInSeconds);

            return this.storageService.setItem(StorageKeys.AccessToken, newToken).then(() => {
                // token refreshed/saved
                // clear this resolver for future requests
                this.freshTokenResolver = null;

                typeof this.tokenUpdateHandler === 'function' && this.tokenUpdateHandler(newToken);

                return newToken;
            });
        });

        return this.freshTokenResolver;
    }

    private async request<T>(config: RequestConfig) {
        const { method = 'GET', headers = {}, signal, apiTarget } = config;

        let dynamicHeaders: HttpHeaders & CustomHeaders = {};

        if (isFunction(this.tokenGetter)) {
            try {
                let token = await this.tokenGetter();

                if (token) {
                    // skip verification for the refresh call
                    token = config.url.endsWith('/refresh-access-token')
                        ? token
                        : await this.verifyTokenFreshness(token);
                    dynamicHeaders[Constants.AccessTokenHeaderName] = token;
                }
            } catch (e) {
                console.error(e);
                throw new Error('TODO: Unable to get access token from storage');
            }
        }

        if (this.enableDegbugApiUtils) {
            const [apiRandomErrorProbability, apiSimulateDelay] = await Promise.all([
                this.storageService.getItem(StorageKeys.ApiRandomErrorProbability),
                this.storageService.getItem(StorageKeys.ApiSimulateDelay),
            ]);

            dynamicHeaders[Constants.RandomErrorProbabilityHeaderName] = apiRandomErrorProbability;
            dynamicHeaders[Constants.SimulateDelayHeaderName] = apiSimulateDelay;
        }

        const requestHeaders = Object.assign({}, this.defaultHeaders, dynamicHeaders, headers);

        if (config.body instanceof FormData) {
            // remove default content-type of application/json
            delete requestHeaders['content-type'];
        }

        const requestInit: RequestInit = {
            method,
            headers: requestHeaders,
            signal,
        };

        if (config.body) {
            requestInit.body = config.body instanceof FormData ? config.body : JSON.stringify(config.body);
        }

        this.enableLogging && this.logRequest({ config, requestInit });

        // signals could abort while performing
        // other async tasks
        // such as refreshing access token
        if (config.signal && config.signal.aborted) {
            const err = new Error('Request Aborted');
            err.name = 'AbortError';
            throw err;
        }

        let baseUrl = this.apiBaseUrls[ApiTarget.StsBackend];
        if (apiTarget == ApiTarget.VinService) {
            baseUrl = this.apiBaseUrls[ApiTarget.VinService];
        }
        const url = `${baseUrl}${config.url}`;
        const response = await fetch(url, requestInit);

        const parsedResponse = await this.parseResponse<T>(response);

        if (method === 'GET' && !config.ignoreCache) {
            this.cache.set(config.url, parsedResponse);
        }

        return parsedResponse;
    }

    private async parseResponse<T>(response: Response) {
        let body;

        if (response.status === 204) {
            return {} as T;
        }

        try {
            body = await response.json();
        } catch (error) {
            if (error instanceof Error && error.name === 'AbortError') {
                // abort signal can trigger
                // between making the request and calling "res.json()"
                return Promise.reject(error);
            }

            return Promise.resolve({} as T);
        }

        if (!response.ok) {
            if (this.notAuthenticatedHandler && response.status === 401) {
                this.notAuthenticatedHandler();
                this.freshTokenResolver = null;
            }

            return Promise.reject(body as ApiError);
        }

        return body as T;
    }

    private logRequest({ config, requestInit }: { config: RequestConfig; requestInit: RequestInit }) {
        console.time(config.requestId);
        console.group('Request Started', config.url);
        console.log('Request ID:', config.requestId);
        console.log('Config:', config);
        console.log('RequestInit:', requestInit);
        console.groupEnd();
    }

    private logRequestComplete(requestId: string, config: RequestConfig, data: any) {
        console.group('Request Complete', config.url);
        console.log('Request ID                |       Time elapsed');
        console.timeEnd(requestId);
        console.log('Config:', config);
        console.log('Response:', data);
        console.groupEnd();
    }

    private logRequestError(requestId: string, config: RequestConfig, data: any) {
        console.group('Request Error', config.url);
        console.log('Request ID                |       Time elapsed');
        console.timeEnd(requestId);
        console.log('Config:', config);
        console.log('Error:', data);
        console.groupEnd();
    }

    private logRequestAborted(requestId: string, config: RequestConfig) {
        console.group('Request Aborted', config.url);
        console.log('Request ID                |       Time elapsed');
        console.timeEnd(requestId);
        console.log('Config:', config);
        console.groupEnd();
    }

    private updateTimeoutTimer(tokenExpirationInSeconds: number) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        const startTiming = Date.now() / 1000;
        this.timer = window.setTimeout(
            () => {
                this.sessionTimeoutHandler && this.sessionTimeoutHandler(startTiming + tokenExpirationInSeconds);
            },
            (tokenExpirationInSeconds - Constants.TimeoutPopupDurationInSeconds) * 1000
        );
    }

    get baseUrl() {
        return this.configService.env.API_BASE_URL;
    }

    registerSessionTimeoutHandler(handler: (exp: number) => void) {
        this.sessionTimeoutHandler = handler;
    }

    registerNotAuthenticatedHandler(handler: () => void) {
        this.notAuthenticatedHandler = handler;
    }

    registerTokenUpdateHandler(handler: (newToken: string) => void) {
        this.tokenUpdateHandler = handler;
    }

    queryString(params: { [param: string]: any }) {
        if (!params || Object.keys(params).length === 0) {
            return '';
        }

        return Object.entries(params).reduce((acc, [param, value]) => {
            if (value === undefined) {
                return acc;
            }

            acc += acc ? '&' : '?';

            acc += `${encodeURIComponent(param)}=${encodeURIComponent(value)}`;
            return acc;
        }, '');
    }

    async orchestrateRequest<T>(config: RequestConfig): Promise<ApiResponse<T>> {
        if (config.method === 'GET' && !config.ignoreCache) {
            const cachedResponse: T = this.cache.get(config.url);

            if (cachedResponse) {
                return Promise.resolve({
                    success: true,
                    fromCache: true,
                    data: cachedResponse,
                });
            }
        } else if (config.method !== 'GET') {
            this.cache.clear();
        }

        const requestId = uuid();
        config.requestId = requestId;

        let abortHandler;
        if (config.signal) {
            abortHandler = () => this.enableLogging && this.logRequestAborted(requestId, config);
            config.signal.addEventListener('abort', abortHandler);
        }

        try {
            const data = await this.request<T>(config);
            this.enableLogging && this.logRequestComplete(requestId, config, data);

            if (abortHandler) {
                config.signal!.removeEventListener('abort', abortHandler);
            }

            return {
                success: true,
                fromCache: false,
                data,
            };
        } catch (error) {
            this.enableLogging && this.logRequestError(requestId, config, error);

            return {
                success: false,
                aborted: error instanceof Error && error.name === 'AbortError',
                data: error as ApiError,
            };
        }
    }

    refreshToken() {
        return this.storageService.getItem(StorageKeys.AccessToken).then((t) => {
            if (t) {
                return this.verifyTokenFreshness(t);
            }
        });
    }

    clearCache() {
        this.cache.clear();
    }
}
